import * as Autocompletion from './AutoCompletion'
import * as CurrentUser from './CurrentUser'
import * as DashbardGroups from './DashboardGroups'
import * as DemoModeNotification from './DemoModeNotification'
import * as FavouriteDashboards from './FavouriteDashboards'
import * as Menu from './Menu'
import * as Modal from './Modal'
import * as Notification from './Notification'
import * as SetupWizard from './SetupWizard'
import * as Widget from './Widget'

export const actions = {
    Autocompletion: Autocompletion.actions,
    CurrentUser: CurrentUser.actions,
    DashboardGroups: DashbardGroups.actions,
    DemoModeNotification: DemoModeNotification.actions,
    FavouriteDashboards: FavouriteDashboards.actions,
    Menu: Menu.actions,
    Modal: Modal.actions,
    Notification: Notification.actions,
    SetupWizard: SetupWizard.actions,
    Widget: Widget.actions,
}

export const reducerArray = [
    CurrentUser.reducer,
    DashbardGroups.reducers,
    DemoModeNotification.reducer,
    FavouriteDashboards.reducer,
    Menu.reducer,
    Modal.reducer,
    Notification.reducer,
    SetupWizard.reducer,
    Widget.reducer,
]

export const selectors = {
    CurrentUser: CurrentUser.selectors,
    DashbardGroups: DashbardGroups.selectors,
    DemoModeNotification: DemoModeNotification.selectors,
    FavouriteDashboards: FavouriteDashboards.selectors,
    Menu: Menu.selectors,
    Modal: Modal.selectors,
    Notification: Notification.selectors,
    SetupWizard: SetupWizard.selectors,
    Widget: Widget.selectors,
}
