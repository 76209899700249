import { select, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { actions, selectors } from '../../Redux'
import axios from '../Utility/axios'

export default function* UpdateDashboardGroupsSaga() {
    yield takeLatest(
        [
            actions.UI.DashboardGroups.moveDashboardGroup,
            actions.UI.DashboardGroups.removeDashboardFromGroup,
            actions.UI.DashboardGroups.removeDashboardGroup,
            actions.UI.DashboardGroups.moveDashboardFromGroupToGroup,
            actions.UI.DashboardGroups.createDashboardGroup,
            actions.UI.DashboardGroups.changeGroupTitle,
            actions.UI.DashboardGroups.toggleIsGroupCollapsed,
        ],
        function* () {
            try {
                const dashboardGroups = selectors.UI.DashbardGroups.allDashboardGroups(yield select())

                const response: AxiosResponse = yield axios({
                    method: 'post',
                    url: 'my-profile/dashboard-groups',
                    data: dashboardGroups,
                })

                if (response.status >= 400) {
                    console.error('Could not save user dashboard-groups', response)
                }
            } catch (e) {
                console.error(e)
            }
        }
    )
}
