import React, { lazy, Suspense } from 'react'
import { GanttChartWidgetProps } from './GanttChartWidget.types'

const LazyWidget = lazy(() => import(/* webpackChunkName: "GantChart" */ './GanttChartWidget'))

const LazyWidgetWrapper: React.FC<GanttChartWidgetProps> = (props) => (
    <Suspense fallback="">
        <LazyWidget {...props} />
    </Suspense>
)

export default LazyWidgetWrapper
