import { put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { createAction } from '@reduxjs/toolkit'
import { actions } from '../../../Redux'
import axios from '../../Utility/axios'
import { ImporterId } from '../../../Redux/Data/types'

export const toggleImporterAction = createAction('SAGAS/toggleImporter', (uuid: ImporterId) => ({ payload: { uuid } }))

export default function* ToggleImporter() {
    yield takeLatest(toggleImporterAction, function* (action) {
        try {
            const { uuid } = action.payload

            const response: AxiosResponse = yield axios({
                method: 'post',
                url: 'admin/importer/toggle/' + uuid,
            })
            if (response.status < 400) {
                yield put(actions.Data.Importer.toggleImporterFinished(uuid))
            }
        } catch (e) {
            console.error(e)
        }
    })
}
