import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Switch from '../../../Components/Atoms/Switch/Switch'
import { actions } from '../../../Redux'
import Button from '../../../Components/Atoms/Button/Button'
import { AppDispatch } from '../../../store'

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        handleSetSecureCookie: (secureCookie: boolean) => dispatch(actions.Data.System.setSecureCookie(secureCookie)),
        nextStep: () => dispatch(actions.UI.SetupWizard.nextStep()),
    }
}

type Props = ReturnType<typeof mapDispatchToProps>

const TightenSecurityStep = (props: Props) => {
    const isUsingHttps = window.location.protocol.toLowerCase().indexOf('https') === 0
    const [secureCookies, setSecureCookies] = useState(isUsingHttps)
    useEffect(() => {
        props.handleSetSecureCookie(secureCookies)
    }, [secureCookies, props])

    return (
        <React.Fragment>
            <div className="setup-wizard__step">
                <h1 className="setup-wizard__headline">About Security</h1>

                <div>
                    {isUsingHttps ? (
                        <p className="setup-wizard__hint">
                            Cool, you already access Exply via a secure HTTPS connection. This is highly recommended and
                            automatically activates the Secure-Flag for the session cookie to make your Exply experience
                            even more secure.
                        </p>
                    ) : (
                        <p className="setup-wizard__hint">
                            In certain cases, it is helpful to also allow insecure HTTP connections. Please be aware to
                            use this option with high caution. You can forbid the use below.
                        </p>
                    )}
                </div>

                <div>
                    <Switch
                        value={secureCookies}
                        label="Allow user to login via HTTPs only (recommended)."
                        onChange={setSecureCookies}
                    />
                </div>

                <div className="setup-wizard__warning">
                    {secureCookies && !isUsingHttps && 'Now you cannot login until HTTPs is available!'}
                </div>
            </div>

            <div className="setup-wizard__next-button">
                <Button onClick={props.nextStep} type="primary">
                    Next
                </Button>
            </div>
        </React.Fragment>
    )
}

export default connect(null, mapDispatchToProps)(TightenSecurityStep)
