import React from 'react'
import { push } from 'connected-react-router'
import produce from 'immer'
import { connect } from 'react-redux'
import { UserOrGroupConfig } from '../../../Redux/Data/UserManagement'
import Icon from '../../../Components/Atoms/Icon/Icon'
import Switch from '../../../Components/Atoms/Switch/Switch'
import { AppDispatch } from '../../../store'
import { actions, selectors } from '../../../Redux'
import { RootState } from '../../../Redux/types'

type UserOrGroupListItemOwnProps = {
    userOrGroup: UserOrGroupConfig
}

const mapStateToProps = (state: RootState) => ({
    loggedInUser: selectors.UI.CurrentUser.loggedInUser(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    warnAboutSelfDeactivation: (confirmationCallback: () => void) =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'userManagementRemoveOrDisableSelf',
                confirmationCallback,
            })
        ),
    startEditing: (id: string) => dispatch(push('/user-management/edit/' + id)),
    createOrUpdate: (userOrGroup: UserOrGroupConfig) =>
        dispatch(actions.Data.UserManagement.createOrUpdate({ userOrGroup })),
})

type UserOrGroupListItemProps = UserOrGroupListItemOwnProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>

const UserOrGroupListItem: React.FC<UserOrGroupListItemProps> = (props) => {
    const handleToggle = () => {
        const isCurrentUser = props.loggedInUser?.id === props.userOrGroup.id
        const toggle = () =>
            props.createOrUpdate(
                produce(props.userOrGroup, (draft) => {
                    draft.isActive = !draft.isActive
                })
            )

        if (isCurrentUser) {
            props.warnAboutSelfDeactivation(toggle)
        } else {
            toggle()
        }
    }

    return (
        <div
            key={props.userOrGroup.id}
            className={
                props.loggedInUser?.id === props.userOrGroup.id
                    ? 'user-management__user user-management__user--isLoggedIn'
                    : 'user-management__user'
            }
            onClick={() => props.startEditing(props.userOrGroup.id)}
        >
            {props.userOrGroup.isActive ? (
                <Icon
                    name={props.userOrGroup.isGroup ? 'UsersSolidIcon' : 'UserSolidIcon'}
                    title={props.userOrGroup.isGroup ? 'active user group' : 'active user'}
                    className={'user-management__user-icon'}
                />
            ) : (
                <Icon
                    name={props.userOrGroup.isGroup ? 'UsersLightIcon' : 'UserLightIcon'}
                    title={props.userOrGroup.isGroup ? 'inactive user group' : 'inactive user'}
                    className={'user-management__user-icon'}
                />
            )}
            {props.userOrGroup.label ? (
                <div className={'user-management__user-name'}>{props.userOrGroup.label}</div>
            ) : null}
            <div className={'user-management__user-id'}>{'(' + props.userOrGroup.id + ')'}</div>
            <Switch
                className={'user-management__user-toggle'}
                key="0"
                value={props.userOrGroup.isActive}
                onChange={handleToggle}
                labelPositionRight={false}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOrGroupListItem)
