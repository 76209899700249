import { Ace } from 'ace-builds'
import React, { useContext, useState } from 'react'
import CodeAceArea from '../../../Atoms/CodeAceArea/CodeAceArea'
import { FieldsConfigurationContext } from '../../../Atoms/FieldsConfiguration/FieldsConfigurationContext'
import { ListItem } from '../../List/List'
import Icon from '../../../Atoms/Icon/Icon'
import Button from '../../../Atoms/Button/Button'
import Table from '../../../Atoms/Table/Table'

const SqlTableWidgetQueryEditor: React.FC<{ onChange: (value: string) => void; value: string | null }> = (props) => {
    const [query, setQuery] = useState(props.value || undefined)
    const fieldsConfiguration = useContext(FieldsConfigurationContext)
    const [isFieldsHelpOpen, setIsFieldsHelpOpen] = useState(false)

    // see here how this works: https://github.com/securingsincity/react-ace/issues/69
    const fieldCompleter: Ace.Completer = {
        getCompletions(editor, session, pos, prefix, callback) {
            callback(null, [
                ...Object.values(fieldsConfiguration).map((field) => ({
                    caption: field.label,
                    value: field.name,
                    meta: field.tag,
                    score: 1,
                })),
                {
                    caption: 'StartDate',
                    value: '$START_DATE',
                    meta: 'DATE',
                    score: 1,
                },
                {
                    caption: 'EndDate',
                    value: '$END_DATE',
                    meta: 'DATE',
                    score: 1,
                },
            ])
        },
    }

    const fieldsTableRows = Object.values(fieldsConfiguration).map((field) => ({
        field: field.name,
        label: field.label,
    }))

    const onSubmitQuery = () => props.onChange(query || '')

    return (
        <div className="query-editor">
            <h3>Query</h3>
            <CodeAceArea
                onChange={setQuery}
                value={query}
                language={'mysql'}
                height={'200px'}
                completers={[fieldCompleter]}
            />
            <Button type="primary" onClick={onSubmitQuery}>
                Test & Save Query
            </Button>
            <p>You need to test your query before saving to update the widget.</p>
            <h3>Dates</h3>
            <p>
                We support dates in the "yyyy-MM-dd" format and automatically parse them to milliseconds. If you want
                your query to use dates from the Time Selector Widget, use the reserved variables{' '}
                <span className="code">$START_DATE</span> and
                <span className="code">$END_DATE</span>.
            </p>
            <h3>Syntax</h3>
            <p>
                Available Commands can be found in the{' '}
                <a href="https://www.elastic.co/guide/en/elasticsearch/reference/7.17/sql-spec.html">
                    SQL Docs of Elastic Search.
                </a>{' '}
                A list of the available fields can be found below.
            </p>
            <ListItem
                left={[
                    <Icon
                        key={'fields-list-icon'}
                        name="ChevronIcon"
                        rotation={isFieldsHelpOpen ? 90 : 0}
                        size="small"
                    />,
                    'Available Fields',
                ]}
                isOpen={isFieldsHelpOpen}
                onClick={() => setIsFieldsHelpOpen(!isFieldsHelpOpen)}
                collapsible={
                    <div className="fields-help">
                        <Table
                            columns={[
                                { field: 'field', label: 'Field' },
                                { field: 'label', label: 'Label' },
                            ]}
                            rows={fieldsTableRows}
                            onTableHeaderClicked={() => {}}
                            hasStickyHeader
                        />
                    </div>
                }
            />
        </div>
    )
}

export default SqlTableWidgetQueryEditor
