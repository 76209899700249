import { Mode } from '../Components/Organisms/Widgets/WidgetModel'
import { PayloadEntry } from '../Components/Organisms/Widgets/ToolTip/CustomTooltip.types'

export const formatNumberToString = (value?: number, decimals?: number, additions?: string) => {
    const numberOfDecimals =
        decimals !== undefined && Number.isInteger(decimals)
            ? // 0 <= numberOfDecimals <= 20, see https://www.techonthenet.com/js/number_tolocalestring.php
              Math.max(0, Math.min(20, decimals))
            : 2

    let returnString = ''

    if (value && !Number.isNaN(value)) {
        returnString = value.toLocaleString(undefined, {
            minimumFractionDigits: numberOfDecimals,
            maximumFractionDigits: numberOfDecimals,
        })
    } else {
        returnString = '0'
    }

    if (additions) {
        returnString += ' ' + additions
    }

    return returnString
}

export const calculateTotal = (mode: Mode | undefined, entries: Array<PayloadEntry>) => {
    switch (mode) {
        case 'avg':
            return calculateSum(entries) / entries.length
        case 'min':
            return Math.min(...entries.map((entry) => entry.value))
        case 'max':
            return Math.max(...entries.map((entry) => entry.value))
        case 'sum':
        default:
            return calculateSum(entries)
    }
}

export const calculateSum = (chartData: Array<PayloadEntry>) => {
    let sum = 0
    const length = chartData ? chartData.length : 0
    for (let i = 0; i < length; i++) {
        const entry = chartData[i]
        if (entry.value !== undefined) {
            sum += entry.value
        }
    }

    return sum
}

export const getModeLabel = (mode: Mode | undefined) => {
    switch (mode) {
        case 'avg':
            return 'Averaged'
        case 'min':
            return 'Minimum'
        case 'max':
            return 'Maximum'
        case 'sum':
        default:
            return 'Summed'
    }
}

export const formatBigNumberWithSIPrefix = (number: number) => {
    const prefixAsLetter = {
        1000000000000: 'T',
        1000000000: 'G',
        1000000: 'M',
        1000: 'k',
    }

    const isNegative = number < 0
    number = Math.abs(number)

    if (number > 1) {
        number = Math.floor(number)
    } else {
        number = parseFloat(number.toFixed(2))
    }

    if (number !== 0) {
        Object.keys(prefixAsLetter)
            .sort((a, b) => parseInt(b, 10) - parseInt(a, 10))
            .map((x) => parseInt(x, 10))
            .forEach((prefixAsNumber) => {
                if (number >= prefixAsNumber) {
                    number /= prefixAsNumber
                    // @ts-ignore key is recognised as key of the object
                    number = parseFloat(number.toFixed(1)) + prefixAsLetter[prefixAsNumber]
                    return number
                }
            })
    }

    return (isNegative ? '-' : '') + number
}
