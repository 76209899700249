/**
 * see UserOrGroupDto.groovy
 */

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { actions, selectors } from '../../../Redux'

import Button from '../../../Components/Atoms/Button/Button'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { UserOrGroupConfig } from '../../../Redux/Data/UserManagement'
import TextInput from '../../../Components/Atoms/TextInput/TextInput'
import UserOrGroupListItem from './UserOrGroupListItem'

type UserOverviewProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const UserOverview: React.FC<UserOverviewProps> = (props) => {
    const [userFilter, setUserFilter] = useState('')
    const [filteredUsers, setFilteredUsers] = useState(props.users)
    const [groupFilter, setGroupFilter] = useState('')
    const [filteredGroups, setFilteredGroups] = useState(props.groups)

    useEffect(() => {
        filterUsers()
    }, [props.users, userFilter])

    useEffect(() => {
        filterGroups()
    }, [props.groups, groupFilter])

    const userCount = props.users.filter((user) => user.isActive).length
    const userLimit = props.userLimit

    const filterGroups = () =>
        setFilteredGroups(
            props.groups.filter(
                (group) =>
                    group.label.toLowerCase().match(groupFilter.toLowerCase()) ||
                    group.id.toLowerCase().match(groupFilter.toLowerCase())
            )
        )

    const filterUsers = () =>
        setFilteredUsers(
            props.users.filter(
                (user) =>
                    user.label.toLowerCase().match(userFilter.toLowerCase()) ||
                    user.id.toLowerCase().match(userFilter.toLowerCase())
            )
        )

    const handleAddUser = () => {
        if (userLimit === false || userCount < userLimit) {
            props.startCreatingUser()
        } else {
            props.warnAboutUserLimited()
        }
    }

    return (
        <div className="user-overview">
            <div className="users--sticky-header">
                <div className="users--headline">
                    <h3>
                        Users
                        {userLimit === false ? (
                            ''
                        ) : (
                            <small>
                                {' '}
                                ({userCount} of {userLimit})
                            </small>
                        )}
                    </h3>
                    <TextInput
                        value={userFilter}
                        onChange={(value) => setUserFilter(value)}
                        onClear={() => setUserFilter('')}
                        clearable
                        placeholder="Filter Users by Name or Id"
                        className={'user-or-group--filter'}
                    />
                    <Button type="primary" onClick={handleAddUser}>
                        Add new User
                    </Button>
                </div>
                <ColumLabels />
            </div>
            <div className="users--list">
                {filteredUsers.map((user) => (
                    <UserOrGroupListItem userOrGroup={user} key={user.id} />
                ))}
            </div>
            <div className={'groups-sticky-header'}>
                <div className={'groups--headline'}>
                    <h3>Groups</h3>
                    <TextInput
                        value={groupFilter}
                        onChange={(value) => setGroupFilter(value)}
                        onClear={() => setGroupFilter('')}
                        clearable
                        placeholder="Filter Groups by Name or Id"
                        className={'user-or-group--filter'}
                    />
                    <Button type="primary" onClick={props.startCreatingGroup}>
                        Add new Group
                    </Button>
                </div>
                <ColumLabels />
            </div>
            <div className={'groups--list'}>
                {filteredGroups.map((group) => (
                    <UserOrGroupListItem userOrGroup={group} key={group.id} />
                ))}
            </div>
        </div>
    )
}

const ColumLabels = () => (
    <div className="users--column-labels">
        <div className="column-label__label">Label</div>
        <div className="column-label__id">Id</div>
        <div className="column-label__active">Active</div>
    </div>
)

const mapStateToProps = (state: RootState) => ({
    users: selectors.Data.UserManagement.users(state) as Array<UserOrGroupConfig>,
    groups: selectors.Data.UserManagement.groups(state) as Array<UserOrGroupConfig>,
    userLimit: selectors.Data.System.userLimit(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    warnAboutUserLimited: () =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'userManagementUserLimitReached',
                confirmationCallback: () => {
                    window.location.href = 'mailto:support@exply.io?subject=I want to buy an Exply license'
                },
            })
        ),
    startCreatingUser: () => dispatch(push('/user-management/new-user')),
    startCreatingGroup: () => dispatch(push('/user-management/new-group')),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserOverview)
