import PivotTableWidget from './PivotTableWidget'
import PivotTableWidgetStateInitializer from './PivotTableWidget.init'
import PivotTableWidgetConfigurationInitializer, {
    PivotTableConfigurationInitializerForWidgetExplorer,
} from './PivotTableWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import FieldListEditor from '../../../Molecules/FieldListEditor/FieldListEditor'
// @ts-ignore
import PivotTableWidgetIcon from './PivotTableWidget.Icon.tsx'
import { DATE, NUMBER } from '../../../Atoms/FieldsConfiguration/FieldsConfigurationTypes'
import { groupingFieldOptionGenerator, WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'
import { PivotTableWidgetConfig, PivotTableWidgetData } from './PivotTableWidget.types'
import PivotTableCollapseEditor from '../../../Molecules/PivotTableCollapseEditor'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'

const config: WidgetManifestConfiguration = {
    name: 'Pivot Table',
    component: PivotTableWidget,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 1,
        },
        max: {
            dateFields: 1,
            valueFields: 100,
            categoryFields: 100,
        },
    },
    icon: PivotTableWidgetIcon,
    stateInitializationFunction: PivotTableWidgetStateInitializer,
    configurationInitializer: PivotTableWidgetConfigurationInitializer,
    configurationInitializerForWidgetExplorer: PivotTableConfigurationInitializerForWidgetExplorer,
    renderRequirements: (widgetData?: PivotTableWidgetData) => {
        if (widgetData?.rows[0].children.length === 0) {
            return widgetProblems.noData
        }
    },

    editors: {
        data: [
            {
                widgetConfigurationProperty: 'valueFields',
                component: FieldListEditor,
                configuration: {
                    tags: [NUMBER],
                    labelFn: ({ ordinal }: { ordinal: string }) => `${ordinal} value column`,
                    icon: 'AnalyticsIcon',
                    withFilter: true,
                    isGroupingFieldDisabled: true,
                    optionGenerator: groupingFieldOptionGenerator,
                },
            },
            {
                widgetConfigurationProperty: 'pivotFields',
                component: FieldListEditor,
                configuration: {
                    tags: ['STRING_SUITABLE_FOR_GROUPING', DATE],
                    optionGenerator: groupingFieldOptionGenerator(),
                    labelFn: ({ ordinal }: { ordinal: string }) => `${ordinal} grouping field`,
                    icon: 'CabinetIcon',
                },
            },
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.numberOfDecimals(),
                    {
                        widgetConfigurationProperty: 'collapseOptions',
                        component: PivotTableCollapseEditor,
                        editorContextBuilder: (widgetConfig: PivotTableWidgetConfig) => ({
                            min: 1,
                            max: widgetConfig.pivotFields.length > 1 ? widgetConfig.pivotFields.length - 1 : 0,
                        }),
                        configuration: {
                            editorIcon: 'GroupExpandedIcon',
                            isIconAlwaysActive: true,
                        },
                    },
                ],
            },
        ],
    },
}

export default config
