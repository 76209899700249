import React from 'react'
import produce from 'immer'
import { setIn } from 'immutable'
import List from '../../Organisms/List/List'
import Button from '../../Atoms/Button/Button'
import ChartFieldListItem from './ChartFieldListItem'
import { Group, ModeTypes } from './ChartFieldListItem.types'
import Icon from '../../Atoms/Icon/Icon'
import { AxisConfig, ChartFieldListEditorProps, ChartGroupsType, Unit } from './ChartFieldListEditor.types'
import { FieldsConfigurationContext } from '../../Atoms/FieldsConfiguration/FieldsConfigurationContext'
import { getDefaultAnalysisTermWithField } from '../../Organisms/Widgets/DefaultConfigurations'
import { ValueField } from '../../Organisms/Widgets/WidgetModel'
import { SYMBOL_TYPE } from '../CalculatedFieldEditor/CalculatedFieldTokenEditor'

export default class ChartFieldListEditor extends React.PureComponent<ChartFieldListEditorProps> {
    context!: React.ContextType<typeof FieldsConfigurationContext>

    // TODO remove magic string valueField
    handleFieldChange = (index: number, propertyPath: Array<string>) => (value: any) => {
        // TODO how do we replace this with produce?
        let newValue = setIn(this.props.value, [index, ...propertyPath], value)

        if (propertyPath[0] === 'valueField') {
            const yAxis = this.getYAxis(this.props.value[index], value)
            newValue = setIn(newValue, [index, 'yAxis'], yAxis)
        }

        this.handleChange(newValue)
    }

    handleAddChartGroup = () => {
        // we want to auto-fill area, bar und line if missing
        const mode = this.getIfMissing('area') || this.getIfMissing('bar') || 'line'
        const valueField = {
            analysisTerm: getDefaultAnalysisTermWithField(mode === 'line' ? 'docCount' : 'sumTime'),
            filter: [],
            dateField: 'primaryDate',
        }
        this.handleChange([
            ...this.props.value,
            {
                yPivotField: mode === 'bar' ? 'projectName' : null,
                valueField,
                mode,
                maximumNumberOfStacks: 10,
                isStacked: true,
                showDots: mode === 'line',
                trendLineEntry: {
                    showTrendLine: true,
                    trendLineColor: '#ff0000', // must be long notation to work
                },
                interpolate: mode === 'line',
                sumUpAggregation: false,
                type: mode === 'area' ? 'step' : 'linear',
                yAxis: this.getYAxis(null, this.getFieldIdOfValueField(valueField)),
            },
        ])
    }

    handleChange = (chartGroups: ChartGroupsType) => {
        this.props.onChange(chartGroups)
    }

    getIfMissing = (type: ModeTypes) => !this.hasType(type) && type

    hasType = (type: ModeTypes) => Boolean(this.props.value.find((g) => g.mode === type))

    /**
     * Determines the appropriate y-Axis for the given value field
     * based in the current chart groups.
     *
     * @param chartGroupUnderChange chart group to ignore since it is currently updated
     * @param valueField next value field of the chart group under change
     * @returns {string} 'left' or 'right'
     */
    getYAxis = (chartGroupUnderChange: Group | null, valueField: string): 'left' | 'right' => {
        const current = this.getAxesConfig(this.props.value.filter((g) => g !== chartGroupUnderChange))
        // @ts-ignore
        return (
            current.axisPerUnit[this.getUnitOfField(valueField)] ||
            // @ts-ignore
            ['left', 'right'].find((a) => !current.axes[a]) ||
            'left'
        )
    }

    getFieldIdOfValueField = (valueField: ValueField) => {
        const value = valueField.analysisTerm.find((token) => token.type === SYMBOL_TYPE.VALUE_FIELD)
        return value ? value.value : 'noValueField'
    }

    /**
     *
     * @param valueField field ID to get the unit for
     * @returns the unit, '?' if unknown or '#' if number of documents
     */
    getUnitOfField = (valueField: string): Unit => {
        if (valueField && valueField !== 'docCount') {
            const field = this.context[valueField]
            return field ? (field.unit as Unit) || '?' : '?'
        }

        return '#'
    }

    getAxesConfig = (chartGroups: ChartGroupsType) =>
        chartGroups.reduce(
            (acc: AxisConfig, g: Group) => {
                acc.axisPerUnit[this.getUnitOfField(this.getFieldIdOfValueField(g.valueField))] = g.yAxis || 'left'
                acc.axes[g.yAxis] = true
                return acc
            },
            { axisPerUnit: {}, axes: {} }
        )

    handleRemoveChartGroup = (index: number) => () =>
        this.props.onChange(
            produce(this.props.value, (draft) => {
                draft.splice(index, 1)
            })
        )

    render() {
        const chartGroups = this.props.value
        return (
            <div className="chart-field">
                <List
                    children={chartGroups.map((group, i: number) => (
                        <ChartFieldListItem
                            key={`chart-group-${i}`}
                            widgetConfiguration={this.props.widgetConfiguration}
                            group={group}
                            index={i}
                            numberOfGroups={chartGroups.length}
                            onRemove={this.handleRemoveChartGroup(i)}
                            onChange={this.handleFieldChange}
                            onTriggerAutoCompletion={this.props.onTriggerAutoCompletion}
                        />
                    ))}
                />

                <Button onClick={this.handleAddChartGroup}>
                    <Icon name="LayerPlusIcon" label="Add Chart Element" color="green" />
                </Button>
            </div>
        )
    }
}

ChartFieldListEditor.contextType = FieldsConfigurationContext
