import React, { PureComponent } from 'react'
import classnames from 'classnames'
import produce from 'immer'
import { SingleDatePicker } from 'react-dates'
import moment, { Moment } from 'moment'
import WidgetHeader from '../../../Molecules/WidgetHeader/WidgetHeader'
import Button from '../../../Atoms/Button/Button'
import {
    ActiveFilters,
    QuickFilter,
    QuickFilters,
    QuickFilterWidgetProps,
    QuickFilterWidgetState,
} from './QuickFilterWidget.types'
import { FieldType, getFieldFilterTitle } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'
import { FieldsConfigurationContext } from '../../../Atoms/FieldsConfiguration/FieldsConfigurationContext'
import { renderMonthAndYearDropdown } from '../../DateSelector/DateSelector'
import QuickFilterSelect from './QuickFilterSelect'

export default class QuickFilterWidget extends PureComponent<QuickFilterWidgetProps, QuickFilterWidgetState> {
    context!: React.ContextType<typeof FieldsConfigurationContext>

    static displayName = 'QuickFilter'

    static contextType = FieldsConfigurationContext

    constructor(props: QuickFilterWidgetProps) {
        super(props)

        const defaultOptions: { [key: string]: Array<string> } = {}
        props.widgetConfiguration.filters.forEach((filter) => {
            defaultOptions[filter.uuid] = []
        })

        this.state = {
            options: {},
            focusedDateFilter: null,
        }
    }

    render() {
        const title = this.props.widgetConfiguration.title
        const className = classnames('widget', {
            'widget--has-header': title || this.props.onChangeTitle,
        })
        const filters = this.props.widgetConfiguration.filters
        const activeFilters = this.props.widgetState.activeFilters

        return (
            <div className={className}>
                <WidgetHeader title={title} onChangeTitle={this.props.onChangeTitle} />
                <div className={'quick-filter-widget'}>{this.renderQuickFilters(filters, activeFilters)}</div>
            </div>
        )
    }

    renderQuickFilters = (filters: QuickFilters, activeFilters: ActiveFilters) =>
        filters.map((filter) => {
            if (
                filter.filter.values.length > 0 ||
                filter.filter.operator === 'is empty' ||
                filter.filter.operator === 'is present'
            ) {
                return this.renderQuickFilterButton(filter, activeFilters)
            }

            if (this.isDateFilter(filter.filter)) {
                return this.renderQuickFilterDateSelect(filter, activeFilters)
            }

            return (
                <QuickFilterSelect
                    filter={filter}
                    key={'quick-filter-select' + filter.uuid}
                    value={activeFilters[filter.uuid]}
                    placeholder={this.getFilterTitle(filter)}
                    onChange={this.handleFilterSelectionChange(filter.uuid, activeFilters)}
                    activeFilters={activeFilters}
                    onLoadOptions={(value, callback) =>
                        this.props.requestSideData(
                            'suggestions',
                            { queryString: value, field: filter.filter.field, uuid: filter.uuid },
                            callback
                        )
                    }
                />
            )
        })

    renderQuickFilterButton = (filter: QuickFilter, activeFilters: ActiveFilters) => {
        const uuid = filter.uuid
        return (
            <Button
                key={'quick-filter-' + uuid}
                onClick={this.handleToggleFilter(uuid, activeFilters)}
                type={this.isActive(uuid) ? 'primary' : undefined}
                className="quick-filter quick-filter-button"
            >
                {this.getFilterTitle(filter)}
            </Button>
        )
    }

    renderQuickFilterDateSelect = (filter: QuickFilter, activeFilters: ActiveFilters) => {
        const date = activeFilters[filter.uuid] ? moment(parseInt(activeFilters[filter.uuid][0], 10)) : null

        return (
            <div className="quick-filter quick-filter--date">
                <SingleDatePicker
                    id={'quickfilter-date-picker-' + filter.uuid}
                    date={date}
                    focused={this.state.focusedDateFilter === filter.uuid}
                    onFocusChange={({ focused }) => this.setState({ focusedDateFilter: focused ? filter.uuid : null })}
                    onDateChange={this.handleDateFilter(filter.uuid, activeFilters)}
                    placeholder={this.getFilterTitle(filter)}
                    displayFormat="DD/MM/YYYY"
                    firstDayOfWeek={1}
                    hideKeyboardShortcutsPanel
                    daySize={36}
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    noBorder
                    showClearDate
                    verticalSpacing={10}
                    showDefaultInputIcon
                    renderMonthElement={({ month, onMonthSelect, onYearSelect }) =>
                        renderMonthAndYearDropdown(month, onMonthSelect, onYearSelect)
                    }
                />
            </div>
        )
    }

    getFilterTitle = (filter: QuickFilter) => {
        if (filter.title) {
            return filter.title
        }

        return getFieldFilterTitle(filter.filter, this.context)
    }

    handleFilterSelectionChange = (uuid: string, activeFilters: ActiveFilters) => (values: null | Array<string>) => {
        let newActiveFilters
        if (values === null || values.length === 0) {
            newActiveFilters = produce(activeFilters, (draft) => {
                delete draft[uuid]
            })
        } else {
            newActiveFilters = produce(activeFilters, (draft) => {
                draft[uuid] = values
            })
        }

        this.props.setWidgetState({ activeFilters: newActiveFilters })
    }

    handleToggleFilter = (uuid: string, activeFilters: ActiveFilters) => () => {
        let newActiveFilters
        if (this.isActive(uuid)) {
            newActiveFilters = produce(activeFilters, (draft) => {
                delete draft[uuid]
            })
        } else {
            newActiveFilters = produce(activeFilters, (draft) => {
                draft[uuid] = []
            })
        }

        this.props.setWidgetState({ activeFilters: newActiveFilters })
    }

    handleDateFilter = (filterId: string, activeFilters: ActiveFilters) => (value: Moment | null) => {
        let newActiveFilters

        if (value !== null) {
            newActiveFilters = produce(activeFilters, (draft) => {
                draft[filterId] = [String(value.startOf('day').valueOf())]
            })
        } else {
            newActiveFilters = produce(activeFilters, (draft) => {
                delete draft[filterId]
            })
        }

        this.props.setWidgetState({ activeFilters: newActiveFilters })
    }

    isActive = (uuid: string) => this.props.widgetState.activeFilters[uuid] !== undefined

    isDateFilter = (filter: FieldType) =>
        filter.field && this.context[filter.field] && this.context[filter.field].tag === 'DATE'
}
