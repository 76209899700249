import React, { memo, useState } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Icon from '../../Atoms/Icon/Icon'
import FileUpload, { File, UploadAction } from '../../Molecules/FileUpload/FileUpload'
import { RootState } from '../../../Redux/types'
import { actions, selectors } from '../../../Redux'
import { AppDispatch } from '../../../store'
import DragNDropContent from './DragNDropContent'
import { dispatchAddNewDashboardAction } from '../DashboardOverview/CreateNewDashboardButton'
import { ModalId } from '../../../StaticManifests/manifest.modals'
import {
    startDashboardConfigurationUploadAction,
    startImporterSourceFileUploadAction,
} from '../../../Sagas/FileUploadSaga'

const mapStateToProps = (state: RootState) => {
    const settings = selectors.UI.CurrentUser.settings(state)
    return {
        hasPermissions: selectors.UI.CurrentUser.hasPermissions(state),
        canManageDashboards: settings?.canManageDashboards ?? false,
        canManageImporters: settings?.canManageImporters ?? false,
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onStartImporterUpload: (
        file: File,
        handleProgress: (v: number, m: number) => void,
        handleDone: (result: File | null) => void
    ) => {
        dispatch(startImporterSourceFileUploadAction(file, handleProgress, handleDone))
    },
    onUploadFileNotSupported: (modalId: ModalId) => dispatch(actions.UI.Modal.show(modalId)),
    onUploadImporterFinished: (uploadedFile: File) => {
        dispatch(actions.Data.Importer.setInitialStepIndex(2))
        dispatch(actions.Data.Importer.setOnCancelRoute('/'))
        dispatch(push('/importer/new'))
        dispatch(actions.Data.Importer.create('csvFileDataSource'))
        dispatch(actions.Data.Importer.editConfiguration('source', uploadedFile))
    },
    onAddNewDashboard: () => dispatchAddNewDashboardAction(dispatch),
    onCreateNewImporter: () => {
        dispatch(push('/importer/new'))
    },
    onStartDashboardConfigurationUpload: (
        file: File,
        handleProgress: (v: number, m: number) => void,
        handleDone: (result: File | null) => void
    ) => {
        dispatch(startDashboardConfigurationUploadAction(file, handleProgress, handleDone))
    },
    onUploadDashboardConfigurationFinished: () => window.location.reload(),
})

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const WelcomeScreenHeader = (props: Props) => {
    const [isUploadingImporter, setIsUploadingImporter] = useState(false)
    const [isUploadingDashboardConfig, setIsUploadingDashboardConfig] = useState(false)

    if (!props.canManageImporters && !props.canManageDashboards) {
        return null
    }

    const handleImporterUploadStart: UploadAction = (file, handleProgress, handleDone) => {
        setIsUploadingImporter(true)
        props.onStartImporterUpload(file, handleProgress, handleDone)
    }

    const handleDashboardConfigUploadStart: UploadAction = (file, handleProgress, handleDone) => {
        setIsUploadingDashboardConfig(true)
        props.onStartDashboardConfigurationUpload(file, handleProgress, handleDone)
    }

    return (
        <header className="welcome-screen__header">
            {props.canManageImporters && (
                <div className="welcome-screen__header__option">
                    <FileUpload
                        onChange={(file) => props.onUploadImporterFinished(file)}
                        onTypeNotSupported={() =>
                            props.onUploadFileNotSupported('welcomeScreenOnlyCsvFileForImporterSupported')
                        }
                        onStartUpload={handleImporterUploadStart}
                        content={
                            <DragNDropContent
                                iconColor={'primary'}
                                heading={'Upload or Drag'}
                                subtitle={'your data source file'}
                                isUploading={isUploadingImporter}
                            />
                        }
                        contentWhenDragging={
                            <DragNDropContent
                                iconColor={'green'}
                                heading={'Drag and Release'}
                                subtitle={'your file here'}
                                isUploading={isUploadingImporter}
                            />
                        }
                        fileType="csv"
                    />
                </div>
            )}

            {props.canManageImporters && (
                <div className="welcome-screen__header__option" onClick={props.onCreateNewImporter} role="button">
                    <Icon name="ConnectIcon" color="primary" />
                    <div className="welcome-screen__header__option__text">
                        <h3>Connect</h3>
                        <p>your data source</p>
                    </div>
                </div>
            )}

            {props.canManageDashboards && (
                <div className="welcome-screen__header__option" onClick={props.onAddNewDashboard} role="button">
                    <Icon name="DashboardIcon" color="green" />
                    <div className="welcome-screen__header__option__text">
                        <h3>Create a Dashboard</h3>
                        <p>analyse your data</p>
                    </div>
                </div>
            )}

            {props.canManageDashboards && (
                <div className="welcome-screen__header__option">
                    <FileUpload
                        onChange={props.onUploadDashboardConfigurationFinished}
                        onTypeNotSupported={() =>
                            props.onUploadFileNotSupported(
                                'welcomeScreenOnlyExplyFileForDashboardConfigurationSupported'
                            )
                        }
                        onStartUpload={handleDashboardConfigUploadStart}
                        content={
                            <DragNDropContent
                                iconColor="green"
                                heading="Upload a Dashboard"
                                subtitle="from a local file"
                                isUploading={isUploadingDashboardConfig}
                            />
                        }
                        contentWhenDragging={
                            <DragNDropContent
                                iconColor="green"
                                heading="Drag and Release"
                                subtitle="your file here"
                                isUploading={isUploadingDashboardConfig}
                            />
                        }
                        fileType="exply"
                    />
                </div>
            )}
        </header>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(WelcomeScreenHeader))
