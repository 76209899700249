import React, { lazy, Suspense } from 'react'
import { ScatterPlotWidgetProps } from './ScatterPlotWidget.types'

const ScatterPlotWidget = lazy(() => import(/* webpackChunkName: "ScatterPlot" */ './ScatterPlotWidget'))

const LazyScatterPlotWidget: React.FC<ScatterPlotWidgetProps> = (props) => (
    <Suspense fallback="">
        <ScatterPlotWidget {...props} />
    </Suspense>
)

export default LazyScatterPlotWidget
