import * as Editing from './ImporterEditing'
import * as TempoCloud from './TempoCloud'

export const actions = {
    ...Editing.actions,
    ...TempoCloud.actions,
}

export const reducerArray = [Editing.reducer, TempoCloud.reducer]

export const selectors = {
    ...Editing.selectors,
    ...TempoCloud.selectors,
}
