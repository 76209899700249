import React, { forwardRef, useRef, useState } from 'react'
import classnames from 'classnames'
import TextInput from '../../Atoms/TextInput/TextInput'
import Icon from '../../Atoms/Icon/Icon'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

export type WidgetHeaderFunctions = {
    onChangeTitle?: (newTitle: string) => void
}

type WidgetHeaderProps = WidgetHeaderFunctions & {
    title?: string
    input?: React.ReactNode
    children?: React.ReactNode
}

const WidgetHeader = forwardRef((props: WidgetHeaderProps, ref: React.Ref<HTMLElement>) => {
    const [title, setTitle] = useState(props.title)
    const titleEditRef = useRef<HTMLInputElement>(null)

    const className = classnames('widget__header', {
        'widget__header--with-controls': props.children,
    })

    const handleChangeTitle = (title: string) => {
        setTitle(title)
    }

    const handlePressEdit = () => {
        if (titleEditRef && titleEditRef.current) {
            titleEditRef.current.select()
        }
    }

    const handleSaveTitle = () => {
        if (props.onChangeTitle && title !== undefined) {
            props.onChangeTitle(title)
        }
    }

    const renderWidgetTitleEditor = () => (
        <header className="widget__header">
            <div className="widget__header-controls">
                <Icon size="small" name="PenIcon" onClick={handlePressEdit} title="Change Widget Title" />
            </div>

            <TextInput
                onChange={handleChangeTitle}
                onBlur={handleSaveTitle}
                value={title}
                hideBorder={true}
                ref={titleEditRef}
            />
        </header>
    )

    if (props.onChangeTitle) {
        return renderWidgetTitleEditor()
    }

    if (!props.title && !props.input && !props.children) {
        return null
    }

    return (
        <header className={className} ref={ref}>
            {props.input ? (
                <div className="widget__header-input">{props.input}</div>
            ) : (
                <HoverTooltip
                    isVisible={props.title !== '' && props.title !== undefined}
                    header={props.title}
                    className={'widget__header-title-tooltip'}
                >
                    <div className="widget__header-title">{props.title}</div>
                </HoverTooltip>
            )}
            <div className="widget__header-controls">{props.children}</div>
        </header>
    )
})

export default WidgetHeader
