import { getDefaultAnalysisTermWithField } from '../DefaultConfigurations'
import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

const createDefaultConfiguration = () => ({
    pivotFields: [
        {
            field: 'projectName',
        },
    ],
    valueField: {
        analysisTerm: getDefaultAnalysisTermWithField('sumTime'),
        filter: [] as Array<FieldType>,
        dateField: 'primaryDate',
    },
    numberOfDecimals: 2,
    showCellLabels: true,
    showLegend: false,
    title: 'Tree Map',
})

export default createDefaultConfiguration

export const TreeMapConfigurationInitializerForWidgetExplorer = ({
    valueFields,
    categoryFields,
    dateFields,
    filters,
}: {
    valueFields: Array<string>
    categoryFields: Array<string>
    dateFields: Array<string>
    filters: Array<FieldType>
}) => {
    const defaultConfiguration = createDefaultConfiguration()

    defaultConfiguration.valueField = {
        analysisTerm: getDefaultAnalysisTermWithField(valueFields[0]),
        filter: filters,
        dateField: dateFields[0],
    }

    defaultConfiguration.pivotFields = categoryFields.map((field) => ({ field }))

    return defaultConfiguration
}
