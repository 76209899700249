import React, { useEffect, useState } from 'react'
import Loader from '../Loader/Loader'
import { DashboardLoadingStates } from '../../../Redux/Data/types'

/**
 * After discussing Loading Screens / Spinners in the UX Guild, we wanted to try out a new loading "experience" in exply:
 * Always show the loading spinner for at least 700ms, even if the dashboard loads faster, to avoid flickering of the loading
 * screen.
 *
 * We decided against not showing the loading screen for short loading times (<700ms) with a delay, because the changes in
 * the UI might not be obvious enough for the user to understand that things have changed (the widgets changed so fast but the
 * difference is so small that the difference is barely noticeable, if there's no loading spinner indicating that something is happening)
 * (but it does feel very snappy...)
 *
 */

const LoaderOverlay: React.FC<{
    loadingState: DashboardLoadingStates
}> = (props) => {
    const [showLoader, setShowLoader] = useState(true)
    const [showLoaderSince, setShowLoaderSince] = useState(new Date().getTime())
    const minimumLoaderTime = 700

    useEffect(() => {
        // start showing the spinner
        if (props.loadingState === DashboardLoadingStates.LOADING) {
            setShowLoader(true)
            setShowLoaderSince(new Date().getTime())
        } else {
            // how long have we been showing the spinner?
            const loaderIsSpinningFor = new Date().getTime() - showLoaderSince
            if (loaderIsSpinningFor > minimumLoaderTime) {
                // long enough
                setShowLoader(false)
            } else {
                // need to wait a couple more ms...
                setTimeout(() => setShowLoader(false), minimumLoaderTime - loaderIsSpinningFor)
            }
        }
    }, [props.loadingState])

    return showLoader ? (
        <div className="loader-overlay">
            <Loader isSmall={false} />
        </div>
    ) : null
}

export default React.memo(LoaderOverlay)
