import { all, put, select, takeLatest } from 'redux-saga/effects'
import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { LOCATION_CHANGE, push } from 'connected-react-router'
import { AxiosResponse } from 'axios'

import { actions, selectors } from '../../Redux'
import { AccessMode, SharedDashboardId } from '../../Redux/Data/types'
import axios from '../Utility/axios'
import ApplicationMode from '../../ApplicationMode'
import { DashboardsRoute, SharedDashboardRoute } from '../../routes'
import { CurrentModal } from '../../Redux/UI/types'
import { StartSharingPayload } from '../../Redux/Data/Dashboards/ShareDashboard'

export const startSharingAction = createAction(
    'Data/Dashboards/Sharing/StartSharing',
    (payload: StartSharingPayload) => ({
        payload,
    })
)

export const stopSharingAction = createAction('Data/Dashboards/Sharing/StopSharing', (id: SharedDashboardId) => ({
    payload: id,
}))

function* handleStartSharing(action: PayloadAction<StartSharingPayload>) {
    try {
        const response: AxiosResponse = yield axios({
            method: 'post',
            url: 'edit-shared-dashboard/start-sharing',
            data: action.payload,
        })

        if (response.status >= 400) {
            yield put(actions.UI.Notification.show({ id: 'dashboardShareFailed' }))
        } else {
            yield put(actions.Data.Dashboards.ShareDashboard.addSharedDashboard(action.payload))
        }
    } catch (e) {
        console.error(e)
        yield put(actions.UI.Notification.show({ id: 'dashboardShareFailed' }))
    }
}

function* handleStopSharing(action: PayloadAction<SharedDashboardId>) {
    try {
        const response: AxiosResponse = yield axios({
            method: 'post',
            url: 'edit-shared-dashboard/stop-sharing/' + action.payload,
        })

        if (response.status >= 400) {
            yield put(actions.UI.Notification.show({ id: 'dashboardStopShareFailed' }))
        } else {
            yield put(actions.Data.Dashboards.ShareDashboard.removeSharedDashboard(action.payload))
        }
    } catch (e) {
        console.error(e)
        yield put(actions.UI.Notification.show({ id: 'dashboardStopShareFailed' }))
    }
}

function* redirectToSharedDashboardOnInit(action: PayloadAction<{ isFirstRendering: boolean }>) {
    const accessMode = selectors.Data.System.accessMode(yield select())
    const appMode = selectors.Data.System.applicationMode(yield select())
    const dashboardToOpen = selectors.Data.System.openDashboardOnStart(yield select())
    if (
        action.payload.isFirstRendering &&
        dashboardToOpen &&
        (accessMode === AccessMode.SHARED_DASHBOARD || appMode === ApplicationMode.JIRACloud)
    ) {
        yield put(
            push(
                (accessMode === AccessMode.SHARED_DASHBOARD ? SharedDashboardRoute : DashboardsRoute) +
                    '/' +
                    dashboardToOpen
            )
        )
    }
}

/**
 * Get the internal sharing link for dashboards in JIRA Cloud from the backend, since we can't use window.location (iframe)
 * and can't use the atlassian js api (redirect in iframe in prod). So the backend is the only one who knows the base url.
 */
function* setDashboardSharingLinkForJiraCloud(action: PayloadAction<CurrentModal>) {
    const appMode = selectors.Data.System.applicationMode(yield select())
    if (action.payload.modalId === 'dashboardShare' && appMode === ApplicationMode.JIRACloud) {
        try {
            // atlassian js API doesn't work in an iframe where we redirected, so we have to ask the backend (which has the base url and menu entry link)
            const response: AxiosResponse = yield axios({
                method: 'get',
                url: 'jira-cloud/shared-dashboard/internal-sharing-link',
            })

            if (response.status >= 400) {
                yield put(actions.UI.Notification.show({ id: 'dashboardShareFailed' }))
            } else {
                yield put(actions.Data.System.setInternalDashboardSharingLink(response.data))
            }
        } catch (e) {
            console.error(e)
            yield put(actions.UI.Notification.show({ id: 'dashboardShareFailed' }))
        }
    }
}

export default function* ShareDashboardSaga() {
    yield all([
        takeLatest(startSharingAction.type, handleStartSharing),
        takeLatest(stopSharingAction.type, handleStopSharing),
        // we need to listen to (the first) location change here, because apparently the UI / Routes need to be rendered
        // once before we can push a new path
        takeLatest(LOCATION_CHANGE, redirectToSharedDashboardOnInit),
        takeLatest(actions.UI.Modal.startConfirmation.type, setDashboardSharingLinkForJiraCloud),
    ])
}
