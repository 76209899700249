import { defaultAnalysisTerm, getDefaultAnalysisTermWithField } from '../DefaultConfigurations'
import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

const createDefaultConfiguration = (type: 'rowChart' | 'barChart') => () => ({
    pivotField: 'projectName',
    stackingField: null as string | null,
    valueFields: [
        {
            dateField: 'primaryDate',
            analysisTerm: defaultAnalysisTerm,
        },
    ],
    maximumNumberOfBars: 12,
    maximumNumberOfStacks: 12,
    numberOfDecimals: 2,
    useShortLabels: true,
    orientationIsVertical: false,
    sortAsc: false,
    sortKey: 'valueSortKey',
    topOrFlop: 'desc',
    showLegend: false,
    title: type === 'rowChart' ? 'Row Chart' : 'Bar Chart',
    referenceLines: [],
    showFurtherValues: true,
    showPercentages: false,
    percentageOf: 'valueField',
    stackUpValues: false,
    normaliseStackedBars: false,
    percentageOfStackPer: 'valueFieldTotal',
    yAxisLabelWidth: 70,
})

export default createDefaultConfiguration

export const RowBarChartConfigurationInitializerForWidgetExplorer =
    (type: 'rowChart' | 'barChart') =>
    ({
        valueFields,
        categoryFields,
        dateFields,
        filters,
    }: {
        valueFields: Array<string>
        categoryFields: Array<string>
        dateFields: Array<string>
        filters: Array<FieldType>
    }) => {
        const defaultConfiguration = createDefaultConfiguration(type)()

        defaultConfiguration.valueFields = valueFields.map((field) => ({
            filter: filters,
            dateField: dateFields[0],
            analysisTerm: getDefaultAnalysisTermWithField(field),
        }))

        defaultConfiguration.pivotField = categoryFields[0]

        if (categoryFields.length > 1) {
            defaultConfiguration.stackingField = categoryFields[1]
        }

        return defaultConfiguration
    }
