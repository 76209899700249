import React from 'react'
import { formatNumberToString } from '../../../../Utility/NumberFormator'
import { CustomTooltipProps, HoveredGroupsWithItems, Item, PayloadEntry } from './CustomTooltip.types'
import PopperPortal from './TooltipPortal'

const CustomTooltip: React.FC<CustomTooltipProps> = (props) => {
    const { payload, label } = props
    const separator = ':'

    // IMPORTANT: we need a robust handling if the configuration does not provide the number of decimals.
    // This can happen for older dashboards, where this feature was not present. At some point it might
    // make sense to think about server-side migrations and or validations.
    const decimals = props.decimals === undefined ? 0 : props.decimals

    const getHoveredGroupsWithItems = (hoveredBarData: Array<PayloadEntry>) => {
        if (hoveredBarData) {
            const groups: { [key: string]: Item } = {}
            hoveredBarData.forEach((data) => {
                // this regex might be outdated / unnecessary, since we overwrite the getHoveredGroupsWithItems from most
                // widgets which use the CustomTooltip... Also this is awful :)
                const keyMatch = /(\d+)\.\d+|([A-Za-z0-9]+)(_.*)?/.exec(data.name)
                const key = keyMatch ? (keyMatch[1] ? keyMatch[1] : keyMatch[2]) : data.name
                // Sometimes we can't give in the data directly with the appropriate unit, so we have the getUnit function
                // to set it from outside
                const unit = props.getUnit ? props.getUnit(key) : data.unit || ''

                const entry = {
                    color: data.color,
                    name: props.stackLabelFn!(data),
                    value: data.value,
                    unit,
                }

                if (!(key in groups)) {
                    groups[key] = {
                        entries: [],
                        hint: undefined,
                    }
                }

                // Skip entries with missing names (e.g. trend lines)
                if (entry.name !== null && entry.name !== undefined) {
                    // @ts-ignore TS cannot infer that we catch entry.name === undefined above...
                    groups[key].entries.push(entry)
                }
            })
            return groups
        }

        return {}
    }

    const groupsWithEntries =
        payload && payload.length && props.getHoveredGroupsWithItems
            ? props.getHoveredGroupsWithItems(payload)
            : getHoveredGroupsWithItems(payload!)

    const renderGroups = (groups: HoveredGroupsWithItems, decimals: number, separator: string) => {
        const entryKeys = Object.keys(groups)
        return entryKeys.map((entryKey, i) => {
            const group = groups[entryKey]
            const hint = group.hint
            const entries = group.entries
            // the unit is the same for every entry in the group
            const unit = entries[0] && entries[0].unit ? entries[0].unit : ''

            const groupLabel = props.groupLabelFn ? props.groupLabelFn(entryKey) : null
            const groupStyle = props.groupStyleFn ? props.groupStyleFn(entryKey) : 'box'
            const groupTotal = props.groupTotalFn
                ? props.groupTotalFn(entryKey, entries, decimals, unit, groupLabel)
                : null

            return (
                <div key={`tooltip-group-${i}`} className="recharts-tooltip-group">
                    <div className="recharts-tooltip-group__entry-wrapper">
                        {renderEntries(entries, decimals, separator, groupStyle)}
                        {hint && <div className="recharts-tooltip-group__entry-hint">{hint}</div>}
                    </div>

                    {entries.length > 1 ? (
                        <React.Fragment>
                            {groupTotal && <p className="recharts-tooltip-total">{groupTotal}</p>}
                            {entryKeys.length > 1 && i < entryKeys.length - 1 ? <hr /> : ''}
                        </React.Fragment>
                    ) : (
                        ''
                    )}
                    {props.showSeparatorAfterGroup &&
                        props.showSeparatorAfterGroup.includes(parseInt(entryKey, 10)) && <hr />}
                </div>
            )
        })
    }

    const renderEntries = (entries: Array<PayloadEntry>, decimal: number, separator: string, groupStyle: string) =>
        entries.map((entry, i) => {
            if (entry.value === undefined) {
                return null
            }

            return (
                <li className="recharts-tooltip-item" key={`tooltip-item-${i}`}>
                    {renderColorIndicator(groupStyle, entry.color)}
                    {entry.name ? <span className="recharts-tooltip-item-name">{entry.name}</span> : null}
                    {entry.name ? <span className="recharts-tooltip-item-separator">{separator}</span> : null}
                    {props.showOnlyPercentage && entry.percentage ? (
                        <>
                            <span className="recharts-tooltip-item-value">
                                {formatValue(entry.percentage, decimals)}%
                            </span>
                            <span className="recharts-tooltip-item-percentage">
                                | {formatValue(entry.value, decimals)}
                            </span>
                            <span className="recharts-tooltip-item-unit">{entry.unit || ''}</span>
                        </>
                    ) : (
                        <>
                            <span className="recharts-tooltip-item-value">{formatValue(entry.value, decimals)}</span>
                            <span className="recharts-tooltip-item-unit">{entry.unit || ''}</span>
                            {props.showPercentage && entry.percentage ? (
                                <span className="recharts-tooltip-item-percentage">
                                    | {formatValue(entry.percentage, decimals)}%
                                </span>
                            ) : null}
                        </>
                    )}
                </li>
            )
        })

    const renderColorIndicator = (style: string, color: string) => (
        <span className="custom-tooltip__item__color-indicator" style={{ backgroundColor: color }} />
    )

    const formatValue = (numeric: number, decimals: number) => {
        if (Number.isNaN(numeric)) {
            return numeric
        }

        return formatNumberToString(numeric, decimals)
    }

    return (
        <PopperPortal active={props.payload && props.payload.length > 0}>
            <div className="recharts-tooltip-wrapper">
                <div className="recharts-default-tooltip">
                    {props.alwaysShowLabel || Object.keys(groupsWithEntries).length > 1 ? (
                        <React.Fragment>
                            <p className="recharts-tooltip-label">{label}</p>
                            <hr />
                        </React.Fragment>
                    ) : (
                        ''
                    )}
                    {renderGroups(groupsWithEntries, decimals, separator)}
                </div>
            </div>
        </PopperPortal>
    )
}

export default React.memo(CustomTooltip)
