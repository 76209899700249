import React, { useEffect, useRef, useState } from 'react'
import {
    BarResult,
    RowChartSelection,
    RowChartWidgetData,
    RowChartWidgetProps,
    SortOption,
} from './RowChartWidget.types'
import WidgetHeader from '../../../Molecules/WidgetHeader/WidgetHeader'
import Icon from '../../../Atoms/Icon/Icon'
import Select from '../../../Atoms/Select/Select'
import { EMPTY_VALUE, FURTHER_VALUES, SUM } from '../Constants'
import { SelectOption } from '../../../Atoms/Select/Select.types'
import { useClickOutsideCallback } from '../../../../Utility/Hooks'

const RowChartWidgetHeader = (
    props: RowChartWidgetProps & {
        onChangeSort: (sortKey: string, sortAsc: boolean) => void
        sortKey: string
        sortAsc: boolean
    }
) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [isSortOpen, setIsSortOpen] = useState(false)
    const [filterOptions, setFilterOptions] = useState<Array<SelectOption>>([])
    const ref = useRef(null)

    // load options on mount
    useEffect(() => {
        handleFilterInputChange('')
    }, [])

    const handleCLickOutside = () => {
        setIsSortOpen(false)
        setIsFilterOpen(false)
    }

    useClickOutsideCallback(ref, handleCLickOutside)

    const handleClickSortIcon = () => {
        setIsSortOpen(!isSortOpen)
        setIsFilterOpen(false)
    }
    const handleClickFilterIcon = () => {
        setIsFilterOpen(!isFilterOpen)
        setIsSortOpen(false)
    }

    const handleChangeSort = (stringValue: string) => {
        const { key, asc } = barSortOptionMappings[stringValue]
        props.onChangeSort(key, asc)
        setIsSortOpen(false)
    }

    const handleChangeFilter = (value: string) => {
        if (value) {
            let selection: RowChartSelection = props.widgetState.selection
            if (!selection.includes(value)) {
                selection = [...selection, value]
                props.setWidgetState({ selection })
            }

            setIsFilterOpen(false)
        }
    }

    const handleFilterInputChange = (value: string) => {
        props.requestSideData('suggestions', { queryString: value || '' }, (suggestions) => {
            setFilterOptions(suggestions.map((i: string) => ({ value: i, label: i })))
        })
    }

    const renderInputs = () => {
        if (isFilterOpen) {
            return (
                <Select
                    options={filterOptions}
                    onInputChange={handleFilterInputChange}
                    onChange={handleChangeFilter}
                    autoFocus={true}
                    openMenuOnFocus={true}
                />
            )
        }

        if (isSortOpen) {
            return (
                <Select
                    value={props.sortKey + ' ' + (props.sortAsc ? 'asc' : 'desc')}
                    options={sortOptions}
                    onChange={handleChangeSort}
                    autoFocus={true}
                    openMenuOnFocus={true}
                />
            )
        }

        return null
    }

    return (
        <WidgetHeader
            title={props.widgetConfiguration.title}
            input={renderInputs()}
            onChangeTitle={props.onChangeTitle}
            ref={ref}
        >
            <Icon name="SortIcon" onClick={handleClickSortIcon} title="sort" size="small" color="light" />
            <Icon name="FilterIcon" onClick={handleClickFilterIcon} title="filter" color="light" size="small" />
        </WidgetHeader>
    )
}

const sortOptions = [
    {
        value: 'key asc',
        label: 'Name (asc)',
    },
    {
        value: 'key desc',
        label: 'Name (desc)',
    },
    {
        value: 'valueSortKey asc',
        label: 'Value (asc)',
    },
    {
        value: 'valueSortKey desc',
        label: 'Value (desc)',
    },
]

const barSortOptionMappings: SortOption = {
    'key asc': {
        asc: true,
        key: 'key',
    },
    'key desc': {
        asc: false,
        key: 'key',
    },
    'valueSortKey asc': {
        asc: true,
        key: 'valueSortKey',
    },
    'valueSortKey desc': {
        asc: false,
        key: 'valueSortKey',
    },
}

const sorterHelper = (key: string, asc: boolean) => {
    return (a: BarResult, b: BarResult) => {
        if (a.key === SUM) {
            return 1
        }

        if (b.key === SUM) {
            return -1
        }

        if (a.key === EMPTY_VALUE) {
            return 1
        }

        if (b.key === EMPTY_VALUE) {
            return -1
        }

        if (a.key === FURTHER_VALUES) {
            return 1
        }

        if (b.key === FURTHER_VALUES) {
            return -1
        }

        let x: string | number
        let y: string | number

        if (key === 'valueSortKey') {
            x = a.valueSortKey
            y = b.valueSortKey
        } else {
            x = a.key
            y = b.key
        }

        if (typeof x === 'string' && typeof y === 'string') {
            x = x.toLowerCase()
            y = y.toLowerCase()
        }

        if (x < y) {
            return asc ? -1 : 1
        }

        if (x > y) {
            return asc ? 1 : -1
        }

        return 0
    }
}

export const sortValues = (key: string | undefined, asc: boolean, data: RowChartWidgetData) => {
    const returnData = { ...data }
    if (key !== undefined) {
        returnData.chartData = [...data.chartData].sort(sorterHelper(key, asc))
    }

    return returnData
}

export default RowChartWidgetHeader
