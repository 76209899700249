import React from 'react'
import ConfirmationModal from '../Components/Organisms/Modal/ConfirmationModal/ConfirmationModal'
import DemoModeNotificationModalContainer from '../Containers/Shared/Modals/DemoModeNotificationModalContainer'
import HintModal from '../Components/Organisms/Modal/HintModal/HintModal'
import DuplicateDashboardModal from '../Components/Organisms/Modal/DuplicateDashboardModal/DuplicateDashboardModal'
import DashboardImageModal from '../Containers/Shared/Modals/DashboardImageModal/DashboardImageModal'
import PasswordModal from '../Containers/Shared/Modals/PasswordModal'
import LinkModal from '../Containers/Shared/Modals/LinkModal'
import UploadLicenseModal from '../Components/Organisms/Modal/UploadLicenseModal/UploadLicenseModal'
import QuotaDetails from '../Components/Organisms/Widgets/QuotaTileWidget/QuotaDetails'
import { ButtonType } from '../Components/Atoms/Button/Button.types'
import DuplicateWidgetModal from '../Containers/Shared/Modals/DuplicateWidgetModal/DuplicateWidgetModal'
import SaveImporterMappingStepModal from '../Components/Organisms/Modal/SaveCSVImporterModal/SaveImporterMappingStepModal'
import ShareDashboardModal from '../Containers/Shared/Modals/ShareDashboardModal/ShareDashboardModal'

export type ModalType = keyof typeof modalTypes

export const modalTypes = {
    confirmation: ConfirmationModal,
    demoModeNotification: DemoModeNotificationModalContainer,
    hint: HintModal,
    duplicateDashboard: DuplicateDashboardModal,
    dashboardImage: DashboardImageModal,
    password: PasswordModal,
    link: LinkModal,
    uploadLicense: UploadLicenseModal,
    duplicateWidget: DuplicateWidgetModal,
    saveCSVImporter: SaveImporterMappingStepModal,
    shareDashboard: ShareDashboardModal,
}

const modals = {
    blockDestructiveOperation: {
        type: 'confirmation',
        confirmText: 'Thanks, got it!',
        confirmButtonType: 'primary',
        blocking: true,
    },
    demoOperationDisabled: {
        type: 'demoModeNotification',
    },
    sessionExpired: {
        type: 'confirmation',
        message: 'Your session has expired and you need to log in again.',
        confirmText: 'Refresh and log in',
        confirmButtonType: 'primary',
        blocking: true,
    },
    welcomeScreenOnlyCsvFileForImporterSupported: {
        type: 'hint',
        message: 'Currently we only support csv files as data sources',
    },
    welcomeScreenOnlyExplyFileForDashboardConfigurationSupported: {
        type: 'hint',
        message: 'We only support .exply files as dashboard configuration',
    },
    widgetGroupRemove: {
        type: 'confirmation',
        message: 'Are you sure you want to remove this widget group?',
        confirmText: 'Remove',
        confirmButtonType: 'warning',
    },
    duplicateWidget: {
        type: 'duplicateWidget',
    },
    leaveUnsaved: {
        type: 'confirmation',
        message: 'You have unsaved changes.',
        denyText: 'Discard changes',
        denyButtonType: 'warning',
        confirmText: 'Save changes',
        confirmButtonType: 'primary',
    },
    dashboardDelete: {
        type: 'confirmation',
        message: 'Are you sure you want to remove this dashboard?',
        cancelText: 'Keep dashboard',
        cancelButtonType: 'secondary',
        confirmText: 'Remove dashboard',
        confirmButtonType: 'primary-warning',
    },
    dashboardCancelEdit: {
        type: 'confirmation',
        message: 'Are you sure you want to discard all changes to this dashboard?',
        cancelText: 'Keep editing',
        cancelButtonType: 'secondary',
        confirmText: 'Discard Changes',
        confirmButtonType: 'warning',
    },
    dashboardDuplicate: {
        type: 'duplicateDashboard',
    },
    dashboardIcon: {
        type: 'dashboardImage',
    },
    dashboardShare: {
        type: 'shareDashboard',
    },
    importerDelete: {
        type: 'confirmation',
        message: 'Are you sure you want to remove this importer?',
        cancelText: 'Keep importer',
        cancelButtonType: 'secondary',
        confirmText: 'Remove importer',
        confirmButtonType: 'primary-warning',
    },
    importerClearAll: {
        type: 'confirmation',
        message: 'Are you sure you want to remove all imported data from your system?',
        cancelText: 'Keep imported data',
        cancelButtonType: 'secondary',
        confirmText: 'Remove all data',
        confirmButtonType: 'warning',
    },
    importerClearSingleImporterData: {
        type: 'confirmation',
        message: 'Are you sure you want to remove all imported data by this importer?',
        cancelText: 'Keep imported data',
        cancelButtonType: 'secondary',
        confirmText: 'Remove imported data',
        confirmButtonType: 'warning',
    },
    userManagementSetPassword: {
        type: 'password',
    },
    userManagementResetPasswordLink: {
        type: 'link',
    },
    userManagementRemoveOrDisableSelf: {
        type: 'confirmation',
        message: 'This is you! Are you really sure you want to do this? You cannot fix this by yourself!',
        cancelText: 'I want to stay.',
        cancelButtonType: 'secondary',
        confirmText: 'Bye, log me out!',
        confirmButtonType: 'primary-warning',
    },
    userManagementRemoveGroup: {
        type: 'confirmation',
        message: 'Are you sure you want to remove this group?',
        denyText: 'Keep group',
        confirmText: 'Remove group',
        denyButtonType: 'warning',
        confirmButtonType: 'primary-warning',
    },
    userManagementRemoveUser: {
        type: 'confirmation',
        message: 'Are you sure you want to remove this user?',
        confirmText: 'Remove user',
        confirmButtonType: 'primary-warning',
    },
    userManagementUserLimitReached: {
        type: 'confirmation',
        message:
            'We love to see that you like to invite yet another user to Exply. Unfortunately your free-forever version ran out of users.',
        cancelText: 'Understood',
        cancelButtonType: 'secondary',
        confirmText: 'Buy new license',
        confirmButtonType: 'primary',
    },
    licenseWarning: {
        type: 'confirmation',
        cancelText: 'Keep going',
        confirmText: 'Buy new license',
        confirmButtonType: 'primary',
    },
    licenseBlock: {
        type: 'confirmation',
        blocking: true,
        confirmText: 'Buy new license',
        confirmButtonType: 'primary',
    },
    systemLicenseUpload: {
        type: 'uploadLicense',
    },
    setupWizardContinueWithoutDashboard: {
        type: 'confirmation',
        message: 'Are you sure you want to start without example dashboards?',
        cancelText: 'Add example Dashboards',
        cancelButtonType: 'primary',
        confirmText: 'Start from scratch',
    },
    quotaTileQuotaDetails: {
        type: 'confirmation',
        message: QuotaDetails,
        confirmText: 'Thanks, got it!',
        confirmButtonType: 'primary',
        noCancel: true,
    },
    extensionDelete: {
        type: 'confirmation',
        message: 'Are you sure you want to remove this extension?',
        confirmText: 'Remove',
        confirmButtonType: 'warning',
    },
    saveCSVImporter: {
        type: 'saveCSVImporter',
    },
}

export type ModalId = keyof typeof modals

export type ModalConfiguration = {
    type: ModalType
    // The Component Type is only used for QuotaDetails, but there are a lot of abstractions and it's difficult to type
    message?: string | React.ComponentType<any>
    confirmText?: string
    confirmButtonType?: ButtonType
    cancelText?: string
    cancelButtonType?: ButtonType
    denyText?: string
    denyButtonType?: ButtonType
    noCancel?: boolean
    blocking?: boolean
}

export default modals as Record<ModalId, ModalConfiguration>
