import RowChartWidget from './LazyRowChartWidget'
import RowBarChartWidgetStateInitializer from './RowChartWidget.init'
import RowBarChartConfigurationInitializer, {
    RowBarChartConfigurationInitializerForWidgetExplorer,
} from './RowChartWidget.configurationInitializer'
import FieldListEditor from '../../../Molecules/FieldListEditor/FieldListEditor'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import WidgetSortingPropField from '../../../Molecules/WidgetSortingPropField'
import NumberPicker from '../../../Atoms/NumberPicker/NumberPicker'
import {
    flag,
    groupingFieldOptionGenerator,
    maximumNumberOfStacks,
    number,
    numberOfDecimals,
    referenceLine,
    selectOneOf,
    stackingField,
    topOrFlop,
    WidgetManifestConfiguration,
} from '../../../../widgetManifestBuildingBlocks'
// @ts-ignore file extension
import RowChartWidgetIcon from './RowChartWidget.Icon.tsx'
import { RowChartWidgetConfig, RowChartWidgetData } from './RowChartWidget.types'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'

type RowChartType = 'rowChart' | 'barChart'

export const multiValueLegendPositionOptions = [
    {
        value: 'top',
        label: 'top',
    },
    {
        value: 'bottom',
        label: 'bottom',
    },
]

export default (type: RowChartType) => {
    const config: WidgetManifestConfiguration = {
        name: type === 'rowChart' ? 'Row Chart' : 'Bar Chart',
        component: RowChartWidget,
        capabilities: {
            min: {
                dateFields: 1,
                valueFields: 1,
                categoryFields: 1,
            },
            max: {
                dateFields: 1,
                valueFields: 100,
                categoryFields: 2,
            },
        },
        configurationValidations: (widgetConfig: RowChartWidgetConfig) => {
            if (widgetConfig.valueFields === undefined || widgetConfig.valueFields.length === 0) {
                return widgetProblems.noValueField
            }
        },
        renderRequirements: (widgetData?: RowChartWidgetData) => {
            const chartData = widgetData?.chartData

            if (!chartData || chartData.length === 0) {
                return widgetProblems.noData
            }
        },
        icon: RowChartWidgetIcon(type === 'rowChart'),
        stateInitializationFunction: RowBarChartWidgetStateInitializer,
        configurationInitializer: RowBarChartConfigurationInitializer(type),
        configurationInitializerForWidgetExplorer: RowBarChartConfigurationInitializerForWidgetExplorer(type),

        editors: {
            data: [
                {
                    widgetConfigurationProperty: 'valueFields',
                    component: FieldListEditor,
                    configuration: {
                        icon: 'AnalyticsIcon',
                        tags: ['NUMBER'],
                        clearable: true,
                        labelFn: ({ ordinal, index }: { ordinal: string; index: number }) =>
                            `${index === 0 ? 'Primary' : ordinal} value`,
                        withFilter: true,
                        optionGenerator: groupingFieldOptionGenerator,
                        includePivotField: true,
                    },
                },
                stackingField(),
                topOrFlop(),
            ],
            bottomTabs: [
                {
                    name: 'Display Options',
                    editors: [
                        {
                            component: FormFieldWithLabel,
                            configuration: {
                                editor: WidgetSortingPropField,
                                editorIcon: 'SortingIcon',
                                isIconAlwaysActive: true,
                            },
                        },
                        {
                            widgetConfigurationProperty: 'maximumNumberOfBars',
                            component: NumberPicker,
                            editorContextBuilder: () => ({
                                label: 'Max Number of Bars',
                                placeholder: '20',
                                min: 2,
                                max: 500,
                                step: 1,
                            }),
                            configuration: {
                                editorIcon: 'NumBarsIcon',
                                isIconAlwaysActive: true,
                            },
                        },
                        numberOfDecimals(),
                        flag('showPercentages', 'Show Percentage', 'PercentagesIcon'),
                        selectOneOf(
                            'percentageOf',
                            'Percentage of',
                            [
                                {
                                    label: 'Value',
                                    value: 'valueField',
                                },
                                {
                                    label: 'Category',
                                    value: 'pivotField',
                                },
                            ],
                            false,
                            'PercentagesIcon'
                        ),
                        flag('showSumBar', 'Show Sum Bar', 'ShowRowSumIcon'),
                        flag(
                            'showFurtherValues',
                            'Show Further Values',
                            type === 'rowChart' ? 'FurtherValuesRowsIcon' : 'FurtherValuesIcon'
                        ),
                        flag('showOnlyBarGroupsWithPrimaryValue', 'Leading Primary Value', 'LeadingPrimaryValueIcon'),
                    ],
                },
                {
                    name: 'Stack Options',
                    editors: [
                        flag('showStackingFieldsAsSeparateBars', 'Separate Stacked Bars', 'SeparateStackingBarsIcon'),
                        flag('stackUpValues', 'Stack Multiple Values', 'StackUpMultipleValuesIcon'),
                        maximumNumberOfStacks(),
                        flag('normaliseStackedBars', 'Normalise Stacked Bars'),
                        selectOneOf(
                            'percentageOfStackPer',
                            'Percentage of Stacks Per',
                            [
                                {
                                    label: 'Bar',
                                    value: 'bar',
                                },
                                {
                                    label: 'Total Sum',
                                    value: 'valueFieldTotal',
                                },
                            ],
                            false
                        ),
                    ],
                },
                {
                    name: 'Label Options',
                    editors: [
                        flag(
                            'showLegend',
                            'Legend by Default',
                            'LegendIcon',
                            'The legend is not displayed by default if the width of the widget is too small.'
                        ),
                        flag('useShortLabels', 'Short Labels', 'ShortLabelsIcon'),
                        number('yAxisLabelWidth', 'Label Spacing', '70', 50, 500, 1, undefined, 'LabelSpacingIcon'),
                        flag('showMultiValueLegend', 'Show Multi Value Legend', 'LegendIcon'),
                        selectOneOf(
                            'multiValueLegendPosition',
                            'Multi Value Legend Position',
                            multiValueLegendPositionOptions,
                            false,
                            'LegendIcon',
                            false,
                            'Only available without stacking'
                        ),
                    ],
                },
                {
                    name: 'Reference Lines',
                    editors: [referenceLine()],
                },
            ],
        },
    }

    return config
}
