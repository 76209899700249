import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Layout as LayoutItem, Layouts, Responsive, WidthProvider } from 'react-grid-layout'
import { Collapse } from 'react-collapse'
import { actions, selectors } from '../../../Redux'
import Icon from '../../../Components/Atoms/Icon/Icon'
import WidgetGroupFilters from '../../../Components/Organisms/WidgetGroupFilters/WidgetGroupFilters'
import Button from '../../../Components/Atoms/Button/Button'
import { RootState } from '../../../Redux/types'
import Widget from '../Widget/Widget'
import {
    CoordinatesAndSize,
    DashboardId,
    WidgetConfiguration as WidgetType,
    WidgetGroupFilter,
    WidgetGroupId,
    WidgetId,
} from '../../../Redux/Data/types'
import { AppDispatch } from '../../../store'
import widgetTypes from '../../../StaticManifests/manifest.widgetTypes'
import { createAndAddNewWidgetAction } from '../../../Sagas/DashboardSagas/CreateAndAddNewWidgetSaga'
import WidgetGroupHeader from './WidgetGroupHeader'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

const mapStateToProps = (state: RootState, props: WidgetGroupOwnProps) => {
    return {
        widgetGroupConfiguration: selectors.Data.Dashboards.widgetGroupById(state, props.widgetGroupId),
        widgetIds: selectors.Data.Dashboards.widgetIdsForWidgetGroup(state, props.widgetGroupId),
        widgetConfigurations: selectors.Data.Dashboards.widgetsForWidgetGroup(state, props.widgetGroupId),
        globalWidgetGroupId: selectors.Data.Dashboards.globalWidgetGroupId(state, props.dashboardId),
        inEditMode: selectors.Data.Dashboards.DashboardEditMode.isDashboardEditModeActive(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeCoordinatesAndSizeOfWidgets: (newCoordinatesAndSizesForWidgets: Record<WidgetId, CoordinatesAndSize>) =>
        dispatch(
            actions.Data.Dashboards.WidgetGroupEditMode.changeCoordinatesAndSizeOfWidgets(
                newCoordinatesAndSizesForWidgets
            )
        ),
    onUpdateFilters: (widgetGroupId: WidgetGroupId, filter: WidgetGroupFilter) =>
        dispatch(actions.Data.Dashboards.WidgetGroupEditMode.updateFilters({ widgetGroupId, filter })),
    addWidget: (widgetGroupId: WidgetGroupId) => dispatch(createAndAddNewWidgetAction({ widgetGroupId })),
    triggerAutoCompletion: (type: any, value: any, exclude: any, callback: any) =>
        dispatch(actions.UI.Autocompletion.fetch(type, value, exclude, callback)),
})

const AddWidgetGroupButton: React.FC<{
    onClick: () => void
}> = (props) => {
    return (
        <button className="add-widget-group-button" onClick={props.onClick}>
            <Icon name="PlusIcon" title="add new widget group" /> Add new Widget Group
        </button>
    )
}

type WidgetGroupReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type Layout = Array<LayoutItem>

type WidgetGroupOwnProps = {
    widgetGroupId: WidgetGroupId
    dashboardId: DashboardId
    isPreview?: boolean
}

type WidgetGroupProps = WidgetGroupOwnProps & WidgetGroupReduxProps

const WidgetGroup: React.FC<WidgetGroupProps> = (props) => {
    const { widgetGroupConfiguration } = props
    if (widgetGroupConfiguration === undefined) {
        return null
    }

    const isGlobalWidgetGroup = props.globalWidgetGroupId === props.widgetGroupId
    const inEditMode = props.inEditMode && !props.isPreview

    const createLargeLayout = useCallback(
        (widgets: Array<WidgetType>): Array<LayoutItem> => {
            return widgets.map((widget) => {
                const widgetType = widget.type
                const widgetTypeDef = widgetTypes[widgetType]
                const widgetHasHeader = Boolean(widget.configuration.title)

                if (!widgetTypeDef) {
                    // catch non-existent widgetTypes, usually for dashboards from dev branches
                    return {
                        i: widget.id,
                        x: widget.coordinatesAndSize.x,
                        y: widget.coordinatesAndSize.y,
                        w:
                            widget.coordinatesAndSize.width ||
                            3 /* due to a already fixed bug in the WidgetEditModeSaga the width might not be set in a persistent dashboard */,
                        h:
                            widget.coordinatesAndSize.height ||
                            4 /* due to a already fixed bug in the WidgetEditModeSaga the height might not be set in a persistent dashboard */,
                        minW: 3,
                        minH: 4,
                        maxW: Infinity,
                        maxH: Infinity,
                    }
                }

                // check if there are min Sizes and respect Header for height
                const minWidth = widgetTypeDef.minWidth || 3
                const minHeight = widgetTypeDef.minHeight
                    ? widgetHasHeader
                        ? widgetTypeDef.minHeight + 1
                        : widgetTypeDef.minHeight
                    : 4
                const maxWidth = widgetTypeDef.maxWidth || Infinity
                const maxHeight = widgetTypeDef.maxHeight
                    ? widgetHasHeader
                        ? widgetTypeDef.maxHeight + 1
                        : widgetTypeDef.maxHeight
                    : Infinity

                return {
                    i: widget.id,
                    x: widget.coordinatesAndSize.x,
                    y: widget.coordinatesAndSize.y,
                    w: widget.coordinatesAndSize.width
                        ? Math.min(Math.max(widget.coordinatesAndSize.width, minWidth), maxWidth)
                        : 3 /* due to a already fixed bug in the WidgetEditModeSaga the width might not be set in a persistent dashboard */,
                    h: widget.coordinatesAndSize.height
                        ? Math.min(Math.max(widget.coordinatesAndSize.height, minHeight), maxHeight)
                        : 4 /* due to a already fixed bug in the WidgetEditModeSaga the height might not be set in a persistent dashboard */,
                    minW: minWidth,
                    minH: minHeight,
                    maxW: maxWidth,
                    maxH: maxHeight,
                }
            })
        },
        [props.widgetConfigurations]
    )

    const createMediumLayout = useCallback(
        (layout: Array<LayoutItem>) => {
            // create new copy of array to not sort large layout
            const sortedLayout = layout.slice().sort((a, b) => {
                const yA = a.y
                const yB = b.y

                if (yA !== yB) {
                    return yA - yB
                }

                const xA = a.x
                const xB = b.x
                return xA - xB
            })

            let nextY = 0
            return sortedLayout.map((widget) => {
                const smallWidget = Object.create(widget)
                smallWidget.x = 0
                smallWidget.w = 12
                smallWidget.y = nextY

                nextY += widget.h
                return smallWidget
            })
        },
        [props.widgetConfigurations]
    )

    const [largeLayout, setLargeLayout] = useState<Layout>(createLargeLayout(props.widgetConfigurations))
    const [mediumLayout, setMediumLayout] = useState<Layout>(createMediumLayout(largeLayout))

    // update layout when new widgets get added or config changes
    useEffect(() => {
        const newLargeLayout = createLargeLayout(props.widgetConfigurations)
        const newMediumLayout = createMediumLayout(newLargeLayout)
        setLargeLayout(newLargeLayout)
        setMediumLayout(newMediumLayout)
    }, [createLargeLayout, createMediumLayout, props.widgetConfigurations.length])

    const className = classnames('widget-group', 'widget-group--position-' + props.widgetGroupConfiguration.position, {
        'widget-group--outlined': inEditMode,
        'widget-group--is-global': isGlobalWidgetGroup,
    })

    const classNameFooter = classnames('widget-group__footer', {
        'widget-group__footer--extended': props.widgetIds.length === 0,
    })

    const handleGridLayoutChange = useCallback(
        (newLayout: Layout, allLayouts: Layouts) => {
            if (!inEditMode) {
                return
            }

            setLargeLayout(allLayouts.lg)
            setMediumLayout(allLayouts.md)

            props.changeCoordinatesAndSizeOfWidgets(
                allLayouts.lg.reduce((acc, block) => {
                    return {
                        ...acc,
                        [block.i]: {
                            x: block.x,
                            y: block.y,
                            width: block.w,
                            height: block.h,
                        },
                    }
                }, {} as Record<WidgetId, CoordinatesAndSize>)
            )
        },
        [inEditMode]
    )

    const handleAddWidget = () => {
        props.addWidget(widgetGroupConfiguration.id)
    }

    const layouts = {
        lg: largeLayout,
        md: mediumLayout,
    }

    const gridItems = useMemo(
        () =>
            props.widgetConfigurations.map((widgetConfiguration) => (
                <div key={widgetConfiguration.id} className="widget-group__grid-item">
                    <Widget
                        key={widgetConfiguration.id}
                        widgetId={widgetConfiguration.id}
                        isPreview={props.isPreview}
                    />
                </div>
            )),
        [props.widgetIds, inEditMode, props.isPreview]
    )

    const handleUpdateFilters = (filters: WidgetGroupFilter) => {
        props.onUpdateFilters(props.widgetGroupId, filters)
    }

    return (
        <section className={className} role="group">
            <WidgetGroupHeader
                widgetGroupId={props.widgetGroupId}
                dashboardId={props.dashboardId}
                isPreview={props.isPreview}
            />
            <div className="widget-group__content">
                <Collapse isOpened={inEditMode}>
                    <WidgetGroupFilters
                        filters={widgetGroupConfiguration.filter}
                        updateFilters={handleUpdateFilters}
                        triggerAutoCompletion={props.triggerAutoCompletion}
                    />
                </Collapse>

                <ResponsiveReactGridLayout
                    className="widget-group__grid"
                    breakpoints={{ lg: 800, md: 0 }}
                    cols={{ lg: 24, md: 12 }}
                    rowHeight={36}
                    onLayoutChange={handleGridLayoutChange}
                    isDraggable={inEditMode}
                    compactType="vertical"
                    isResizable={inEditMode}
                    useCSSTransforms={true}
                    layouts={layouts}
                    resizeHandles={['s', 'w', 'e', 'sw', 'se']}
                >
                    {gridItems}
                </ResponsiveReactGridLayout>

                <Collapse isOpened={inEditMode}>
                    <footer className={classNameFooter}>
                        <div className="widget-group__footer-inner">
                            {inEditMode && (
                                <Button className="add-widget-button" onClick={handleAddWidget} type="primary">
                                    <Icon color="white" name="PlusIcon" /> Add new Widgets
                                </Button>
                            )}
                        </div>
                    </footer>
                </Collapse>
            </div>
        </section>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetGroup)
export { AddWidgetGroupButton }
