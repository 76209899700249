// IMPORTANT: date ranges are handled the following way:
// startDate (inclusive) -> endDate (exclusive)
//
// examples:
// 'yesterday' -> 23.02.2017 00:00:00 - 24.02.2017 00:00:00
// 'last 30min (current time = 11:30)' -> 23.02.2017 11:00 - 23.02.2017 11:30
import moment from 'moment'

const defaultWorkingDays = [1, 2, 3, 4, 5]
export const DEFAULT_WORKING_DAYS = defaultWorkingDays
export const DEFAULT_DATE_RANGE = 'thisWeek'
export const DATE_RANGE_CATEGORIES = ['days', 'weeks', 'months', 'years', 'quarters', 'other']

const dateRanges: Record<DateRangeId, DateRangeDefinition> = {
    today: {
        label: () => 'Today',
        category: 'days',
        getDateRange() {
            const startDate = moment().startOf('day')
            const endDate = startDate.clone().add(1, 'days').startOf('day')
            return { startDate, endDate }
        },
    },
    yesterday: {
        label: () => 'Yesterday',
        category: 'days',
        getDateRange() {
            const startDate = moment().subtract(1, 'days').startOf('day')
            const endDate = startDate.clone().add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    dayBeforeYesterday: {
        label: () => 'Day before Yesterday',
        category: 'days',
        getDateRange() {
            const startDate = moment().subtract(2, 'days').startOf('day')
            const endDate = startDate.clone().add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastWorkingDay: {
        label: () => 'Last Working Day',
        category: 'days',
        getDateRange: (config) => {
            const workingDays = config?.workingDays || defaultWorkingDays

            const lastWorkingDay = moment()
            if (workingDays.length !== 0) {
                do {
                    lastWorkingDay.add(-1, 'days')
                } while (!workingDays.includes(lastWorkingDay.day()))
            }

            // moment starts the week on sunday, we on monday, so we have to do some calculations
            const startDate = lastWorkingDay.startOf('day')
            const endDate = lastWorkingDay.clone().add(1, 'days').startOf('day')
            return { startDate, endDate }
        },
    },
    lastSevenDays: {
        label: () => 'Last Seven Days',
        category: 'days',
        getDateRange() {
            const startDate = moment().subtract(8, 'days')
            const endDate = moment().startOf('day')

            return { startDate, endDate }
        },
    },
    lastXDays: {
        label: (showLastXDays) => `Last ${showLastXDays || 'x '} days`,
        category: 'days',
        getDateRange: (config) => {
            const days = config?.showLastXDays || 30
            const startDate = moment().add(-days, 'days')
            const endDate = moment()

            return { startDate, endDate }
        },
    },
    thisWeek: {
        label: () => 'This Week',
        category: 'weeks',
        getDateRange() {
            const startDate = moment().startOf('isoWeek')
            const endDate = startDate.clone().endOf('isoWeek').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastWeek: {
        label: () => 'Last Week',
        category: 'weeks',
        getDateRange() {
            const startDate = moment().subtract(1, 'week').startOf('isoWeek')
            const endDate = startDate.clone().endOf('isoWeek').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastTwoWeeks: {
        label: () => 'Last Two Weeks',
        category: 'weeks',
        getDateRange() {
            const startDate = moment().subtract(2, 'week').startOf('isoWeek')
            const endDate = startDate.clone().add(1, 'week').endOf('isoWeek').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastFourWeeks: {
        label: () => 'Last Four Weeks',
        category: 'weeks',
        getDateRange() {
            const startDate = moment().subtract(4, 'week').startOf('isoWeek')
            const endDate = startDate.clone().add(3, 'week').endOf('isoWeek').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    penultimateWeek: {
        label: () => 'Penultimate Week',
        category: 'weeks',
        getDateRange() {
            const startDate = moment().subtract(2, 'week').startOf('isoWeek')
            const endDate = startDate.clone().endOf('isoWeek').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    thisMonth: {
        label: () => 'This Month',
        category: 'months',
        getDateRange() {
            const startDate = moment().startOf('month')
            const endDate = startDate.clone().endOf('month').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastMonth: {
        label: () => 'Last Month',
        category: 'months',
        getDateRange() {
            const startDate = moment().subtract(1, 'month').startOf('month')
            const endDate = startDate.clone().endOf('month').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastTwoMonths: {
        label: () => 'Last Two Months',
        category: 'months',
        getDateRange() {
            const startDate = moment().subtract(2, 'month').startOf('month')
            const endDate = startDate.clone().add(1, 'months').endOf('month').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastThreeMonths: {
        label: () => 'Last Three Months',
        category: 'months',
        getDateRange() {
            const startDate = moment().subtract(3, 'month').startOf('month')
            const endDate = startDate.clone().add(2, 'months').endOf('month').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastSixMonths: {
        label: () => 'Last Six Months',
        category: 'months',
        getDateRange() {
            const startDate = moment().subtract(6, 'month').startOf('month')
            const endDate = startDate.clone().add(5, 'months').endOf('month').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastTwelveMonths: {
        label: () => 'Last Twelve Months',
        category: 'months',
        getDateRange() {
            const startDate = moment().subtract(12, 'month').startOf('month')
            const endDate = startDate.clone().add(11, 'months').endOf('month').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    penultimateMonth: {
        label: () => 'Penultimate Month',
        category: 'months',
        getDateRange() {
            const startDate = moment().subtract(2, 'month').startOf('month')
            const endDate = startDate.clone().endOf('month').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    thisYear: {
        label: () => 'This Year',
        category: 'years',
        getDateRange() {
            const startDate = moment().startOf('year')
            const endDate = startDate.clone().endOf('year').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastYear: {
        label: () => 'Last Year',
        category: 'years',
        getDateRange() {
            const startDate = moment().subtract(1, 'year').startOf('year')
            const endDate = startDate.clone().endOf('year').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastTwoYears: {
        label: () => 'Last Two Years',
        category: 'years',
        getDateRange() {
            const startDate = moment().subtract(2, 'year').startOf('year')
            const endDate = startDate.clone().add(1, 'year').endOf('year').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    penultimateYear: {
        label: () => 'Penultimate Year',
        category: 'years',
        getDateRange() {
            const startDate = moment().subtract(2, 'year').startOf('year')
            const endDate = startDate.clone().endOf('year').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    thisQuarter: {
        label: () => 'This Quarter',
        category: 'quarters',
        getDateRange() {
            const startDate = moment().startOf('quarter')
            const endDate = startDate.clone().endOf('quarter').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    lastQuarter: {
        label: () => 'Last Quarter',
        category: 'quarters',
        getDateRange() {
            const startDate = moment().subtract(1, 'quarter').startOf('quarter')
            const endDate = startDate.clone().endOf('quarter').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    penultimateQuarter: {
        label: () => 'Penultimate Quarter',
        category: 'quarters',
        getDateRange() {
            const startDate = moment().subtract(2, 'quarter').startOf('quarter')
            const endDate = startDate.clone().endOf('quarter').add(1, 'days').startOf('day')

            return { startDate, endDate }
        },
    },
    allTime: {
        label: () => 'All Time',
        category: 'other',
        getDateRange() {
            return { startDate: null, endDate: null }
        },
    },
}

// These dateRanges are for the beta feature "forecasts", which is disabled as of now
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const betaDateRanges = {
    next14Days: {
        label: () => 'Next 14 Days',
        className: 'forecast',
        getDateRange() {
            return {
                startDate: moment().add(1, 'day'),
                endDate: moment().add(14, 'day'),
                forecastPeriod: 14,
            }
        },
    },
    next30Days: {
        label: () => 'Next 30 Days',
        className: 'forecast',
        getDateRange() {
            return {
                startDate: moment().add(1, 'day'),
                endDate: moment().add(30, 'day'),
                forecastPeriod: 30,
            }
        },
    },
    next90Days: {
        label: () => 'Next 90 Days',
        className: 'forecast',
        getDateRange() {
            return {
                startDate: moment().add(1, 'day'),
                endDate: moment().add(90, 'day'),
                forecastPeriod: 90,
            }
        },
    },
    nextYear: {
        label: () => 'Next Year',
        className: 'forecast',
        getDateRange() {
            return {
                startDate: moment().add(1, 'day'),
                endDate: moment().add(365, 'day'),
                forecastPeriod: 365,
            }
        },
    },
}

type DateRangeDefinition = {
    label: (insertInLabel?: number | string) => string
    getDateRange: (config?: { workingDays?: Array<number>; showLastXDays?: number }) => {
        startDate: moment.Moment | null
        endDate: moment.Moment | null
    }
    category: string
}

export type DateRangeId =
    | 'today'
    | 'yesterday'
    | 'dayBeforeYesterday'
    | 'lastWorkingDay'
    | 'lastSevenDays'
    | 'lastXDays'
    | 'thisWeek'
    | 'lastWeek'
    | 'lastTwoWeeks'
    | 'lastFourWeeks'
    | 'penultimateWeek'
    | 'thisMonth'
    | 'lastMonth'
    | 'lastTwoMonths'
    | 'lastThreeMonths'
    | 'lastSixMonths'
    | 'lastTwelveMonths'
    | 'penultimateMonth'
    | 'thisYear'
    | 'lastYear'
    | 'lastTwoYears'
    | 'penultimateYear'
    | 'thisQuarter'
    | 'lastQuarter'
    | 'penultimateQuarter'
    | 'allTime'

export default dateRanges
