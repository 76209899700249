import React from 'react'
import { ModalActions, ModalContent } from '../../../Molecules/Modal/Modal'
import Button from '../../../Atoms/Button/Button'
import { ImporterConfiguration, PostProcessorConfiguration } from '../../../../Redux/Data/types'
import { Mapping } from '../../../Molecules/ImporterFieldMapping/ImporterFieldMappingEditor'

type Parameters = {
    configuration: ImporterConfiguration | PostProcessorConfiguration
}

type Props = {
    onConfirmModal: (parameters?: Parameters) => void
    onCancelModal: () => void
    parameters: Parameters
}

const SaveImporterMappingStepModal = (props: Props) => {
    const columns = props.parameters.configuration.configuration?.mapping?.mappings || []
    const columnCount = columns.length
    const importedColumns = columns.filter((mapping: Mapping) => mapping.isActive && mapping.isConfirmed).length
    const ignoredColumns = columnCount - importedColumns
    return (
        <ModalContent className="save-csv-importer-modal">
            <p>
                {importedColumns === columnCount && 'All '}
                <span className="imported-columns">{importedColumns}</span>{' '}
                {importedColumns === 1 ? 'column ' : 'columns'} will be imported.{' '}
                {importedColumns !== columnCount && (
                    <>
                        <span className="ignored-columns">{ignoredColumns}</span>
                        {ignoredColumns === 1 ? ' column will not be imported.' : ' columns will not be imported.'}
                    </>
                )}
            </p>
            <ModalActions>
                <Button type="warning" onClick={props.onCancelModal}>
                    Cancel
                </Button>
                <Button type="primary" onClick={() => props.onConfirmModal()}>
                    Finalize Import
                </Button>
            </ModalActions>
        </ModalContent>
    )
}

export default SaveImporterMappingStepModal
