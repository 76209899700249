import React, { lazy, Suspense } from 'react'
import { TreeMapWidgetProps } from './TreeMapWidget.types'

const LazyWidget = lazy(() => import(/* webpackChunkName: "TreeMapWidget" */ './TreeMapWidget'))

const LazyWidgetWrapper: React.FC<TreeMapWidgetProps> = (props) => (
    <Suspense fallback="">
        <LazyWidget {...props} />
    </Suspense>
)

export default LazyWidgetWrapper
