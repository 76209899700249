import React from 'react'
import { connect } from 'react-redux'
import produce from 'immer'

import uuid from 'uuid'

import { actions } from '../../../Redux'
import { closeWizard, saveExampleDashboard } from '../../../Sagas/SetupWizardSagas'

// @ts-ignore
import exampleDashboard1 from '../ExampleDashboards/exampleDashboard1'
import exampleDashboard1ScreenShot from '../ExampleDashboards/exampleDashboard1_Screenshot.png'
// @ts-ignore
import exampleDashboard2 from '../ExampleDashboards/exampleDashboard2'
import exampleDashboard2ScreenShot from '../ExampleDashboards/exampleDashboard2_Screenshot.png'
// @ts-ignore
import exampleDashboard3 from '../ExampleDashboards/exampleDashboard3'
import exampleDashboard3ScreenShot from '../ExampleDashboards/exampleDashboard3_Screenshot.png'
// @ts-ignore
import exampleDashboard4 from '../ExampleDashboards/exampleDashboard4'
import exampleDashboard4ScreenShot from '../ExampleDashboards/exampleDashboard4_Screenshot.png'
import Button from '../../../Components/Atoms/Button/Button'
import { AppDispatch } from '../../../store'
import { ApiDashboardConfiguration } from '../../../Redux/Data/types'
import ButtonWithTooltip from '../../../Components/Molecules/HoverTooltip/ButtonWithTooltip'

const dashboards = [
    {
        title: 'Work Logs Overview',
        configuration: exampleDashboard1,
        screenshot: exampleDashboard1ScreenShot,
    },
    {
        title: 'Issues and Worked Hours',
        configuration: exampleDashboard2,
        screenshot: exampleDashboard2ScreenShot,
    },
    {
        title: 'Project Dashboard',
        configuration: exampleDashboard3,
        screenshot: exampleDashboard3ScreenShot,
    },
    {
        title: 'Member & Project Overview',
        configuration: exampleDashboard4,
        screenshot: exampleDashboard4ScreenShot,
    },
]

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        addDashboardToFavourites: (dashboardUuid: string) =>
            dispatch(actions.UI.FavouriteDashboards.add(dashboardUuid)),
        closeWizard: () => dispatch(closeWizard()),
        continueWithoutDashboard: () =>
            dispatch(
                actions.UI.Modal.startConfirmation({
                    modalId: 'setupWizardContinueWithoutDashboard',
                    confirmationCallback: () => dispatch(closeWizard()),
                })
            ),
        saveExampleDashboard: (dashboard: ApiDashboardConfiguration) => dispatch(saveExampleDashboard(dashboard)),
    }
}

type AddDashboardsStepProps = ReturnType<typeof mapDispatchToProps>
type AddDashboardsStepState = {
    sampleDashboardsChecked: Array<boolean>
}

class AddDashboardsStep extends React.PureComponent<AddDashboardsStepProps, AddDashboardsStepState> {
    constructor(props: AddDashboardsStepProps) {
        super(props)

        this.state = {
            sampleDashboardsChecked: dashboards.map(() => false),
        }
    }

    handleAddDashboard = (i: number, template: ApiDashboardConfiguration) => () => {
        this.setState(
            produce(this.state, (draft) => {
                draft.sampleDashboardsChecked[i] = true
            })
        )

        const configuration = { ...template, uuid: uuid() }
        this.props.saveExampleDashboard(configuration)
        // add "random" dashboards of the examples as favorites, to demonstrate the favorite function to new users
        if (i % 2 === 0) {
            this.props.addDashboardToFavourites(configuration.uuid)
        }
    }

    handleCloseWizard = () => {
        let numberOfDashboardsAdded = 0
        this.state.sampleDashboardsChecked.forEach((v) => {
            numberOfDashboardsAdded = v ? numberOfDashboardsAdded + 1 : numberOfDashboardsAdded
        })

        if (numberOfDashboardsAdded === 0) {
            this.props.continueWithoutDashboard()
        } else {
            this.props.closeWizard()
        }
    }

    render() {
        return (
            <div className="setup-wizard__example-dashboard-wrapper">
                {dashboards.map((dashboard, i) => (
                    <div
                        key={'setup-wizard-example-dashboard' + dashboard.configuration.uuid}
                        className="setup-wizard__dashboard-preview"
                    >
                        <h3>{dashboard.title}</h3>

                        <img src={dashboard.screenshot} alt={'Screenshot of ' + dashboard.title + ' dashboard'} />

                        <ButtonWithTooltip
                            isDisabled={this.state.sampleDashboardsChecked[i]}
                            header="Dashboard already added"
                            onClick={this.handleAddDashboard(i, dashboard.configuration)}
                            type="primary"
                        >
                            Add Dashboard
                        </ButtonWithTooltip>
                    </div>
                ))}

                <div className="setup-wizard__next-button">
                    <Button onClick={this.handleCloseWizard} type="primary">
                        Let's get started!
                    </Button>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(AddDashboardsStep)
