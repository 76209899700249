import { put, select, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { createAction } from '@reduxjs/toolkit'
import { actions } from '../../Redux'
import axios from '../Utility/axios'
import { ApiDashboardConfiguration } from '../../Redux/Data/types'
import { currentlyEditedDashboardId as selectCurrentlyEditedDashboardId } from '../../Redux/Data/Dashboards/BaseSelectors'
import { getApiDashboardConfigurationForId } from './Utility'

export function* saveDashboard(dashboard: ApiDashboardConfiguration) {
    const response: AxiosResponse = yield axios({
        method: 'post',
        url: 'dashboard/' + dashboard.uuid,
        data: dashboard,
    })

    return response.status < 400
}

export const saveCurrentlyEditedDashboardAndExitEditMode = createAction(
    'SAGA/SAVE_CURRENTLY_EDITED_DASHBOARD_AND_EXIT_EDIT_MODE'
)

export function* SaveDashboardToServerSaga() {
    yield takeLatest(saveCurrentlyEditedDashboardAndExitEditMode.type, function* () {
        const currentlyEditedDashboardId = selectCurrentlyEditedDashboardId(yield select())

        if (currentlyEditedDashboardId === undefined) {
            return
        }

        const apiDashboardConfiguration: ApiDashboardConfiguration = yield getApiDashboardConfigurationForId(
            currentlyEditedDashboardId
        )

        try {
            const dashboardSaveWasSuccessful: boolean = yield saveDashboard(apiDashboardConfiguration)

            if (dashboardSaveWasSuccessful) {
                yield put(actions.Data.Dashboards.DashboardEditMode.disableEditMode())
            } else {
                yield put(actions.UI.Notification.show({ id: 'dashboardSaveFailed' }))
            }
        } catch (e) {
            yield put(actions.UI.Notification.show({ id: 'dashboardSaveFailed' }))
        }
    })
}
