import React from 'react'
import ApplicationHeader from './ApplicationHeader/ApplicationHeader'

type Props = {
    children: any
}

const DataSources: React.FC<Props> = (props) => (
    <div className="importers">
        <ApplicationHeader pageTitle="Data Sources" />
        {props.children}
    </div>
)

export default DataSources
