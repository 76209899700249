import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DashboardId, DataState, SharedDashboard, SharedDashboardId } from '../types'
import { RootState } from '../../types'
import { actionTypes as system } from '../../System'

export type StartSharingPayload = {
    dashboardId: DashboardId
    password: string
    id: string
    allowDownload: boolean
}

const shareDashboardSlice = createSlice({
    name: 'Data/Dashboards/Share',
    initialState: {} as DataState,
    extraReducers: {
        [system.INIT]: (state, action) => {
            state.dashboards.normalizedDashboardConfig.sharedDashboards = {
                allIds: [],
                byId: {},
            }
            action.payload.initialState.data.sharedDashboards?.forEach((sharedDashboard: SharedDashboard) => {
                state.dashboards.normalizedDashboardConfig.sharedDashboards.allIds.push(sharedDashboard.id)
                state.dashboards.normalizedDashboardConfig.sharedDashboards.byId[sharedDashboard.id] = sharedDashboard
            })
        },
    },
    reducers: {
        addSharedDashboard: (state, action: PayloadAction<StartSharingPayload>) => {
            const dashboardId = action.payload.dashboardId
            const id = action.payload.id
            state.dashboards.normalizedDashboardConfig.sharedDashboards.byId[id] = {
                dashboardId,
                id,
                isPasswordProtected: action.payload.password.length > 0,
                isDownloadAllowed: action.payload.allowDownload,
            }
            state.dashboards.normalizedDashboardConfig.sharedDashboards.allIds.push(id)
        },
        removeSharedDashboard: (state, action: PayloadAction<SharedDashboardId>) => {
            state.dashboards.normalizedDashboardConfig.sharedDashboards.allIds =
                state.dashboards.normalizedDashboardConfig.sharedDashboards.allIds.filter((id) => id !== action.payload)
            delete state.dashboards.normalizedDashboardConfig.sharedDashboards.byId[action.payload]
        },
    },
})

export const actions = shareDashboardSlice.actions
export const reducer = shareDashboardSlice.reducer

const getAllSharedDashboards = (state: RootState) =>
    Object.values(state.Data.dashboards.normalizedDashboardConfig.sharedDashboards.byId)

const getInternalSharingLink = (state: RootState, dashboardId: DashboardId) =>
    state.Data.system.internalDashboardSharingLink + dashboardId

const getExternalSharingLink = (state: RootState, sharedDashboardId: SharedDashboardId) =>
    window.location.protocol + '//' + window.location.host + '/exply/shared-dashboard/' + sharedDashboardId

const getSharedDashboardByDashboardId = (state: RootState, dashboardId: DashboardId) =>
    getAllSharedDashboards(state).find((sharedDashboard) => sharedDashboard.dashboardId === dashboardId)

export const selectors = {
    getSharedDashboardByDashboardId,
    getAllSharedDashboardIds: (state: RootState) =>
        state.Data.dashboards.normalizedDashboardConfig.sharedDashboards.allIds,
    getAllSharedDashboards,
    getInternalSharingLink,
    getExternalSharingLink,
}
