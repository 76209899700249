export const UNKNOWN = 'UNKNOWN'
export const DATE = 'DATE'
export const NUMBER = 'NUMBER'
export const STRING_SUITABLE_FOR_GROUPING = 'STRING_SUITABLE_FOR_GROUPING'

export type TagType =
    | 'UNKNOWN'
    | 'DATE'
    | 'NUMBER'
    | 'STRING_SUITABLE_FOR_GROUPING'
    | 'GEO_POINT'
    | 'GEO_SHAPE'
    | 'DATE_RANGE'
export type TagsType = Array<TagType>

/**
 * see UiField.groovy
 */
export type FieldConfiguration = {
    name: string
    label: string
    tag: TagType
    unit?: string
}

/**
 * see UiFieldConfiguration.groovy#fields
 */
export type FieldsConfiguration = Record<string, FieldConfiguration>
