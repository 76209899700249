import LoadingOverlay from 'react-loading-overlay'
import React, { memo, useState } from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { RootState } from '../../../Redux/types'
import { DashboardId } from '../../../Redux/Data/types'
import { selectors } from '../../../Redux'
import { AppDispatch } from '../../../store'
import DashboardContextButtons from './DashboardContextButtons'
import { deleteDashboard } from '../../../Sagas/DashboardSagas/DeleteDashboardSaga'
import DashboardRemovalConfirmation from './DashboardRemovalConfirmation'

type OwnProps = {
    dashboardId: DashboardId
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
    dashboard: selectors.Data.Dashboards.dashboardById(state, ownProps.dashboardId),
})

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
    handleSelectDashboard: () => dispatch(push(`/dashboard/${ownProps.dashboardId}`)),
    handleDeleteDashboard: () => dispatch(deleteDashboard(ownProps.dashboardId, false)),
})

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const DashboardListViewItem = (props: Props) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    if (!props.dashboard) {
        return null
    }

    const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true)
    const handleHideDeleteConfirmation = () => setShowDeleteConfirmation(false)

    return (
        <LoadingOverlay
            key={props.dashboard.id}
            text={
                <DashboardRemovalConfirmation
                    handleCancelRemoval={handleHideDeleteConfirmation}
                    handleRemoveButtonClick={props.handleDeleteDashboard}
                />
            }
            active={showDeleteConfirmation}
            classNamePrefix="tile-"
            fadeSpeed={250}
        >
            <div className="list-item" onClick={props.handleSelectDashboard} role="button">
                <div className="list__item-header" role="button">
                    <div className="list__item-left">
                        <div className="importer-list-item__name">{props.dashboard.title}</div>
                    </div>
                    <div className="list__item-right">
                        <DashboardContextButtons
                            dashboardId={props.dashboard.id}
                            handleShowDeleteConfirmation={handleShowDeleteConfirmation}
                        />
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DashboardListViewItem))
