import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { withSize } from 'react-sizeme'
import { VariableSizeTree, VariableSizeTree as Tree } from 'react-vtree'
import WidgetHeader from '../../../Molecules/WidgetHeader/WidgetHeader'
import { formatNumberToString } from '../../../../Utility/NumberFormator'
import { Column, PivotTableProps, Row, PivotTableRowProps, PivotTableNodeData } from './PivotTableWidget.types'
import { updatePivotTableSelection } from './PivotTableHelpers'
import Icon from '../../../Atoms/Icon/Icon'

class PivotTable extends PureComponent<PivotTableProps> {
    static displayName = 'PivotTable'

    treeRef: React.RefObject<VariableSizeTree<any>>

    constructor(props: PivotTableProps) {
        super(props)
        this.treeRef = React.createRef()
    }

    componentDidUpdate(prevProps: Readonly<PivotTableProps>) {
        if (
            prevProps.widgetConfiguration.collapseOptions?.allowCollapsing !==
                this.props.widgetConfiguration.collapseOptions?.allowCollapsing ||
            prevProps.widgetConfiguration.collapseOptions?.indexOfGroupingField !==
                this.props.widgetConfiguration.collapseOptions?.indexOfGroupingField
        ) {
            const rootId = this.props.widgetData.rows[0].id
            // close rows which should be collapsed by default when turning on / changing the value
            if (this.props.widgetConfiguration.collapseOptions?.allowCollapsing) {
                const indexOfGroupingField = this.props.widgetConfiguration.collapseOptions?.indexOfGroupingField
                this.treeRef.current?.recomputeTree({
                    [rootId]: {
                        open: true,
                        subtreeCallback(node: any, ownerNode: any) {
                            if (node !== ownerNode) {
                                node.isOpen = indexOfGroupingField <= node.nestingLevel
                            }
                        },
                    },
                })
            } else {
                // reopen all nodes when collapsing is turned off
                this.treeRef.current?.recomputeTree({
                    [rootId]: {
                        open: true,
                        subtreeCallback(node: any, ownerNode: any) {
                            if (node !== ownerNode) {
                                node.isOpen = true
                            }
                        },
                    },
                })
            }
        }

        // force the tree to recalculate row height
        if (prevProps.size.width !== this.props.size.width) {
            this.treeRef.current?.recomputeTree({})
        }
    }

    render = () => {
        const title = this.props.widgetConfiguration.title
        const className = classnames('widget', {
            'widget--has-header': title || this.props.onChangeTitle,
        })
        return (
            <div className={className}>
                <WidgetHeader title={title} onChangeTitle={this.props.onChangeTitle} />
                {this.renderWidget()}
            </div>
        )
    }

    handleUpdateSelection = (newSelection: Array<string>) => () => {
        const updatedSelection = updatePivotTableSelection(this.props.widgetState.selection, newSelection)

        this.props.setWidgetState({ selection: updatedSelection })
    }

    pathOrParentIsInSelection = (path: Array<string>) => {
        const selections = this.props.widgetState.selection

        return selections.some((selection: Array<string>) => {
            const selectionString = selection.join()
            const pathString = path.join()

            // check if this row is selected: 'root.element1.element2.selection' matches only 'root.element1.element2.selection'
            // or this row is a child of a selected element: 'root.element1.element2.selection' matches 'root.element1.element2.selection.child1', 'root.element1.element2.selection.child2' ...
            return selectionString === pathString || pathString.includes(selectionString)
        })
    }

    getIsRowOpenByDefault = (nestingLevel: number) =>
        !(
            this.props.widgetConfiguration.collapseOptions?.allowCollapsing &&
            this.props.widgetConfiguration.collapseOptions.indexOfGroupingField <= nestingLevel
        )

    // TODO fixed header height
    // TODO proper types
    // TODO sticky headers

    renderWidget() {
        const columns = this.props.widgetData.columns
        const rows = this.props.widgetData.rows

        const getTextWidth = (text: string) => {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            if (context) {
                context.font = getComputedStyle(document.body).font
                // We needed 4px as some extra padding in an edge case.
                return context.measureText(text).width + 4
            }

            // text.length * 11 was the calculation (originally written by Flo some years ago) before we implemented the
            // "measuring method". We kept it as a fall back.
            return text.length * 11
        }
        // We set the width of the values based on the first row, where the biggest values
        // are displayed, to ensure that the values are correctly aligned on big screens. On small
        // screens this might be different.
        const firstRowValueWidths = (Object.values(rows[0].values) as Array<number>).map((value, index) => {
            const numberOfDecimals = this.props.widgetConfiguration.numberOfDecimals
            const unit = columns[index].unit
            // The default number of decimals is 2, we need to consider this when calculating the width
            return getTextWidth(
                // the parseFloat ensures that we have a number which then gets fixed, since we had a bug where the value was "Infinity", making toFixed() crash
                parseFloat(value as unknown as string).toFixed(
                    numberOfDecimals === undefined || numberOfDecimals === null ? 2 : numberOfDecimals
                ) + (unit ? ' ' + unit : '')
            )
        })

        const columnLabelWidths = columns.map((col) => getTextWidth(col.label))

        const headerColumnStyles = (i: number) => ({
            minWidth: firstRowValueWidths[i] + 'px',
            maxWidth: Math.min(Math.max(firstRowValueWidths[i], columnLabelWidths[i]), 100) + 'px',
        })

        // to leave more place for the label, the first column should be as small as possible
        const rowColumnStyles = (i: number) => ({
            minWidth: i === 0 ? 'auto' : firstRowValueWidths[i] + 'px',
            width: i === 0 ? 'auto' : Math.min(Math.max(firstRowValueWidths[i], columnLabelWidths[i]), 100) + 'px',
        })

        const getRowHeight = (label: string, values: Array<number>, nestingLevel: number, isLeaf: boolean) => {
            const words = label.split(' ')
            const lengthPerWord = words.map((w) => getTextWidth(w) * 0.75 + 3) // * font-size + padding
            const totalLength = lengthPerWord.reduce((p, c) => p + c, 0)
            const valuesWidth =
                getTextWidth(values[0].toString()) +
                columnLabelWidths
                    .slice(1)
                    .map((w, i) => Math.min(Math.max(firstRowValueWidths[i], columnLabelWidths[i]), 100))
                    .reduce((p, c) => p + c, 0) +
                4 * 3 // value cols + padding
            const labelAndValuesWidth = this.props.size.width - nestingLevel * 18 - (!isLeaf ? 15 : 0) // subtract padding and chevron
            const spaceBetweenChevronAndValues = labelAndValuesWidth - valuesWidth

            if (spaceBetweenChevronAndValues > totalLength) {
                return 21 // default height
            }

            let spaceLeft = spaceBetweenChevronAndValues
            const wordsNotFittingInFirstLine = lengthPerWord.filter((w) => {
                if (spaceLeft > w) {
                    spaceLeft -= w
                    return false
                }
                return true
            })

            spaceLeft = labelAndValuesWidth
            let additionalRows = 1
            wordsNotFittingInFirstLine.forEach((w) => {
                spaceLeft -= w
                if (spaceLeft < 1) {
                    spaceLeft = labelAndValuesWidth
                    additionalRows++
                }
            })

            return 21 + additionalRows * 17 // default height + lineHeight * rows
        }

        const getNodeData = (node: Row, nestingLevel: number, parent?: PivotTableNodeData): PivotTableNodeData => {
            return {
                data: {
                    isLeaf: node.children.length === 0,
                    defaultHeight: getRowHeight(
                        node.label,
                        Object.values(node.values),
                        nestingLevel,
                        node.children.length === 0
                    ),
                    isOpenByDefault: this.getIsRowOpenByDefault(nestingLevel),
                    nestingLevel,
                    pathOrParentIsInSelection: this.pathOrParentIsInSelection,
                    handleUpdateSelection: this.handleUpdateSelection,
                    numberOfDecimals:
                        this.props.widgetConfiguration.numberOfDecimals === null
                            ? undefined
                            : this.props.widgetConfiguration.numberOfDecimals,
                    firstRowValueWidths,
                    columns: this.props.widgetData.columns,
                    path: parent ? [...parent.data.path, node.label] : [],
                    columnStyles: rowColumnStyles,
                    ...node,
                },
                nestingLevel,
                node,
            }
        }

        function* treeWalker() {
            for (let i = 0; i < rows.length; i++) {
                yield getNodeData(rows[i], 0)
            }

            while (true) {
                const parent: PivotTableNodeData = yield

                for (let i = 0; i < parent.node.children.length; i++) {
                    yield getNodeData(parent.node.children[i], parent.nestingLevel + 1, parent)
                }
            }
        }

        return (
            <div className="pivot-table">
                <div className="pivot-table__inner">
                    <div className="pivot-table__header">
                        <div className="pivot-table__row pivot-table__row--header">
                            <div className="pivot-table__row-content">
                                <div className="pivot-table__row-values">
                                    {columns.map((column: Column, i: number) => {
                                        return (
                                            <div
                                                key={i}
                                                className="pivot-table__row-value"
                                                title={column.label}
                                                style={headerColumnStyles(i)}
                                            >
                                                {column.label}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pivot-table__content">
                        <Tree
                            // @ts-ignore returns void instead of undefined?
                            treeWalker={treeWalker}
                            height={this.props.size.height - (this.props.widgetConfiguration?.title ? 71 : 34)}
                            width={this.props.size.width}
                            ref={this.treeRef}
                        >
                            {/*
                            // @ts-ignore props required vs actual idk */}
                            {PivotTableRow(this.treeRef)}
                        </Tree>
                    </div>
                </div>
            </div>
        )
    }
}

export default withSize({ monitorHeight: true })(PivotTable)

const PivotTableRow =
    (tree: React.RefObject<VariableSizeTree<any>>) =>
    ({
        data: {
            isLeaf,
            nestingLevel,
            id,
            label,
            values,
            path,
            pathOrParentIsInSelection,
            columns,
            handleUpdateSelection,
            numberOfDecimals,
            columnStyles,
        },
        isOpen,
        style,
        setOpen,
    }: PivotTableRowProps) => {
        const handleToggleRow = () => {
            // if we are closing (isOpen is still true and unchanged), close all children rows
            if (isOpen) {
                tree.current?.recomputeTree({
                    [id]: {
                        open: isOpen,
                        subtreeCallback(node: any, ownerNode: any) {
                            if (node !== ownerNode) {
                                node.isOpen = false
                            }
                        },
                    },
                })
            }

            setOpen(!isOpen)
        }

        const isSelected = pathOrParentIsInSelection(path)
        const rowClassName = classnames('pivot-table__row pivot-table__row--level-' + nestingLevel, {
            'pivot-table__row--leaf': isLeaf,
        })
        const rowIconAndHeaderClassName = classnames('pivot-table__row-content', {
            'pivot-table__row--selected': isSelected,
        })
        const valueArray = columns.map((column) => {
            return { value: values[column.key], unit: column.unit }
        })

        return (
            <div className={rowClassName} style={style}>
                <div className={rowIconAndHeaderClassName}>
                    {!isLeaf && nestingLevel !== 0 && (
                        <Icon
                            name="ChevronIcon"
                            color={isSelected ? 'blue' : undefined}
                            size="xs"
                            onClick={handleToggleRow}
                            rotation={isOpen ? 90 : 0}
                        />
                    )}
                    <div
                        className="pivot-table__row-content--label-and-values"
                        onClick={nestingLevel !== 0 ? handleUpdateSelection(path) : undefined}
                    >
                        {label.split(' ').map((word, i) => (
                            <span key={word + '-' + i} className="pivot-table__row-label--part">
                                {word}
                            </span>
                        ))}
                        <div className="pivot-table__row-values">
                            {valueArray.map((valueObject, i) => {
                                return (
                                    <div key={i} className="pivot-table__row-value" style={columnStyles(i)}>
                                        {formatNumberToString(
                                            valueObject.value,
                                            numberOfDecimals,
                                            valueObject.unit || ''
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
