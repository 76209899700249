import React from 'react'
import classnames from 'classnames'
import { formatNumberToString } from '../../../../Utility/NumberFormator'
import WidgetHeader from '../../../Molecules/WidgetHeader/WidgetHeader'
import { updateSelectionAfterItemClick } from '../DataMatrixHelpers'
import { MatrixChartWidgetProps } from './MatrixChartWidget.types'
import { FURTHER_VALUES } from '../Constants'

export default class MatrixChartWidget extends React.PureComponent<MatrixChartWidgetProps> {
    static displayName = 'MatrixChart'

    render = () => {
        const title = this.props.widgetConfiguration?.title
        return (
            <div
                style={{ overflow: 'auto' }}
                className={title || this.props.onChangeTitle ? 'widget widget--has-header' : 'widget'}
            >
                <WidgetHeader title={title} onChangeTitle={this.props.onChangeTitle} />
                {this.renderWidget()}
            </div>
        )
    }

    renderWidget() {
        const data = this.props.widgetData
        const unit = data.unit
        const decimals = this.props.widgetConfiguration?.numberOfDecimals
        const selections = this.props.widgetState?.selection
        const showSumRow = this.props.widgetConfiguration?.showSumRow
        const showSumColumn = this.props.widgetConfiguration?.showSumColumn
        const xAxisLabel = this.props.widgetConfiguration?.xAxisLabel
        const yAxisLabel = this.props.widgetConfiguration?.yAxisLabel
        const className = classnames('matrix-chart-widget', {
            'matrix-chart-widget--has-selection': selections.length > 0,
            'matrix-chart-widget--has-y-axis-label': yAxisLabel,
        })
        return (
            <div className={className}>
                {xAxisLabel && <label className="matrix-chart-widget__x-axis-label">{xAxisLabel}</label>}
                {yAxisLabel && (
                    <div className="matrix-chart-widget__y-axis-label__wrapper">
                        <label className="matrix-chart-widget__y-axis-label">{yAxisLabel}</label>
                    </div>
                )}

                <table>
                    <tbody>
                        <tr>
                            <td />
                            {data.xPivotValues.map((e, i) => {
                                const appliedSelectors = selections.filter((selection) => selection.x === e)
                                const className = classnames({
                                    selected: appliedSelectors.length,
                                    disabled: e === FURTHER_VALUES,
                                })

                                return (
                                    <td
                                        className={className}
                                        onClick={this.handleClick({ x: e })}
                                        key={'matrix-chart-header-' + i}
                                        title={e}
                                    >
                                        {this.renderCell(e)}
                                    </td>
                                )
                            })}
                            {showSumRow && <td className="disabled">sum</td>}
                        </tr>

                        {data.yPivotValues.map((yPivotValue: string, i: number) => {
                            const appliedSelectors = selections.filter((selection) => selection.y === yPivotValue)
                            const rowClassName = classnames({
                                selected: appliedSelectors.length > 0,
                                disabled: yPivotValue === FURTHER_VALUES,
                            })

                            return (
                                <tr key={'matrix-chart-row-' + i}>
                                    <td
                                        className={rowClassName}
                                        onClick={this.handleClick({ y: yPivotValue })}
                                        title={yPivotValue}
                                    >
                                        {this.renderCell(yPivotValue)}
                                    </td>

                                    {data.xPivotValues.map((xPivotValue: string) => {
                                        const cell = data.rows[yPivotValue].cells[xPivotValue]
                                        const appliedSelectors = selections.filter((selection) => {
                                            const x = selection.x
                                            const y = selection.y
                                            if (x === undefined || y === undefined) {
                                                return x === xPivotValue || y === yPivotValue
                                            }

                                            return x === xPivotValue && y === yPivotValue
                                        })
                                        const cellClassName = classnames({
                                            selected: appliedSelectors.length > 0,
                                            disabled: yPivotValue === FURTHER_VALUES || xPivotValue === FURTHER_VALUES,
                                        })
                                        const value = formatNumberToString(cell.value, decimals)

                                        return this.renderTableEntry(
                                            xPivotValue,
                                            yPivotValue,
                                            cellClassName,
                                            cell.normalizedValue,
                                            value,
                                            unit
                                        )
                                    })}

                                    {showSumRow &&
                                        this.renderSumRow(
                                            yPivotValue,
                                            formatNumberToString(data.rows[yPivotValue].sum, decimals),
                                            unit
                                        )}
                                </tr>
                            )
                        })}

                        {showSumColumn && (
                            <tr>
                                <td className="sum-row">sum</td>
                                {data.xPivotValues.map((xPivotValue: string) => (
                                    <td className="disabled sum-row" key={`sum-column-${xPivotValue}`}>
                                        {formatNumberToString(data.ySums[xPivotValue], decimals)}
                                    </td>
                                ))}

                                {showSumColumn && showSumRow && (
                                    <td className="sum-column sum-row">
                                        {formatNumberToString(data.totalSum, decimals)}
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }

    renderSumRow = (yPivotValue: string, sum: string, unit?: string) =>
        this.renderTableEntry(yPivotValue, 'sum', 'sum-column', 0, sum, unit)

    renderTableEntry = (
        xPivotValue: string,
        yPivotValue: string,
        cellClassName: string,
        alpha: number,
        value: number | string,
        unit?: string
    ) => (
        <td
            key={'matrix-x-' + xPivotValue + '-y-' + yPivotValue}
            className={cellClassName}
            style={{ background: 'rgba(79, 195, 247 , ' + alpha + ')' }}
            onClick={this.handleClick({ x: xPivotValue, y: yPivotValue })}
        >
            {this.renderCell(value, unit)}
        </td>
    )

    renderCell = (value: number | string, unit?: string) => (
        <div>
            <span>
                {value}
                {unit}
            </span>
            <span className="widget-chart-ghost">{value}</span>
        </div>
    )

    handleClick =
        ({ x, y }: { x?: string; y?: string }) =>
        () =>
            this.props.setWidgetState({
                selection: updateSelectionAfterItemClick({ x, y }, this.props.widgetState?.selection),
            })
}
