import { put, select, takeLatest } from 'redux-saga/effects'
import produce from 'immer'
import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { LOCATION_CHANGE } from 'connected-react-router'
import { actions, selectors } from '../../Redux'
import { applyFakeTimeSelector, buildInitialDashboardDataRequest, executeDashboardDataRequest } from './Utility'

import {
    EXPLORER_DASHBOARD_UUID,
    EXPLORER_DASHBOARD_WIDGET_GROUP_UUID,
} from '../../Redux/Data/Dashboards/WidgetExplorer'
import { createWidget } from '../../Utility/DashboardEditor'
import { executeDashboard } from './LoadDataFromServerWhenDashboardChanged'
import { AvailableWidgets, availableWidgets, WidgetFeatureFlags } from '../../Utility/WidgetUtility'
import { DashboardRequest, WidgetConfiguration, WidgetExplorerSelections, WidgetId } from '../../Redux/Data/types'
import { createApiDashboardConfigurationFromNormalizedDashboardConfiguration } from '../../Redux/Data/Dashboards/denormalization'
import { createAndAddNewWidgetAction } from './CreateAndAddNewWidgetSaga'

const widgetIsCapable = ({
    widget,
    numberOfDateFields,
    numberOfValueFields,
    numberOfCategories,
}: {
    widget: any
    numberOfDateFields: number
    numberOfValueFields: number
    numberOfCategories: number
}) => {
    const capabilities = widget.capabilities

    if (!capabilities) {
        return false
    }

    return (
        numberOfDateFields >= capabilities.min.dateFields &&
        numberOfDateFields <= capabilities.max.dateFields &&
        numberOfValueFields >= capabilities.min.valueFields &&
        numberOfValueFields <= capabilities.max.valueFields &&
        numberOfCategories >= capabilities.min.categoryFields &&
        numberOfCategories <= capabilities.max.categoryFields
    )
}

const generateConfigForWidgets = (
    widgets: AvailableWidgets,
    selectedFields: WidgetExplorerSelections
): Record<WidgetId, WidgetConfiguration> => {
    const migratedWidgets = widgets.filter((widget) => 'configurationInitializerForWidgetExplorer' in widget)
    let configuredWidgets: Record<string, any> = {}
    migratedWidgets.forEach((widget) => {
        const configuredWidget = createWidget({
            widgetGroup: EXPLORER_DASHBOARD_WIDGET_GROUP_UUID,
            type: widget.typeName,
            configuration:
                widget.configurationInitializerForWidgetExplorer &&
                widget.configurationInitializerForWidgetExplorer(selectedFields),
        })

        configuredWidgets = produce(configuredWidgets, (draft) => {
            draft[configuredWidget.id] = configuredWidget
        })
    })

    return configuredWidgets
}

export const addWidgetFromExplorerAction = createAction(
    'Sagas/Dashbboards/addWidgetFromExplorer',
    (widgetId: WidgetId) => ({
        payload: {
            widgetId,
        },
    })
)

export function* CreateWidgetPreviewsForExplorer() {
    yield takeLatest(
        [
            actions.Data.Dashboards.WidgetExplorer.setExplorerDataSelectorFields.type,

            actions.Data.Dashboards.WidgetExplorer.addFilter.type,
            actions.Data.Dashboards.WidgetExplorer.changeFilter.type,
            actions.Data.Dashboards.WidgetExplorer.removeFilter.type,

            actions.Data.Dashboards.WidgetExplorer.setTime.type,

            createAndAddNewWidgetAction.type,
        ],
        function* () {
            const selectedFields: WidgetExplorerSelections =
                selectors.Data.Dashboards.WidgetExplorer.currentFieldSelections(yield select())
            const numberOfDateFields = selectedFields.dateFields.length
            const numberOfValueFields = selectedFields.valueFields.length
            const numberOfCategories = selectedFields.categoryFields.length

            const widgetFeatureFlags: WidgetFeatureFlags = yield select(selectors.Data.FeatureFlags.widgetFeatureFlags)
            const capableWidgets = availableWidgets(widgetFeatureFlags)
                // remove widgets not suited for the selected fields
                .filter((t) =>
                    widgetIsCapable({ widget: t, numberOfDateFields, numberOfValueFields, numberOfCategories })
                )

            const widgetConfigurations = generateConfigForWidgets(capableWidgets, selectedFields)
            yield put(actions.Data.Dashboards.WidgetExplorer.addSuggestedWidgetsToExplorer(widgetConfigurations))

            let widgetDataRequest: DashboardRequest = yield buildInitialDashboardDataRequest(EXPLORER_DASHBOARD_UUID)

            // @ts-ignore
            delete widgetDataRequest.dashboard
            widgetDataRequest.transientDashboard = createApiDashboardConfigurationFromNormalizedDashboardConfiguration(
                yield select(),
                EXPLORER_DASHBOARD_UUID
            )

            widgetDataRequest = yield applyFakeTimeSelector(widgetDataRequest)

            yield put(actions.Data.Dashboards.WidgetExplorer.setWidgetRequest(widgetDataRequest.widgetRequests))
            yield executeDashboardDataRequest(widgetDataRequest, [
                actions.Data.Dashboards.WidgetExplorer.setDashboardResponse,
            ])
        }
    )
}

export function* UpdateDashboardAfterAddingWidget() {
    yield takeLatest(addWidgetFromExplorerAction, function* (action) {
        yield put(actions.Data.Dashboards.WidgetExplorer.setInitialWidgetPositionAndSize(action.payload.widgetId))
        yield put(actions.Data.Dashboards.WidgetExplorer.addWidgetToStore(action.payload.widgetId))
        yield executeDashboard()
    })
}

export function* DeleteNewDashboardOnBrowserBack() {
    yield takeLatest(LOCATION_CHANGE, function* (action: PayloadAction<any>) {
        const inEditMode = selectors.Data.Dashboards.DashboardEditMode.isDashboardEditModeActive(yield select())
        if (inEditMode && action.payload.action === 'POP') {
            yield put(actions.Data.Dashboards.WidgetExplorer.closeWidgetExplorer())
            yield put(actions.Data.Dashboards.DashboardEditMode.discardEditModeChanges())
            yield put(actions.Data.Dashboards.DashboardEditMode.disableEditMode())
            yield put(actions.UI.Widget.resetLatestConfiguration())
        }
    })
}
