import React, { lazy, Suspense } from 'react'
import { BurnChartWidgetProps } from './BurnChartWidget.types'

const LazyWidget = lazy(() => import(/* webpackChunkName: "BurnChart" */ './BurnChartWidget'))

const LazyWidgetWrapper: React.FC<BurnChartWidgetProps> = (props) => (
    <Suspense fallback="">
        <LazyWidget {...props} />
    </Suspense>
)

export default LazyWidgetWrapper
