import React from 'react'

const Headline: React.FunctionComponent<{
    configuration: {
        title: string
        level?: number
    }
}> = (props) => {
    const className = 'headline--underline'
    const title = props.configuration.title
    const level = props.configuration.level

    switch (level) {
        case 1:
            return (
                <h1 key={'headline-1-' + title} className={className}>
                    {title}
                </h1>
            )
        case 2:
            return (
                <h2 key={'headline-2-' + title} className={className}>
                    {title}
                </h2>
            )
        case 3:
            return (
                <h3 key={'headline-3-' + title} className={className}>
                    {title}
                </h3>
            )
        case 4:
            return (
                <h4 key={'headline-4-' + title} className={className}>
                    {title}
                </h4>
            )
        default:
            return (
                <div key={'headline-' + title} className={className}>
                    {title}
                </div>
            )
    }
}

export default Headline
