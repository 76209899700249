let ctrlKeyPressed = false

document.addEventListener('keydown', (event) => {
    if (event.keyCode === 91 || event.ctrlKey || event.metaKey) {
        ctrlKeyPressed = true
    }
})

document.addEventListener('keyup', () => {
    ctrlKeyPressed = false
})

export function getCtrlKeyPressed() {
    return ctrlKeyPressed
}
