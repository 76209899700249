import ImageWidget from './ImageWidget'
// @ts-ignore file extension
import ImageWidgetIcon from './ImageWidget.Icon.tsx'
import ImageWidgetStateInitializer from './ImageWidget.init'
import ImageWidgetConfigurationInitializer, {
    ImageWidgetConfigurationInitializerForWidgetExplorer,
} from './ImageWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import ImageUploader from '../../../../Containers/ImageUploadContainer'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'

const config: WidgetManifestConfiguration = {
    name: 'Image Widget',
    component: ImageWidget,
    icon: ImageWidgetIcon,
    stateInitializationFunction: ImageWidgetStateInitializer,
    configurationInitializer: ImageWidgetConfigurationInitializer,
    configurationInitializerForWidgetExplorer: ImageWidgetConfigurationInitializerForWidgetExplorer,

    editors: {
        data: [
            buildingBlocks.headline('Select your image source'),
            {
                component: ImageUploader,
                widgetConfigurationProperty: 'image',
                editorContextBuilder: () => ({
                    fileType: 'image',
                    onTypeNotSupported: () => {},
                }),
            },
        ],
        bottomTabs: [],
    },
}

export default config
