import React, { useState } from 'react'
import produce from 'immer'
import DocumentFieldSelect, { Option } from '../../Atoms/DocumentFieldSelect/DocumentFieldSelect'
import FieldFilterEditor from '../FieldFilterEditor/FieldFilterEditor'
import { ListItem } from '../../Organisms/List/List'
import Icon from '../../Atoms/Icon/Icon'
import { FieldKeys, OnTriggerAutoCompletion } from '../../Atoms/FieldFilterInput/FieldFilterInput'
import { AnalysisTerm, Filters, ValueField } from '../../Organisms/Widgets/WidgetModel'
import TextInput from '../../Atoms/TextInput/TextInput'
import { FieldType } from '../../Atoms/FieldFilterInput/FieldFilterManifest'
import {
    DATE,
    FieldConfiguration,
    STRING_SUITABLE_FOR_GROUPING,
    TagsType,
} from '../../Atoms/FieldsConfiguration/FieldsConfigurationTypes'
import DateFieldEditor, { IGNORE_TIME_SELECTION } from '../DateFieldEditor/DateFieldEditor'
import { SYMBOL_TYPE } from '../CalculatedFieldEditor/CalculatedFieldTokenEditor'
import { getDefaultAnalysisTermWithField } from '../../Organisms/Widgets/DefaultConfigurations'
import { CalculatedFieldEditor } from '../CalculatedFieldEditor/CalculatedFieldEditor'
import { OnChangeMultiple } from '../../Organisms/WidgetEditorForm/WidgetEditorForm.types'
import { groupingFieldOptionGenerator } from '../../../widgetManifestBuildingBlocks'
import ButtonWithTooltip from '../HoverTooltip/ButtonWithTooltip'

const ValueFieldEditor: React.FC<{
    value: ValueField
    clearable?: boolean
    optionGenerator: (currentDateField?: string) => (element: FieldConfiguration) => Array<Option>
    tags: TagsType
    onChange: (value: ValueField) => void
    onChangeMultiple?: OnChangeMultiple
    onTriggerAutoCompletion: OnTriggerAutoCompletion
    configuration?: {
        disableCalculatedField?: boolean
        isModeDisabled?: boolean
        isGroupingFieldDisabled?: boolean
        isIgnoreTimeSelectionDisabled?: boolean
        areDateRangeFieldsAllowed?: boolean
    }
    chartFieldListItem?: {
        additionalOptions: any
        showDeleteButton: boolean
        onRemove: () => void
        index: number
        pivotField: JSX.Element
    }
    includePivotField?: boolean
    widgetConfiguration?: {
        pivotField?: string
        stackingField?: string
        chartGroups?: Array<{ yPivotField: string }>
        type: string
    }
    widgetType?: string
}> = (props) => {
    const [showAdvancedOptions, setAdvancedOptionsAreOpen] = useState(false)
    const analysisTerm: AnalysisTerm = props.value.analysisTerm
    const fieldNeedsCalculationEditor =
        analysisTerm &&
        analysisTerm.some(
            (token) => token.type === SYMBOL_TYPE.OPERATOR || (token.type === SYMBOL_TYPE.MODE && token.value !== 'sum')
        )
    const [hasOpenedCalculationEditor, setHasOpenedCalculationEditor] = useState(false)
    const isCalculationEditor = hasOpenedCalculationEditor || fieldNeedsCalculationEditor
    const hasValueField = Boolean(analysisTerm)
    const firstValueField = analysisTerm.find((token) => token.type === SYMBOL_TYPE.VALUE_FIELD)
    const isDocCount = Boolean(firstValueField && firstValueField.value === 'docCount')
    const [calculationLabel, setCalculationLabel] = useState<string | undefined>(props.value.customFieldLabel)

    const handleFilterAdded = (newFilter: FieldType) =>
        props.onChange(
            produce(props.value, (draft: ValueField) => {
                draft.filter = draft.filter && draft.filter.length > 0 ? [...draft.filter, newFilter] : [newFilter]
            })
        )

    const handleFieldFilterChanged = (index: number, field: FieldKeys, newValue: string & Array<string>) =>
        props.onChange(
            produce(props.value, (draft) => {
                draft.filter[index][field] = newValue
            })
        )

    const handleFilterRemoved = (i: number) =>
        props.onChange(
            produce(props.value, (draft) => {
                draft.filter = [...draft.filter.slice(0, i), ...draft.filter.slice(i + 1, draft.filter.length)]
            })
        )

    const handleCalculationChange = (analysisTerm: AnalysisTerm) => {
        props.onChange(
            produce(props.value, (draft) => {
                draft.analysisTerm = analysisTerm
            })
        )
    }

    const handleFieldNameChange = (fieldName: string) => {
        const newAnalysisTerm = analysisTerm
            ? produce(analysisTerm, (draft) => {
                  draft[2] = { type: SYMBOL_TYPE.VALUE_FIELD, value: fieldName }
                  draft[3] = { type: SYMBOL_TYPE.CLOSE_PARENTHESES, value: ')' }
                  // Only sum mode for docCount (docCount is not like the others)
                  if (fieldName === 'docCount') {
                      draft[0] = { type: SYMBOL_TYPE.MODE, value: 'sum' }
                  }
              })
            : getDefaultAnalysisTermWithField(fieldName)

        props.onChange(
            produce(props.value, (draft) => {
                draft.analysisTerm = newAnalysisTerm
            })
        )
    }

    const handleCalculationLabelChange = (calculationFieldName?: string) => {
        setCalculationLabel(calculationFieldName)
        props.onChange(
            produce(props.value, (draft) => {
                draft.customFieldLabel = calculationFieldName
            })
        )
    }

    const handleDateFieldChange = (dateField: string) => {
        props.onChange(
            produce(props.value, (draft) => {
                draft.dateField = dateField
                draft.ignoreTimeSelection = dateField === IGNORE_TIME_SELECTION
            })
        )
    }

    const handleUnitChange = (unit: string) =>
        props.onChange(
            produce(props.value, (draft) => {
                draft.unit = unit
            })
        )

    const handleGroupingFieldChange = (groupingField: string) =>
        props.onChange(
            produce(props.value, (draft) => {
                draft.groupingField = groupingField
            })
        )

    const handleAdvancedOptionsToggle = () => setAdvancedOptionsAreOpen(!showAdvancedOptions)

    const handleAddCalculation = () => setHasOpenedCalculationEditor(true)

    const handleRemoveCalculation = () => {
        // @ts-ignore
        const currentFirstValueField = analysisTerm.find((token) => token.type === SYMBOL_TYPE.VALUE_FIELD).value
        setHasOpenedCalculationEditor(false)
        props.onChange(
            produce(props.value, (draft) => {
                draft.analysisTerm = getDefaultAnalysisTermWithField(currentFirstValueField)
            })
        )
    }

    const renderValueField = () => {
        const firstValueField = analysisTerm
            ? analysisTerm.find((token) => token.type === SYMBOL_TYPE.VALUE_FIELD)
            : null
        return (
            <DocumentFieldSelect
                key="field-select"
                onChange={handleFieldNameChange}
                clearable={props.clearable}
                value={firstValueField ? firstValueField.value : undefined}
                tags={props.tags}
            />
        )
    }

    const renderCalculator = () => {
        const isModeDisabled = props.configuration && props.configuration.isModeDisabled
        const isGroupingFieldDisabled = Boolean(props.configuration && props.configuration.isGroupingFieldDisabled)

        return (
            <CalculatedFieldEditor
                key="calc-editor"
                onChange={handleCalculationChange}
                value={props.value.analysisTerm}
                label={props.value.customFieldLabel}
                onChangeLabel={handleCalculationLabelChange}
                isModeDisabled={isModeDisabled || isDocCount}
                groupingField={props.value.groupingField}
                isGroupingFieldDisabled={isGroupingFieldDisabled}
                onChangeGroupingField={handleGroupingFieldChange}
                optionGenerator={props.optionGenerator(props.value.dateField)}
            />
        )
    }

    const renderHoverTooltipContent = () => {
        if (!hasValueField) {
            return ['No value field selected']
        }
        if (isDocCount) {
            return ['"Number of Entries" not supported']
        }
        return ['']
    }

    const renderFiltersAndCalculationButton = (fields: Filters, isCalculationDisabled?: boolean) => (
        <>
            <div className="form-field--actions">
                {!isCalculationDisabled && (
                    <div className="form-field--action">
                        <ButtonWithTooltip
                            isDisabled={!hasValueField || isDocCount}
                            header="Cannot add calculation"
                            content={renderHoverTooltipContent()}
                            onClick={isCalculationEditor ? handleRemoveCalculation : handleAddCalculation}
                            type={isCalculationEditor ? 'warning' : undefined}
                        >
                            <Icon
                                name="CalculatorIcon"
                                color={isCalculationEditor ? 'error' : 'green'}
                                label={isCalculationEditor ? 'Remove calculation' : 'Add calculation'}
                                size="small"
                            />
                        </ButtonWithTooltip>
                    </div>
                )}
                <div className="form-field--action widget-editor__input--small">
                    <ButtonWithTooltip
                        isDisabled={!hasValueField}
                        header="No value field selected"
                        onClick={() =>
                            handleFilterAdded({
                                field: null,
                                operator: 'equals',
                                values: [],
                            })
                        }
                    >
                        <Icon name="FilterIcon" color="green" label="Add Filter" size="small" />
                    </ButtonWithTooltip>
                </div>
            </div>
            <FieldFilterEditor
                className="form-field--filters"
                fields={fields}
                onTriggerAutoCompletion={props.onTriggerAutoCompletion}
                noAddFilterButton
                onFilterAdded={handleFilterAdded}
                onFilterChanged={handleFieldFilterChanged}
                onFilterRemoved={handleFilterRemoved}
            />
        </>
    )

    const renderLabelEditor = () => (
        <TextInput
            className="valueField--label"
            onChange={handleCalculationLabelChange}
            value={calculationLabel}
            placeholder={isCalculationEditor ? 'Calculation Label' : 'Custom Label'}
            useSelectHeight
        />
    )

    const renderUnit = () => (
        <TextInput
            className="valueField--unit widget-editor__input--tiny"
            key="unit-field"
            onChange={handleUnitChange}
            value={props.value.unit}
            placeholder="Unit"
            useSelectHeight
        />
    )

    const renderDateField = () => (
        <DateFieldEditor
            value={props.value.dateField}
            onChange={handleDateFieldChange}
            clearable={false}
            isIgnoreTimeSelectionDisabled={isIgnoreTimeSelectionDisabled}
            areDateRangeFieldsAllowed={areDateRangeFieldsAllowed}
        />
    )

    const getPropertyName = (widgetType?: string) => {
        switch (widgetType) {
            case 'burnChart':
            case 'jiraBurnChart':
                return 'chartGroups.2.yPivotField'
            case 'timeAreaChart':
            case 'timeBarChart':
            case 'timeLineChart':
                return 'stackingField'
            default:
                return 'pivotField'
        }
    }

    const handlePivotFieldChange = (value: any) => {
        if (props.onChangeMultiple && props.widgetConfiguration) {
            return props.onChangeMultiple([
                {
                    propertyName: getPropertyName(props.widgetType),
                    value,
                },
            ])
        }
        return () => {}
    }

    const getPivotFieldValue = () => {
        if (props.widgetConfiguration) {
            if (
                (props.widgetType === 'burnChart' || props.widgetType === 'jiraBurnChart') &&
                props.widgetConfiguration.chartGroups
            ) {
                // this is necessary to catch the stupid dot-notation of the burnchart
                return props.widgetConfiguration.chartGroups[2].yPivotField
            }

            // @ts-ignore the stupid burnchart dot-notation
            return props.widgetConfiguration[getPropertyName(props.widgetType)]
        }
    }

    const renderPivotField = () => (
        <div className="value-field__pivot-field">
            <DocumentFieldSelect
                tags={[STRING_SUITABLE_FOR_GROUPING, DATE]}
                placeholder="Stacking Field"
                onChange={handlePivotFieldChange}
                value={getPivotFieldValue()}
                valueWithIcon="LayerPlusIcon"
                smallChevron
                optionGenerator={groupingFieldOptionGenerator()}
                clearable={!(props.widgetType === 'rowChart' || props.widgetType === 'barChart')}
            />
        </div>
    )

    const renderValueFieldHeaderContent = () => {
        const headerContent = [
            <div
                className="valueFieldEditor--header-content__first-row"
                key="valueFieldEditor--header-content__first-row"
            >
                {isCalculationEditor && !disableCalculatedField ? renderCalculator() : renderValueField()}
                {renderDateField()}
                {renderUnit()}
            </div>,
            <div
                className="valueFieldEditor--header-content__second-row"
                key="valueFieldEditor--header-content__second-row"
            >
                {props.chartFieldListItem?.pivotField || (props.includePivotField && renderPivotField())}
                {renderFiltersAndCalculationButton(fields, disableCalculatedField)}
            </div>,
        ]

        return (
            <div className="valueFieldEditor--header-content" key="value-field-editor-header">
                <div className="valueFieldEditor--header--input-area">{headerContent}</div>
                {props.chartFieldListItem && props.chartFieldListItem.showDeleteButton ? (
                    <Icon
                        key={`remove-button-${props.chartFieldListItem.index}`}
                        className="filter-group__reset"
                        size="sm"
                        name="TrashIcon"
                        onClick={props.chartFieldListItem.onRemove}
                    />
                ) : null}
            </div>
        )
    }

    const fields = props.value.filter
    const disableCalculatedField = props.configuration && props.configuration.disableCalculatedField
    const isIgnoreTimeSelectionDisabled = props.configuration && props.configuration.isIgnoreTimeSelectionDisabled
    const areDateRangeFieldsAllowed = props.configuration && props.configuration.areDateRangeFieldsAllowed

    return (
        <div className="form-field-with-filter">
            <ListItem
                left={[
                    <Icon
                        key="icon"
                        name="ChevronIcon"
                        rotation={showAdvancedOptions ? 90 : 0}
                        onClick={handleAdvancedOptionsToggle}
                        size="small"
                    />,
                ]}
                right={[
                    renderValueFieldHeaderContent(),
                    !showAdvancedOptions && (
                        <p
                            className="valueField-advancedOptions-toggle"
                            onClick={handleAdvancedOptionsToggle}
                            key="value-field-editor-advanced-options-toggle"
                        >
                            <span>Show</span> Advanced Options
                        </p>
                    ),
                ]}
                collapsible={
                    <>
                        <hr />
                        {renderLabelEditor()}
                        {props.chartFieldListItem && props.chartFieldListItem.additionalOptions}
                        <p className="valueField-advancedOptions-toggle" onClick={handleAdvancedOptionsToggle}>
                            <span>Hide</span> Advanced Options
                        </p>
                    </>
                }
                isOpen={showAdvancedOptions}
            />
        </div>
    )
}

export default React.memo(ValueFieldEditor)
