import { MapWidgetConfiguration } from './MapWiget.types'

export default function (config: MapWidgetConfiguration) {
    return {
        selection: [],
        // should be in sync with default viewport in configuration intializer
        viewport: config.defaultViewport || {
            zoom: 2,
            center: {
                lat: 43.315088190459576,
                lng: -14.160438084395622,
            },
            topLeft: {
                lat: 73.12494524712693,
                lng: 154.6875,
            },
            bottomRight: {
                lat: -12.897489183755892,
                lng: 176.8359375,
            },
        },
    }
}
