import React from 'react'
import WidgetIcon from '../../../Atoms/WidgetIcon/WidgetIcon'

import { ReactComponent as AreaIconSvg } from './TimeSeriesChart.Icon.Area.svg'
import { ReactComponent as BarIconSvg } from './TimeSeriesChart.Icon.Bar.svg'
import { ReactComponent as LineIconSvg } from './TimeSeriesChart.Icon.Line.svg'
import { ReactComponent as SeriesIconSvg } from './TimeSeriesChart.Icon.Series.svg'

const IconByMode = {
    area: AreaIconSvg,
    bar: BarIconSvg,
    line: LineIconSvg,
    series: SeriesIconSvg,
}

export default (mode: 'area' | 'bar' | 'line' | 'series') => () => <WidgetIcon icon={IconByMode[mode]} />
