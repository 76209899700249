import {
    csvParsingStep,
    csvSourceFileStep,
    defaultRequiredPropertyValidator,
    editorWithLabel,
    fieldMappingConfigurationStep,
} from './manifest.importerTypes.buildingBlocks'
import Select from '../Components/Atoms/Select/Select'
import { EditorContextBuilderConfig, ImporterConfigurationStep } from './manifest.importerTypes'
import { Mapping } from '../Components/Molecules/ImporterFieldMapping/ImporterFieldMappingEditor'

/*
1. csv upload
2. parser settings
3. join fields + preview
4. mapping of csv fields + preview (mit original data)
 */
const importerExtensionTypes: ImporterExtensionTypes = {
    joinExtraCsvData: {
        label: 'Combine Additional CSV Data',
        configurationSteps: [
            csvSourceFileStep,
            csvParsingStep(true),
            {
                title: 'Extension',
                requiredProperties: [
                    {
                        propertyPath: 'joinFieldOriginal',
                        propertyValidator: defaultRequiredPropertyValidator,
                    },
                    {
                        propertyPath: 'joinFieldExtra',
                        propertyValidator: defaultRequiredPropertyValidator,
                    },
                ],
                hasPreview: true,
                editors: [
                    editorWithLabel(
                        'joinFieldOriginal',
                        'Join Field in original data source',
                        Select,
                        {
                            clearable: false,
                            placeholder: 'Select field...',
                        },
                        (config: EditorContextBuilderConfig) => {
                            const suggestions: any[] | undefined = config.configurationSuggestions?.joinFieldOriginal
                            return {
                                options:
                                    suggestions?.map((suggestion) => {
                                        return { value: suggestion.id, label: suggestion.label }
                                    }) || [],
                            }
                        }
                    ),
                    editorWithLabel(
                        'joinFieldExtra',
                        'Column in CSV to join over',
                        Select,
                        {
                            clearable: false,
                            placeholder: 'Select column...',
                        },
                        (config: EditorContextBuilderConfig) => {
                            const suggestions: any[] | undefined = config.configurationSuggestions?.joinFieldExtra
                            return {
                                options:
                                    suggestions?.map((suggestion) => {
                                        return { value: suggestion, label: suggestion }
                                    }) || [],
                            }
                        }
                    ),
                ],
            },
            fieldMappingConfigurationStep(true),
        ],
    },
}

export type ImporterExtensionTypes = Record<ImporterExtensionType, ImporterExtensionTypeConfiguration>

export type ImporterExtensionType = 'joinExtraCsvData'

export type JoinExtraCsvDataConfiguration = {
    source: {
        path: string | null
        name: string | null
    }
    parserSettings: CsvParserSettings | null
    joinFieldOriginal: string | null
    joinFieldExtra: string | null
    mapping?: {
        documentType?: string
        mappings?: Array<Mapping>
    }
}

export type CsvParserSettings = {
    separator?: string
    quoteChar?: string
    escapeChar?: string
    ignoreLeadingWhiteSpaces?: boolean
    ignoreQuotation?: boolean
    strictQuotes?: boolean
    linesToSkip?: number
    multiLineLimit?: number
    charset?: string
}

export type ImporterExtensionTypeConfiguration = {
    label: string
    configurationSteps: Array<ImporterConfigurationStep>
}

export default importerExtensionTypes
