import React from 'react'
import classnames from 'classnames'

const RowMenu: React.FunctionComponent<{
    inverted?: boolean
    children?: React.ReactNode
    isHalfSized?: boolean
}> = (props) => {
    const className = classnames('row-menu', {
        'row-menu--inverted': props.inverted === true,
    })

    return <ul className={className}>{props.children}</ul>
}

const RowMenuItem: React.FunctionComponent<{
    children?: React.ReactChild
    onClick?: () => void
    isHalfSized?: boolean
    isActive?: boolean
}> = (props) => {
    const className = classnames('row-menu__item', {
        'row-menu__item--half': props.isHalfSized,
        'row-menu__item--active': props.isActive,
    })
    return (
        <li className={className} onClick={props.onClick} role="button">
            {props.children}
        </li>
    )
}

export default RowMenu
export { RowMenuItem }
