import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import { actions, selectors } from '../../../Redux'
import endpoint from '../../../Utility/endpoint'

import Icon from '../../../Components/Atoms/Icon/Icon'
import TextInput from '../../../Components/Atoms/TextInput/TextInput'
import { ModalContent } from '../../../Components/Molecules/Modal/Modal'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'

type LinkModalProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const LinkModal: React.FC<LinkModalProps> = (props) => {
    const handleCopiedLink = () => {
        props.showCopyNotification()
        setTimeout(props.hideCopyNotification, 3000)
    }

    useEffect(() => {
        return function cleanup() {
            props.removeToken()
        }
    })

    const resetLink =
        window.location.protocol +
        '//' +
        window.location.host +
        endpoint +
        '/my-profile/reset-password?token=' +
        props.token

    return (
        <ModalContent direction="row" className="link-modal">
            {/*
             empty onChange to disable changing, but enable selecting and copying the content
            */}
            <TextInput value={resetLink} onChange={() => {}} />

            <CopyToClipboard text={resetLink} onCopy={handleCopiedLink}>
                <Icon name="ClipboardIcon" title="copy to clipboard" />
            </CopyToClipboard>
        </ModalContent>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        token: selectors.Data.UserManagement.currentPasswordToken(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        removeToken: () => dispatch(actions.Data.UserManagement.removePasswordToken()),
        showCopyNotification: () => dispatch(actions.UI.Notification.show({ id: 'copiedToClipboard' })),
        hideCopyNotification: () => dispatch(actions.UI.Notification.dismiss()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkModal)
