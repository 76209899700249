import React, { useContext } from 'react'
import uuid from 'uuid'

import produce from 'immer'
import Button from '../../Atoms/Button/Button'
import Icon from '../../Atoms/Icon/Icon'
import FieldFilterInput, { FieldKeys, OnTriggerAutoCompletion } from '../../Atoms/FieldFilterInput/FieldFilterInput'
import TextInput from '../../Atoms/TextInput/TextInput'
import { getFieldFilterTitle } from '../../Atoms/FieldFilterInput/FieldFilterManifest'
import { FieldsConfigurationContext } from '../../Atoms/FieldsConfiguration/FieldsConfigurationContext'
import { QuickFilter } from '../../Organisms/Widgets/QuickFilterWidget/QuickFilterWidget.types'

const QuickFilterEditor: React.FunctionComponent<{
    widgetConfiguration: {
        title: string
        filters: Array<QuickFilter>
    }
    onChange: (filters: Array<QuickFilter>) => void
    onTriggerAutoCompletion: OnTriggerAutoCompletion
}> = (props) => {
    const fieldsConfiguration = useContext(FieldsConfigurationContext)

    const handleChangeName = (index: number, filters: Array<QuickFilter>) => (name: string) => {
        const newFilters = filters.map((filter, i: number) => {
            if (i === index) {
                return produce(filter, (draft) => {
                    draft.title = name
                })
            }

            return filter
        })

        props.onChange(newFilters)
    }

    const handleAddFilter = () => {
        const filters = props.widgetConfiguration.filters
        const firstField = Object.values(fieldsConfiguration).sort((e1, e2) => {
            const a = e1.label.toLowerCase()
            const b = e2.label.toLowerCase()
            return a > b ? -1 : b > a ? 1 : 0
        })[0]
        const filter = {
            uuid: uuid(),
            filter: {
                field: firstField.name,
                operator: 'equals',
                values: [],
            },
        }
        const newFilters = produce(filters, (draft) => {
            draft.push(filter)
        })
        props.onChange(newFilters)
    }

    const handleFieldFilterChanged = (index: number, field: FieldKeys, newValue: string | string[]) => {
        const oldFilters = props.widgetConfiguration.filters
        const updatedFilters = oldFilters.map((filter, i) => {
            if (i === index) {
                return produce(filter, (draft: QuickFilter) => {
                    switch (field) {
                        case 'field':
                            if (!Array.isArray(newValue)) {
                                draft.filter.field = newValue
                            }

                            break
                        case 'operator':
                            if (!Array.isArray(newValue)) {
                                draft.filter.operator = newValue
                            }

                            break
                        default:
                            if (Array.isArray(newValue)) {
                                draft.filter.values = newValue
                            }

                            break
                    }
                })
            }

            return filter
        })

        props.onChange(updatedFilters)
    }

    const handleFilterRemoved = (i: number) => {
        const oldFilters = props.widgetConfiguration.filters
        const updatedFilters = oldFilters.filter((filter, index) => i !== index)
        props.onChange(updatedFilters)
    }

    const filters = props.widgetConfiguration.filters

    return (
        <React.Fragment>
            {filters.map((filter, i) => {
                return (
                    <div key={'filter-' + i} className="quick-filter-editor">
                        <FieldFilterInput
                            key={i}
                            index={i}
                            field={filter.filter}
                            hasPermissionFilters={false}
                            onTriggerAutoCompletion={props.onTriggerAutoCompletion}
                            onFilterChanged={handleFieldFilterChanged}
                            onFilterRemoved={handleFilterRemoved}
                            isDocumentFieldClearable={false}
                        />
                        <small>Filter Title</small>
                        <TextInput
                            className="quick-filter-title"
                            value={filter.title}
                            placeholder={getFieldFilterTitle(filter.filter, fieldsConfiguration)}
                            onChange={handleChangeName(i, filters)}
                        />
                    </div>
                )
            })}
            {/*
            // dataTestId is a specific selector for the e2e tests
            */}
            <Button onClick={handleAddFilter} dataTestId="add-quick-filter-button">
                <Icon name="FilterIcon" color="green" label="Add new filter" />
            </Button>
        </React.Fragment>
    )
}

export default QuickFilterEditor
