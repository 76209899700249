import React from 'react'
import classnames from 'classnames'
import Button from '../../Atoms/Button/Button'
import { ButtonType } from '../../Atoms/Button/Button.types'
import HoverTooltip from './HoverTooltip'

const ButtonWithTooltip: React.FunctionComponent<{
    className?: string
    onClick: (arg?: any) => void
    type?: ButtonType
    title?: string
    isSquare?: boolean
    isDisabled: boolean
    content?: Array<string> | string
    header?: string
    children?: any
}> = (props) => {
    const className = classnames('button', props.className, {
        ['button--' + props.type]: props.type,
        'button--secondary': !props.type, // default,
        'button--square': props.isSquare,
        'button--disabled': props.isDisabled,
    })

    return (
        <HoverTooltip
            isVisible={props.isDisabled}
            header={props.header}
            content={props.content}
            className="button--with-tooltip"
        >
            <Button
                className={className}
                onClick={props.onClick}
                isDisabled={props.isDisabled}
                title={props.title}
                type={props.type}
            >
                {props.children}
            </Button>
        </HoverTooltip>
    )
}

export default ButtonWithTooltip
