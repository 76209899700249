import { defaultAnalysisTerm, getDefaultAnalysisTermWithField } from '../DefaultConfigurations'
import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

const createDefaultConfiguration = () => {
    return {
        pivotFields: [
            {
                field: 'projectName',
            },
        ],
        valueField: { analysisTerm: defaultAnalysisTerm, filter: [] as Array<FieldType>, dateField: 'primaryDate' },
        numberOfDecimals: 2,
        showLegend: false,
        title: 'Sun Burst Chart',
    }
}

export default createDefaultConfiguration

export const SunBurstConfigurationInitializerForWidgetExplorer = ({
    valueFields,
    categoryFields,
    dateFields,
    filters,
}: {
    valueFields: Array<string>
    categoryFields: Array<string>
    dateFields: Array<string>
    filters: Array<FieldType>
}) => {
    const defaultConfiguration = createDefaultConfiguration()

    defaultConfiguration.valueField = {
        analysisTerm: getDefaultAnalysisTermWithField(valueFields[0]),
        filter: filters,
        dateField: dateFields[0],
    }

    defaultConfiguration.pivotFields = categoryFields.map((field) => ({ field }))

    return defaultConfiguration
}
