import React from 'react'
import classnames from 'classnames'

type StatusTypes =
    | boolean
    | 'Running'
    | 'Erroneous'
    | 'Inconsistent'
    | 'Outdated'
    | 'Removed'
    | 'Healthy'
    | 'Deactivated'

const StatusIndicator: React.FunctionComponent<{
    status: StatusTypes
}> = (props) => {
    let status

    if (props.status === true) {
        status = 'Healthy'
    } else if (props.status === false) {
        status = 'Erroneous'
    } else {
        status = props.status
    }

    const statusClassName = classnames('status-indicator__status', {
        ['status-indicator__status--' + status]: status,
    })

    return (
        <div className="status-indicator" title={status}>
            <div className={statusClassName} />
        </div>
    )
}

export default StatusIndicator
