import React from 'react'
import Select from '../../Atoms/Select/Select'
import { DEFAULT_WORKING_DAYS } from '../../../StaticManifests/manifest.dateRanges'
import { WorkingDays } from '../../../Redux/Data/types'

const WorkingDaySelector: React.FunctionComponent<{
    value?: WorkingDays
    onChange: (value: WorkingDays) => void
}> = (props) => {
    const weekDays = [
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 },
        { label: 'Sunday', value: 0 },
    ]

    const workingDays = props.value || DEFAULT_WORKING_DAYS

    return (
        <Select
            isMulti
            options={weekDays}
            value={workingDays}
            onChange={props.onChange}
            isClearable={false}
            closeMenuOnSelect={false}
        />
    )
}

export default WorkingDaySelector
