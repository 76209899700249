import React, { useState } from 'react'
import TextInput from '../../Atoms/TextInput/TextInput'
import FileUpload, { File, HandleFileUploadDone, HandleFileUploadProgress } from '../FileUpload/FileUpload'
import Headline from '../../Atoms/Headline/Headline'

type Option = {
    id: string
    label: string
}

type SourceSelectionOptions = 'upload' | 'url' | 'path'

const options: Record<SourceSelectionOptions, Option> = {
    upload: {
        id: 'upload',
        label: 'Upload a file',
    },
    url: {
        id: 'url',
        label: 'Enter URL',
    },
    path: {
        id: 'path',
        label: 'Enter path on server',
    },
}

/**
 * allows to select an importer source file from
 * - server disk
 * - local disk
 * - URL
 *
 * output format
 * { path: "(upload|https?|file):://<path>>", name: "<display name>" }
 */
const SourceSelector: React.FunctionComponent<{
    onStartUpload: (file: File, handleProgress: HandleFileUploadProgress, handleDone: HandleFileUploadDone) => void
    onChange: (result: File) => void
    value?: File
    allowedOptions?: Array<SourceSelectionOptions>
}> = (props) => {
    const [active, setActive] = useState(
        props.value?.name || (!props.allowedOptions ? 'upload' : props.allowedOptions[0])
    )

    const renderUploadForm = () => <FileUpload {...props} onStartUpload={props.onStartUpload} onChange={handleChange} />

    const renderPathForm = (placeholder: string) => (
        <TextInput
            {...props}
            value={props.value && props.value.path}
            placeholder={placeholder}
            onChange={handleTextChange}
        />
    )

    const renderPathWithLoginForm = (placeholder: string) => (
        <div className="path-with-login-form">
            <TextInput
                {...props}
                value={props.value && props.value.path}
                placeholder={placeholder}
                onChange={handleTextChange}
            />
            <p>Optional: Credentials</p>
            <TextInput value={props.value?.user} onChange={handleCredentialsChange('user')} placeholder={'user'} />
            <TextInput
                value={props.value?.password}
                onChange={handleCredentialsChange('password')}
                placeholder={'password'}
                type="password"
            />
        </div>
    )

    const handleTextChange = (value: string) => handleChange({ name: 'file-upload', path: value })

    const handleCredentialsChange = (field: 'user' | 'password') => (value: string) => {
        handleChange({
            ...props.value,
            name: 'url',
            [field]: value,
        })
    }

    const handleChange = (value: File) => props.onChange(value)

    const renderSelection = () =>
        Object.entries(options)
            .filter(
                (entry) =>
                    !props.allowedOptions || props.allowedOptions.some((allowedOption) => allowedOption === entry[0])
            )
            .map((entry) => {
                const option = entry[1]
                return (
                    <div key={`source-type-${option.id}`} className="source-selector__radio">
                        <input
                            type="radio"
                            id={option.id}
                            name="select-source"
                            value={option.id}
                            onChange={handleToggle}
                            checked={option.id === active}
                        />
                        <label htmlFor={option.id} className="source-selector__radio--label">
                            {option.label}
                        </label>
                    </div>
                )
            })

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) =>
        setActive(event.target.value as SourceSelectionOptions)

    const renderForm = () => {
        const keys = Object.entries(options).map((option) => option[1].id)
        switch (active) {
            case keys[0]:
                return renderUploadForm()
            case keys[1]:
                return renderPathWithLoginForm('URL to file')
            case keys[2]:
                return renderPathForm('path on server')
            default:
                return null
        }
    }

    return (
        <React.Fragment>
            <Headline configuration={{ level: 3, title: 'Select source' }} />
            <div className="source-selector__radio-wrapper">{renderSelection()}</div>
            <div className="source-selector__form">{renderForm()}</div>
        </React.Fragment>
    )
}

export default SourceSelector
