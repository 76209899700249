import { delay, put, select, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { actions, selectors } from '../../Redux'
import axios from '../Utility/axios'
import { FieldsUIConfiguration } from '../../Redux/Data/types'

export default function* SaveFieldsCustomizationDataSaga() {
    yield takeLatest(actions.Data.FieldsCustomization.saveConfiguration.type, function* () {
        try {
            const userConfiguration: FieldsUIConfiguration = yield select(
                selectors.Data.FieldsCustomization.getTransientConfig
            )

            const response: AxiosResponse = yield axios({
                method: 'POST',
                url: 'admin/typesUiConfiguration',
                data: userConfiguration,
            })

            if (response.status < 400) {
                // Since we modify a part of the state that is completely immutable during application runtime,
                // we want to trigger a hard page reload here. That causes the config.js file to be reloaded.
                // The success message should be visible for a few seconds, so we delay here.
                yield delay(1000)
                window.location.href = window.location.protocol + '//' + window.location.host
            } else {
                yield put(actions.UI.Notification.show({ id: 'typesUiSaveFailed' }))
            }
        } catch (e) {
            console.warn(e)
        }
    })
}
