import React, { PureComponent } from 'react'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import enhanceWithClickOutside from 'react-click-outside'
import moment, { Moment } from 'moment'
import uuid from 'uuid'

import Select from '../../Atoms/Select/Select'
import DateRangeSelect from '../../Atoms/DateRangeSelect/DateRangeSelect'
import { DateObj, DateRangeObject, DateSelectorProps, DateSelectorState } from './DateSelector.types'
import dateRanges, { DateRangeId } from '../../../StaticManifests/manifest.dateRanges'

class DateSelector extends PureComponent<DateSelectorProps, DateSelectorState> {
    static defaultProps = {
        showPredefinedTimeSpan: true,
    }

    // !!! IMPORTANT !!!
    // server and frontend handle dates differently, for example: Mo-Fr
    // server:   start: Mon, 00:00:00, end: Sat, 00:00:00
    // frontend: start: Mon, 12:00:00, end: Fri, 23:59:59

    constructor(props: DateSelectorProps) {
        super(props)

        this.state = {
            focusedInput: null,
            startDate: props.startDate ? props.startDate.add(12, 'hours') : null,
            endDate: props.endDate,
            dateRangeValue: props.dateRangeValue,
            viewportWidth: window.innerWidth,
            isDateRangeSelectOpen: false,
        }
    }

    isSameDay = (a?: DateObj, b?: DateObj) => {
        if (!moment.isMoment(a) || !moment.isMoment(b)) {
            return false
        }

        // Compare least significant, most likely to change units first
        // Moment's isSame clones moment inputs and is a tad slow
        return a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
    }

    findPreset = (startDate: DateObj, endDate?: DateObj) => {
        const presets = Object.entries(dateRanges).map(([key, value]) => ({ ...value, id: key }))
        return presets.find((preset) => {
            const dateRange = preset.getDateRange({
                workingDays: this.props.workingDays,
                showLastXDays: this.props.showLastXDays,
            })
            if (
                this.isSameDay(startDate, dateRange.startDate) &&
                this.isSameDay(endDate, dateRange.endDate) &&
                this.props.availablePredefinedTimespans?.includes(preset.id as DateRangeId)
            ) {
                return preset
            }

            return null
        })
    }

    onDateChange = (startDate: DateObj, endDate: DateObj) => {
        startDate?.startOf('day')
        const endDateExcluded = endDate?.clone().add(1, 'days').startOf('day')
        const dateRange = { startDate, endDate: endDateExcluded }
        const preset = this.findPreset(startDate, endDateExcluded)
        if (preset) {
            return this.setState(
                {
                    dateRangeValue: preset.id as DateRangeId,
                    startDate,
                    endDate,
                },
                // @ts-ignore
                this.props.onDateChange({ ...dateRange, selectedPredefinedTimespan: preset.id })
            )
        }

        return this.setState(
            {
                dateRangeValue: null,
                startDate,
                endDate,
            },
            // @ts-ignore
            this.props.onDateChange(dateRange)
        )
    }

    // used by react-click-outside
    // noinspection JSUnusedGlobalSymbols
    handleClickOutside() {
        if (!this.state.endDate) {
            this.setState({ endDate: this.state.startDate })
        }
    }

    handlePresetSelectionChange = (value: DateRangeId) => {
        const dateRange = dateRanges[value].getDateRange({
            workingDays: this.props.workingDays,
            showLastXDays: this.props.showLastXDays,
        })

        this.setState({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            dateRangeValue: value,
        })
        this.props.onDateChange({ ...dateRange, selectedPredefinedTimespan: value })
    }

    handleDateSelectionChange = ({ startDate, endDate }: DateRangeObject) => {
        if (!startDate) {
            return
        }

        if (this.state.focusedInput === 'endDate' && endDate === null) {
            // startDate set, now selecting endDate, for server: startDate - startDate
            // @ts-ignore TODO look into these callbacks
            this.setState({ startDate, endDate: null, dateRangeValue: null }, this.onDateChange(startDate, startDate))
        } else if (this.state.focusedInput === 'endDate') {
            // startDate and endDate set, for server startDate - endDate
            // @ts-ignore
            this.setState({ startDate, endDate, dateRangeValue: null }, this.onDateChange(startDate, endDate))
        } else if (this.state.endDate && startDate.isBefore(this.state.endDate)) {
            // changed the startDate into something before the endDate - keep the old endDate
            // @ts-ignore
            this.setState(
                { startDate, endDate: this.state.endDate, dateRangeValue: null },
                // @ts-ignore
                this.onDateChange(startDate, endDate)
            )
        } else {
            // @ts-ignore
            this.setState({ startDate, endDate: null, dateRangeValue: null }, this.onDateChange(startDate, startDate))
        }
    }

    toggleDateRangeSelect = () => {
        if (this.props.onDropDownOpen && this.props.onDropDownClose) {
            const isOpen = this.state.isDateRangeSelectOpen
            this.setState(
                { isDateRangeSelectOpen: !isOpen },
                isOpen ? () => this.props.onDropDownClose : () => this.props.onDropDownOpen
            )
        }
    }

    handleMouseOutside = () => {
        if (this.state.isDateRangeSelectOpen && this.props.onDropDownOpen) {
            this.props.onDropDownOpen()
        }
    }

    getInitialCalendarMonth = () => {
        if (this.state.focusedInput === 'startDate' && this.state.startDate) {
            return this.state.startDate
        }

        if (this.state.focusedInput === 'endDate' && this.state.endDate) {
            return this.state.endDate
        }

        return moment()
    }

    render() {
        const dateFormat = 'DD/MM/YYYY'
        this.handleMouseOutside()

        // The DateRange Picker wants the end date to be included
        // The Server needs the endDate to be excluded
        // So we always use the excluded endDate (01.05.2019 00:00:00 instead of 30.04.2019 23:59:59)
        // And subtract it here for the date range picker
        const endDateForComponent = moment.isMoment(this.state.endDate)
            ? this.state.endDate.subtract(1, 'second')
            : this.state.endDate

        const setFocus = (focusedInput: 'startDate' | 'endDate' | null) => {
            if (focusedInput === null) {
                ;(document.activeElement as HTMLElement).blur() // fix endDate field not losing focus after typing date - prevent crash
            }

            this.setState({ focusedInput })
        }

        const inputIds = {
            startDate: uuid(),
            endDate: uuid(),
        }

        const keepInputFocusedOnMonthNavigation = () => {
            if (this.state.focusedInput) {
                document.getElementById(inputIds[this.state.focusedInput])?.focus()
            }
        }

        return (
            <div className="date-selector">
                {this.props.showPredefinedTimeSpan && (
                    <DateRangeSelect
                        availablePredefinedTimespans={this.props.availablePredefinedTimespans}
                        className="time-selector-widget__preset-selector"
                        onChange={this.handlePresetSelectionChange}
                        onDropDownOpen={this.toggleDateRangeSelect}
                        onDropDownClose={this.toggleDateRangeSelect}
                        value={this.props.dateRangeValue}
                        showLastXDays={this.props.showLastXDays}
                    />
                )}
                <DateRangePicker
                    startDate={this.state.startDate}
                    startDateId={inputIds.startDate}
                    endDate={endDateForComponent}
                    endDateId={inputIds.endDate}
                    onDatesChange={this.handleDateSelectionChange}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={setFocus}
                    firstDayOfWeek={1}
                    showDefaultInputIcon={false}
                    displayFormat={dateFormat}
                    isOutsideRange={() => false}
                    hideKeyboardShortcutsPanel
                    customArrowIcon={<span />}
                    minimumNights={0}
                    initialVisibleMonth={this.getInitialCalendarMonth}
                    renderMonthElement={({ month, onMonthSelect, onYearSelect }) =>
                        renderMonthAndYearDropdown(month, onMonthSelect, onYearSelect)
                    }
                    daySize={36}
                    numberOfMonths={1}
                    onPrevMonthClick={keepInputFocusedOnMonthNavigation}
                    onNextMonthClick={keepInputFocusedOnMonthNavigation}
                    endDatePlaceholderText={this.state.startDate ? this.state.startDate.format(dateFormat) : 'End Date'}
                    onClose={this.props.onDropDownClose}
                />
            </div>
        )
    }

    componentDidUpdate(prevProps: DateSelectorProps, prevState: DateSelectorState) {
        if (prevProps.dateRangeValue !== this.props.dateRangeValue) {
            this.setState({ dateRangeValue: this.props.dateRangeValue })
        }

        // @ts-ignore sometimes we get 12:00 and sometimes 0:00 so we set comparison granularity
        if (prevProps.startDate && !prevProps.startDate.isSame(this.props.startDate, 'day')) {
            this.setState({ startDate: this.props.startDate })
        }

        // update UI-preview when changing default time range in widget editor
        // conditions as below:
        // 1.) sometimes during editing, endDate is NULL (not set), so we stop right here
        // 2.) the props have updated -> there is an update from outside, e.g. we have set a different default time span
        // 3.) the state has NOT updated -> it really is a update from ONLY outside (props ALWAYS update, since we send every change to the server, state only updates from internal changes)
        // in the else if block we catch updates from allTime preset to another default time span:
        // 1. props change from preset allTime to something else / null (custom preset)
        // 2. state did not change (= not an internal update)
        if (
            this.state.endDate &&
            prevState.endDate &&
            prevProps.endDate &&
            !prevProps.endDate.isSame(this.props.endDate || undefined, 'day') &&
            prevState.endDate.isSame(this.state.endDate, 'day')
        ) {
            this.setState({ endDate: this.props.endDate })
        } else if (
            prevProps.dateRangeValue === 'allTime' &&
            this.props.dateRangeValue !== 'allTime' &&
            prevState.dateRangeValue === this.state.dateRangeValue
        ) {
            this.setState({ startDate: this.props.startDate, endDate: this.props.endDate })
        }
    }
}

export const renderMonthAndYearDropdown = (month: Moment, onMonthSelect: any, onYearSelect: any) => {
    const months = moment.months().map((label, value) => {
        return { value, label }
    })

    const years = []
    const now = moment()

    if (month.year() > now.year()) {
        years.push({ value: month.year() + 1, label: (month.year() + 1).toString() })
        for (let i = 0; i < 9; i++) {
            years.push({ value: month.year() - i, label: (month.year() - i).toString() })
        }
    } else {
        years.push({ value: now.year() + 1, label: (now.year() + 1).toString() })
        for (let i = 0; i < 9; i++) {
            years.push({ value: now.year() - i, label: (now.year() - i).toString() })
        }
    }

    return (
        <div className="date-selector__month-year-picker">
            <div className="date-selector__select-month">
                <Select
                    value={month.month()}
                    onChange={(e: any) => onMonthSelect(month, e)}
                    options={months}
                    isClearable={false}
                    isSearchable={false}
                />
            </div>
            <div className="date-selector__select-year">
                <Select
                    value={month.year()}
                    onChange={(e: any) => onYearSelect(month, e)}
                    options={years}
                    isClearable={false}
                    isSearchable={false}
                />
            </div>
        </div>
    )
}

export default enhanceWithClickOutside(DateSelector)
