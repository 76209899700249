import React, { PureComponent } from 'react'
import { withSize } from 'react-sizeme'
import classnames from 'classnames'
import WidgetHeader from '../../../Molecules/WidgetHeader/WidgetHeader'
import ColorUtility from '../../../../Utility/ColorUtility'
import { formatNumberToString } from '../../../../Utility/NumberFormator'
import Icon from '../../../Atoms/Icon/Icon'
import { inTestingMode } from '../../../../Utility/Testing'
import { FontSizes, QuotaTileWidgetProps } from './QuotaTileWidget.types'

const colors = {
    50: 'red',
    80: 'yellow',
    95: 'green',
    100: 'green',
    105: 'green',
    120: 'yellow',
    150: 'red',
}

const colorScale = ColorUtility.colorScale(colors)

class QuotaTileWidget extends PureComponent<QuotaTileWidgetProps> {
    static displayName = 'QuotaTile'

    handleInfoIconClick = () =>
        this.props.openModal({
            modalId: 'quotaTileQuotaDetails',
            parameters: {
                sum: this.props.widgetData.sum,
                expectation: this.props.widgetData.expectation,
                unit: this.props.widgetData.unit,
                timeFrame: this.props.widgetData.timeFrame,
                allocationResults: this.props.widgetData.allocationResults,
            },
        })

    getBackgroundColor: () => string = () => {
        const sum = this.props.widgetData.sum
        const expectation = this.props.widgetData.expectation
        if (this.props.widgetConfiguration.color) {
            return this.props.widgetConfiguration.color.replace(/[^#a-z0-9]/gi, '')
        }

        if (expectation && this.props.widgetConfiguration.colorScale?.ranges) {
            const value = this.props.widgetConfiguration.colorScale.isPercentage
                ? ((sum || 0) / expectation) * 100
                : sum || 0
            const range = this.props.widgetConfiguration.colorScale.ranges.find((r) => {
                if (r.upperBound) {
                    return r.lowerBound <= value && r.upperBound > value
                }

                return r.lowerBound <= value
            })
            if (range) {
                return range.color
            }
        }

        if (expectation) {
            const percent = ((sum || 0) / expectation) * 100
            return colorScale(percent)
        }

        return '#1f77b4'
    }

    render = () => {
        const title = this.props.widgetConfiguration.title
        return (
            <div
                className={classnames('widget', {
                    'widget--has-header': title || this.props.onChangeTitle,
                })}
            >
                <WidgetHeader title={title} onChangeTitle={this.props.onChangeTitle} />
                {this.renderWidget()}
            </div>
        )
    }

    renderWidget = () => {
        const backgroundColor = this.getBackgroundColor()
        const isLight = ColorUtility.isLight(backgroundColor.toString())
        const fontSizes = this.calcFontSize()
        return (
            <div
                className={classnames('quota-tile-widget', {
                    'quota-tile-widget--dark': !isLight,
                    'quota-tile-widget--light': isLight,
                })}
                style={{ width: '100%', height: '100%', background: backgroundColor }}
            >
                <div>
                    {this.renderSum(isLight, fontSizes)}
                    {this.renderRemainder(fontSizes)}
                </div>
            </div>
        )
    }

    renderSum(backgroundIsLight: boolean, fontSizes: FontSizes) {
        const sum = this.props.widgetData.sum
        const expectation = this.props.widgetData.expectation
        const hasExpectation = expectation !== null && expectation !== undefined
        const numberOfDecimals = this.props.widgetConfiguration.numberOfDecimals
        const unit = this.props.widgetData.unit
        return (
            <div className="quota-tile-widget__result" style={{ fontSize: fontSizes.top }}>
                {formatNumberToString(sum, numberOfDecimals, unit)}

                {hasExpectation && (
                    <small className="quota-tile-widget__result-expectation" style={{ fontSize: fontSizes.small }}>
                        of {formatNumberToString(expectation, numberOfDecimals, unit)}
                    </small>
                )}

                <Icon
                    name="QuestionIcon"
                    color={backgroundIsLight ? undefined : 'white'}
                    onClick={this.handleInfoIconClick}
                    size="small"
                />
            </div>
        )
    }

    renderRemainder(fontSizes: FontSizes) {
        const widgetData = this.props.widgetData
        const remainingWorkingDays = widgetData.remainingWorkingDays
        const remainingPerDay = widgetData.remainingPerDay
        const timeFrame = widgetData.timeFrame
        if (!timeFrame) {
            return (
                <div className="quota-tile-widget__remaining" style={{ fontSize: fontSizes.bottom }}>
                    Please add Quota.
                </div>
            )
        }

        if (remainingWorkingDays && remainingWorkingDays > 0) {
            const sum = widgetData.sum
            const expectation = widgetData.expectation
            const unit = this.props.widgetData.unit
            if (sum !== undefined && expectation !== undefined && sum < expectation) {
                const numberOfDecimals = this.props.widgetConfiguration.numberOfDecimals

                return (
                    <div className="quota-tile-widget__remaining" style={{ fontSize: fontSizes.bottom }}>
                        {formatNumberToString(remainingPerDay, numberOfDecimals, unit)}/d the next{' '}
                        {remainingWorkingDays} d
                    </div>
                )
            }

            return (
                <div className="quota-tile-widget__remaining" style={{ fontSize: fontSizes.bottom }}>
                    0{unit}/d the next {remainingWorkingDays} d (quota exceeded)
                </div>
            )
        }

        if (timeFrame === 'all time') {
            return (
                <div className="quota-tile-widget__remaining" style={{ fontSize: fontSizes.bottom }}>
                    No time frame selected.
                </div>
            )
        }

        return (
            <div className="quota-tile-widget__remaining" style={{ fontSize: fontSizes.bottom }}>
                Time frame is in the past.
            </div>
        )
    }

    calcFontSize = (): FontSizes => {
        if (this.props.size) {
            const { width, height } = this.props.size
            return {
                top: Math.max(1, Math.min(width * 0.008, height * 0.01, 3.5)) + 'rem',
                small: Math.max(1, Math.min(width * 0.004, height * 0.006, 2.5)) + 'rem',
                bottom: Math.max(1, Math.min(width * 0.005, height * 0.006, 2.0)) + 'rem',
            }
        }

        return {
            normal: '3rem',
            small: '1.777rem',
        }
    }
}

export default inTestingMode ? QuotaTileWidget : withSize({ monitorHeight: true })(QuotaTileWidget)
