import { put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { actions } from '../../Redux'
import axios from '../Utility/axios'

export default function* LoadInitialFieldsCustomizationDataSaga() {
    yield takeLatest(actions.Data.FieldsCustomization.loadInitialData.type, function* () {
        try {
            const response: AxiosResponse = yield axios({
                method: 'GET',
                url: 'admin/typesUiConfiguration',
            })

            if (response.status < 400) {
                yield put(actions.Data.FieldsCustomization.configurationDataLoaded(response.data))
            }
        } catch (e) {
            console.warn(e)
        }
    })
}
