// compares to semantic version numbers
export const isNewerVersionThan = (latestVersion: string, currentLocalVersion: string) => {
    const latestVersionNumbers = latestVersion.split('.').map((number) => parseInt(number, 10))
    const localVersionNumbers = currentLocalVersion.split('.').map((number) => parseInt(number, 10))

    // major release
    if (latestVersionNumbers[0] > localVersionNumbers[0]) return true

    // minor release
    if (latestVersionNumbers[0] === localVersionNumbers[0] && latestVersionNumbers[1] > localVersionNumbers[1])
        return true

    // patch
    return (
        latestVersionNumbers[0] === localVersionNumbers[0] &&
        latestVersionNumbers[1] === localVersionNumbers[1] &&
        latestVersionNumbers[2] > localVersionNumbers[2]
    )
}
