import React from 'react'
import classnames from 'classnames'
import { ValueInput } from './FieldFilterManifest'
import NumberPicker from '../NumberPicker/NumberPicker'

export const NumberInput: ValueInput = (props) => {
    const value = props.field.values[0] || ''
    const className = classnames('field-filter-value-number-input', props.className)
    const handleChange = (value: number | null) => {
        if (value === null) {
            props.onValueChanged([])
        } else {
            props.onValueChanged([String(value)])
        }
    }

    return (
        <div className={className}>
            <NumberPicker
                onChange={handleChange}
                value={value}
                hasError={value !== '' && Number.isNaN(Number(value))}
            />
        </div>
    )
}
