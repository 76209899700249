import React from 'react'
import Select from '../../Atoms/Select/Select'
import { DateFormats } from '../../../Redux/Data/types'

const DateFormatSelector: React.FunctionComponent<{
    value: DateFormats
    onChange: (value: DateFormats) => void
}> = (props) => {
    const DateFormats = [
        { label: 'MM/DD/YY', value: 'MM/DD/YY' },
        { label: 'DD/MM/YY', value: 'DD/MM/YY' },
        { label: 'YY/MM/DD', value: 'YY/MM/DD' },
    ]

    const dateFormat = props.value

    return (
        <Select
            options={DateFormats}
            value={dateFormat}
            onChange={props.onChange}
            isClearable={false}
            closeMenuOnSelect={true}
        />
    )
}

export default DateFormatSelector
