import React, { useContext } from 'react'
import DocumentFieldSelect from '../DocumentFieldSelect/DocumentFieldSelect'
import Select from '../Select/Select'
import Icon from '../Icon/Icon'
import { defaultOperators, FieldType, Operator, permissionOperators, valueInputsByTag } from './FieldFilterManifest'
import { UNKNOWN } from '../FieldsConfiguration/FieldsConfigurationTypes'
import { FieldsConfigurationContext } from '../FieldsConfiguration/FieldsConfigurationContext'

export type FieldKeys = 'field' | 'operator' | 'values'

export type OnTriggerAutoCompletion = (
    fieldType: string | null,
    value: string,
    exclude: Array<string>,
    callback: (suggestions: Array<string>) => void
) => void

const FieldFilterInput: React.FunctionComponent<{
    index: number
    field: FieldType
    hasPermissionFilters?: boolean
    onTriggerAutoCompletion: OnTriggerAutoCompletion
    onFilterChanged: (index: number, field: FieldKeys, newValue: any) => void
    onFilterRemoved: (index: number) => void
    isDocumentFieldClearable?: boolean
}> = (props) => {
    const handleFilterChanged = (field: FieldKeys) => (newValue: string | Array<string>) =>
        props.onFilterChanged(props.index, field, newValue)
    const buildValuesFromAvailableOperators = (operators: Array<Operator>) =>
        operators.map((o) => ({
            value: o.name,
            label: o.name,
        }))

    const fieldsConfiguration = useContext(FieldsConfigurationContext)

    const fieldName = props.field.field
    const fieldConfig = fieldName ? fieldsConfiguration[fieldName] : undefined
    const fieldTag = fieldConfig ? fieldConfig.tag : UNKNOWN

    const availableOperators = props.hasPermissionFilters
        ? defaultOperators.concat(permissionOperators)
        : defaultOperators
    const compatibleOperators = availableOperators.filter((o) => o.isCompatibleWith(fieldTag))
    const currentOperator = compatibleOperators.find((op) => op.name === props.field.operator)
    const fallbackOperator = compatibleOperators[0]
    if (!currentOperator) {
        // auto-adjust invalid settings, e.g. after the user changed the field form "Logged Hours" to "Creation Date"
        handleFilterChanged('operator')(fallbackOperator.name)
    }

    const operator = currentOperator || fallbackOperator

    const ValueInput = operator.hasValues ? valueInputsByTag[fieldTag] : undefined

    const handleFilterRemoved = (index: number) => () => props.onFilterRemoved(index)
    return (
        <div key={'filter-' + props.index} className="filter-group">
            <Icon name="FilterIcon" color="green" />
            <div className="filter-group__inputs">
                <DocumentFieldSelect
                    tags={['DATE', 'NUMBER', 'STRING_SUITABLE_FOR_GROUPING']}
                    className="filter-group__field"
                    value={fieldName}
                    isValid={Boolean(fieldName ? fieldName.length : false)}
                    onChange={handleFilterChanged('field')}
                    clearable={props.isDocumentFieldClearable}
                    smallChevron
                />
                <Select
                    className="filter-group__operator"
                    isClearable={false}
                    isValid={Boolean(operator)}
                    value={operator ? operator.name : undefined}
                    options={buildValuesFromAvailableOperators(compatibleOperators)}
                    onChange={handleFilterChanged('operator')}
                    placeholder="operator"
                    smallChevron
                />
                {ValueInput && (
                    <ValueInput
                        className="filter-group__operand"
                        field={props.field}
                        onTriggerAutoCompletion={props.onTriggerAutoCompletion}
                        onValueChanged={handleFilterChanged('values')}
                    />
                )}
            </div>
            <Icon
                className="filter-group__reset"
                name="TrashIcon"
                color="error"
                onClick={handleFilterRemoved(props.index)}
            />
        </div>
    )
}

export default FieldFilterInput
