import React from 'react'
import classnames from 'classnames'

import { withSize } from 'react-sizeme'
import { formatNumberToString } from '../../../../Utility/NumberFormator'
import { BarLabelProps } from './RowChartWidget.types'

const RowChartBarLabel: React.FC<BarLabelProps> = (props) => {
    const handleBarClick = () => {
        const key = props.chartData[props.index!].key
        props.onBarClick!({ key })
    }

    const key = props.chartData[props.index!].key
    const valueFieldResult = props.stackId ? props.chartData[props.index!].valueFieldResults[props.stackId] : null
    const multiValueLabel = valueFieldResult?.barLabel
    const unit = valueFieldResult?.unit || ''
    const percentage = valueFieldResult?.percentage
    const formattedPercentage = percentage ? formatNumberToString(percentage, props.decimals || 0) : null
    const label = multiValueLabel || key
    // props.value contains the cumulative sum of the bars in the stack.
    // Since we are only rendering the last label, props.value already contains the sum (calculated by recharts)
    const value = formatNumberToString(props.value, props.decimals === undefined ? 0 : props.decimals)

    const className = classnames('row-chart-widget__bar-label', {
        'row-chart-widget__bar-label--selected': props.selection!.includes(key),
    })

    if (props.height! < 10 && !props.isVertical) {
        return null
    }

    if (props.isVertical) {
        if (props.width! < 20 || props.value === 0) {
            return null
        }

        const splitLine = !props.showOnlyPercentage && props.size!.width > props.width!
        const { width, size } = props
        const x = props.x! + (width! - size!.width) / 2
        const fontSize = Math.max(8, Math.min(14, width! * 0.25))
        return (
            <text
                className={className}
                x={x}
                y={splitLine && props.showPercentages && !props.showOnlyPercentage ? props.y! - 25 : props.y! - 5}
                onClick={handleBarClick}
                fontSize={fontSize}
                fill={props.color}
            >
                {splitLine ? (
                    <React.Fragment>
                        {!props.showOnlyPercentage && <tspan x={x}>{`${value}${unit}`}</tspan>}
                        {formattedPercentage && (props.showPercentages || props.showOnlyPercentage) && (
                            <tspan x={x} dy={fontSize + 5} className="row-chart-widget__bar-label__percent">
                                {formattedPercentage}%
                            </tspan>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {!props.showOnlyPercentage && `${value}${unit} `}
                        {formattedPercentage && (props.showPercentages || props.showOnlyPercentage) && (
                            <tspan className="row-chart-widget__bar-label__percent--of-bar">
                                {!props.showOnlyPercentage && ' |'}
                                {formattedPercentage}%
                            </tspan>
                        )}
                    </React.Fragment>
                )}
            </text>
        )
    }

    return (
        <text
            className={className}
            x={props.xOffset! + 22}
            y={props.y! + props.height! / 2 + 5}
            dy={props.height! < 10 ? -2 : undefined}
            onClick={handleBarClick}
            fontSize={Math.max(8, Math.min(18, props.height! * 0.5))}
        >
            {`${label} [`}
            {!props.showOnlyPercentage && `${value}${unit} `}
            {formattedPercentage && (props.showPercentages || props.showOnlyPercentage) && (
                <tspan className="row-chart-widget__bar-label__percent">
                    {!props.showOnlyPercentage && ' | '}
                    {formattedPercentage}%
                </tspan>
            )}
            ]
        </text>
    )
}

export default withSize()(React.memo(RowChartBarLabel))
