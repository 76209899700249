import React from 'react'
import { EditDataSourceContainerOwnProps, EditDataSourceContainerReduxProps } from './EditDataSourceContainer'
import EditDataSourceStepManager from '../../Components/Organisms/DataSources/EditDataSourceStepManager/EditDataSourceStepManager'
import {
    getExtensionConfigurationSteps,
    getImporterConfigurationSteps,
} from '../../Components/Organisms/DataSources/Helpers'
import importerTypes, { ImporterType, ImporterTypes } from '../../StaticManifests/manifest.importerTypes'
import importerExtensionTypes, {
    ImporterExtensionType,
    ImporterExtensionTypes,
} from '../../StaticManifests/manifest.importerExtensionTypes'

type Props = EditDataSourceContainerReduxProps & EditDataSourceContainerOwnProps

/**
 * A Data Source can be an {@link ImporterConfiguration} (original source of Data) or an {@link PostProcessorConfiguration}
 * (called Extension here) of an Importer (additional Data connected to the original Data), which technically is a part
 * of the importer config.
 * Since the editing process of these Data Sources is very similar, they share the following components:
 * 1. Handling Progress {@link EditDataSourceStepManager}
 * 2. Handling Preview {@link EditDataSourcePreviewManager}
 * 3. Displaying Editors & Preview {@link EditDataSourceEditorsAndPreview}
 * 4. Displaying Editors to change configuration {@link EditDataSourceEditors}
 * 5. Displaying Preview {@link EditDataSourcePreview}
 *
 * This component handles the different content / logic for editing an importer or an extension.
 *
 * Note that we currently support only one postProcessor(extension) per importer, but want to support several in
 * the future. That's why we always hard code the first element of our postProcessors list. When this is implemented,
 * the postProcessorSelection becomes the 0th step (currently only ImporterTypeSelector) and should be passed here.
 */

const EditImporterOrExtensionManager: React.FC<Props> = (props) => {
    const isExtension = Boolean(props.isExtension)
    const importerUuid = props.match?.params.importerUuid
    const importer = props.currentlyEditedImporter
    const extension = importer?.postProcessors[0]
    const handleRemove = (extensionIndex: number) => (uuid: string) =>
        isExtension ? props.removeExtension(importer, extensionIndex) : props.removeImporter(uuid)
    const types = isExtension ? importerExtensionTypes : importerTypes
    const type = isExtension ? extension?.type : importer?.definitionId

    const initialStepIndex = getInitialStepIndex(props.initialStepIndex, isExtension, importerUuid)

    const importerHasSteps =
        importer && type
            ? Object.prototype.hasOwnProperty.call((types as ImporterTypes)[type as ImporterType], 'configurationSteps')
            : false

    /*
     * the stepCount is based on the amount of steps in the config of the importer/extension + the importerTypeSelection
     * since the 0th step is hardcoded as importerTypeSelection. As of now, this is skipped by default for the extension
     * by passing initialStepIndex=1. In the future there will probably also be an extensionTypeSelection step
     * (when we support more extensions than csv)
     * if there are no config steps, there at least 2: type selection + minimum run config
     */
    const stepCount =
        (isExtension
            ? type && (types as ImporterExtensionTypes)[type as ImporterExtensionType].configurationSteps.length + 1 // plus the importer type selection
            : importerHasSteps &&
              ((types as ImporterTypes)[type as ImporterType]?.configurationSteps?.length || 0) + 1) || // plus the importer type selection
        2 // if there are no configSteps, there are always 2 steps. (type selection + minimum run config)

    return isExtension ? (
        <EditDataSourceStepManager
            startEditingCallback={props.startEditingExtension}
            title={extension?.title || ''}
            handleTitleChange={props.onChangeExtensionTitle}
            handleRemove={handleRemove(0)}
            currentlyEditedConfiguration={extension}
            typeConfiguration={type ? (types as ImporterExtensionTypes)[type as ImporterExtensionType] : undefined}
            type={type}
            stepCount={stepCount}
            steps={getExtensionConfigurationSteps('joinExtraCsvData')}
            showNoSchedule
            onChangeProperty={props.onChangeExtensionConfigurationProperty}
            fetchPreviewData={props.fetchExtensionStepData}
            {...props}
            initialStepIndex={initialStepIndex}
        />
    ) : (
        <EditDataSourceStepManager
            startEditingCallback={props.startEditingConfiguration}
            title={importer?.title || ''}
            handleTitleChange={props.onChangeTitle}
            handleRemove={handleRemove(0)}
            currentlyEditedConfiguration={importer}
            typeConfiguration={type ? (types as ImporterTypes)[type as ImporterType] : undefined}
            importerTypes={types as ImporterTypes}
            type={type}
            stepCount={stepCount}
            steps={getImporterConfigurationSteps(type as ImporterType)}
            onChangeProperty={props.onChangeImporterConfigurationProperty}
            fetchPreviewData={props.fetchImporterStepData}
            {...props}
            initialStepIndex={initialStepIndex}
        />
    )
}

/**
 * There are several ways to enter the importer editing process:
 * 1) Add a new importer on the Data Source Screen (no importerUuid given)
 * 2) Edit an importer (importerUuid is present)
 * 3) Add a data source extension (uses the same process and components) (isExtension is set)
 * 4) Edit a data source component (basically the same process as creating) (isExtension is set)
 * 5) Upload a file from the start screen (initialStepIndex in redux store is set and connected to the props)
 * (The user can also create an importer via SetUpWizard, but this component is not used there, so it doesn't matter here.)
 *
 * Initial step index in redux store takes precedence.
 * If extension or editing an existing importer we skip the type selection step and start with 1.
 * Otherwise we have a new importer and start with 0 (the importer type selection step).
 */
function getInitialStepIndex(
    initialStepIndexFromReduxStore: number | null,
    isExtension: boolean,
    importerUuid: string | undefined
) {
    return initialStepIndexFromReduxStore !== null
        ? initialStepIndexFromReduxStore
        : isExtension || importerUuid
        ? 1
        : 0
}

export default EditImporterOrExtensionManager
