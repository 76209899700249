import React from 'react'
import Select from '../Select/Select'
import dateRanges, {
    DATE_RANGE_CATEGORIES as categories,
    DateRangeId,
} from '../../../StaticManifests/manifest.dateRanges'

type Props = {
    availablePredefinedTimespans?: Array<DateRangeId>
    value?: DateRangeId
    onChange: (value: DateRangeId) => void
    showLastXDays?: number
    onDropDownOpen?: () => void
    onDropDownClose?: () => void
    multi?: boolean
    clearable?: boolean
    placeholder?: string
    className?: string
}

const DateRangeSelect: React.FunctionComponent<Props> = (props) => {
    const { value, onChange, onDropDownOpen, onDropDownClose, multi, availablePredefinedTimespans, ...restProps } =
        props

    const selectOptions = Object.entries(dateRanges).map(([key, value]) => {
        return {
            label: value.label(props.showLastXDays),
            value: key,
            category: value.category,
        }
    })

    const availableOptions = availablePredefinedTimespans
        ? selectOptions.filter((option: any) =>
              availablePredefinedTimespans.find((availablePTS: string) => availablePTS === option.value)
          )
        : selectOptions

    const groupedOptions = categories.map((category: string) => ({
        label: category,
        options: availableOptions.filter((option: any) => option.category === category),
    }))

    const isDisabled = availablePredefinedTimespans && availablePredefinedTimespans.length < 1

    return (
        <Select
            isClearable={props.clearable}
            isDisabled={isDisabled}
            isGrouped
            isMulti={multi}
            onChange={onChange}
            onMenuClose={onDropDownClose}
            onMenuOpen={onDropDownOpen}
            options={groupedOptions}
            className={props.className}
            placeholder={props.placeholder || (isDisabled ? 'No Presets Available' : 'Time Span Presets')}
            value={value}
            {...restProps}
        />
    )
}

export default DateRangeSelect

export const DateRangeSelectMulti: React.FunctionComponent<Props> = (props) => {
    return (
        <DateRangeSelect
            multi
            placeholder="No time span presets"
            onChange={props.onChange}
            onDropDownClose={props.onDropDownClose}
            onDropDownOpen={props.onDropDownOpen}
            value={props.value}
            showLastXDays={props.showLastXDays}
            clearable
        />
    )
}
