import ScatterPlotWidget from './LazyScatterPlotWidget'
import ScatterPlotWidgetStateInitializer from './ScatterPlotWidget.init'
import ScatterPlotWidgetConfigurationInitializer, {
    ScatterPlotConfigurationInitializerForWidgetExplorer,
} from './ScatterPlotWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import NumberPicker from '../../../Atoms/NumberPicker/NumberPicker'
// @ts-ignore file ext
import ScatterPlotWidgetIcon from './ScatterPlotWidget.Icon.tsx'
import { ScatterPlotWidgetData } from './ScatterPlotWidget.types'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'

const config: WidgetManifestConfiguration = {
    name: 'Scatter Plot',
    component: ScatterPlotWidget,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 1,
        },
        max: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 2,
        },
    },
    icon: ScatterPlotWidgetIcon,
    stateInitializationFunction: ScatterPlotWidgetStateInitializer,
    configurationInitializer: ScatterPlotWidgetConfigurationInitializer,
    configurationInitializerForWidgetExplorer: ScatterPlotConfigurationInitializerForWidgetExplorer,
    renderRequirements: (widgetData?: ScatterPlotWidgetData) => {
        if (widgetData?.negativeValues) {
            return widgetProblems.negativeValues
        }
    },

    editors: {
        data: [
            buildingBlocks.valueField({ areDateRangeFieldsAllowed: true }),
            buildingBlocks.pivotField('X-Axis', 'xAxisPivotField'),
            buildingBlocks.pivotField('Y-Axis', 'yAxisPivotField'),
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    {
                        widgetConfigurationProperty: 'xLimit',
                        component: NumberPicker,
                        editorContextBuilder: () => ({
                            label: 'Maximum Number of Values on the x-Axis',
                            placeholder: '10',
                            min: 1,
                            max: 50,
                            step: 1,
                        }),
                        configuration: {
                            editorIcon: 'MaxXAxisValuesIcon',
                            isIconAlwaysActive: true,
                        },
                    },
                    {
                        widgetConfigurationProperty: 'yLimit',
                        component: NumberPicker,
                        editorContextBuilder: () => ({
                            label: 'Maximum Number of Values on the y-Axis',
                            placeholder: '10',
                            min: 1,
                            max: 50,
                            step: 1,
                        }),
                        configuration: {
                            editorIcon: 'MaxYAxisValuesIcon',
                            isIconAlwaysActive: true,
                        },
                    },
                    buildingBlocks.colorPicker(),
                    buildingBlocks.numberOfDecimals(),
                ],
            },
        ],
    },
}

export default config
