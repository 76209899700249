import { createSelector } from 'reselect'
import { DashboardId, DataState, WidgetGroupId, WidgetId } from '../types'

export const normalizedDashboardConfig = (state: { Data: DataState }) => state.Data.dashboards.normalizedDashboardConfig

// #############################################
//      general dashboard selectors
// #############################################
export const dashboardIds = (state: { Data: DataState }) =>
    state.Data.dashboards.normalizedDashboardConfig.dashboards.allIds
export const dashboardsById = (state: { Data: DataState }) =>
    state.Data.dashboards.normalizedDashboardConfig.dashboards.byId
export const dashboardById = (state: { Data: DataState }, dashboardId: DashboardId) =>
    dashboardsById(state)[dashboardId]

export const allDashboards = createSelector([dashboardIds, dashboardsById], (allIds, byId) =>
    allIds.map((id) => byId[id])
)
// #############################################
//      currentlyEditedDashboard selectors
// #############################################
export const currentlyEditedDashboardId = (state: { Data: DataState }) =>
    state.Data.dashboards.editedDashboard.dashboardId
export const currentlyEditedDashboardConfig = createSelector(
    [dashboardsById, currentlyEditedDashboardId],
    (dashboardsById, currentlyEditedDashboardId) => {
        if (currentlyEditedDashboardId === undefined) {
            return undefined
        }
        return dashboardsById[currentlyEditedDashboardId]
    }
)

// #############################################
//      widgetGroup selectors
// #############################################
export const widgetGroupIds = (state: { Data: DataState }) =>
    state.Data.dashboards.normalizedDashboardConfig.widgetGroups.allIds
export const widgetGroupsById = (state: { Data: DataState }) =>
    state.Data.dashboards.normalizedDashboardConfig.widgetGroups.byId
export const widgetGroupById = (state: { Data: DataState }, widgetGroupId: WidgetGroupId) =>
    widgetGroupsById(state)[widgetGroupId]

// PARAMS: state, dashboardId
export const globalWidgetGroupId = createSelector([dashboardById], (dashboardConfiguration) => {
    return dashboardConfiguration?.globalWidgetGroup
})

// #############################################
//      widget selectors
// #############################################
export const widgetIdsForWidgetGroup = (state: { Data: DataState }, widgetGroupId: WidgetGroupId) => {
    const group = widgetGroupById(state, widgetGroupId)
    return group?.widgets ?? []
}

// selectors for caching
export const widgetsById = (state: { Data: DataState }) => state.Data.dashboards.normalizedDashboardConfig.widgets.byId
export const widgetById = (state: { Data: DataState }, widgetId: WidgetId) => widgetsById(state)[widgetId]

export const widgetsForWidgetGroup = createSelector([widgetIdsForWidgetGroup, widgetsById], (widgetIds, byId) =>
    widgetIds.map((id) => byId[id])
)

export const currentlyEditedWidgetId = (state: { Data: DataState }) => state.Data.dashboards.editedWidget.widgetId
export const currentlyEditedWidgetConfig = createSelector(
    [widgetsById, currentlyEditedWidgetId],
    (widgetsById, currentlyEditedWidgetId) => {
        if (currentlyEditedWidgetId === undefined) {
            return undefined
        }
        return widgetsById[currentlyEditedWidgetId]
    }
)
