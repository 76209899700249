import React from 'react'
import classnames from 'classnames'

const Hamburger: React.FunctionComponent<{
    className?: string
    active: boolean
}> = (props) => {
    const className = classnames('hamburger', props.className, {
        'hamburger--active': props.active,
    })

    return (
        <div className={className}>
            <span className="hamburger__line hamburger__line--top" />
            <span className="hamburger__line hamburger__line--middle" />
            <span className="hamburger__line hamburger__line--bottom" />
        </div>
    )
}

export default Hamburger
