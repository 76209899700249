import { delay, put, select, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions, selectors } from '../../Redux'
import axios from '../Utility/axios'

export const deleteDashboard = createAction(
    'Data/Dashboards/DashboardEditMode/DeleteDashboard',
    (dashboardId: string, navigate?: boolean) => ({ payload: { dashboardId, navigate } })
)

export default function* DeleteDashboardSaga() {
    yield takeLatest(deleteDashboard, function* (action) {
        try {
            const dashboardId = action.payload.dashboardId
            const response: AxiosResponse = yield axios({
                method: 'DELETE',
                url: 'dashboard/' + dashboardId,
            })

            if (response.status < 400) {
                // default is undefined, so we need to check for false
                if (action.payload.navigate !== false) {
                    yield put(push('/'))
                }

                // WORKAROUND! this prevents the browser from rendering a deleted dashboard
                yield delay(0)
                yield put(actions.Data.Dashboards.DashboardEditMode.deleteDashboardSuccess(dashboardId))
                const dashboardGroupIds = selectors.UI.DashbardGroups.groupIdsOfDashboard(yield select(), dashboardId)
                if (dashboardGroupIds.length > 0) {
                    dashboardGroupIds.forEach((id) =>
                        actions.UI.DashboardGroups.removeDashboardFromGroup({
                            groupId: id,
                            dashboardId,
                        })
                    )
                }
            } else {
                yield put(actions.UI.Notification.show({ id: 'dashboardDeleteFailed' }))
            }
        } catch (e) {
            console.error(e)
        }
    })
}
