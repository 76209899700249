import React, { useContext } from 'react'
import DocumentFieldSelect from '../../Atoms/DocumentFieldSelect/DocumentFieldSelect'
import { FieldsConfigurationContext } from '../../Atoms/FieldsConfiguration/FieldsConfigurationContext'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

export const IGNORE_TIME_SELECTION = 'ignoreTimeSelection'

const DateFieldEditor: React.FunctionComponent<{
    value?: string | undefined
    clearable?: boolean
    onChange: (value: string) => void
    isIgnoreTimeSelectionDisabled?: boolean
    areDateRangeFieldsAllowed?: boolean
}> = (props) => {
    const fieldsConfig = useContext(FieldsConfigurationContext)
    const title = Object.values(fieldsConfig).find((e) => e.name === props.value)?.label || 'Select a date field'
    return (
        <div className="date-field widget-editor__input--tiny" title={title}>
            <HoverTooltip isVisible={true} header={title}>
                <DocumentFieldSelect
                    onChange={props.onChange}
                    tags={props.areDateRangeFieldsAllowed ? ['DATE', 'DATE_RANGE'] : ['DATE']}
                    clearable={props.clearable}
                    placeholder={'Please select a date field'}
                    value={props.value}
                    additionalOptions={
                        props.isIgnoreTimeSelectionDisabled
                            ? []
                            : [{ value: IGNORE_TIME_SELECTION, label: 'Ignore Time Selection' }]
                    }
                    displayIconAsValue={'CalendarIcon'}
                    smallChevron
                    searchable={false}
                />
            </HoverTooltip>
        </div>
    )
}

export default React.memo(DateFieldEditor)
