import { put, takeLatest, select } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions, selectors } from '../../Redux'
import { getDashboardDataRequest } from './Utility'
import axios from '../Utility/axios'
import { AccessMode, DashboardId, DashboardRequest } from '../../Redux/Data/types'

export const getExportDashboardSettingsThenExport = createAction(
    'EXPLY/DATA/DASHBOARDS/EXPORT/GET_EXPORT_DASHBOARD_SETTINGS_THEN_EXPORT',
    (format: string, dashboardId: DashboardId, callback: any) => ({ payload: { format, dashboardId, callback } })
)

export function* GetExportDashboardSettingsThenExportSaga() {
    yield takeLatest([getExportDashboardSettingsThenExport], function* (action) {
        const { format, dashboardId, callback } = action.payload
        yield put(exportDashboard(format, dashboardId, true, callback))
    })
}

export const exportDashboard = createAction(
    'EXPLY/DATA/DASHBOARDS/EXPORT/EXPORT_DASHBOARD',
    (format: string | undefined, dashboardUuid: string, includeWidgetData: boolean, callback: any) => ({
        payload: {
            format,
            dashboardUuid,
            includeWidgetData,
            callback,
        },
    })
)

export function* ExportDashboardSaga() {
    yield takeLatest(exportDashboard, function* (action) {
        yield put(actions.UI.Notification.show({ id: 'dashboardExportRunning' }))

        try {
            const { name, location } = yield executeExport(action.payload)
            yield put(actions.UI.Notification.dismiss())
            const accessMode = selectors.Data.System.accessMode(yield select())
            const sharedDashboardId = selectors.Data.Dashboards.ShareDashboard.getSharedDashboardByDashboardId(
                yield select(),
                action.payload.dashboardUuid
            )?.id
            const prefix = accessMode === AccessMode.SHARED_DASHBOARD ? 'export/' + sharedDashboardId : 'export'
            window.location.href = `${prefix}/download/${name}?location=${location}`
        } catch (e) {
            console.error('failed to export dashboard', e)
            yield put(actions.UI.Notification.show({ id: 'dashboardExportFailed' }))
        }

        const { callback } = action.payload
        if (callback) {
            callback()
        }
    })
}

function* executeExport({
    format,
    includeWidgetData,
    dashboardUuid,
}: {
    format: string | undefined
    includeWidgetData: boolean
    dashboardUuid: DashboardId
}) {
    const request: DashboardRequest = yield getDashboardDataRequest()
    const accessMode = selectors.Data.System.accessMode(yield select())
    const sharedDashboardId = selectors.Data.Dashboards.ShareDashboard.getSharedDashboardByDashboardId(
        yield select(),
        dashboardUuid
    )?.id
    const prefix =
        accessMode === AccessMode.SHARED_DASHBOARD ? 'shared-dashboard/export/' + sharedDashboardId : 'export'
    const response: AxiosResponse = yield axios({
        method: 'post',
        url: prefix + '/execute/' + format,
        data: {
            includeWidgetData,
            dashboardRequest: request,
        },
    })
    if (response.status < 400) {
        return response.data
    }

    throw new Error(response.data || 'unknown error')
}
