import CreateOrUpdateUserOrGroupSagas from './CreateOrUpdateUserOrGroupSagas'
import CreateOrUpdateAndCloseSagas from './CreateOrUpdateAndCloseSagas'
import SaveUserPasswordSaga from './SaveUserPasswordSaga'
import RemoveSaga from './RemoveSaga'
import GetPasswordTokenSaga from './GetPasswordTokenSaga'
import SwitchToUserSaga from './SwitchToUserSaga'
import SwitchUserBackSaga from './SwitchUserBackSaga'

export default [
    CreateOrUpdateUserOrGroupSagas,
    CreateOrUpdateAndCloseSagas,
    SaveUserPasswordSaga,
    RemoveSaga,
    GetPasswordTokenSaga,
    SwitchToUserSaga,
    SwitchUserBackSaga,
]
