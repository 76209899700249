import React, { PureComponent } from 'react'
import { getIn } from 'immutable'
import enhanceWithClickOutside from 'react-click-outside'
import { withRouter } from 'react-router-dom'
import { Action, Location } from 'history'
import Button from '../../../Atoms/Button/Button'
import SecondaryHeader from '../../../Molecules/SecondaryHeader/SecondaryHeader'
import ShyTextInput from '../../../Atoms/ShyTextInput/ShyTextInput'
import SecondaryFooter from '../../../Molecules/SecondaryFooter/SecondaryFooter'
import containsGivenObject from '../../../../Utility/HandleClickOutside'
import EditDataSourcePreviewManager from '../EditDataSourcePreviewManager/EditDataSourcePreviewManager'
import { EditDataSourceStepManagerProps } from '../DataSource.types'
import ImporterTypeSelector from '../ImporterTypeSelector/ImporterTypeSelector'
import { ImporterConfiguration, ImporterDefinition, PostProcessorConfiguration } from '../../../../Redux/Data/types'
import {
    ImporterType,
    ImporterTypeConfiguration,
    ImporterTypes,
} from '../../../../StaticManifests/manifest.importerTypes'
import {
    ImporterExtensionType,
    ImporterExtensionTypeConfiguration,
} from '../../../../StaticManifests/manifest.importerExtensionTypes'
import { defaultImporterTitle } from '../../../../Redux/Data/Importers/Editing/ImporterEditing'
import ButtonWithTooltip from '../../../Molecules/HoverTooltip/ButtonWithTooltip'

const TYPE_SELECTION_STEP = 0

type EditDataSourceStepManagerState = {
    currentStepIndex: number
}

/**
 * This component handles the progress of the editing process, e.g. navigating forward/backwards between steps,
 * saving, canceling & removing.
 * More Details in {@link EditImporterOrExtensionManager}
 *
 * TODO simplify the "initialStepIndex" logic
 */
class EditDataSourceStepManager extends PureComponent<EditDataSourceStepManagerProps, EditDataSourceStepManagerState> {
    constructor(props: EditDataSourceStepManagerProps) {
        super(props)
        const importerUuid = props.match?.params.importerUuid

        if (importerUuid) {
            props.startEditingCallback(importerUuid, 0)
        } else {
            props.createNewImporter()
        }

        this.state = {
            currentStepIndex: props.initialStepIndex,
        }
    }

    /*
    we want the browser-back-button to take the user back one step in the data source step,
    not back to the Data Source page. React-Router only allows to intercept all navigations / route changes
    so we have to catch a few cases
     */
    onBackButton = (prompt: Location, action: Action) => {
        // default: go back one step
        if (action === 'POP' && this.state.currentStepIndex > 0) {
            this.handleJumpStep(-1)()
            // re-set url/ route-name to disable forth-navigation
            this.props.history.push(this.props.location.pathname)
            // go back to data source screen on initial step
        } else if (action === 'POP' && this.state.currentStepIndex === 0) {
            this.props.history.block(() => {})
            this.props.history.replace('/importer')
        } else {
            // do not intercept other navigations (cancel, click on exply logo etc.)
            this.props.history.block(() => {})
            this.props.history.push(prompt.pathname)
            this.props.history.block(this.onBackButton)
        }

        return 'true'
    }

    componentDidMount() {
        this.props.history.block(this.onBackButton)
        window.addEventListener('beforeunload', this.onClose)
        window.addEventListener('keypress', this.onPressEnter)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onClose)
        window.removeEventListener('keypress', this.onPressEnter)
    }

    onClose = (event: BeforeUnloadEvent) => {
        if (this.props.changedConfiguration && this.props.reloadConfirmation) {
            event.preventDefault()
            event.returnValue = 'This importer-configuration has got unsaved changes. Are You sure You want to leave?'
        }

        return event
    }

    onPressEnter = (event: KeyboardEvent) => {
        const { typeConfiguration, type, currentlyEditedConfiguration } = this.props
        const configurationHasSteps =
            currentlyEditedConfiguration && type && typeConfiguration
                ? Object.prototype.hasOwnProperty.call(typeConfiguration, 'configurationSteps')
                : false
        const configIsIncomplete = this.isConfigIncomplete(
            typeConfiguration,
            type,
            this.state.currentStepIndex,
            currentlyEditedConfiguration,
            configurationHasSteps
        )
        if (event.key === 'Enter' && !configIsIncomplete) {
            this.handleNext()
        }
    }

    render() {
        const { typeConfiguration, type, currentlyEditedConfiguration } = this.props
        const configurationHasSteps =
            currentlyEditedConfiguration && type && typeConfiguration
                ? Object.prototype.hasOwnProperty.call(typeConfiguration, 'configurationSteps')
                : false

        const currentStepIndex = this.state.currentStepIndex
        const isTypeSelectionStep = currentStepIndex === TYPE_SELECTION_STEP
        const primaryButtonIsDisabled = this.isConfigIncomplete(
            typeConfiguration,
            type,
            currentStepIndex,
            currentlyEditedConfiguration,
            configurationHasSteps
        )

        const disabledMessage =
            (typeConfiguration?.configurationSteps &&
                typeConfiguration.configurationSteps[currentStepIndex - 1] &&
                typeConfiguration.configurationSteps[currentStepIndex - 1].requiredPropertiesMessage) ||
            'Config incomplete'

        return (
            <div className="edit-importer-screen">
                <SecondaryHeader
                    left={
                        <h1>
                            <ShyTextInput
                                placeholder="importer label"
                                value={this.props.title}
                                onChange={this.props.handleTitleChange}
                            />
                        </h1>
                    }
                />
                {this.renderBody(
                    isTypeSelectionStep,
                    type,
                    this.props.importerTypes,
                    this.props.importerDefinitionsById,
                    currentlyEditedConfiguration,
                    currentStepIndex
                )}

                <SecondaryFooter
                    left={
                        <Button type="warning" onClick={this.props.handleCancelEditingImporter(this.props.cancelRoute)}>
                            Cancel
                        </Button>
                    }
                    right={
                        <div>
                            {this.renderBackButton(currentStepIndex)}
                            {this.renderPrimaryButton(
                                this.handleNext,
                                this.getPrimaryButtonLabel(),
                                disabledMessage,
                                primaryButtonIsDisabled
                            )}
                        </div>
                    }
                />
            </div>
        )
    }

    renderBackButton = (currentStepIndex: number) => {
        if (currentStepIndex > this.props.initialStepIndex) {
            return <Button onClick={this.handleJumpStep(-1)}>Back</Button>
        }

        return null
    }

    getPrimaryButtonLabel = () => {
        const { typeConfiguration, currentlyEditedConfiguration } = this.props
        const currentStepIndex = this.state.currentStepIndex

        if (currentStepIndex + 1 < this.props.stepCount) {
            return 'Next'
        }

        // check if there is a custom label for this step
        if (typeConfiguration?.configurationSteps?.[currentStepIndex - 1].customSaveButtonLabel) {
            // check if the condition for this label is set (e.g. entered tempo credentials? show "grant access")
            if (
                typeConfiguration?.configurationSteps?.[currentStepIndex - 1].customSaveButtonLabel?.conditionValidator(
                    currentlyEditedConfiguration as ImporterConfiguration
                )
            ) {
                // TS doesnt get that this will not be falsy since we catch that in the if condition so we provide a fallback
                return typeConfiguration.configurationSteps[currentStepIndex - 1].customSaveButtonLabel?.label || 'Save'
            }
        }

        return 'Save'
    }

    renderPrimaryButton = (
        handler: () => void,
        buttonText: string,
        tooltipText: string,
        primaryButtonIsDisabled: boolean
    ) => (
        <ButtonWithTooltip isDisabled={primaryButtonIsDisabled} header={tooltipText} type="primary" onClick={handler}>
            {buttonText}
        </ButtonWithTooltip>
    )

    renderBody(
        isTypeSelectionStep: boolean,
        type: string | undefined,
        importerTypes: ImporterTypes | undefined,
        importerDefinitions: { [id: string]: ImporterDefinition },
        configuration: any,
        currentStepIndex: number
    ) {
        return (
            <>
                {isTypeSelectionStep && importerTypes ? (
                    <ImporterTypeSelector
                        selectedType={type}
                        importerTypes={Object.entries(importerTypes)
                            .map(([id, configuration]) => ({ ...configuration, id: id as ImporterType }))
                            .filter((configuration) => {
                                const managedByExply = importerDefinitions[configuration.id]?.managedByExply
                                const isVisible =
                                    !configuration.isHidden || this.props.additionalImporters.includes(configuration.id)
                                return !managedByExply && isVisible
                            })}
                        onChange={this.handleCreateNewImporter}
                    />
                ) : (
                    <EditDataSourcePreviewManager
                        currentlyEditedConfiguration={this.props.currentlyEditedConfiguration}
                        currentlyEditedImporter={this.props.currentlyEditedImporter}
                        currentStepIndex={currentStepIndex - 1}
                        onChangeProperty={this.handleChangeProperty}
                        onChangeSchedule={this.props.onChangeSchedule}
                        fetchStepData={this.props.fetchPreviewData}
                        dispatch={this.props.dispatch}
                        type={this.props.type}
                        steps={this.props.steps}
                        showNoSchedule={this.props.showNoSchedule}
                    />
                )}
            </>
        )
    }

    handleNext = () => {
        // the current step index counts the type selection step as the first step, but the steps array does not
        // contain it
        const stepIndex = this.state.currentStepIndex - 1
        const onNextStep =
            this.props.typeConfiguration?.configurationSteps &&
            this.props.typeConfiguration?.configurationSteps[stepIndex] &&
            this.props.typeConfiguration?.configurationSteps[stepIndex].onNextStep
        const defaultNextAction =
            this.state.currentStepIndex + 1 < this.props.stepCount ? this.handleJumpStep(1) : this.handleSaveImporter

        if (this.props.currentlyEditedImporter && onNextStep && this.props.currentlyEditedConfiguration) {
            onNextStep(this.props.currentlyEditedConfiguration, this.props.dispatch, defaultNextAction)
        } else {
            defaultNextAction()
        }
    }

    handleSaveImporter = () => {
        if (this.props.currentlyEditedImporter) {
            const stepIndex = this.state.currentStepIndex - 1
            const callback = this.props.steps[stepIndex].onSaveCallback
            const runImporterAfterSave = this.props.steps[stepIndex].runImporterAfterSave
            const continueEditingImporterAfterSave = this.props.steps[stepIndex].continueEditingImporterAfterSave
            this.props.saveImporter(
                this.props.currentlyEditedImporter,
                runImporterAfterSave,
                continueEditingImporterAfterSave,
                callback
                    ? callback(
                          this.props.currentlyEditedConfiguration as ImporterConfiguration,
                          this.props.typeSpecific
                      )
                    : undefined
            )
        }
    }

    handleJumpStep = (offset: number) => () => this.setState({ currentStepIndex: this.state.currentStepIndex + offset })

    handleCreateNewImporter = (type: ImporterType) => this.props.createNewImporter(type)

    // There is no good location for this logic, but this is the least bad place
    // If there is no custom Importer title, this will use the file's name as title instead by dispatching it
    handleChangeProperty = (propertyPath: string, value: any) => {
        if (this.props.title === defaultImporterTitle) {
            if (propertyPath === 'source') {
                this.props.handleTitleChange(value.name)
            }
        }
        this.props.onChangeProperty(propertyPath, value)
    }

    // used to as user to discard changes
    // noinspection JSUnusedGlobalSymbols
    handleClickOutside = (event: React.MouseEvent) => {
        // catch untrusted events because they are sent by this function
        if (
            containsGivenObject(event, ['row-menu__item', 'application-header__logo']) &&
            this.props.changedConfiguration &&
            this.props.reloadConfirmation &&
            event.isTrusted
        ) {
            event.stopPropagation()
            event.preventDefault()

            if (this.props.currentlyEditedImporter !== null) {
                this.props.onUnsavedLeave(this.props.currentlyEditedImporter, event)
            }
        }
    }

    isConfigIncomplete = (
        definition: ImporterTypeConfiguration | ImporterExtensionTypeConfiguration | undefined,
        type: ImporterType | ImporterExtensionType | undefined,
        currentStepIndex: number,
        configuration: ImporterConfiguration | PostProcessorConfiguration | null | undefined,
        configurationHasSteps: boolean
    ) => {
        if (currentStepIndex === TYPE_SELECTION_STEP) {
            return !type
        }

        // step 0 is the importer type selection, 1st step is the 0st step from the manifest
        const manifestStepIndex = currentStepIndex - 1
        if (
            configurationHasSteps &&
            definition &&
            definition?.configurationSteps &&
            definition?.configurationSteps[manifestStepIndex] &&
            definition.configurationSteps[manifestStepIndex].requiredProperties
        ) {
            const allRequiredPropsComplete = definition.configurationSteps[manifestStepIndex].requiredProperties?.every(
                (requiredProperty) => {
                    const requiredValue: unknown | string | Array<any> = getIn(configuration, [
                        'configuration',
                        ...requiredProperty.propertyPath.split('.'),
                    ])

                    return requiredProperty.propertyValidator(requiredValue)
                }
            )
            return !allRequiredPropsComplete
        }

        return false
    }
}

export default withRouter(enhanceWithClickOutside(EditDataSourceStepManager))
