import LineChartWidget from './LineChartWidget'
// eslint-disable-next-line import/extensions
import LineChartWidgetIcon from './LineChartWidget.Icon'
import lineChartWidgetInit from './LineChartWidget.init'
import lineChartWidgetConfigurationInitializer from './LineChartWidget.configurationInitializer'
import FieldListEditor from '../../../Molecules/FieldListEditor/FieldListEditor'
import {
    flag,
    groupingFieldOptionGenerator,
    numberOfDecimals,
    referenceLine,
    selectOneOf,
} from '../../../../widgetManifestBuildingBlocks'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import WidgetSortingPropField from '../../../Molecules/WidgetSortingPropField'
import NumberPicker from '../../../Atoms/NumberPicker/NumberPicker'
import { multiValueLegendPositionOptions } from '../RowChartWidget/RowChartWidget.manifest'

export default {
    name: 'Line Chart',
    component: LineChartWidget,
    icon: LineChartWidgetIcon,
    stateInitializationFunction: lineChartWidgetInit,
    configurationInitializer: lineChartWidgetConfigurationInitializer,
    editors: {
        data: [
            {
                widgetConfigurationProperty: 'valueFields',
                component: FieldListEditor,
                configuration: {
                    icon: 'AnalyticsIcon',
                    tags: ['NUMBER'],
                    clearable: true,
                    labelFn: ({ ordinal, index }: { ordinal: string; index: number }) =>
                        `${index === 0 ? 'Primary' : ordinal} value`,
                    withFilter: true,
                    optionGenerator: groupingFieldOptionGenerator,
                    includePivotField: true,
                },
            },
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    {
                        component: FormFieldWithLabel,
                        configuration: {
                            editor: WidgetSortingPropField,
                            editorIcon: 'SortingIcon',
                            isIconAlwaysActive: true,
                        },
                    },
                    numberOfDecimals(),
                    {
                        widgetConfigurationProperty: 'maximumNumberOfBars',
                        component: NumberPicker,
                        editorContextBuilder: () => ({
                            label: 'Max Number of Lines',
                            placeholder: '20',
                            min: 2,
                            max: 500,
                            step: 1,
                        }),
                        configuration: {
                            editorIcon: 'NumBarsIcon',
                            isIconAlwaysActive: true,
                        },
                    },
                    flag('showPercentages', 'Show Percentage', 'PercentagesIcon'),
                    selectOneOf(
                        'percentageOf',
                        'Percentage of',
                        [
                            {
                                label: 'Value',
                                value: 'valueField',
                            },
                            {
                                label: 'Category',
                                value: 'pivotField',
                            },
                        ],
                        false,
                        'PercentagesIcon'
                    ),
                    flag('showFurtherValues', 'Show Further Values', 'FurtherValuesRowsIcon'),
                    flag('showOnlyBarGroupsWithPrimaryValue', 'Leading Primary Value', 'LeadingPrimaryValueIcon'),
                ],
            },
            {
                name: 'Label Options',
                editors: [
                    flag('useShortLabels', 'Short Labels', 'ShortLabelsIcon'),
                    flag('showMultiValueLegend', 'Show Multi Value Legend', 'LegendIcon'),
                    selectOneOf(
                        'multiValueLegendPosition',
                        'Multi Value Legend Position',
                        multiValueLegendPositionOptions,
                        false,
                        'LegendIcon',
                        false
                    ),
                ],
            },
            {
                name: 'Reference Lines',
                editors: [referenceLine()],
            },
        ],
    },
}
