import React from 'react'
import { connect } from 'react-redux'
import { Page, PageSection } from '../../Components/Organisms/Page/Page'
import ApplicationHeader from '../ApplicationHeader/ApplicationHeader'
import { RootState } from '../../Redux/types'
import { actions, selectors } from '../../Redux'
import { DashboardId, SharedDashboardId } from '../../Redux/Data/types'
import Headline from '../../Components/Atoms/Headline/Headline'
import Button from '../../Components/Atoms/Button/Button'
import Icon from '../../Components/Atoms/Icon/Icon'
import { AppDispatch } from '../../store'

const mapStateToProps = (state: RootState) => ({
    sharedDashboards: selectors.Data.Dashboards.ShareDashboard.getAllSharedDashboards(state),
    dashboardById: (id: DashboardId) => selectors.Data.Dashboards.dashboardById(state, id),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onShareDashboard: (id: SharedDashboardId) =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'dashboardShare',
                parameters: {
                    dashboardId: id,
                },
            })
        ),
})

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const SharedDashboardsOverview: React.FC<Props> = (props) => {
    return (
        <Page header={<ApplicationHeader pageTitle="Shared Dashboards" />}>
            <PageSection>
                <Headline configuration={{ title: 'Shared Dashboards', level: 2 }} />
                {props.sharedDashboards.length > 0
                    ? props.sharedDashboards.map((sharedDashboard) => (
                          <div className="shared-dashboards-overview">
                              {props.dashboardById(sharedDashboard.dashboardId).title}
                              <div>
                                  <Button onClick={() => props.onShareDashboard(sharedDashboard.dashboardId)}>
                                      <Icon name={'PenIcon'} color="green" label="Edit" />
                                  </Button>
                              </div>
                          </div>
                      ))
                    : 'You have no shared dashboards.'}
            </PageSection>
        </Page>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedDashboardsOverview)
