import { put, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions } from '../../../Redux'
import axios from '../../Utility/axios'
import { ImporterId } from '../../../Redux/Data/types'
import { pollLogTailsAction } from './PollImporterLogTails'

export const runImport = createAction('Data/Importers/Execution/RunImport', (uuid: ImporterId) => ({
    payload: { uuid },
}))

export default function* ImporterRunStartSaga() {
    yield takeLatest(runImport, function* (action) {
        try {
            const importerUuid = action.payload.uuid
            const response: AxiosResponse = yield axios({
                method: 'post',
                url: 'admin/importer/start/' + importerUuid,
            })
            if (response.status >= 400) {
                yield put(actions.UI.Notification.show({ id: 'importerStartFailed' }))
            } else {
                yield put(pollLogTailsAction([importerUuid]))
            }
        } catch (e) {
            yield put(actions.UI.Notification.show({ id: 'importerStartFailed' }))
            console.error(e)
        }
    })
}
