import { ConfirmationModalCallback } from '../../../Components/Organisms/Modal/ConfirmationModal/ConfirmationModal.types'

export enum DashboardActions {
    RESET = 'Reset',
    EDIT = 'Edit',
    EXPORT = 'Export',
    DELETE = 'Delete',
    DUPLICATE = 'Duplicate',
    DOWNLOAD_CONFIGURATION = 'Download Configuration',
    SHARE = 'Share',
}

export enum ExportTypes {
    xlsx = 'xlsx',
}

export type DashboardContextMenuProps = {
    onDashboardDuplicate: () => void
    onDashboardDeletion: () => void
    onDashboardExport: (format: string, callback: ConfirmationModalCallback) => void
    onDownloadDashboardConfiguration: () => void
    hasAction: (action: DashboardActions) => boolean
    onShareDashboard: () => void
}
