import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Button from '../../../Components/Atoms/Button/Button'
import { actions, selectors } from '../../../Redux'
import ButtonWithTooltip from '../../../Components/Molecules/HoverTooltip/ButtonWithTooltip'
import { DashboardId } from '../../../Redux/Data/types'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { saveCurrentlyEditedDashboardAndExitEditMode } from '../../../Sagas/DashboardSagas/SaveDashboardToServerSaga'

type EditDashboardButtonsOwnProps = {
    dashboardId: DashboardId
}

const mapStateToProps = (state: RootState, ownProps: EditDashboardButtonsOwnProps) => ({
    isValidDashboard: selectors.Data.Dashboards.DashboardEditMode.isValidDashboard(state),
    hasDashboardBeenModified: selectors.Data.Dashboards.DashboardEditMode.hasDashboardBeenModified(state),
    isNewDashboard: selectors.Data.Dashboards.DashboardEditMode.isNewDashboard(state, ownProps.dashboardId),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onCancel: (
        confirmationCallbackFactory: (dispatch: AppDispatch, isNewDashboard: boolean) => VoidFunction,
        isNewDashboard: boolean
    ) =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'dashboardCancelEdit',
                confirmationCallback: confirmationCallbackFactory(dispatch, isNewDashboard),
                denyCallback: () => {},
            })
        ),
    onDiscardChanges: () => dispatch(actions.Data.Dashboards.DashboardEditMode.discardEditModeChanges()),
    saveDashboardAndExitEditMode: () => dispatch(saveCurrentlyEditedDashboardAndExitEditMode()),
})

type EditDashboardButtonsProps = EditDashboardButtonsOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const EditDashboardButtons: React.FC<EditDashboardButtonsProps> = (props) => {
    const handleCancelEditMode = () => {
        if (props.hasDashboardBeenModified || props.isNewDashboard) {
            const confirmationModalCallbackFactory = (dispatch: Dispatch, isNewDashboard: boolean) => {
                return () => {
                    if (isNewDashboard) {
                        dispatch(push('/'))
                    }

                    dispatch(actions.Data.Dashboards.DashboardEditMode.discardEditModeChanges())
                }
            }

            props.onCancel(confirmationModalCallbackFactory, props.isNewDashboard)
        } else {
            props.onDiscardChanges()
        }
    }

    const renderTooltipContent = () => {
        return props.isValidDashboard.messages
    }

    return (
        <React.Fragment>
            <Button onClick={handleCancelEditMode}>Cancel</Button>
            <ButtonWithTooltip
                isDisabled={!props.isValidDashboard.isValid}
                content={renderTooltipContent()}
                header="Can't save dashboard"
                type="primary"
                onClick={props.saveDashboardAndExitEditMode}
            >
                Save
            </ButtonWithTooltip>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDashboardButtons)
