import React from 'react'

import produce from 'immer'
import FieldFilterEditor from '../FieldFilterEditor/FieldFilterEditor'
import { ListItem } from '../../Organisms/List/List'
import { FieldKeys, OnTriggerAutoCompletion } from '../../Atoms/FieldFilterInput/FieldFilterInput'
import { FieldType } from '../../Atoms/FieldFilterInput/FieldFilterManifest'

type Value = Array<FieldType>

const FilterEditor: React.FunctionComponent<{
    value: Value
    clearable?: boolean
    onChange: (value: Value) => void
    onTriggerAutoCompletion: OnTriggerAutoCompletion
}> = (props) => {
    const handleFilterAdded = (newFilter: FieldType) => {
        props.onChange([...props.value, newFilter])
    }

    const handleFieldFilterChanged = (index: number, field: FieldKeys, newValue: string & Array<string>) => {
        const oldFilters = props.value
        const updatedFilters = oldFilters.map((filter: FieldType, i: number) => {
            if (i === index) {
                return produce(filter, (draft) => {
                    draft[field] = newValue
                })
            }

            return filter
        })
        props.onChange(updatedFilters)
    }

    const handleFilterRemoved = (i: number) => {
        const filter = props.value
        const filterFiltered = filter.reduce((acc: Array<FieldType>, current: FieldType, j: number) => {
            if (i !== j) {
                acc.push(current)
            }

            return acc
        }, [])
        props.onChange(filterFiltered)
    }

    const fields = props.value
    return (
        <div className="form-field-with-filter filter-editor">
            <ListItem
                isOpen={true}
                collapsible={[
                    <FieldFilterEditor
                        key="form-field-with-filter-field-filter-editor"
                        fields={fields}
                        onTriggerAutoCompletion={props.onTriggerAutoCompletion}
                        onFilterAdded={handleFilterAdded}
                        onFilterChanged={handleFieldFilterChanged}
                        onFilterRemoved={handleFilterRemoved}
                    />,
                ]}
            />
        </div>
    )
}

export default FilterEditor
