import React from 'react'
import Select from '../../Atoms/Select/Select'

type Mode = {
    label: string
    editor: React.ComponentType
    configuration: any
}

const FormFieldWithModes: React.FunctionComponent<{
    value?: any
    modeByValue: (value: any) => string
    initialValueByMode: (mode: Mode) => void
    modes: Array<Mode>
    onChange: (newValue: any) => void
}> = (props) => {
    const handleModeChange = (mode: Mode) => {
        const newValue = props.initialValueByMode(mode)
        props.onChange(newValue)
    }

    const currentModeLabel = props.modeByValue(props.value)
    // @ts-ignore TODO fix: find could return undefined
    const currentMode: Mode = props.modes.find((m) => m.label === currentModeLabel)
    const EditorComponent = currentMode.editor

    return (
        <div className="form-field-group">
            <Select
                className="form-field-group__item form-field-group__item--80"
                value={currentModeLabel}
                onChange={handleModeChange}
                options={props.modes.map((m) => m.label).map((m) => ({ label: m, value: m }))}
                isClearable={false}
            />
            <div className="form-field-group__item form-field-group__item--20">
                {EditorComponent ? <EditorComponent {...props} {...currentMode.configuration} /> : ''}
            </div>
        </div>
    )
}

export default FormFieldWithModes
