import { SYMBOL_TYPE } from '../../Molecules/CalculatedFieldEditor/CalculatedFieldTokenEditor'

export const getDefaultAnalysisTermWithField = (field: string) => {
    return [
        { type: SYMBOL_TYPE.MODE, value: 'sum' },
        { type: SYMBOL_TYPE.OPEN_PARENTHESES, value: '(' },
        { type: SYMBOL_TYPE.VALUE_FIELD, value: field },
        { type: SYMBOL_TYPE.CLOSE_PARENTHESES, value: ')' },
    ]
}

export const defaultAnalysisTerm = getDefaultAnalysisTermWithField('docCount')
