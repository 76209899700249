import { put, select, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { actions, selectors } from '../../Redux'
import { initializeWidgetState } from './Utility'
import { buildDefaultWidgetPlacement, createWidget } from '../../Utility/DashboardEditor'
import { WidgetConfiguration, WidgetGroupId } from '../../Redux/Data/types'
import { DEFAULT_WIDGET_TYPE } from '../../StaticManifests/manifest.widgetTypes'
import { widgetsForWidgetGroup } from '../../Redux/Data/Dashboards/BaseSelectors'
import { RootState } from '../../Redux/types'

// used when creating a new dashboard or when clicking the "add widgets" button in a dashboard
export const createAndAddNewWidgetAction = createAction(
    'Sagas/Dashboards/createAndAddNewWidget',
    // when creating a new dashboard, we also create a new widget, but we do not know which widget group
    // it belongs to. In this case, we do not want to submit the widgetGroupId
    // widget is optional, uses default widget unless set
    (payload: { widgetGroupId?: WidgetGroupId; widget?: WidgetConfiguration }) => {
        const { widget, widgetGroupId } = payload
        return {
            payload: { widget, widgetGroupId },
        }
    }
)

export default function* createAndAddNewWidgetSaga() {
    yield takeLatest(createAndAddNewWidgetAction, function* (action) {
        const { widgetGroupId, widget } = action.payload

        const state: RootState = yield select()
        const dashboardId = selectors.Data.Dashboards.currentlyEditedDashboardId(state)
        const targetWidgetGroupId =
            // we assume, that this case only happens for new dashboards, so there is only one widget group
            widgetGroupId === undefined
                ? selectors.Data.Dashboards.ViewMode.widgetGroupIdsForDashboard(state, dashboardId!)![0]
                : widgetGroupId

        const otherWidgetsInGroup = widgetsForWidgetGroup(yield select(), targetWidgetGroupId).map(
            (widget) => widget.coordinatesAndSize
        )

        const newWidget =
            widget ||
            createWidget({
                widgetGroup: targetWidgetGroupId,
                type: DEFAULT_WIDGET_TYPE,
                coordinatesAndSize: yield buildDefaultWidgetPlacement(otherWidgetsInGroup),
            })

        const initialWidgetRequestPart = {
            dependencyHash: null,
            widgetState: initializeWidgetState(newWidget),
        }

        const persistAction = widget
            ? actions.Data.Dashboards.WidgetEditMode.addWidgetWithInitialState
            : actions.Data.Dashboards.WidgetEditMode.addWidgetWithInitialStateForEditing

        yield put(
            persistAction({
                widgetId: newWidget.id,
                widgetConfiguration: newWidget,
                widgetRequest: initialWidgetRequestPart,
            })
        )
    })
}
