import React from 'react'
import { goBack } from 'connected-react-router'
import { connect } from 'react-redux'
import ColorCustomization, {
    ColorCustomizationType,
} from '../Components/Organisms/ColorCustomization/ColorCustomization'
import { RootState } from '../Redux/types'
import { actions, selectors } from '../Redux'
import { AppDispatch } from '../store'
import { saveColorCustomization } from '../Sagas/SaveColorCustomizationSaga'
import Headline from '../Components/Atoms/Headline/Headline'
import Button from '../Components/Atoms/Button/Button'
import SecondaryFooter from '../Components/Molecules/SecondaryFooter/SecondaryFooter'
import ApplicationHeader from './ApplicationHeader/ApplicationHeader'
import { Page, PageSection } from '../Components/Organisms/Page/Page'

const mapStateToProps = (state: RootState) => ({
    colorCustomization: selectors.Data.ColorCustomization.getTransientCustomColors(state),
    hasColorConfigurationChanged: selectors.Data.ColorCustomization.hasColorConfigurationChanged(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    save: () => dispatch(saveColorCustomization()),
    changeColorConfig: (value: ColorCustomizationType) =>
        dispatch(actions.Data.ColorCustomization.changeColorConfig(value)),
    reset: () => dispatch(actions.Data.ColorCustomization.reset()),
    goBack: () => {
        dispatch(goBack())
        dispatch(actions.Data.ColorCustomization.resetTransient())
    },
    onUnsavedLeave: (event: Event) =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'leaveUnsaved',
                confirmationCallback: () => {
                    dispatch(saveColorCustomization())
                    // @ts-ignore
                    event.target.click()
                },
                denyCallback: () => {
                    // @ts-ignore click does not exist?
                    event.target.click()
                },
            })
        ),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const ColorCustomizationContainer: React.FC<Props> = (props) => {
    const renderResetButton = () => <Button onClick={props.reset}>Reset</Button>

    const renderCancelSaveButton = () => (
        <>
            <Button onClick={props.goBack}>Cancel</Button>
            <Button onClick={props.save} type="primary">
                Save
            </Button>
        </>
    )

    const header = <ApplicationHeader pageTitle="Color Customization" />

    return (
        <Page header={header}>
            <PageSection>
                <Headline configuration={{ title: 'Custom Colors per Valuefield', level: 3 }} />
                <ColorCustomization
                    colorCustomization={props.colorCustomization}
                    onChange={props.changeColorConfig}
                    onUnsavedLeave={props.onUnsavedLeave}
                    hasColorConfigurationChanged={props.hasColorConfigurationChanged}
                />
                <SecondaryFooter left={renderResetButton()} right={renderCancelSaveButton()} />
            </PageSection>
        </Page>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ColorCustomizationContainer)
