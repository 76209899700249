import React from 'react'
import classnames from 'classnames'

export const ModalActions: React.FunctionComponent<{
    children: any
}> = (props) => <div className="modal__content-actions">{props.children}</div>

export const ModalContent: React.FunctionComponent<{
    className?: string
    children: any
    align?: 'center'
    size?: 'small'
    direction?: 'column' | 'row'
}> = (props) => {
    const className = classnames('modal__content', props.className, {
        ['modal__content--' + props.align]: props.align,
        ['modal__content--' + props.size]: props.size,
        ['modal__content--' + props.direction]: props.direction,
    })

    return <div className={className}>{props.children}</div>
}
