import { setIn } from 'immutable'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uuid from 'uuid'
import { DataState, ImporterConfiguration } from '../../types'
import { actionTypes as system } from '../../../System'
import { RootState } from '../../../types'
import importerTypes, { ImporterType } from '../../../../StaticManifests/manifest.importerTypes'

function getDefinitionLabel(
    definitionId: ImporterType | undefined,
    currentlyEditedImporter: null | ImporterConfiguration
) {
    if (definitionId) {
        const definition = importerTypes[definitionId]
        // do not overwrite custom label set by user
        if (currentlyEditedImporter) {
            const allDefinitions = Object.values(importerTypes).map((i) => i.label)

            const title = currentlyEditedImporter.title

            if (title && !allDefinitions.includes(title)) {
                return title
            }
        }

        return definition && definition.label ? definition.label : definitionId
    }

    return defaultImporterTitle
}

function createImporterConfig(definitionId?: ImporterType) {
    const definition = definitionId && importerTypes[definitionId]
    return definition && definition.configurationInitializer ? definition.configurationInitializer() : {}
}

// i tried placing this in the manifest file, which results in the "reading from uninit API..." error
const getDefaultJoinExtraCsvDataConfiguration = () => ({
    source: {
        path: null,
        name: null,
    },
    parserSettings: {
        separator: ';',
        quoteChar: undefined,
        escapeChar: undefined,
        ignoreLeadingWhiteSpaces: undefined,
        ignoreQuotation: undefined,
        strictQuotes: undefined,
        linesToSkip: undefined,
        multiLineLimit: undefined,
        charset: undefined,
    },
    joinFieldOriginal: null,
    joinFieldExtra: null,
})

const initialState = {
    currentlyEditedImporter: null,
    changedConfiguration: false,
    initialStepIndex: null,
    cancelLocation: null,
    lastEditedImporter: null,
    typeSpecific: {
        tempoCloud: {},
    },
}

const importerEditingSlice = createSlice({
    name: 'Data/Importer/ImporterEditing',
    initialState: {} as DataState,
    reducers: {
        create: {
            prepare: (definitionId?: ImporterType) => ({
                payload: { definitionId },
            }),
            reducer: (state, action: PayloadAction<{ definitionId?: ImporterType }>) => {
                const { definitionId } = action.payload
                state.importers.editing.currentlyEditedImporter = {
                    uuid: uuid(),
                    title: getDefinitionLabel(definitionId, state.importers.editing.currentlyEditedImporter),
                    // @ts-ignore must be string but can be null on creation from setupWizard
                    definitionId,
                    schedule: '0 0 * * *',
                    enabled: false,
                    postProcessors: [],
                    configuration: createImporterConfig(definitionId),
                }
            },
        },
        addExtension: (state) => {
            if (state.importers.editing.currentlyEditedImporter) {
                // right now we only support having 1 extension, as well as only 1 type of extension (joinExtraCsvData)
                if (state.importers.editing.currentlyEditedImporter.postProcessors.length === 0) {
                    state.importers.editing.currentlyEditedImporter.postProcessors[0] = {
                        uuid: uuid(),
                        title: 'CSV Extension',
                        type: 'joinExtraCsvData',
                        configuration: getDefaultJoinExtraCsvDataConfiguration(),
                    }
                }
            }
        },
        removeExtension: {
            prepare: (importer: ImporterConfiguration, index: number) => ({ payload: { importer, index } }),
            reducer: (state, action: PayloadAction<{ importer: ImporterConfiguration; index: number }>) => {
                state.importers.management.byUuid[action.payload.importer.uuid].postProcessors.splice(
                    action.payload.index,
                    1
                )

                state.importers.editing.currentlyEditedImporter = null
            },
        },
        // To create an importer via uploading a file from start screen
        setInitialStepIndex: {
            prepare: (stepIndex: number) => ({ payload: { stepIndex } }),
            reducer: (state, action: PayloadAction<{ stepIndex: number }>) => {
                state.importers.editing.initialStepIndex = action.payload.stepIndex
            },
        },
        setOnCancelRoute: {
            prepare: (route: string) => ({ payload: { route } }),
            reducer: (state, action: PayloadAction<{ route: string }>) => {
                state.importers.editing.cancelLocation = action.payload.route
            },
        },
        loadedConfiguration: {
            prepare: (importerConfiguration: ImporterConfiguration) => ({ payload: { importerConfiguration } }),
            reducer: (state, action: PayloadAction<{ importerConfiguration: any }>) => {
                state.importers.editing.currentlyEditedImporter = action.payload.importerConfiguration
            },
        },
        changeTitle: {
            prepare: (value: string) => ({ payload: { value } }),
            reducer: (state, action: PayloadAction<{ value: string }>) => {
                if (state.importers.editing.currentlyEditedImporter) {
                    state.importers.editing.currentlyEditedImporter.title = action.payload.value
                }
                state.importers.editing.changedConfiguration = true
            },
        },
        changeExtensionTitle: {
            prepare: (value: string) => ({ payload: { value } }),
            reducer: (state, action: PayloadAction<{ value: string }>) => {
                if (state.importers.editing.currentlyEditedImporter?.postProcessors[0]) {
                    state.importers.editing.currentlyEditedImporter.postProcessors[0].title = action.payload.value
                }
                state.importers.editing.changedConfiguration = true
            },
        },
        changeSchedule: {
            prepare: (value: string) => ({ payload: { value } }),
            reducer: (state, action: PayloadAction<{ value: string }>) => {
                if (state.importers.editing.currentlyEditedImporter) {
                    state.importers.editing.currentlyEditedImporter.schedule = action.payload.value
                    state.importers.editing.changedConfiguration = true
                }
            },
        },
        changeType: {
            prepare: (newType: ImporterType, newConfiguration: any) => ({ payload: { newType, newConfiguration } }),
            reducer: (state, action: PayloadAction<{ newType: ImporterType; newConfiguration: any }>) => {
                if (state.importers.editing.currentlyEditedImporter) {
                    state.importers.editing.currentlyEditedImporter.definitionId = action.payload.newType
                    state.importers.editing.currentlyEditedImporter.configuration = action.payload.newConfiguration
                    state.importers.editing.changedConfiguration = true
                    state.importers.editing.initialStepIndex = 0
                }
            },
        },
        editConfiguration: {
            prepare: (propertyName: string, value: any) => ({ payload: { propertyName, value } }),
            reducer: (state, action: PayloadAction<{ propertyName: string; value: any }>) => {
                if (state.importers.editing.currentlyEditedImporter) {
                    // string.string based paths...
                    state.importers.editing.currentlyEditedImporter.configuration = setIn(
                        state.importers.editing.currentlyEditedImporter.configuration,
                        action.payload.propertyName.split('.'),
                        action.payload.value
                    )

                    state.importers.editing.changedConfiguration = true
                }
            },
        },
        editExtensionConfigurationProperty: {
            prepare: (propertyPath: string, value: any) => ({ payload: { propertyPath, value } }),
            reducer: (state, action: PayloadAction<{ propertyPath: string; value: any }>) => {
                if (state.importers.editing.currentlyEditedImporter) {
                    // At the moment, we support only one postProcessor/extension per importer
                    state.importers.editing.currentlyEditedImporter.postProcessors[0].configuration = setIn(
                        state.importers.editing.currentlyEditedImporter.postProcessors[0].configuration,
                        [...action.payload.propertyPath.split('.')],
                        action.payload.value
                    )
                    state.importers.editing.changedConfiguration = true
                }
            },
        },
        setConfiguration: {
            prepare: (configuration: any) => ({ payload: { configuration } }),
            reducer: (state, action: PayloadAction<{ configuration: any }>) => {
                if (state.importers.editing.currentlyEditedImporter) {
                    state.importers.editing.currentlyEditedImporter.configuration = action.payload.configuration
                    state.importers.editing.changedConfiguration = true
                }
            },
        },
        enableImporter: (state) => {
            if (state.importers.editing.currentlyEditedImporter) {
                state.importers.editing.currentlyEditedImporter.enabled = true
            }
        },
        cancelEditing: (state) => {
            const uuid = state.importers.editing.currentlyEditedImporter?.uuid
            state.importers.editing = { ...initialState, lastEditedImporter: uuid }
        },
        save: {
            prepare: (
                importer: ImporterConfiguration,
                nextPath?: string,
                runImporterAfterSave?: boolean,
                continueEditingImporter?: boolean,
                callback?: () => void
            ) => ({
                payload: { importer, nextPath, runImporterAfterSave, continueEditingImporter, callback },
            }),
            reducer: (state) => {
                state.importers.editing.lastEditedImporter = state.importers.editing.currentlyEditedImporter?.uuid
            },
        },
        sagaSaveFinished: (state) => {
            const uuid =
                state.importers.editing.currentlyEditedImporter && state.importers.editing.currentlyEditedImporter.uuid
            const currentlyEditedImporter = state.importers.editing.currentlyEditedImporter
            if (uuid && currentlyEditedImporter) {
                state.importers.management.byUuid[uuid] = currentlyEditedImporter
                state.importers.editing.changedConfiguration = false
                state.importers.editing.currentlyEditedImporter = null
            }
        },
        remove: {
            prepare: (uuid: string) => ({ payload: { uuid } }),
            reducer: (state, action: PayloadAction<{ uuid: string }>) => {
                const uuid = action.payload.uuid
                delete state.importers.management.byUuid[uuid]
                delete state.importers.execution.statusByUuid[uuid]
                state.importers.editing.currentlyEditedImporter = null
            },
        },
        resetLastEditedImporter: (state) => {
            state.importers.editing.lastEditedImporter = null
        },
    },
    extraReducers: {
        [system.INIT]: (state) => {
            // @ts-ignore missing props are filled up in the management/execution
            state.importers = {
                editing: initialState,
            }
        },
    },
})

export const actions = importerEditingSlice.actions

export const reducer = importerEditingSlice.reducer

export const selectors = {
    currentlyEditedImporter: (state: RootState) => state.Data.importers.editing.currentlyEditedImporter,
    changedConfiguration: (state: RootState) => state.Data.importers.editing.changedConfiguration,
    initialStepIndex: (state: RootState) => state.Data.importers.editing.initialStepIndex,
    cancelRoute: (state: RootState) => state.Data.importers.editing.cancelLocation,
    lastEditedImporter: (state: RootState) => state.Data.importers.editing.lastEditedImporter,
    typeSpecific: (state: RootState) => state.Data.importers.editing.typeSpecific,
}

export const defaultImporterTitle = 'New Importer'
