import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../Redux/types'
import { actions, selectors } from '../../../Redux'
import { AppDispatch } from '../../../store'
import Button from '../../../Components/Atoms/Button/Button'

type ReduxTempoCloudButtonProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: RootState) => ({
    disabled: !selectors.Data.Importer.currentlyEditedImporter(state)?.configuration.tempo?.oAuthTokens,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    resetTempoOAuthTokens: () => dispatch(actions.Data.Importer.removeTempoOAuthToken()),
})

const TempoCloudResetOAuthButton: React.FunctionComponent<ReduxTempoCloudButtonProps> = (props) => {
    return (
        <Button onClick={props.resetTempoOAuthTokens} type={'warning'} isDisabled={props.disabled}>
            Reset Client-ID and Secret
        </Button>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TempoCloudResetOAuthButton)
