import { createSlice } from '@reduxjs/toolkit'
import { UiState } from './types'
import { RootState } from '../types'

const menuSlice = createSlice({
    name: 'UI/MainMenu',
    initialState: {
        currentMenuVisibility: false,
    } as UiState,
    reducers: {
        toggleMenuVisibility: (state) => {
            state.currentMenuVisibility = !state.currentMenuVisibility
        },
    },
})

export const actions = menuSlice.actions
export const reducer = menuSlice.reducer

export const selectors = {
    isMainMenuOpen: (state: RootState) => state.UI.currentMenuVisibility,
}
