import { call, put, select } from 'redux-saga/effects'
import uuid from 'uuid'
import produce from 'immer'
import { AxiosResponse } from 'axios'
import omit from 'lodash.omit'
import { actions, selectors } from '../../Redux'
import axios from '../Utility/axios'
import { EXPLORER_DASHBOARD_UUID } from '../../Redux/Data/Dashboards/WidgetExplorer'
import { createWidget } from '../../Utility/DashboardEditor'
import {
    AccessMode,
    DashboardId,
    DashboardRequest,
    ExecuteDashboardServerResponse,
    WidgetConfiguration,
    WidgetExplorerSelections,
    WidgetId,
} from '../../Redux/Data/types'
import widgetTypes, { TIME_SELECTOR } from '../../StaticManifests/manifest.widgetTypes'
import { createApiDashboardConfigurationFromNormalizedDashboardConfiguration } from '../../Redux/Data/Dashboards/denormalization'

/**
 * By default, the selector would use the defaultSelector for dashboard requests and would
 * always favour the currently edited widget. When the widget-explorer is used, the currently
 * edited widget also exists, but we do not want to use it. So we force this saga to load the
 * currently edited dashboard and ignore that there is a currently edited widget
 */
export function* getDashboardDataRequest() {
    const requestFromStore = selectors.Data.Dashboards.ViewMode.dashboardRequest(yield select())

    if (requestFromStore === null) {
        throw Error('DashboardRequest must be set in store to create a server request!')
    }

    const isDemo: boolean = yield select(selectors.Data.FeatureFlags.isDemo)
    if (isDemo) {
        const transientDashboard = requestFromStore.transientDashboard
        if (transientDashboard) {
            return requestFromStore
        }

        // in demo mode dashboards are not really stored on the server, so we always have to send the config
        requestFromStore.transientDashboard = createApiDashboardConfigurationFromNormalizedDashboardConfiguration(
            yield select(),
            requestFromStore.dashboard
        )
        return requestFromStore
    }

    // usually we do not need to adjust anything
    return requestFromStore
}

export function* buildInitialDashboardDataRequest(dashboardId: DashboardId) {
    const dashboardDataRequest: DashboardRequest = yield getDashboardInformationForDashboardRequest(dashboardId)

    const widgetIds: Array<WidgetId> =
        dashboardId === EXPLORER_DASHBOARD_UUID
            ? yield select(selectors.Data.Dashboards.WidgetExplorer.widgetIds)
            : yield select(selectors.Data.Dashboards.ViewMode.widgetIdsForDashboard, dashboardId)

    dashboardDataRequest.widgetRequests = {}
    for (const widgetId of widgetIds) {
        const widgetConfiguration =
            dashboardId === EXPLORER_DASHBOARD_UUID
                ? selectors.Data.Dashboards.WidgetExplorer.widgetById(yield select(), widgetId)
                : selectors.Data.Dashboards.widgetById(yield select(), widgetId)

        dashboardDataRequest.widgetRequests[widgetId] = {
            dependencyHash: null,
            widgetState: initializeWidgetState(widgetConfiguration),
        }
    }
    return dashboardDataRequest
}

/**
 * By default, this will add the uuid of the dashboard to the request so the server knows which
 * dashboard configuration to load to execute.
 * In case of the demo site, users can not save the dashboard to the server, so we have to send the complete
 * dashboard configuration to the server on every request as the server does not store the configuration.
 */
function* getDashboardInformationForDashboardRequest(currentlySelectedDashboardId: DashboardId) {
    const isDemo: boolean = yield select(selectors.Data.FeatureFlags.isDemo)
    if (isDemo) {
        // in demo mode dashboards are not really stored on the server, so we always have to send the config
        const dashboardConfig = createApiDashboardConfigurationFromNormalizedDashboardConfiguration(
            yield select(),
            currentlySelectedDashboardId
        )
        return {
            transientDashboard: dashboardConfig,
        }
    }

    // normally it is enough to send the server the dashboard UUID since it knows the most recent configuration
    // not yet saved update in dashboard edit mode are added later (not here)
    return { dashboard: currentlySelectedDashboardId }
}

type ServerResponse<T> = {
    meta: Array<any>
    payload: T
    message?: string
}

export function* executeDashboardDataRequest(widgetDataRequest: DashboardRequest, successActions: Array<any>) {
    yield put(actions.Data.Dashboards.ViewMode.loadWidgetDataStart())
    const accessMode = selectors.Data.System.accessMode(yield select())
    const prefix = accessMode === AccessMode.SHARED_DASHBOARD ? 'shared-dashboard' : 'dashboard'
    const data =
        accessMode === AccessMode.SHARED_DASHBOARD
            ? {
                  ...widgetDataRequest,
                  sharedDashboardId: selectors.Data.Dashboards.ShareDashboard.getSharedDashboardByDashboardId(
                      yield select(),
                      widgetDataRequest.dashboard
                  )?.id,
              }
            : widgetDataRequest

    const response: AxiosResponse<ServerResponse<ExecuteDashboardServerResponse> | string> = yield axios({
        method: 'post',
        url: prefix + '/execute',
        data,
    })

    if (response.status < 400 && typeof response.data !== 'string') {
        for (const successAction of successActions) {
            yield put(successAction(response.data))
        }
    } else {
        // Error handling
        if (response.status === 404) {
            yield put(actions.UI.Notification.show({ id: 'widgetData404' }))
        } else {
            yield put(
                actions.UI.Notification.show({
                    id: 'widgetData500',
                    // response.data might be a string here, but could also be the the ordinary error response with the object with key "message"
                    details:
                        typeof response.data === 'string'
                            ? response.data
                            : response.data.message ?? 'RESPONSE DATA WAS EMPTY',
                    timeout: 10000,
                })
            )
        }

        yield put(actions.Data.Dashboards.ViewMode.loadWidgetDataError())
    }
}

export function* executeWidgetSideDataRequest(
    widgetUuid: string,
    endpoint: string,
    parameters: any,
    widgetDataRequest: any,
    callback: (response: any) => void
) {
    const accessMode = selectors.Data.System.accessMode(yield select())
    const prefix = accessMode === AccessMode.SHARED_DASHBOARD ? 'shared-dashboard' : 'dashboard'
    const data =
        accessMode === AccessMode.SHARED_DASHBOARD
            ? {
                  ...widgetDataRequest,
                  sharedDashboardId: selectors.Data.Dashboards.ShareDashboard.getSharedDashboardByDashboardId(
                      yield select(),
                      widgetDataRequest.dashboard
                  )?.id,
              }
            : widgetDataRequest

    const response: AxiosResponse = yield axios({
        method: 'post',
        url: prefix + '/sideData/' + widgetUuid + '/' + endpoint,
        data,
        params: parameters,
    })

    if (response.status < 400) {
        yield call(callback, response.data)
    } else if (response.status === 404) {
        yield put(actions.UI.Notification.show({ id: 'widgetData404' }))
    } else {
        yield put(
            // response.data might be a string here, but could also be the the ordinary error response with the object with key "message"
            actions.UI.Notification.show({
                id: 'widgetData500',
                details:
                    response.data instanceof String
                        ? response.data
                        : response.data.message || 'RESPONSE DATA WAS EMPTY',
            })
        )
    }
}

export function* addCurrentlyEditedDashboardToRequest(widgetDataRequest: DashboardRequest) {
    const isEditingDashboard = selectors.Data.Dashboards.DashboardEditMode.isDashboardEditModeActive(yield select())

    if (!isEditingDashboard) {
        return widgetDataRequest
    }

    const transientDashboard = createApiDashboardConfigurationFromNormalizedDashboardConfiguration(
        yield select(),
        widgetDataRequest.dashboard
    )

    const widgetDataRequestWithTransientDashboard = produce(widgetDataRequest, (draft) => {
        draft.transientDashboard = transientDashboard
    })

    return omit(widgetDataRequestWithTransientDashboard, 'dashboard')
}

export function* addCurrentlyEditedWidgetToRequest(widgetDataRequest: Readonly<DashboardRequest>) {
    const currentlyEditedWidget = selectors.Data.Dashboards.currentlyEditedWidgetConfig(yield select())
    if (currentlyEditedWidget === undefined) {
        return widgetDataRequest
    }

    return produce(widgetDataRequest, (draft: DashboardRequest) => {
        draft.transientDashboard!.widgets[currentlyEditedWidget.id] = {
            ...omit(currentlyEditedWidget, 'id'),
            uuid: currentlyEditedWidget.id,
        }

        // we always want to ignore the last dependency hash to ensure the server calculates values
        draft.widgetRequests[currentlyEditedWidget.id].dependencyHash = null
    })
}

export function initializeWidgetState(widget: WidgetConfiguration) {
    const type = widget.type

    const widgetTypeDefinition = widgetTypes[type]
    if (!widgetTypeDefinition) {
        // throwing an error would crash the whole UI
        return null
    }

    if (widgetTypeDefinition.stateInitializationFunction) {
        return widgetTypeDefinition.stateInitializationFunction(widget.configuration)
    }

    throw new Error(`Widget type ${type} does not have a stateInitializationFunction defined.`)
}

export function* applyFakeTimeSelector(widgetDataRequest: DashboardRequest) {
    // function only applies to requests with transient dashboard
    if (widgetDataRequest.transientDashboard === undefined) {
        return widgetDataRequest
    }

    const selectedFields: WidgetExplorerSelections = yield select(
        selectors.Data.Dashboards.WidgetExplorer.currentFieldSelections
    )
    const timeSelectorType = TIME_SELECTOR

    // make sure every widget group contains at least one time selector
    Object.values(widgetDataRequest.transientDashboard.widgetGroups).forEach((widgetGroup) => {
        // find all time selectors of every group
        const timeSelectorsOfGroup = Object.values(widgetDataRequest.transientDashboard?.widgets ?? []).filter(
            (widget) => widget.type === timeSelectorType && widget.widgetGroup === widgetGroup.uuid
        )

        // create the time selectors in case they are missing
        if (timeSelectorsOfGroup.length === 0) {
            const timeSelectorId = uuid()
            const timeSelectorConfiguration = createWidget({
                id: timeSelectorId,
                widgetGroup: widgetGroup.uuid,
                type: TIME_SELECTOR,
                coordinatesAndSize: { x: 0, y: 0, height: 1, width: 1 },
            })

            widgetDataRequest = produce(widgetDataRequest, (draft) => {
                draft.transientDashboard!.widgets[timeSelectorId] = {
                    ...omit(timeSelectorConfiguration, 'id'),
                    uuid: timeSelectorConfiguration.id,
                }
            })
        }
    })

    // set all time selectors to the time selected in widget explorer
    const selectedTime = selectedFields.time
    Object.values(widgetDataRequest.transientDashboard.widgets)
        .filter((widget) => widget.type === timeSelectorType)
        .forEach((timeSelector) => {
            const startDate = selectedTime.startDate
            const endDate = selectedTime.endDate
            const hasTimeSelection = startDate && endDate
            widgetDataRequest = produce(widgetDataRequest, (draft) => {
                const oldState = draft.widgetRequests[timeSelector.uuid]?.widgetState || {}
                draft.widgetRequests[timeSelector.uuid] = {
                    dependencyHash: null,
                    widgetState: {
                        ...oldState,
                        startMilliseconds: startDate ? startDate.valueOf() : undefined,
                        endMilliseconds: endDate ? endDate.valueOf() : undefined,
                        // @ts-ignore
                        selectedPredefinedTimespan: hasTimeSelection ? undefined : 'allTime',
                    },
                }
            })
        })

    return widgetDataRequest
}

export function* getApiDashboardConfigurationForId(dashboardId: DashboardId) {
    return createApiDashboardConfigurationFromNormalizedDashboardConfiguration(yield select(), dashboardId)
}
