import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UiState } from './types'
import { RootState } from '../types'
import { DashboardId } from '../Data/types'

const favouriteDashboardSlice = createSlice({
    name: 'Ui/FavouriteDashboards',
    initialState: {} as UiState,
    reducers: {
        add: (state, action: PayloadAction<DashboardId>) => {
            if (state.currentUser) {
                state.currentUser.uiSettings.favouriteDashboards.push(action.payload)
            }
        },

        remove: (state, action: PayloadAction<DashboardId>) => {
            if (state.currentUser) {
                const index = state.currentUser.uiSettings.favouriteDashboards.indexOf(action.payload)
                state.currentUser.uiSettings.favouriteDashboards.splice(index, 1)
            }
        },
        toggle: (state, action: PayloadAction<DashboardId>) => {
            const dashboardId = action.payload

            if (state.currentUser) {
                if (state.currentUser.uiSettings.favouriteDashboards.includes(dashboardId)) {
                    const index = state.currentUser.uiSettings.favouriteDashboards.indexOf(action.payload)
                    state.currentUser.uiSettings.favouriteDashboards.splice(index, 1)
                } else {
                    state.currentUser.uiSettings.favouriteDashboards.push(action.payload)
                }
            }
        },
    },
})

export const actions = favouriteDashboardSlice.actions
export const reducer = favouriteDashboardSlice.reducer

const list = (state: RootState) => (state.UI.currentUser ? state.UI.currentUser.uiSettings.favouriteDashboards : [])

const isFavouriteDashboardById = (state: RootState, dashboardId: DashboardId) => list(state).includes(dashboardId)

export const selectors = {
    list,
    isFavouriteDashboardById,
}
