import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from '../../Redux'
import SystemInformation from '../../Components/Organisms/System/SystemInformation/SystemInformation'
import ApplicationHeader from '../ApplicationHeader/ApplicationHeader'
import { Page, PageSection } from '../../Components/Organisms/Page/Page'
import { RootState } from '../../Redux/types'
import { AppDispatch } from '../../store'
import { DateFormats, License, WorkingDays } from '../../Redux/Data/types'

const mapStateToProps = (state: RootState) => ({
    version: selectors.Data.System.version(state),
    releaseDateMillis: selectors.Data.System.releaseDateMillis(state),
    licenses: selectors.Data.System.licenses(state),
    timeZone: selectors.Data.System.timeZone(state),
    applicationMode: selectors.Data.System.applicationMode(state),
    workingDays: selectors.Data.System.workingDays(state),
    dateFormat: selectors.Data.System.dateFormat(state),
    elasticTokenLength: selectors.Data.System.elasticTokenLength(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onUpdateLicense: () =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'systemLicenseUpload',
                confirmationCallback: ({ license }: { license: License }) =>
                    dispatch(actions.Data.System.uploadLicense(license)),
            })
        ),
    onChangeTimeZone: (timeZone: string) => dispatch(actions.Data.System.setTimeZone(timeZone)),
    onChangeWorkingDays: (workingDays: WorkingDays) => dispatch(actions.Data.System.setWorkingDays(workingDays)),
    onChangeDateFormat: (dateFormat: DateFormats) => dispatch(actions.Data.System.setDateFormat(dateFormat)),
    onChangeElasticTokenLength: (elasticTokenLength: number) =>
        dispatch(actions.Data.System.setElasticTokenLength(elasticTokenLength)),
})

export type SystemInformationProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class SystemInformationPage extends PureComponent<SystemInformationProps> {
    render() {
        const header = <ApplicationHeader pageTitle="System Information" />

        return (
            <Page header={header}>
                <PageSection>
                    <SystemInformation {...this.props} />
                </PageSection>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemInformationPage)
