import React, { lazy, Suspense } from 'react'
import { CodeAceAreaProps } from './CodeAceArea'

const CodeAceArea = lazy(() => import(/* webpackChunkName: "CodeAceArea" */ './CodeAceArea'))

const LazyCodeAceArea: React.FC<CodeAceAreaProps> = (props) => (
    <Suspense fallback={''}>
        <CodeAceArea {...props} />
    </Suspense>
)

export default LazyCodeAceArea
