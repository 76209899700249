import TimeSeriesChart from './LazyTimeSeriesChart'
// @ts-ignore file ext
import TimeSeriesChartIcon from './TimeSeriesChart.Icon.tsx'
import TimeSeriesChartStateInitializer from './TimeSeriesChart.init'
import AliasChartConfigurationInitializer, {
    getDefaultTitle,
    TimeSeriesChartConfigurationInitializerForWidgetExplorer,
} from './AliasChart.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import { headline, referenceLine, trendLine } from '../../../../widgetManifestBuildingBlocks'
import ChartFieldListEditor from '../../../Molecules/ChartFieldListEditor/ChartFieldListEditor'
import { TimeSeriesWidgetConfig, TimeSeriesWidgetData } from './TimeSeriesChart.types'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'

export const configMapping = {
    stackingField: 'chartGroups.0.yPivotField',
    maximumNumberOfStacks: 'chartGroups.0.maximumNumberOfStacks',
    valueField: 'chartGroups.0.valueField',
    granularity: 'granularity',
    numberOfDecimals: 'numberOfDecimals',
    showDots: 'chartGroups.0.showDots',
    trendLineEntry: 'chartGroups.0.trendLineEntry',
    isStacked: 'chartGroups.0.isStacked',
    interpolate: 'chartGroups.0.interpolate',
    lineType: 'chartGroups.0.type',
    sumUpAggregation: 'chartGroups.0.sumUpAggregation',
    referenceLines: 'referenceLines',
    hideNonWorkingDays: 'hideNonWorkingDays',
    hideFurtherValues: 'hideFurtherValues',
}

const timeSeriesChartAliasProps = {
    component: TimeSeriesChart,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 0,
        },
        max: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 1,
        },
    },
    configurationValidations: (widgetConfig: TimeSeriesWidgetConfig) => {
        const chartGroups = widgetConfig.chartGroups
        // @ts-ignore checks for old exply versions will always be falsy
        if (chartGroups.filter((group) => group.valueField === undefined || group.valueField === '').length > 0) {
            return widgetProblems.noValueField
        }
    },
    renderRequirements: (widgetData?: TimeSeriesWidgetData) => {
        const chartData = widgetData?.chartData
        if (!chartData || chartData.length === 0) {
            return widgetProblems.noData
        }
    },
    stateInitializationFunction: TimeSeriesChartStateInitializer,

    configMapping,
}

const timeSeriesChartAliasDataEditors = [
    buildingBlocks.valueField({
        isIgnoreTimeSelectionDisabled: true,
        includePivotField: true,
        areDateRangeFieldsAllowed: false,
    }),
    buildingBlocks.flag('sumUpAggregation', 'Sum up values'),
]

const defaultDisplayOptionsEditors = [
    buildingBlocks.maximumNumberOfStacks(),
    buildingBlocks.numberOfDecimals(),
    buildingBlocks.flag('hideNonWorkingDays', 'Hide non working days', 'HideNonWorkingDaysIcon'),
    buildingBlocks.flag('hideFurtherValues', 'Hide further values', 'FurtherValuesIcon'),
]

export default {
    name: getDefaultTitle(''),
    component: TimeSeriesChart,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 0,
        },
        max: {
            dateFields: 1,
            valueFields: 3,
            categoryFields: 3,
        },
    },
    icon: TimeSeriesChartIcon('series'),
    stateInitializationFunction: TimeSeriesChartStateInitializer,
    configurationInitializer: AliasChartConfigurationInitializer(),
    configurationInitializerForWidgetExplorer: TimeSeriesChartConfigurationInitializerForWidgetExplorer(undefined),

    renderRequirements: (widgetData?: TimeSeriesWidgetData) => {
        const chartData = widgetData?.chartData
        if (!chartData || chartData.length === 0) {
            return widgetProblems.noData
        }
    },

    editors: {
        data: [
            headline('Value Axes'),
            {
                widgetConfigurationProperty: 'chartGroups',
                component: ChartFieldListEditor,
            },
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.granularity(),
                    buildingBlocks.numberOfDecimals(),
                    buildingBlocks.flag('showGrid', 'Grid Lines', 'ShowGridLinesIcon'),
                    buildingBlocks.flag('stackUpValues', 'Stack Multiple Values', 'StackUpMultipleValuesIcon'),
                    buildingBlocks.flag('hideNonWorkingDays', 'Hide non working days', 'HideNonWorkingDaysIcon'),
                    buildingBlocks.flag('hideFurtherValues', 'Hide further values', 'FurtherValuesIcon'),
                ],
            },
            {
                name: 'Axis Options',
                editors: [
                    buildingBlocks.text('labels.xAxis', 'X-Axis Label'),
                    buildingBlocks.text('labels.yAxisLeft', 'Y-Axis Left Label'),
                    buildingBlocks.text('labels.yAxisRight', 'Y-Axis Right Label'),
                ],
            },
            {
                name: 'Reference Lines',
                editors: [referenceLine()],
            },
        ],
    },
}

export const TimeAreaChartManifest = {
    ...timeSeriesChartAliasProps,
    // alias widget of time series chart
    name: getDefaultTitle('area'),
    icon: TimeSeriesChartIcon('area'),
    configurationInitializer: AliasChartConfigurationInitializer('area'),
    configurationInitializerForWidgetExplorer: TimeSeriesChartConfigurationInitializerForWidgetExplorer('area'),

    editors: {
        data: [...timeSeriesChartAliasDataEditors],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.granularity(),
                    buildingBlocks.flag('showDots', 'Show Value Dots', 'ShowValueDotsAreaIcon'),
                    buildingBlocks.flag('isStacked', 'Cluster Values', 'SeparateStackingBarsIcon'),
                    buildingBlocks.flag(
                        'interpolate',
                        'Interpolate Missing Values',
                        'InterpolateMissingValuesAreaIcon'
                    ),
                    trendLine('trendLineEntry', 'TrendLineAreaIcon'),
                    buildingBlocks.selectOneOf(
                        'lineType',
                        'Line Drawing Style',
                        [
                            { label: 'linear', value: 'linear' },
                            { label: 'smooth', value: 'monotone' },
                            { label: 'step', value: 'step' },
                        ],
                        false,
                        'LineDrawingStyleIcon'
                    ),
                    ...defaultDisplayOptionsEditors,
                ],
            },
            {
                name: 'Reference Lines',
                editors: [referenceLine()],
            },
        ],
    },
}

export const TimeBarChartManifest = {
    ...timeSeriesChartAliasProps,
    // alias widget of time series chart
    name: getDefaultTitle('bar'),
    icon: TimeSeriesChartIcon('bar'),
    configurationInitializer: AliasChartConfigurationInitializer('bar'),
    configurationInitializerForWidgetExplorer: TimeSeriesChartConfigurationInitializerForWidgetExplorer('bar'),

    editors: {
        data: [...timeSeriesChartAliasDataEditors],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.granularity(),
                    buildingBlocks.flag('isStacked', 'Cluster Values', 'SeparateStackingBarsIcon'),
                    trendLine('trendLineEntry', 'TrendLineBarIcon'),
                    ...defaultDisplayOptionsEditors,
                ],
            },
            {
                name: 'Reference Lines',
                editors: [referenceLine()],
            },
        ],
    },
}

export const TimeLineChartManifest = {
    ...timeSeriesChartAliasProps,
    // alias widget of time series chart
    name: getDefaultTitle('line'),
    icon: TimeSeriesChartIcon('line'),
    configurationInitializer: AliasChartConfigurationInitializer('line'),
    configurationInitializerForWidgetExplorer: TimeSeriesChartConfigurationInitializerForWidgetExplorer('line'),

    editors: {
        data: [...timeSeriesChartAliasDataEditors],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.flag('showDots', 'Show Value Dots', 'ShowValueDotsLineIcon'),
                    buildingBlocks.flag(
                        'interpolate',
                        'Interpolate Missing Values',
                        'InterpolateMissingValuesLineIcon'
                    ),
                    trendLine('trendLineEntry', 'TrendLineLineIcon'),
                    buildingBlocks.selectOneOf(
                        'lineType',
                        'Line Drawing Style',
                        [
                            { label: 'linear', value: 'linear' },
                            { label: 'smooth', value: 'monotone' },
                            { label: 'step', value: 'step' },
                        ],
                        false,
                        'LineDrawingStyleIcon'
                    ),
                    ...defaultDisplayOptionsEditors,
                ],
            },
            {
                name: 'Reference Lines',
                editors: [referenceLine()],
            },
        ],
    },
}
