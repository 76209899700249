import { PayloadAction } from '@reduxjs/toolkit'
import { Draft } from 'immer'
import { DashboardLoadingStates, ExecuteDashboardServerResponse, DataState } from '../types'

export const storeExecuteDashboardResponseReducer = (
    state: Draft<DataState>,
    action: PayloadAction<ExecuteDashboardServerResponse>
) => {
    const { widgetResponses } = action.payload
    // Update loading state
    state.dashboards.loadingState = DashboardLoadingStates.SUCCESS

    if (state.dashboards.response === null) {
        state.dashboards.response = {}
    }

    const listOfNewWidgetIds = Object.keys(widgetResponses)
    listOfNewWidgetIds.forEach((widgetId) => {
        const widgetResponse = widgetResponses[widgetId]

        if (state.dashboards.response === null) {
            // we only want to override this part on demand
            // we would lose previous results when resetting it always
            state.dashboards.response = {}
        }

        const oldData = state.dashboards.response[widgetId]?.data
        state.dashboards.response[widgetId] = widgetResponse

        if (!widgetResponse.hasChanged) {
            state.dashboards.response[widgetId].data = oldData
        }
        if (widgetResponse.hasFailed) {
            console.error('Widget failed:', widgetResponse.data)
            state.dashboards.response[widgetId].data = null
        }

        const dependencyHashSoFar = state.dashboards.request?.widgetRequests[widgetId]?.dependencyHash
        if (dependencyHashSoFar == null || dependencyHashSoFar !== widgetResponse.dependencyHash) {
            if (state.dashboards.request && state.dashboards.request.widgetRequests[widgetId]) {
                state.dashboards.request.widgetRequests[widgetId].dependencyHash = widgetResponse.dependencyHash
            }
        }
    })

    // clean up leftover widget responses which are not included in the response; to ensure that we do not show stale data!!
    Object.keys(state.dashboards.response).forEach((widgetId) => {
        if (!listOfNewWidgetIds.includes(widgetId)) {
            delete state.dashboards.response![widgetId]
        }
    })
}
