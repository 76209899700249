import * as Importers from './Importers'
import * as ColorCustomization from './ColorCustomization'
import * as ConfigurationSharing from './ConfigurationSharing'
import * as Dashboards from './Dashboards'
import * as FeatureFlags from './FeatureFlags'
import * as FieldsConfiguration from './FieldsConfiguration'
import * as FieldsCustomization from './FieldsCustomization'
import * as JiraConfiguration from './JiraConfiguration'
import * as System from './System'
import * as UserManagement from './UserManagement'

export const actions = {
    Importer: Importers.actions,
    ColorCustomization: ColorCustomization.actions,
    ConfigurationSharing: ConfigurationSharing.actions,
    Dashboards: Dashboards.actions,
    FieldsConfiguration: FieldsConfiguration.actions,
    FieldsCustomization: FieldsCustomization.actions,
    JiraConfiguration: JiraConfiguration.actions,
    System: System.actions,
    UserManagement: UserManagement.actions,
}

export const reducerArray = [
    ...Importers.reducerArray,
    ...Dashboards.reducerArray,
    FeatureFlags.reducer,
    FieldsConfiguration.reducer,
    FieldsCustomization.reducer,
    JiraConfiguration.reducer,
    System.reducer,
    UserManagement.reducer,
    ColorCustomization.reducer,
]

export const selectors = {
    Importer: Importers.selectors,
    Dashboards: Dashboards.selectors,
    FeatureFlags: FeatureFlags.selectors,
    FieldsConfiguration: FieldsConfiguration.selectors,
    FieldsCustomization: FieldsCustomization.selectors,
    JiraConfiguration: JiraConfiguration.selectors,
    System: System.selectors,
    UserManagement: UserManagement.selectors,
    ColorCustomization: ColorCustomization.selectors,
}
