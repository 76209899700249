import React, { useEffect } from 'react'
import lottieLight from 'lottie-web/build/player/lottie_light'

// eslint-disable-next-line import/extensions
import LoadingSpinnerAnimation from './loadingSpinner_animation.json'

const Loader = ({ isSmall = true }) => {
    const className = isSmall ? 'loader--small' : 'loader--big'
    useEffect(() => {
        lottieLight.loadAnimation({
            container: document.querySelector('#lottie-container')!,
            animationData: LoadingSpinnerAnimation,
            loop: true,
            autoplay: true,
        })
    }, [])

    // ISSUE: after one complete play, the spinner disappears for a few frames, before returning and restarting the animation
    // No fix planned? -> animation takes 3-4 seconds, could not replicate without massive throttling (e.g. the lowest possible performance preset in dev tools)
    // problem is likely lottie-react and it´s implementation of autoplay (likely just reloading entirely + loading of complex svg takes lottie a few ms)
    return <div id="lottie-container" className={className} />
}

export default Loader
