import React from 'react'
import DocumentFieldSelect from '../../Atoms/DocumentFieldSelect/DocumentFieldSelect'
import Select from '../../Atoms/Select/Select'
import NumberPicker from '../../Atoms/NumberPicker/NumberPicker'
import Switch from '../../Atoms/Switch/Switch'
import { DATE, NUMBER, STRING_SUITABLE_FOR_GROUPING } from '../../Atoms/FieldsConfiguration/FieldsConfigurationTypes'
import { ChartFieldListItemProps } from './ChartFieldListItem.types'
import { ValueField } from '../../Organisms/Widgets/WidgetModel'
import { groupingFieldOptionGenerator } from '../../../widgetManifestBuildingBlocks'
import ValueFieldEditor from '../ValueFieldEditor/ValueFieldEditor'
import TrendLineEditor from '../TrendLineEditor/TrendLineEditor'
import { IconNames } from '../../Atoms/Icon/Icon'
import { SelectValue } from '../../Atoms/Select/Select.types'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

const area = 'area'
const bar = 'bar'
const line = 'line'

type Option = {
    label: string
    value: SelectValue
    iconName: IconNames
}

const ChartFieldListItem: React.FC<ChartFieldListItemProps> = (props) => {
    const isBar = (): boolean => props.group.mode === bar
    const isArea = (): boolean => props.group.mode === area
    const canStack = (): boolean => isArea() || isBar()
    const showDeleteButton = !(props.index === 0 && props.numberOfGroups === 1)

    const handleValueFieldChange = (valueField: ValueField) => {
        props.onChange(props.index, ['valueField'])(valueField)
    }

    const axisOptions: Array<Option> = [
        {
            label: 'Axis Left',
            value: 'left',
            iconName: 'AxisPositionLeftIcon',
        },
        {
            label: 'Axis Right',
            value: 'right',
            iconName: 'AxisPositionRightIcon',
        },
    ]

    const modeOptions: Array<Option> = [
        {
            label: 'Area',
            value: area,
            iconName: 'InterpolateMissingValuesAreaIcon',
        },
        {
            label: 'Bar',
            value: bar,
            iconName: 'TimeBarChartIcon',
        },
        {
            label: 'Line',
            value: line,
            iconName: 'InterpolateMissingValuesLineIcon',
        },
    ]

    const stackOptions: Array<Option> = [
        {
            label: 'Stacked',
            value: true,
            iconName: 'NumStacksIcon',
        },
        {
            label: 'Not Stacked',
            value: false,
            iconName: 'SeparateStackingBarsIcon',
        },
    ]

    const drawingOptions: Array<Option> = [
        {
            label: 'Draw Linear',
            value: 'linear',
            iconName: isArea() ? 'AreaDrawingLinearIcon' : 'LineDrawingLinearIcon',
        },
        {
            label: 'Draw Smooth',
            value: 'monotone',
            iconName: isArea() ? 'AreaDrawingSmoothIcon' : 'LineDrawingSmoothIcon',
        },
        {
            label: 'Draw Steps',
            value: 'step',
            iconName: isArea() ? 'AreaDrawingStepsIcon' : 'LineDrawingStepsIcon',
        },
    ]

    const renderAdditionalOptions = () => {
        return (
            <>
                <div className="chart-field__stacking-options">
                    <NumberPicker
                        value={props.group.maximumNumberOfStacks}
                        onChange={props.onChange(props.index, ['maximumNumberOfStacks'])}
                        min={1}
                        max={12}
                        step={1}
                        placeholder="12"
                        label="Categories"
                        disabled={!props.group.yPivotField}
                    />
                    <HoverTooltip isVisible={true} header={props.group.isStacked ? 'Stacked' : 'Not Stacked'}>
                        <Select
                            className="chart-field__stack-mode widget-editor__input--tiny"
                            value={props.group.isStacked}
                            options={stackOptions}
                            displayIconAsValue={props.group.isStacked ? 'NumStacksIcon' : 'SeparateStackingBarsIcon'}
                            optionsWithIcons
                            smallChevron
                            onChange={props.onChange(props.index, ['isStacked'])}
                            isDisabled={!(props.group.yPivotField && canStack())}
                        />
                    </HoverTooltip>
                </div>
                <div className="chart-field__drawing-options">
                    <div className="chart-field__mode-and-style">
                        <Select
                            className="chart-field__mode widget-editor__input--small"
                            value={props.group.mode || 'line'}
                            options={modeOptions}
                            isSearchable={false}
                            optionsWithIcons
                            smallChevron
                            onChange={props.onChange(props.index, ['mode'])}
                        />
                        <Select
                            className="chart-field__drawing-style"
                            value={props.group.type || 'linear'}
                            isClearable={false}
                            onChange={props.onChange(props.index, ['type'])}
                            options={drawingOptions}
                            smallChevron
                            optionsWithIcons
                            isDisabled={isBar()}
                        />
                    </div>
                    <Switch
                        label="Interpolate Gaps"
                        className="chart-field__interpolate"
                        value={props.group.interpolate}
                        onChange={props.onChange(props.index, ['interpolate'])}
                        disabled={isBar()}
                    />
                    <Switch
                        label="Dots"
                        className="chart-field__dots"
                        value={props.group.showDots}
                        onChange={props.onChange(props.index, ['showDots'])}
                        disabled={isBar()}
                    />
                </div>
                <div className="chart-field__axis-and-trendline">
                    <Select
                        className="chart-field__axis widget-editor__input--small"
                        value={props.group.yAxis || 'left'}
                        onChange={props.onChange(props.index, ['yAxis'])}
                        options={axisOptions}
                        optionsWithIcons
                        smallChevron
                        isSearchable={false}
                    />
                    <TrendLineEditor
                        value={props.group.trendLineEntry}
                        onChange={props.onChange(props.index, ['trendLineEntry'])}
                        isLeftToRight={true}
                        label="Trend Line"
                    />
                </div>
                <Switch
                    label="Cumulate Values"
                    className="chart-field__sum-values"
                    value={props.group.sumUpAggregation}
                    onChange={props.onChange(props.index, ['sumUpAggregation'])}
                />
            </>
        )
    }

    const renderPivotField = () => (
        <div className="value-field__pivot-field">
            <DocumentFieldSelect
                className="chart-field__pivot-field"
                tags={[STRING_SUITABLE_FOR_GROUPING, DATE]}
                optionGenerator={groupingFieldOptionGenerator()}
                placeholder={(canStack() ? 'Stacking' : 'Pivot') + ' Field'}
                onChange={props.onChange(props.index, ['yPivotField'])}
                value={props.group.yPivotField}
                valueWithIcon="LayerPlusIcon"
                smallChevron
            />
        </div>
    )

    return (
        <ValueFieldEditor
            value={props.group.valueField}
            optionGenerator={groupingFieldOptionGenerator}
            tags={[NUMBER]}
            onChange={handleValueFieldChange}
            chartFieldListItem={{
                showDeleteButton,
                additionalOptions: renderAdditionalOptions(),
                onRemove: props.onRemove,
                index: props.index,
                pivotField: renderPivotField(),
            }}
            onTriggerAutoCompletion={props.onTriggerAutoCompletion}
            configuration={{
                isIgnoreTimeSelectionDisabled: true,
                areDateRangeFieldsAllowed: false,
            }}
        />
    )
}

export default React.memo(ChartFieldListItem)
