import React from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy'
import { RootState } from '../../../Redux/types'
import { selectors } from '../../../Redux'

const mapStateToProps = (state: RootState) => ({
    usesDarkMode: selectors.UI.CurrentUser.usesDarkMode(state),
})

type TooltipProps = {
    isVisible: boolean
    content?: Array<string> | string
    header?: string
    children?: any
    className?: string
} & ReturnType<typeof mapStateToProps>

const HoverTooltip: React.FunctionComponent<TooltipProps> = (props) => {
    const convertContentToString = () => {
        if (typeof props.content === 'object') return '<ul><li>' + props.content?.join('</li><li>') + '</li></ul>'
        if (typeof props.content === 'string') return '<ul><li>' + props.content + '</li></ul>'
        return ''
    }

    const renderTooltipContent = () => {
        return (
            <div className="help-tooltip--body">
                <div className="help-tooltip--header">
                    <h4 className="help-tooltip--header">{props.header}</h4>
                </div>
                {props.content ? (
                    <span
                        className="help-tooltip--content"
                        dangerouslySetInnerHTML={{ __html: convertContentToString() }}
                    />
                ) : null}
            </div>
        )
    }

    return props.isVisible ? (
        <Tooltip
            html={renderTooltipContent()}
            position="top"
            trigger="mouseenter"
            theme={props.usesDarkMode ? 'dark' : 'light'}
            className={props.className}
        >
            {props.children}
        </Tooltip>
    ) : (
        props.children || null
    )
}

export default connect(mapStateToProps)(HoverTooltip)
