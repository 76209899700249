import React, { PureComponent } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import DateSelector from '../../DateSelector/DateSelector'
import WidgetHeader, { WidgetHeaderFunctions } from '../../../Molecules/WidgetHeader/WidgetHeader'
import { DateRangeId } from '../../../../StaticManifests/manifest.dateRanges'

type TimeSelectorWidgetProps = {
    widgetState: {
        selectedPredefinedTimespan?: DateRangeId
        startMilliseconds?: number
        endMilliseconds?: number
        selectedTimeZone?: string
    }
    setWidgetState: (...args: any[]) => any
    widgetConfiguration: TimeSelectorWidgetConfiguration
} & WidgetHeaderFunctions

export type TimeSelectorWidgetConfiguration = {
    title?: string
    availablePredefinedTimespans?: Array<DateRangeId>
    workingDays: Array<number>
    showLastXDays: number
    defaultTimespan: {
        selectedPredefinedTimespan: DateRangeId
        startDate: number
        endDate: number
    }
    defaultSelectedTimeZone: string
}

type TimeSelectorChangeObject = {
    startDate?: moment.Moment | null
    endDate?: moment.Moment | null
    forecastPeriod?: string
    selectedPredefinedTimespan?: string
}

class TimeSelectorWidget extends PureComponent<TimeSelectorWidgetProps, unknown> {
    static displayName = 'TimeSelector'

    render() {
        const title = this.props.widgetConfiguration.title
        const availablePredefinedTimespans = this.props.widgetConfiguration.availablePredefinedTimespans

        const className = classnames('widget', 'time-selector-widget', {
            'widget--has-header': title || this.props.onChangeTitle,
        })
        const startDate = this.props.widgetState.startMilliseconds
            ? moment(this.props.widgetState.startMilliseconds)
            : null
        const endDate = this.props.widgetState.endMilliseconds
            ? moment(this.props.widgetState.endMilliseconds).subtract(1, 'milliseconds')
            : null

        return (
            <div className="widget-container">
                <WidgetHeader title={title} onChangeTitle={this.props.onChangeTitle} />
                <div className={className}>
                    <DateSelector
                        availablePredefinedTimespans={availablePredefinedTimespans}
                        dateRangeValue={this.props.widgetState.selectedPredefinedTimespan}
                        endDate={endDate}
                        onDateChange={this.handleDateChange}
                        startDate={startDate}
                        timeZone={this.props.widgetState.selectedTimeZone}
                        workingDays={this.props.widgetConfiguration.workingDays}
                        showLastXDays={this.props.widgetConfiguration.showLastXDays}
                    />
                </div>
            </div>
        )
    }

    handleDateChange = ({
        startDate,
        endDate,
        forecastPeriod,
        selectedPredefinedTimespan,
    }: TimeSelectorChangeObject) => {
        if (startDate && endDate) {
            this.props.setWidgetState({
                startMilliseconds: startDate.valueOf(),
                endMilliseconds: endDate.valueOf(),
                selectedPredefinedTimespan,
                forecastPeriod,
            })
        } else if (selectedPredefinedTimespan === 'allTime') {
            this.props.setWidgetState({
                startMilliseconds: null,
                endMilliseconds: null,
                selectedPredefinedTimespan,
                forecastPeriod: undefined,
            })
        }
    }
}

export default TimeSelectorWidget
