import { call, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import axios from '../../Utility/axios'
import { DataSourceStepData } from '../../../Components/Organisms/DataSources/DataSource.types'

export const fetchImporterStepData = createAction(
    'Data/Importer/ConfigPreview/FetchImporterStepData',
    (uuid: string, type: string, step: number, configuration: any, callback: () => void) => ({
        payload: { uuid, type, step, configuration, callback },
    })
)

export const fetchExtensionStepData = createAction(
    'Data/Importer/ConfigPreview/FetchExtensionStepData',
    (
        uuid: string,
        type: string,
        step: number,
        configuration: any,
        callback: (stepData: DataSourceStepData) => void
    ) => ({
        payload: { uuid, type, step, configuration, callback },
    })
)

export function* ExtensionStepDataSaga() {
    yield takeLatest(fetchExtensionStepData, function* (action) {
        try {
            const { uuid, type, step, configuration, callback } = action.payload
            const data = {
                type,
                step,
                configuration,
                importerUuid: uuid,
            }

            const result: AxiosResponse = yield axios({
                method: 'post',
                url: '/admin/importer/extension/step-data',
                // see PostProcessorStepDataRequest
                data,
            })
            // @ts-ignore
            yield call(callback, result.data || { preview: { type: 'error', content: result.data.message } })
        } catch (e) {
            console.error(e)
        }
    })
}

export default function* ImporterStepDataSaga() {
    yield takeLatest(fetchImporterStepData, function* (action) {
        try {
            const { uuid, type, step, configuration, callback } = action.payload
            // result of type ImporterStepDataResponse.groovy
            const data = {
                uuid,
                type,
                step,
                configuration,
            }

            const result: AxiosResponse = yield axios({
                method: 'post',
                url: '/admin/importer/step-data',
                // see ImporterStepDataRequest
                data,
            })
            // @ts-ignore
            yield call(callback, result.data || { preview: { type: 'error', content: result.data.message } })
        } catch (e) {
            console.error(e)
        }
    })
}
