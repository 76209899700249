import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import Button from '../Components/Atoms/Button/Button'
import { Page, PageControlRow, PageSection } from '../Components/Organisms/Page/Page'
import ApplicationHeader from './ApplicationHeader/ApplicationHeader'
import { AppDispatch } from '../store'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onToggleSelector: () => {
        dispatch(push('/'))
    },
    goBack: () => dispatch(goBack()),
})

type Props = ReturnType<typeof mapDispatchToProps>

class AccessDeniedScreen extends PureComponent<Props> {
    handleBackButtonClick = () => {
        this.props.goBack()
    }

    render() {
        return (
            <Page>
                <ApplicationHeader />
                <PageSection>
                    <h1 className="huge headline--primary">Oops... :-(</h1>
                    You tried to access a page without the necessary permissions. If you think this is a mistake,
                    contact your admin.
                    <PageControlRow>
                        <Button onClick={this.handleBackButtonClick}>go back</Button>
                        <Button onClick={this.props.onToggleSelector} type="primary">
                            open a dashboard
                        </Button>
                    </PageControlRow>
                </PageSection>
            </Page>
        )
    }
}

export default connect(null, mapDispatchToProps)(AccessDeniedScreen)
