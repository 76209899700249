import React, { FC, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import { components } from 'react-select'
import { ActiveFilters, QuickFilter } from './QuickFilterWidget.types'
import { SelectOption } from '../../../Atoms/Select/Select.types'
import { AsyncCreatableSelect } from '../../../Atoms/Select/Select'

const QuickFilterSelect: React.FC<{
    filter: QuickFilter
    activeFilters: ActiveFilters
    placeholder: string
    onChange: (values: null | Array<string>) => void
    value: Array<string> | null
    onLoadOptions: (value: string, callback: (result: any) => void) => void
}> = (props) => {
    const handleLoadOptions = debounce(
        (value: string) =>
            new Promise<Array<SelectOption>>((resolve) =>
                props.onLoadOptions(value, (result) => {
                    resolve(buildOptionsFromStrings(result))
                })
            ),
        250,
        { leading: true }
    )

    const [options, setOptions] = useState<Array<SelectOption>>([])

    useEffect(() => {
        handleLoadOptions('').then((options) => setOptions(options))
    }, [])

    const buildOptionsFromStrings = (values: Array<string>) =>
        values.map((v) => ({
            value: v,
            label: v,
        }))

    const handleFocusSelect = () => handleLoadOptions('').then((result) => setOptions(result))

    const uuid = props.filter.uuid
    return (
        <AsyncCreatableSelect
            key={'quick-filter-' + uuid}
            onChange={props.onChange}
            value={props.value}
            className="quick-filter quick-filter-select"
            isClearable
            isSearchable
            placeholder={props.placeholder}
            components={{ Option }}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isMulti
            options={options}
            loadOptions={handleLoadOptions}
            defaultOptions={options}
            onFocus={handleFocusSelect}
            sortOptionsAlphabetically
            menuPortalTarget={document.getElementById('root')}
        />
    )
}

export default QuickFilterSelect

const Option: FC = (props: any) => (
    <div>
        <components.Option {...props}>
            <div className="quick-filter-select--option">
                <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
            </div>
        </components.Option>
    </div>
)
