import React from 'react'
import { connect } from 'react-redux'
import { selectors } from '../../../Redux'
import Headline from '../../../Components/Atoms/Headline/Headline'
import Switch from '../../../Components/Atoms/Switch/Switch'
import { RootState } from '../../../Redux/types'

type ImporterSelectorProps = {
    value: Array<string>
    onChange: (importers: Array<string>) => void
} & ReturnType<typeof mapStateToProps>

const ImporterSelector: React.FC<ImporterSelectorProps> = (props) => {
    const handleCheckChange = (uuid: string) => () => {
        let list
        if (props.value.includes(uuid)) {
            list = props.value.filter((iter) => iter !== uuid)
        } else {
            list = [...props.value, uuid]
        }

        props.onChange(list)
    }

    return (
        <div>
            <Headline configuration={{ title: 'Select your importer' }} />

            {Object.values(props.importerArray).map((importer) => {
                const uuid = importer.uuid
                return (
                    <Switch
                        key={'importer-selector-' + uuid}
                        value={props.value.includes(uuid)}
                        onChange={handleCheckChange(uuid)}
                        label={importer.title}
                    />
                )
            })}
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        importerArray: selectors.Data.Importer.importersByUuid(state),
    }
}

export default connect(mapStateToProps)(React.memo(ImporterSelector))
