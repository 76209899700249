import {
    DATE,
    FieldsConfiguration,
    NUMBER,
    STRING_SUITABLE_FOR_GROUPING,
} from '../Components/Atoms/FieldsConfiguration/FieldsConfigurationTypes'

export const inTestingMode = typeof process !== 'undefined' && process.release && process.release.name === 'node'

export const testFieldsConfiguration: FieldsConfiguration = {
    timeRecordName: {
        name: 'timeRecordName',
        label: 'Time Record Name',
        tag: STRING_SUITABLE_FOR_GROUPING,
    },
    body: {
        name: 'body',
        label: 'body',
        tag: 'UNKNOWN',
    },
    projectName: {
        name: 'projectName',
        label: 'Name of Project',
        tag: STRING_SUITABLE_FOR_GROUPING,
    },
    sumTime: {
        name: 'sumTime',
        label: 'Logged Hours',
        tag: NUMBER,
        unit: 'h',
    },
    estimate: {
        name: 'estimate',
        label: 'Estimated Hours',
        tag: NUMBER,
        unit: 'h',
    },
    bookedOn: {
        name: 'bookedOn',
        label: 'Date of Time Record Creation',
        tag: DATE,
    },
}
