import React from 'react'
import { connect } from 'react-redux'

import { actions } from '../../../Redux'

import InputValidationWrapper from '../../../Components/Atoms/InputValidationWrapper/InputValidationWrapper'
import TextInput from '../../../Components/Atoms/TextInput/TextInput'
import FormFieldWithLabel from '../../../Components/Molecules/FormFieldWithLabel/FormFieldWithLabel'
import { AppDispatch } from '../../../store'
import ButtonWithTooltip from '../../../Components/Molecules/HoverTooltip/ButtonWithTooltip'

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        // 'admin' is the default user created by the server
        handleSavePassword: (password: string) =>
            dispatch(actions.Data.UserManagement.saveUserPassword('admin', password)),
    }
}

type SetAdminPasswordStepProps = ReturnType<typeof mapDispatchToProps>
type SetAdminPasswordStepState = {
    newPassword: string
    repeatPassword: string
}

class SetAdminPasswordStep extends React.PureComponent<SetAdminPasswordStepProps, SetAdminPasswordStepState> {
    firstInputRef: undefined | HTMLInputElement | null

    constructor(props: SetAdminPasswordStepProps) {
        super(props)

        this.state = {
            newPassword: '',
            repeatPassword: '',
        }
    }

    handleChange = (fieldName: keyof SetAdminPasswordStepState) => (newValue: string) =>
        this.setState({ [fieldName]: newValue } as Pick<SetAdminPasswordStepState, keyof SetAdminPasswordStepState>)

    handleSavePassword = () => this.props.handleSavePassword(this.state.newPassword)

    validatePassword = (newPassword: string, repeatPassword: string) => {
        const messages = {
            newPassword: '',
            repeatPassword: '',
        }

        let isValid = true

        if (newPassword.length < 8) {
            messages.newPassword = 'Password has to have a length of at least 8 characters.'
            isValid = false
        } else if (newPassword === 'password') {
            messages.newPassword = "'password' is not a safe password."
            isValid = false
        } else if (newPassword !== repeatPassword) {
            messages.repeatPassword = 'Passwords do not match.'
            isValid = false
        }

        return {
            messages,
            isValid,
        }
    }

    render() {
        const validation = this.validatePassword(this.state.newPassword, this.state.repeatPassword)

        return (
            <React.Fragment>
                <div className="setup-wizard__step">
                    <h1 className="setup-wizard__headline">Welcome to Exply!</h1>

                    <p>
                        First let's start with securing your Exply instance by changing the default password of the
                        default user to something more secure.
                    </p>

                    <InputValidationWrapper message={validation.messages.newPassword} messageType="hint">
                        <FormFieldWithLabel
                            configuration={{
                                label: 'New password for admin',
                                type: 'password',
                                value: this.state.newPassword,
                                editor: TextInput,
                                onChange: this.handleChange('newPassword'),
                            }}
                            ref={(ref) => {
                                this.firstInputRef = ref
                            }}
                        />
                    </InputValidationWrapper>

                    <InputValidationWrapper message={validation.messages.repeatPassword}>
                        <FormFieldWithLabel
                            configuration={{
                                label: 'Repeat new password for admin',
                                type: 'password',
                                value: this.state.repeatPassword,
                                editor: TextInput,
                                onChange: this.handleChange('repeatPassword'),
                                onEnterPress: validation.isValid ? this.handleSavePassword : null,
                            }}
                        />
                    </InputValidationWrapper>
                </div>

                <div className="setup-wizard__next-button">
                    <ButtonWithTooltip
                        isDisabled={!validation.isValid}
                        header="Invalid password"
                        content={[validation.messages.newPassword, validation.messages.repeatPassword]}
                        onClick={this.handleSavePassword}
                        type="primary"
                    >
                        Save & Next
                    </ButtonWithTooltip>
                </div>
            </React.Fragment>
        )
    }

    componentDidMount() {
        if (this.firstInputRef) {
            this.firstInputRef.focus()
        }
    }
}

export default connect(null, mapDispatchToProps)(SetAdminPasswordStep)
