import { getDefaultAnalysisTermWithField } from '../DefaultConfigurations'

export const capabilities = {
    min: {
        dateFields: 0,
        valueFields: 1,
        categoryFields: 1,
    },
    max: {
        dateFields: 1,
        valueFields: 1,
        categoryFields: 2,
    },
}

const noSubIssuesFilter = [
    {
        field: 'subIssueName',
        operator: 'equals',
        values: ['(empty value)'],
    },
]

export const createDefaultConfiguration = () => ({
    title: 'Gantt Chart',
    pivotField: 'issueName',
    stackingField: 'toStatus' as string | null,
    valueField: {
        analysisTerm: getDefaultAnalysisTermWithField('hoursInToStatus'),
        filter: noSubIssuesFilter,
        dateField: 'statusChangeDate' as string | null,
    },
    numberOfDecimals: 2,
})

export const configurationInitializerForWidgetExplorer = ({
    valueFields,
    dateFields,
    categoryFields,
}: {
    valueFields: Array<string>
    categoryFields: Array<string>
    dateFields: Array<string>
}) => {
    const defaultConfiguration = createDefaultConfiguration()

    const pivotField = categoryFields[0]
    defaultConfiguration.pivotField = pivotField
    defaultConfiguration.stackingField = categoryFields.length > 1 ? categoryFields[1] : null
    const valueField = valueFields[0]
    defaultConfiguration.valueField = {
        analysisTerm: getDefaultAnalysisTermWithField(valueField),
        // protect user from common misconfiguration of one of the most hot use-cases
        filter: pivotField === 'issueName' && valueField === 'hoursInToStatus' ? noSubIssuesFilter : [],
        dateField: dateFields.length > 0 ? dateFields[0] : null,
    }

    return defaultConfiguration
}
