import { all, put, takeLatest, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { PayloadAction, createAction } from '@reduxjs/toolkit'
import { actions, selectors } from '../Redux'
import axios from './Utility/axios'
import { ApiDashboardConfiguration } from '../Redux/Data/types'

function* continueIfIsInStep(step: number) {
    const currentStep: number = yield select(selectors.UI.SetupWizard.currentStep)
    if (currentStep === step) {
        yield put(actions.UI.SetupWizard.nextStep())
    }
}

export const saveExampleDashboard = createAction(
    'UI/SetupWizard/saveExampleDashboard',
    (dashboard: ApiDashboardConfiguration) => ({
        payload: { dashboard },
    })
)

export const closeWizard = createAction('UI/SetupWizard/close', () => ({ payload: {} }))

export default function* SetupWizardSagas() {
    yield all([
        takeLatest(actions.Data.UserManagement.savePasswordSuccess, function* () {
            yield continueIfIsInStep(1)
        }),
        takeLatest(actions.Data.Importer.sagaSaveFinished, function* () {
            yield put(actions.UI.SetupWizard.nextStep())
        }),
        takeLatest(actions.Data.UserManagement.setUsersAndGroups, function* () {
            yield continueIfIsInStep(3)
        }),
        takeLatest(
            saveExampleDashboard.type,
            function* (action: PayloadAction<{ dashboard: ApiDashboardConfiguration }>) {
                const dashboard = action.payload.dashboard
                const response: AxiosResponse = yield axios({
                    method: 'post',
                    url: 'dashboard/' + dashboard.uuid,
                    data: dashboard,
                })

                if (response.status >= 400) {
                    yield put(actions.UI.Notification.show({ id: 'dashboardSaveFailed' }))
                }
            }
        ),
        takeLatest(closeWizard.type, () => {
            window.location.reload()
        }),
    ])
}
