import React from 'react'
import Headline from '../../../Atoms/Headline/Headline'
import Button from '../../../Atoms/Button/Button'
import { ModalActions, ModalContent } from '../../../Molecules/Modal/Modal'
import TextArea from '../../../Atoms/TextArea/TextArea'
import ButtonWithTooltip from '../../../Molecules/HoverTooltip/ButtonWithTooltip'

type UploadLicenseModalProps = {
    onConfirmModal: (state: UploadLicenseModalState) => void
    onCancelModal: () => void
}

type UploadLicenseModalState = {
    license: string | null
}

export default class UploadLicenseModal extends React.PureComponent<UploadLicenseModalProps, UploadLicenseModalState> {
    constructor(props: UploadLicenseModalProps) {
        super(props)

        this.state = {
            license: null,
        }
    }

    render() {
        return (
            <ModalContent className="upload-license-modal">
                <Headline configuration={{ title: 'Upload License' }} />
                <TextArea
                    size="large"
                    placeholder="AAAAAwAAAFoAAAAGAAAACXVzZXJMaW1pdAAAAAMxMDAAAAACaWQAAAAPbXktdGVzdC1saWNlbmNlAAAAFGV4cGlyYXRpb25EYXRlTWlsbGlzAAAADTE1MjgxMDMxMTcwMDAAAAAg0Kk8G9qXMppCytubBlq+GUBiLYtok0hLvKzkblN6TkcAAAEAhry3fFuVzf..."
                    onChange={this.handleLicenseChange}
                />

                <ModalActions>
                    <Button onClick={this.props.onCancelModal}>Cancel</Button>
                    <ButtonWithTooltip
                        isDisabled={!this.isValid()}
                        header="License invalid"
                        type="primary"
                        onClick={this.handleConfirmation}
                    >
                        Upload
                    </ButtonWithTooltip>
                </ModalActions>
            </ModalContent>
        )
    }

    handleLicenseChange = (v: string) => this.setState({ license: v })

    isValid = () => this.state.license

    handleConfirmation = () => this.props.onConfirmModal(this.state)
}
