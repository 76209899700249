import React, { lazy, Suspense } from 'react'
import { MapWidgetProps } from './MapWiget.types'

const LazyWidget = lazy(() => import(/* webpackChunkName: "MapWidget" */ './MapWidget'))

const LazyWidgetWrapper: React.FC<MapWidgetProps> = (props) => (
    <Suspense fallback="">
        <LazyWidget {...props} />
    </Suspense>
)

export default LazyWidgetWrapper
