import React from 'react'
import moment from 'moment'
import { KeywordSelector } from './KeywordSelector'
import { OnTriggerAutoCompletion } from './FieldFilterInput'
import { NumberInput } from './NumberInput'
import { DateInput, displayFormat } from './DateInput'
import {
    DATE,
    FieldsConfiguration,
    NUMBER,
    STRING_SUITABLE_FOR_GROUPING,
    TagType,
    UNKNOWN,
} from '../FieldsConfiguration/FieldsConfigurationTypes'

export type ValueInput = React.FunctionComponent<{
    className?: string
    field: FieldType
    onTriggerAutoCompletion: OnTriggerAutoCompletion
    onValueChanged: (newValue: Array<string>) => void
    placeholder?: string
}>

export type FieldType = {
    field: string | null
    operator: string
    values: Array<string>
}

type FieldForFormatting = {
    /**
     * label of the field (not the field ID)
     */
    fieldLabel: string
    /**
     * type of the target field
     */
    tag: TagType
    /**
     * un-formatted values
     */
    values: Array<string>
}

export type Operator = {
    name: string
    /**
     * for internal use, see getFieldFilterTitle
     */
    _getTitle: (f: FieldForFormatting) => string
    hasValues: boolean
    isCompatibleWith: (type: TagType) => boolean
}

// eslint-disable-next-line
const isAny = (_: TagType) => true
const isString = (t: TagType) => t === STRING_SUITABLE_FOR_GROUPING
const isDate = (t: TagType) => t === DATE
const isNumber = (t: TagType) => t === NUMBER

export const permissionOperators: Array<Operator> = [
    {
        name: 'matches current user name',
        // eslint-disable-next-line
        _getTitle: (f) => 'is current user',
        isCompatibleWith: isString,
        hasValues: false,
    },
]

/**
 * all available operators, must match server-side
 * see FilterOperator.groovy
 * and FilterDtoTest.groovy
 */
export const defaultOperators: Array<Operator> = [
    {
        name: 'equals',
        _getTitle: (f) => `${f.fieldLabel} is ${formatValues(f)}`,
        isCompatibleWith: isAny,
        hasValues: true,
    },
    {
        name: "isn't equal to",
        _getTitle: (f) => `${f.fieldLabel} not ${formatValues(f)}`,
        isCompatibleWith: isAny,
        hasValues: true,
    },
    {
        name: 'is present',
        _getTitle: (f) => `has ${f.fieldLabel}`,
        isCompatibleWith: isAny,
        hasValues: false,
    },
    {
        name: 'is empty',
        _getTitle: (f) => `lacks ${f.fieldLabel}`,
        isCompatibleWith: isAny,
        hasValues: false,
    },
    {
        name: 'contains',
        _getTitle: (f) => `${f.fieldLabel} is ${formatValues(f, '*', '*')}`,
        isCompatibleWith: isString,
        hasValues: true,
    },
    {
        name: 'ends with',
        _getTitle: (f) => `${f.fieldLabel} is ${formatValues(f, '*', '')}`,
        isCompatibleWith: isString,
        hasValues: true,
    },
    {
        name: 'matches',
        _getTitle: (f) => `${f.fieldLabel} is ${formatValues(f)}`,
        isCompatibleWith: isString,
        hasValues: true,
    },
    {
        name: 'starts with',
        _getTitle: (f) => `${f.fieldLabel} is ${formatValues(f, '', '*')}`,
        isCompatibleWith: isString,
        hasValues: true,
    },
    {
        name: "doesn't contain",
        _getTitle: (f) => `${f.fieldLabel} not ${formatValues(f, '*', '*')}`,
        isCompatibleWith: isString,
        hasValues: true,
    },
    {
        name: "doesn't end with",
        _getTitle: (f) => `${f.fieldLabel} not ${formatValues(f, '*', '')}`,
        isCompatibleWith: isString,
        hasValues: true,
    },
    {
        name: "doesn't match",
        _getTitle: (f) => `${f.fieldLabel} not ${formatValues(f)}`,
        isCompatibleWith: isString,
        hasValues: true,
    },
    {
        name: "doesn't start with",
        _getTitle: (f) => `${f.fieldLabel} not ${formatValues(f, '', '*')}`,
        isCompatibleWith: isString,
        hasValues: true,
    },
    {
        name: 'before (excluding)',
        _getTitle: (f) => `${f.fieldLabel} before ${formatValues(f)}`,
        isCompatibleWith: isDate,
        hasValues: true,
    },
    {
        name: 'after (including)',
        _getTitle: (f) => `${f.fieldLabel} after ${formatValues(f)}`,
        isCompatibleWith: isDate,
        hasValues: true,
    },
    {
        name: 'lower than',
        _getTitle: (f) => `${f.fieldLabel} < ${formatValues(f)}`,
        isCompatibleWith: isNumber,
        hasValues: true,
    },
    {
        name: 'lower than equal',
        _getTitle: (f) => `${f.fieldLabel} <= ${formatValues(f)}`,
        isCompatibleWith: isNumber,
        hasValues: true,
    },
    {
        name: 'greater than',
        _getTitle: (f) => `${f.fieldLabel} > ${formatValues(f)}`,
        isCompatibleWith: isNumber,
        hasValues: true,
    },
    {
        name: 'greater than equal',
        _getTitle: (f) => `${f.fieldLabel} >= ${formatValues(f)}`,
        isCompatibleWith: isNumber,
        hasValues: true,
    },
]

/**
 * the suitable component to select a value based on the desired tag (aka type)
 */
export const valueInputsByTag: {
    [key in TagType]: ValueInput | undefined
} = {
    UNKNOWN: KeywordSelector,
    DATE: DateInput,
    DATE_RANGE: DateInput,
    NUMBER: NumberInput,
    STRING_SUITABLE_FOR_GROUPING: KeywordSelector,
    GEO_POINT: KeywordSelector,
    GEO_SHAPE: KeywordSelector,
}

/**
 * @param fieldFilter the field filter to format
 * @param fieldsConfiguration current configuration
 * @return human readable and nice title for the given filter
 */
export function getFieldFilterTitle(fieldFilter: FieldType, fieldsConfiguration: FieldsConfiguration): string {
    const fieldName = fieldFilter.field
    const field = fieldName && fieldsConfiguration[fieldName]
    const operatorName = fieldFilter.operator
    const operator = defaultOperators.concat(permissionOperators).find((op) => op.name === operatorName)
    const withLabel: FieldForFormatting = {
        // @ts-ignore
        fieldLabel: field && field.label ? field.label : fieldName,
        tag: field && field.tag ? field.tag : UNKNOWN,
        values: fieldFilter.values,
    }
    if (operator) {
        return operator._getTitle(withLabel)
    }

    // fall-back for no longer existing operators
    return withLabel.values.length > 0
        ? `${operatorName} ${formatStringValues(withLabel)}`
        : `${withLabel.fieldLabel} ${operatorName}`
}

function formatValues(fieldFilter: FieldForFormatting, prefix = '', suffix = '') {
    if (isDate(fieldFilter.tag)) {
        return formatTimestampValues(fieldFilter)
    }

    return formatStringValues(fieldFilter, prefix, suffix)
}

function formatStringValues(fieldFilter: FieldForFormatting, prefix = '', suffix = ''): string {
    const values = fieldFilter.values
    if (values.length > 0) {
        return values
            .map((i: string) => `${prefix}${i}${suffix}`)
            .toString()
            .replace(/,/g, ', ')
    }

    return '…'
}

function formatTimestampValues(fieldFilter: FieldForFormatting): string {
    const values = fieldFilter.values
    if (values.length > 0) {
        return values
            .map((stringValue: string) => {
                const numberValue = stringValue ? Number(stringValue) : Number.NaN
                const dateValue = !Number.isNaN(numberValue) ? moment(numberValue) : null
                return dateValue ? dateValue.format(displayFormat) : stringValue
            })
            .toString()
            .replace(/,/g, ', ')
    }

    return '…'
}
