import React from 'react'
import ApplicationHeader from './ApplicationHeader/ApplicationHeader'
import { Page, PageSection } from '../Components/Organisms/Page/Page'
import FieldsCustomizationContainer from './FieldsCustomization/FieldsCustomizationContainer'

const FieldsCustomization: React.FC = () => {
    const header = <ApplicationHeader pageTitle="Fields Customization" />

    return (
        <Page header={header}>
            <PageSection>
                <FieldsCustomizationContainer />
            </PageSection>
        </Page>
    )
}

export default FieldsCustomization
