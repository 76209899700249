import { takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import axios from '../../Utility/axios'
import { ImporterId } from '../../../Redux/Data/types'

export const killImportAction = createAction('SAGAS/killImport', (uuid: ImporterId) => ({ payload: { uuid } }))

export default function* ImporterRunStartSaga() {
    yield takeLatest(killImportAction, function* (action) {
        try {
            const importerUuid = action.payload.uuid
            yield axios({
                method: 'post',
                url: 'admin/importer/stop/' + importerUuid,
            })
        } catch (e) {
            console.error(e)
        }
    })
}
