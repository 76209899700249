import React from 'react'
import classNames from 'classnames'
import WidgetHeader, { WidgetHeaderFunctions } from '../Molecules/WidgetHeader/WidgetHeader'
import HelpTooltipIcon from '../Molecules/HelpTooltip/HelpTooltipIcon'
import { DashboardLoadingStates } from '../../Redux/Data/types'

const widgetProblems = {
    noValueField: 'Please select a value to be shown.',
    noSuchWidget: 'Widget Type not found.',
    LOADING: 'Loading...',
    noData: 'No data for selected period.',
    unsupportedCalculation:
        'Calculated Value Fields with fields as second value are currently only supported in sum mode.',
    negativeValues:
        "The result would contain negative values which this widget can't display. Please choose another widget.",
    tooManyResults:
        'There are too many results to render. You can add filters, disable stacking or select a shorter time period.',
    noGeoField: 'Please select a geo field!',
    noPermissions: "You don't have permission to view this widget.",
}

const WidgetProblemState: React.FC<WidgetProblemStateProps> = (props) => {
    const classname = classNames('widget', 'widget--state-information', {
        'widget--has-header': props.title,
    })

    const renderProblem = (problem?: string) => {
        if (problem === DashboardLoadingStates.LOADING) {
            return widgetProblems.LOADING
        }

        if (problem) {
            return problem
        }

        return (
            <>
                We have never seen this problem before. Feel free to let us know via{' '}
                <a href="mailto:support@exply.io">support@exply.io</a>.
            </>
        )
    }

    return (
        <div className={classname}>
            <WidgetHeader title={props.title} onChangeTitle={props.onChangeTitle} />
            <p>{renderProblem(props.problem)}</p>
            {props.problem === widgetProblems.noData && (
                <HelpTooltipIcon id={props.isJira ? 'widgetProblemNoDataJira' : 'widgetProblemNoDataOnPremise'} />
            )}
        </div>
    )
}

export default React.memo(WidgetProblemState)
export { widgetProblems }

type WidgetProblemStateProps = WidgetHeaderFunctions & {
    problem?: string
    title?: string
    isJira?: boolean
}
