import { put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions } from '../../../Redux'
import axios from '../../Utility/axios'
import { runImport } from '../Execution/RunStartSaga'
import { ImporterConfiguration } from '../../../Redux/Data/types'

export function* saveImporter(
    action: PayloadAction<{
        importer: ImporterConfiguration
        nextPath?: string
        runImporterAfterSave?: boolean
        continueEditingImporter?: boolean
        callback?: () => void
    }>
) {
    try {
        const { importer, nextPath, runImporterAfterSave, continueEditingImporter, callback } = action.payload
        const uuid = importer.uuid

        const response: AxiosResponse = yield axios({
            method: 'POST',
            url: 'admin/importer/' + uuid,
            data: importer,
        })

        if (response.status < 400) {
            if (nextPath) {
                yield put(push(nextPath))
            }

            if (!continueEditingImporter) {
                yield put(actions.Data.Importer.sagaSaveFinished())
            }

            if (runImporterAfterSave) {
                yield put(runImport(uuid))
            }

            if (callback) {
                yield callback()
            }
        } else {
            yield put(actions.UI.Notification.show({ id: 'importerSaveFailed' }))
        }
    } catch (e) {
        console.error(e)
    }
}

export default function* SaveImporterSaga() {
    yield takeLatest(actions.Data.Importer.save.type, saveImporter)
}
