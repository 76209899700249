import { setIn } from 'immutable'
import moment from 'moment'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uuid from 'uuid'
import { Draft } from 'immer'
import {
    CoordinatesAndSize,
    DashboardsState,
    DataState,
    WidgetConfiguration,
    WidgetGroupId,
    WidgetId,
    WidgetRequest,
    WidgetState,
} from '../types'
import {
    currentlyEditedDashboardConfig,
    currentlyEditedWidgetId,
    widgetById,
    widgetsForWidgetGroup,
} from './BaseSelectors'
import widgetTypes, { TIME_SELECTOR } from '../../../StaticManifests/manifest.widgetTypes'

export const defaultWidgetConfiguration: WidgetConfiguration = {
    id: 'widgetId',
    widgetGroup: 'widgetGroupId',
    type: 'rowChart',
    configuration: {
        title: 'Row Chart',
    },
    coordinatesAndSize: {
        x: 0,
        y: 0,
        width: 6,
        height: 6,
    },
}

export const defaultCurrentlyEditedWidget: DashboardsState['editedWidget'] = {
    widgetId: undefined,
    backup: undefined,
}

export const resetCurrentlyEditedWidget = (state: Draft<DataState>) => {
    state.dashboards.editedWidget = defaultCurrentlyEditedWidget
}

const setTimeForWidgetExplorer = (state: Draft<DataState>, widgetGroupId: WidgetGroupId) => {
    const timeSelector = getActiveTimeSelectorWidgetForWidgetGroupOfCurrentlyEditedDashboard(
        state as DataState,
        widgetGroupId
    )
    if (!timeSelector || !state.dashboards.request) {
        return
    }

    const timeSelectorId = timeSelector.id
    const { startMilliseconds, endMilliseconds } = state.dashboards.request.widgetRequests[timeSelectorId].widgetState

    state.dashboards.editedDashboard.explorer.selections.time.startDate = startMilliseconds
        ? moment(startMilliseconds)
        : null
    state.dashboards.editedDashboard.explorer.selections.time.endDate = endMilliseconds ? moment(endMilliseconds) : null
}

const firstTimeSelectorWidgetIdForWidgetGroup = (
    state: DataState,
    widgetGroupId: WidgetGroupId
): WidgetConfiguration | undefined =>
    widgetsForWidgetGroup({ Data: state }, widgetGroupId).find((widget) => widget.type === 'timeSelector')

// The active time selector is either the time selector of the global widget group, if existent, or else the time
// selector of the group the widget is in. If we don't have widgets, we don't have a time selector.
export const getActiveTimeSelectorWidgetForWidgetGroupOfCurrentlyEditedDashboard = (
    state: DataState,
    widgetGroupId: WidgetGroupId
): WidgetConfiguration | undefined => {
    const dashboardConfiguration = currentlyEditedDashboardConfig({ Data: state })

    if (!dashboardConfiguration || !dashboardConfiguration.widgetGroups.includes(widgetGroupId)) {
        return undefined
    }

    const globalWidgetGroupId = dashboardConfiguration.globalWidgetGroup

    return (
        // check globalWidgetGroup first
        (globalWidgetGroupId ? firstTimeSelectorWidgetIdForWidgetGroup(state, globalWidgetGroupId) : undefined) ??
        firstTimeSelectorWidgetIdForWidgetGroup(state, widgetGroupId)
    )
}

type AddWidgetPayload = {
    widgetId: WidgetId
    widgetConfiguration: WidgetConfiguration
    widgetRequest: WidgetRequest
}
const addWidgetToStore = (state: Draft<DataState>, payload: AddWidgetPayload) => {
    const { widgetId, widgetConfiguration, widgetRequest } = payload
    const widgetGroupId = widgetConfiguration.widgetGroup

    state.dashboards.editedWidget.widgetId = widgetId
    state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId].widgets.push(widgetId)
    state.dashboards.normalizedDashboardConfig.widgets.allIds.push(widgetId)
    state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId] = widgetConfiguration

    if (state.dashboards.request === null) {
        throw Error('dashboard request must be set to add a new widget')
    }
    state.dashboards.request.widgetRequests[widgetId] = widgetRequest
}

const widgetEditModeSlice = createSlice({
    name: 'Data/Dashboards/WidgetEditMode',
    initialState: {} as DataState,
    reducers: {
        startEditingWidget: (state, action: PayloadAction<WidgetId>) => {
            const widgetId = action.payload
            const widgetConfiguration = widgetById({ Data: state as DataState }, widgetId)

            setTimeForWidgetExplorer(state, widgetConfiguration.widgetGroup)

            state.dashboards.editedWidget.widgetId = widgetId
            state.dashboards.editedWidget.backup = {
                normalizedDashboardConfig: JSON.parse(JSON.stringify(state.dashboards.normalizedDashboardConfig)),
                request: JSON.parse(JSON.stringify(state.dashboards.request)),
                response: JSON.parse(JSON.stringify(state.dashboards.response)),
            }
        },

        // this should only get called by the WidgetEditModeSaga
        changeWidgetConfiguration: (
            state,
            action: PayloadAction<{
                propertyName: string
                value: any
                widgetRequestPart?: WidgetRequest
            }>
        ) => {
            const { propertyName, value, widgetRequestPart } = action.payload
            const widgetId = currentlyEditedWidgetId({ Data: state as DataState })
            if (widgetId === undefined) {
                throw Error('Can not change any configuration while no widget is getting edited!')
            }

            // need to use setIn here since some widget editors use dot-notation for their configuration properties,
            // for example nested properties of chartGroups in the TimeSeries Charts
            state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId] = setIn(
                state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId],
                ['configuration', ...propertyName.split('.')],
                value
            )

            if (widgetId && widgetRequestPart && state.dashboards.request && state.dashboards.request.widgetRequests) {
                state.dashboards.request.widgetRequests[widgetId] = widgetRequestPart
            }
        },
        changeWidgetTitle: (state, action: PayloadAction<{ newTitle: string }>) => {
            const { newTitle } = action.payload
            const widgetId = currentlyEditedWidgetId({ Data: state as DataState })
            if (widgetId === undefined) {
                throw Error('Widget must be edited to change the title!')
            }
            state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId].configuration.title = newTitle
        },

        // This is triggered by a SAGA which updates the default configuration of the widget
        changeWidgetType: (
            state,
            action: PayloadAction<{
                newType: string
                newConfiguration: WidgetConfiguration['configuration']
                newInitialState: WidgetState
                newCoordinatesAndSize: CoordinatesAndSize
            }>
        ) => {
            const { newType, newConfiguration, newInitialState, newCoordinatesAndSize } = action.payload
            const widgetId = currentlyEditedWidgetId({ Data: state as DataState })
            if (widgetId === undefined) {
                throw Error('must edit widget to change the widget type')
            }

            state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId].type = newType
            state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId].configuration = newConfiguration
            state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId].coordinatesAndSize = newCoordinatesAndSize

            if (state.dashboards.request == null) {
                throw new Error('dashboard request must be set!')
            }
            state.dashboards.request.widgetRequests[widgetId].widgetState = newInitialState
            state.dashboards.request.widgetRequests[widgetId].dependencyHash = null

            if (state.dashboards.response) {
                state.dashboards.response[widgetId] = {
                    data: null,
                    dependencyHash: null,
                    hasChanged: false,
                    hasFailed: false,
                    displayErrorMessage: null,
                }
            }
        },

        finishEditingWidget: (state) => {
            resetCurrentlyEditedWidget(state)
        },

        deleteWidgetById: (state, action: PayloadAction<WidgetId>) => {
            const widgetId = action.payload
            const widgetConfig = widgetById({ Data: state as DataState }, widgetId)

            state.dashboards.normalizedDashboardConfig.widgets.allIds =
                state.dashboards.normalizedDashboardConfig.widgets.allIds.filter((id) => id !== widgetId)
            delete state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId]

            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetConfig.widgetGroup].widgets =
                state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetConfig.widgetGroup].widgets.filter(
                    (id) => id !== widgetId
                )
        },

        duplicateWidgetById: (state, action: PayloadAction<{ widgetId: WidgetId; widgetGroupId: WidgetGroupId }>) => {
            const originalWidgetId = action.payload.widgetId
            const targetWidgetGroupId = action.payload.widgetGroupId

            const newWidgetId = uuid()
            const newWidgetConfiguration: WidgetConfiguration = {
                ...widgetById({ Data: state as DataState }, originalWidgetId),
                id: newWidgetId,
                widgetGroup: targetWidgetGroupId,
            }

            state.dashboards.normalizedDashboardConfig.widgets.allIds.push(newWidgetId)
            state.dashboards.normalizedDashboardConfig.widgets.byId[newWidgetId] = newWidgetConfiguration

            // add widget to the widget group
            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[targetWidgetGroupId].widgets.push(newWidgetId)

            if (!state.dashboards.response || !state.dashboards.request) {
                throw new Error('tried to duplicate a widget while the dashboard was not ready yet!')
            }

            // copy current widget data
            state.dashboards.response[newWidgetId] = state.dashboards.response[originalWidgetId]

            // copy the current widget state
            state.dashboards.request.widgetRequests[newWidgetId] =
                state.dashboards.request.widgetRequests[originalWidgetId]
        },

        // triggered by saga
        addWidgetWithInitialState: (state, action: PayloadAction<AddWidgetPayload>) =>
            addWidgetToStore(state, action.payload),

        // triggered by saga
        addWidgetWithInitialStateForEditing: (state, action: PayloadAction<AddWidgetPayload>) => {
            addWidgetToStore(state, action.payload)
            setTimeForWidgetExplorer(state, action.payload.widgetConfiguration.widgetGroup)
        },

        addInitialTimeSelectorWidget: (state, action: PayloadAction<WidgetGroupId>) => {
            const widgetId = uuid()
            const widgetGroupId = action.payload

            const timeSelectorType = widgetTypes[TIME_SELECTOR]
            const timeSelectorConfig = timeSelectorType.configurationInitializer()
            const timeSelectorState = timeSelectorType.stateInitializationFunction(timeSelectorConfig)

            state.dashboards.normalizedDashboardConfig.widgets.allIds.push(widgetId)
            state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId] = {
                id: widgetId,
                widgetGroup: widgetGroupId,
                type: TIME_SELECTOR,
                configuration: timeSelectorConfig,
                coordinatesAndSize: { x: 0, y: 0, height: 3, width: 6 },
            }

            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId].widgets.push(widgetId)
            state.dashboards.request!.widgetRequests[widgetId] = {
                dependencyHash: null,
                widgetState: timeSelectorState,
            }
        },

        restoreWidgetFromBackup: (state) => {
            const widgetId = state.dashboards.editedWidget.widgetId
            if (widgetId && state.dashboards.editedWidget.backup) {
                state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId] =
                    state.dashboards.editedWidget.backup.normalizedDashboardConfig.widgets.byId[widgetId]
            }
        },
    },
})

export const actions = widgetEditModeSlice.actions
export const reducer = widgetEditModeSlice.reducer

const currentlyEditedWidgetBackup = (state: { Data: DataState }) => state.Data.dashboards.editedWidget.backup

const isEditingExistingWidget = (state: { Data: DataState }) => {
    const widgetId = currentlyEditedWidgetId(state)
    const backup = currentlyEditedWidgetBackup(state)
    if (widgetId === undefined || backup === undefined) {
        return false
    }
    return backup.normalizedDashboardConfig.widgets.allIds.includes(widgetId)
}

export const selectors = {
    currentlyEditedWidgetId,
    isEditingExistingWidget,
}
