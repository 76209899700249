import MapWidget from './LazyMapWidget'
// @ts-ignore file ext
import MapWidgetIcon from './MapWidget.Icon.tsx'
import MapWidgetStateInitializer from './MapWidget.init'
import MapWidgetConfigurationInitializer, {
    MapWidgetConfigurationInitializerForWidgetExplorer,
} from './MapWidget.configurationInitializer'
import {
    colorScale,
    headline,
    numberOfDecimals,
    selectOneOf,
    valueField,
    WidgetManifestConfiguration,
} from '../../../../widgetManifestBuildingBlocks'
import NumberPicker from '../../../Atoms/NumberPicker/NumberPicker'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import GeoFieldEditor from '../../../Atoms/GeoFieldEditor/GeoFieldEditor'
import DocumentFieldSelect from '../../../Atoms/DocumentFieldSelect/DocumentFieldSelect'
import { MapWidgetConfiguration } from './MapWiget.types'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'

export const GEO_POINT = 'geo_point'
export const GEO_SHAPE = 'geo_shape'

const config: WidgetManifestConfiguration = {
    name: 'Map Widget',
    component: MapWidget,
    icon: MapWidgetIcon,
    stateInitializationFunction: MapWidgetStateInitializer,
    configurationInitializer: MapWidgetConfigurationInitializer,
    configurationInitializerForWidgetExplorer: MapWidgetConfigurationInitializerForWidgetExplorer,
    configurationValidations: (widgetConfig: MapWidgetConfiguration) => {
        if (widgetConfig.geoShapeField === undefined || widgetConfig.geoShapeField === null) {
            return widgetProblems.noGeoField
        }
    },
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 1,
        },
        max: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 1,
        },
    },
    editors: {
        data: [
            valueField({
                areDateRangeFieldsAllowed: true,
            }),
            selectOneOf('mode', 'Mode', [
                {
                    value: GEO_SHAPE,
                    label: 'Geo Shape',
                },
                {
                    value: GEO_POINT,
                    label: 'Geo Point',
                },
            ]),
            {
                widgetConfigurationProperty: 'geoShapeField',
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Geo Field',
                    icon: 'CabinetIcon',
                    editor: GeoFieldEditor,
                },
            },
            {
                widgetConfigurationProperty: 'pivotField',
                // the function call can be found in WidgetEditorForm.tsx
                isDisplayed: (widgetConfiguration: MapWidgetConfiguration) => {
                    return widgetConfiguration.mode === GEO_SHAPE
                },
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Category Field',
                    icon: 'CabinetIcon',
                    editor: DocumentFieldSelect,
                    clearable: false,
                    tags: ['STRING_SUITABLE_FOR_GROUPING'],
                    placeholder: 'Please select a category field',
                    helpTooltip: 'pivotFieldMapWidget',
                },
            },
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    {
                        widgetConfigurationProperty: 'maxNumberOfResults',
                        component: NumberPicker,
                        editorContextBuilder: () => ({
                            label: 'Maximum Number of Results',
                            placeholder: 100,
                            min: 0,
                            max: 1000,
                            step: 1,
                        }),
                        configuration: {
                            editorIcon: 'NumStacksIcon',
                            isIconAlwaysActive: true,
                        },
                    },
                    numberOfDecimals(),
                    colorScale(),
                    headline(
                        "You can set the default view port by moving the map and clicking the 'Set as default viewport' button"
                    ),
                ],
            },
        ],
    },
}

export default config
