import widgetTypes from '../StaticManifests/manifest.widgetTypes'
import { WidgetManifestConfiguration } from '../widgetManifestBuildingBlocks'

export type WidgetFeatureFlags = {
    hasJiraConfiguration?: boolean
    additionalWidgets: Array<string>
}

export function availableWidgets(features: WidgetFeatureFlags) {
    const { hasJiraConfiguration, additionalWidgets } = features
    return (
        Object.entries(widgetTypes)
            .map(([typeName, widget]) => ({
                ...widget,
                typeName,
            }))
            // remove JIRA widgets unless in JIRA mode
            .filter((w) => hasJiraConfiguration || !w.isJiraWidget)
            // remove OnPremise Only Widgets unless in On Premise Mode
            .filter((w) => !(w.isOnPremiseOnlyWidget && hasJiraConfiguration))
            // remove hidden widgets unless on white-list
            .filter((w) => !w.isHidden || (additionalWidgets && additionalWidgets.includes(w.typeName)))
    )
}

export type AvailableWidget = WidgetManifestConfiguration & { typeName: string }
export type AvailableWidgets = ReturnType<typeof availableWidgets>
