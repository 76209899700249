import { getDefaultAnalysisTermWithField } from '../DefaultConfigurations'
import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

const createDefaultConfiguration = () => ({
    sortBy: 'label',
    sortDirection: 'ascending',
    numberOfDecimals: 2,
    pivotFields: [
        {
            field: 'issueName',
        },
        {
            field: 'projectName',
        },
    ],
    valueFields: [
        {
            field: {
                analysisTerm: getDefaultAnalysisTermWithField('sumTime'),
                filter: [] as Array<FieldType>,
                dateField: 'primaryDate',
            },
            format: '%.1f h',
        },
    ],
    title: 'Pivot Table',
    collapseOptions: {
        allowCollapsing: false,
        indexOfGroupingField: 1,
    },
})

export default createDefaultConfiguration

export const PivotTableConfigurationInitializerForWidgetExplorer = ({
    valueFields,
    categoryFields,
    dateFields,
    filters,
}: {
    valueFields: Array<string>
    categoryFields: Array<string>
    dateFields: Array<string>
    filters: Array<FieldType>
}) => {
    const defaultConfiguration = createDefaultConfiguration()

    defaultConfiguration.pivotFields = categoryFields.map((e) => ({ field: e }))
    defaultConfiguration.valueFields = valueFields.map((e) => ({
        field: {
            analysisTerm: getDefaultAnalysisTermWithField(e),
            filter: filters,
            dateField: dateFields[0],
            mode: 'sum',
        },
        format: '%.1f h',
    }))

    return defaultConfiguration
}
