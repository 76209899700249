import moment from 'moment'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resetCurrentlyEditedWidget } from './WidgetEditMode'
import {
    DashboardLoadingStates,
    DataState,
    WidgetConfiguration,
    WidgetRequest,
    WidgetId,
    WidgetExplorer,
    ExecuteDashboardServerResponse,
    Filter,
} from '../types'
import { FieldKeys } from '../../../Components/Atoms/FieldFilterInput/FieldFilterInput'
import { DateObj } from '../../../Components/Organisms/DateSelector/DateSelector.types'
import { FieldStrings } from '../../../Containers/Dashboards/WidgetEditor/WidgetExplorerDataSelector/WidgetExplorerDataSelector'
import { currentlyEditedWidgetId } from './BaseSelectors'
import { buildDefaultWidgetPlacement } from '../../../Utility/DashboardEditor'

const widgetExplorerSlice = createSlice({
    name: 'Data/Dashboards/WidgetExplorer',
    initialState: {} as DataState,
    reducers: {
        setExplorerDataSelectorFields: (state, action: PayloadAction<{ fieldName: FieldStrings; selections: any }>) => {
            const { fieldName, selections } = action.payload
            state.dashboards.editedDashboard.explorer.selections[fieldName] = selections
        },

        addFilter: (state, action: PayloadAction<Filter>) => {
            const filter = action.payload
            state.dashboards.editedDashboard.explorer.selections.filters.push(filter)
        },
        changeFilter: (state, action: PayloadAction<{ index: number; field: FieldKeys; newValue: any }>) => {
            const { index, field, newValue } = action.payload
            state.dashboards.editedDashboard.explorer.selections.filters[index][field] = newValue
        },
        removeFilter: (state, action) => {
            const index = action.payload
            state.dashboards.editedDashboard.explorer.selections.filters.splice(index, 1)
        },

        changeWidgetTitle: (state, action: PayloadAction<{ widgetId: WidgetId; newTitle: string }>) => {
            const { widgetId, newTitle } = action.payload
            state.dashboards.editedDashboard.explorer.dashboardConfigurations.widgets.byId[
                widgetId
            ].configuration.title = newTitle
        },
        addSuggestedWidgetsToExplorer: (state, action: PayloadAction<Record<WidgetId, WidgetConfiguration>>) => {
            const widgetIds: Array<WidgetId> = Object.keys(action.payload)
            state.dashboards.editedDashboard.explorer.dashboardConfigurations.widgets = {
                allIds: widgetIds,
                byId: action.payload,
            }

            state.dashboards.editedDashboard.explorer.dashboardConfigurations.widgetGroups.byId[
                EXPLORER_DASHBOARD_WIDGET_GROUP_UUID
            ].widgets = widgetIds

            state.dashboards.editedDashboard.explorer.response = {}
        },

        setWidgetRequest: (state, action: PayloadAction<Record<WidgetId, WidgetRequest>>) => {
            state.dashboards.editedDashboard.explorer.request = action.payload
        },

        setDashboardResponse: (state, action: PayloadAction<ExecuteDashboardServerResponse>) => {
            state.dashboards.editedDashboard.explorer.response = action.payload.widgetResponses
            state.dashboards.loadingState = DashboardLoadingStates.SUCCESS
        },

        setTime: (state, action: PayloadAction<{ startDate: DateObj; endDate: DateObj }>) => {
            const { startDate, endDate } = action.payload
            state.dashboards.editedDashboard.explorer.selections.time.startDate = startDate
            state.dashboards.editedDashboard.explorer.selections.time.endDate = endDate
        },

        // this function is called by WidgetExplorerSaga.UpdateDashboardAfterAddingWidget
        addWidgetToStore: (state, action: PayloadAction<WidgetId>) => {
            const widgetId = action.payload

            const editedWidgetId = currentlyEditedWidgetId({ Data: state as DataState })
            if (editedWidgetId === undefined) {
                throw Error('Can not add widgets when not in edit mode!')
            }
            const targetWidgetGroup =
                state.dashboards.normalizedDashboardConfig.widgets.byId[editedWidgetId].widgetGroup

            const widgetConfig = selectors.widgetById({ Data: state as DataState }, widgetId)
            widgetConfig.widgetGroup = targetWidgetGroup
            state.dashboards.editedDashboard.explorer.addedWidgets.push(widgetId)

            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetConfig.widgetGroup].widgets.push(
                widgetId
            )
            state.dashboards.normalizedDashboardConfig.widgets.allIds.push(widgetId)
            state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId] = widgetConfig

            if (state.dashboards.request === null) {
                throw Error('Request must be set to create widget from explorer')
            }
            state.dashboards.request.widgetRequests[widgetId] = selectors.widgetRequest(
                { Data: state as DataState },
                widgetId
            )

            if (state.dashboards.response === null) {
                throw Error('Response must be set to create widget from explorer')
            }
            state.dashboards.response[widgetId] = selectors.widgetResponse({ Data: state as DataState }, widgetId)
        },

        setInitialWidgetPositionAndSize: (state, action: PayloadAction<WidgetId>) => {
            const widgetId = action.payload
            const editedWidgetId = state.dashboards.editedWidget.widgetId

            if (editedWidgetId === undefined) {
                throw Error('Can not add widgets when not in edit mode!')
            }

            // get other widgets in widget group
            const widgetGroupId = state.dashboards.normalizedDashboardConfig.widgets.byId[editedWidgetId].widgetGroup
            const otherWidgetsInGroup = state.dashboards.normalizedDashboardConfig.widgetGroups.byId[
                widgetGroupId
            ].widgets.filter((id) => id !== widgetId && id !== editedWidgetId)
            const otherWidgetPositions = otherWidgetsInGroup.map(
                (id) => state.dashboards.normalizedDashboardConfig.widgets.byId[id].coordinatesAndSize
            )

            // set new values
            state.dashboards.editedDashboard.explorer.dashboardConfigurations.widgets.byId[
                widgetId
            ].coordinatesAndSize = buildDefaultWidgetPlacement(otherWidgetPositions)
        },

        configureWidget: (state, action: PayloadAction<WidgetId>) => {
            const widgetId = action.payload

            const configuration =
                state.dashboards.editedDashboard.explorer.dashboardConfigurations.widgets.byId[widgetId]
            const request = state.dashboards.editedDashboard.explorer.request[widgetId]
            const response = state.dashboards.editedDashboard.explorer.response[widgetId]

            state.dashboards.editedWidget.widgetId = widgetId
            state.dashboards.normalizedDashboardConfig.widgets.allIds.push(widgetId)
            state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId] = configuration

            if (state.dashboards.request === null) {
                throw new Error('Invalid State! request should exist at this point!')
            }
            state.dashboards.request.widgetRequests[widgetId] = request

            if (state.dashboards.response === null) {
                throw new Error('Invalid State! response should exist at this point!')
            }

            state.dashboards.response = {
                [widgetId]: response,
            }
        },

        closeWidgetExplorer: (state) => {
            // need to clean up the draft widget for the advanced editor when closing the widget explorer
            const draftWidgetId = state.dashboards.editedWidget.widgetId

            if (
                draftWidgetId &&
                !state.dashboards.editedWidget.backup?.normalizedDashboardConfig.widgets.allIds.includes(draftWidgetId)
            ) {
                const widgetGroupId = state.dashboards.normalizedDashboardConfig.widgets.byId[draftWidgetId].widgetGroup
                delete state.dashboards.normalizedDashboardConfig.widgets.byId[draftWidgetId]
                state.dashboards.normalizedDashboardConfig.widgets.allIds =
                    state.dashboards.normalizedDashboardConfig.widgets.allIds.filter((id) => id !== draftWidgetId)
                state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId].widgets =
                    state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId].widgets.filter(
                        (id) => id !== draftWidgetId
                    )
                delete state.dashboards.request?.widgetRequests[draftWidgetId]
            }

            state.dashboards.editedDashboard.explorer.addedWidgets = []
            resetCurrentlyEditedWidget(state)
        },
    },
})

export const actions = widgetExplorerSlice.actions

export const reducer = widgetExplorerSlice.reducer

export const EXPLORER_DASHBOARD_UUID = 'widget-explorer-dashboard'
export const EXPLORER_DASHBOARD_WIDGET_GROUP_UUID = 'widget-explorer-dashboard-widget-group'

export const EXPLORER_DEFAULT_STATE: WidgetExplorer = {
    selections: {
        dateFields: ['primaryDate'],
        valueFields: [],
        categoryFields: [],
        time: {
            startDate: moment().add(-1, 'month').startOf('month'),
            endDate: moment().add(-1, 'month').endOf('month'),
        },
        filters: [],
    },

    // the dashboard send to the server for the widget previews inside the explorer tab
    dashboardConfigurations: {
        dashboards: {
            allIds: [EXPLORER_DASHBOARD_UUID],
            byId: {
                [EXPLORER_DASHBOARD_UUID]: {
                    id: EXPLORER_DASHBOARD_UUID,
                    title: 'Explorer Fake Dashboard',
                    globalWidgetGroup: null,
                    widgetGroups: [EXPLORER_DASHBOARD_WIDGET_GROUP_UUID],
                },
            },
        },
        widgetGroups: {
            allIds: [EXPLORER_DASHBOARD_WIDGET_GROUP_UUID],
            byId: {
                [EXPLORER_DASHBOARD_WIDGET_GROUP_UUID]: {
                    id: EXPLORER_DASHBOARD_WIDGET_GROUP_UUID,
                    dashboardId: EXPLORER_DASHBOARD_UUID,
                    position: 1,
                    filter: {
                        fields: [],
                        dateFields: [],
                    },
                    widgets: [],
                },
            },
        },
        widgets: {
            allIds: [],
            byId: {},
        },
        sharedDashboards: {
            allIds: [],
            byId: {},
        },
    },

    request: {},
    response: {},
    addedWidgets: [],
    isActive: false,
}

export const selectors = {
    currentFieldSelections: (state: { Data: DataState }) => state.Data.dashboards.editedDashboard.explorer.selections,
    dashboard: (state: { Data: DataState }) =>
        state.Data.dashboards.editedDashboard.explorer.dashboardConfigurations.dashboards.byId[EXPLORER_DASHBOARD_UUID],
    widgetGroupConfiguration: (state: { Data: DataState }) =>
        state.Data.dashboards.editedDashboard.explorer.dashboardConfigurations.widgetGroups.byId[
            EXPLORER_DASHBOARD_WIDGET_GROUP_UUID
        ],
    fullExplorerConfiguration: (state: { Data: DataState }) =>
        state.Data.dashboards.editedDashboard.explorer.dashboardConfigurations,
    widgetIds: (state: { Data: DataState }) =>
        // we can just use all widgets because the explorer does only has one dashboard
        state.Data.dashboards.editedDashboard.explorer.dashboardConfigurations.widgets.allIds,
    widgetById: (state: { Data: DataState }, widgetId: WidgetId) =>
        state.Data.dashboards.editedDashboard.explorer.dashboardConfigurations.widgets.byId[widgetId],

    widgetRequest: (state: { Data: DataState }, widgetId: WidgetId) =>
        state.Data.dashboards.editedDashboard.explorer.request[widgetId],
    widgetResponse: (state: { Data: DataState }, widgetId: WidgetId) =>
        state.Data.dashboards.editedDashboard.explorer.response[widgetId],

    isWidgetExplorerActive: (state: { Data: DataState }) => state.Data.dashboards.editedDashboard.explorer.isActive,
    addedWidgets: (state: { Data: DataState }) => state.Data.dashboards.editedDashboard.explorer.addedWidgets,

    widgetWasAlreadyAdded: (state: { Data: DataState }, widgetId: WidgetId) =>
        state.Data.dashboards.editedDashboard.explorer.addedWidgets.includes(widgetId),
}
