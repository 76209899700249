import React from 'react'
import { components } from 'react-select'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import Select from '../../Atoms/Select/Select'
import { AvailableWidget, availableWidgets, WidgetFeatureFlags } from '../../../Utility/WidgetUtility'
import { selectors } from '../../../Redux/UI/CurrentUser'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

// difficult to type as both OptionProps and SingleValueProps from react-select
const WidgetTypeSelectorOption = (props: any) => {
    const WidgetIcon = props.data.icon

    const className = classnames('widget-type-selector-option', {
        'widget-type-selector-option--disabled': props.data.isDisabled,
    })

    return (
        <HoverTooltip isVisible={props.data.isDisabled} header={"You don't have permission to use this widget."}>
            <div className={className}>
                <components.Option {...props}>
                    <WidgetIcon />
                    <label>{props.data.label}</label>
                </components.Option>
            </div>
        </HoverTooltip>
    )
}

const WidgetTypeSelector: React.FunctionComponent<{
    widgetFeatureFlags: WidgetFeatureFlags
    selectedType: string
    onChange: (widgetType: string) => void
}> = (props) => {
    const userSettings = useSelector(selectors.settings)

    const isWidgetDisabled = (widget: AvailableWidget) => {
        if (widget.requiredPermissions && userSettings) {
            // there can be multiple required permissions set, but only one needs to match
            return !widget.requiredPermissions.some((permission) => userSettings[permission])
        }

        return false
    }

    const generateOptions = () =>
        availableWidgets(props.widgetFeatureFlags).map((widgetType) => ({
            value: widgetType.typeName,
            label: widgetType.name,
            icon: widgetType.icon,
            isDisabled: isWidgetDisabled(widgetType),
        }))

    const handleWidgetTypeChange = (newType: string) => {
        if (newType !== props.selectedType) {
            props.onChange(newType)
        }
    }

    return (
        <div className="widget-type-selection">
            <Select
                value={props.selectedType}
                components={{
                    Option: WidgetTypeSelectorOption,
                    SingleValue: WidgetTypeSelectorOption,
                }}
                options={generateOptions()}
                onChange={handleWidgetTypeChange}
                isOptionDisabled={(option) => option.isDisabled}
            />
        </div>
    )
}

export default WidgetTypeSelector
