import React, { PureComponent } from 'react'
import Button from '../../../Atoms/Button/Button'
import { ModalActions, ModalContent } from '../../../Molecules/Modal/Modal'
import { ConfirmationModalProps } from './ConfirmationModal.types'

export default class ConfirmationModal extends PureComponent<ConfirmationModalProps> {
    render() {
        // do not pass event as payload since confirmation allows to send custom payloads and events might crash Redux store
        const handleDeny = () => this.props.onDenyModal()
        const handleCancel = () => this.props.onCancelModal()
        const handleConfirm = () => this.props.onConfirmModal()

        return (
            <ModalContent align="center">
                {this.renderMessage()}
                <ModalActions>
                    {this.props.currentModalConfiguration.denyText ? (
                        <Button type={this.props.currentModalConfiguration.denyButtonType} onClick={handleDeny}>
                            {this.props.currentModalConfiguration.denyText}
                        </Button>
                    ) : (
                        ''
                    )}

                    {!this.props.currentModalConfiguration.blocking && !this.props.currentModalConfiguration.noCancel && (
                        <Button type={this.props.currentModalConfiguration.cancelButtonType} onClick={handleCancel}>
                            {this.props.currentModalConfiguration.cancelText || 'Cancel'}
                        </Button>
                    )}

                    {this.props.currentModalConfiguration.confirmText ? (
                        <Button type={this.props.currentModalConfiguration.confirmButtonType} onClick={handleConfirm}>
                            {this.props.currentModalConfiguration.confirmText}
                        </Button>
                    ) : (
                        ''
                    )}
                </ModalActions>
            </ModalContent>
        )
    }

    renderMessage() {
        const Message = this.props.parameters?.message || this.props.currentModalConfiguration.message
        if (typeof Message === 'string' || !Message) {
            return Message
        }

        // The only place where we use this as Components is QuotaDetails.
        return <Message {...this.props.parameters} />
    }
}
