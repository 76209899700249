import React, { useState } from 'react'
import { ExceptionDto, ImportIncident } from '../ImporterListItem/ImporterListItem.types'
import Icon from '../../Atoms/Icon/Icon'
import { ListItem } from '../../Organisms/List/List'
import LogMessageContainer from '../LogMessageContainer/LogMessageContainer'

type ImportIncidentListItemProps = {
    incident: ImportIncident
}

const ImportIncidentListItem: React.FC<ImportIncidentListItemProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <ListItem
            className="import-incident-list-item"
            left={[
                <Icon name="AttentionIcon" size="small" color="error" />,
                <Icon name="ChevronIcon" rotation={isOpen ? 90 : 0} size="small" />,
                <p>{props.incident.nonTechieDescription}</p>,
            ]}
            isOpen={isOpen}
            onClick={() => setIsOpen((prevState) => !prevState)}
            collapsible={
                <LogMessageContainer
                    logs={props.incident.exception ? javaExceptionToString(props.incident.exception) : ''}
                />
            }
        />
    )
}

const javaExceptionToString = (exception: ExceptionDto) => {
    return `${exception.message}\nStack trace: ${exception.stackTrace.map(
        (element) => `\n${element.className}.${element.methodName}(${element.fileName}:${element.lineNumber})`
    )}`
}

export default React.memo(ImportIncidentListItem)
