import React from 'react'
import TextPreview from './TextPreview'
import TablePreview, { TablePreviewContent } from './TablePreview'
import { DataSourcePreviewData } from '../../DataSource.types'

const EditDataSourcePreview = React.forwardRef(
    (
        props: {
            disabled?: boolean
            previewData?: DataSourcePreviewData
            height?: number
        },
        ref
    ) => {
        if (props.disabled) {
            return <div>No preview available.</div>
        }

        if (props.previewData) {
            return props.previewData.type === 'table' ? (
                <TablePreview
                    content={props.previewData.content as TablePreviewContent}
                    ref={ref}
                    height={props.height}
                />
            ) : (
                <TextPreview content={props.previewData.content as string} />
            )
        }

        return <div>Loading...</div>
    }
)

EditDataSourcePreview.displayName = 'EditDataSourcePreview'

export default React.memo(EditDataSourcePreview)
