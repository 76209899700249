import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { formatBigNumberWithSIPrefix } from '../../../Utility/NumberFormator'
import { DateFormats } from '../../../Redux/Data/types'
import { selectors as systemSelectors } from '../../../Redux/Data/System'

const CustomizedTick: React.FC<CustomizedTickProps> = (props) => {
    const dateFormat = useSelector(systemSelectors.dateFormat)
    let value = props.payload!.value
    if (typeof value === 'string') {
        switch (props.granularity) {
            case 'week':
                value = moment(value).format('W')
                break
            case 'month':
                value = moment(value).format('MMM YY')
                break
            case 'quarter':
                value = 'Q' + moment(value).format("Q 'YY")
                break
            case 'year':
                value = moment(value).format('Y')
                break
            default:
                value = moment(value).format(dateFormat)
        }
    } else if (props.showPercentage) {
        value = value * 100 + '%'
    } else {
        value = formatBigNumberWithSIPrefix(value)
    }

    return (
        <g transform={`translate(${props.x},${props.y})`}>
            <text
                x={0}
                y={0}
                dx={props.dx}
                dy={props.dy}
                textAnchor={props.textAnchor}
                fontSize={props.fontSize}
                fill={props.fill}
                transform={`rotate(${props.angle === undefined ? '0' : props.angle})`}
            >
                {value} {props.unit}
            </text>
        </g>
    )
}

type CustomizedTickProps = Partial<{
    payload: {
        value: string | number
    }
    x: number
    y: number
    dx: number
    dy: number
    fill: string
    textAnchor: string
    angle: number
    fontSize: number
    unit: string
    granularity: string
    showPercentage?: boolean
    dateFormat?: DateFormats
}>

CustomizedTick.defaultProps = {
    dy: -8,
    textAnchor: 'middle',
    fontSize: 12,
}

export default React.memo(CustomizedTick)
