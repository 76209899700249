import React, { useEffect } from 'react'

/**
 * Hook that handles clicks outside of the passed ref (referenced element)
 */
function useClickOutsideCallback(ref: React.RefObject<any>, callback: () => unknown) {
    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, callback])
}

export { useClickOutsideCallback }
