import LoadingOverlay from 'react-loading-overlay'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Tile } from '../../Molecules/TileWall/TileWall'
import Icon from '../../Atoms/Icon/Icon'
import { RootState } from '../../../Redux/types'
import { DashboardId } from '../../../Redux/Data/types'
import { actions, selectors } from '../../../Redux'
import DashboardRemovalConfirmation from './DashboardRemovalConfirmation'
import { AppDispatch } from '../../../store'
import { deleteDashboard } from '../../../Sagas/DashboardSagas/DeleteDashboardSaga'
import DashboardContextButtons from './DashboardContextButtons'
import { DashboardGroupId } from '../../../Redux/UI/types'
import explyLogo from '../../../Images/exply_logo.svg'

type OwnProps = {
    dashboardId: DashboardId
    dashboardGroupId?: DashboardGroupId
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
    dashboard: selectors.Data.Dashboards.dashboardById(state, ownProps.dashboardId),
    isFavouriteDashboard: selectors.UI.FavouriteDashboards.isFavouriteDashboardById(state, ownProps.dashboardId),
    isShared: Boolean(
        selectors.Data.Dashboards.ShareDashboard.getSharedDashboardByDashboardId(state, ownProps.dashboardId)
    ),
    jiraCloudJwt: selectors.Data.System.jiraCloudJwt(state),
})

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
    onDeleteDashboard: () => dispatch(deleteDashboard(ownProps.dashboardId, false)),
    onToggleFavourite: () => dispatch(actions.UI.FavouriteDashboards.toggle(ownProps.dashboardId)),
    onShareDashboard: () =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'dashboardShare',
                parameters: {
                    dashboardId: ownProps.dashboardId,
                },
            })
        ),
})

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const DashboardTileViewItem = (props: Props) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [image, setImage] = useState('')

    const dashboardIcon = props.dashboard.icon
    const dashboardTitle = props.dashboard.title

    const imagePath = dashboardIcon ? `/exply/dashboard/user-files/${dashboardIcon}` : '/assets/exply_logo.7b1b9ae5.svg'

    const getImage = () => {
        fetch(imagePath, {
            headers: new Headers({
                Authorization: 'JWT ' + props.jiraCloudJwt,
            }),
        }).then((res) => {
            res.blob().then((blob) => {
                const img = URL.createObjectURL(blob)
                setImage(img)
            })
        })
    }

    useEffect(() => {
        if (props.dashboard.icon) {
            getImage()
        } else {
            setImage(explyLogo)
        }
    }, [])

    if (!props.dashboard) {
        return null
    }

    const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true)
    const handleHideDeleteConfirmation = () => setShowDeleteConfirmation(false)
    const handleToggleFavourite = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()
            props.onToggleFavourite()
        },
        [props.dashboardId]
    )

    const handleClickSharedIcon = useCallback((event: React.MouseEvent) => {
        event.preventDefault()
        event.stopPropagation()
        props.onShareDashboard()
    }, [])

    return (
        <LoadingOverlay
            key={props.dashboard.id}
            text={
                <DashboardRemovalConfirmation
                    handleCancelRemoval={handleHideDeleteConfirmation}
                    handleRemoveButtonClick={props.onDeleteDashboard}
                />
            }
            active={showDeleteConfirmation}
            classNamePrefix="dashboard-tile-"
            fadeSpeed={250}
        >
            <Tile className="dashboard-tile" dashboardId={props.dashboardId}>
                <header className="tile__header">
                    {props.isShared && (
                        <Icon
                            name={'LinkIcon'}
                            color="primary"
                            className="tile__shared-icon"
                            title="Dashboard is shared"
                            onClick={handleClickSharedIcon}
                        />
                    )}

                    <img
                        src={image}
                        alt={dashboardTitle}
                        className={dashboardIcon ? '' : 'dashboard-default-icon'}
                        loading="lazy"
                    />

                    <Icon
                        name={props.isFavouriteDashboard ? 'HeartFilledIcon' : 'HeartIcon'}
                        color={props.isFavouriteDashboard ? 'primary' : 'light'}
                        size="lg"
                        className="tile__favourite-icon"
                        onClick={handleToggleFavourite}
                    />
                </header>

                <div className="dashboard-tile__title">
                    <h3 title={dashboardTitle}>{dashboardTitle}</h3>
                    <DashboardContextButtons
                        dashboardId={props.dashboardId}
                        dashboardGroupId={props.dashboardGroupId}
                        handleShowDeleteConfirmation={handleShowDeleteConfirmation}
                    />
                </div>
            </Tile>
        </LoadingOverlay>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DashboardTileViewItem))
