import React from 'react'

import Switch from '../../Atoms/Switch/Switch'
import TextInput from '../../Atoms/TextInput/TextInput'
import { FieldsCustomizationEntryReduxProps } from '../../../Containers/FieldsCustomization/FieldsCustomizationEntryContainer'
import Icon from '../../Atoms/Icon/Icon'

export type FieldsCustomizationEntryOwnProps = {
    fieldIdentifier: string
}

type FieldsCustomizationEntryProps = FieldsCustomizationEntryOwnProps & FieldsCustomizationEntryReduxProps

const FieldsCustomizationEntry: React.FunctionComponent<FieldsCustomizationEntryProps> = (props) => {
    const isVisibleInUiWithDefault = () => {
        const isVisibleInUiUserConfig = props.userConfiguration?.isVisibleInUi
        if (isVisibleInUiUserConfig !== null) {
            return Boolean(isVisibleInUiUserConfig)
        }

        // Use default value for Switch value, if it is not explicitly set by the user.
        return Boolean(props.defaultValues?.isVisibleInUi)
    }

    const renderResetButton = () => {
        return <Icon name="UndoIcon" onClick={props.handleResetFieldsCustomization} title="reset" size="xs" />
    }

    const isDirty = (label: string, defaultLabel: string, isVisible: boolean, defaultVisible: boolean) =>
        (label && label !== defaultLabel) || isVisible !== defaultVisible

    const label = props.userConfiguration?.label || ''
    const identifier = props.fieldIdentifier
    const isVisibleInUi = isVisibleInUiWithDefault()
    const labelDefault = props.defaultValues?.label || ''
    const defaultVisible = Boolean(props.defaultValues?.isVisibleInUi)

    return (
        <div className="fields-customization-entry">
            <div className="fields-customization-entry--visibleInUi">
                <Switch value={isVisibleInUi} onChange={props.handleChangeIsVisibleInUi} />
            </div>
            <div className="fields-customization-entry--identifier" title={identifier}>
                {identifier}
            </div>
            <TextInput
                className="fields-customization-entry--label"
                value={label || ''}
                onChange={props.handleChangeLabel}
                placeholder={labelDefault || undefined}
            />
            <div className="fields-customization-entry--type">{props.type}</div>
            <div className="fields-customization-entry--usageDocCount">{props.usageDocCount}</div>
            <div className="fields-customization-entry--reset">
                {isDirty(label, labelDefault, isVisibleInUi, defaultVisible) ? renderResetButton() : ''}
            </div>
        </div>
    )
}

export default FieldsCustomizationEntry
