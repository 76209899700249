import { put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { actions } from '../../Redux'
import axios from '../Utility/axios'

function* load() {
    const response: AxiosResponse = yield axios({
        method: 'GET',
        url: 'admin/jira-importer-configuration/available-projects',
    })
    if (response.data && response.data.projects) {
        // NOTE: the response structure must be identical between the two routes
        yield put(actions.Data.JiraConfiguration.loadedAvailableProjects(response.data.projects))
    } else {
        yield put(actions.UI.Notification.show({ id: 'jiraConfigurationLoadAvailableProjectsError' }))
    }
}

export default function* LoadAvailableProjects() {
    yield takeLatest(actions.Data.JiraConfiguration.loadAvailableProjects.type, load)
}
