import SearchBarWidget from './SearchBarWidget'
import SearchBarWidgetStateInitializer from './SearchBarWidget.init'
import SearchBarWidgetConfigurationInitializer from './SearchBarWidget.configurationInitializer'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import TextInput from '../../../Atoms/TextInput/TextInput'
// @ts-ignore
import SearchBarWidgetIcon from './SearchBarWidget.Icon.tsx'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'

const config: WidgetManifestConfiguration = {
    name: 'Search Bar Widget',
    component: SearchBarWidget,
    icon: SearchBarWidgetIcon,
    stateInitializationFunction: SearchBarWidgetStateInitializer,
    configurationInitializer: SearchBarWidgetConfigurationInitializer,
    editors: {
        data: [
            {
                widgetConfigurationProperty: 'placeholder',
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Placeholder',
                    editor: TextInput,
                },
            },
        ],
        bottomTabs: [],
    },
    minWidth: 3,
    minHeight: 1,
    maxHeight: 1,
}

export default config
