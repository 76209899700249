import { createAction } from 'redux-actions'

//
// actionTypes
//
const INIT = 'EXPLY/System/INIT'
const SAGAS_INIT = 'Exply/System/Sagas_Init'

//
// Create actions
//
const init = createAction(INIT, (initialState: any) => ({ initialState }))
const sagasInit = createAction(SAGAS_INIT)

//
// Export actions
//
export const actions = {
    init,
    sagasInit,
}

export const actionTypes = {
    INIT,
    SAGAS_INIT,
}
