import { createSlice } from '@reduxjs/toolkit'

import { actionTypes as system } from '../../System'
import { ApiDashboardConfiguration, DashboardId, DashboardLoadingStates, DataState } from '../types'
import { createNormalizedDashboardConfigFromApiDashboardConfig } from './normalization'
import { EXPLORER_DEFAULT_STATE } from './WidgetExplorer'

const DashboardsSlice = createSlice({
    name: 'Data/Dashboards',
    initialState: {} as DataState,
    reducers: {},
    extraReducers: {
        [system.INIT]: (state, action) => {
            const apiDashboardConfig = action.payload.initialState.data.dashboards.byUuid as Record<
                DashboardId,
                ApiDashboardConfiguration
            >

            state.dashboards = {
                normalizedDashboardConfig: createNormalizedDashboardConfigFromApiDashboardConfig(apiDashboardConfig),
                loadingState: DashboardLoadingStates.INITIAL,

                // the currently executed widget request; in case we are NOT in edit mode. For the expected request structure, see
                // io.exply.core.dashboards.execution.model.DashboardRequest Groovy Class and subclasses
                request: null,

                // Dashboard Response data; in case we are NOT in edit mode
                // Structure:
                // {
                //    "<uuid-of-the-widget>": {
                //      (widget-specific-data)
                //    }
                // }
                response: null,

                editedDashboard: {
                    // filled when a dashboard is edited (ENABLE_EDIT_MODE)
                    dashboardId: undefined,
                    backup: undefined,
                    // side data not needed here since you cannot interact with the widget anyway
                    explorer: EXPLORER_DEFAULT_STATE,
                },

                editedWidget: {
                    widgetId: undefined,
                    backup: undefined,
                },
            }
        },
    },
})

export const actions = DashboardsSlice.actions
export const reducer = DashboardsSlice.reducer
