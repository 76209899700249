import React from 'react'

export const Page: React.FC<{
    children: React.ReactNode
    header?: React.ReactNode
}> = (props) => (
    <div className="page">
        {props.header}
        <div className="page__content">{props.children}</div>
    </div>
)

export const PageSection: React.FC<{
    children: React.ReactNode | string
}> = (props) => <section className="page__content-section">{props.children}</section>

export const PageControlRow: React.FC<{
    children: React.ReactNode
}> = (props) => <section className="page__control-row">{props.children}</section>
