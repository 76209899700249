import dateRanges from '../../../../StaticManifests/manifest.dateRanges'

const createDefaultConfiguration = () => {
    const workingDays = [1, 2, 3, 4, 5]
    const showLastXDays = 30
    const dateRange = dateRanges.lastMonth.getDateRange({
        workingDays,
        showLastXDays,
    })

    const defaultTimespan = {
        selectedPredefinedTimespan: 'lastMonth',
        startDate: dateRange.startDate?.valueOf(),
        endDate: dateRange.endDate?.valueOf(),
    }

    return {
        title: 'Time Selector Widget',
        defaultSelectedTimeZone: null,
        defaultTimespan,
        workingDays,
        availablePredefinedTimespans: Object.keys(dateRanges),
        showLastXDays,
    }
}

export default createDefaultConfiguration

export const TimeSelectorConfigurationInitializerForWidgetExplorer = createDefaultConfiguration
