import { defaultAnalysisTerm, getDefaultAnalysisTermWithField } from '../DefaultConfigurations'
import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

const createDefaultConfiguration = () => ({
    valueField: { analysisTerm: defaultAnalysisTerm, filter: [] as Array<FieldType>, dateField: 'primaryDate' },
    color: '#4fc3f7',
    fontColor: 'white',
    numberOfDecimals: 2,
    title: 'Metric Tile',
    comparison: [],
})

export default createDefaultConfiguration

export const MetricTileConfigurationInitializerForWidgetExplorer = ({
    valueFields,
    dateFields,
    filters,
}: {
    valueFields: Array<string>
    dateFields: Array<string>
    filters: Array<FieldType>
}) => {
    const defaultConfiguration = createDefaultConfiguration()

    defaultConfiguration.valueField = {
        analysisTerm: getDefaultAnalysisTermWithField(valueFields[0]),
        filter: filters,
        dateField: dateFields[0],
    }

    return defaultConfiguration
}
