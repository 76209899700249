import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { RootState } from '../../Redux/types'
import { AppDispatch } from '../../store'
import { selectors } from '../../Redux'

import ApplicationHeader from '../ApplicationHeader/ApplicationHeader'
import { Page, PageControlRow, PageSection } from '../../Components/Organisms/Page/Page'
import TextInput from '../../Components/Atoms/TextInput/TextInput'
import Button from '../../Components/Atoms/Button/Button'
import { containsErrorMessages, getValidationErrorMessage } from '../../Utility/Validation'
import InputValidationWrapper from '../../Components/Atoms/InputValidationWrapper/InputValidationWrapper'
import { changeName, changePassword, ChangePasswordConfig, changePreferredTheme } from '../../Sagas/MyProfile'
import Select from '../../Components/Atoms/Select/Select'
import { THEME } from '../../Utility/DarkMode'
import ButtonWithTooltip from '../../Components/Molecules/HoverTooltip/ButtonWithTooltip'

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: selectors.UI.CurrentUser.loggedInUser(state),
        passwordsAreManagedLocally: selectors.Data.FeatureFlags.passwordsAreManagedLocally(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        gotoDashboard: () => dispatch(push('/')),
        saveNewPassword: (config: ChangePasswordConfig) => dispatch(changePassword(config)),
        saveNewName: (name: string) => dispatch(changeName(name)),
        saveNewPreferredTheme: (theme: THEME) => dispatch(changePreferredTheme(theme)),
    }
}

type MyProfileProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
type MyProfileState = {
    name: string
    oldPassword: string
    newPassword: string
    repeatPassword: string
    preferredTheme: THEME
}

class MyProfile extends PureComponent<MyProfileProps, MyProfileState> {
    constructor(props: MyProfileProps) {
        super(props)
        this.state = {
            name: props.currentUser?.label ?? '',
            oldPassword: '',
            newPassword: '',
            repeatPassword: '',
            preferredTheme: props.currentUser?.uiSettings.preferredTheme ?? 'SYSTEM',
        }
    }

    validatePasswords = (oldPassword: string, newPassword: string, repeatPassword: string) => {
        let repeatPasswordMessage

        if (newPassword !== repeatPassword) {
            repeatPasswordMessage = 'Your Passwords do not match!'
        }

        const errorMessages = {
            oldPassword: getValidationErrorMessage(oldPassword, [
                {
                    type: 'required',
                    message: 'required',
                },
            ]),
            newPassword: getValidationErrorMessage(newPassword, [
                {
                    type: 'required',
                    message: 'required',
                },
                {
                    type: 'minLength',
                    length: 6,
                    message: 'Password needs to have a least 6 characters',
                },
            ]),
            repeatPassword: repeatPasswordMessage,
        }

        return {
            isValid: !containsErrorMessages(errorMessages),
            errorMessages,
        }
    }

    validateName = (name: string) => {
        const isValid = name.length >= 1

        return {
            isValid,
            errorMessages: isValid ? '' : 'The name cannot be empty.',
        }
    }

    handleChangePassword = () => {
        this.props.saveNewPassword({ oldPassword: this.state.oldPassword, newPassword: this.state.newPassword })
        this.setState({
            oldPassword: '',
            newPassword: '',
            repeatPassword: '',
        })
    }

    handlePasswordChange = (passwordField: 'oldPassword' | 'newPassword' | 'repeatPassword') => (password: string) => {
        this.setState({ [passwordField]: password } as Pick<MyProfileState, keyof MyProfileState>)
    }

    handleNameChange = (newName: string) => this.setState({ name: newName })

    handleSaveNewName = () => {
        this.props.saveNewName(this.state.name)
    }

    handleChangeTheme = (theme: THEME) => {
        this.setState(
            {
                preferredTheme: theme,
            },
            () => this.props.saveNewPreferredTheme(theme)
        )
    }

    renderHoverTooltip = (errorMessages: {
        oldPassword: string | undefined
        newPassword: string | undefined
        repeatPassword: string | undefined
    }) => {
        const messages: Array<string> = []
        if (errorMessages.oldPassword) messages.push(errorMessages.oldPassword)
        if (errorMessages.newPassword) messages.push(errorMessages.newPassword)
        if (errorMessages.repeatPassword) messages.push(errorMessages.repeatPassword)
        return messages
    }

    render() {
        return (
            <div className="my-profile">
                <ApplicationHeader pageTitle="My Profile" />
                <Page>
                    <PageSection>
                        <h1 className="huge headline--primary">Hello {this.props.currentUser?.label}</h1>

                        <div>
                            <h3>Exply Theme</h3>

                            <Select
                                className="my-profile__theme-select"
                                value={this.state.preferredTheme}
                                options={[
                                    {
                                        value: 'LIGHT',
                                        label: 'light theme',
                                    },
                                    {
                                        value: 'DARK',
                                        label: 'dark theme',
                                    },
                                    {
                                        value: 'SYSTEM',
                                        label: 'follow system theme',
                                    },
                                ]}
                                onChange={this.handleChangeTheme}
                            />
                        </div>

                        {this.props.passwordsAreManagedLocally ? this.renderChangeUserData() : null}

                        <PageControlRow>
                            <Button onClick={this.props.gotoDashboard}>go to dashboards</Button>
                        </PageControlRow>
                    </PageSection>
                </Page>
            </div>
        )
    }

    renderChangeUserData = () => {
        const passwordValidation = this.validatePasswords(
            this.state.oldPassword,
            this.state.newPassword,
            this.state.repeatPassword
        )
        const passwordErrorMessages = passwordValidation.errorMessages

        const nameValidation = this.validateName(this.state.name)

        return (
            <React.Fragment>
                <h3>Change your name</h3>

                <InputValidationWrapper message={nameValidation.errorMessages} messageType="hint">
                    <TextInput placeholder="Name" value={this.state.name} onChange={this.handleNameChange} />
                    <ButtonWithTooltip
                        isDisabled={!nameValidation.isValid}
                        header={nameValidation.errorMessages}
                        type="primary"
                        onClick={this.handleSaveNewName}
                    >
                        Change name
                    </ButtonWithTooltip>
                </InputValidationWrapper>

                <h3>Change Password</h3>

                <InputValidationWrapper message={passwordErrorMessages.oldPassword} messageType="hint">
                    <TextInput
                        placeholder="your old password"
                        type="password"
                        value={this.state.oldPassword}
                        onChange={this.handlePasswordChange('oldPassword')}
                    />
                </InputValidationWrapper>

                <InputValidationWrapper message={passwordErrorMessages.newPassword} messageType="hint">
                    <TextInput
                        placeholder="your new password"
                        type="password"
                        value={this.state.newPassword}
                        onChange={this.handlePasswordChange('newPassword')}
                    />
                </InputValidationWrapper>

                <InputValidationWrapper message={passwordErrorMessages.repeatPassword}>
                    <TextInput
                        placeholder="repeat your password"
                        type="password"
                        value={this.state.repeatPassword}
                        onChange={this.handlePasswordChange('repeatPassword')}
                    />
                    <ButtonWithTooltip
                        isDisabled={!passwordValidation.isValid}
                        content={this.renderHoverTooltip(passwordValidation.errorMessages)}
                        header="Password cannot be saved"
                        type="primary"
                        onClick={this.handleChangePassword}
                    >
                        change password
                    </ButtonWithTooltip>
                </InputValidationWrapper>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)
