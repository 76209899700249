import React from 'react'
import { Route, Switch, withRouter } from 'react-router'
import { connect } from 'react-redux'

import MainMenu from './Shared/MainMenu/MainMenu'
import NotificationContainer from './Shared/Notification/NotificationContainer'
import ModalContainer from './Shared/ModalContainer'

import WelcomeScreen from './WelcomeScreen/WelcomeScreen'

import DashboardContainer from './Dashboards/Dashboard/DashboardContainer'
import Dashboards from './Dashboards'
import {
    DashboardsRoute,
    EmbeddedDashboardRoute,
    hasAccessToRoute,
    RouteKeys,
    Routes,
    SharedDashboardRoute,
} from '../routes'

import DataSources from './DataSources'
import ImporterOverview from './Importers/ImporterOverview/ImporterOverview'

import UserManagement from './UserManagement'
import UserOverview from './UserManagement/UserOverview/UserOverview'
import UserEditing from './UserManagement/UserEditing/UserEditing'
import MyProfile from './MyProfile/MyProfile'
import FieldsCustomization from './FieldsCustomization'

import ConfigurationSharingScreen from './ConfigurationSharingScreen'
import AccessDeniedScreen from './AccessDeniedScreen'
import Imprint from '../Components/Organisms/Pages/Imprint'
import PrivacyStatement from '../Components/Organisms/Pages/PrivacyStatement'
import SystemInformation from './System/SystemInformation'
import SetupWizard from './SetupWizard'
import { actions, selectors } from '../Redux'
import EditDataSourceContainer from './Importers/EditDataSourceContainer'
import EmbeddedDashboards from './EmbeddedDashboards'
import ErrorBoundary from './ErrorBoundary/ErrorBoundary'
import { RootState } from '../Redux/types'
import ColorCustomizationContainer from './ColorCustomizationContainer'
import toggleLightMode, { THEME } from '../Utility/DarkMode'
import { AppDispatch } from '../store'
import { pingServerAction } from '../Sagas/SystemSaga'
import SharedDashboardsOverview from './SharedDashboardsOverview/SharedDashboardsOverview'

const mapStateToProps = (state: RootState) => {
    return {
        wizardIsActive: selectors.UI.SetupWizard.wizardIsActive(state),
        userSettings: selectors.UI.CurrentUser.settings(state),
        currentUser: selectors.UI.CurrentUser.loggedInUser(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    checkIfUserIsLoggedIn: () => dispatch(pingServerAction()),
    updateSystemTheme: (theme: THEME) => dispatch(actions.UI.CurrentUser.changeSystemTheme(theme)),
})

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class Application extends React.Component<Props> {
    render() {
        toggleLightMode(this.props.currentUser?.uiSettings.preferredTheme)

        return (
            <ErrorBoundary>
                {this.props.wizardIsActive ? (
                    <SetupWizard />
                ) : (
                    <Switch>
                        <Route path="/my-profile" component={MyProfile} />
                        <Route
                            path={Routes.importer.path}
                            component={this.hasAccessTo('importer', this.ImporterRoutes)}
                        />
                        <Route
                            path={Routes.userManagement.path}
                            component={this.hasAccessTo('userManagement', this.UserManagementRoutes)}
                        />
                        <Route
                            path={Routes.typesUiConfiguration.path}
                            component={this.hasAccessTo('typesUiConfiguration', FieldsCustomization)}
                        />
                        <Route
                            path={Routes.colorConfiguration.path}
                            component={this.hasAccessTo('colorConfiguration', ColorCustomizationContainer)}
                        />
                        <Route
                            path={Routes.configurationSharing.path}
                            component={this.hasAccessTo('configurationSharing', ConfigurationSharingScreen)}
                        />
                        <Route
                            path={Routes.sharedDashboardsOverview.path}
                            component={this.hasAccessTo('sharedDashboardsOverview', SharedDashboardsOverview)}
                        />
                        <Route
                            path={Routes.systemInformation.path}
                            component={this.hasAccessTo('systemInformation', SystemInformation)}
                        />

                        <Route path="/imprint" component={Imprint} />
                        <Route path="/privacy" component={PrivacyStatement} />

                        <Route path={EmbeddedDashboardRoute} component={this.EmbeddedDashboardRoutes} />
                        <Route path={SharedDashboardRoute} component={this.SharedDashboardRoutes} />
                        <Route component={this.DashboardRoutes} />
                    </Switch>
                )}

                <MainMenu />
                <NotificationContainer />
                <ModalContainer />
            </ErrorBoundary>
        )
    }

    hasAccessTo = (route: RouteKeys, component: React.ComponentType) => {
        if (hasAccessToRoute(route, this.props.userSettings)) {
            return component
        }

        return AccessDeniedScreen
    }

    ImporterRoutes = () => (
        <DataSources>
            <Switch>
                <Route path="/importer/new" component={EditDataSourceContainer} />
                <Route path="/importer/edit/:importerUuid" component={EditDataSourceContainer} />
                <Route
                    path="/importer/:importerUuid/extension"
                    render={({ match }) => <EditDataSourceContainer isExtension match={match} />}
                />
                <Route path="/importer/:mode" component={DataSources} />
                <Route path="/importer/:mode/:importerIdentifier" component={DataSources} />
                <Route component={ImporterOverview} />
            </Switch>
        </DataSources>
    )

    UserManagementRoutes = () => (
        <UserManagement>
            <Switch>
                <Route path="/user-management/edit/:id" component={UserEditing} />
                <Route path="/user-management/new-user" component={UserEditing} />
                <Route path="/user-management/new-group" component={UserEditing} />
                <Route component={UserOverview} />
            </Switch>
        </UserManagement>
    )

    EmbeddedDashboardRoutes = () => (
        <EmbeddedDashboards>
            <Switch>
                <Route
                    exact
                    path={`${EmbeddedDashboardRoute}/:dashboardId`}
                    render={({ match }) => <DashboardContainer match={match} isEmbedded={true} />}
                />
                <Route exact path={`${EmbeddedDashboardRoute}/:dashboardId/edit`} component={DashboardContainer} />
            </Switch>
        </EmbeddedDashboards>
    )

    SharedDashboardRoutes = () => (
        <EmbeddedDashboards>
            <Switch>
                <Route exact path={`${SharedDashboardRoute}/:dashboardId`} component={DashboardContainer} />
            </Switch>
        </EmbeddedDashboards>
    )

    DashboardRoutes = () => (
        <Dashboards>
            <Switch>
                <Route exact path={`${DashboardsRoute}/:dashboardId`} component={DashboardContainer} />
                <Route exact path={`${DashboardsRoute}/:dashboardId/edit`} component={DashboardContainer} />
                <Route component={WelcomeScreen} />
            </Switch>
        </Dashboards>
    )

    // when window becomes active again we want to check if the user is still logged in
    handleWindowBecomeActive = () => this.props.checkIfUserIsLoggedIn()

    componentDidMount = () => {
        window.addEventListener('focus', this.handleWindowBecomeActive)
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', ({ matches }) => {
            this.props.updateSystemTheme(matches ? 'DARK' : 'LIGHT')
        })
    }

    componentWillUnmount = () => {
        window.removeEventListener('focus', this.handleWindowBecomeActive)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Application))
