import { createAction } from '@reduxjs/toolkit'

const resetDashboardState = createAction(
    'EXPLY/Data/Dashboards/RESET_DASHBOARD_STATE',
    (currentlySelectedDashboardUuid: string) => ({ payload: { currentlySelectedDashboardUuid } })
)

export const actions = {
    resetDashboardState,
}
