import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { push } from 'connected-react-router'
import Logo from '../../Components/Atoms/Logo/Logo'
import { MainMenuToggle } from '../Shared/MainMenu/MainMenu'
import { selectors } from '../../Redux'
import ContextMenu, { ContextMenuItem } from '../../Components/Atoms/ContextMenu/ContextMenu'
import Icon from '../../Components/Atoms/Icon/Icon'
import { RootState } from '../../Redux/types'
import { AppDispatch } from '../../store'
import ApplicationMode from '../../ApplicationMode'

const mapStateToProps = (state: RootState) => ({
    currentUser: selectors.UI.CurrentUser.loggedInUser(state),
    userCanLogout: selectors.Data.FeatureFlags.userCanLogout(state),
    isJira: selectors.Data.FeatureFlags.jiraConfigurationFlag(state),
    applicationMode: selectors.Data.System.applicationMode(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    showMyProfile: () => dispatch(push('/my-profile')),
})

type ApplicationHeaderOwnProps = {
    pageTitle?: string
}

type ApplicationHeaderReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const ApplicationHeader: React.FC<ApplicationHeaderOwnProps & ApplicationHeaderReduxProps> = (props) => {
    useEffect(() => {
        document.title = props.pageTitle ? 'Exply | ' + props.pageTitle : 'Exply'
    }, [props.pageTitle])

    const handleLogout = useCallback(() => {
        // @ts-ignore not assignable to location
        window.location = '/exply/logout'
    }, [])

    const renderLeft = () => (
        <div className="application-header__right-wrapper">
            <MainMenuToggle />
            <Link to="/">
                <Logo variant="light" className="application-header__logo" />
            </Link>
            {props.pageTitle && <div className="application-header__page-title">{props.pageTitle}</div>}
        </div>
    )

    const renderRight = () => (
        <ContextMenu toggleElement={renderUserContextToggle()} menuNamePrefix="application-header__">
            <ContextMenuItem onClick={props.showMyProfile}>
                <Icon name="UserSolidIcon" title="show profile" label="My Profile" size="small" />
            </ContextMenuItem>

            {props.userCanLogout && (
                <ContextMenuItem onClick={handleLogout}>
                    <Icon name="SignOutIcon" title="log out" label="Log Out" size="small" />
                </ContextMenuItem>
            )}
        </ContextMenu>
    )

    const renderUserContextToggle = () => (
        <div className="application-header__user-context-toggle">
            <Icon
                name="UserLightIcon"
                label={
                    !props.isJira && props.applicationMode !== ApplicationMode.JIRACloud && props.currentUser
                        ? props.currentUser.label
                        : undefined
                }
                labelPosition="left"
                color="white"
            />
        </div>
    )

    return (
        <header className="application-header">
            <div className="application-header__left">{renderLeft()}</div>
            <div className="application-header__right">{renderRight()}</div>
        </header>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ApplicationHeader))
