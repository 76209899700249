import { getDefaultAnalysisTermWithField } from '../DefaultConfigurations'
import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

const createDefaultConfiguration = (type: 'burnChart' | 'jiraBurnChart') => () => ({
    numberOfDecimals: 2,
    showCompletedTasks: true,
    showGrid: false,
    quota: type === 'burnChart' ? { allocations: [] } : { numberOfSprints: 5, capacityField: 'sprintOriginalEstimate' },
    chartGroups: [
        {
            valueField: {
                // @ts-ignore null is not allowed as value for AnalysisTermTokens...
                analysisTerm: getDefaultAnalysisTermWithField(null),
                filter: [] as Array<FieldType>,
                customFieldLabel: 'guideline',
            },
            showDots: false,
            interpolate: false,
        },
        {
            valueField: {
                // @ts-ignore null is not allowed as value for AnalysisTermTokens...
                analysisTerm: getDefaultAnalysisTermWithField(null),
                filter: [] as Array<FieldType>,
                customFieldLabel: 'remaining',
            },
            mode: 'area',
            maximumNumberOfStacks: 10,
            isStacked: false,
            showDots: false,
            interpolate: true,
            type: 'step',
            customColor: '#f99509',
            yAxis: 'left',
        },
        {
            yPivotField: 'projectName',
            valueField: {
                analysisTerm: getDefaultAnalysisTermWithField('sumTime'),
                filter:
                    type === 'burnChart'
                        ? []
                        : ([{ field: 'projectName', operator: 'equals', values: [] }] as Array<FieldType>),
                dateField: 'primaryDate',
            },
            mode: 'bar',
            maximumNumberOfStacks: 10,
            isStacked: true,
            showDots: false,
            trendLineEntry: {
                showTrendLine: false,
                trendLineColor: '#ff0000', // must be long notation to work
            },
            interpolate: false,
            type: 'linear',
            yAxis: 'left',
        },
    ],
    title: type === 'burnChart' ? 'Burn Down Chart' : 'Sprint Burndown Chart',
})

export default createDefaultConfiguration

export const BurnChartConfigurationInitializerForWidgetExplorer =
    (type: 'burnChart' | 'jiraBurnChart') =>
    ({
        valueFields,
        categoryFields,
        dateFields,
        filters,
    }: {
        valueFields: Array<string>
        categoryFields: Array<string>
        dateFields: Array<string>
        filters: Array<FieldType>
    }) => {
        const defaultConfiguration = createDefaultConfiguration(type)()

        defaultConfiguration.chartGroups[2].valueField = {
            analysisTerm: getDefaultAnalysisTermWithField(valueFields[0]),
            filter: filters,
            dateField: dateFields[0],
        }
        defaultConfiguration.chartGroups[2].yPivotField = categoryFields[0]

        return defaultConfiguration
    }
