import React from 'react'
import classnames from 'classnames'

const renderSection = (part: string, content: any) => {
    if (!content) {
        return ''
    }

    return <div className={'secondary-header__' + part}>{content}</div>
}

const SecondaryHeader: React.FunctionComponent<{
    isPositionRelative?: boolean
    left?: React.ReactNode
    right?: React.ReactNode
    className?: string
}> = (props) => {
    const containerClassName = classnames('secondary-header-container', {
        'secondary-header-container--relative': props.isPositionRelative,
    })
    const headerClassName = classnames('secondary-header', props.className, {
        'secondary-header--relative': props.isPositionRelative,
    })
    const shadowClassName = classnames('secondary-header__shadow', {
        'secondary-header__shadow--relative': props.isPositionRelative,
    })

    return (
        <div className={containerClassName}>
            <div className={headerClassName}>
                {renderSection('left', props.left)}
                {renderSection('right', props.right)}
            </div>
            <div className={shadowClassName} />
        </div>
    )
}

export default SecondaryHeader
