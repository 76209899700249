import { COLORS } from '../Constants'
import { defaultAnalysisTerm, getDefaultAnalysisTermWithField } from '../DefaultConfigurations'
import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

const createDefaultConfiguration = () => {
    return {
        xAxisPivotField: 'projectName',
        xLimit: 10,
        yAxisPivotField: 'status' as string | null,
        yLimit: 10,
        valueField: {
            analysisTerm: defaultAnalysisTerm,
            filter: [] as Array<FieldType>,
            dateField: 'primaryDate',
        },
        color: COLORS[0],
        numberOfDecimals: 2,
        title: 'Scatter Plot',
    }
}

export default createDefaultConfiguration

export const ScatterPlotConfigurationInitializerForWidgetExplorer = ({
    valueFields,
    categoryFields,
    dateFields,
    filters,
}: {
    valueFields: Array<string>
    categoryFields: Array<string>
    dateFields: Array<string>
    filters: Array<FieldType>
}) => {
    const defaultConfiguration = createDefaultConfiguration()

    defaultConfiguration.xAxisPivotField = categoryFields[0]

    defaultConfiguration.yAxisPivotField = categoryFields.length === 2 ? categoryFields[1] : null

    defaultConfiguration.valueField = {
        analysisTerm: getDefaultAnalysisTermWithField(valueFields[0]),
        filter: filters,
        dateField: dateFields[0],
    }

    return defaultConfiguration
}
