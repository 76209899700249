import createUuid from 'uuid'
import { CoordinatesAndSize, WidgetConfiguration } from '../Redux/Data/types'
import widgetTypes from '../StaticManifests/manifest.widgetTypes'
import { ValueField } from '../Components/Organisms/Widgets/WidgetModel'
import { InitialWidgetConfiguration } from '../widgetManifestBuildingBlocks'

export function forEachWidgetOfGroup(
    widgetGroupUuid: string,
    widgetsIterator: Array<WidgetConfiguration>,
    action: any
) {
    for (const widget of widgetsIterator) {
        if (widget.widgetGroup === widgetGroupUuid) {
            action(widget)
        }
    }
}

export const createWidget = ({
    id,
    widgetGroup,
    type,
    coordinatesAndSize,
    configuration,
}: {
    id?: WidgetConfiguration['id']
    widgetGroup: WidgetConfiguration['widgetGroup']
    type: WidgetConfiguration['type']
    configuration?: WidgetConfiguration['configuration']
    coordinatesAndSize?: WidgetConfiguration['coordinatesAndSize']
}): WidgetConfiguration => ({
    id: id ?? createUuid(),
    widgetGroup,
    type,
    configuration: configuration || buildDefaultWidgetConfiguration(type, undefined /* no prior config */),
    coordinatesAndSize: coordinatesAndSize || buildDefaultWidgetPlacement([]),
})

export function buildDefaultWidgetConfiguration(widgetType: string, priorConfig: any) {
    const widgetTypeDefinition = widgetTypes[widgetType]
    return widgetTypeDefinition.configurationInitializer(priorConfig)
}

const PIVOT_FIELD = 'pivotField'
const PIVOT_FIELDS = 'pivotFields'
const STACKING_FIELD = 'stackingField'
const X_AXIS_PIVOT_FIELD = 'xAxisPivotField'
const Y_AXIS_PIVOT_FIELD = 'yAxisPivotField'
// const VALUE_FIELD = 'valueField';
const PROPERTY_WHITELIST = [
    PIVOT_FIELD,
    PIVOT_FIELDS,
    STACKING_FIELD,
    X_AXIS_PIVOT_FIELD,
    Y_AXIS_PIVOT_FIELD,
    // VALUE_FIELD, TODO not working anymore because not every widget has a mode or a grouping field
    'unit',
    'referenceLines',
]
const timeSeriesTypes = [
    'burnChart',
    'jiraBurnChart',
    'timeAreaChart',
    'timeBarChart',
    'timeLineChart',
    'timeSeriesChart',
]

function unsafeMergeWidgetConfigurationWithOldOne(
    initial: InitialWidgetConfiguration,
    prior: Record<string, any>,
    priorType: string,
    newType: string
) {
    const merged: {
        title: string
        [key: string]: any
    } = {
        title: '',
    }
    let pivotFields

    /*
     * copy values
     */
    Object.keys(initial).forEach((key) => {
        if (key === 'title') {
            merged[key] = initial[key]
        }

        switch (true) {
            // !!! we do not want to inherit other values blindly, since it breaks stuff, see #608 !!!
            case Object.prototype.hasOwnProperty.call(prior, key) && PROPERTY_WHITELIST.includes(key):
                merged[key] = prior[key]
                break

            // get pivot field from first of pivot fields
            case key === PIVOT_FIELD && Object.prototype.hasOwnProperty.call(prior, PIVOT_FIELDS):
                merged[key] = prior[PIVOT_FIELDS][0]?.field
                break
            // get pivot field from pivot fields
            case key === PIVOT_FIELDS && Object.prototype.hasOwnProperty.call(prior, PIVOT_FIELD):
                pivotFields = [{ field: prior[PIVOT_FIELD] }]
                // add stacking as second pivot field if available
                if (Object.prototype.hasOwnProperty.call(prior, STACKING_FIELD)) {
                    const stackingField = prior[STACKING_FIELD]
                    if (stackingField) {
                        pivotFields.push({ field: stackingField })
                    }
                }

                merged[key] = pivotFields
                break
            // use axis from matrix chart as pivot fields
            case key === PIVOT_FIELDS &&
                Object.prototype.hasOwnProperty.call(prior, X_AXIS_PIVOT_FIELD) &&
                Object.prototype.hasOwnProperty.call(prior, Y_AXIS_PIVOT_FIELD):
                merged[key] = [{ field: prior[X_AXIS_PIVOT_FIELD] }, { field: prior[Y_AXIS_PIVOT_FIELD] }]
                break

            // matrix chart axis from pivot fields
            case key === X_AXIS_PIVOT_FIELD && Object.prototype.hasOwnProperty.call(prior, PIVOT_FIELDS):
                pivotFields = prior[PIVOT_FIELDS]
                if (pivotFields.length > 1) {
                    merged.xAxisPivotField = pivotFields[0].field
                    merged.yAxisPivotField = pivotFields[1].field
                } else if (pivotFields.length === 1) {
                    merged.xAxisPivotField = pivotFields[0].field
                }

                break
            // matrix x axis from pivot field
            case key === X_AXIS_PIVOT_FIELD && Object.prototype.hasOwnProperty.call(prior, PIVOT_FIELD):
                merged[key] = prior[PIVOT_FIELD]
                break
            // matrix x axis from stacking field if available and nothing else is set
            case key === X_AXIS_PIVOT_FIELD:
                if (Object.prototype.hasOwnProperty.call(prior, STACKING_FIELD) && !merged[key]) {
                    merged[key] = prior[STACKING_FIELD]
                } else if (!merged[key]) {
                    merged[key] = initial[key]
                }

                break

            // get pivot field from matrix chart x axis
            case key === PIVOT_FIELD && Object.prototype.hasOwnProperty.call(prior, X_AXIS_PIVOT_FIELD):
                merged[key] = prior[X_AXIS_PIVOT_FIELD]
                break

            // get pivot field from stacking field
            case key === PIVOT_FIELD && Object.prototype.hasOwnProperty.call(prior, STACKING_FIELD):
                merged[key] = prior[STACKING_FIELD]
                break
            case key === PIVOT_FIELDS && Object.prototype.hasOwnProperty.call(prior, STACKING_FIELD):
                merged[key] = [{ field: prior[STACKING_FIELD] }]
                break
            case key === Y_AXIS_PIVOT_FIELD &&
                !merged[key] &&
                Object.prototype.hasOwnProperty.call(prior, STACKING_FIELD):
                merged[key] = prior[STACKING_FIELD]
                break

            case key === STACKING_FIELD && Object.prototype.hasOwnProperty.call(prior, PIVOT_FIELDS):
                if (prior[PIVOT_FIELDS][1]) {
                    merged[key] = prior[PIVOT_FIELDS][1].field
                }

                break
            case key === STACKING_FIELD && Object.prototype.hasOwnProperty.call(prior, Y_AXIS_PIVOT_FIELD):
                merged[key] = prior[Y_AXIS_PIVOT_FIELD]
                break

            // get value field from value fields
            case key === 'valueField' && Object.prototype.hasOwnProperty.call(prior, 'valueFields'): {
                if (getSingleValueFieldFromConfig(prior)) {
                    merged[key] = getSingleValueFieldFromConfig(prior)
                } else {
                    merged[key] = initial[key]
                }

                break
            }

            // valueField to valueField
            case key === 'valueField' && Object.prototype.hasOwnProperty.call(prior, 'valueField'): {
                merged[key] = prior.valueField
                break
            }

            // valueField from timeSeries
            case key === 'valueField' && timeSeriesTypes.includes(priorType): {
                const valueFieldIndex = priorType === 'burnChart' || priorType === 'jiraBurnChart' ? 2 : 0
                if (prior.chartGroups[valueFieldIndex]?.valueField) {
                    merged[key] = prior.chartGroups[valueFieldIndex]?.valueField
                } else {
                    merged[key] = initial[key]
                }

                break
            }

            // valueFields to valueFields
            case key === 'valueFields' && Object.prototype.hasOwnProperty.call(prior, 'valueFields'): {
                // from pivot table
                if (prior.valueFields[0].field) {
                    merged[key] = prior.valueFields.map((v: any) => v.field)
                } else if (initial.valueFields[0].field) {
                    // to pivot table
                    merged[key] = prior.valueFields.map((v: any) => ({ field: v, format: '%.1f h' }))
                } else {
                    merged[key] = prior.valueFields
                }

                break
            }

            // create list of valueFields from single valueField
            case key === 'valueFields' && Object.prototype.hasOwnProperty.call(prior, 'valueField'): {
                if (initial.valueFields[0].analysisTerm && prior.valueField.analysisTerm) {
                    merged[key] = [prior.valueField]
                } else {
                    // to pivot table
                    merged[key] = [
                        {
                            field: prior.valueField,
                            format: '%.1f h',
                        },
                    ]
                }

                break
            }

            case key === 'valueFields' && timeSeriesTypes.includes(priorType): {
                // the burnChart has 2 predefined chartGroups
                // (see BurnChartDefinition or BurnChartWidget.configurationInitializer)
                const valueFieldIndex = priorType === 'burnChart' || priorType === 'jiraBurnChart' ? 2 : 0
                if (prior.chartGroups[valueFieldIndex]?.valueField) {
                    // merging to pivot table
                    if (initial.valueFields[0].field) {
                        merged[key] = [{ field: prior.chartGroups[valueFieldIndex]?.valueField, format: '%.1f h' }]
                    } else {
                        merged[key] = [prior.chartGroups[valueFieldIndex]?.valueField]
                    }
                } else {
                    merged[key] = initial[key]
                }

                break
            }

            // switching to a time series from something else
            // (merging between time series is handled in their configInitializer)
            case key === 'chartGroups' && !Object.prototype.hasOwnProperty.call(prior, 'chartGroups'): {
                const valueField = getSingleValueFieldFromConfig(prior)
                if (valueField) {
                    // the burnChart has 2 predefined chartGroups
                    // (fsee BurnChartDefinition or BurnChartWidget.configurationInitializer)
                    if (newType === 'burnChart' || newType === 'jiraBurnChart') {
                        merged[key] = [initial[key][0], initial[key][1], { ...initial[key][2], valueField }]
                    } else {
                        merged[key] = [{ ...initial[key][0], valueField }]
                    }
                } else {
                    merged[key] = initial[key]
                }

                break
            }

            default:
                merged[key] = initial[key]
                break
        }
    })
    return merged
}

const getSingleValueFieldFromConfig: (config: Record<string, any>) => ValueField | null = (config) => {
    if (Object.prototype.hasOwnProperty.call(config, 'valueField')) {
        return config.valueField
    }

    if (Object.prototype.hasOwnProperty.call(config, 'valueFields')) {
        if (config.valueFields[0].field) {
            return config.valueFields[0].field
        }

        return config.valueFields[0]
    }

    return null
}

export function mergeWidgetConfigurationWithOldOne(
    initialConfiguration: InitialWidgetConfiguration,
    latestConfiguration: Record<string, any>,
    priorType: string,
    newType: string
) {
    try {
        return unsafeMergeWidgetConfigurationWithOldOne(initialConfiguration, latestConfiguration, priorType, newType)
    } catch (error) {
        console.warn('failed to merge widget configurations', { initialConfiguration, latestConfiguration }, error)
        return initialConfiguration
    }
}

// TODO respect min / max size
export const buildDefaultWidgetPlacement = (groupWidgetsCoordsWithoutNewWidget: Array<CoordinatesAndSize>) => {
    // find widgets in bottom row and to the very right
    const highestY = groupWidgetsCoordsWithoutNewWidget.reduce(
        (prev, current) => (prev.y > current.y ? prev : current),
        {
            y: 0,
        }
    ).y
    const bottomRowWidgets = groupWidgetsCoordsWithoutNewWidget.filter((position) => position.y === highestY)
    const bottomRightWidget = bottomRowWidgets.reduce((prev, current) => (prev.x > current.x ? prev : current), {
        y: 0,
        x: 0,
        height: 0,
        width: 0,
    })

    const newCoords = {
        x: 0,
        y: 0,
        width: 6,
        height: 6,
    }

    // widget fits next to bottomRight widget, max width of dashboard is 24
    if (bottomRightWidget.x + bottomRightWidget.width < 19) {
        newCoords.x = bottomRightWidget.x + bottomRightWidget.width
        newCoords.y = bottomRightWidget.y
    } else {
        // push to next row
        newCoords.y = bottomRightWidget.y + bottomRightWidget.height
    }

    return newCoords
}
