import omit from 'lodash.omit'
import { ApiDashboardConfiguration, DashboardId, DataState } from '../types'
import { dashboardById, widgetById } from './BaseSelectors'
import { widgetGroupConfigurationsForDashboard, widgetIdsForDashboard } from './ViewMode'
import { EXPLORER_DASHBOARD_UUID, selectors as widgetExplorerSelectors } from './WidgetExplorer'

const isExplorerDashboard = (dashboardId: DashboardId) => dashboardId === EXPLORER_DASHBOARD_UUID

/**
 * Denormalize a single Dashboard from normalized state to ApiDashboardConfiguration
 */
export function createApiDashboardConfigurationFromNormalizedDashboardConfiguration(
    state: { Data: DataState },
    dashboardId: DashboardId
): ApiDashboardConfiguration {
    const dashboardConfiguration = isExplorerDashboard(dashboardId)
        ? widgetExplorerSelectors.dashboard(state)
        : dashboardById(state, dashboardId)

    if (dashboardConfiguration === undefined) {
        throw Error('tried to denormalize a dashboard which does not exist')
    }

    return {
        ...omit(dashboardConfiguration, ['id', 'widgetGroups']),
        uuid: dashboardConfiguration.id,
        widgetGroups: denormalizeWidgetGroupsForDashboardConfiguration(state, dashboardId),
        widgets: denormalizeWidgetsForDashboardConfiguration(state, dashboardId),
    }
}

function denormalizeWidgetGroupsForDashboardConfiguration(
    state: { Data: DataState },
    dashboardId: DashboardId
): ApiDashboardConfiguration['widgetGroups'] {
    const widgetGroups = isExplorerDashboard(dashboardId)
        ? [widgetExplorerSelectors.widgetGroupConfiguration(state)]
        : widgetGroupConfigurationsForDashboard(state, dashboardId)

    if (widgetGroups === undefined) {
        return {}
    }

    return Object.values(widgetGroups).reduce((acc, widgetGroup) => {
        return {
            ...acc,
            [widgetGroup.id]: {
                ...omit(widgetGroup, ['id', 'widgets', 'dashboardId']),
                uuid: widgetGroup.id,
            },
        }
    }, {} as ApiDashboardConfiguration['widgetGroups'])
}

function denormalizeWidgetsForDashboardConfiguration(
    state: { Data: DataState },
    dashboardId: DashboardId
): ApiDashboardConfiguration['widgets'] {
    const widgetIds = isExplorerDashboard(dashboardId)
        ? widgetExplorerSelectors.widgetIds(state)
        : widgetIdsForDashboard(state, dashboardId)

    return widgetIds.reduce((acc, widgetId) => {
        const widget = isExplorerDashboard(dashboardId)
            ? widgetExplorerSelectors.widgetById(state, widgetId)
            : widgetById(state, widgetId)

        return {
            ...acc,
            [widgetId]: {
                ...omit(widget, 'id'),
                uuid: widget.id,
            },
        }
    }, {} as ApiDashboardConfiguration['widgets'])
}
