import React, { PureComponent, RefObject } from 'react'
import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import { RootState } from '../../../../Redux/types'
import { AppDispatch } from '../../../../store'
import { actions, selectors } from '../../../../Redux'
import FieldFilterEditor from '../../../../Components/Molecules/FieldFilterEditor/FieldFilterEditor'
import { FieldType } from '../../../../Components/Atoms/FieldFilterInput/FieldFilterManifest'
import { FieldKeys } from '../../../../Components/Atoms/FieldFilterInput/FieldFilterInput'
import Select from '../../../../Components/Atoms/Select/Select'
import Icon, { IconNames } from '../../../../Components/Atoms/Icon/Icon'
import { SelectOption } from '../../../../Components/Atoms/Select/Select.types'
import { FieldsConfigurationContext } from '../../../../Components/Atoms/FieldsConfiguration/FieldsConfigurationContext'
import {
    DATE,
    FieldConfiguration,
    STRING_SUITABLE_FOR_GROUPING,
} from '../../../../Components/Atoms/FieldsConfiguration/FieldsConfigurationTypes'
import { Option } from '../../../../Components/Atoms/DocumentFieldSelect/DocumentFieldSelect'
import { groupingFieldOptionGenerator } from '../../../../widgetManifestBuildingBlocks'

const mapStateToProps = (state: RootState) => ({
    explorerFieldSelection: selectors.Data.Dashboards.WidgetExplorer.currentFieldSelections(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onSetDataSelectorFields: (fieldName: FieldStrings, selections: any) =>
        dispatch(actions.Data.Dashboards.WidgetExplorer.setExplorerDataSelectorFields({ fieldName, selections })),
    onTriggerAutoCompletion: (type: any, value: any, exclude: any, callback: any) =>
        dispatch(actions.UI.Autocompletion.fetch(type, value, exclude, callback)),
    onAddFilter: (filter: FieldType) => dispatch(actions.Data.Dashboards.WidgetExplorer.addFilter(filter)),
    onChangeFilter: (index: number, field: FieldKeys, newValue: any) =>
        dispatch(actions.Data.Dashboards.WidgetExplorer.changeFilter({ index, field, newValue })),
    onRemoveFilter: (index: number) => dispatch(actions.Data.Dashboards.WidgetExplorer.removeFilter(index)),
})

export type FieldStrings = 'valueFields' | 'dateFields' | 'categoryFields'

export type Selection = string | Array<string> | null

type WidgetExplorerDataSelectorProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class WidgetExplorerDataSelector extends PureComponent<WidgetExplorerDataSelectorProps> {
    context!: React.ContextType<typeof FieldsConfigurationContext>

    static contextType = FieldsConfigurationContext

    ref: RefObject<HTMLDivElement> = React.createRef()

    componentDidMount() {
        if (this.ref !== null && this.ref.current !== null && this.ref.current.parentNode) {
            // this is due to a bug where this part of the
            // UI was always scrolled down for no reason, react-select scrolling into view?
            // @ts-ignore scrollTop doesnt exist?
            this.ref.current.parentNode.scrollTop = 0
        }
    }

    render() {
        const hasSelectedValueField = this.props.explorerFieldSelection.valueFields.length === 0

        return (
            <div className="widget-explorer__data-selection" ref={this.ref}>
                {this.renderHeadline('Filters', 'FilterIcon')}
                <FieldFilterEditor
                    className="widget-explorer__data-selection__filters"
                    onFilterAdded={this.props.onAddFilter}
                    onFilterChanged={this.props.onChangeFilter}
                    onFilterRemoved={this.props.onRemoveFilter}
                    onTriggerAutoCompletion={this.props.onTriggerAutoCompletion}
                    fields={this.props.explorerFieldSelection.filters}
                />

                <div className="widget-explorer__data-selection__fields">
                    {this.renderHeadline('Value', 'AnalyticsIcon')}
                    {this.renderFieldList('valueFields', ['NUMBER'])}
                    <LoadingOverlay
                        classNamePrefix="widget-explorer-loading"
                        fadeSpeed={250}
                        active={hasSelectedValueField}
                        text="Please select a Value Field"
                    >
                        {this.renderHeadline('Category', 'CabinetIcon')}
                        {this.renderFieldList(
                            'categoryFields',
                            [STRING_SUITABLE_FOR_GROUPING, DATE],
                            groupingFieldOptionGenerator()
                        )}

                        {this.renderHeadline('Date', 'CalendarIcon')}
                        {this.renderFieldList('dateFields', [DATE])}
                    </LoadingOverlay>
                </div>
            </div>
        )
    }

    renderHeadline = (title: string, iconName: IconNames) => (
        <h2 className="headline--underline widget-explorer__data-selection__heading">
            <Icon name={iconName} position="left" size="small" />
            {title}
        </h2>
    )

    renderFieldList = (
        fieldName: FieldStrings,
        tags: Array<string>,
        optionGenerator?: (element: FieldConfiguration) => Array<Option>
    ) => {
        const selectedFieldEntries = this.props.explorerFieldSelection[fieldName]

        const values = this.getValuesForSelect(selectedFieldEntries)
        const filteredFields = this.getFilteredFields(fieldName, tags, optionGenerator)

        return (
            <Select
                placeholder="Search ..."
                menuIsOpen={true}
                isMulti={fieldName !== 'dateFields'}
                value={values}
                options={filteredFields}
                sortOptionsAlphabetically
                onChange={(selection: Selection) => this.handleChangeDataSelectionField(fieldName, selection)}
            />
        )
    }

    getValuesForSelect = (values: Array<string>) => {
        if (!values) {
            return []
        }

        return (
            values
                // here, we filter out NULL values; otherwise values which have been removed crash the next step.
                .filter((v: any) => v)
                .reduce((options: Array<SelectOption>, value) => {
                    const entry = this.getFieldsWithDocCount().find((e) => e.name === value)
                    if (entry) {
                        // catch disabled fields returning undefined
                        options.push({
                            value: entry.name,
                            label: entry.label,
                        })
                    }

                    return options
                }, [])
        )
    }

    getFilteredFields = (
        fieldName: string,
        tags: Array<string>,
        optionGenerator?: (element: FieldConfiguration) => Array<Option>
    ): Array<Option> => {
        const list = this.getFieldsWithDocCount()
        return list
            .filter((element) => !tags || tags.indexOf(element.tag) !== -1)
            .reduce((accumulator: Array<Option>, element: FieldConfiguration) => {
                const options = optionGenerator
                    ? optionGenerator(element)
                    : [{ label: element.label, value: element.name }]
                accumulator.push(...options)
                return accumulator
            }, [])
    }

    getFieldsWithDocCount = (): Array<FieldConfiguration> => {
        const list: Array<FieldConfiguration> = Object.values(this.context)
        list.push({ label: 'Number of entries', name: 'docCount', tag: 'NUMBER' })
        return list
    }

    handleChangeDataSelectionField = (fieldName: FieldStrings, selection: Selection) => {
        if (selection === null) {
            selection = []
        }

        if (!Array.isArray(selection)) {
            selection = [selection]
        }

        this.props.onSetDataSelectorFields(fieldName, selection)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetExplorerDataSelector)
