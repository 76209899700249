import SpacerWidget from './LazySpacerWidget'
// @ts-ignore tsx extension
import SpacerWidgetIcon from './SpacerWidget.Icon.tsx'
import SpacerWidgetStateInitializer from './SpacerWidget.init'
import SpacerWidgetConfigurationInitializer from './SpacerWidget.configurationInitializer'
import RichTextEditor from '../../../Atoms/RichTextEditor/LazyRichTextEditor'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'

const config: WidgetManifestConfiguration = {
    name: 'Spacer Widget',
    component: SpacerWidget,
    icon: SpacerWidgetIcon,
    stateInitializationFunction: SpacerWidgetStateInitializer,
    configurationInitializer: SpacerWidgetConfigurationInitializer,
    editors: {
        data: [
            {
                component: FormFieldWithLabel,
                widgetConfigurationProperty: 'richTextMessage',
                configuration: {
                    editor: RichTextEditor,
                    label: 'Message',
                },
            },
        ],
        bottomTabs: [],
    },
    minHeight: 1,
}

export default config
