import React, { useEffect } from 'react'
import produce from 'immer'
import NumberPicker from '../../Atoms/NumberPicker/NumberPicker'
import Select from '../../Atoms/Select/Select'
import TextInput from '../../Atoms/TextInput/TextInput'
import Icon from '../../Atoms/Icon/Icon'
import { SelectOption } from '../../Atoms/Select/Select.types'
import Button from '../../Atoms/Button/Button'

type ColorScaleEntry = {
    lowerBound: number | null
    upperBound?: number | null
    color: string
}

type ColorScaleConfig = {
    ranges: Array<ColorScaleEntry>
    isPercentage: boolean
}

const ColorScaleEditor: React.FunctionComponent<{
    value?: ColorScaleConfig
    onChange: (arg?: ColorScaleConfig) => void
    configuration?: {
        noPercentage: boolean
    }
}> = (props) => {
    useEffect(() => {
        if (!props.value) {
            props.onChange({
                ranges: props.configuration?.noPercentage ? [] : defaultColorScale,
                isPercentage: props.configuration?.noPercentage || true,
            })
        }
    }, [props])

    const changeConfiguration =
        <K extends keyof ColorScaleEntry>(property: K, index: number) =>
        (value: ColorScaleEntry[K]) =>
            props.onChange(
                produce(props.value, (draft) => {
                    if (draft) {
                        draft.ranges[index][property] = value
                    }
                })
            )
    const deleteEntry = () =>
        props.onChange(
            produce(props.value, (draft) => {
                if (draft) {
                    draft.ranges.pop()
                }
            })
        )
    const isPercentageOptions: Array<SelectOption> = [
        {
            value: true,
            label: 'Percentage Values',
        },
        {
            value: false,
            label: 'Absolute Values',
        },
    ]
    const changeIsPercentage = (value: boolean) =>
        props.onChange(
            produce(props.value, (draft) => {
                if (draft) {
                    draft.isPercentage = value
                }
            })
        )
    const handleAddEntry = () =>
        props.onChange(
            produce(props.value, (draft) => {
                if (draft) {
                    const prevUpperBound = draft.ranges[draft.ranges.length - 1]?.upperBound
                    const lowerBound = prevUpperBound || 0

                    draft.ranges.push({
                        lowerBound,
                        upperBound: undefined,
                        color: '#f99509',
                    })
                }
            })
        )

    const entries = props.value?.ranges || []

    return (
        <div className="color-scale-editor">
            {!props.configuration?.noPercentage && (
                <Select options={isPercentageOptions} value={props.value?.isPercentage} onChange={changeIsPercentage} />
            )}
            {entries.map((e, index) => (
                <div className="color-scale-editor--entry" key={'color-scale-editor--entry' + index}>
                    from
                    <NumberPicker onChange={changeConfiguration('lowerBound', index)} value={e.lowerBound} />
                    to
                    <NumberPicker
                        onChange={changeConfiguration('upperBound', index)}
                        value={e.upperBound}
                        min={e.lowerBound || undefined}
                        placeholder={'Infinity'}
                    />
                    <TextInput type="color" onChange={changeConfiguration('color', index)} value={e.color} />
                    <Icon onClick={deleteEntry} name="TrashIcon" />
                </div>
            ))}
            <Button onClick={handleAddEntry}>Add Range</Button>
        </div>
    )
}

const defaultColorScale: Array<ColorScaleEntry> = [
    {
        lowerBound: 0,
        upperBound: 50,
        color: '#ff0000',
    },
    {
        lowerBound: 50,
        upperBound: 80,
        color: '#ffA800',
    },
    {
        lowerBound: 80,
        upperBound: 120,
        color: '#008000',
    },
    {
        lowerBound: 120,
        upperBound: 150,
        color: '#ffA800',
    },
    {
        lowerBound: 150,
        upperBound: undefined,
        color: '#ff0000',
    },
]

export default ColorScaleEditor
export { defaultColorScale }
