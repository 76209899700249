import { delay, put, select, takeLatest } from 'redux-saga/effects'
import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions, selectors } from '../Redux'
import axios from './Utility/axios'
import { AccessMode, DateFormats, WorkingDays } from '../Redux/Data/types'

function* uploadLicense(action: PayloadAction) {
    try {
        const response: AxiosResponse = yield axios({
            method: 'POST',
            url: 'admin/license',
            data: action.payload,
        })

        if (response.status < 400) {
            yield put(actions.Data.System.setLicenses(response.data.licenses))
            yield put(actions.Data.UserManagement.setUsersAndGroups(response.data.users))
        } else {
            yield put(actions.UI.Notification.show({ id: 'systemLicenseUploadFailed' }))
        }
    } catch (e) {
        console.error(e)
    }
}

function* uploadLicenseSaga() {
    yield takeLatest(actions.Data.System.uploadLicense.type, uploadLicense)
}

function* setSecureCookie(action: PayloadAction<{ secureCookie: boolean }>) {
    try {
        const { secureCookie } = action.payload
        const response: AxiosResponse = yield axios({
            method: 'POST',
            url: 'admin/session-configuration/set-secure-cookie',
            params: {
                value: secureCookie ? 'true' : 'false',
            },
        })

        if (response.status >= 400) {
            console.error('failed to set secure cookie')
        }
    } catch (e) {
        console.error(e)
    }
}

function* setSecureCookieSaga() {
    yield takeLatest(actions.Data.System.setSecureCookie.type, setSecureCookie)
}

function* setTimeZone(action: PayloadAction<{ timeZone: string }>) {
    try {
        const { timeZone } = action.payload
        const response: AxiosResponse = yield axios({
            method: 'POST',
            url: 'admin/timezone',
            params: {
                value: timeZone,
            },
        })

        if (response.status >= 400) {
            yield put(actions.UI.Notification.show({ id: 'systemTimezoneChangeError' }))
        }
    } catch (e) {
        console.error(e)
    }
}

function* setTimeZoneSaga() {
    yield takeLatest(actions.Data.System.setTimeZone.type, setTimeZone)
}

function* setDateFormat(action: PayloadAction<{ dateFormat: DateFormats }>) {
    try {
        const { dateFormat } = action.payload
        const response: AxiosResponse = yield axios({
            method: 'POST',
            url: 'admin/dateFormat',
            params: {
                value: dateFormat,
            },
        })

        if (response.status >= 400) {
            yield put(actions.UI.Notification.show({ id: 'systemDateFormatChangeError' }))
        }
    } catch (e) {
        console.error(e)
    }
}

function* setDateFormatSaga() {
    yield takeLatest(actions.Data.System.setDateFormat.type, setDateFormat)
}

function* setElasticTokenLengthSaga() {
    yield takeLatest(
        actions.Data.System.setElasticTokenLength.type,
        function* (action: PayloadAction<{ elasticTokenLength: number }>) {
            try {
                const elasticTokenLength = action.payload
                const response: AxiosResponse = yield axios({
                    method: 'POST',
                    url: 'admin/elasticTokenLength',
                    params: {
                        elasticTokenLength,
                    },
                })

                if (response.status >= 400) {
                    yield put(actions.UI.Notification.show({ id: 'systemElasticTokenChangeError' }))
                } else {
                    yield put(actions.UI.Notification.show({ id: 'systemElasticTokenChangeSuccess' }))
                }
            } catch (e) {
                console.error(e)
            }
        }
    )
}

function* setWorkingDays(action: PayloadAction<{ workingDays: WorkingDays }>) {
    try {
        const { workingDays } = action.payload
        const response: AxiosResponse = yield axios({
            method: 'POST',
            url: 'admin/workingDays',
            data: { workingDays },
        })

        if (response.status >= 400) {
            yield put(actions.UI.Notification.show({ id: 'systemWorkingDayChangeError' }))
        }
    } catch (e) {
        console.error(e)
    }
}

function* setWorkingDaysSaga() {
    yield takeLatest(actions.Data.System.setWorkingDays.type, setWorkingDays)
}

export const pingServerAction = createAction('SAGAS/pingServerSaga')

/**
 * this saga is used to frequently ping the server and check if the user is still logged in
 */
function* pingServerSaga() {
    yield takeLatest([actions.System.sagasInit, pingServerAction], function* () {
        try {
            const accessMode = selectors.Data.System.accessMode(yield select())
            if (accessMode !== AccessMode.SHARED_DASHBOARD) {
                do {
                    const response: AxiosResponse = yield axios({
                        method: 'GET',
                        url: 'api/ping',
                    })

                    // in case we are logged out we do not need to ping anymore
                    if (response.status === 401) {
                        return
                    }

                    // check every minute
                    yield delay(1000 * 60)
                } while (true)
            }
        } catch (e) {
            console.error(e)
        }
    })
}

export default [
    uploadLicenseSaga,
    setSecureCookieSaga,
    setTimeZoneSaga,
    setDateFormatSaga,
    pingServerSaga,
    setWorkingDaysSaga,
    setElasticTokenLengthSaga,
]
