import React, { useEffect, useRef } from 'react'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-mysql'
import 'ace-builds/src-noconflict/theme-xcode'
import 'ace-builds/src-noconflict/theme-monokai'
import { Ace } from 'ace-builds'
// for auto-completion
import 'ace-builds/src-noconflict/ext-language_tools'
import { useSelector } from 'react-redux'
import { usesDarkMode } from '../../../Redux/UI/CurrentUser'

export type CodeAceAreaProps = {
    // add imports to add more supported languages
    language?: 'mysql'
    focus?: boolean
    name?: string // unique name of the input
    height?: string
    width?: string
    debounceMs?: number
    placeholder?: string
    value?: string
    onChange: (value: string) => unknown
    completers?: Array<Ace.Completer>
}

// Demo and Configurator at https://github.com/securingsincity/react-ace
// Reference at https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
// Options https://github.com/ajaxorg/ace/wiki/Configuring-Ace
// Modes and Themes https://github.com/ajaxorg/ace-builds
const CodeAceArea: React.FunctionComponent<CodeAceAreaProps> = (props) => {
    const isDarkModeActive = useSelector(usesDarkMode)
    const aceRef = useRef<AceEditor>(null)

    // since we don't want to override the builtin autocompleters, we need to pass in ours after initialization
    useEffect(() => {
        if (aceRef.current && props.completers) {
            aceRef.current.editor.completers = [...aceRef.current.editor.completers, ...props.completers]
        }
    }, [aceRef, props.completers])

    return (
        <div
            className="code-ace-area"
            style={{
                height: props.height,
                width: props.width,
            }}
        >
            <AceEditor
                focus={props.focus}
                height="100%"
                width="100%"
                placeholder={props.placeholder}
                mode={props.language}
                theme={isDarkModeActive ? 'monokai' : 'xcode'}
                name={props.name}
                onChange={props.onChange}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={props.value || ''}
                ref={aceRef}
                debounceChangePeriod={props.debounceMs}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                    // @ts-ignore types are not correct
                    newLineMode: 'unix',
                }}
            />
        </div>
    )
}

CodeAceArea.defaultProps = {
    focus: false,
    name: 'code-ace-area',
    height: '100%',
    width: '100%',
    debounceMs: 300,
}

export default CodeAceArea
