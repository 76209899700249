import uuid from 'uuid'
import { put, select, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { createAction } from '@reduxjs/toolkit'
import { actions, selectors } from '../../Redux'
import { saveDashboard } from './SaveDashboardToServerSaga'
import createDuplicatedDashboard from '../../Redux/Data/Dashboards/DuplicateDashboard'
import { createApiDashboardConfigurationFromNormalizedDashboardConfiguration } from '../../Redux/Data/Dashboards/denormalization'
import { DashboardId } from '../../Redux/Data/types'
import { ALL_DASHBOARDS } from '../../Redux/UI/DashboardGroups'
import { DashboardGroupId } from '../../Redux/UI/types'

export const duplicateDashboard = createAction(
    'Data/Dashboards/DashboardEditMode/DuplicateDashboard',
    (dashboardId: DashboardId, dashboardName: string, navigate: boolean, dashboardGroup?: DashboardGroupId) => ({
        payload: { dashboardId, dashboardName, navigate, dashboardGroup },
    })
)

export default function* DuplicateDashboardSaga() {
    yield takeLatest(duplicateDashboard, function* (action) {
        const dashboardId = action.payload.dashboardId
        const newDashboardId = uuid()
        const newDashboardName = action.payload.dashboardName
        const goToNewDashboard = action.payload.navigate
        const normalizedDashboardConfig = selectors.Data.Dashboards.normalizedDashboardConfig(yield select())

        // create duplicate
        const duplicatedDashboard = createDuplicatedDashboard(
            normalizedDashboardConfig,
            dashboardId,
            newDashboardId,
            newDashboardName
        )

        // save to store
        yield put(actions.Data.Dashboards.DuplicateDashboard.storeDuplicatedDashboard(duplicatedDashboard))

        // save to server
        try {
            const newDashboardAsApiConfig = createApiDashboardConfigurationFromNormalizedDashboardConfiguration(
                yield select(),
                newDashboardId
            )
            const saveDashboardWasSuccessful: boolean = yield saveDashboard(newDashboardAsApiConfig)

            // add new Dashboard to favourites if original Dashboard is in favourites
            if (saveDashboardWasSuccessful) {
                const favoriteDashboards = selectors.UI.FavouriteDashboards.list(yield select())
                if (favoriteDashboards.includes(dashboardId)) {
                    yield put(actions.UI.FavouriteDashboards.add(newDashboardId))
                }

                if (action.payload.dashboardGroup) {
                    yield put(
                        actions.UI.DashboardGroups.moveDashboardFromGroupToGroup({
                            sourceGroup: ALL_DASHBOARDS,
                            destinationGroup: action.payload.dashboardGroup,
                            dashboardId: newDashboardId,
                        })
                    )
                }

                // open new dashboard
                if (goToNewDashboard) {
                    yield put(push(`/dashboard/${newDashboardId}`))
                }
            }
        } catch (e) {
            console.error(e)
            yield put(actions.UI.Notification.show({ id: 'dashboardDuplicateFailed' }))
        }
    })
}
