import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'
// eslint-disable-next-line
import SqlTableWidgetIcon from './SqlTableWidget.Icon'
import SqlTableWidgetInit from './SqlTableWidget.init'
import SqlTableWidgetConfigurationInitializer from './SqlTableWidget.configurationInitializer'
import LazySqlTableWidget from './LazySqlTableWidget'
import SqlTableWidgetQueryEditor from './SqlTableWidgetQueryEditor'

const config: WidgetManifestConfiguration = {
    name: 'SQL Table',
    component: LazySqlTableWidget,
    icon: SqlTableWidgetIcon,
    stateInitializationFunction: SqlTableWidgetInit,
    configurationInitializer: SqlTableWidgetConfigurationInitializer,
    editors: {
        data: [
            {
                widgetConfigurationProperty: 'query',
                component: SqlTableWidgetQueryEditor,
            },
        ],
        bottomTabs: [],
    },
    requiredPermissions: ['isAdmin'],
}

export default config
