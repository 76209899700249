// see ApplicationMode.groovy
const ApplicationMode = {
    Development: 'Development',
    JIRACloud: 'JIRACloud',
    OnPremise: 'OnPremise',
}

export type ApplicationModeType = keyof typeof ApplicationMode

export default ApplicationMode
