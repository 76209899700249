import React, { PureComponent } from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import moment from 'moment'
import DateSelector from '../DateSelector/DateSelector'
import { DateRangeObject } from '../DateSelector/DateSelector.types'
import dateRanges from '../../../StaticManifests/manifest.dateRanges'
import { TimeSelectorWidgetConfiguration } from '../Widgets/TimeSelectorWidget/TimeSelectorWidget'

type DateSelectorEditorProps = {
    onChange: (value: {
        selectedPredefinedTimespan: string | undefined
        startDate: number | null
        endDate: number | null
    }) => void
    // currently only used for time selector
    widgetConfiguration: TimeSelectorWidgetConfiguration
}

/**
 * Wraps a date selector for usage as an editor for widget configuration in our manifest. Currently
 * only used for the TimeSelector.
 */
class DateSelectorEditor extends PureComponent<DateSelectorEditorProps, unknown> {
    onDateChange = (data: DateRangeObject) => {
        this.props.onChange({
            startDate: data.startDate ? data.startDate.valueOf() : null,
            endDate: data.endDate ? data.endDate.valueOf() : null,
            selectedPredefinedTimespan: data.selectedPredefinedTimespan,
        })
    }

    render() {
        let startDate
        let endDate
        const timespan = this.props.widgetConfiguration.defaultTimespan
        const selectedPredefinedTimespan = timespan.selectedPredefinedTimespan
        if (selectedPredefinedTimespan) {
            const dateRange = dateRanges[selectedPredefinedTimespan].getDateRange({
                workingDays: this.props.widgetConfiguration.workingDays,
                showLastXDays: this.props.widgetConfiguration.showLastXDays,
            })

            startDate = dateRange.startDate
            endDate = dateRange.endDate
        } else {
            startDate = moment(timespan.startDate)
            endDate = moment(timespan.endDate)
        }

        return (
            <div className="date-selector-editor">
                <DateSelector
                    availablePredefinedTimespans={this.props.widgetConfiguration.availablePredefinedTimespans}
                    onDateChange={this.onDateChange}
                    dateRangeValue={selectedPredefinedTimespan}
                    startDate={startDate}
                    endDate={endDate}
                />
            </div>
        )
    }
}

export default enhanceWithClickOutside(DateSelectorEditor)
