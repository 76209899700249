import React from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from '../../../../Redux'
import Widget from '../../Widget/Widget'
import { RootState } from '../../../../Redux/types'
import { AppDispatch } from '../../../../store'

const mapStateToProps = (state: RootState) => ({
    currentlyEditedWidgetId: selectors.Data.Dashboards.WidgetEditMode.currentlyEditedWidgetId(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onChangeWidgetTitle: (newTitle: string) =>
        dispatch(actions.Data.Dashboards.WidgetEditMode.changeWidgetTitle({ newTitle })),
})

type WidgetPreviewProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const WidgetPreview: React.FC<WidgetPreviewProps> = (props) => {
    const widgetId = props.currentlyEditedWidgetId
    if (widgetId === undefined) {
        return null
    }

    return (
        <div className="widget-preview">
            <Widget
                widgetId={widgetId}
                isPreview={true}
                hideEditModeControls={true}
                onChangeTitle={props.onChangeWidgetTitle}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetPreview)
