import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CurrentModal, UiState } from './types'
import { RootState } from '../types'
// eslint-disable-next-line import/extensions
import { ModalId } from '../../StaticManifests/manifest.modals'

const modalSlice = createSlice({
    name: 'UI/Modal',
    initialState: {
        currentModal: null,
    } as UiState,
    reducers: {
        show: {
            prepare: (id: ModalId, parameters?: any) => ({
                payload: {
                    id,
                    parameters,
                },
            }),
            reducer: (state, action: PayloadAction<{ id: ModalId; parameters: any }>) => {
                state.currentModal = {
                    modalId: action.payload.id,
                    parameters: action.payload.parameters,
                }
            },
        },
        hide: (state) => {
            state.currentModal = null
        },
        startConfirmation: (state, action: PayloadAction<CurrentModal>) => {
            state.currentModal = action.payload
        },
    },
})

export const actions = modalSlice.actions
export const reducer = modalSlice.reducer

export const selectors = {
    currentModal: (state: RootState) => state.UI.currentModal,
}
