import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import TextInput from '../../Atoms/TextInput/TextInput'
import Icon from '../../Atoms/Icon/Icon'
import { AppDispatch } from '../../../store'
import { DashboardOverviewMode } from '../../../Redux/UI/CurrentUser'
import { actions, selectors } from '../../../Redux'
import { RootState } from '../../../Redux/types'
import Button from '../../Atoms/Button/Button'

type Props = {
    titleFilter: string
    handleClearFilter: () => void
    setTitleFilter: (filter: string) => void
} & ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: RootState) => ({
    dashboardOverviewMode: selectors.UI.CurrentUser.dashboardOverviewMode(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleChangeDashboardViewMode: (viewMode: DashboardOverviewMode) => () =>
        dispatch(actions.UI.CurrentUser.setDashboardOverviewMode(viewMode)),
    addDashboardGroup: () => dispatch(actions.UI.DashboardGroups.createDashboardGroup()),
})

const DashboardOverviewHeader: React.FC<Props> = (props) => {
    const [scrollTop, setScrollTop] = useState(0)
    const handleScroll = () => setScrollTop(window.pageYOffset)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const className = classnames('dashboard-overview__header', {
        'dashboard-overview__header--fixed': scrollTop > 180, // set fixed as soon as weve scrolled past the header
    })

    return (
        <div className={className}>
            <TextInput
                className="dashboard-filter"
                value={props.titleFilter}
                onChange={props.setTitleFilter}
                onClear={props.handleClearFilter}
                clearable
                placeholder="Filter dashboards by title"
            />
            <Icon
                name="TilesIcon"
                onClick={props.handleChangeDashboardViewMode('tile')}
                className="dashboard-overview__list-toggle"
                position="left"
                color={props.dashboardOverviewMode === 'tile' ? 'primary' : undefined}
                title="Tile View"
            />
            <Icon
                name="ListIcon"
                onClick={props.handleChangeDashboardViewMode('list')}
                className="dashboard-overview__list-toggle"
                position="left"
                color={props.dashboardOverviewMode === 'tile' ? undefined : 'primary'}
                title="List View"
            />
            <Button onClick={props.addDashboardGroup} className="add-dashboard-group-button">
                Add Dashboard Group
            </Button>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardOverviewHeader)
