import React from 'react'
import moment from 'moment'
import produce from 'immer'
import DateSelector from '../../../Organisms/DateSelector/DateSelector'
import Icon from '../../../Atoms/Icon/Icon'
import NumberPicker from '../../../Atoms/NumberPicker/NumberPicker'
import Button from '../../../Atoms/Button/Button'
import { DateRangeObject } from '../../../Organisms/DateSelector/DateSelector.types'

type Allocation = {
    start: string | undefined // "YYYY/MM/dd",
    end: string | undefined // "YYYY/MM/dd",
    capacity: number
}

type Value = {
    allocations: Array<Allocation>
}

const QuotaEditor: React.FunctionComponent<{
    value: Value
    onChange: (value: Value) => void
}> = (props) => {
    const handleAdditionalFormFieldAdd = () => {
        const { value, onChange } = props
        let allocations = value.allocations

        const startDate = moment().startOf('month')
        const endDate = moment().add(1, 'month').startOf('month')

        allocations = [
            ...allocations,
            {
                start: startDate.format('YYYY/MM/DD'),
                end: endDate.format('YYYY/MM/DD'),
                capacity: 0,
            },
        ]

        onChange(
            produce(value, (draft) => {
                draft.allocations = allocations
            })
        )
    }

    const handleRepeatClick = () => {
        let allocations = props.value.allocations

        if (allocations.length === 0) {
            return
        }

        const lastAllocation = allocations[allocations.length - 1]

        allocations = [...allocations, calculateNextQuota({ ...lastAllocation })]

        props.onChange(
            produce(props.value, (draft) => {
                draft.allocations = allocations
            })
        )
    }

    const handleDateChange = (index: number) => {
        return ({ startDate, endDate }: DateRangeObject) => {
            props.onChange(
                produce(props.value, (draft) => {
                    draft.allocations[index].start = startDate ? startDate.format('YYYY/MM/DD') : undefined
                    draft.allocations[index].end = endDate ? endDate.format('YYYY/MM/DD') : undefined
                })
            )
        }
    }

    const handleCapacityChange = (index: number) => {
        return (newCapacity: number | null) => {
            props.onChange(
                produce(props.value, (draft) => {
                    draft.allocations[index].capacity = newCapacity || 0
                })
            )
        }
    }

    const handleRemoveLine = (index: number) => {
        return () => {
            props.onChange(
                produce(props.value, (draft) => {
                    draft.allocations.splice(index, 1)
                })
            )
        }
    }

    const allocations = props.value.allocations

    return (
        <div className="quota-editor">
            {allocations.map((value: Allocation, i: number) => {
                const startDate = moment(value.start, 'YYYY/MM/DD')
                const endDate = moment(value.end, 'YYYY/MM/DD')
                const capacity = value.capacity
                return (
                    <div key={i} className="quota-editor__row">
                        <DateSelector
                            onDateChange={handleDateChange(i)}
                            startDate={startDate}
                            endDate={endDate ? endDate.subtract(1, 'milliseconds') : endDate}
                            showPredefinedTimeSpan={false}
                        />
                        <NumberPicker
                            value={capacity}
                            min={0}
                            max={100000000}
                            step={1}
                            onChange={handleCapacityChange(i)}
                            useSelectHeight
                        />
                        <Icon name="TrashIcon" onClick={handleRemoveLine(i)} />
                    </div>
                )
            })}
            <div className="quota-editor__buttons">
                {allocations.length > 0 && <Button onClick={handleRepeatClick}>Continue</Button>}
                <Button onClick={handleAdditionalFormFieldAdd} type="primary">
                    Add time span & resources
                </Button>
            </div>
        </div>
    )
}

export default QuotaEditor

const format = 'YYYY/MM/DD'

/**
 * Always be aware of the fact that we exclude the end dates every time.
 * So the month ranges from 2019/07/01 to 2019/08/01
 *
 * PLEASE add tests when you fix this function
 */
export function calculateNextQuota({ start, end, capacity }: Allocation) {
    // our internal date string format is deprecated in javascript, so we
    // have to transform our strings to a format understood by javascript
    const startString = start?.replaceAll('/', '-')
    const endString = end?.replaceAll('/', '-')

    const endMoment = moment(endString)

    const diffDays = endMoment.diff(startString, 'days')
    const diffWeeks = endMoment.diff(startString, 'weeks')
    const diffMonths = endMoment.diff(startString, 'months')

    let diff: number
    let diffUnit: 'months' | 'weeks' | 'days'

    if (diffMonths > 0) {
        diff = diffMonths
        diffUnit = 'months'
    } else if (diffWeeks > 0 && diffDays % 7 === 0) {
        diff = diffWeeks
        diffUnit = 'weeks'
    } else {
        diff = diffDays
        diffUnit = 'days'
    }

    // the next start date is always the last end date
    const nextStart = endMoment.clone()
    const nextEnd = endMoment.clone().add(diff, diffUnit)

    return {
        start: nextStart.format(format),
        end: nextEnd.format(format),
        capacity,
    }
}
