import { getDefaultAnalysisTermWithField } from '../DefaultConfigurations'
import { QuotaTileType } from './QuotaTileWidget.manifest'
import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

const createDefaultConfiguration = (type: QuotaTileType) => () => ({
    valueField: {
        analysisTerm: getDefaultAnalysisTermWithField('sumTime'),
        filter:
            type === 'quotaTile'
                ? []
                : ([{ field: 'projectName', operator: 'equals', values: [] }] as Array<FieldType>),
        dateField: 'primaryDate',
    },
    unit: '',
    color: null,
    quota:
        type === 'quotaTile'
            ? {
                  allocations: [],
              }
            : { numberOfSprints: 5, capacityField: 'sprintOriginalEstimate' },
    numberOfDecimals: 2,
    title: 'Quota Tile',
})

export default createDefaultConfiguration

export const QuotaTileConfigurationInitializerForWidgetExplorer =
    (type: QuotaTileType) =>
    ({
        valueFields,
        dateFields,
        filters,
    }: {
        valueFields: Array<string>
        dateFields: Array<string>
        filters: Array<FieldType>
    }) => {
        const defaultConfiguration = createDefaultConfiguration(type)()

        defaultConfiguration.valueField = {
            analysisTerm: getDefaultAnalysisTermWithField(valueFields[0]),
            filter: filters,
            dateField: dateFields[0],
        }

        return defaultConfiguration
    }
