import React, { CSSProperties } from 'react'
import classnames from 'classnames'

import { ReactComponent as AnalyticsIcon } from '../../../icons/icon-analytics.svg'
import { ReactComponent as AngleIcon } from '../../../icons/icon-angle.svg'
import { ReactComponent as ArrowBoldIcon } from '../../../icons/icon-arrow-bold.svg'
import { ReactComponent as ArrowIcon } from '../../../icons/icon-arrow.svg'
import { ReactComponent as AttentionIcon } from '../../../icons/icon-attention.svg'
import { ReactComponent as BinocularsIcon } from '../../../icons/icon-binoculars.svg'
import { ReactComponent as CabinetIcon } from '../../../icons/icon-cabinet.svg'
import { ReactComponent as CalculatorIcon } from '../../../icons/icon-calculator.svg'
import { ReactComponent as CalendarIcon } from '../../../icons/icon-calendar.svg'
import { ReactComponent as CheckIcon } from '../../../icons/icon-check.svg'
import { ReactComponent as CheckmarkIcon } from '../../../icons/icon-checkmark.svg'
import { ReactComponent as ChevronIcon } from '../../../icons/icon-chevron.svg'
import { ReactComponent as ClipboardIcon } from '../../../icons/icon-clipboard.svg'
import { ReactComponent as CodeIcon } from '../../../icons/icon-code.svg'
import { ReactComponent as CogIcon } from '../../../icons/icon-cog.svg'
import { ReactComponent as ColorPaletteIcon } from '../../../icons/icon-color-palette.svg'
import { ReactComponent as CommentsIcon } from '../../../icons/icon-comments.svg'
import { ReactComponent as CompassIcon } from '../../../icons/icon-compass.svg'
import { ReactComponent as ConnectIcon } from '../../../icons/icon-connect.svg'
import { ReactComponent as ContextIcon } from '../../../icons/icon-context.svg'
import { ReactComponent as CrownIcon } from '../../../icons/icon-crown.svg'
import { ReactComponent as CsvIcon } from '../../../icons/icon-csv.svg'
import { ReactComponent as DashboardIcon } from '../../../icons/icon-dashboard.svg'
import { ReactComponent as DatabaseIcon } from '../../../icons/icon-database.svg'
import { ReactComponent as DownloadIcon } from '../../../icons/icon-download.svg'
import { ReactComponent as ExternalLinkIcon } from '../../../icons/icon-external-link.svg'
import { ReactComponent as FeedbackIcon } from '../../../icons/icon-feedback.svg'
import { ReactComponent as FilterIcon } from '../../../icons/icon-filter.svg'
import { ReactComponent as FunctionIcon } from '../../../icons/icon-function.svg'
import { ReactComponent as FurtherValuesRowsIcon } from '../../../icons/icon-further-values-rows.svg'
import { ReactComponent as FurtherValuesIcon } from '../../../icons/icon-further-values.svg'
import { ReactComponent as GitIcon } from '../../../icons/icon-git.svg'
import { ReactComponent as GripIcon } from '../../../icons/icon-grip.svg'
import { ReactComponent as GroupExpandedIcon } from '../../../icons/icon-group-expanded.svg'
import { ReactComponent as HeartFilledIcon } from '../../../icons/icon-heart-filled.svg'
import { ReactComponent as HeartIcon } from '../../../icons/icon-heart.svg'
import { ReactComponent as HideNonWorkingDaysIcon } from '../../../icons/icon-hide-non-working-days.svg'
import { ReactComponent as ImageIcon } from '../../../icons/icon-image.svg'
import { ReactComponent as ImportScheduleIcon } from '../../../icons/icon-import-schedule.svg'
import { ReactComponent as ImportTimeIcon } from '../../../icons/icon-import-time.svg'
import { ReactComponent as InterpolateMissingValuesAreaIcon } from '../../../icons/icon-interpolate-missing-values-area.svg'
import { ReactComponent as InterpolateMissingValuesLineIcon } from '../../../icons/icon-interpolate-missing-values-line.svg'
import { ReactComponent as JiraAlertIcon } from '../../../icons/icon-jira-alert.svg'
import { ReactComponent as JiraSuccessIcon } from '../../../icons/icon-jira-success.svg'
import { ReactComponent as JiraTodoIcon } from '../../../icons/icon-jira-todo.svg'
import { ReactComponent as LabelSpacingIcon } from '../../../icons/icon-labelSpacing.svg'
import { ReactComponent as LayerPlusIcon } from '../../../icons/icon-layer-plus.svg'
import { ReactComponent as LeadingPrimaryValueIcon } from '../../../icons/icon-leading-primary-value.svg'
import { ReactComponent as LegendIcon } from '../../../icons/icon-legend.svg'
import { ReactComponent as LightbulbIcon } from '../../../icons/icon-lightbulb.svg'
import { ReactComponent as LineDrawingStyleIcon } from '../../../icons/icon-line-drawing-style.svg'
import { ReactComponent as ListIcon } from '../../../icons/icon-list.svg'
import { ReactComponent as MainMenuConfigurationSharingIcon } from '../../../icons/icon-main-menu-configuration-sharing.svg'
import { ReactComponent as MainMenuDashboardsIcon } from '../../../icons/icon-main-menu-dashboards.svg'
import { ReactComponent as MainMenuDataSourcesIcon } from '../../../icons/icon-main-menu-data-sources.svg'
import { ReactComponent as MainMenuFieldsCustomizationIcon } from '../../../icons/icon-main-menu-fields-customization.svg'
import { ReactComponent as MainMenuSystemInformationIcon } from '../../../icons/icon-main-menu-system-information.svg'
import { ReactComponent as MainMenuUserManagementIcon } from '../../../icons/icon-main-menu-user-management.svg'
import { ReactComponent as MaxColsIcon } from '../../../icons/icon-max-cols.svg'
import { ReactComponent as MaxRowsIcon } from '../../../icons/icon-max-rows.svg'
import { ReactComponent as MaxXAxisValuesIcon } from '../../../icons/icon-max-x-axis-values.svg'
import { ReactComponent as MaxYAxisValuesIcon } from '../../../icons/icon-max-y-axis-values.svg'
import { ReactComponent as NumBarsIcon } from '../../../icons/icon-num-bars.svg'
import { ReactComponent as NumDecimalsIcon } from '../../../icons/icon-num-decimals.svg'
import { ReactComponent as NumStacksIcon } from '../../../icons/icon-num-stacks.svg'
import { ReactComponent as PaperPlaneIcon } from '../../../icons/icon-paper-plane.svg'
import { ReactComponent as PenIcon } from '../../../icons/icon-pen.svg'
import { ReactComponent as PercentagesIcon } from '../../../icons/icon-percentages.svg'
import { ReactComponent as PlusIcon } from '../../../icons/icon-plus.svg'
import { ReactComponent as QuestionIcon } from '../../../icons/icon-question.svg'
import { ReactComponent as SeparateStackingBarsIcon } from '../../../icons/icon-separate-stacking-bars.svg'
import { ReactComponent as ShareIcon } from '../../../icons/icon-share.svg'
import { ReactComponent as ShortLabelsIcon } from '../../../icons/icon-short-labels.svg'
import { ReactComponent as ShowColSumIcon } from '../../../icons/icon-show-col-sum.svg'
import { ReactComponent as ShowGridLinesIcon } from '../../../icons/icon-show-grid-lines.svg'
import { ReactComponent as ShowRowSumIcon } from '../../../icons/icon-show-row-sum.svg'
import { ReactComponent as ShowValueDotsAreaIcon } from '../../../icons/icon-show-value-dots-area.svg'
import { ReactComponent as ShowValueDotsLineIcon } from '../../../icons/icon-show-value-dots-line.svg'
import { ReactComponent as SignOutIcon } from '../../../icons/icon-sign-out.svg'
import { ReactComponent as SortIcon } from '../../../icons/icon-sort.svg'
import { ReactComponent as SortingIcon } from '../../../icons/icon-sorting.svg'
import { ReactComponent as StackUpMultipleValuesIcon } from '../../../icons/icon-stack-multiple-values.svg'
import { ReactComponent as SumUpAllQuotasIcon } from '../../../icons/icon-sum-up-all-quotas.svg'
import { ReactComponent as SyncIcon } from '../../../icons/icon-sync.svg'
import { ReactComponent as TilesIcon } from '../../../icons/icon-tiles.svg'
import { ReactComponent as TimesIcon } from '../../../icons/icon-times.svg'
import { ReactComponent as TopFlopIcon } from '../../../icons/icon-top-flop.svg'
import { ReactComponent as TrashIcon } from '../../../icons/icon-trash.svg'
import { ReactComponent as TrendLineAreaIcon } from '../../../icons/icon-trend-line-area.svg'
import { ReactComponent as TrendLineBarIcon } from '../../../icons/icon-trend-line-bar.svg'
import { ReactComponent as TrendLineLineIcon } from '../../../icons/icon-trend-line-line.svg'
import { ReactComponent as UndoIcon } from '../../../icons/icon-undo.svg'
import { ReactComponent as UploadIcon } from '../../../icons/icon-upload.svg'
import { ReactComponent as UserLightIcon } from '../../../icons/icon-user-light.svg'
import { ReactComponent as UserSolidIcon } from '../../../icons/icon-user-solid.svg'
import { ReactComponent as UsersLightIcon } from '../../../icons/icon-users-light.svg'
import { ReactComponent as UsersSolidIcon } from '../../../icons/icon-users-solid.svg'
import { ReactComponent as AxisPositionRightIcon } from '../../../icons/icon-axis-position-right.svg'
import { ReactComponent as AxisPositionLeftIcon } from '../../../icons/icon-axis-position-left.svg'
import { ReactComponent as TimeBarChartIcon } from '../../../icons/icon-time-bar-chart.svg'
import { ReactComponent as LineDrawingStepsIcon } from '../../../icons/icon-line-drawing-style-steps.svg'
import { ReactComponent as LineDrawingSmoothIcon } from '../../../icons/icon-line-drawing-style-smooth.svg'
import { ReactComponent as LineDrawingLinearIcon } from '../../../icons/icon-line-drawing-style-linear.svg'
import { ReactComponent as AreaDrawingStepsIcon } from '../../../icons/icon-area-drawing-style-steps.svg'
import { ReactComponent as AreaDrawingSmoothIcon } from '../../../icons/icon-area-drawing-style-smooth.svg'
import { ReactComponent as AreaDrawingLinearIcon } from '../../../icons/icon-area-drawing-style-linear.svg'
import { ReactComponent as ProjectsIcon } from '../../../icons/icon-projects.svg'
import { ReactComponent as LinkIcon } from '../../../icons/icon-link.svg'
import { ReactComponent as LockedIcon } from '../../../icons/icon-locked.svg'
import { ReactComponent as UnlockedIcon } from '../../../icons/icon-unlocked.svg'
import { ReactComponent as AxisTopIcon } from '../../../icons/icon-axis-top.svg'
import { ReactComponent as AxisLeftIcon } from '../../../icons/icon-axis-left.svg'

export const IconComponents = {
    AnalyticsIcon,
    AngleIcon,
    ArrowBoldIcon,
    ArrowIcon,
    AttentionIcon,
    BinocularsIcon,
    CabinetIcon,
    CalculatorIcon,
    CalendarIcon,
    CheckIcon,
    CheckmarkIcon,
    ChevronIcon,
    ClipboardIcon,
    CodeIcon,
    CogIcon,
    ColorPaletteIcon,
    CommentsIcon,
    CompassIcon,
    ConnectIcon,
    ContextIcon,
    CrownIcon,
    CsvIcon,
    DashboardIcon,
    DatabaseIcon,
    DownloadIcon,
    ExternalLinkIcon,
    FeedbackIcon,
    FilterIcon,
    FunctionIcon,
    FurtherValuesRowsIcon,
    FurtherValuesIcon,
    GitIcon,
    GripIcon,
    GroupExpandedIcon,
    HeartFilledIcon,
    HeartIcon,
    HideNonWorkingDaysIcon,
    ImageIcon,
    ImportScheduleIcon,
    ImportTimeIcon,
    InterpolateMissingValuesAreaIcon,
    InterpolateMissingValuesLineIcon,
    JiraAlertIcon,
    JiraSuccessIcon,
    JiraTodoIcon,
    LabelSpacingIcon,
    LayerPlusIcon,
    LeadingPrimaryValueIcon,
    LegendIcon,
    LightbulbIcon,
    LineDrawingStyleIcon,
    ListIcon,
    MainMenuConfigurationSharingIcon,
    MainMenuDashboardsIcon,
    MainMenuDataSourcesIcon,
    MainMenuFieldsCustomizationIcon,
    MainMenuSystemInformationIcon,
    MainMenuUserManagementIcon,
    MaxColsIcon,
    MaxRowsIcon,
    MaxXAxisValuesIcon,
    MaxYAxisValuesIcon,
    NumBarsIcon,
    NumDecimalsIcon,
    NumStacksIcon,
    PaperPlaneIcon,
    PenIcon,
    PercentagesIcon,
    PlusIcon,
    QuestionIcon,
    SeparateStackingBarsIcon,
    ShareIcon,
    ShortLabelsIcon,
    ShowColSumIcon,
    ShowGridLinesIcon,
    ShowRowSumIcon,
    ShowValueDotsAreaIcon,
    ShowValueDotsLineIcon,
    SignOutIcon,
    SortIcon,
    SortingIcon,
    StackUpMultipleValuesIcon,
    SumUpAllQuotasIcon,
    SyncIcon,
    TilesIcon,
    TimesIcon,
    TopFlopIcon,
    TrashIcon,
    TrendLineAreaIcon,
    TrendLineBarIcon,
    TrendLineLineIcon,
    UndoIcon,
    UploadIcon,
    UserLightIcon,
    UserSolidIcon,
    UsersLightIcon,
    UsersSolidIcon,
    AxisPositionLeftIcon,
    AxisPositionRightIcon,
    TimeBarChartIcon,
    LineDrawingLinearIcon,
    LineDrawingSmoothIcon,
    LineDrawingStepsIcon,
    AreaDrawingLinearIcon,
    AreaDrawingSmoothIcon,
    AreaDrawingStepsIcon,
    ProjectsIcon,
    LinkIcon,
    LockedIcon,
    UnlockedIcon,
    AxisLeftIcon,
    AxisTopIcon,
}

export type IconNames = keyof typeof IconComponents

export type IconColor = 'light' | 'white' | 'primary' | 'error' | 'green' | 'blue'

type IconSize = 'small' | 'xs' | 'sm' | 'lg' | 'xl'

type LabelPosition = 'left' | 'right'

const Icon: React.FunctionComponent<{
    name: IconNames
    className?: string
    onClick?: (event: React.MouseEvent) => void
    color?: IconColor
    size?: IconSize
    rotation?: number
    position?: string
    title?: string
    label?: string
    labelPosition?: LabelPosition
    useOwnColors?: boolean
    useGrayscale?: boolean
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    isDisabled?: boolean
}> = (props) => {
    const className = classnames('icon', props.className, {
        'icon--black': !props.useOwnColors,
        ['icon--' + props.color]: props.color,
        ['icon--' + props.position]: props.position,
        ['icon--' + props.size]: props.size,
        'icon--is-button': props.onClick,
        'icon--grayscale': props.useGrayscale,
        'icon--is-disabled': props.isDisabled,
    })

    const IconComponent = IconComponents[props.name] || IconComponents.QuestionIcon
    const labelClassName = classnames('icon--with-label', {
        ['icon--with-label-' + props.labelPosition]: props.labelPosition,
        'icon--with-label--is-disabled': props.isDisabled,
    })

    const style: CSSProperties = props.rotation ? { transform: `rotate(${props.rotation}deg)` } : {}

    return props.label ? (
        <div className={labelClassName} onClick={!props.isDisabled ? props.onClick : () => {}} role="button">
            <div
                title={props.title}
                className={className}
                role="button"
                style={style}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
            >
                <IconComponent />
            </div>
            <label>{props.label}</label>
        </div>
    ) : (
        <div
            title={props.title}
            onClick={!props.isDisabled ? props.onClick : () => {}}
            className={className}
            role="button"
            style={style}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            <IconComponent />
        </div>
    )
}

export default Icon
