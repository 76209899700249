import { defaultAnalysisTerm } from '../DefaultConfigurations'

export default function () {
    return {
        title: 'Line Chart',
        pivotField: 'projectName',
        stackingField: null as string | null,
        valueFields: [
            {
                dateField: 'primaryDate',
                analysisTerm: defaultAnalysisTerm,
            },
        ],
        maximumNumberOfBars: 12,
        maximumNumberOfStacks: 12,
        numberOfDecimals: 2,
        useShortLabels: true,
        orientationIsVertical: false,
        sortAsc: false,
        sortKey: 'valueSortKey',
        topOrFlop: 'desc',
        showLegend: false,
        referenceLines: [],
        showFurtherValues: true,
        showPercentages: false,
        percentageOf: 'valueField',
        stackUpValues: false,
        normaliseStackedBars: false,
        percentageOfStackPer: 'valueFieldTotal',
        yAxisLabelWidth: 70,
    }
}
