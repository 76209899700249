import React from 'react'
import Button from '../../Atoms/Button/Button'
import Icon from '../../Atoms/Icon/Icon'
import FieldFilterInput, { FieldKeys, OnTriggerAutoCompletion } from '../../Atoms/FieldFilterInput/FieldFilterInput'
import { FieldType } from '../../Atoms/FieldFilterInput/FieldFilterManifest'

const FieldFilterEditor: React.FunctionComponent<{
    className?: string
    fields: Array<FieldType>
    hasPermissionFilters?: boolean
    onTriggerAutoCompletion: OnTriggerAutoCompletion
    onFilterAdded: (filter: FieldType) => void
    onFilterChanged: (index: number, field: FieldKeys, newValue: any) => void
    onFilterRemoved: (index: number) => void
    noAddFilterButton?: boolean
}> = (props) => {
    const fields = props.fields || []

    const handleFilterAdded = () => {
        props.onFilterAdded({
            field: null,
            operator: 'equals',
            values: [],
        })
    }

    return (
        <div className={props.className}>
            {fields.map((field, i) => (
                <FieldFilterInput
                    key={i}
                    index={i}
                    field={field}
                    hasPermissionFilters={props.hasPermissionFilters}
                    onTriggerAutoCompletion={props.onTriggerAutoCompletion}
                    onFilterChanged={props.onFilterChanged}
                    onFilterRemoved={props.onFilterRemoved}
                    isDocumentFieldClearable={false}
                />
            ))}

            {props.noAddFilterButton ? null : (
                <div className="filter-group">
                    <Button onClick={handleFilterAdded}>
                        <Icon name="FilterIcon" color="green" label="Add new filter" size="small" />
                    </Button>
                </div>
            )}
        </div>
    )
}

export default FieldFilterEditor
