import React, { useContext, useState } from 'react'

import { Bar, BarChart, Cell, Legend, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts'
import classnames from 'classnames'
import BarLabel from './RowChartBarLabel'
import CustomTooltip from '../ToolTip/CustomTooltip'
import ResponsiveTestingAwareChartContainer, {
    chartWidthAndHeightForTesting,
} from '../../../Atoms/ResponsiveTestingAwareChartContainer'

import { COLORS, FURTHER_VALUES, SUM } from '../Constants'
import { getCtrlKeyPressed } from '../../../../Utility/KeyPress'

import CustomizedTick from '../CustomizedTick'
import { CustomTickForHorizontal, CustomTickForVertical } from './CustomTicks'
import { RowChartSelection, RowChartWidgetProps, Stack, Stacks } from './RowChartWidget.types'
import { ReferenceLineEntry } from '../../../Molecules/ReferenceLineEditor/ReferenceLineEditor'
import CustomizedReferenceLineLabel from '../CustomizedReferenceLineLabel'
import { Item, PayloadEntry } from '../ToolTip/CustomTooltip.types'
import { calculateTotal, formatNumberToString } from '../../../../Utility/NumberFormator'
import { getModeOfAnalysisTerm, ValueField } from '../WidgetModel'
import { FieldsConfigurationContext } from '../../../Atoms/FieldsConfiguration/FieldsConfigurationContext'
import CustomizedLabel from '../CustomizedLabel'
import { FieldConfiguration } from '../../../Atoms/FieldsConfiguration/FieldsConfigurationTypes'

const RowChartWidgetChart: React.FC<RowChartWidgetProps> = (props) => {
    const context = useContext(FieldsConfigurationContext)
    const [hoveredBarIndex, setHoveredBarIndex] = useState<number | null>(null)
    const chartData = props.widgetData.chartData
    const isRowChart = !props.widgetConfiguration.orientationIsVertical && props.widgetType !== 'barChart'

    const stacks = props.widgetData.stacks

    const referenceLines = props.widgetConfiguration.referenceLines

    const decimals = props.widgetConfiguration.numberOfDecimals
    const normaliseBars = props.widgetConfiguration.normaliseStackedBars
    const showStackingFieldsAsSeparateBars = props.widgetConfiguration.showStackingFieldsAsSeparateBars
    const stackUpValues = props.widgetConfiguration.stackUpValues && props.widgetConfiguration.valueFields.length > 1
    const hasCategoryStackingField = props.widgetConfiguration.stackingField
    const selection = props.widgetState.selection || []
    const hasSelection = selection.length > 0
    const margin = isRowChart
        ? {
              top: 18,
              right: 18,
              left: stacks.length < 2 ? -15 : 42,
              bottom: 9,
          }
        : {
              top: 36,
              right: 18,
              left: 0,
              bottom: 30,
          }

    const groupLabelFn = (stackId: string | number) => {
        const stack = stacks.filter((s) => s.stackId === stackId)
        return stack.length > 0 ? stack[0].label : stackId.toString()
    }

    const groupTotalFn = (
        groupIndex: any,
        entries: PayloadEntry[],
        decimals: number,
        unit: string,
        groupLabel: string | null
    ): string => {
        const valueField: ValueField | undefined = props.widgetConfiguration.valueFields[groupIndex]
        const mode = valueField ? getModeOfAnalysisTerm(valueField.analysisTerm) : 'sum'
        const total = calculateTotal(mode, entries)
        return 'Total ' + groupLabel + ': ' + formatNumberToString(total, decimals, unit)
    }

    const getHoveredGroupsWithItems = (hoveredBarData: Array<PayloadEntry>) => {
        if (hoveredBarData) {
            const groups: { [key: string]: Item } = {}
            hoveredBarData.forEach((data) => {
                const key = data.name.split('.')[1]
                const stackKey = data.name.split('.')[3]
                // Sometimes we can't give in the data directly with the appropriate unit, so we have the getUnit function
                // to set it from outside
                const unit = getUnit(key)
                const isStacked = Boolean(props.widgetConfiguration.stackingField)
                const percentage = isStacked
                    ? data.payload.valueFieldResults[key].stackValues[stackKey].percentage
                    : data.payload.valueFieldResults[key].percentage

                const entry = {
                    color: data.color,
                    name: stackLabelFnBanner(stacks, Boolean(props.widgetData.legend))(data),
                    value: data.value,
                    unit,
                    percentage,
                }

                if (!(key in groups)) {
                    groups[key] = {
                        entries: [],
                        hint: undefined,
                    }
                }

                // Skip entries with missing names (e.g. trend lines)
                if (entry.name !== null && entry.name !== undefined) {
                    groups[key].entries.push(entry)
                }
            })
            return groups
        }

        return {}
    }

    const getUnit = (key: string | number): string => {
        // We can just take the first because the units stay the same for the same value field.
        const stack = props.widgetData.chartData[0]
        // @ts-ignore
        return stack[key + '_unit']
    }

    const getAxisLabel = (value?: string) =>
        (Object.values(context) as Array<FieldConfiguration>).find((e) => e.name === value)?.label
    const xAxisLabel = isRowChart
        ? stacks.length === 1
            ? props.widgetData.stacks[0].label
            : undefined
        : getAxisLabel(props.widgetConfiguration.pivotField)
    const yAxisLabel = isRowChart
        ? getAxisLabel(props.widgetConfiguration.pivotField)
        : stacks.length === 1
        ? props.widgetData.stacks[0].label
        : undefined
    const yAxisLabelWidth = isRowChart ? props.widgetConfiguration.yAxisLabelWidth || 70 : undefined

    const formatLegendEntries = (value: string, entry: any) => {
        const label = groupLabelFn(entry.payload.stackId)

        return <span style={{ fontSize: 12 }}>{label}</span>
    }

    const renderBarLabel = (
        showStackingFieldsAsSeparateBars: boolean,
        stackId: string | undefined,
        dataKeys: Array<string>,
        i: number,
        showPercentages: boolean
    ): React.ReactElement | undefined => {
        const barLabel = (
            <BarLabel
                isVertical={!isRowChart}
                xOffset={
                    isRowChart
                        ? yAxisLabelWidth
                            ? props.widgetConfiguration.valueFields.length >= 2
                                ? yAxisLabelWidth + 25
                                : 40
                            : 95
                        : 0
                }
                chartData={chartData}
                selection={selection}
                decimals={decimals}
                stackId={stackId}
                onBarClick={handleUpdateSelection(props.widgetState, props.setWidgetState)}
                showPercentages={showPercentages}
                showOnlyPercentage={normaliseBars}
                color={props.usesDarkMode ? 'white' : 'black'}
            />
        )

        if (stackUpValues) {
            return undefined
        }

        if (showStackingFieldsAsSeparateBars) {
            return i === Math.floor(dataKeys.length / 2) ? barLabel : undefined
        }

        return i + 1 === dataKeys.length || i + 1 === dataKeys.length ? barLabel : undefined
    }

    return (
        <ResponsiveTestingAwareChartContainer debounce={200}>
            <BarChart
                data={chartData}
                layout={isRowChart ? 'vertical' : 'horizontal'}
                margin={margin}
                barGap={showStackingFieldsAsSeparateBars ? 0 : 2}
                stackOffset={normaliseBars ? 'expand' : 'sign'}
                onMouseMove={(state) =>
                    state.isTooltipActive ? setHoveredBarIndex(state.activeTooltipIndex) : setHoveredBarIndex(null)
                }
                {...chartWidthAndHeightForTesting}
            >
                {isRowChart ? (
                    <XAxis
                        type="number"
                        tick={
                            <CustomizedTick dy={10} fontSize={12} textAnchor="middle" showPercentage={normaliseBars} />
                        }
                        label={
                            xAxisLabel && {
                                content: <CustomizedLabel y={15} fontSize={12} value={xAxisLabel} />,
                            }
                        }
                    />
                ) : (
                    <XAxis
                        type="category"
                        dataKey="key"
                        interval={0}
                        tick={<CustomTickForVertical />}
                        axisLine={false}
                        label={
                            xAxisLabel && {
                                content: <CustomizedLabel y={35} fontSize={12} value={xAxisLabel} />,
                            }
                        }
                    />
                )}

                {isRowChart ? (
                    <YAxis
                        type="category"
                        dataKey="key"
                        axisLine={false}
                        interval={0}
                        width={yAxisLabelWidth}
                        label={
                            yAxisLabel && {
                                content: (
                                    <CustomizedLabel
                                        // calculates distance to yAxisLabels
                                        y={yAxisLabelWidth ? yAxisLabelWidth * -0.5 - 20 : -50}
                                        angle={-90}
                                        fontSize={12}
                                        value={yAxisLabel}
                                    />
                                ),
                            }
                        }
                        tick={stacks.length < 2 ? false : <CustomTickForHorizontal />}
                    />
                ) : (
                    <YAxis
                        type="number"
                        tick={<CustomizedTick dy={4} fontSize={12} textAnchor="end" showPercentage={normaliseBars} />}
                        label={
                            yAxisLabel && {
                                content: <CustomizedLabel y={-5} angle={-90} fontSize={12} value={yAxisLabel} />,
                            }
                        }
                    />
                )}

                {isRowChart ? <ReferenceLine x={0} stroke="#666" /> : <ReferenceLine y={0} stroke="#666" />}

                <Tooltip
                    content={
                        <CustomTooltip
                            alwaysShowLabel={showStackingFieldsAsSeparateBars}
                            stackLabelFn={stackLabelFnBanner(stacks, Boolean(props.widgetData.legend))}
                            decimals={decimals}
                            getHoveredGroupsWithItems={getHoveredGroupsWithItems}
                            groupLabelFn={groupLabelFn}
                            groupTotalFn={groupTotalFn}
                            getUnit={getUnit}
                            showOnlyPercentage={normaliseBars}
                            showPercentage={props.widgetConfiguration.showPercentages}
                        />
                    }
                    cursor={false}
                />

                {stacks.map((stack, stackIndex) => {
                    const showPercentages = props.widgetConfiguration.showPercentages
                    const stackId = stackUpValues ? 'valueStack' : stack.stackId || undefined
                    const keys = stack.dataKeys.map((e) => e.key)

                    return keys.map((dataKey: string, i: number) => {
                        const customColorEntry = chartData.find((entry) => entry.valueFieldResults[dataKey]?.color)
                        const customColor =
                            customColorEntry &&
                            customColorEntry.valueFieldResults[dataKey] &&
                            customColorEntry.valueFieldResults[dataKey].color

                        const isMultiValue = props.widgetData.stacks.length > 1
                        const color =
                            customColor ||
                            COLORS[
                                ((stackUpValues && !hasCategoryStackingField) ||
                                (isMultiValue && !hasCategoryStackingField)
                                    ? stackIndex
                                    : i) % COLORS.length
                            ]

                        return (
                            <Bar
                                key={dataKey}
                                dataKey={dataKey}
                                stackId={
                                    stackUpValues
                                        ? 'valueStack'
                                        : showStackingFieldsAsSeparateBars
                                        ? undefined
                                        : stackId
                                }
                                label={renderBarLabel(
                                    showStackingFieldsAsSeparateBars,
                                    stackId,
                                    keys,
                                    i,
                                    showPercentages
                                )}
                                background={{ fill: 'transparent' }} // this make the whole row clickable
                                stroke={color} // needed for hovering tooltip
                                fill={color} // needed for hovering tooltip
                                isAnimationActive={false}
                                className="row-chart__bar-cell"
                                onClick={handleUpdateSelection(props.widgetState, props.setWidgetState)}
                            >
                                {chartData.map((entry, index: number) => {
                                    const isGray = hasSelection && !selection.includes(entry.key)
                                    const cellClassName = classnames('row-chart__bar-cell', {
                                        'row-chart__bar-cell--non-clickable': entry.key === FURTHER_VALUES,
                                        'row-chart__bar-cell--grey': isGray,
                                    })

                                    return (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={color}
                                            opacity={hoveredBarIndex !== null && hoveredBarIndex !== index ? 0.5 : 1}
                                            // @ts-ignore props overload className
                                            className={cellClassName}
                                        />
                                    )
                                })}
                            </Bar>
                        )
                    })
                })}

                {referenceLines &&
                    referenceLines.map((entry: ReferenceLineEntry, index) => {
                        const value = entry.value || undefined
                        const color = entry.color
                        return (
                            <ReferenceLine
                                key={`reference-line-${index}`}
                                x={isRowChart ? value : undefined}
                                y={isRowChart ? undefined : value}
                                label={
                                    <CustomizedReferenceLineLabel
                                        color={color}
                                        isVerticalChart={isRowChart}
                                        label={entry.label}
                                    />
                                }
                                stroke={color}
                                strokeWidth={2}
                            />
                        )
                    })}
                {!isRowChart && !hasCategoryStackingField && props.widgetConfiguration.showMultiValueLegend && (
                    <Legend
                        verticalAlign={props.widgetConfiguration.multiValueLegendPosition || 'top'}
                        formatter={formatLegendEntries}
                        wrapperStyle={
                            props.widgetConfiguration.multiValueLegendPosition === 'bottom'
                                ? { bottom: 10 }
                                : { top: 10 }
                        }
                    />
                )}
            </BarChart>
        </ResponsiveTestingAwareChartContainer>
    )
}

export const handleUpdateSelection =
    (
        widgetState: {
            selection: RowChartSelection
        },
        setWidgetState: (selection: any) => void
    ) =>
    (data: any) => {
        const key = data.key || data.activeLabel
        if (key !== FURTHER_VALUES && key !== SUM) {
            let selection: RowChartSelection = widgetState.selection
            const ctrlKeyClicked = getCtrlKeyPressed()
            if (ctrlKeyClicked) {
                if (selection.includes(key)) {
                    // remove an already set value in the selection (deselect)
                    selection = selection.filter((value) => value !== key)
                } else if (ctrlKeyClicked) {
                    // add new value to previous selection
                    selection = [...selection, key]
                }
            } else if (selection.length === 1 && selection.includes(key)) {
                // deselect
                selection = []
            } else {
                // set new selection
                selection = [key]
            }

            setWidgetState({ selection })
        }

        return null
    }

export const stackLabelFnBanner = (stacks: Stacks, hasLegend: boolean) => (data: any) => {
    const pivotKey = data.dataKey
    const valueFieldHash = pivotKey.split('.')[1]

    const getLabels = (stacks: Stacks) => {
        return stacks.map((stack) => {
            const dataKeys = stack.dataKeys
            const labelStack = dataKeys.find((e) => e.key === pivotKey)
            return labelStack ? labelStack.label : undefined
        })
    }

    if (hasLegend) {
        if (stacks.length > 1) {
            return getLabels(stacks)
        }

        const firstStack: Stack = stacks[0]
        return getLabels([firstStack])
    }

    return data.payload.valueFieldResults[valueFieldHash].barLabel
}

export default RowChartWidgetChart
