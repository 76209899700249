import React, { PureComponent } from 'react'
import ApplicationHeader from './ApplicationHeader/ApplicationHeader'

class Dashboards extends PureComponent<{
    children: React.ReactNode
}> {
    render() {
        return (
            <div className="dashboards">
                <ApplicationHeader />
                {this.props.children}
            </div>
        )
    }
}

export default Dashboards
