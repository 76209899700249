import React from 'react'
import FieldFilterEditor from '../../Molecules/FieldFilterEditor/FieldFilterEditor'
import { FieldType } from '../../Atoms/FieldFilterInput/FieldFilterManifest'
import { WidgetGroupFilter } from '../../../Redux/Data/types'
import { OnTriggerAutoCompletion } from '../../Atoms/FieldFilterInput/FieldFilterInput'

type WidgetGroupFiltersProps = {
    filters: WidgetGroupFilter
    triggerAutoCompletion: OnTriggerAutoCompletion
    updateFilters: (filters: WidgetGroupFilter) => void
}

const WidgetGroupFilters: React.FC<WidgetGroupFiltersProps> = (props) => {
    const handleAddFieldFilter = (newFilter: FieldType) => {
        props.updateFilters({
            ...props.filters,
            fields: [...props.filters.fields, newFilter],
        })
    }

    const handleFieldFilterChanged = (index: number, field: string, newValue: string) => {
        props.updateFilters({
            ...props.filters,
            fields: props.filters.fields.map((oldField, i) => {
                if (i !== index) {
                    return oldField
                }

                return {
                    ...oldField,
                    [field]: newValue,
                }
            }),
        })
    }

    const handleRemoveFieldFilter = (indexToRemove: number) => {
        props.updateFilters({
            ...props.filters,
            fields: props.filters.fields.filter((v, runningIndex) => indexToRemove !== runningIndex),
        })
    }

    const fields = props.filters.fields || []
    return (
        <div>
            <FieldFilterEditor
                className="widget-group__filters filter-group--rows"
                fields={fields}
                onTriggerAutoCompletion={props.triggerAutoCompletion}
                onFilterAdded={handleAddFieldFilter}
                onFilterChanged={handleFieldFilterChanged}
                onFilterRemoved={handleRemoveFieldFilter}
            />
        </div>
    )
}

export default WidgetGroupFilters
