import React from 'react'
import { Link } from 'react-router-dom'
import { DashboardId } from '../../../Redux/Data/types'

const Tile: React.FunctionComponent<{
    className?: string
    children?: any
    onClick?: () => void
    dashboardId?: DashboardId
}> = (props) => {
    // used for placeholder tile to add new dashboard
    if (props.onClick) {
        return (
            <a onClick={props.onClick} className={'tile ' + props.className} role="button">
                {props.children}
            </a>
        )
    }

    return (
        <Link to={'/dashboard/' + props.dashboardId} className={'tile ' + props.className}>
            {props.children}
        </Link>
    )
}

const TileWall: React.FunctionComponent<{
    children?: any
}> = (props) => {
    return <div className="tile-wall">{props.children}</div>
}

export default TileWall
export { Tile }
