import { createAction } from '@reduxjs/toolkit'

const uploadConfigurationStart = createAction('EXPLY/DATA/CONFIGURATION_SHARING/UPLOAD/START')
const uploadConfigurationFinished = createAction('EXPLY/DATA/CONFIGURATION_SHARING/UPLOAD/FINISHED')
const uploadConfigurationFailed = createAction('EXPLY/DATA/CONFIGURATION_SHARING/UPLOAD/FAILED')

export const actions = {
    uploadConfigurationStart,
    uploadConfigurationFinished,
    uploadConfigurationFailed,
}
