import React, { ErrorInfo } from 'react'
import { Link } from 'react-router-dom'
import { Page, PageSection } from '../../Components/Organisms/Page/Page'
import ApplicationHeader from '../ApplicationHeader/ApplicationHeader'
import Button from '../../Components/Atoms/Button/Button'

type ErrorBoundaryProps = {
    children: React.ReactNode
    componentWrapper?: boolean
}
type ErrorBoundaryState = {
    hasError: boolean
    errorInfo?: ErrorInfo
    error?: Error
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error, errorInfo })
    }

    handleBugReport = () => {
        const url = window.location
        const mailBody =
            '\n \nError Message: \n' +
            this.state.error +
            '\n\nURL: \n' +
            url +
            '\n\nStack Trace: \n' +
            this.state.errorInfo?.componentStack
        window.open(
            'mailto:support@exply.io?subject=Bugreport&body=' + encodeURIComponent(mailBody),
            'BugReport',
            'target=_blank'
        )
    }

    handleGoBack = () => this.setState({ hasError: false })

    render() {
        if (this.state.hasError) {
            if (this.props.componentWrapper) {
                return (
                    <div className="error-boundary--message">
                        <h2>Uh oh... Something went wrong. We are terribly sorry!</h2>
                        <p>You can help our engineers fix this problem by submitting a bug report.</p>
                        <div className="error-boundary--actions">
                            <Link to="/">
                                <Button onClick={this.handleGoBack}>Return to start page</Button>
                            </Link>
                            <Button onClick={this.handleBugReport} type="primary">
                                Submit bug report
                            </Button>
                        </div>
                    </div>
                )
            }

            return (
                <Page header={<ApplicationHeader />}>
                    <PageSection>
                        <div className="error-boundary--message">
                            <h2>Uh oh... Something went wrong. We are terribly sorry!</h2>
                            <p>You can help our engineers fix this problem by submitting a bug report.</p>
                            <div className="error-boundary--actions">
                                <Link to="/">
                                    <Button onClick={this.handleGoBack}>Return to start page</Button>
                                </Link>
                                <Button onClick={this.handleBugReport} type="primary">
                                    Submit bug report
                                </Button>
                            </div>
                        </div>
                    </PageSection>
                </Page>
            )
        }

        return this.props.children
    }
}
