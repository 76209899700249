import MetricsTileWidget, { MetricsTileWidgetData } from './MetricsTileWidget'
// @ts-ignore
import MetricsTileWidgetIcon from './MetricsTileWidget.Icon.tsx'
import MetricsTileWidgetStateInitializer from './MetricsTileWidget.init'
import MetricsTileWidgetConfigurationInitializer, {
    MetricTileConfigurationInitializerForWidgetExplorer,
} from './MetricsTileWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import Select from '../../../Atoms/Select/Select'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import ColorScaleEditor from '../../../Molecules/ColorScaleEditor/ColorScaleEditor'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'

export const metricTileComparisonOptions = [
    {
        value: 'previousPeriod',
        label: 'Previous Period',
    },
    {
        value: 'previousYear',
        label: 'Previous Year',
    },
]

const config: WidgetManifestConfiguration = {
    name: 'Metric Tile',
    component: MetricsTileWidget,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 0,
        },
        max: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 0,
        },
    },
    renderRequirements: (widgetData?: MetricsTileWidgetData) => {
        if (!widgetData?.value) {
            return widgetProblems.noData
        }
    },
    icon: MetricsTileWidgetIcon,
    stateInitializationFunction: MetricsTileWidgetStateInitializer,
    configurationInitializer: MetricsTileWidgetConfigurationInitializer,
    configurationInitializerForWidgetExplorer: MetricTileConfigurationInitializerForWidgetExplorer,

    editors: {
        data: [
            buildingBlocks.valueField({ areDateRangeFieldsAllowed: true }),
            {
                widgetConfigurationProperty: 'comparison',
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Compare with',
                    editor: Select,
                    isMulti: true,
                    options: metricTileComparisonOptions,
                },
            },
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.colorPicker(),
                    buildingBlocks.selectOneOf('fontColor', 'Font Color', [
                        {
                            value: 'white',
                            label: 'white',
                        },
                        {
                            value: 'black',
                            label: 'black',
                        },
                    ]),
                    buildingBlocks.numberOfDecimals(),
                ],
            },
            {
                name: 'Color Scale',
                editors: [
                    {
                        widgetConfigurationProperty: 'colorScale',
                        component: ColorScaleEditor,
                        configuration: {
                            noPercentage: true,
                        },
                    },
                ],
            },
        ],
    },
    minWidth: 2,
    minHeight: 2,
}

export default config
