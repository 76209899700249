import React, { useEffect, useState, FC } from 'react'

import DocumentFieldSelect from '../DocumentFieldSelect/DocumentFieldSelect'
import { GEO_POINT } from '../../Organisms/Widgets/MapWidget/MapWidget.manifest'

type GeoFieldEditorProps = {
    value?: string
    onChange: (value: any) => void
    widgetConfiguration: any
}

const GeoFieldEditor: FC<GeoFieldEditorProps> = (props) => {
    const [mode, setMode] = useState<string>(props.widgetConfiguration.mode)

    const widgetConfig = props.widgetConfiguration

    useEffect(() => {
        if (widgetConfig.mode !== mode) {
            setMode(widgetConfig.mode)
            props.onChange(null)
        }
    }, [widgetConfig, mode, props])

    const isPointMode = mode === GEO_POINT
    return (
        <DocumentFieldSelect
            onChange={props.onChange}
            tags={isPointMode ? ['GEO_POINT'] : ['GEO_SHAPE']}
            value={props.value}
            clearable={false}
        />
    )
}

export default GeoFieldEditor
