import React, { PureComponent } from 'react'
import SecondaryHeader from '../../../Components/Molecules/SecondaryHeader/SecondaryHeader'
import SecondaryFooter from '../../../Components/Molecules/SecondaryFooter/SecondaryFooter'
import WidgetGroupArea from './WidgetGroupsArea'
import DashboardTitle from './DashboardTitle'
import EditDashboardButtons from './EditDashboardButtons'
import DashboardControls from './DashboardControls'
import { DashboardId } from '../../../Redux/Data/types'

/**
 * embedded dashboard without header, footer or Exply menu
 */
export default class EmbeddedDashboard extends PureComponent<{
    dashboardId: DashboardId
    inEditMode: boolean
}> {
    render() {
        const { dashboardId, inEditMode } = this.props

        return (
            <div className="embedded-dashboard-container">
                <SecondaryHeader
                    isPositionRelative={true}
                    left={<DashboardTitle dashboardId={dashboardId} />}
                    right={inEditMode ? this.renderEditDashboardButtons() : this.renderDashboardControls()}
                />
                <WidgetGroupArea
                    className="dashboard--relative"
                    dashboardId={this.props.dashboardId}
                    isPreview={false}
                />
                {inEditMode && <SecondaryFooter isPositionRelative={true} right={this.renderEditDashboardButtons()} />}
            </div>
        )
    }

    renderDashboardControls = () => <DashboardControls dashboardId={this.props.dashboardId} isEmbedded={true} />

    renderEditDashboardButtons = () => <EditDashboardButtons dashboardId={this.props.dashboardId} />
}
