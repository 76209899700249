import { takeLatest, select } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { actions, selectors } from '../../Redux'
import { WidgetGroupId } from '../../Redux/Data/types'

export default function* ScrollNewWidgetGroupIntoView() {
    yield takeLatest(
        [actions.Data.Dashboards.WidgetGroupEditMode.duplicateWidgetGroup],
        function* (action: PayloadAction<WidgetGroupId>) {
            const widgetGroupConfig = selectors.Data.Dashboards.widgetGroupById(yield select(), action.payload)
            const targetPosition = widgetGroupConfig.position + 10
            const widgetGroupsHTML = document.getElementsByClassName('widget-group--position-' + targetPosition)
            const groupToScrollTo = widgetGroupsHTML[0]
            if (groupToScrollTo) {
                window.scrollBy({
                    left: 0,
                    top: groupToScrollTo.getBoundingClientRect().top - 100,
                    behavior: 'smooth',
                })
            }
        }
    )
}
