import uuid from 'uuid'

const createDefaultConfiguration = () => {
    return {
        title: 'Quick Filter Widget',
        filters: [
            {
                uuid: uuid(),
                filter: {
                    field: '',
                    operator: 'equals',
                    values: [],
                },
            },
        ],
    }
}

export default createDefaultConfiguration

export const QuickFilterWidgetConfigurationInitializerForWidgetExplorer = createDefaultConfiguration
