import React from 'react'
import ApplicationHeader from '../../../Containers/ApplicationHeader/ApplicationHeader'
import { Page, PageSection } from '../Page/Page'

const Imprint: React.FC<unknown> = () => {
    const header = <ApplicationHeader pageTitle="Imprint" />

    return (
        <Page header={header}>
            <PageSection>
                <h2>Information in accordance with section 5 TMG</h2>
                Sandstorm Media GmbH
                <br />
                Tatzberg 47
                <br />
                01307 Dresden
                <br />
                <h4>Represented by</h4>
                Florian Heinze
                <br />
                <h4>Contact</h4>
                Telephone: +49 351 418 96 49
                <br />
                E-Mail: kontakt@sandstorm-media.de
                <br />
                <h4>Register entry</h4>
                Entry in Handelsregister
                <br />
                Register Court: Amtsgericht Dresden, Germany
                <br />
                Register Number: HRB 27395
                <br />
                <h4>Umsatzsteuer-ID</h4>
                VAT identification number in accordance with section 27 a of the German VAT act
                <br />
                DE 264 953 453
                <br />
                <h2>Disclaimer</h2>
                <h4>Accountability for content</h4>
                <p>
                    The contents of our pages have been created with the utmost care. However, we cannot guarantee the
                    contents' accuracy, completeness or topicality. According to statutory provisions, we are
                    furthermore responsible for our own content on these web pages. In this context, please note that we
                    are accordingly not obliged to monitor merely the transmitted or saved information of third parties,
                    or investigate circumstances pointing to illegal activity. Our obligations to remove or block the
                    use of information under generally applicable laws remain unaffected by this as per §§ 8 to 10 of
                    the Telemedia Act (TMG).
                </p>
                <h4>Accountability for links</h4>
                <p>
                    Responsibility for the content of external links (to web pages of third parties) lies solely with
                    the operators of the linked pages. No violations were evident to us at the time of linking. Should
                    any legal infringement become known to us, we will remove the respective link immediately.
                </p>
                <h4>Copyright</h4>
                <p>
                    Our web pages and their contents are subject to German copyright law. Unless expressly permitted by
                    law (§ 44a et seq. of the copyright law), every form of utilizing, reproducing or processing works
                    subject to copyright protection on our web pages requires the prior consent of the respective owner
                    of the rights. Individual reproductions of a work are allowed only for private use, so must not
                    serve either directly or indirectly for earnings. Unauthorized utilization of copyrighted works is
                    punishable (§ 106 of the copyright law).
                </p>
            </PageSection>
        </Page>
    )
}

export default Imprint
