import React from 'react'
import classnames from 'classnames'
import HelpTooltipIcon from '../../Molecules/HelpTooltip/HelpTooltipIcon'
import { TooltipId } from '../../../StaticManifests/manifest.tooltips'

const NumberPicker: React.FunctionComponent<{
    className?: string
    placeholder?: string | number
    // undefined value means uncontrolled, can lead to warnings if not undefined later
    value?: number | string | null
    label?: string
    onChange: (arg: number | null) => void
    min?: number
    max?: number
    step?: number
    disabled?: boolean
    precision?: number
    helpToolTip?: TooltipId
    hideBorder?: boolean
    hasError?: boolean
    useSelectHeight?: boolean
    autoFocus?: boolean
    onFocus?: () => void
}> = (props) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const stringValue = (event.target.value || '').trim()
        const isEmpty = stringValue === ''
        if (isEmpty) {
            props.onChange(null)
        } else {
            const numericValue = Number(event.target.value)
            const numberOrNull = Number.isNaN(numericValue) ? null : numericValue
            props.onChange(numberOrNull)
        }
    }

    const classNames = classnames('input', props.className, {
        'input--disabled': props.disabled,
        'input--hide-border': props.hideBorder,
        'input--has-error': props.hasError,
        'input--select-height': props.useSelectHeight,
    })

    return (
        <div className="numberpicker">
            <input
                className={classNames}
                disabled={props.disabled}
                placeholder={props.placeholder ? props.placeholder.toString() : undefined}
                value={props.value === null || props.value === undefined ? '' : props.value}
                onChange={handleChange}
                onFocus={props.onFocus}
                min={props.min}
                max={props.max}
                step={props.step}
                type="number"
                autoFocus={props.autoFocus}
            />
            {props.label && <label>{props.label}</label>}
            {props.helpToolTip && <HelpTooltipIcon id={props.helpToolTip} />}
        </div>
    )
}

export default NumberPicker
