import { takeLatest } from 'redux-saga/effects'
import { actions } from '../../Redux'
import { saveDashboard } from './SaveDashboardToServerSaga'
import { ApiDashboardConfiguration } from '../../Redux/Data/types'
import { getApiDashboardConfigurationForId } from './Utility'

export default function* SetDashboardImage() {
    yield takeLatest(actions.Data.Dashboards.DashboardEditMode.setIcon, function* (action) {
        const { dashboardId } = action.payload
        const apiDashboard: ApiDashboardConfiguration = yield getApiDashboardConfigurationForId(dashboardId)
        yield saveDashboard(apiDashboard)
    })
}
