import React from 'react'
import { Collapse } from 'react-collapse'
import classnames from 'classnames'
import { inTestingMode } from '../../../Utility/Testing'

const List: React.FunctionComponent<{
    className?: string
    children?: Array<React.ReactChild | any> //  i hate typing "children"
}> = (props) => {
    const className = classnames('list', props.className)
    return <div className={className}>{props.children}</div>
}

export const ListItem = React.forwardRef<
    HTMLDivElement,
    {
        isOpen?: boolean
        bullet?: React.ReactNode
        left?: string | Array<React.ReactNode>
        right?: string | Array<React.ReactNode>
        collapsible?: React.ReactNode
        onClick?: () => void // can also be applied to children of left / right
        className?: string
    }
>((props, ref) => {
    const handleClick = () => {
        if (props.onClick) {
            props.onClick()
        }
    }

    const renderCollapsible = () => {
        if (inTestingMode) {
            // React collapse does not work in tests, see https://github.com/nkbt/react-collapse/issues/142
            return <div className="mock-react-collapse list__item-body">{props.collapsible}</div>
        }

        return (
            <Collapse
                isOpened={props.isOpen || false}
                theme={{ collapse: 'list__item-body', content: 'ReactCollapse--content' }}
            >
                {props.collapsible}
            </Collapse>
        )
    }

    const className = classnames('list-item', props.className, {
        'list-item--open': props.collapsible && props.isOpen,
    })

    return (
        <div className={className} ref={ref}>
            <div className="list__item-header" onClick={handleClick} role="button">
                {props.bullet || ''}
                <div className="list__item-left">{props.left || ''}</div>
                <div className="list__item-right">{props.right || ''}</div>
            </div>
            {renderCollapsible()}
        </div>
    )
})

export default List
