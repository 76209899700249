import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { put, select, takeLatest } from 'redux-saga/effects'
import axios from '../Utility/axios'
import { actions, selectors } from '../../Redux'
import { AccessMode, DashboardId } from '../../Redux/Data/types'

export const downloadDashboardConfigurationAction = createAction<DashboardId>('Sagas/DownloadDashboardConfiguration')

export default function* DownloadDashboardConfigurationSaga() {
    yield takeLatest(downloadDashboardConfigurationAction, function* (action) {
        try {
            const accessMode = selectors.Data.System.accessMode(yield select())
            const prefix = accessMode === AccessMode.SHARED_DASHBOARD ? 'shared-dashboard' : 'dashboard'
            const suffix =
                accessMode === AccessMode.SHARED_DASHBOARD
                    ? '/' +
                      selectors.Data.Dashboards.ShareDashboard.getSharedDashboardByDashboardId(
                          yield select(),
                          action.payload
                      )?.id
                    : ''

            const response: AxiosResponse = yield axios({
                method: 'GET',
                url: `/${prefix}/configuration/${action.payload}` + suffix,
                responseType: 'blob',
            })

            if (response.status === 200) {
                const fileName = response.headers['content-disposition'].match(/attachment; filename="(.*)"/)[1]
                const link = document.createElement('a')
                link.download = fileName
                link.href = window.URL.createObjectURL(response.data)
                link.click()
                link.remove()
            } else {
                yield put(actions.UI.Notification.show({ id: 'dashboardDownloadFailed' }))
            }
        } catch (e) {
            console.warn('FAILED!', e)
        }
    })
}
