import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Draggable, DragStart, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { DashboardConfiguration } from '../../../Redux/Data/types'
import DashboardListView from '../DashboardOverview/DashboardListView'
import DashboardTileView from '../DashboardOverview/DashboardTileView'
import Icon from '../../Atoms/Icon/Icon'
import { DashboardGroupId } from '../../../Redux/UI/types'
import ShyTextInput from '../../Atoms/ShyTextInput/ShyTextInput'
import { AppDispatch } from '../../../store'
import { actions, selectors } from '../../../Redux'
import { RootState } from '../../../Redux/types'
import { useClickOutsideCallback } from '../../../Utility/Hooks'
import { ListItem } from '../List/List'

export type SharedDashboardGroupOwnProps = {
    titleFilter: string
    id: DashboardGroupId
}

type DashboardGroupOwnProps = {
    index: number
    draggedItem: null | DragStart
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: SharedDashboardGroupOwnProps) => ({
    handleChangeGroupTitle: (title: string) =>
        dispatch(
            actions.UI.DashboardGroups.changeGroupTitle({
                groupId: ownProps.id,
                title,
            })
        ),
    handleRemoveGroup: () => dispatch(actions.UI.DashboardGroups.removeDashboardGroup(ownProps.id)),
    handleToggleIsGroupCollapsed: () => dispatch(actions.UI.DashboardGroups.toggleIsGroupCollapsed(ownProps.id)),
})

const mapStateToProps = (state: RootState, props: SharedDashboardGroupOwnProps) => ({
    allDashboards: selectors.Data.Dashboards.allDashboards(state),
    viewMode: selectors.UI.CurrentUser.dashboardOverviewMode(state),
    groupConfig: selectors.UI.DashbardGroups.dashboardGroupById(state, props.id),
    isLastAddedGroup: selectors.UI.DashbardGroups.lastAddedDashboardGroup(state) === props.id,
})

type ReduxProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
type DashboardGroupProps = SharedDashboardGroupOwnProps & DashboardGroupOwnProps & ReduxProps

export const filterDashboards = (dashboards: Array<DashboardConfiguration>, filter: string) =>
    dashboards
        .filter((dashboard) => dashboard.title.toLowerCase().match(filter.toLowerCase()))
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((dashboard) => dashboard.id)

const DashboardGroup: React.FC<DashboardGroupProps> = (props) => {
    const DashboardListComponent = props.viewMode === 'list' ? DashboardListView : DashboardTileView
    const [inEditMode, setInEditMode] = useState(props.isLastAddedGroup)
    const [isHovered, setIsHovered] = useState(false)
    const [groupTitle, setGroupTitle] = useState(props.groupConfig.title)
    const [isDropDisabled, setIsDropDisabled] = useState(false)
    const ref = useRef<HTMLDivElement | null>(null)
    const handleSave = () => {
        if (inEditMode) {
            props.handleChangeGroupTitle(groupTitle)
            setInEditMode(false)
        }
    }
    useClickOutsideCallback(ref, handleSave)
    const dashboardIds = props.groupConfig.dashboards
    const dashboardConfigs = props.allDashboards.filter((dashboard) => dashboardIds.includes(dashboard.id))
    const filteredDashboards = useMemo(
        () => filterDashboards(dashboardConfigs, props.titleFilter),
        [props.titleFilter, dashboardConfigs]
    )

    const handleEdit = () => setInEditMode(true)

    const handleCancel = () => {
        setGroupTitle(props.groupConfig.title)
        setInEditMode(false)
    }

    const isOpen =
        Boolean(props.draggedItem) ||
        (props.titleFilter
            ? props.titleFilter.length > 0 && filteredDashboards.length > 0
            : !props.groupConfig.isCollapsed)

    // disable dropping a dashboard into a group which already includes that dashboard
    useEffect(() => {
        const dashboardId = props.draggedItem?.draggableId.split('#')[1]
        setIsDropDisabled(
            Boolean(
                props.draggedItem?.source.droppableId !== props.id &&
                    dashboardId &&
                    props.groupConfig.dashboards.includes(dashboardId)
            )
        )
    }, [props.draggedItem, props.groupConfig])

    return (
        <Draggable draggableId={props.id} index={props.index}>
            {(provided) => (
                <div
                    className="dashboard-overview__dashboard-group"
                    onMouseOver={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ListItem
                        ref={ref}
                        left={[
                            <Icon
                                key="icon"
                                name="ChevronIcon"
                                rotation={isOpen ? 90 : 0}
                                onClick={props.handleToggleIsGroupCollapsed}
                                size="small"
                            />,
                            <h2 className="dashboard-group__header" key={'dashboard-group-header-' + props.id}>
                                <div
                                    onClick={!inEditMode ? props.handleToggleIsGroupCollapsed : () => {}}
                                    className="dashboard-group__icon-and-title"
                                >
                                    <Icon name={'DashboardIcon'} key={'dashboard-group-icon-' + props.id} />
                                    <ShyTextInput
                                        onChange={setGroupTitle}
                                        value={groupTitle}
                                        disabled={!inEditMode}
                                        autoFocus
                                        onEnterPress={handleSave}
                                        placeholder={inEditMode ? 'Your group title' : 'Unnamed Group'}
                                        className="dashboard-group__title-input"
                                    />
                                </div>
                                <div className="dashboard-group__header__edit-icons">
                                    <Icon
                                        name={inEditMode ? 'CheckmarkIcon' : 'PenIcon'}
                                        onClick={inEditMode ? handleSave : handleEdit}
                                        className={isHovered || inEditMode ? '' : 'edit-icon--hidden'}
                                        title={inEditMode ? 'Save changes' : 'Cancel'}
                                    />
                                    {inEditMode && <Icon name={'PlusIcon'} onClick={handleCancel} rotation={45} />}
                                    {inEditMode && (
                                        <Icon
                                            name={'TrashIcon'}
                                            onClick={props.handleRemoveGroup}
                                            title={'Remove Group. This does not delete your dashboards.'}
                                        />
                                    )}
                                </div>
                            </h2>,
                        ]}
                        collapsible={
                            <Droppable
                                droppableId={props.id}
                                type="dashboard"
                                isDropDisabled={isDropDisabled}
                                direction={'horizontal'}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={
                                            snapshot.isDraggingOver
                                                ? 'dashboard-group__content dashboard-group__content--dragged-over'
                                                : 'dashboard-group__content'
                                        }
                                    >
                                        {filteredDashboards.length > 0 ? (
                                            <DashboardListComponent
                                                dashboardIds={filteredDashboards}
                                                dashboardGroupId={props.id}
                                                dragNDropPlaceholder={provided.placeholder}
                                            />
                                        ) : props.titleFilter ? (
                                            <p className="dashboard-overview--no-dashboards">
                                                No search results for '{props.titleFilter}'
                                            </p>
                                        ) : (
                                            <p className="dashboard-overview--empty-group">
                                                Drag and Drop Dashboards here
                                            </p>
                                        )}
                                    </div>
                                )}
                            </Droppable>
                        }
                        isOpen={isOpen}
                    />
                </div>
            )}
        </Draggable>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGroup)
