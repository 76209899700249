import React from 'react'
import classnames from 'classnames'

const SettingsView: React.FC<{
    main: React.ReactNode | HTMLElement
    aside: React.ReactNode | HTMLElement
    verticalOrientation?: boolean
    className?: string
}> = (props) => {
    const className = classnames('settings-view', props.className, {
        'settings-view-vertical': props.verticalOrientation,
    })

    return (
        <div className={className}>
            <section className="settings-view__column settings-view__column--main">{props.main}</section>

            {props.aside && <aside className="settings-view__column settings-view__column--aside">{props.aside}</aside>}
        </div>
    )
}

export default SettingsView
