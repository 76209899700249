import { put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { actions } from '../../Redux'
import axios from '../Utility/axios'

export default function* CreateOrUpdateUserOrGroupSagas() {
    yield takeLatest(actions.Data.UserManagement.createOrUpdate, function* (action) {
        try {
            const userOrGroup = action.payload.userOrGroup

            const response: AxiosResponse = yield axios({
                method: 'POST',
                url: 'admin/userManagement/createOrUpdate',
                data: userOrGroup,
            })

            if (response.status < 400) {
                yield put(actions.Data.UserManagement.setUsersAndGroups(response.data))
            } else if (response.status !== 418 /* there is already a modal in this case */) {
                yield put(actions.UI.Notification.show({ id: 'userOrGroupSaveFailed' }))
            }
        } catch (e) {
            console.error(e)
        }
    })
}
