import React from 'react'

import produce from 'immer'
import Button from '../../Atoms/Button/Button'
import TextInput from '../../Atoms/TextInput/TextInput'
import NumberPicker from '../../Atoms/NumberPicker/NumberPicker'
import Icon from '../../Atoms/Icon/Icon'

export type ReferenceLineEntry = {
    label: string
    value: number | null
    color: string
}

const defaultEntry: ReferenceLineEntry = {
    label: '',
    value: 0,
    color: '#ff0000',
}

const ReferenceLineEditor: React.FunctionComponent<{
    value?: Array<ReferenceLineEntry>
    onChange: (arg?: Array<ReferenceLineEntry>) => void
}> = (props) => {
    const addNewReferenceLine = () => {
        if (props.value) {
            props.onChange([...props.value, defaultEntry])
        } else {
            props.onChange([defaultEntry])
        }
    }

    const changeConfiguration =
        <K extends keyof ReferenceLineEntry>(propertyName: K, index: number) =>
        (value: ReferenceLineEntry[K]) => {
            props.onChange(
                produce(props.value, (draft) => {
                    if (draft) {
                        draft[index][propertyName] = value
                    }
                })
            )
        }

    const deleteReferenceLine = (index: number) => () => {
        props.onChange(
            produce(props.value, (draft) => {
                if (draft) {
                    draft.splice(index, 1)
                }
            })
        )
    }

    return (
        <div className="reference-line-editor">
            {props.value &&
                props.value.map((entry, index) => {
                    const color = entry.color
                    const value = entry.value
                    const label = entry.label

                    return (
                        <div key={`ref-line-${index}`} className="reference-line-editor__entry">
                            <TextInput
                                value={color}
                                type="color"
                                onChange={changeConfiguration('color', index)}
                                className="reference-line-editor__input--color"
                            />
                            <TextInput
                                value={label}
                                placeholder="Label"
                                onChange={changeConfiguration('label', index)}
                                className="reference-line-editor__input--label"
                            />
                            <NumberPicker
                                value={value}
                                onChange={changeConfiguration('value', index)}
                                step={1}
                                className="reference-line-editor__input--number"
                            />
                            <Icon onClick={deleteReferenceLine(index)} name="TimesIcon" />
                        </div>
                    )
                })}

            <Button onClick={addNewReferenceLine}>Add new reference line</Button>
        </div>
    )
}

export default ReferenceLineEditor
