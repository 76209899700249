import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import produce from 'immer'
import { actionTypes as system } from '../System'
import { UiState } from './types'
import { RootState } from '../types'
import { THEME } from '../../Utility/DarkMode'

export type DashboardOverviewMode = 'tile' | 'list'

export type ToggleUserSettingPayload = {
    isAllowed: boolean
    isInitial?: true
}

const currentUserSlice = createSlice({
    name: 'UI/CurrentUser',
    initialState: {} as UiState,
    extraReducers: {
        [system.INIT](state, action) {
            const { initialState } = action.payload
            state.currentUser = { ...initialState.user }
            if (state.currentUser) {
                state.currentUser.uiSettings = initialState.userSettings
            }
        },
    },
    reducers: {
        changeName: (state, action: PayloadAction<string>) => {
            if (state.currentUser) {
                state.currentUser.label = action.payload
            }
        },
        changePreferredTheme: (state, action: PayloadAction<THEME>) => {
            if (state.currentUser) {
                state.currentUser.uiSettings.preferredTheme = action.payload
            }
        },
        changeSystemTheme: (state, action: PayloadAction<THEME>) => {
            if (state.currentUser) {
                state.currentUser.uiSettings.currentSystemTheme = action.payload
            }
        },
        setDashboardOverviewMode: (state, action: PayloadAction<DashboardOverviewMode>) => {
            if (state.currentUser) {
                state.currentUser.uiSettings.dashboardOverviewMode = action.payload
            }
        },
    },
})

export const actions = currentUserSlice.actions
export const reducer = currentUserSlice.reducer

const settings = createSelector(
    (state: RootState) => state.UI.currentUser && state.UI.currentUser.settings,
    (settings) => {
        if (settings) {
            // see UserSettings.groovy
            const isAdmin = settings.isAdmin
            const isSystemManager = settings.isSystemManager
            const isUserManager = settings.isUserManager
            const isDashboardManager = settings.isDashboardManager
            const isImporterManager = settings.isImporterManager
            settings = produce(settings, (draft) => {
                draft.canManageDashboards = isAdmin || isSystemManager || isUserManager
                draft.canManageDashboards = isAdmin || isSystemManager || isDashboardManager
                draft.canManageImporters = isAdmin || isSystemManager || isImporterManager
            })

            return settings
        }
    }
)

const hasPermissions = createSelector(
    (state: RootState) => state.UI.currentUser,
    (user) => {
        if (user) {
            const isAdmin = user.settings.isAdmin
            const hasGroups = user.subGroups.length > 0
            const hasVisibleDashboards = user.settings.visibleDashboards.length > 0
            const canSeeAnyDashboard = user.settings.canSeeAnyDashboard
            const canManageDashboards = user.settings.canManageDashboards
            return isAdmin || hasGroups || hasVisibleDashboards || canSeeAnyDashboard || canManageDashboards
        }

        return false
    }
)

export function usesDarkMode(state: RootState) {
    const preferredTheme = state.UI.currentUser.uiSettings.preferredTheme
    if (preferredTheme === 'SYSTEM' || preferredTheme === undefined) {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    }

    return preferredTheme === 'DARK' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
}

export const selectors = {
    settings,
    hasPermissions,
    loggedInUser: (state: RootState) => state.UI.currentUser,
    dashboardOverviewMode: (state: RootState) =>
        state.UI.currentUser ? state.UI.currentUser.uiSettings.dashboardOverviewMode : 'tile',
    usesDarkMode,
}
