// @ts-ignore file extension
import GanttChartWidgetIcon from './GanttChartWidget.Icon.tsx'
import StateInitializer from './GanttChartWidget.init'
import {
    capabilities,
    configurationInitializerForWidgetExplorer,
    createDefaultConfiguration,
} from './GanttChartWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import GanttChartWidget from './LazyGanttChartWidget'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'
import { GanttChartData } from './GanttChartWidget.types'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'

const config: WidgetManifestConfiguration = {
    name: 'Gantt Chart',
    component: GanttChartWidget,
    capabilities,
    icon: GanttChartWidgetIcon,
    stateInitializationFunction: StateInitializer,
    configurationInitializer: createDefaultConfiguration,
    configurationInitializerForWidgetExplorer,
    renderRequirements: (widgetData?: GanttChartData) => {
        // The Gantt Chart has more ways of having too many results than a too many buckets exception (which gets caught and processed
        // in backend), so we need this extra requirement for tooManyValues.
        if (widgetData?.tooManyValues) {
            return widgetProblems.tooManyResults
        }

        if (!widgetData?.hasData) {
            return widgetProblems.noData
        }

        if (widgetData?.negativeValues) {
            return widgetProblems.negativeValues
        }

        // TODO: maybe increase limit on server and allow the user to "render nonetheless" for 500 - 3000 cells (takes forever) ?
        if (widgetData?.stacks.numberOfCells > 500) {
            return widgetProblems.tooManyResults
        }
    },

    editors: {
        data: [
            buildingBlocks.pivotField(),
            buildingBlocks.stackingField(),
            buildingBlocks.valueField({
                label: 'Elapsed Hours Value Field',
                disableCalculatedField: true,
                isModeDisabled: true,
                isGroupingFieldDisabled: true,
                areDateRangeFieldsAllowed: false,
            }),
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [buildingBlocks.numberOfDecimals()],
            },
        ],
    },
}

export default config
