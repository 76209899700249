import { put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { createAction } from '@reduxjs/toolkit'
import { actions } from '../../../../Redux'
import axios from '../../../Utility/axios'

export const loadTempoOAuthConfigurationAction = createAction('Sagas/loadTempoOAuthConfiguration')
// Loads information which the user needs to configure a tempo OAuth Connection (JIRA Cloud Importer). This route
// currently only exists for Exply for JIRA Cloud.
export default function* GetTempoOAuthConfigSaga() {
    yield takeLatest(loadTempoOAuthConfigurationAction, function* () {
        try {
            const response: AxiosResponse = yield axios({
                method: 'GET',
                url: 'admin/tempo-configuration/tempo-oauth-configuration',
            })
            if (response.status < 400) {
                yield put(actions.Data.Importer.addTempoOAuthConfigurationToStore(response.data))
            } else {
                console.error('failed to fetch Tempo OAuth Configuration', response.status, response)
            }
        } catch (e) {
            console.error(e)
        }
    })
}
