import React from 'react'
import classnames from 'classnames'

const InputValidationWrapper: React.FunctionComponent<{
    message?: string
    messageType?: 'hint'
    children: React.ReactNode
}> = (props) => {
    const className = classnames('input-validation-wrapper', {
        'input-validation-wrapper--invalid': props.message,
        ['input-validation-wrapper--' + props.messageType]: props.messageType,
    })

    return (
        <div className={className}>
            {props.children}
            <div className="input-validation-wrapper__message">{props.message}</div>
        </div>
    )
}

export default InputValidationWrapper
