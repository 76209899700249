import React, { useRef } from 'react'
import classnames from 'classnames'
import { ButtonProps } from './Button.types'

const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const className = classnames('button', props.className, {
        ['button--' + props.type]: props.type,
        'button--secondary': !props.type, // default,
        'button--square': props.isSquare,
        'button--disabled': props.isDisabled,
    })

    const buttonRef = useRef<HTMLButtonElement | null>(null)

    const handleClick = (arg?: any) => {
        props.onClick(arg)
        buttonRef.current?.blur()
    }

    return (
        <button
            className={className}
            onClick={handleClick}
            ref={buttonRef}
            disabled={props.isDisabled}
            title={props.title}
            // dataTestId is a specific selector for the e2e tests
            data-testid={props.dataTestId}
        >
            {props.label ? props.label : props.children}
        </button>
    )
}

export default Button
