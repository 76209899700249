import { ApiDashboardConfiguration, DashboardId, NormalizedDashboardConfig } from '../types'

export const initialDashboardConfig: NormalizedDashboardConfig = {
    dashboards: {
        allIds: [],
        byId: {},
    },
    widgetGroups: {
        allIds: [],
        byId: {},
    },
    widgets: {
        allIds: [],
        byId: {},
    },
    sharedDashboards: {
        allIds: [],
        byId: {},
    },
}

function createNormalizedWidgetGroupsForDashboard(
    dashboard: ApiDashboardConfiguration
): NormalizedDashboardConfig['widgetGroups'] {
    return Object.keys(dashboard.widgetGroups).reduce(
        (acc, widgetGroupId) => {
            const widgetGroup = dashboard.widgetGroups[widgetGroupId]

            return {
                allIds: [...acc.allIds, widgetGroupId],
                byId: {
                    ...acc.byId,
                    [widgetGroupId]: {
                        id: widgetGroup.uuid,
                        dashboardId: dashboard.uuid,
                        widgets: Object.values(dashboard.widgets)
                            .filter((widget) => widget.widgetGroup === widgetGroupId)
                            .map((widget) => widget.uuid),
                        position: widgetGroup.position,
                        filter: widgetGroup.filter,
                        name: widgetGroup.name,
                    },
                },
            }
        },
        {
            allIds: [],
            byId: {},
        } as NormalizedDashboardConfig['widgetGroups']
    )
}

function createNormalizedWidgetsForDashboard(
    dashboard: ApiDashboardConfiguration
): NormalizedDashboardConfig['widgets'] {
    return Object.keys(dashboard.widgets).reduce(
        (acc, widgetId) => {
            const { uuid: id, widgetGroup, coordinatesAndSize, configuration, type } = dashboard.widgets[widgetId]

            return {
                allIds: [...acc.allIds, widgetId],
                byId: {
                    ...acc.byId,
                    [widgetId]: {
                        id,
                        type,
                        widgetGroup,
                        coordinatesAndSize,
                        configuration,
                    },
                },
            } as NormalizedDashboardConfig['widgets']
        },
        {
            allIds: [],
            byId: {},
        } as NormalizedDashboardConfig['widgets']
    )
}

export function createNormalizedDashboardConfigFromApiDashboardConfig(
    apiDashboardConfig: Record<DashboardId, ApiDashboardConfiguration>
): NormalizedDashboardConfig {
    return Object.keys(apiDashboardConfig).reduce((acc, id) => {
        const dashboard = apiDashboardConfig[id]
        const widgets = createNormalizedWidgetsForDashboard(dashboard)
        const widgetGroups = createNormalizedWidgetGroupsForDashboard(dashboard)

        return {
            dashboards: {
                allIds: [...acc.dashboards.allIds, id],
                byId: {
                    ...acc.dashboards.byId,
                    [id]: {
                        id,
                        title: dashboard.title,
                        globalWidgetGroup: dashboard.globalWidgetGroup,
                        icon: dashboard.icon,
                        widgetGroups: Object.keys(dashboard.widgetGroups),
                    },
                },
            },
            widgetGroups: {
                allIds: [...acc.widgetGroups.allIds, ...widgetGroups.allIds],
                byId: {
                    ...acc.widgetGroups.byId,
                    ...widgetGroups.byId,
                },
            },
            widgets: {
                allIds: [...acc.widgets.allIds, ...widgets.allIds],
                byId: {
                    ...acc.widgets.byId,
                    ...widgets.byId,
                },
            },
            sharedDashboards: {
                allIds: [],
                byId: {},
            },
        }
    }, initialDashboardConfig)
}
