import { all, put, takeLatest } from 'redux-saga/effects'
import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions } from '../Redux'
import axios from './Utility/axios'
import { DashboardOverviewMode } from '../Redux/UI/CurrentUser'
import { THEME } from '../Utility/DarkMode'

export type ChangePasswordConfig = {
    oldPassword: string
    newPassword: string
}

export const changePassword = createAction<ChangePasswordConfig>('EXPLY/DATA/MY_PROFILE/CHANGE_PASSWORD')

function* changePasswordSaga(action: PayloadAction<string>) {
    try {
        const response: AxiosResponse = yield axios({
            method: 'POST',
            url: 'my-profile/change-password',
            data: action.payload,
        })

        if (response.status < 500 && response.status >= 400) {
            yield put(actions.UI.Notification.show({ id: 'myProfileOldPasswordWrong' }))
        } else {
            yield put(
                actions.UI.Notification.show({
                    id: 'myProfileSave500',
                    details: response.data ? JSON.stringify(response.data) : 'RESPONSE DATA WAS EMPTY',
                })
            )
        }
    } catch (e) {
        console.error(e)
    }
}

export const changeName = createAction<string>('EXPLY/DATA/MY_PROFILE/CHANGE_NAME')

function* changeNameSaga(action: PayloadAction<string>) {
    try {
        const response: AxiosResponse = yield axios({
            method: 'POST',
            url: 'my-profile/change-name',
            data: action.payload,
        })

        if (response.status < 400) {
            yield put(actions.UI.CurrentUser.changeName(action.payload))
        } else {
            yield put(actions.UI.Notification.show({ id: 'myProfileSaveNameFailed' }))
        }
    } catch (e) {
        console.error(e)
    }
}

export const changePreferredTheme = createAction<THEME>('EXPLY/DATA/MY_PROFILE/CHANGE_PREFERRED_THEME')

function* changePreferredThemeSaga(action: PayloadAction<THEME>) {
    try {
        const response: AxiosResponse = yield axios({
            method: 'POST',
            url: 'my-profile/change-preferred-theme',
            data: { theme: action.payload },
        })

        if (response.status < 400) {
            yield put(actions.UI.CurrentUser.changePreferredTheme(action.payload))
        } else {
            yield put(actions.UI.Notification.show({ id: 'myProfileSavePreferredThemeFailed' }))
        }
    } catch (e) {
        console.error(e)
    }
}

function* setDashboardOverviewMode(action: PayloadAction<DashboardOverviewMode>) {
    try {
        yield axios({
            method: 'POST',
            url: 'my-profile/dashboardOverviewMode?mode=' + action.payload,
        })
    } catch (e) {
        console.error(e)
    }
}

export default function* MyProfileSaga() {
    yield all([
        takeLatest(changePassword, changePasswordSaga),
        takeLatest(changeName, changeNameSaga),
        takeLatest(changePreferredTheme, changePreferredThemeSaga),
        takeLatest(actions.UI.CurrentUser.setDashboardOverviewMode.type, setDashboardOverviewMode),
    ])
}
