import React, { useState } from 'react'
import produce from 'immer'
import { OnTriggerAutoCompletion } from '../../../Atoms/FieldFilterInput/FieldFilterInput'
import NumberPicker from '../../../Atoms/NumberPicker/NumberPicker'
import Select from '../../../Atoms/Select/Select'
import FormFieldWithLabel from '../../FormFieldWithLabel/FormFieldWithLabel'

type Value = {
    jiraProjectBoard: string
    numberOfSprints: number
    capacityField: string
}

const JiraQuotaEditor: React.FunctionComponent<{
    value: Value
    onChange: (value: Value) => void
    onTriggerAutoCompletion: OnTriggerAutoCompletion
}> = (props) => {
    const [options, setOptions] = useState<Array<unknown>>([])

    const buildValueFromNameString = (value: string) => {
        return { label: value, value }
    }

    const handleFocusedInput = (current: string) => {
        return () => {
            props.onTriggerAutoCompletion('projectBoardName', '', [current], handleReceivedNewSuggestions)
        }
    }

    const handleReceivedNewSuggestions = (values: Array<string>) => {
        if (!values.includes(props.value.jiraProjectBoard)) {
            values = [props.value.jiraProjectBoard].concat(values)
        }

        setOptions(values.map((e) => ({ value: e, label: e })))
    }

    const handleInputChange = (current: string) => {
        return (queryString: string) => {
            props.onTriggerAutoCompletion('projectBoardName', queryString, [current], handleReceivedNewSuggestions)
        }
    }

    const handleBoardChanged = (newValue: any) => {
        props.onChange(
            produce(props.value, (draft) => {
                draft.jiraProjectBoard = newValue
            })
        )
    }

    const handleNumberOfSprintsChanged = (newValue: number | null) => {
        props.onChange(
            produce(props.value, (draft) => {
                draft.numberOfSprints = newValue || 0
            })
        )
    }

    // This widget is exclusively used in JIRA context, and this fields are always present in JIRA context (set in type-config)
    // Therefore it's okay to hard code them here & this list is not very likely to get changed & and we can choose some nice
    // and easy names for the UI here
    const capacityFieldOptions = [
        {
            value: 'sprintOriginalEstimate',
            label: 'Hours',
        },
        {
            value: 'sprintStoryPoints',
            label: 'Story Points',
        },
    ]

    const handleCapacityFieldChange = (newValue: any) => {
        props.onChange(
            produce(props.value, (draft) => {
                draft.capacityField = newValue
            })
        )
    }

    return (
        <div className="jira-quota-editor">
            <FormFieldWithLabel
                configuration={{
                    label: 'Board',
                    editor: Select,
                    helpTooltip: 'widgetEditorProjectBoard',
                    clearable: false,
                    value: buildValueFromNameString(props.value.jiraProjectBoard),
                    isValid: Boolean(props.value.jiraProjectBoard),
                    options,
                    onChange: handleBoardChanged,
                    onFocus: handleFocusedInput(props.value.jiraProjectBoard),
                    onInputChange: handleInputChange(props.value.jiraProjectBoard),
                }}
            />
            <div className="jira-quota-editor_number-picker">
                <NumberPicker
                    onChange={handleNumberOfSprintsChanged}
                    min={1}
                    max={100}
                    step={1}
                    value={props.value.numberOfSprints}
                    label={'Number of Sprints'}
                    helpToolTip="widgetEditorNumberOfSprints"
                />
            </div>
            <div className="jira-quota-editor_estimation-unit">
                <label>Estimation Unit</label>
                <Select
                    isClearable={false}
                    value={props.value.capacityField}
                    isValid={Boolean(props.value.capacityField)}
                    options={capacityFieldOptions}
                    onChange={handleCapacityFieldChange}
                />
            </div>
            <hr />
        </div>
    )
}

export default JiraQuotaEditor
