import React, { memo } from 'react'
import DashboardListItem from './DashboardListViewItem'
import { DashboardId } from '../../../Redux/Data/types'

type Props = {
    dashboardIds: Array<DashboardId>
}

const DashboardListView = (props: Props) => {
    return (
        <div className={'list dashboard-overview--list'}>
            {props.dashboardIds.map((dashboardId) => (
                <DashboardListItem key={dashboardId} dashboardId={dashboardId} />
            ))}
        </div>
    )
}

export default memo(DashboardListView)
