import React from 'react'
import classnames from 'classnames'
import { Mode } from '../../Organisms/Widgets/WidgetModel'
import Select from '../../Atoms/Select/Select'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

const ModeEditor: React.FunctionComponent<{
    value?: Mode
    onChange: (value: Mode) => void
    onlySum?: boolean
}> = (props) => {
    const options = props.onlySum
        ? [
              {
                  value: 'sum',
                  label: 'Sum',
              },
          ]
        : [
              {
                  value: 'sum',
                  label: 'Sum',
              },
              {
                  value: 'min',
                  label: 'Min',
              },
              {
                  value: 'max',
                  label: 'Max',
              },
              {
                  value: 'avg',
                  label: 'Avg',
              },
          ]

    const className = classnames('mode-editor', 'calculated-field--token-editor', {
        'mode-editor--disabled': props.onlySum,
    })

    return (
        <div className={className}>
            <HoverTooltip
                isVisible={true}
                header="Definition:"
                content={
                    props.onlySum
                        ? ['Sum = Result of Summation', 'Min, Max, Avg are not available in Sunburst Chart']
                        : [
                              'Sum = Result of Summation',
                              'Min = Minimum',
                              'Max = Maximum',
                              'Avg = Average / Arithmetic Mean',
                          ]
                }
            >
                <Select
                    onChange={props.onChange}
                    isClearable={false}
                    options={options}
                    value={props.value}
                    placeholder="Mode..."
                    isDisabled={props.onlySum}
                    smallChevron
                />
                <div className="mode-editor--concave" />
            </HoverTooltip>
        </div>
    )
}

export default React.memo(ModeEditor)
