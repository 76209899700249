import { all, put, select, takeLatest } from 'redux-saga/effects'
import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { actions, selectors } from '../Redux'
import { ModalId } from '../StaticManifests/manifest.modals'

type ResultPayload = {
    state: 'confirm' | 'deny' | 'cancel'
    id: ModalId
}

export const handleModalResultAction = createAction('Sagas/handleModalResult', (result: ResultPayload) => ({
    payload: { ...result },
}))

function* handleModalResult(action: PayloadAction<ResultPayload>) {
    const currentModal = selectors.UI.Modal.currentModal(yield select())

    if (currentModal) {
        const preventModalHide = currentModal.parameters?.preventHideModal

        if (!preventModalHide) {
            yield put(actions.UI.Modal.hide())
        }

        const state = action.payload.state
        const { confirmationCallback, denyCallback, cancelCallback } = currentModal

        switch (state) {
            case 'confirm':
                if (confirmationCallback) {
                    confirmationCallback(action.payload)
                }

                break
            case 'deny':
                if (denyCallback) {
                    denyCallback()
                }

                break
            case 'cancel':
                if (cancelCallback) {
                    cancelCallback()
                }

                break
            default:
                console.error('unknown state', state)
        }
    }
}

export default function* ConfirmationModalSaga() {
    yield all([takeLatest(handleModalResultAction.type, handleModalResult)])
}
