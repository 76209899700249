import { getDefaultAnalysisTermWithField } from '../DefaultConfigurations'
import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

type Mode = 'area' | 'bar' | 'line'

const defaultChartGroup = (mode?: Mode) => ({
    yPivotField: 'projectName' as string | undefined,
    valueField: {
        analysisTerm: getDefaultAnalysisTermWithField('sumTime'),
        filter: [] as Array<FieldType>,
        dateField: 'primaryDate',
    },
    mode,
    maximumNumberOfStacks: 10,
    isStacked: true,
    showDots: false,
    trendLineEntry: {
        showTrendLine: false,
        trendLineColor: '#ff0000', // must be long notation to work
    },
    interpolate: mode === 'line',
    type: 'linear',
    yAxis: 'left',
    sumUpAggregation: false,
})

const newDefaultConfig = (mode?: Mode) => ({
    numberOfDecimals: 2,
    labels: {
        unitLeft: 'h',
    },
    showGrid: true,
    chartGroups: [defaultChartGroup(mode)],
    referenceLines: [],
    stackUpValues: false,
})

export const getDefaultTitle = (type?: Mode | string) => {
    switch (type) {
        case 'bar':
            return 'Bar - Time Series Chart'
        case 'area':
            return 'Area - Time Series Chart'
        case 'line':
            return 'Line - Time Series Chart'
        default:
            return 'Multiple Value - Time Series Chart'
    }
}

const createDefaultConfiguration = (mode?: Mode) => (priorConfig?: Record<string, any>) => {
    const defaultConfig = newDefaultConfig(mode || 'bar')
    if (priorConfig && priorConfig.chartGroups) {
        const firstChartGroup = priorConfig.chartGroups[0]
        const sanitizedChartGroup = firstChartGroup.valueField.dateField
            ? firstChartGroup
            : { ...firstChartGroup, valueField: { ...firstChartGroup.valueField, dateField: 'primaryDate' } }
        return {
            __alreadyMerged: true, // this is a HACK: the old config merge strategy does not work here, but refactoring has yet to be done, see WidgetEditModeSaga.js
            numberOfDecimals: priorConfig.numberOfDecimals || defaultConfig.numberOfDecimals,
            title: getDefaultTitle(mode),
            chartGroups: [
                {
                    ...sanitizedChartGroup,
                    trendLineEntry: sanitizedChartGroup.trendLineEntry || defaultConfig.chartGroups[0].trendLineEntry,
                    mode: mode || 'bar',
                },
            ],
            referenceLines: priorConfig.referenceLines || defaultConfig.referenceLines,
        }
    }

    return {
        ...defaultConfig,
        title: getDefaultTitle(mode),
    }
}

export default createDefaultConfiguration

const getMode = (mode: Mode | undefined, counter: number) => {
    if (mode) {
        return mode
    }

    switch (counter % 3) {
        case 0:
            return 'bar'
        case 1:
            return 'area'
        default:
            return 'line'
    }
}

export const TimeSeriesChartConfigurationInitializerForWidgetExplorer =
    (mode?: Mode) =>
    ({
        valueFields,
        categoryFields,
        dateFields,
        filters,
    }: {
        valueFields: Array<string>
        categoryFields: Array<string | undefined>
        dateFields: Array<string>
        filters: Array<FieldType>
    }) => {
        const defaultConfiguration = createDefaultConfiguration(mode)()

        defaultConfiguration.chartGroups = []

        valueFields.forEach((value) => {
            if (categoryFields.length === 0) {
                categoryFields = [undefined]
            }

            categoryFields.forEach((category, index) =>
                defaultConfiguration.chartGroups.push({
                    ...defaultChartGroup(getMode(mode, index)),
                    valueField: {
                        analysisTerm: getDefaultAnalysisTermWithField(value),
                        filter: filters,
                        dateField: dateFields[0],
                    },
                    yPivotField: category,
                })
            )
        })

        return defaultConfiguration
    }
