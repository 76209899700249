import React, { useContext } from 'react'

import Select from '../../Atoms/Select/Select'
import { FieldsConfigurationContext } from '../../Atoms/FieldsConfiguration/FieldsConfigurationContext'

const valueOf = (field: string, direction: string) => `${field}::${direction}`
const labelOf = (label: string, direction: string) => `${label} (${direction})`

const OrderBySelector: React.FunctionComponent<{
    columns: Array<{
        field: string
    }>
    orderBy: Array<{
        field: string
        direction: 'asc' | 'desc'
    }>
    onChange: (value: any) => void
}> = (props) => {
    const fieldsConfiguration = useContext(FieldsConfigurationContext)

    const handleSelectionChanged = (selection: Array<string> | null) => {
        if (selection === null) {
            return props.onChange([])
        }

        const reverseOrderByWithDuplicates = selection.reverse().map((value) => {
            const [field, direction] = value.split('::', 2)
            return { field, direction }
        })
        const alreadyUsedFields: Array<string> = []

        const orderBy = reverseOrderByWithDuplicates
            .filter((item) => {
                if (alreadyUsedFields.includes(item.field)) {
                    return false
                }

                alreadyUsedFields.push(item.field)
                return true
            })
            .reverse()

        props.onChange(orderBy)
    }

    const { columns, orderBy } = props
    const columnFields = columns.map((column) => column.field)
    const optionFields = Object.values(fieldsConfiguration)
        // only visible columns
        .filter((field: any) => columnFields.includes(field.name))
        // order by user column order
        .sort((field1: any, field2: any) => columnFields.indexOf(field1.name) - columnFields.indexOf(field2.name))
    // cannot use .flatMap here since not supported in storybook
    const options: Array<{ value: string; label: string }> = []
    optionFields.forEach((field) => {
        options.push({ value: valueOf(field.name, 'desc'), label: labelOf(field.label, 'desc') })
        options.push({ value: valueOf(field.name, 'asc'), label: labelOf(field.label, 'asc') })
    })

    const selection = orderBy.map((item) => valueOf(item.field, item.direction))
    return (
        <Select
            className="order-by-selector"
            isMulti
            isClearable
            value={selection}
            options={options}
            onChange={handleSelectionChanged}
            placeholder="please select ordering"
        />
    )
}

export default OrderBySelector
