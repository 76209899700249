import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import { SizeMe } from 'react-sizeme'
import classnames from 'classnames'

import { actions, selectors } from '../../../Redux'
import FloatingActionButton from '../../../Components/Atoms/FloatingActionButton/FloatingActionButton'
import Button from '../../../Components/Atoms/Button/Button'
import WidgetProblemState, { widgetProblems } from '../../../Components/Atoms/WidgetProblemState'
import { WidgetErrorBoundary } from './WidgetErrorBoundary'
import { RootState } from '../../../Redux/types'
import { WidgetId } from '../../../Redux/Data/types'
import { AppDispatch } from '../../../store'
import { WidgetHeaderFunctions } from '../../../Components/Molecules/WidgetHeader/WidgetHeader'
import { noDataWidgetTypes } from '../../../Components/Organisms/Widgets/WidgetModel'
// eslint-disable-next-line import/extensions
import { ModalId } from '../../../StaticManifests/manifest.modals'
import widgetTypes from '../../../StaticManifests/manifest.widgetTypes'
import { requestSideDataAction } from '../../../Sagas/DashboardSagas/WidgetsDataSaga'
import { startDuplicateWidget } from '../../../Sagas/DashboardSagas/DuplicateWidgetSaga'
import HoverTooltip from '../../../Components/Molecules/HoverTooltip/HoverTooltip'

type WidgetOwnProps = {
    widgetId: WidgetId
    isPreview?: boolean
    hideEditModeControls?: boolean
}

const mapStateToProps = (state: RootState, ownProps: WidgetOwnProps) => ({
    widgetConfiguration: selectors.Data.Dashboards.widgetById(state, ownProps.widgetId),
    // request
    widgetState: selectors.Data.Dashboards.ViewMode.widgetStateForWidget(ownProps.widgetId)(state),
    // response
    widgetData: selectors.Data.Dashboards.ViewMode.widgetDataForWidget(ownProps.widgetId)(state),

    loadingState: selectors.Data.Dashboards.ViewMode.dashboardLoadingState(state),
    inEditMode: selectors.Data.Dashboards.DashboardEditMode.isDashboardEditModeActive(state),

    userSettings: selectors.UI.CurrentUser.settings(state),
    hasJiraConfiguration: selectors.Data.FeatureFlags.jiraConfigurationFlag(state),
    importerStatusByUuid: selectors.Data.Importer.importerStatusByUuid(state),

    currentlyEditedWidgetId: selectors.Data.Dashboards.WidgetEditMode.currentlyEditedWidgetId(state),
    usesDarkMode: selectors.UI.CurrentUser.usesDarkMode(state),
    dashboardId: selectors.Data.Dashboards.ViewMode.dashboardRequest(state)?.dashboard,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    updateWidgetState: (widgetId: WidgetId, widgetStateChanges: any) =>
        dispatch(actions.Data.Dashboards.ViewMode.updateWidgetState({ widgetId, widgetStateChanges })),
    requestSideData: (widgetUuid: WidgetId, endpoint: string, parameters: any, callback: any) =>
        dispatch(requestSideDataAction(widgetUuid, endpoint, parameters, callback)),
    startEditingWidget: (widgetUuid: WidgetId) =>
        dispatch(actions.Data.Dashboards.WidgetEditMode.startEditingWidget(widgetUuid)),
    deleteWidget: (uuid: WidgetId) => dispatch(actions.Data.Dashboards.WidgetEditMode.deleteWidgetById(uuid)),
    onDuplicateWidget: (widgetId: WidgetId) => dispatch(startDuplicateWidget({ widgetId })),
    openModal: ({
        modalId,
        parameters,
        confirmationCallback,
        denyCallback,
        cancelCallback,
    }: {
        modalId: ModalId
        parameters: any
        confirmationCallback: any
        denyCallback: any
        cancelCallback: any
    }) =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId,
                parameters,
                confirmationCallback,
                denyCallback,
                cancelCallback,
            })
        ),
    triggerAutoCompletion: (type: any, value: any, exclude: any, callback: any) =>
        dispatch(actions.UI.Autocompletion.fetch(type, value, exclude, callback)),
})

type WidgetReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type WidgetProps = WidgetOwnProps & WidgetReduxProps & WidgetHeaderFunctions

type Size = { height: number | null; width: number | null }

type WidgetState = {
    showWidgetRemovalConfirmation: boolean
}

class Widget extends PureComponent<WidgetProps, WidgetState> {
    constructor(props: WidgetProps) {
        super(props)

        this.state = {
            // before deleting a widget, we ask again if the user is sure about that
            showWidgetRemovalConfirmation: false,
        }

        this.widgetRef = React.createRef()
    }

    widgetRef: React.RefObject<HTMLDivElement>

    componentDidMount() {
        if (this.props.isPreview && this.props.currentlyEditedWidgetId === this.props.widgetId) {
            // need timeout for DOM to build
            setTimeout(
                () =>
                    this.widgetRef.current?.scrollIntoView({
                        behavior: 'smooth',
                    }),
                500
            )
        }
    }

    componentDidUpdate(prevProps: Readonly<WidgetProps>, prevState: Readonly<WidgetState>) {
        // remove "remove widget?" overlay when leaving dashboard-edit-mode
        if (prevProps.inEditMode && !this.props.inEditMode && prevState.showWidgetRemovalConfirmation) {
            this.setState({ showWidgetRemovalConfirmation: false })
        }
    }

    handleEditButtonClick = () => this.props.startEditingWidget(this.props.widgetId)

    handleRemoveButtonClick = () => this.props.deleteWidget(this.props.widgetId)

    handleDuplicateWidget = () => this.props.onDuplicateWidget(this.props.widgetId)

    handleSetWidgetState = (widgetStateChanges: any) =>
        this.props.updateWidgetState(this.props.widgetId, widgetStateChanges)

    handleRequestSideData = (endpoint: string, parameters: any, callback: any) => {
        // we do not want to load side data when editing the widget
        if (!this.props.isPreview) {
            return this.props.requestSideData(this.props.widgetId, endpoint, parameters, callback)
        }
    }

    handleCancelRemoval = () => this.setState({ showWidgetRemovalConfirmation: false })

    handleRemoveWidget = () => this.setState({ showWidgetRemovalConfirmation: true })

    render() {
        const className = classnames('widget-container', {
            'widget-container--highlighted':
                this.props.isPreview && this.props.currentlyEditedWidgetId === this.props.widgetId,
        })

        return (
            <SizeMe monitorHeight>
                {({ size }) => (
                    <div className={className} ref={this.widgetRef}>
                        <LoadingOverlay
                            text={this.renderRemovalConfirmationContent(size)}
                            active={this.state.showWidgetRemovalConfirmation}
                            classNamePrefix="remove-widget__overlay__"
                            fadeSpeed={250}
                        >
                            <WidgetErrorBoundary size={size}>{this.renderWidget()}</WidgetErrorBoundary>
                            {this.renderEditModeControls(size)}
                        </LoadingOverlay>
                    </div>
                )}
            </SizeMe>
        )
    }

    hasPermissions = () => {
        const requiredPermissions = widgetTypes[this.props.widgetConfiguration.type].requiredPermissions
        return (
            !requiredPermissions ||
            requiredPermissions.some((permission) => this.props.userSettings && this.props.userSettings[permission])
        )
    }

    renderWidget = () => {
        const widgetType = this.props.widgetConfiguration.type
        const widgetTypeDefinition = widgetType && widgetTypes[widgetType]
        const widgetConfiguration = this.props.widgetConfiguration?.configuration
        const widgetData = this.props.widgetData?.data
        const widgetHasFailed = this.props.widgetData?.hasFailed
        const title = widgetConfiguration?.title

        // unknown widgetType
        if (!widgetTypeDefinition) {
            return this.renderWidgetProblemState('noSuchWidget', title)
        }

        // The configuration is not (yet) correct, so the widget can give a hint what has to be done.
        const configurationError = widgetTypeDefinition.configurationValidations
            ? widgetTypeDefinition.configurationValidations(widgetConfiguration)
            : null
        if (configurationError) {
            return this.renderWidgetProblemState(configurationError, title)
        }

        // probably broken config, NOTE: if the widget fails in backend, the custom widget renderRequirement Rules are not taken
        // into account
        if (widgetHasFailed) {
            return this.renderWidgetProblemState(this.props.widgetData?.displayErrorMessage ?? undefined, title)
        }

        // render logic of the widget itself, e.g. custom conditions for no widget data. Is implemented in the widget manifests.
        const problem = widgetTypeDefinition.renderRequirements
            ? widgetTypeDefinition.renderRequirements(widgetData)
            : false
        if (problem) {
            return this.renderWidgetProblemState(problem, title)
        }

        if (!this.hasPermissions()) {
            return this.renderWidgetProblemState(widgetProblems.noPermissions, title)
        }

        // no widget Data
        if (widgetType && !noDataWidgetTypes.includes(widgetType) && !widgetData) {
            return this.renderWidgetProblemState(
                this.props.loadingState !== 'SUCCESS' ? this.props.loadingState : widgetProblems.noData,
                title
            )
        }

        const WidgetComponent = widgetTypeDefinition.component

        return (
            <WidgetComponent
                widgetType={widgetType}
                currentDashboardUuid={this.props.dashboardId}
                widgetConfiguration={widgetConfiguration}
                widgetState={this.props.widgetState}
                widgetData={widgetData}
                loadingState={this.props.loadingState}
                userSettings={this.props.userSettings}
                hasJiraConfiguration={this.props.hasJiraConfiguration}
                importerStatusByUuid={this.props.importerStatusByUuid}
                setWidgetState={this.handleSetWidgetState}
                requestSideData={this.handleRequestSideData}
                openModal={this.props.openModal}
                onTriggerAutoCompletion={this.props.triggerAutoCompletion}
                inEditMode={this.props.inEditMode}
                onChangeTitle={this.props.onChangeTitle}
                usesDarkMode={this.props.usesDarkMode}
            />
        )
    }

    renderWidgetProblemState = (problem?: string, title?: string) => (
        <WidgetProblemState
            problem={problem}
            title={title}
            onChangeTitle={this.props.onChangeTitle}
            isJira={this.props.hasJiraConfiguration}
        />
    )

    renderEditModeControls = (widgetSize: Size) => {
        if (this.state.showWidgetRemovalConfirmation || !this.props.inEditMode || this.props.hideEditModeControls) {
            return null
        }

        const hasPermissions = this.hasPermissions()

        const iconSize = this.getIconSize(widgetSize)
        return (
            <div className="widget-container__edit-overlay widget-container__edit-controls__tooltip">
                <div className="widget-container__edit-controls">
                    <HoverTooltip isVisible={!hasPermissions} header={"You don't have permission to edit this widget."}>
                        <FloatingActionButton
                            disabled={!hasPermissions}
                            iconName="PenIcon"
                            size={iconSize}
                            onClick={this.handleEditButtonClick}
                        />
                    </HoverTooltip>
                    <FloatingActionButton
                        iconName="ClipboardIcon"
                        size={iconSize}
                        onClick={this.handleDuplicateWidget}
                    />
                    <FloatingActionButton iconName="TrashIcon" size={iconSize} onClick={this.handleRemoveWidget} />
                </div>
            </div>
        )
    }

    getIconSize = ({ height, width }: Size) => {
        if (height && width) {
            if (height < 40 || width < 120) {
                return 'small'
            }

            if (height < 128 || width < 160) {
                return undefined
            }

            return 'big'
        }

        return undefined
    }

    renderRemovalConfirmationContent = (widgetSize: Size) => {
        const useButtons = widgetSize.height && widgetSize.height >= 128 && widgetSize.width && widgetSize.width > 360
        return (
            <div className="removal-confirmation--body">
                {useButtons ? (
                    <React.Fragment>
                        <div className="removal-confirmation--header">
                            <h4 className="removal-confirmation--header">
                                Are you sure you want to remove this widget?
                            </h4>
                        </div>
                    </React.Fragment>
                ) : (
                    ''
                )}
                <div className="removal-confirmation__actions">
                    {useButtons ? (
                        <React.Fragment>
                            <Button onClick={this.handleCancelRemoval}>Cancel</Button>
                            <Button onClick={this.handleRemoveButtonClick} type="warning">
                                Remove
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <FloatingActionButton
                                iconName="TimesIcon"
                                iconColor="green"
                                size={this.getIconSize(widgetSize)}
                                onClick={this.handleCancelRemoval}
                            />
                            <FloatingActionButton
                                iconName="TrashIcon"
                                iconColor="error"
                                size={this.getIconSize(widgetSize)}
                                onClick={this.handleRemoveButtonClick}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget)
