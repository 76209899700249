import React from 'react'

const WidgetIcon: React.FunctionComponent<{
    // this was copied from frontend-app-react/node_modules/react-scripts/lib/react-app.d.ts line 45
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>
}> = (props) => {
    const IconComponent = props.icon

    return (
        <div className="widget-icon">
            <IconComponent width="100%" height="100%" />
        </div>
    )
}

export default WidgetIcon
