import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Collapse } from 'react-collapse'
import classnames from 'classnames'
import uuid from 'uuid'
import Button from '../../../Components/Atoms/Button/Button'
import WidgetGroup, { AddWidgetGroupButton } from '../WidgetGroup/WidgetGroup'
import WidgetEditor from '../WidgetEditor/WidgetEditor'
import { DashboardId } from '../../../Redux/Data/types'
import LoaderOverlay from '../../../Components/Atoms/LoaderOverlay/LoaderOverlay'
import { RootState } from '../../../Redux/types'
import { actions, selectors } from '../../../Redux'
import { AppDispatch } from '../../../store'

type WidgetGroupsAreaOwnProps = {
    className?: string
    dashboardId: DashboardId
    isPreview?: boolean
}

const mapStateToProps = (state: RootState, ownProps: WidgetGroupsAreaOwnProps) => ({
    widgetGroupIds: selectors.Data.Dashboards.ViewMode.widgetGroupIdsSortedByPosition(state, ownProps.dashboardId),
    currentlyEditedWidgetId: selectors.Data.Dashboards.WidgetEditMode.currentlyEditedWidgetId(state),
    loadingState: selectors.Data.Dashboards.ViewMode.dashboardLoadingState(state),
    inEditMode: selectors.Data.Dashboards.DashboardEditMode.isDashboardEditModeActive(state),
    dashboardHasWidgets: selectors.Data.Dashboards.ViewMode.dashboardHasWidgets(state, ownProps.dashboardId),
})

const mapDispatchToPros = (dispatch: AppDispatch) => ({
    enableEditMode: (dashboardId: DashboardId) =>
        dispatch(actions.Data.Dashboards.DashboardEditMode.enableEditMode(dashboardId)),
    addNewWidgetGroup: () => {
        const groupId = uuid()
        dispatch(actions.Data.Dashboards.WidgetGroupEditMode.addWidgetGroup({ idOfNewGroup: groupId }))
        dispatch(actions.Data.Dashboards.WidgetEditMode.addInitialTimeSelectorWidget(groupId))
    },
})

type WidgetGroupsAreaState = {
    scrollPosition?: number
    widgetGroupWithOpenDropdown?: string
}

type WidgetGroupsAreaProps = WidgetGroupsAreaOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToPros>

/*
 * isPreview: the widgetGroupArea is used in the normal dashboard as well as preview in the WidgetEditor (you can
 * switch between single-widget and dashboard preview). Since the component tree looks like:
 * WidgetGroupArea > WidgetEditor > WidgetGroupArea, this flag avoids recursive rendering and some optional rendering
 */
class WidgetGroupArea extends PureComponent<WidgetGroupsAreaProps, WidgetGroupsAreaState> {
    constructor(props: WidgetGroupsAreaProps) {
        super(props)

        this.state = {}
    }

    render() {
        const { currentlyEditedWidgetId, dashboardHasWidgets, inEditMode, loadingState } = this.props
        const className = classnames('dashboard', this.props.className, {
            'dashboard--no-scroll': currentlyEditedWidgetId !== undefined && !this.props.isPreview,
        })

        return (
            <div className={className}>
                {<LoaderOverlay loadingState={loadingState} />}
                {!dashboardHasWidgets && !this.props.inEditMode
                    ? this.renderNoWidgetsWarning()
                    : this.renderWidgetGroups()}
                {!this.props.isPreview && (
                    <Collapse isOpened={inEditMode}>
                        <footer className="dashboard__add-widget-group">
                            <AddWidgetGroupButton onClick={this.props.addNewWidgetGroup} />
                        </footer>
                    </Collapse>
                )}

                {!this.props.isPreview && this.props.currentlyEditedWidgetId && (
                    <WidgetEditor dashboardId={this.props.dashboardId} />
                )}
            </div>
        )
    }

    handleEnableEditMode = () => this.props.enableEditMode(this.props.dashboardId)

    renderNoWidgetsWarning() {
        return (
            <div className="dashboard__empty">
                <p>You currently have no widgets. Add some!</p>
                <Button type="primary" onClick={this.handleEnableEditMode}>
                    Start editing
                </Button>
            </div>
        )
    }

    renderWidgetGroups() {
        return (
            <div className="widget-groups">
                {this.props.widgetGroupIds.map((widgetGroupId) => {
                    return (
                        <div id={'widget-group-' + widgetGroupId} key={widgetGroupId}>
                            <WidgetGroup
                                key={widgetGroupId}
                                widgetGroupId={widgetGroupId}
                                dashboardId={this.props.dashboardId}
                                isPreview={this.props.isPreview}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }

    getSnapshotBeforeUpdate(prevProps: WidgetGroupsAreaProps): any | null {
        if (prevProps.currentlyEditedWidgetId === undefined && this.props.currentlyEditedWidgetId !== undefined) {
            return window.scrollY
        }

        return null
    }

    componentDidUpdate(
        prevProps: Readonly<WidgetGroupsAreaProps>,
        prevState: Readonly<WidgetGroupsAreaState>,
        snapshot?: any
    ) {
        if (snapshot !== null) {
            this.setState({
                scrollPosition: snapshot,
            })
        }
        if (
            prevProps.currentlyEditedWidgetId !== undefined &&
            this.props.currentlyEditedWidgetId === undefined &&
            this.state.scrollPosition
        ) {
            window.scrollTo(0, this.state.scrollPosition)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToPros)(WidgetGroupArea)
