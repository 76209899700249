import React, { memo } from 'react'
import { connect } from 'react-redux'
import ContextMenu, { ContextMenuItem } from '../../Atoms/ContextMenu/ContextMenu'
import Icon from '../../Atoms/Icon/Icon'
import { RootState } from '../../../Redux/types'
import { actions, selectors } from '../../../Redux'
import { AppDispatch } from '../../../store'
import { duplicateDashboard } from '../../../Sagas/DashboardSagas/DuplicateDashboardSaga'
import { DashboardId } from '../../../Redux/Data/types'
import { downloadDashboardConfigurationAction } from '../../../Sagas/DashboardSagas/DownloadDashboardConfigurationSaga'
import { DashboardGroupId } from '../../../Redux/UI/types'
import { ALL_DASHBOARDS, FAVOURITE_DASHOARDS } from '../../../Redux/UI/DashboardGroups'

type OwnProps = {
    dashboardId: DashboardId
    handleShowDeleteConfirmation: () => void
    dashboardGroupId?: DashboardGroupId
}

const mapStateToProps = (state: RootState) => ({
    canManageDashboards: selectors.UI.CurrentUser.settings(state)?.canManageDashboards ?? false,
})

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
    handleUploadImage: () => dispatch(actions.UI.Modal.show('dashboardIcon', { dashboardId: ownProps.dashboardId })),
    handleDuplicateDashboard: () =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'dashboardDuplicate',
                parameters: {
                    showNavigate: false,
                },
                confirmationCallback: ({ dashboardName }: { dashboardName: string }) => {
                    dispatch(duplicateDashboard(ownProps.dashboardId, dashboardName, false, ownProps.dashboardGroupId))
                },
            })
        ),
    handleDownloadDashboardConfiguration: () => dispatch(downloadDashboardConfigurationAction(ownProps.dashboardId)),
    handleUngroupDashboard: () =>
        ownProps.dashboardGroupId &&
        dispatch(
            actions.UI.DashboardGroups.removeDashboardFromGroup({
                groupId: ownProps.dashboardGroupId,
                dashboardId: ownProps.dashboardId,
            })
        ),
    onShareDashboard: () =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'dashboardShare',
                parameters: {
                    dashboardId: ownProps.dashboardId,
                },
            })
        ),
})

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const DashboardContextButtons = (props: Props) => {
    if (!props.canManageDashboards) {
        return null
    }

    return (
        <ContextMenu size="small" menuNamePrefix="dashboard-overview-">
            <ContextMenuItem key={`${props.dashboardId}-set-image`} onClick={props.handleUploadImage}>
                <Icon name="ImageIcon" size="small" /> Set Image
            </ContextMenuItem>
            <ContextMenuItem key={`${props.dashboardId}-duplicate`} onClick={props.handleDuplicateDashboard}>
                <Icon name="ClipboardIcon" size="small" /> Duplicate
            </ContextMenuItem>
            <ContextMenuItem onClick={props.onShareDashboard}>
                <Icon name={'ShareIcon'} size="small" position="left" title="share dashboard" />
                Share
            </ContextMenuItem>
            <ContextMenuItem
                key={`${props.dashboardId}-download-config`}
                onClick={props.handleDownloadDashboardConfiguration}
            >
                <Icon name="DownloadIcon" size="small" /> Configuration
            </ContextMenuItem>
            {props.dashboardGroupId &&
                props.dashboardGroupId !== ALL_DASHBOARDS &&
                props.dashboardGroupId !== FAVOURITE_DASHOARDS && (
                    <ContextMenuItem onClick={props.handleUngroupDashboard} key={`${props.dashboardId}-ungroup`}>
                        <Icon name={'TimesIcon'} /> Ungroup
                    </ContextMenuItem>
                )}
            <ContextMenuItem key={`${props.dashboardId}-remove`} onClick={props.handleShowDeleteConfirmation}>
                <Icon name="TrashIcon" size="small" /> Delete
            </ContextMenuItem>
        </ContextMenu>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DashboardContextButtons))
