import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import TextInput from '../../../Atoms/TextInput/TextInput'
import WidgetHeader, { WidgetHeaderFunctions } from '../../../Molecules/WidgetHeader/WidgetHeader'

type SearchBarWidgetProps = {
    widgetState: {
        searchQuery?: string
    }
    setWidgetState: (...args: any[]) => any
    widgetConfiguration: {
        title?: string
        placeholder?: string
    }
} & WidgetHeaderFunctions

const SearchBarWidget: React.FC<SearchBarWidgetProps> = (props) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        if (value.length > 0 && props.widgetState.searchQuery && props.widgetState.searchQuery.length === 0) {
            setValue('')
        }
    }, [value.length, props.widgetState])

    const handleChange = (value: string) => {
        setValue(value)
        props.setWidgetState({ searchQuery: value })
    }

    const title = props.widgetConfiguration.title
    const placeholder = props.widgetConfiguration.placeholder
    const className = classnames('widget', {
        'widget--has-header': title || props.onChangeTitle,
    })
    return (
        <div className={className}>
            <WidgetHeader title={title} onChangeTitle={props.onChangeTitle} />
            <div className="search-bar-widget">
                <TextInput value={value} placeholder={placeholder} onChange={handleChange} />
            </div>
        </div>
    )
}

SearchBarWidget.displayName = 'SearchBar'

export default React.memo(SearchBarWidget)
