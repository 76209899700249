import React, { useState } from 'react'
import ContextMenu, { ContextMenuItem } from '../../../Components/Atoms/ContextMenu/ContextMenu'
import Icon from '../../../Components/Atoms/Icon/Icon'
import { DashboardActions, DashboardContextMenuProps, ExportTypes } from './DashboardContextMenu.types'

const DashboardContextMenu: React.FC<DashboardContextMenuProps> = (props) => {
    const [isExportPending, setIsExportPending] = useState(false)
    const handleDashboardExport = (format: ExportTypes) => (): void => {
        setIsExportPending(true)
        props.onDashboardExport(format, () => setIsExportPending(false))
    }

    return (
        <ContextMenu menuNamePrefix="secondary-header__">
            {props.hasAction(DashboardActions.EXPORT) && (
                <ContextMenuItem disabled={isExportPending} onClick={handleDashboardExport(ExportTypes.xlsx)}>
                    <Icon name="DownloadIcon" size="small" position="left" title="export dashboard as spreadsheet" />
                    Export {isExportPending ? 'running' : ''}
                </ContextMenuItem>
            )}
            {props.hasAction(DashboardActions.DUPLICATE) && (
                <ContextMenuItem onClick={props.onDashboardDuplicate}>
                    <Icon name="ClipboardIcon" size="small" position="left" title="duplicate dashboard" /> Duplicate
                </ContextMenuItem>
            )}
            {props.hasAction(DashboardActions.DOWNLOAD_CONFIGURATION) && (
                <ContextMenuItem onClick={props.onDownloadDashboardConfiguration}>
                    <Icon name="DownloadIcon" size="small" position="left" title="download configuration" />
                    Configuration
                </ContextMenuItem>
            )}
            {props.hasAction(DashboardActions.SHARE) && (
                <ContextMenuItem onClick={props.onShareDashboard}>
                    <Icon name={'ShareIcon'} size="small" position="left" title="share dashboard" />
                    Share
                </ContextMenuItem>
            )}
            {/* <ContextMenuItem disabled={isExportPending} onClick={handleDashboardExport('csv')}>
                    <Icon name="download" size="small" position="left" title="export dashboard as CSV file"/> CSV Export (experimental) {isExportPending ? 'running' : null}
                </ContextMenuItem> */}
            {props.hasAction(DashboardActions.DELETE) && (
                <ContextMenuItem onClick={props.onDashboardDeletion}>
                    <Icon name="TrashIcon" size="small" position="left" title="remove dashboard" /> Remove
                </ContextMenuItem>
            )}
        </ContextMenu>
    )
}

export default DashboardContextMenu
