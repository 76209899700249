import React, { PureComponent } from 'react'
import Headline from '../../../Atoms/Headline/Headline'
import Switch from '../../../Atoms/Switch/Switch'
import Button from '../../../Atoms/Button/Button'
import { ModalActions, ModalContent } from '../../../Molecules/Modal/Modal'

export default class DemoModeNotificationModal extends PureComponent<{
    isHidden: boolean
    onIsHiddenChanged: (value: boolean) => void
    onConfirmModal: (payload?: any) => void
    onCancelModal: () => void
}> {
    render() {
        return (
            <ModalContent className="demo-mode-notification-modal">
                <Headline configuration={{ title: 'The Demo does not allow you to save' }} />
                {this.renderMessage()}
                {this.renderHideForOneDay()}
                {this.renderButtons()}
            </ModalContent>
        )
    }

    renderMessage = () => {
        return 'Well done. This is a Demo though so progress is not stored but lost on next refresh.'
    }

    renderHideForOneDay() {
        return (
            <div className="demo-mode-notification-modal__hide-for-one-day">
                <Switch
                    onChange={this.handleToggleIsHidden}
                    value={this.props.isHidden}
                    label="Got it, do not show me again today."
                />
            </div>
        )
    }

    renderButtons() {
        return (
            <ModalActions>
                <Button type="warning" onClick={this.props.onCancelModal}>
                    Reload to start over
                </Button>
                <Button type="primary" onClick={this.handleConfirmation}>
                    Keep exploring the Demo
                </Button>
            </ModalActions>
        )
    }

    handleToggleIsHidden = () => this.props.onIsHiddenChanged(!this.props.isHidden)

    handleConfirmation = () => this.props.onConfirmModal()
}
