import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UiState } from './types'
import { RootState } from '../types'
import { NotificationKey } from '../../StaticManifests/manifest.notifications'
import { actionTypes as system } from '../System'

const notificationSlice = createSlice({
    name: 'UI/Notification',
    initialState: {
        currentNotification: null,
    } as UiState,
    extraReducers: {
        [system.INIT]: (state, action) => {
            const { initialState } = action.payload
            state.switchUserModeEnabled = initialState.data.switchUserModeEnabled
        },
    },
    reducers: {
        show: (state, action: PayloadAction<{ id: NotificationKey; details?: string }>) => {
            const { id, details } = action.payload
            const isDetailsInitiallyOpen = Boolean(details)
            state.currentNotification = { id, details, isDetailsInitiallyOpen }
        },
        dismiss: (state) => {
            state.currentNotification = null
        },
    },
})

export const actions = notificationSlice.actions
export const reducer = notificationSlice.reducer

export type Notification = {
    id: NotificationKey
    isDetailsInitiallyOpen: boolean
    details?: string
    timeout?: number
}

export const selectors = {
    currentNotification: (state: RootState) => state.UI.currentNotification,
    isSwitchUserModeEnabled: (state: RootState) => state.UI.switchUserModeEnabled,
}
