import React from 'react'
import classnames from 'classnames'
import Icon from '../../Atoms/Icon/Icon'

const Paginator: React.FunctionComponent<{
    className?: string
    currentOffset: number
    itemsPerPage: number
    itemsTotal: number
    onOffsetChanged: (value: number) => void
}> = (props) => {
    const lastPage = (() => {
        const { itemsPerPage, itemsTotal } = props
        return Math.ceil(itemsTotal / itemsPerPage)
    })()

    const currentPage = (() => {
        const { currentOffset, itemsPerPage } = props
        return Math.floor(currentOffset / itemsPerPage) + 1
    })()

    const pageChangedHandler = (newPage: number) => () => {
        if (newPage !== currentPage) {
            // reminder: offset it the number of items to skip
            const newOffset = (newPage - 1) * props.itemsPerPage
            props.onOffsetChanged(newOffset)
        }
    }

    const renderButton = (targetPage: number, label: any, key?: any) => {
        const firstPage = 1
        const isDisabled = targetPage < firstPage || targetPage > lastPage
        const className = classnames('paginator__button', {
            'paginator__button--inactive': isDisabled,
            'paginator__button--current': targetPage === currentPage,
        })

        return (
            <button key={key} className={className} onClick={pageChangedHandler(targetPage)} disabled={isDisabled}>
                {label}
            </button>
        )
    }

    const leftMostOption = Math.max(1, Math.min(lastPage - 4, currentPage - 2))
    const className = classnames('paginator', props.className)
    return (
        <div className={className}>
            {renderButton(
                currentPage - 1,
                <Icon name="ChevronIcon" rotation={180} title="previous pages" size="small" />
            )}
            {Array.from({ length: 5 }, (value, key) => key).map((i) =>
                i < lastPage ? renderButton(leftMostOption + i, leftMostOption + i, i) : ''
            )}
            {renderButton(currentPage + 1, <Icon name="ChevronIcon" title="next pages" size="small" />)}
        </div>
    )
}

export default React.memo(Paginator)
