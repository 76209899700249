import React from 'react'
import { FieldsConfiguration } from './FieldsConfigurationTypes'

const emptyConfiguration: FieldsConfiguration = {}

/**
 * The global configuration of existing fields in the system (sumTime, projectName, …) used to be
 * read-only. With the importers and the entire system becoming more flexible it now depends on
 * the configuration of Exply and might change during runtime.
 *
 * In order to propagate these changes from server to clients the field configuration resides in
 * the Redux state (used to be in the global registry).
 * The fields configuration however is an integral part of Exply, used at many different places
 * and only changes when the importer configuration changes.
 *
 * Thus the fields configuration is still accessible via this context.
 */
export const FieldsConfigurationContext = React.createContext(emptyConfiguration)
