import React, { PureComponent } from 'react'
import classnames from 'classnames'
import enhanceWithClickOutside from 'react-click-outside'
import { Tooltip, TooltipProps } from 'react-tippy'

import Button from '../../Atoms/Button/Button'
import Icon from '../../Atoms/Icon/Icon'
import tooltips, { TooltipId } from '../../../StaticManifests/manifest.tooltips'

class HelpTooltip extends PureComponent<
    Props,
    {
        isActive?: boolean
    }
> {
    tooltip: TooltipType

    constructor(props: Props) {
        super(props)
        this.tooltip = tooltips[props.id]

        this.state = {
            isActive: props.isActive,
        }
    }

    render() {
        const className = classnames('tooltipped-area', this.props.className, {
            'tooltipped-area--wrapping-button': this.props.children && this.props.children.type === Button,
        })

        // failsafe if the content of the tooltip was deleted but not all of its usages
        if (this.tooltip === undefined) {
            this.tooltip = {
                header: 'Ooops!',
                content: 'This is not the tooltip you are looking for... Something went wrong',
                position: 'top',
            }
        }

        return this.props.isVisible ? (
            <Tooltip
                html={this.renderTooltipContent()}
                open={this.state.isActive}
                position={this.tooltip.position as TooltipProps['position']}
                offset={this.tooltip.offset}
                distance={this.tooltip.distance || 24}
                className={className}
                theme="light"
                arrow={true}
                interactive={true}
            >
                <Beacon isVisible={!this.state.isActive} onClick={this.handleBeaconClick} />
                {this.props.children}
            </Tooltip>
        ) : (
            this.props.children || null
        )
    }

    renderTooltipContent() {
        return (
            <div className="help-tooltip--body">
                <div className="help-tooltip--header">
                    {this.tooltip?.header && <h4 className="help-tooltip--header">{this.tooltip.header}</h4>}
                    <Icon name="TimesIcon" size={'small'} onClick={this.onCloseTooltip} />
                </div>
                {'content' in this.tooltip ? (
                    <span
                        className="help-tooltip--content"
                        dangerouslySetInnerHTML={{ __html: this.tooltip.content }}
                    />
                ) : null}
            </div>
        )
    }

    onCloseTooltip = () => this.setState({ isActive: false })

    handleBeaconClick = () => this.setState({ isActive: true })

    handleClickOutside = () => this.onCloseTooltip()

    componentDidUpdate = (prevProps: Props) => {
        if (this.props.isActive !== prevProps.isActive) {
            this.setState({ isActive: this.props.isActive })
        }
    }
}

const Beacon: React.FunctionComponent<{
    isVisible: boolean
    onClick: () => void
}> = (props) => {
    if (props.isVisible) {
        return <div onClick={props.onClick} className="help-tooltip-beacon" role="button" />
    }

    return null
}

type Props = {
    className?: string
    id: TooltipId
    isVisible: boolean
    children?: any
    isActive?: boolean
    tooltip?: TooltipType
    onCloseTooltip?: () => void
}

type TooltipType = {
    header?: string
    content: string
    position: string
    offset?: number
    distance?: number
}

export default enhanceWithClickOutside(HelpTooltip)
