import React, { useState } from 'react'
import classnames from 'classnames'
import Dropzone from 'react-dropzone'

import ProgressBar from '../../Atoms/ProgressBar/ProgressBar'
import Icon from '../../Atoms/Icon/Icon'

const PICK = 'pick'
const UPLOADING = 'uploading'
const DONE = 'done'
const FAILED = 'failed'

// this is what the client uploads to the server
export type File = {
    name: string
    path?: string
    // the path on the server
    filePath?: string
    user?: string
    password?: string
}

export type HandleFileUploadProgress = (v: number, m: number) => void
export type HandleFileUploadDone = (result: File | null) => void

export type UploadAction = (
    file: File,
    handleProgress: HandleFileUploadProgress,
    handleDone: HandleFileUploadDone
) => void

const getAccept = (type?: string) => {
    if (type === 'image') {
        return 'image/*,.svg'
    }
    if (type === 'exply') {
        return '.exply'
    }

    return undefined // with undefined, all files are accepted here, and in case of csv filtered later by file name
}

type Progress = {
    value: number
    max: number
}

type ProgressHistory = Array<{
    value: number
    time: number
}>

const FileUpload: React.FunctionComponent<{
    className?: string
    fileType?: 'image' | 'csv' | 'xml' | 'exply'
    fileName?: string
    content?: string | any // element
    contentWhenDragging?: string | any // element
    onStartUpload: UploadAction
    onChange: (result: File) => void // fun(false || uploadResponse.data)
    onTypeNotSupported?: () => void
    uploadSuccessful?: boolean
    // only for stories
    idOverride?: string
    step?: string
    progress?: Progress
    progressHistory?: ProgressHistory
}> = (props) => {
    // const [uuid, setUuid] = useState(props.idOverride || UUID());
    const [step, setStep] = useState(props.step || PICK)
    const [progress, setProgress] = useState(props.progress || { value: 0, max: 0 })
    const [progressHistory, setProgressHistory] = useState(props.progressHistory || [])
    const [fileIsUnsupported, setFileIsUnsupported] = useState<boolean>(false)
    const [fileName, setFileName] = useState<string>('')
    const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(true)

    const renderPick = () => {
        const accepted = getAccept(props.fileType)
        return (
            <Dropzone onDrop={handleFileUpload} accept={accepted}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div {...getRootProps()} className={isDragActive ? 'drop-zone drop-zone--dragging' : 'drop-zone'}>
                        <input {...getInputProps()} multiple={false} />
                        {isDragActive ? renderContentWhenDragging() : renderDefaultContent()}
                    </div>
                )}
            </Dropzone>
        )
    }

    const renderDefaultContent = () => {
        if (props.content) {
            return props.content
        }

        if (!uploadSuccessful) {
            return (
                <div className="drop-zone--fail">
                    <Icon
                        name="UploadIcon"
                        size="lg"
                        label="We are sorry, but the file you provided is not supported here."
                    />
                </div>
            )
        }

        if (fileName !== '') {
            return (
                <div className="drop-zone--success">
                    <Icon name="CsvIcon" size="lg" label={fileName} />
                </div>
            )
        }
        return (
            <div>
                <Icon name="UploadIcon" size="lg" label="Upload Your File" />
            </div>
        )
    }

    const renderContentWhenDragging = () => props.contentWhenDragging || <p>Drop File here</p>

    const handleFileUpload = (acceptedFiles: Array<File>, rejectedFiles: Array<File>) => {
        const fileEndingNotSupported =
            props.fileType === 'csv' &&
            acceptedFiles.some((file) => !file.name.endsWith('.csv') && !file.name.endsWith('.CSV'))
        if (rejectedFiles.length > 0 || fileEndingNotSupported) {
            if (props.onTypeNotSupported) {
                props.onTypeNotSupported()
            } else {
                setStep(FAILED)
                setUploadSuccessful(false)
                setFileIsUnsupported(true)
            }

            return
        }

        if (acceptedFiles.length === 1) {
            const file = acceptedFiles[0]

            props.onStartUpload(file, handleProgress, handleDone)
            setStep(UPLOADING)
            setFileIsUnsupported(false)
            setProgress({ value: 0, max: 0 })
            setFileName(file.name)
        }
    }

    const handleProgress = (value: number, max: number) => {
        setStep(UPLOADING)
        setProgress({ value, max })
        setProgressHistory(progressHistory.slice(-5).concat([{ value, time: new Date().getTime() }]))
    }

    const handleDone = (result: File | null) => {
        if (result) {
            setStep(DONE)
            setUploadSuccessful(true)
            props.onChange(result)
            if ('activeElement' in document) {
                ;(document.activeElement as HTMLElement).blur()
            }
        } else {
            setStep(FAILED)
        }
    }

    const renderUploading = () => {
        const { value, max } = progress
        const speed = getAverageUploadBytesPerMs()
        const remaining = speed && (max - value) / speed
        return (
            <React.Fragment>
                <ProgressBar
                    messageLeft={speed ? formatBytesPerMs(speed) : undefined}
                    messageCenter={Boolean(remaining) && formatMs(remaining)}
                    value={value}
                    max={max}
                />
            </React.Fragment>
        )
    }

    const getAverageUploadBytesPerMs = () => {
        const history = progressHistory
        if (history.length < 2) {
            return 0
        }

        const first = history[0]
        const last = history[history.length - 1]
        const deltaBytes = last.value - first.value
        const deltaTime = last.time - first.time
        return deltaBytes / deltaTime
    }

    const formatBytesPerMs = (value: number) => {
        const bytesPerS = value * 1000
        const prefix = ['', 'k', 'M', 'G', 'T', 'P', 'E']
        const power = Math.min(prefix.length - 1, Math.max(0, Math.floor(Math.log(bytesPerS) / Math.log(1024))))
        const divisor = 1024 ** power
        return (bytesPerS / divisor).toFixed(2) + ' ' + prefix[power] + 'B/s'
    }

    const formatMs = (ms: number) => {
        const base = 60
        const s = ms / 1000
        if (s < 5) {
            return '< 5 s'
        }

        const unit = ['s', 'min', 'h']
        const power = Math.min(unit.length - 1, Math.max(0, Math.floor(Math.log(s) / Math.log(base))))
        const divisor = base ** power
        const divisorFraction = power > 0 && base ** (power - 1)
        const fraction = divisorFraction ? Math.round((s % divisor) / divisorFraction) : 0
        return (
            Math.round(s / divisor) + (fraction ? ':' + (fraction < 10 ? '0' : '') + fraction : '') + ' ' + unit[power]
        )
    }

    const renderDone = () => {
        return <ProgressBar value={1} max={1} />
    }

    const renderFailed = () => {
        const { value, max } = progress
        return <ProgressBar hasFailed={true} messageCenter="error" value={value} max={max} />
    }

    const className = classnames('file-upload', props.className)
    return (
        <div className={className}>
            {renderPick()}
            {fileIsUnsupported}
            {step === UPLOADING && renderUploading()}
            {step === DONE && renderDone()}
            {step === FAILED && renderFailed()}
        </div>
    )
}

export default FileUpload
