import React, { useMemo, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import DashboardListView from '../DashboardOverview/DashboardListView'
import DashboardTileView from '../DashboardOverview/DashboardTileView'
import { ListItem } from '../List/List'
import Icon, { IconNames } from '../../Atoms/Icon/Icon'
import ShyTextInput from '../../Atoms/ShyTextInput/ShyTextInput'
import { DashboardConfiguration } from '../../../Redux/Data/types'
import { filterDashboards, SharedDashboardGroupOwnProps } from './DashboardGroup'
import { AppDispatch } from '../../../store'
import { actions, selectors } from '../../../Redux'
import { RootState } from '../../../Redux/types'

type FixedDashboardGroupProps = {
    dashboards: Array<DashboardConfiguration>
    groupTitle: string
    iconName: IconNames
    isDragDisabled?: boolean
    id: string
    titleFilter: string
} & ReduxProps &
    SharedDashboardGroupOwnProps

type ReduxProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: SharedDashboardGroupOwnProps) => ({
    handleToggleIsGroupCollapsed: () => dispatch(actions.UI.DashboardGroups.toggleIsGroupCollapsed(ownProps.id)),
})

const mapStateToProps = (state: RootState) => ({
    viewMode: selectors.UI.CurrentUser.dashboardOverviewMode(state),
})

/*
 * Fixed Dashboard Groups are smaller versions of the DashboardGroup.
 * They're used for the "Favourite Dashboards" and the "All Dashboards" pseudo groups. They can't be used
 * as drag-target, their title can't be changed and other small changes which add up.
 */

const FixedDashboardGroup: React.FC<FixedDashboardGroupProps> = (props) => {
    const DashboardListComponent = props.viewMode === 'list' ? DashboardListView : DashboardTileView
    const [isOpen, setIsOpen] = useState(true)

    const filteredDashboards = useMemo(
        () => filterDashboards(props.dashboards, props.titleFilter),
        [props.titleFilter, props.dashboards]
    )

    const isOpenWithSearch = props.titleFilter ? props.titleFilter.length > 0 && filteredDashboards.length > 0 : isOpen

    return (
        <div className="dashboard-overview__dashboard-group">
            <ListItem
                left={[
                    <Icon
                        key="icon"
                        name="ChevronIcon"
                        rotation={isOpenWithSearch ? 90 : 0}
                        onClick={() => setIsOpen(!isOpen)}
                        size="small"
                    />,
                    <h2 className="dashboard-group__header" key={'dashboard-group-header-' + props.id}>
                        <Icon name={props.iconName} key={'dashboard-group-icon-' + props.id} />
                        <ShyTextInput onChange={() => {}} value={props.groupTitle} disabled />
                    </h2>,
                ]}
                collapsible={
                    <Droppable droppableId={props.id} type="dashboard" isDropDisabled direction={'horizontal'}>
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="dashboard-group__content"
                            >
                                {filteredDashboards.length > 0 ? (
                                    <DashboardListComponent
                                        dashboardIds={filteredDashboards}
                                        dashboardGroupId={props.id}
                                        isDragDisabled={props.isDragDisabled}
                                    />
                                ) : props.titleFilter ? (
                                    <p className="dashboard-overview--no-dashboards">
                                        No search results for '{props.titleFilter}'
                                    </p>
                                ) : (
                                    <>
                                        <p className="dashboard-overview--no-dashboards">You have no dashboards.</p>
                                        <DashboardListComponent
                                            dashboardIds={[]}
                                            dashboardGroupId={props.id}
                                            isDragDisabled={props.isDragDisabled}
                                        />
                                    </>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                }
                isOpen={isOpenWithSearch}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(FixedDashboardGroup)
