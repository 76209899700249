import React from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from '../../Redux'

import explyLogo from '../../Images/exply_logo_light.svg'

import Icon from '../../Components/Atoms/Icon/Icon'

import SetAdminPasswordStep from './Steps/SetAdminPasswordStep'
import TightenSecurityStep from './Steps/TightenSecurityStep'
import SetImportersStep from './Steps/SetImportersStep'
import AddUserStep from './Steps/AddUserStep'
import AddDashboardsStep from './Steps/AddDashboardsStep'
import { RootState } from '../../Redux/types'
import { AppDispatch } from '../../store'

const stepDefinitions = [
    {
        title: 'Change Admin Password',
        component: SetAdminPasswordStep,
    },
    {
        title: 'Tighten Security',
        component: TightenSecurityStep,
    },
    {
        title: 'Setup Data Source Importer',
        component: SetImportersStep,
    },
    {
        title: 'Add first User',
        component: AddUserStep,
    },
    {
        title: 'Explore Example Dashboards',
        component: AddDashboardsStep,
    },
]

const mapStateToProps = (state: RootState) => {
    return {
        currentStep: selectors.UI.SetupWizard.currentStep(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        nextStep: () => dispatch(actions.UI.SetupWizard.nextStep()),
    }
}

type SetupWizardProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class SetupWizard extends React.PureComponent<SetupWizardProps> {
    render() {
        const activeStep = stepDefinitions[this.props.currentStep - 1]
        const StepComponent = activeStep.component

        return (
            <div className="setup-wizard__container">
                <div className="setup-wizard__control-row">
                    <div className="setup-wizard__control-heading">
                        <h2>Steps:</h2>
                    </div>

                    {stepDefinitions.map((step, i) => (
                        <div
                            className={
                                'setup-wizard__step' +
                                (this.props.currentStep === i + 1 ? ' setup-wizard__step--active' : '')
                            }
                            key={i + 'setup-wizard-step'}
                        >
                            <h3>
                                <span>
                                    {this.props.currentStep > i + 1 ? (
                                        <Icon name="CheckmarkIcon" color="white" />
                                    ) : (
                                        i + 1 + '.'
                                    )}{' '}
                                </span>
                                {step.title}
                            </h3>
                        </div>
                    ))}

                    <img src={explyLogo} alt="Exply Logo" />
                </div>

                <div className={'setup-wizard__content setup-wizard--step-' + this.props.currentStep}>
                    <StepComponent />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupWizard)
