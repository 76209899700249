import React, { useState } from 'react'
import { connect } from 'react-redux'
import { DashboardId, WidgetGroupId, WidgetId } from '../../../../Redux/Data/types'
import { ModalActions, ModalContent } from '../../../../Components/Molecules/Modal/Modal'
import Select from '../../../../Components/Atoms/Select/Select'
import Button from '../../../../Components/Atoms/Button/Button'
import { RootState } from '../../../../Redux/types'
import { actions, selectors } from '../../../../Redux'
import { AppDispatch } from '../../../../store'

type DuplicateWidgetModalOwnProps = {
    onConfirmModal: (params?: any) => void
    onCancelModal: () => void
    parameters: {
        widgetId: WidgetId
        dashboardId: DashboardId
    }
}

const mapStateToProps = (state: RootState, ownProps: DuplicateWidgetModalOwnProps) => ({
    widgetConfiguration: selectors.Data.Dashboards.widgetById(state, ownProps.parameters.widgetId),
    widgetGroups: selectors.Data.Dashboards.ViewMode.widgetGroupConfigurationsForDashboard(
        state,
        ownProps.parameters.dashboardId
    ),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    duplicateWidgetById: (widgetId: WidgetId, widgetGroupId: WidgetGroupId) =>
        dispatch(
            actions.Data.Dashboards.WidgetEditMode.duplicateWidgetById({
                widgetId,
                widgetGroupId,
            })
        ),
})

type DuplicateWidgetModalProps = DuplicateWidgetModalOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const DuplicateWidgetModal = (props: DuplicateWidgetModalProps) => {
    if (props.widgetGroups === undefined) {
        throw Error('failed to duplicate widget, widget groups were undefined!')
    }

    const defaultWidgetGroup = props.widgetGroups.find((group) => group.id === props.widgetConfiguration.widgetGroup)
    if (defaultWidgetGroup === undefined) {
        throw Error('could not find widget group of widget!')
    }
    const [targetWidgetGroup, setTargetWidgetGroup] = useState(defaultWidgetGroup.id)

    const options = props.widgetGroups.map((widgetGroup, index) => ({
        value: widgetGroup.id,
        label: index + 1 + '. | ' + (widgetGroup.name || 'Unnamed Widget Group'),
    }))

    const handleConfirm = () => {
        props.duplicateWidgetById(props.parameters.widgetId, targetWidgetGroup)
        props.onConfirmModal()
    }

    return (
        <ModalContent className="duplicate-widget-modal">
            <div id="duplicate-widget-modal">
                <p>Duplicate Widget to Widget Group:</p>
                <Select
                    value={targetWidgetGroup}
                    options={options}
                    onChange={setTargetWidgetGroup}
                    placeholder={'Select Widget Group'}
                    menuPortalTarget={(document.querySelector('#duplicate-widget-modal') as HTMLElement) || undefined}
                    menuPosition={'fixed'}
                />
                <ModalActions>
                    <Button type="warning" onClick={props.onCancelModal}>
                        Cancel
                    </Button>
                    <Button type="primary" onClick={handleConfirm}>
                        Duplicate
                    </Button>
                </ModalActions>
            </div>
        </ModalContent>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateWidgetModal)
