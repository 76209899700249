import React, { useContext } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { connect } from 'react-redux'
import ShyTextInput from '../../../Components/Atoms/ShyTextInput/ShyTextInput'
import Icon from '../../../Components/Atoms/Icon/Icon'
import ContextMenu, { ContextMenuItem } from '../../../Components/Atoms/ContextMenu/ContextMenu'
import { DashboardId, WidgetGroupId } from '../../../Redux/Data/types'
import { Filters } from '../../../Components/Organisms/Widgets/WidgetModel'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { actions, selectors } from '../../../Redux'
import { FieldsConfigurationContext } from '../../../Components/Atoms/FieldsConfiguration/FieldsConfigurationContext'

type WidgetGroupHeaderOwnProps = {
    widgetGroupId: WidgetGroupId
    dashboardId: DashboardId
    isPreview?: boolean
}

const mapStateToProps = (state: RootState, props: WidgetGroupHeaderOwnProps) => ({
    widgetGroupConfiguration: selectors.Data.Dashboards.widgetGroupById(state, props.widgetGroupId),
    inEditMode: selectors.Data.Dashboards.DashboardEditMode.isDashboardEditModeActive(state),
    globalWidgetGroupId: selectors.Data.Dashboards.globalWidgetGroupId(state, props.dashboardId),
})

const mapDispatchToProps = (dispatch: AppDispatch, props: WidgetGroupHeaderOwnProps) => ({
    handleDuplicateWidgetGroup: () =>
        dispatch(actions.Data.Dashboards.WidgetGroupEditMode.duplicateWidgetGroup(props.widgetGroupId)),
    handleDeleteWidgetGroup: () =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'widgetGroupRemove',
                confirmationCallback: () => {
                    dispatch(actions.Data.Dashboards.WidgetGroupEditMode.removeWidgetGroup(props.widgetGroupId))
                },
                denyCallback: () => {},
            })
        ),
    handleMoveWidgetGroup: (direction: 'up' | 'down') =>
        dispatch(
            actions.Data.Dashboards.WidgetGroupEditMode.moveWidgetGroup({
                widgetGroupId: props.widgetGroupId,
                direction,
            })
        ),
    handleChangeWidgetGroupName: (newName: string) => {
        dispatch(
            actions.Data.Dashboards.WidgetGroupEditMode.changeName({ widgetGroupId: props.widgetGroupId, newName })
        )
    },
    toggleAsGlobalWidgetGroup: (widgetGroupId: WidgetGroupId | null) =>
        dispatch(actions.Data.Dashboards.WidgetGroupEditMode.toggleAsGlobalWidgetGroup(widgetGroupId)),
})

type WidgetGroupHeaderProps = WidgetGroupHeaderOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const WidgetGroupHeader: React.FC<WidgetGroupHeaderProps> = (props) => {
    const FieldsConfiguration = useContext(FieldsConfigurationContext)
    const inEditMode = props.inEditMode && !props.isPreview
    const isGlobalWidgetGroup = props.globalWidgetGroupId === props.widgetGroupId
    const filters = props.widgetGroupConfiguration.filter.fields

    const renderFilters = (filters: Filters) => {
        return filters.map((filter) => {
            const fieldName = filter.field || undefined
            const field = fieldName ? FieldsConfiguration[fieldName] : undefined
            const operator = filter.operator
            const tag = field && field.tag
            const fieldLabel = field && field.label ? field.label : fieldName
            const values = filter.values

            return field
                ? renderSingleFilter(values.join(', '), fieldLabel, operator, tag)
                : renderMissingFilterField(fieldName)
        })
    }

    const renderSingleFilter = (value: string, label?: string, operator?: string, tag?: string) => {
        const operatorAndValue =
            operator + ' ' + (tag === 'DATE' ? moment(parseInt(value, 10)).format('DD/MM/YYYY') : value)
        return (
            <div
                className="widget-group-filters--entry"
                key={'widget-group-filters--entry' + label + value}
                title={label + ' ' + operatorAndValue}
            >
                <span>{label + ' '}</span> {operatorAndValue}
            </div>
        )
    }

    const renderMissingFilterField = (fieldName?: string) => {
        return (
            <div
                className="widget-group-filters--entry widget-group-filters--entry__error"
                key={'widget-group-filters--entry' + fieldName}
            >
                The filter for {fieldName} is invalid
            </div>
        )
    }

    const name = props.widgetGroupConfiguration.name || ''
    const placeholder = inEditMode ? 'Your Group Title' : ''
    const hasHeader = inEditMode || filters.length > 0 || name.length > 0
    const headerClassName = classnames('widget-group__header', {
        'widget-group__header--hidden': !hasHeader,
        'widget-group__header--no-border': !hasHeader && props.widgetGroupConfiguration.position === 10,
    })

    const handleToggleGlobalGroup = () => {
        props.toggleAsGlobalWidgetGroup(isGlobalWidgetGroup ? null : props.widgetGroupId)
        if (!isGlobalWidgetGroup) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }

    return (
        <header className={headerClassName}>
            <h2>
                <ShyTextInput
                    disabled={!inEditMode}
                    value={name}
                    placeholder={placeholder}
                    onChange={props.handleChangeWidgetGroupName}
                    className="widget-group__title"
                />
            </h2>

            {!inEditMode && filters.length > 0 && (
                <div className="widget-group__header-right">
                    <Icon name="FilterIcon" size="small" />
                    <div className="widget-group-filters__list">{renderFilters(filters)}</div>
                </div>
            )}

            {inEditMode && (
                <div className="widget-group__header-right">
                    {isGlobalWidgetGroup ? (
                        [
                            <Icon
                                key={'widgetGroup-quick-control-1'}
                                label="Unset as Global Group"
                                name="CrownIcon"
                                title="Unset as Global Group"
                                onClick={handleToggleGlobalGroup}
                            />,
                            <Icon
                                key={'widgetGroup-quick-control-2'}
                                label="Duplicate"
                                name="ClipboardIcon"
                                title="Duplicate Widget Group"
                                onClick={props.handleDuplicateWidgetGroup}
                            />,
                            <Icon
                                key={'widgetGroup-quick-control-3'}
                                label="Remove"
                                name="TrashIcon"
                                title="Remove Widget Group"
                                onClick={props.handleDeleteWidgetGroup}
                            />,
                        ]
                    ) : (
                        <div className="secondary-header__options">
                            <div className="secondary-header__options__icon">
                                <Icon
                                    name="ChevronIcon"
                                    title="move up"
                                    rotation={-90}
                                    onClick={() => props.handleMoveWidgetGroup('up')}
                                />
                            </div>
                            <div className="secondary-header__options__icon">
                                <Icon
                                    name="ChevronIcon"
                                    title="move down"
                                    rotation={90}
                                    onClick={() => props.handleMoveWidgetGroup('down')}
                                />
                            </div>
                            <ContextMenu menuNamePrefix="widget-group__header-" size="small">
                                <ContextMenuItem onClick={handleToggleGlobalGroup}>
                                    <Icon name="CrownIcon" size="small" />
                                    Set as Global Group
                                </ContextMenuItem>
                                <ContextMenuItem onClick={props.handleDuplicateWidgetGroup}>
                                    <Icon name="ClipboardIcon" size="small" />
                                    <span>Duplicate</span>
                                </ContextMenuItem>
                                <ContextMenuItem onClick={props.handleDeleteWidgetGroup}>
                                    <Icon name="TrashIcon" size="small" />
                                    <span>Remove</span>
                                </ContextMenuItem>
                            </ContextMenu>
                        </div>
                    )}
                </div>
            )}
        </header>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetGroupHeader)
