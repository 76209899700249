import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'connected-react-router'
import { actions, selectors } from '../../Redux'
import { RootState } from '../../Redux/types'
import { AppDispatch } from '../../store'
import {
    startEditingExtension,
    startEditingImporterConfiguration,
} from '../../Sagas/ImporterSagas/Editing/LoadConfiguration'
import {
    fetchExtensionStepData,
    fetchImporterStepData,
} from '../../Sagas/ImporterSagas/ConfigPreview/ImporterStepDataSaga'
import EditImporterOrExtensionManager from './EditImporterOrExtensionManager'
import { ImporterConfiguration } from '../../Redux/Data/types'
import { ImporterType } from '../../StaticManifests/manifest.importerTypes'

const mapStateToProps = (state: RootState) => {
    return {
        currentlyEditedImporter: selectors.Data.Importer.currentlyEditedImporter(state),
        changedConfiguration: selectors.Data.Importer.changedConfiguration(state),
        reloadConfirmation: selectors.Data.FeatureFlags.reloadConfirmationFlag(state),
        existingImportersByUuid: selectors.Data.Importer.importersByUuid(state),
        additionalImporters: selectors.Data.FeatureFlags.additionalImporters(state),
        importerDefinitionsById: selectors.Data.Importer.importerDefinitionsById(state),
        initialStepIndex: selectors.Data.Importer.initialStepIndex(state),
        cancelRoute: selectors.Data.Importer.cancelRoute(state),
        typeSpecific: selectors.Data.Importer.typeSpecific(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createNewImporter: (type?: ImporterType) => dispatch(actions.Data.Importer.create(type)),
    fetchImporterStepData: (uuid: string, type: string, step: number, config: any, callback: any) =>
        dispatch(fetchImporterStepData(uuid, type, step, config, callback)),
    fetchExtensionStepData: (uuid: string, type: string, step: number, config: any, callback: any) =>
        dispatch(fetchExtensionStepData(uuid, type, step, config, callback)),
    startEditingConfiguration: (uuid: string) => dispatch(startEditingImporterConfiguration(uuid)),
    onChangeType: (newType: ImporterType, newConfiguration: any) =>
        dispatch(actions.Data.Importer.changeType(newType, newConfiguration)),
    onChangeImporterConfigurationProperty: (propertyPath: string, value: any) =>
        dispatch(actions.Data.Importer.editConfiguration(propertyPath, value)),
    onChangeTitle: (value: string) => dispatch(actions.Data.Importer.changeTitle(value)),
    onChangeSchedule: (value: string) => dispatch(actions.Data.Importer.changeSchedule(value)),
    removeImporter: (importerUuid: string) =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'importerDelete',
                confirmationCallback: () => {
                    dispatch(actions.Data.Importer.remove(importerUuid))
                },
            })
        ),
    removeExtension: (importer: ImporterConfiguration | null, index: number) =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'extensionDelete',
                confirmationCallback: () => {
                    if (importer) {
                        dispatch(actions.Data.Importer.removeExtension(importer, index))
                    }
                    dispatch(push('/importer'))
                },
            })
        ),
    handleCancelEditingImporter: (cancelRoute: string | null) => () => {
        dispatch(actions.Data.Importer.cancelEditing())
        dispatch(push(cancelRoute || '/importer'))
    },
    saveImporter: (
        importer: ImporterConfiguration,
        runImporterAfterSave?: boolean,
        continueEditingImporter?: boolean,
        callback?: () => void
    ) =>
        // runImporterAfterSave and continueEditing flags are needed for tempo cloud config, since we first have to do a redirect to tempo for access tokens
        // before running the importer
        dispatch(
            actions.Data.Importer.save(
                importer,
                '/importer',
                runImporterAfterSave !== undefined ? runImporterAfterSave : true,
                continueEditingImporter !== undefined ? continueEditingImporter : false,
                callback
            )
        ),
    onUnsavedLeave: (importer: ImporterConfiguration, mouseEvent: any) =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'leaveUnsaved',
                confirmationCallback: () => {
                    dispatch(actions.Data.Importer.save(importer))
                    mouseEvent.target.click()
                    dispatch(actions.UI.Menu.toggleMenuVisibility())
                },
                denyCallback: () => {
                    dispatch(startEditingImporterConfiguration(importer.uuid))
                    mouseEvent.target.click()
                    dispatch(actions.UI.Menu.toggleMenuVisibility())
                },
            })
        ),
    startEditingExtension: (importerUuid: string, index: number) =>
        dispatch(startEditingExtension(importerUuid, index)),
    onChangeExtensionConfigurationProperty: (propertyPath: string, value: any) =>
        dispatch(actions.Data.Importer.editExtensionConfigurationProperty(propertyPath, value)),
    onChangeExtensionTitle: (value: string) => dispatch(actions.Data.Importer.changeExtensionTitle(value)),
    // !!! ONLY !!! to be used to register custom actions in the Manifest (see csvFileDataSource)
    dispatch,
})

export type EditDataSourceContainerReduxProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

export type EditDataSourceContainerOwnProps = {
    isExtension?: boolean
    // URL parsing
    match?: {
        path?: string
        params: {
            importerUuid?: string
        }
    }
} & Partial<RouteComponentProps>

export default connect(mapStateToProps, mapDispatchToProps)(EditImporterOrExtensionManager)
