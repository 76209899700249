import { put, select, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import axios from './Utility/axios'
import { actions, selectors } from '../Redux'
import { ColorCustomizationType } from '../Components/Organisms/ColorCustomization/ColorCustomization'

export const saveColorCustomization = createAction('Data/ColorCustomization/Save')

export default function* SaveColorCustomizationSaga() {
    yield takeLatest(saveColorCustomization, function* () {
        try {
            const colorCustomization: { fieldBasedColors: ColorCustomizationType } = {
                fieldBasedColors: yield select(selectors.Data.ColorCustomization.getTransientCustomColors),
            }

            const response: AxiosResponse = yield axios({
                method: 'POST',
                url: 'admin/custom-colors',
                data: colorCustomization,
            })

            if (response.status < 400) {
                // Since we modify a part of the state that is completely immutable during application runtime,
                // we want to trigger a hard page reload here. That causes the config.js file to be reloaded.
                // TODO refactor so that this is not the case see #1343
                window.location.href = window.location.protocol + '//' + window.location.host
            } else {
                yield put(actions.UI.Notification.show({ id: 'typesUiSaveFailed' }))
            }
        } catch (e) {
            console.warn(e)
        }
    })
}
