import { call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { selectors } from '../../../Redux'
import fetchLogTails from './fetchLogTails'
import { ImporterId } from '../../../Redux/Data/types'

export const pollLogTailsAction = createAction('SAGAS/pollLogTails', (uuids?: Array<ImporterId>) => ({
    payload: { uuids },
}))

export default function* ImporterRunStartSaga() {
    yield takeLatest(pollLogTailsAction, function* (action) {
        try {
            let importerUuids = action.payload.uuids

            if (!importerUuids) {
                importerUuids = selectors.Data.Importer.importerUuidArray(yield select())
            }

            yield call(fetchLogTails, importerUuids)

            const runningImportersUuidArray = selectors.Data.Importer.runningImportersUuidArray(yield select())
            if (runningImportersUuidArray.length > 0) {
                yield delay(1000)
                yield put(pollLogTailsAction(runningImportersUuidArray))
            }
        } catch (e) {
            console.error(e)
        }
    })
}
