import React, { useState } from 'react'

import Button from '../../Atoms/Button/Button'
import CustomIcon from '../../Atoms/CustomIcon/CustomIcon'
import LogMessageContainer from '../LogMessageContainer/LogMessageContainer'
import StatusIndicator from '../../Atoms/StatusIndicator/StatusIndicator'
import Switch from '../../Atoms/Switch/Switch'
import { ListItem } from '../../Organisms/List/List'
import { ImporterListItemProps } from './ImporterListItem.types'
import ButtonWithTooltip from '../HoverTooltip/ButtonWithTooltip'
import ImportIncidentListItem from '../ImportIncidentListItem/ImportIncidentListItem'

const ImporterListItem = React.forwardRef<HTMLDivElement, ImporterListItemProps>((props, ref) => {
    const [isOpen, setIsOpen] = useState(props.disableToggle || props.isOpen)
    const [hasBeenStartedOn, setHasBeenStartedOn] = useState<number | null>(null)
    const [hasBeenKilled, setHasBeenKilled] = useState(false)

    const handleOnClick = () => {
        if (!isOpen) {
            pollLogTails()
        }

        if (!props.disableToggle) {
            setIsOpen(!isOpen)
        }
    }

    const handleRunButtonClick = () => {
        props.onRunImport(props.uuid)
        setHasBeenStartedOn(new Date().getTime())
        setHasBeenKilled(false)
    }

    const handleKillButtonClick = () => {
        props.onKillImport(props.uuid)
        setHasBeenKilled(true)
    }

    const handleEditButtonClick = () => props.onEditImporter(props.uuid)

    const handleClearDataClick = () => props.onClearData(props.uuid)

    const handleDeleteImporter = () => props.onRemoveImporter(props.uuid)

    const handleSwitchClick = () => props.onToggleImporterEnabled(props.uuid)

    const pollLogTails = () => props.onPollLogTails([props.uuid])

    const handleExportLogsButtonClick = () => props.onExportLogs(props.uuid)

    const handleDataSourceExtensionClick = () => props.onAddExtension(props.uuid)

    const renderRunButton = () => {
        if (props.status.state === 'Running') {
            return (
                <Button onClick={handleKillButtonClick} type="primary-warning">
                    Kill
                </Button>
            )
        }

        const hasJustBeenStarted = hasBeenStartedOn !== null && new Date().getTime() - hasBeenStartedOn < 5000
        return (
            <ButtonWithTooltip
                isDisabled={hasJustBeenStarted && !hasBeenKilled}
                header="Importer is running"
                onClick={handleRunButtonClick}
                type="primary"
                // The importer might take a while to start and we want to show the user that something
                // is happening and prevent them from clicking the button several times
            >
                Run
            </ButtonWithTooltip>
        )
    }

    const renderButtons = () => {
        if (props.hideAllButtons) {
            return (
                <div key="1" className="importer-list-item__column--width-1">
                    <Button onClick={handleExportLogsButtonClick}>Download Logs</Button>
                </div>
            )
        }

        return (
            <div key="1" className="importer-list-item__column--width-1">
                {renderRunButton()}
                <ButtonWithTooltip
                    isDisabled={props.status.isRemoved || false}
                    header="Importer is marked as removed"
                    onClick={handleEditButtonClick}
                    className="importer-list-item__edit-button"
                >
                    Edit
                </ButtonWithTooltip>
                <Button onClick={handleDataSourceExtensionClick}>
                    {(props.importer.postProcessors.length > 0 ? 'Edit' : 'Add') + ' Data Source Extension'}
                </Button>
                <Button onClick={handleExportLogsButtonClick}>Download Logs</Button>
                <Button onClick={handleClearDataClick} type="warning">
                    Clear Data
                </Button>
                {!props.importerDefinition.managedByExply && (
                    <Button onClick={handleDeleteImporter} type="primary-warning">
                        Remove
                    </Button>
                )}
            </div>
        )
    }

    const renderIncidents = () => {
        return props.status.incidents?.map((incident, index) => (
            <ImportIncidentListItem incident={incident} key={'importer-incident' + props.importer.uuid + index} />
        ))
    }

    const importer = props.importer
    const isRemoved = props.status.isRemoved
    const isEnabled = importer ? importer.enabled : false
    const logs = props.logTails

    return (
        <ListItem
            ref={ref}
            className="importer-list-item"
            isOpen={isOpen}
            left={[
                <StatusIndicator
                    key={0}
                    status={
                        (isEnabled || props.status.state !== 'Outdated') && !isRemoved
                            ? props.status.state
                            : 'Deactivated'
                    }
                />,
                <CustomIcon key="1" type={importer.definitionId} />,
                <div key="2" className="importer-list-item__name">
                    {importer ? importer.title : 'data with removed importer'}
                </div>,
            ]}
            right={[
                !props.hideAllButtons && (
                    <Switch
                        key="2"
                        value={isEnabled}
                        onChange={handleSwitchClick}
                        label={isEnabled ? 'active' : 'inactive'}
                        labelPositionRight={false}
                    />
                ),
            ]}
            collapsible={[
                <div className="importer-list-item__column" key={'importer-list-item--column' + props.importer.uuid}>
                    {renderIncidents()}
                    <div className="importer-list-item__row">
                        {renderButtons()}
                        <div key="2" className="importer-list-item__column--width-2">
                            <p>Data Transformation-Server Logs</p>
                            <LogMessageContainer
                                className="importer-list-item__logs"
                                logs={logs && logs.server ? logs.server : 'No logs'}
                            />
                        </div>
                        <div key="3" className="importer-list-item__column--width-2">
                            <p>Data Import Logs</p>
                            <LogMessageContainer
                                className="importer-list-item__logs"
                                logs={logs && logs.importer ? logs.importer : 'No logs'}
                            />
                        </div>
                    </div>
                </div>,
            ]}
            onClick={props.disableToggle ? undefined : handleOnClick}
        />
    )
})

export default React.memo(ImporterListItem)
