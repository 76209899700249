import React, { PureComponent } from 'react'
import { withSize } from 'react-sizeme'
import classnames from 'classnames'
import WidgetHeader, { WidgetHeaderFunctions } from '../../../Molecules/WidgetHeader/WidgetHeader'
import { formatNumberToString } from '../../../../Utility/NumberFormator'
import { inTestingMode } from '../../../../Utility/Testing'
import Icon from '../../../Atoms/Icon/Icon'
import { metricTileComparisonOptions } from './MetricsTileWidget.manifest'

export type MetricsTileWidgetData = {
    value: number
    comparison?: {
        [key: string]: number | null
    }
    comparisonMessage?: string
    unit?: string
}

type MetricsTileWidgetProps = {
    widgetData: MetricsTileWidgetData
    setWidgetState: (...args: any[]) => any
    widgetConfiguration: {
        title?: string
        color?: string
        fontColor?: 'white' | 'black'
        numberOfDecimals?: number
        comparison?: string[]
        colorScale?: {
            isPercentage: boolean
            ranges: Array<{
                lowerBound: number
                upperBound: number
                color: string
            }>
        }
    }
    size?: {
        width: number
        height: number
    }
} & WidgetHeaderFunctions

class MetricsTileWidget extends PureComponent<MetricsTileWidgetProps> {
    static displayName = 'MetricsTile'

    render = () => {
        const title = this.props.widgetConfiguration.title
        const className = classnames('widget', {
            'widget--has-header': title || this.props.onChangeTitle,
        })
        return (
            <div className={className}>
                <WidgetHeader title={title} onChangeTitle={this.props.onChangeTitle} />
                {this.renderWidget()}
            </div>
        )
    }

    renderWidget = () => <div className="metric-tile-widget">{this.renderTile()}</div>

    getColor = () => {
        if (this.props.widgetConfiguration.colorScale?.ranges) {
            const range = this.props.widgetConfiguration.colorScale.ranges.find((r) => {
                if (r.upperBound) {
                    return r.lowerBound <= this.props.widgetData.value && r.upperBound > this.props.widgetData.value
                }

                return r.lowerBound <= this.props.widgetData.value
            })
            if (range) {
                return range.color
            }
        }

        return this.props.widgetConfiguration.color
    }

    renderTile() {
        const value = this.props.widgetData.value
        const hasComparison = Boolean(
            this.props.widgetConfiguration.comparison && this.props.widgetConfiguration.comparison.length > 0
        )
        const color = this.getColor()
        const label = formatNumberToString(
            value,
            this.props.widgetConfiguration.numberOfDecimals,
            this.props.widgetData.unit
        )
        const fontColor = this.props.widgetConfiguration.fontColor || 'white'
        const fontSize = this.calcFontSize(label.length, hasComparison)

        return (
            <div
                className="metric-tile-widget metric-tile-widget__tile"
                style={{ background: color, color: fontColor, fontSize: fontSize + 'rem' }}
            >
                <div className="metric-tile-widget__label">{label}</div>
                {hasComparison && this.props.widgetData.comparison && (
                    <div className="metric-tile-widget__comparisons">
                        {this.renderComparisons(this.props.widgetData.comparison, fontSize)}
                    </div>
                )}
            </div>
        )
    }

    renderComparisons = (
        comparison: {
            [key: string]: number | null
        } | null,
        fontSize: number
    ) => {
        const fontColor = this.props.widgetConfiguration.fontColor || 'white'
        fontSize /= 2

        if (this.props.widgetData.comparisonMessage) {
            return (
                <div
                    className="metric-tile-widget__comparison"
                    style={{
                        fontSize: fontSize + 'rem',
                    }}
                >
                    {this.props.widgetData.comparisonMessage}
                </div>
            )
        }

        return (
            comparison &&
            Object.entries(comparison).map(([period, value]) => {
                const comparison = metricTileComparisonOptions.find((option) => option.value === period)
                return (
                    <div
                        className="metric-tile-widget__comparison"
                        style={{
                            fontSize: fontSize + 'rem',
                        }}
                    >
                        {value && (
                            <div className="metric-tile-widget__comparison--value">
                                <Icon
                                    name="ArrowBoldIcon"
                                    color={fontColor === 'white' ? 'white' : undefined}
                                    size={fontSize >= 3 ? 'xl' : fontSize < 1.5 ? 'sm' : 'lg'}
                                    rotation={value > 0 ? -45 : 45}
                                />
                                {(value > 0 ? '+' : '') +
                                    formatNumberToString(value, this.props.widgetConfiguration.numberOfDecimals, '%')}
                            </div>
                        )}
                        <p
                            style={{
                                fontSize: fontSize / 2 + 'rem',
                            }}
                        >
                            {!value && 'No Data in '}
                            {comparison ? comparison.label : 'unknown comparison'}
                        </p>
                    </div>
                )
            })
        )
    }

    calcFontSize = (length: number, hasComparison: boolean) => {
        if (this.props.size) {
            const heightFactor = hasComparison ? 0.01 : 0.03

            return Math.max(
                1,
                Math.min((this.props.size.width * 0.09) / length, this.props.size.height * heightFactor, 4)
            )
        }

        return 3
    }
}

export default inTestingMode ? MetricsTileWidget : withSize({ monitorHeight: true })(MetricsTileWidget)
