import React, { Children } from 'react'
import { ResponsiveContainer } from 'recharts'
import { inTestingMode } from '../../Utility/Testing'

const FakeContainer: React.FunctionComponent<{ children: React.ReactElement }> = (props) =>
    Children.only(props.children)

const ResponsiveTestingAwareChartContainer = inTestingMode ? FakeContainer : ResponsiveContainer
export default ResponsiveTestingAwareChartContainer

export const chartWidthAndHeightForTesting = inTestingMode ? { width: 200, height: 200 } : {}
