import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

class ScrollToTop extends React.PureComponent<RouteComponentProps> {
    componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0)
        }
    }

    // eslint-disable-next-line
    render() {
        return null
    }
}

export default withRouter(ScrollToTop)
