import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import SettingsView from '../../../Components/Organisms/SettingsView/SettingsView'
import WidgetExplorerDataSelector from './WidgetExplorerDataSelector/WidgetExplorerDataSelector'
import Button from '../../../Components/Atoms/Button/Button'
import ButtonWithTooltip from '../../../Components/Molecules/HoverTooltip/ButtonWithTooltip'
import SecondaryFooter from '../../../Components/Molecules/SecondaryFooter/SecondaryFooter'
import { AppDispatch } from '../../../store'
import { DashboardId, WidgetId } from '../../../Redux/Data/types'
import { actions, selectors } from '../../../Redux'
import { RootState } from '../../../Redux/types'
import WidgetExplorerWidgetPreviewDashboard from './WidgetExplorerSuggestions/WidgetExplorerSuggestions'
import { DashboardGroupId } from '../../../Redux/UI/types'

const mapStateToProps = (state: RootState) => ({
    widgetsOnDashboardCount: 1,
    loadingState: selectors.Data.Dashboards.ViewMode.dashboardLoadingState(state),
    addedWidgets: selectors.Data.Dashboards.WidgetExplorer.addedWidgets(state),
})

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: WidgetExplorerOwnProps) => {
    const cancelWidgetExplorerDecider =
        (addedWidgets: Array<WidgetId>, isNewDashboard: boolean, numberOfWidgetInGroup: number) => () => {
            if (addedWidgets.length > 0) {
                dispatch(
                    actions.UI.Modal.startConfirmation({
                        modalId: 'leaveUnsaved',
                        parameters: {
                            message: `You have added ${addedWidgets.length} ${
                                addedWidgets.length === 1 ? 'Widget' : 'Widgets'
                            }. Do you want to discard your changes?`,
                        },
                        confirmationCallback: () => {
                            // save the widget and display the dashboard in edit mode
                            dispatch(actions.Data.Dashboards.WidgetExplorer.closeWidgetExplorer())
                        },
                        cancelCallback: () => {},
                        denyCallback: () => {
                            addedWidgets.map((uuid: string) =>
                                dispatch(actions.Data.Dashboards.WidgetEditMode.deleteWidgetById(uuid))
                            )
                            if (isNewDashboard) {
                                dispatch(actions.Data.Dashboards.DashboardEditMode.disableEditMode())
                                dispatch(push('/'))
                            } else {
                                dispatch(actions.Data.Dashboards.WidgetExplorer.closeWidgetExplorer())
                            }
                        },
                    })
                )
            } else if (isNewDashboard && numberOfWidgetInGroup === 1) {
                dispatch(actions.UI.Widget.resetLatestConfiguration())
                dispatch(actions.Data.Dashboards.DashboardEditMode.discardEditModeChanges())
                dispatch(actions.Data.Dashboards.DashboardEditMode.disableEditMode())
                if (ownProps.groupIdsOfDashboard.length > 0) {
                    ownProps.groupIdsOfDashboard.forEach((id) =>
                        dispatch(
                            actions.UI.DashboardGroups.removeDashboardFromGroup({
                                groupId: id,
                                dashboardId: ownProps.dashboardId,
                            })
                        )
                    )
                }
                dispatch(push('/'))
            } else {
                // when we already added some widgets to the new dashboard show the dashboard
                dispatch(actions.Data.Dashboards.WidgetExplorer.closeWidgetExplorer())
            }
        }

    return {
        onCancelWidgetExplorer: cancelWidgetExplorerDecider,
        // this will save the added widgets to the explorer
        handleCloseWidgetExplorer: () => dispatch(actions.Data.Dashboards.WidgetExplorer.closeWidgetExplorer()),
    }
}

type WidgetExplorerOwnProps = {
    onShowAdvancedEditor: VoidFunction
    isNewDashboard: boolean
    groupIdsOfDashboard: Array<DashboardGroupId>
    dashboardId: DashboardId
}

type WidgetExplorerProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    WidgetExplorerOwnProps

class WidgetExplorer extends PureComponent<WidgetExplorerProps> {
    render() {
        return (
            <>
                <SettingsView
                    className="widget-explorer__settings-view"
                    main={<WidgetExplorerDataSelector />}
                    aside={
                        <WidgetExplorerWidgetPreviewDashboard showAdvancedScreen={this.props.onShowAdvancedEditor} />
                    }
                />
                {this.renderFooter()}
            </>
        )
    }

    renderFooter = () => (
        <SecondaryFooter
            right={
                <div>
                    <Button onClick={this.handleCancelWidgetExplorer}>Cancel</Button>
                    <ButtonWithTooltip
                        isDisabled={this.props.addedWidgets.length === 0}
                        header="No widget added"
                        type="primary"
                        onClick={this.props.handleCloseWidgetExplorer}
                    >
                        Save
                    </ButtonWithTooltip>
                </div>
            }
        />
    )

    handleCancelWidgetExplorer = () =>
        this.props.onCancelWidgetExplorer(
            this.props.addedWidgets,
            this.props.isNewDashboard,
            this.props.widgetsOnDashboardCount
        )()
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetExplorer)
