import React, { lazy, Suspense } from 'react'
import { RowChartWidgetProps } from './RowChartWidget.types'

const LazyWidget = lazy(() => import(/* webpackChunkName: "RowChart" */ './RowChartWidget'))

const LazyWidgetWrapper: React.FC<RowChartWidgetProps> = (props) => (
    <Suspense fallback="">
        <LazyWidget {...props} />
    </Suspense>
)

export default LazyWidgetWrapper
