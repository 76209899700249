import { put, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { LOCATION_CHANGE } from 'connected-react-router'
import { AxiosResponse } from 'axios'
import { actions } from '../../Redux'
import axios from '../Utility/axios'

export const switchUserBack = createAction('Data/UserManagement/SwitchBackUser')

export default function* SwitchUserBackSaga() {
    yield takeLatest(switchUserBack, function* () {
        try {
            const response: AxiosResponse = yield axios({
                method: 'GET',
                url: 'switch-back-user',
            })

            if (response.status < 400) {
                sessionStorage.setItem('redirectToUser', response.data.previousUser)
                window.location.reload()
            } else {
                yield put(actions.UI.Notification.show({ id: 'userOrGroupSwitchToUserFailed' }))
            }
        } catch (e) {
            console.error(e)
        }
    })

    yield takeLatest([LOCATION_CHANGE], function () {
        if (sessionStorage.getItem('redirectToUser')) {
            window.location.hash = '#/user-management/edit/' + sessionStorage.getItem('redirectToUser')
            sessionStorage.removeItem('redirectToUser')
        }
    })
}
