import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from '../../../Redux'

import Button from '../../../Components/Atoms/Button/Button'
import FormFieldWithLabel from '../../../Components/Molecules/FormFieldWithLabel/FormFieldWithLabel'
import InputValidationWrapper from '../../../Components/Atoms/InputValidationWrapper/InputValidationWrapper'

import Headline from '../../../Components/Atoms/Headline/Headline'

import TextInput from '../../../Components/Atoms/TextInput/TextInput'

import { getValidationErrorMessage, containsErrorMessages } from '../../../Utility/Validation'
import { ModalActions, ModalContent } from '../../../Components/Molecules/Modal/Modal'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import ButtonWithTooltip from '../../../Components/Molecules/HoverTooltip/ButtonWithTooltip'

type PasswordModalState = {
    password: string
    passwordConfirmation: string
}
type PasswordModalOwnProps = {
    onDenyModal: () => void
}

type PasswordModalProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    PasswordModalOwnProps

class PasswordModal extends PureComponent<PasswordModalProps, PasswordModalState> {
    passwordInputRef = React.createRef<HTMLInputElement>()

    constructor(props: PasswordModalProps) {
        super(props)

        this.state = {
            password: '',
            passwordConfirmation: '',
        }
    }

    handlePasswordChange = (newValue: string) => {
        return this.setState({ password: newValue })
    }

    handlePasswordConfirmationChange = (newValue: string) => {
        return this.setState({ passwordConfirmation: newValue })
    }

    handleSavePassword = () => {
        if (this.props.currentUserId) {
            this.props.savePassword(this.props.currentUserId, this.state.password)
            this.props.onDenyModal()
        }
    }

    validatePasswords = (newPassword: string, repeatPassword: string) => {
        const repeatPasswordMessage = newPassword !== repeatPassword ? 'Your Passwords do not match!' : undefined

        const errorMessages = {
            newPassword: getValidationErrorMessage(newPassword, [
                {
                    type: 'required',
                    message: 'required',
                },
                {
                    type: 'minLength',
                    length: 8,
                    message: 'Password needs to have a least 8 characters',
                },
            ]),
            repeatPassword: repeatPasswordMessage,
        }

        return {
            isValid: !containsErrorMessages(errorMessages),
            errorMessages,
        }
    }

    renderHoverTooltip = (errorMessages: { newPassword: string | undefined; repeatPassword: string | undefined }) => {
        const messages: Array<string> = []
        if (errorMessages.newPassword) messages.push(errorMessages.newPassword)
        if (errorMessages.repeatPassword) messages.push(errorMessages.repeatPassword)
        return messages
    }

    componentDidMount() {
        this.passwordInputRef.current?.focus()
    }

    render() {
        const passwordField = {
            label: 'New Password',
            editor: TextInput,
            onChange: this.handlePasswordChange,
            value: this.state.password,
            type: 'password',
        }

        const passwordConfirmationField = {
            label: 'Confirm new Password',
            editor: TextInput,
            onChange: this.handlePasswordConfirmationChange,
            onEnterPress: this.handleSavePassword,
            value: this.state.passwordConfirmation,
            type: 'password',
        }

        const validation = this.validatePasswords(this.state.password, this.state.passwordConfirmation)
        const errorMessages = validation.errorMessages

        return (
            <ModalContent>
                <Headline configuration={{ title: 'Set Password' }} />
                <InputValidationWrapper message={errorMessages.newPassword} messageType="hint">
                    <FormFieldWithLabel configuration={passwordField} ref={this.passwordInputRef} />
                </InputValidationWrapper>
                <InputValidationWrapper message={errorMessages.repeatPassword}>
                    <FormFieldWithLabel configuration={passwordConfirmationField} />
                </InputValidationWrapper>

                <ModalActions>
                    <Button onClick={this.props.onDenyModal}>Cancel</Button>
                    <ButtonWithTooltip
                        isDisabled={!validation.isValid}
                        content={this.renderHoverTooltip(errorMessages)}
                        header="Password cannot be saved"
                        type="primary"
                        onClick={this.handleSavePassword}
                    >
                        Save
                    </ButtonWithTooltip>
                </ModalActions>
            </ModalContent>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUserId: selectors.Data.UserManagement.currentlyEditedUserOrGroup(state)?.id,
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        savePassword: (userId: string, password: string) => {
            dispatch(actions.Data.UserManagement.saveUserPassword(userId, password))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordModal)
