import React, { ErrorInfo } from 'react'
import classnames from 'classnames'
import Button from '../../../Components/Atoms/Button/Button'
import Icon from '../../../Components/Atoms/Icon/Icon'

type WidgetErrorBoundaryProps = {
    children: any
    size: {
        height: number | null
        width: number | null
    }
}

type WidgetErrorBoundaryState = {
    hasError: boolean
    error?: Error
    errorInfo?: ErrorInfo
}

export class WidgetErrorBoundary extends React.Component<WidgetErrorBoundaryProps, WidgetErrorBoundaryState> {
    constructor(props: any) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error, errorInfo })
    }

    handleBugReport = () => {
        const mailBody = this.handleCreateBugReport()
        window.open(
            'mailto:support@exply.io?subject=Bugreport&body=' + encodeURIComponent(mailBody),
            'BugReport',
            'target=_blank'
        )
    }

    handleCopyBugReport = () => {
        const errorMessage = this.handleCreateBugReport()
        navigator.clipboard.writeText(errorMessage)
    }

    handleCreateBugReport = () => {
        const url = window.location
        return (
            '\n \nError Message: \n' +
            this.state.error +
            '\n\nURL: \n' +
            url +
            '\n\nStack Trace: \n' +
            // @ts-ignore
            this.state.errorInfo.componentStack
        )
    }

    renderErrorText = () => {
        if (this.props.size.height && this.props.size.height < 350) {
            return (
                <>
                    <p>Uh oh... Something went wrong in this widget. We are terribly sorry!</p>
                    <p>You can help our engineers fix this problem by submitting a bug report.</p>
                </>
            )
        }

        return (
            <>
                <h3>Uh oh... Something went wrong in this widget. We are terribly sorry!</h3>
                <p>You can help our engineers fix this problem by submitting a bug report.</p>
            </>
        )
    }

    render() {
        const classNames = classnames('widget-error-boundary', {
            'widget-error-boundary--small': this.props.size.width && this.props.size.width < 720,
            'widget-error-boundary--tiny': this.props.size.height && this.props.size.height < 350,
        })

        if (this.state.hasError) {
            return (
                <div className={classNames}>
                    {this.renderErrorText()}
                    <div className="error-boundary--actions">
                        <Button onClick={this.handleCopyBugReport}>
                            <Icon
                                name="ClipboardIcon"
                                color="green"
                                size="small"
                                title="Copy error message to clipboard"
                            />
                            {this.props.size.height && this.props.size.height < 350
                                ? ''
                                : 'Copy error message to clipboard'}
                        </Button>
                        <Button onClick={this.handleBugReport} type="primary">
                            <Icon name="PaperPlaneIcon" color="white" size="small" title="Submit bug report via mail" />
                            {this.props.size.height && this.props.size.height < 350 ? '' : 'Submit bug report via mail'}
                        </Button>
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}
