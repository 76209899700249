export type NotificationConfiguration = {
    type: 'error' | 'info'
    title: string
    message?: string
}

const notifications: Record<string, NotificationConfiguration> = {
    widgetData404: {
        type: 'error',
        title: 'Failed to load widget data',
        message: 'Seems like the server cannot be reached :(',
    },
    widgetData500: {
        type: 'error',
        title: 'Failed to load widget data',
        message: 'Seems like an exception was thrown on the server',
    },
    // DASHBOARDS
    dashboardTransientInvariant: {
        type: 'error',
        title: 'Editing your dashboard might fail',
        message: 'Something was not initialized correctly. Please reload!',
    },
    dashboardSaveFailed: {
        type: 'error',
        title: 'Saving your dashboard failed',
        message: 'Seems like an exception was thrown on the server',
    },
    dashboardShareFailed: {
        type: 'error',
        title: 'Sharing your dashboard failed',
        message: 'Seems like an exception was thrown on the server',
    },
    dashboardStopShareFailed: {
        type: 'error',
        title: 'Making your dashboard private failed',
        message: 'Seems like an exception was thrown on the server',
    },
    dashboardDuplicateFailed: {
        type: 'error',
        title: 'Duplicating your dashboard failed. Please reload the application.',
    },
    dashboardDeleteFailed: {
        type: 'error',
        title: 'Deleting your dashboard failed',
    },
    dashboardExportFailed: {
        type: 'error',
        title: 'Error exporting dashboard!',
    },
    dashboardExportRunning: {
        type: 'info',
        title: 'Exporting Dashboard …',
    },
    dashboardDownloadFailed: {
        type: 'error',
        title: 'Failed to download dashboard configuration',
    },
    // IMPORTERS
    importerStartFailed: {
        type: 'error',
        title: 'Could not start importer. Check schedule and avoid empty fields!',
    },
    importerSaveFailed: {
        type: 'error',
        title: 'Could not save importer configuration. Check schedule and avoid empty fields',
    },
    importerDeleteFailed: {
        type: 'error',
        title: 'Could not delete importer',
    },
    importerClearSingleFailed: {
        type: 'error',
        title: 'Data of importer could not be cleared',
    },
    importerClearAllFailed: {
        type: 'error',
        title: 'Data of all importers could not be cleared',
    },
    importerDetectMappingFailed: {
        type: 'error',
        title: 'Failed to analyze the source data. Please add a manual configuration.',
    },
    importerExportLogsRunning: {
        type: 'info',
        title: 'Exporting Importer Logs...',
    },
    importerExportLogsFailed: {
        type: 'error',
        title: 'Failed to export Importer Logs!',
    },
    // SYSTEM
    systemTimezoneChangeError: {
        type: 'error',
        title: 'Failed to change time zone!',
    },
    systemDateFormatChangeError: {
        type: 'error',
        title: 'Failed to change date format!',
    },
    systemLicenseUploadFailed: {
        type: 'error',
        title: 'Upload failed!',
    },
    systemWorkingDayChangeError: {
        type: 'error',
        title: 'Failed to change working days!',
    },
    systemElasticTokenChangeError: {
        type: 'error',
        title: 'Failed to change elastic token length',
    },
    systemElasticTokenChangeSuccess: {
        type: 'info',
        title: 'Successfully changed ElasticSearch token length',
    },
    // USER MANAGEMENT
    userOrGroupSwitchToUserFailed: {
        type: 'error',
        title: 'There was an error switching to the other user!',
    },
    userOrGroupSwitchBack: {
        type: 'info',
        title: 'Switch back user',
    },
    userOrGroupSaveFailed: {
        type: 'error',
        title: 'Server did not accept password!',
    },
    userOrGroupPasswordSaveFailed: {
        type: 'error',
        title: 'Password could not be saved!',
    },
    userOrGroupGetPassWordTokenFailed: {
        type: 'error',
        title: 'Failed to get the reset password token!',
    },
    userOrGroupRemoveFailed: {
        type: 'error',
        title: 'Failed to remove!',
    },
    // MISC
    myProfileSave500: {
        type: 'error',
        title: 'Saving your new password failed',
        message: 'Seems like an exception was thrown on the server',
    },
    myProfileSaveNameFailed: {
        type: 'error',
        title: 'Something went wrong while changing your name!',
    },
    myProfileSavePreferredThemeFailed: {
        type: 'error',
        title: 'Something went wrong while updating your preferred theme!',
    },
    myProfileOldPasswordWrong: {
        type: 'error',
        title: 'Your old password was wrong!',
    },
    typesUiSaveFailed: {
        type: 'error',
        title: 'Error saving configuration!',
    },
    jiraConfigurationLoadAvailableProjectsError: {
        type: 'error',
        title: 'We were not able to load the currently available Projects from your JIRA :(',
    },
    copiedToClipboard: {
        type: 'info',
        title: 'Copied to clipboard!',
    },
    updateAvailable: {
        type: 'info',
        title: 'Update available!',
    },
}

export type NotificationKey = keyof typeof notifications

export default notifications
