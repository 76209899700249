import React, { PureComponent } from 'react'
import Table, { TableColumnFormatAndStyle } from '../../../Atoms/Table/Table'
import { formatNumberToString } from '../../../../Utility/NumberFormator'
import { AllocationResult } from './QuotaTileWidget.types'

function noOp() {}

const hoursColumn = (unit: string): TableColumnFormatAndStyle => ({
    style: 'numeric',
    format: {
        numberOfDecimals: 2,
        unit,
    },
})

const hoursPerDayColumn = (unit: string): TableColumnFormatAndStyle => ({
    style: 'numeric',
    format: {
        numberOfDecimals: 2,
        unit: unit + '/d',
    },
})

const daysColumn: TableColumnFormatAndStyle = {
    style: 'numeric',
    format: {
        numberOfDecimals: 0,
        unit: 'd',
    },
}

type QuotaDetailsProps = {
    sum: number
    expectation: number
    unit: string
    timeFrame: string
    allocationResults: Array<AllocationResult>
}

export default class QuotaDetails extends PureComponent<QuotaDetailsProps, unknown> {
    render() {
        return <div className="quota-details">{this.renderContent()}</div>
    }

    renderContent() {
        const { sum, unit, timeFrame } = this.props
        const displaySum = formatNumberToString(sum, 2, unit)
        if (this.props.allocationResults) {
            const displaySum = formatNumberToString(sum, 2, unit)
            return (
                <React.Fragment>
                    <p>
                        In the selected time frame {timeFrame} are {displaySum}. You expect{' '}
                        {formatNumberToString(this.props.expectation, 2, unit)} in total for this time frame and your
                        quota configuration.
                    </p>
                    <Table
                        columns={[
                            { field: 'start', label: 'start', style: 'numeric' },
                            { field: 'end', label: 'end', style: 'numeric' },
                            { field: 'capacity', label: 'capacity', ...hoursColumn(unit) },
                            { field: 'workingDays', label: 'working days', ...daysColumn },
                            { field: 'dailyCapacity', label: 'daily capacity', ...hoursPerDayColumn(unit) },
                            { field: 'selectedWorkingDays', label: 'selected working days', ...daysColumn },
                            { field: 'selectedCapacity', label: 'selected capacity', ...hoursColumn(unit) },
                        ]}
                        rows={this.props.allocationResults}
                        onTableHeaderClicked={noOp}
                        hasStickyHeader
                    />
                    {this.renderExplanationWithSampleAllocation()}
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <p>
                    In the selected time frame {timeFrame} are {displaySum}. If you want to compare it to an expectation
                    of yours, please configure some quota in the widgets settings.
                </p>
                <p>
                    You can return to this explanation and it will contain detailed information about your quote and
                    it's impact, including intermediate results.
                </p>
            </React.Fragment>
        )
    }

    renderExplanationWithSampleAllocation() {
        const timeFrame = this.props.timeFrame
        const sampleAllocation =
            this.props.allocationResults.filter((a) => a.selectedCapacity)[0] || this.props.allocationResults[0]
        // const $sampleAllocation = (property: string) => sampleAllocation[property];
        const start = sampleAllocation.start
        const end = sampleAllocation.end
        const capacity = formatNumberToString(sampleAllocation.capacity, 2, this.props.unit)
        const workingDays = sampleAllocation.workingDays
        const dailyCapacity = formatNumberToString(sampleAllocation.dailyCapacity, 2, this.props.unit + '/d')
        const selectedWorkingDays = sampleAllocation.selectedWorkingDays
        const selectedCapacity = formatNumberToString(sampleAllocation.selectedCapacity, 2, this.props.unit)
        return (
            <React.Fragment>
                <p>
                    Between {start} and {end} there are {workingDays} working days. You configured a total expectation
                    of {capacity} for this time. Thus the mean expectation per day is {dailyCapacity} = {capacity} /{' '}
                    {workingDays} d.
                    <br />
                    The selected time frame, {timeFrame}, contains {selectedWorkingDays} of the overall {workingDays}{' '}
                    working days. Therefore we add {selectedCapacity} = {selectedWorkingDays} d * {dailyCapacity} to the
                    overall expectation.
                </p>
                <p>We treat all lines in your quota configuration in this fashion.</p>
            </React.Fragment>
        )
    }
}
