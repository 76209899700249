import React, { lazy, Suspense } from 'react'
import { SunburstWidgetProps } from './SunburstWidget.types'

const SunburstWidget = lazy(() => import(/* webpackChunkName: "SunBurstWidget" */ './SunburstWidget'))

const LazySunburstWidget: React.FC<SunburstWidgetProps> = (props) => (
    <Suspense fallback="">
        <SunburstWidget {...props} />
    </Suspense>
)

export default LazySunburstWidget
