import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, TempoOAuthConfiguration } from '../../types'
import { RootState } from '../../../types'

const tempoCloudSlice = createSlice({
    name: 'Data/Importer/Editing/TempoCloud',
    initialState: {} as DataState,
    reducers: {
        addTempoOAuthConfigurationToStore: {
            prepare: (tempoOAuthConfiguration: TempoOAuthConfiguration) => ({ payload: { tempoOAuthConfiguration } }),
            reducer: (state, action: PayloadAction<{ tempoOAuthConfiguration: TempoOAuthConfiguration }>) => {
                const tempoOAuthConfiguration = action.payload.tempoOAuthConfiguration
                state.importers.editing.typeSpecific.tempoCloud.tempoOAuthConfiguration = {
                    redirectionUri: tempoOAuthConfiguration.redirectionUri,
                    atlassianHostUrl: tempoOAuthConfiguration.atlassianHostUrl,
                }
            },
        },
        removeTempoOAuthToken: (state) => {
            delete state.importers.editing.currentlyEditedImporter?.configuration.tempo
        },
    },
})

export const actions = {
    ...tempoCloudSlice.actions,
}

export const reducer = tempoCloudSlice.reducer

export const selectors = {
    tempoOAuthConfiguration: (state: RootState) =>
        state.Data.importers.editing.typeSpecific.tempoCloud.tempoOAuthConfiguration,
}
