import React from 'react'
import ReactSelect, { components, PlaceholderProps, Styles } from 'react-select'
import AsyncCreatable from 'react-select/async-creatable'
import Async from 'react-select/async'
import Creatable from 'react-select/creatable'
import { SelectComponentsConfig } from 'react-select/src/components'
import { ControlProps } from 'react-select/src/components/Control'
import { SingleValueProps } from 'react-select/src/components/SingleValue'
import { OptionTypeBase } from 'react-select/src/types'
import { OptionProps } from 'react-select/src/components/Option'
import classnames from 'classnames'

import { AsyncCreatableSelectProps, AsyncSelectProps, CreatableSelectProps, SelectProps } from './Select.types'
import Icon, { IconNames } from '../Icon/Icon'

const Select = (props: SelectProps) => {
    return (
        <ReactSelect
            {...props}
            onChange={handleOnChange(props.onChange)}
            styles={getStyles(props.displayIconAsValue)}
            classNamePrefix="Select"
            className={getClassnames(props)}
            value={getValue(props)}
            options={getOptions(props)}
            components={getComponents(props)}
        />
    )
}

const CreatableSelect = (props: CreatableSelectProps) => {
    return (
        <Creatable
            {...props}
            onChange={handleOnChange(props.onChange)}
            styles={getStyles(props.displayIconAsValue)}
            classNamePrefix="Select"
            className={getClassnames(props)}
            value={getValue(props)}
            options={getOptions(props)}
            components={getComponents(props)}
        />
    )
}

const AsyncSelect = (props: AsyncSelectProps) => {
    return (
        <Async
            {...props}
            onChange={handleOnChange(props.onChange)}
            styles={getStyles(props.displayIconAsValue)}
            classNamePrefix="Select"
            className={getClassnames(props)}
            value={getValue(props)}
            options={getOptions(props)}
            components={getComponents(props)}
        />
    )
}

const AsyncCreatableSelect = (props: AsyncCreatableSelectProps) => {
    return (
        <AsyncCreatable
            {...props}
            onChange={handleOnChange(props.onChange)}
            styles={getStyles(props.displayIconAsValue)}
            classNamePrefix="Select"
            className={getClassnames(props)}
            value={getValue(props)}
            options={getOptions(props)}
            components={getComponents(props)}
        />
    )
}

// extract the actual value(s) from the Value/Option object(s)
const handleOnChange =
    (callback: (value: any) => void) => (value: OptionTypeBase | Array<OptionTypeBase> | null | undefined) => {
        if (value !== null && value !== undefined) {
            if (Array.isArray(value)) {
                value = value.map((it) => it.value)
            } else {
                value = value.value
            }
        }

        callback(value)
    }

// extract the actual value(s) from the Value/Option object(s)
const getValue = (props: SelectProps | AsyncSelectProps | AsyncCreatableSelectProps | CreatableSelectProps) => {
    const options = (props.isGrouped ? props.options.map((group) => group.options).flat() : props.options) || []
    const value = props.value

    if (Array.isArray(value)) {
        if (typeof value[0] === 'object') {
            return value
        }

        return value.map((val) => {
            return options.find((option) => option && option.value === val)
        })
    }

    if (typeof value === 'object') {
        return value
    }

    // check if value exists in options
    const valueFromOptions = options.find((option) => option && option.value === props.value)
    if (valueFromOptions) {
        return valueFromOptions
    }

    if (typeof value === 'string') {
        return {
            value,
            label: value,
        }
    }

    return null
}

// enables sorting the options alphabetically
const getOptions = (props: SelectProps | AsyncSelectProps | AsyncCreatableSelectProps | CreatableSelectProps) =>
    props.sortOptionsAlphabetically
        ? props.options.sort((e1, e2) => {
              const a = e1.label.toLowerCase()
              const b = e2.label.toLowerCase()
              return a < b ? -1 : b < a ? 1 : 0
          })
        : props.options

// styles (like invalid => red border)
const getClassnames = (props: SelectProps | AsyncSelectProps | AsyncCreatableSelectProps | CreatableSelectProps) => {
    let isValid = true

    if (typeof props.isValid === 'boolean') {
        isValid = props.isValid
    }

    return classnames('select', props.className, {
        'select--has-error': !isValid,
        'select--small-chevron': props.smallChevron,
    })
}

// custom components for styling / behaviour
const getComponents = (props: SelectProps | AsyncSelectProps | AsyncCreatableSelectProps | CreatableSelectProps) => {
    const ValueIcon = (ownProps: ControlProps<OptionTypeBase>) => (
        <components.Control {...ownProps}>
            <Icon name={props.displayIconAsValue!} useOwnColors />
            {ownProps.children}
        </components.Control>
    )
    const OptionWithIcon = (ownProps: OptionProps<OptionTypeBase>) => (
        <components.Option {...ownProps}>
            <Icon name={ownProps.data.iconName as IconNames} useOwnColors />
            <label>{ownProps.label}</label>
        </components.Option>
    )

    const SingleValueWithIcon = (ownProps: SingleValueProps<OptionTypeBase>) => (
        <components.SingleValue {...ownProps}>
            <Icon name={(ownProps.data.iconName as IconNames) || props.valueWithIcon} useOwnColors />
            {ownProps.children}
        </components.SingleValue>
    )
    const PlaceholderWithIcon = (ownProps: PlaceholderProps<OptionTypeBase>) => (
        <components.Placeholder {...ownProps}>
            <Icon name={props.valueWithIcon!} useOwnColors />
            {ownProps.children}
        </components.Placeholder>
    )

    let customComponents: SelectComponentsConfig<any> = {}

    if (props.components) {
        customComponents = props.components
    }

    if (props.displayIconAsValue) {
        customComponents.Control = ValueIcon
    }

    if (props.optionsWithIcons) {
        customComponents.Option = OptionWithIcon
        customComponents.SingleValue = SingleValueWithIcon
    }

    if (props.valueWithIcon) {
        customComponents.SingleValue = SingleValueWithIcon
        customComponents.Placeholder = PlaceholderWithIcon
    }

    return customComponents
}

// styles
const getStyles = (displayIconAsValue?: IconNames) => {
    const customStyles = STYLES

    if (displayIconAsValue) {
        customStyles.valueContainer = (provided) => ({
            ...provided,
            padding: '0',
        })
    }

    return customStyles
}

const STYLES: Styles = {
    control: (provided) => ({
        ...provided,
        minHeight: '28px',
        border: '1px solid #9b9b9b', // medium-grey
        borderRadius: '3px', // spacing--tiny
        boxShadow: 'inset 0 0 9px 0 rgba(0, 0, 0, .02)',
        color: 'black',
        fontWeight: 300,
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '4px',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: '4px',
    }),
    menu: (provided) => ({
        ...provided,
        maxHeight: '358px', // the 360 - 2 makes the border at the bottom visible
        marginTop: '-1px',
        boxShadow: 'none',
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '360px',
        border: '1px solid #ececec', // lighter-grey
        borderRadius: 0,
        boxShadow: 'none',
        color: 'black',
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: 300,
    }),
    option: (provided) => ({
        ...provided,
        padding: '3px 9px',
        color: 'black',
        fontFamily: 'inherit',
        fontSize: '12px',
        fontWeight: 300,
    }),
}

export { AsyncSelect, AsyncCreatableSelect, CreatableSelect }
export default Select
