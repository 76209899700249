import React from 'react'
import classnames from 'classnames'
import HelpTooltipIcon from '../HelpTooltip/HelpTooltipIcon'
import Icon, { IconNames } from '../../Atoms/Icon/Icon'
import { TooltipId } from '../../../StaticManifests/manifest.tooltips'

const FormFieldWithLabel = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const EditorComponent = props.configuration.editor
    const icon = props.configuration.icon
    const className = classnames('form-field-with-label', props.className, {
        'form-field-with-label--label-inline': props.isLabelInline,
    })

    const editorClassName = classnames('form-field-with-label__editor', {
        'form-field-with-label__editor--with-draggable-placeholder': props.configuration.alignWithDraggable,
    })

    return (
        <div className={className}>
            {(icon || props.configuration.label) && (
                <div className="form-field-with-label__label">
                    {icon ? (
                        <Icon name={icon} label={props.configuration.label} size="small" />
                    ) : (
                        <label>{props.configuration.label}</label>
                    )}
                    {props.configuration.helpTooltip && <HelpTooltipIcon id={props.configuration.helpTooltip} />}
                </div>
            )}
            <div className={editorClassName}>
                {EditorComponent && <EditorComponent {...props} ref={ref} {...props.configuration} />}
                {props.children}
                {props.configuration.isDraggable && <Icon name="GripIcon" size={'sm'} labelPosition={'right'} />}
            </div>
        </div>
    )
})

FormFieldWithLabel.displayName = 'FormFieldWithLabel'

type Props = {
    className?: string
    configuration: {
        icon?: IconNames
        label?: string
        editor?: React.FunctionComponent<any> | React.ComponentClass<any>
        placeholder?: string | number
        helpTooltip?: TooltipId
        isDraggable?: boolean // show the grip icon to show the field can be dragged
        alignWithDraggable?: boolean // when this is set, the editor should be aligned with other draggable
        [otherProp: string]: any // allow other props depending on editor
    }
    children?: React.ReactNode
    isLabelInline?: boolean
    [otherProps: string]: any
}

export default FormFieldWithLabel
