import React, { PureComponent } from 'react'
import classnames from 'classnames'

import requestImporterIcon from '../../../../Images/importerLogos/request_importer.svg'
import dummyImporterIcon from '../../../../Images/importerLogos/none.png'
import { ImporterType, ImporterTypeConfiguration } from '../../../../StaticManifests/manifest.importerTypes'

export type ImporterTypeConfigWithId = ImporterTypeConfiguration & { id: ImporterType }

export default class ImporterTypeSelector extends PureComponent<{
    selectedType?: string
    importerTypes: Array<ImporterTypeConfigWithId>
    onChange: (type: ImporterType) => void
}> {
    render() {
        return (
            <div className="importer-type-selector">
                {this.getImporterTypes().map((importerType) => (
                    <div
                        key={`importer-type-for-selection-${importerType.id}`}
                        className={classnames('importer-type-selector__entry', {
                            'importer-type-selector__entry--selected': this.props.selectedType === importerType.id,
                        })}
                        onClick={this.handleImporterTypeChange(importerType.id!)}
                        role="button"
                    >
                        {importerType.label}

                        <figure>
                            <img src={importerType.icon || dummyImporterIcon} alt={importerType.label + ' Logo'} />
                        </figure>
                    </div>
                ))}

                <a
                    href="mailto:support@exply.io?Subject=Importer%20Request&body=Hi Exply Team, I would like to have a new importer for ..."
                    className="importer-type-selector__entry"
                >
                    Request new importer
                    <figure>
                        <img src={requestImporterIcon} alt="Request own importer" />
                    </figure>
                </a>
            </div>
        )
    }

    getImporterTypes = () =>
        this.props.importerTypes
            .sort((e1, e2) => {
                const a = e1.label.toLowerCase()
                const b = e2.label.toLowerCase()
                return a > b ? 1 : b > a ? -1 : 0
            })
            .map((t) => ({ id: t.id, label: t.label, icon: t.icon }))

    handleImporterTypeChange = (newType: ImporterType) => () => this.props.onChange(newType)
}
