import React from 'react'
import produce from 'immer'

import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'

import FormFieldWithLabel from '../FormFieldWithLabel/FormFieldWithLabel'
import ordinalSuffixOf from '../../../Utility/OrdinalSuffix'
import ValueFieldEditor from '../ValueFieldEditor/ValueFieldEditor'
import DocumentFieldSelect from '../../Atoms/DocumentFieldSelect/DocumentFieldSelect'
import { OnTriggerAutoCompletion } from '../../Atoms/FieldFilterInput/FieldFilterInput'
import { FieldType } from '../../Atoms/FieldFilterInput/FieldFilterManifest'
import { SYMBOL_TYPE } from '../CalculatedFieldEditor/CalculatedFieldTokenEditor'
import { AnalysisTerm, ValueField } from '../../Organisms/Widgets/WidgetModel'
import { getDefaultAnalysisTermWithField } from '../../Organisms/Widgets/DefaultConfigurations'
import Button from '../../Atoms/Button/Button'
import Icon, { IconNames } from '../../Atoms/Icon/Icon'
import { OnChangeMultiple } from '../../Organisms/WidgetEditorForm/WidgetEditorForm.types'

type PivotTableValueField = { field: ValueField; format: string }
type ValuesType = Array<ValueField | FieldType | PivotTableValueField>

const FieldListEditor: React.FunctionComponent<{
    value?: ValuesType
    onChange: (values: any) => void // TODO type better: ValuesType is wrong here - i dont know what is fitting
    onTriggerAutoCompletion: OnTriggerAutoCompletion
    onChangeMultiple?: OnChangeMultiple
    configuration: {
        tags?: Array<string>
        labelFn: (value: any) => string // TODO type
        icon?: IconNames
        optionGenerator?: () => void
        limit?: number
        placeholder?: string
        clearable?: boolean
        withFilter?: boolean
        isGroupingFieldDisabled: boolean
        includePivotField?: boolean
    }
    widgetConfiguration: any
    widgetType?: string
}> = (props) => {
    const [currentlyAddedValue, setCurrentlyAddedValue] = React.useState('')

    const values = props.value || []

    const handleAdditionalFormFieldAdd = (values: ValuesType) => (field: string | ValueField) => {
        if (props.configuration.withFilter) {
            handleAddValueField(field as ValueField, values)
        } else {
            handleAddField(field as string, values)
        }
    }

    const handleAddValueField = (field: ValueField, values: ValuesType) => {
        const { onChange } = props

        if (values[0] && (values[0] as PivotTableValueField).field) {
            onChange([...values, { field }])
        } else {
            onChange([...values, field])
        }

        setCurrentlyAddedValue('')
    }

    const handleAddField = (field: string, values: ValuesType) => {
        const { onChange } = props
        // @ts-ignore
        onChange([...values, { field: field === '' ? values.slice(-1)[0].field : field }])
        setCurrentlyAddedValue('')
    }

    const handleValueChange = (values: ValuesType, index: number) => {
        return (newFieldValue: any) => {
            if (props.configuration.withFilter) {
                handleValueFieldValueChange(values, index, newFieldValue)
            } else {
                handleFieldValueChange(values, index, newFieldValue)
            }
        }
    }

    const handleValueFieldValueChange = (values: ValuesType, index: number, newFieldValue: any) => {
        const analysisTerm: AnalysisTerm = newFieldValue.analysisTerm
        const firstValueField = analysisTerm.find((token) => token.type === SYMBOL_TYPE.VALUE_FIELD)
        const firstValueFieldName = firstValueField && firstValueField.value
        if (firstValueFieldName) {
            // @ts-ignore
            if (props.value[0].field) {
                props.onChange(
                    produce(values, (draft) => {
                        // @ts-ignore
                        draft[index].field = newFieldValue
                    })
                )
            } else {
                props.onChange(
                    produce(values, (draft) => {
                        draft[index] = newFieldValue
                    })
                )
            }
        } else {
            props.onChange(
                produce(values, (draft) => {
                    draft.splice(index, 1)
                })
            )
        }
    }

    const handleFieldValueChange = (values: ValuesType, index: number, newFieldValue: string) => {
        if (newFieldValue) {
            props.onChange(
                produce(values, (draft) => {
                    // @ts-ignore
                    draft[index].field = newFieldValue
                })
            )
        } else {
            props.onChange(
                produce(values, (draft) => {
                    draft.splice(index, 1)
                })
            )
        }
    }

    const renderForm = (value: ValueField | FieldType | PivotTableValueField, index: number) => (
        <FormFieldWithLabel
            key={index}
            configuration={{
                label: props.configuration.labelFn({
                    ordinal: ordinalSuffixOf(index + 1),
                    index,
                }),
                icon: props.configuration.icon,
                editor: props.configuration.withFilter ? ValueFieldEditor : DocumentFieldSelect,
                tags: props.configuration.tags,
                optionGenerator: props.configuration.optionGenerator,
                placeholder: props.configuration.placeholder,
                clearable: index === 0 ? values.length > 1 : props.configuration.clearable,
                values,
                configuration: {
                    isGroupingFieldDisabled: props.configuration.isGroupingFieldDisabled,
                    areDateRangeFieldsAllowed: true,
                },
                isDraggable: true,
                includePivotField: props.configuration.includePivotField,
                widgetConfiguration: props.widgetConfiguration,
                widgetType: props.widgetType,
            }}
            onChange={handleValueChange(values, index)}
            onChangeMultiple={props.onChangeMultiple}
            value={(value as FieldType).field ? (value as FieldType).field : value}
            onTriggerAutoCompletion={props.onTriggerAutoCompletion}
        />
    )

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const newIndex = result.destination.index
        const oldIndex = result.source.index

        props.onChange(
            produce(values, (draft) => {
                ;[draft[newIndex], draft[oldIndex]] = [draft[oldIndex], draft[newIndex]]
            })
        )
    }

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {values.map((value, i: number) => (
                                <Draggable draggableId={`draggable-${i}`} index={i} key={'draggable-' + i}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            key={'draggable-item-' + i}
                                        >
                                            {renderForm(value, i)}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {!props.configuration.limit || values.length < props.configuration.limit ? (
                <Button
                    onClick={() =>
                        handleAdditionalFormFieldAdd(values)(
                            props.configuration.withFilter
                                ? {
                                      analysisTerm: getDefaultAnalysisTermWithField(currentlyAddedValue),
                                      filter: [],
                                  }
                                : currentlyAddedValue
                        )
                    }
                    type="primary"
                >
                    <Icon name="PlusIcon" color="white" />
                    Add further value
                </Button>
            ) : (
                ''
            )}
        </div>
    )
}

export default FieldListEditor
