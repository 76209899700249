import React from 'react'
import classnames from 'classnames'
import TextInput from '../../../Atoms/TextInput/TextInput'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import Select from '../../../Atoms/Select/Select'
import { SelectOption } from '../../../Atoms/Select/Select.types'

const schedulePresets = [
    { value: '0 0 * * 0', label: 'Every Week on Sunday' },
    { value: '0 0 * * *', label: 'Every Day' },
    { value: '0 /12 * * *  ', label: 'Every 12 Hours' },
    { value: '30 3 * * 1-5', label: 'Mon - Fri at 3:30 AM' },
    { value: '45 7,14 * * 1-5', label: 'Mon - Fri at 7:45 AM and 2:45 PM' },
    { value: '0 * * * *', label: 'Every Hour (recommend only for smaller imports)' },
]

const ImporterScheduleInput: React.FunctionComponent<{
    className?: string
    value: string
    onChange: (schedule: string) => void
    options: Array<SelectOption>
}> = (props) => (
    <div className={classnames('importer-schedule--configuration', props.className)}>
        <Select
            className="importer-schedule--presets"
            placeholder="Preset Schedules"
            value={props.value}
            onChange={props.onChange}
            options={props.options}
        />
        or
        <TextInput value={props.value} onChange={props.onChange} />
    </div>
)

const ImporterScheduleWithLabel: React.FunctionComponent<{
    className?: string
    value?: string
    onChange: (schedule: string) => void
}> = (props) => (
    <FormFieldWithLabel
        configuration={{
            label: 'Configure Import Schedule',
            helpTooltip: 'importerScheduling',
            editor: ImporterScheduleInput,
        }}
        className={props.className}
        value={props.value}
        onChange={props.onChange}
        options={schedulePresets}
    />
)

export const ImporterScheduleWithoutLabel: React.FunctionComponent<{
    className?: string
    value: string
    onChange: (schedule: string) => void
}> = (props) => (
    <ImporterScheduleInput
        className={props.className}
        value={props.value}
        onChange={props.onChange}
        options={schedulePresets}
    />
)

export default ImporterScheduleWithLabel
