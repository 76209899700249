import React from 'react'
import classnames from 'classnames'

const ProgressBar: React.FunctionComponent<{
    className?: string
    hasFailed?: boolean
    value: number
    max?: number
    messageLeft?: string | boolean
    messageCenter?: string | boolean
    noMessages?: boolean
    isWaiting?: boolean
}> = (props) => {
    const progress = props.max && (props.value / props.max) * 100
    const hasFailed = props.hasFailed
    const isRunning = !hasFailed && (!progress || progress < 100)
    const isDone = !hasFailed && progress && progress >= 100
    const className = classnames('progress-bar', props.className, {
        'progress-bar--animated': isRunning,
        'progress-bar--has-failed': hasFailed,
        'progress-bar--done': isDone,
        'progress-bar--waiting': props.isWaiting,
    })
    const meterWidth = progress || 100
    return (
        <div className={className}>
            <div className="progress-bar__bar">
                <div className="progress-bar__meter" style={{ width: meterWidth + '%' }} />
            </div>
            {!props.noMessages && (
                <div className="progress-bar__labels">
                    <div className="progress-bar__labels__left">{props.messageLeft}</div>
                    <div className="progress-bar__labels__center">{props.messageCenter}</div>
                    <div className="progress-bar__labels__right">{progress && progress.toFixed(0) + ' %'}</div>
                </div>
            )}
        </div>
    )
}

export default ProgressBar
