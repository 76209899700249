import React, { lazy, Suspense } from 'react'
import { SqlTableWidgetProps } from './SqlTableWidget'

const LazyWidget = lazy(() => import(/* webpackChunkName: "SqlTableWidget" */ './SqlTableWidget'))

const LazyWidgetWrapper: React.FC<SqlTableWidgetProps> = (props) => (
    <Suspense fallback="">
        <LazyWidget {...props} />
    </Suspense>
)

export default LazyWidgetWrapper
