import { createSelector } from 'reselect'
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, FieldsUIConfiguration } from './types'
import { RootState } from '../types'

type FieldIdentifier = string

type ConfigurationResponse = {
    userConfiguration: FieldsUIConfiguration
    typesUiDefaults: FieldsUIConfiguration
    fieldsCountByIdentifier: {
        [type: string]: number
    }
    typesByIdentifiers: {
        [type: string]: string
    }
}

const fieldsCustomizationSlice = createSlice({
    name: 'Data/FieldsCustomization',
    initialState: {} as DataState,
    reducers: {
        configurationDataLoaded: (state, action: PayloadAction<ConfigurationResponse>) => {
            const configurationResponse = action.payload
            // @ts-ignore type payload...
            state.typeUiConfiguration = configurationResponse
            // setup transient state for editing
            if (state.typeUiConfiguration) {
                state.typeUiConfiguration.transientUserConfiguration = configurationResponse.userConfiguration
            }
        },
        changeLabel: {
            prepare: (typeIdentifier: FieldIdentifier, label: string) => ({ payload: { typeIdentifier, label } }),
            reducer: (state, action) => {
                const { typeIdentifier } = action.payload
                let { label } = action.payload
                if (label === '') {
                    label = null
                }

                if (state.typeUiConfiguration) {
                    state.typeUiConfiguration.transientUserConfiguration.fieldsUiConfiguration[typeIdentifier].label =
                        label
                }
            },
        },
        changeIsVisibleInUi: {
            prepare: (typeIdentifier: FieldIdentifier, isVisibleInUi: boolean) => ({
                payload: { typeIdentifier, isVisibleInUi },
            }),
            reducer: (state, action) => {
                const { typeIdentifier, isVisibleInUi } = action.payload
                if (state.typeUiConfiguration) {
                    state.typeUiConfiguration.transientUserConfiguration.fieldsUiConfiguration[
                        typeIdentifier
                    ].isVisibleInUi = isVisibleInUi
                }
            },
        },
        resetSingle: {
            prepare: (typeIdentifier: FieldIdentifier) => ({ payload: { typeIdentifier } }),
            reducer: (state, action) => {
                const { typeIdentifier } = action.payload
                if (state.typeUiConfiguration) {
                    state.typeUiConfiguration.transientUserConfiguration.fieldsUiConfiguration[typeIdentifier] = {
                        isVisibleInUi: null,
                        label: null,
                    }
                }
            },
        },
        resetConfiguration: (state) => {
            const defaultFieldsConfiguration: {
                [index: string]: {
                    isVisibleInUi: null
                    label: null
                }
            } = {}
            // set null values on config objects for each field and document type
            if (state.typeUiConfiguration) {
                Object.keys(state.typeUiConfiguration.transientUserConfiguration.fieldsUiConfiguration).forEach(
                    (fieldIdentifier) => {
                        defaultFieldsConfiguration[fieldIdentifier] = { isVisibleInUi: null, label: null }
                    }
                )

                state.typeUiConfiguration.transientUserConfiguration = {
                    fieldsUiConfiguration: defaultFieldsConfiguration,
                }
            }
        },
        saveConfiguration: (state) => {
            if (state.typeUiConfiguration) {
                state.typeUiConfiguration.isSaving = true
            }
        },
    },
})

export const actions = {
    loadInitialData: createAction('EXPLY/DATA/TYPE_UI_CONFIGURATION/LOAD_INITIAL_DATA'),
    ...fieldsCustomizationSlice.actions,
}

export const reducer = fieldsCustomizationSlice.reducer

/**
 * selectors
 */

const isLoaded = (state: RootState) => state.Data.typeUiConfiguration !== undefined
const isSaving = (state: RootState) => state.Data.typeUiConfiguration?.isSaving
const getTransientConfig = (state: RootState) => state.Data.typeUiConfiguration?.transientUserConfiguration
const getConfig = (state: RootState) => state.Data.typeUiConfiguration?.userConfiguration
const getTransientConfigSelector = (state: RootState) =>
    state.Data.typeUiConfiguration?.transientUserConfiguration.fieldsUiConfiguration
const getDefaultValuesSelector = (state: RootState) =>
    state.Data.typeUiConfiguration?.typesUiDefaults.fieldsUiConfiguration
const getStatsSelector = (state: RootState) => state.Data.typeUiConfiguration?.fieldsCountByIdentifier
const getTypesSelector = (state: RootState) => state.Data.typeUiConfiguration?.typesByIdentifiers

// FACTORY for get all identifiers for type
const makeAllIdentifiers = createSelector(
    getDefaultValuesSelector,
    getStatsSelector,
    (configurationByIdentifier, statsByIdentifier) => {
        if (!configurationByIdentifier) {
            return []
        }

        const identifiers = Object.keys(configurationByIdentifier)
        const stats = statsByIdentifier
        // sort by usage count (first), then typeIdentifier alphabetically
        return identifiers.sort((o1, o2) => {
            if (stats) {
                const diffStats = (stats[o2] || 0) - (stats[o1] || 0)
                if (diffStats !== 0) {
                    return diffStats
                }

                if (o1.toLowerCase() === o2.toLowerCase()) {
                    return 0
                }

                return o1.toLowerCase() < o2.toLowerCase() ? -1 : 1
            }

            return 0
        })
    }
)

// FACTORY for get the transient document type configuration by id
const makeConfigurationForField = createSelector(
    getTransientConfigSelector,
    (state: RootState, fieldIdentifier: FieldIdentifier) => fieldIdentifier,
    (configurationByIdentifier, fieldIdentifier) => {
        if (configurationByIdentifier) {
            return configurationByIdentifier[fieldIdentifier]
        }
    }
)

// FACTORY for get document type default values by id
const makeDefaultValuesForField = createSelector(
    getDefaultValuesSelector,
    (state: RootState, fieldIdentifier: FieldIdentifier) => fieldIdentifier,
    (defaultValueByIdentifier, typeIdentifier) => {
        if (defaultValueByIdentifier) {
            return defaultValueByIdentifier[typeIdentifier]
        }
    }
)

// FACTORY for get the usage document count by id
const makeUsageDocCountForField = createSelector(
    getStatsSelector,
    (state: RootState, fieldIdentifier: FieldIdentifier) => fieldIdentifier,
    (statsByIdentifier, fieldIdentifier) => {
        if (statsByIdentifier) {
            return statsByIdentifier[fieldIdentifier] || 0
        }
    }
)

// FACTORY for get the type by id
const makeTypeForField = createSelector(
    getTypesSelector,
    (state: RootState, fieldIdentifier: FieldIdentifier) => fieldIdentifier,
    (typesByIdentifier, fieldIdentifier) => {
        if (typesByIdentifier) {
            return typesByIdentifier[fieldIdentifier] || 0
        }
    }
)

const hasTypeConfigurationChanged = createSelector(getTransientConfig, getConfig, (transientConfig, config) => {
    if (config) {
        return !Object.is(config, transientConfig)
    }

    return false
})

const isConfigurationEqualToDefaultConfig = createSelector(
    getTransientConfig,
    getDefaultValuesSelector,
    (transientConfig, defaultConfig) => {
        if (!transientConfig) {
            return true
        }

        if (defaultConfig) {
            const isNotEqualToDefaultConfig = Object.entries(transientConfig.fieldsUiConfiguration).some(
                ([fieldIdentifier, fieldConfig]) => {
                    const fieldLabel = fieldConfig.label
                    const isVisibleInUi = fieldConfig.isVisibleInUi
                    const defaultFieldLabel = defaultConfig[fieldIdentifier].label
                    const defaultIsVisibleInUi = defaultConfig[fieldIdentifier].isVisibleInUi

                    return (
                        (fieldLabel !== null && fieldLabel !== defaultFieldLabel) ||
                        (isVisibleInUi !== null && isVisibleInUi !== defaultIsVisibleInUi)
                    )
                }
            )

            return !isNotEqualToDefaultConfig
        }
    }
)

export const selectors = {
    makeAllIdentifiers,
    getTransientConfig,
    makeConfigurationForField,
    makeDefaultValuesForField,
    makeUsageDocCountForField,
    makeTypeForField,
    hasTypeConfigurationChanged,
    isConfigurationEqualToDefaultConfig,
    isLoaded,
    isSaving,
}
