import React, { createContext, PureComponent, useRef } from 'react'
import { SizeMe } from 'react-sizeme'
import EditDataSourceEditors from '../EditDataSourceEditors'
import SettingsView from '../../SettingsView/SettingsView'
import EditDataSourcePreview from '../EditDataSourcePreviewManager/Previews/EditDataSourcePreview'
import ImporterSchedule from '../ImporterSchedule/ImporterSchedule'
import Icon from '../../../Atoms/Icon/Icon'
import { CurrentlyEditedConfiguration, DataSourcePreviewData } from '../DataSource.types'
import { ImporterConfiguration } from '../../../../Redux/Data/types'
import { ImporterExtensionType } from '../../../../StaticManifests/manifest.importerExtensionTypes'
import { ImporterConfigurationStep, ImporterType } from '../../../../StaticManifests/manifest.importerTypes'

/**
 * This context is used to store the ref to the DataSourcePreview to enable the synchronized scrolling of the ImporterFieldMappingEditor
 * and the preview rendered below (in the last step of a CSV importer). Normally we would pass the ref down through the props,
 * but since the rendering of the editors is generic, the ref also gets passed to all other Editors being rendered. Some of them
 * don't accept refs (because they are fn-components without a forwardRef wrapper) and it's easier to use this context which we
 * can access in the Editor directly, than rewriting all editors to accept refs.
 */
export const DataSourcePreviewRefContext = createContext<React.MutableRefObject<HTMLDivElement | null> | null>(null)
/**
 * Renders the configuration menu of an importer consisting of two main parts:
 * - the form to change values
 * - the preview (given as prop from outside)
 * More Details in {@link EditImporterOrExtensionManager}
 */
const EditDataSourceEditorsAndPreview = (props: {
    currentlyEditedConfiguration: CurrentlyEditedConfiguration
    currentStepIndex: number
    previewIsOutdated: boolean
    preview?: DataSourcePreviewData
    configurationSuggestions: {
        [key: string]: any
    }
    onReloadPreview: () => void // fun()
    onChangeConfigurationProperty: (propertyName: string, newValue: any) => void
    onChangeSchedule: (value: string) => void
    dispatch: () => void // !!! ONLY !!! to be used to register custom actions in the Manifest (see csvFileDataSource)
    steps: Array<ImporterConfigurationStep>
    showNoSchedule?: boolean
    type?: ImporterType | ImporterExtensionType
}) => {
    const DataSourcePreviewRef = useRef<HTMLDivElement | null>(null)

    const renderEditorForm = () => {
        const steps = props.steps
        const stepIndex = props.currentStepIndex
        // When configuring importers we always want the schedule on the first screen. An extension has no schedule
        const showSchedule = !props.showNoSchedule && stepIndex === 0
        const Description = steps[stepIndex]?.description
        return (
            steps.length > 0 && (
                <div className="data-source--editors-and-schedule">
                    {/* renders Step description, done here to prevent large refactors */}
                    {Description && <Description />}
                    {/* renders all the little editors defined for one configuration step (importer or extension) */}
                    <EditDataSourceEditors
                        uuid={props.currentlyEditedConfiguration?.uuid}
                        definitionId={props.type}
                        configuration={props.currentlyEditedConfiguration?.configuration}
                        editors={steps[stepIndex].editors}
                        advancedEditors={steps[stepIndex].advancedEditors || []}
                        configurationSuggestions={props.configurationSuggestions}
                        onChange={props.onChangeConfigurationProperty}
                        dispatch={props.dispatch}
                    />
                    {showSchedule && (
                        <ImporterSchedule
                            onChange={props.onChangeSchedule}
                            // only importers have a schedule so we can safely assume that this is an importer
                            value={(props.currentlyEditedConfiguration as ImporterConfiguration)?.schedule}
                        />
                    )}
                </div>
            )
        )
    }

    const renderPreview = () => {
        const type = props.type
        if (type) {
            const { hasPreview } = props.steps[props.currentStepIndex]
            return (
                hasPreview && (
                    <Preview
                        previewIsOutdated={props.previewIsOutdated}
                        preview={props.preview}
                        hasPreview={hasPreview}
                        onReloadPreview={props.onReloadPreview}
                        tableRef={DataSourcePreviewRef}
                        className={
                            props.steps[props.currentStepIndex].isMappingStep
                                ? 'importer-config-preview csv-mapping-preview'
                                : 'importer-config-preview'
                        }
                    />
                )
            )
        }

        return ''
    }

    return (
        <DataSourcePreviewRefContext.Provider value={DataSourcePreviewRef}>
            <SettingsView main={renderEditorForm()} aside={renderPreview()} verticalOrientation={true} />
        </DataSourcePreviewRefContext.Provider>
    )
}

export default EditDataSourceEditorsAndPreview

class Preview extends PureComponent<{
    previewIsOutdated: boolean
    onReloadPreview: () => void
    hasPreview: boolean
    preview?: DataSourcePreviewData
    className: string
    tableRef: React.MutableRefObject<HTMLDivElement | null>
}> {
    render() {
        return (
            <SizeMe monitorHeight={true}>
                {({ size }) => (
                    <div className={this.props.className}>
                        <div className="importer-config-preview__headline-with-controls">
                            <h2>Preview</h2>
                            {this.props.previewIsOutdated && (
                                <Icon
                                    label="Reload"
                                    labelPosition="right"
                                    name="UndoIcon"
                                    title="Reload"
                                    onClick={this.props.onReloadPreview}
                                />
                            )}
                        </div>
                        <EditDataSourcePreview
                            height={(size && size.height) || undefined}
                            disabled={!this.props.hasPreview}
                            previewData={this.props.preview}
                            ref={this.props.tableRef}
                        />
                    </div>
                )}
            </SizeMe>
        )
    }
}
