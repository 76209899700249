import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

import { actions, selectors } from '../../../Redux'

import activeCollabLogo from '../../../Images/importerLogos/activeCollab.svg'
import actiTimeLogo from '../../../Images/importerLogos/actiTIME.svg'
import explyLogo from '../../../Images/exply_logo.svg'
import quoJobLogo from '../../../Images/importerLogos/QuoJob.svg'

import EditDataSourcePreviewManager from '../../../Components/Organisms/DataSources/EditDataSourcePreviewManager/EditDataSourcePreviewManager'
import Button from '../../../Components/Atoms/Button/Button'
import csvLogo from '../../../Images/importerLogos/csv.svg'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { ImporterConfiguration } from '../../../Redux/Data/types'
import { getImporterConfigurationSteps } from '../../../Components/Organisms/DataSources/Helpers'
import { fetchImporterStepData } from '../../../Sagas/ImporterSagas/ConfigPreview/ImporterStepDataSaga'
import importerTypes, { ImporterType } from '../../../StaticManifests/manifest.importerTypes'
import ButtonWithTooltip from '../../../Components/Molecules/HoverTooltip/ButtonWithTooltip'

const mapStateToProps = (state: RootState) => ({
    currentlyEditedImporter: selectors.Data.Importer.currentlyEditedImporter(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createNewImporter: () => dispatch(actions.Data.Importer.create()),
    onChangeType: (importerType: ImporterType, importerConfiguration: any) =>
        dispatch(actions.Data.Importer.changeType(importerType, importerConfiguration)),
    onChangeProperty: (propertyPath: string, value: any) =>
        dispatch(actions.Data.Importer.editConfiguration(propertyPath, value)),
    onChangeSchedule: (value: string) => dispatch(actions.Data.Importer.changeSchedule(value)),
    onChangeTitle: (value: string) => dispatch(actions.Data.Importer.changeTitle(value)),
    saveImporter: (importer: ImporterConfiguration) => dispatch(actions.Data.Importer.save(importer, '', true)),
    fetchPreviewData: (uuid: string, type: string, step: number, config: any, callback: any) =>
        dispatch(fetchImporterStepData(uuid, type, step, config, callback)),
    dispatch,
})

const availableImporterTypes: Array<{ type: ImporterType; logo: any; label?: string }> = [
    {
        type: 'activeCollab',
        logo: activeCollabLogo,
    },
    {
        type: 'actiTime',
        logo: actiTimeLogo,
    },
    {
        type: 'quoJob',
        logo: quoJobLogo,
    },
    {
        type: 'sampleDataGenerator',
        logo: explyLogo,
        label: 'Sample Data',
    },
    {
        type: 'csvFileDataSource',
        logo: csvLogo,
        label: 'CSV File',
    },
]

type SetImportersStepProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
type SetImportersStepState = {
    importerType: null | ImporterType
    currentStepIndex: number
}

class SetImportersStep extends React.PureComponent<SetImportersStepProps, SetImportersStepState> {
    constructor(props: SetImportersStepProps) {
        super(props)

        this.state = {
            importerType: null,
            currentStepIndex: 0,
        }
    }

    componentDidMount() {
        this.props.createNewImporter()
        this.props.onChangeSchedule('0 5 * * 1-7')
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    {this.renderImporterTypeSelector()}
                    {this.renderImporterConfiguration()}
                    {this.renderButtons()}
                </div>
            </React.Fragment>
        )
    }

    handleJumpStep = (offset: number) => () => this.setState({ currentStepIndex: this.state.currentStepIndex + offset })

    renderImporterTypeSelector() {
        return (
            <React.Fragment>
                <h1 className="setup-wizard__headline">Choose your Data Source</h1>
                <div className="setup-wizard__tile-wall">
                    {availableImporterTypes.map((importer, i) => {
                        const className = classnames('setup-wizard__tile', {
                            'setup-wizard__tile--active': importer.type === this.state.importerType,
                        })

                        return (
                            <div
                                className={className}
                                onClick={this.handleTileClick(importer.type)}
                                key={'setup-wizard__importer-type' + i}
                                role="button"
                            >
                                <figure>
                                    <img src={importer.logo} alt={(importer.label || importer.type) + ' logo'} />
                                </figure>
                                {importer.label}
                            </div>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

    handleTileClick = (typeId: ImporterType) => {
        return () => {
            const type = importerTypes[typeId]
            this.props.onChangeType(typeId, {})
            this.setState({ importerType: typeId, currentStepIndex: 0 })
            this.props.onChangeTitle(type.label)
        }
    }

    handleSaveImporter = () =>
        this.props.currentlyEditedImporter ? this.props.saveImporter(this.props.currentlyEditedImporter) : () => {}

    renderImporterConfiguration = () => {
        return (
            this.state.importerType &&
            this.props.currentlyEditedImporter && (
                <div className="setup-wizard__importer-editor">
                    <EditDataSourcePreviewManager
                        currentlyEditedConfiguration={this.props.currentlyEditedImporter}
                        currentlyEditedImporter={this.props.currentlyEditedImporter}
                        steps={getImporterConfigurationSteps(this.state.importerType)}
                        currentStepIndex={this.state.currentStepIndex}
                        onChangeProperty={this.props.onChangeProperty}
                        onChangeSchedule={this.props.onChangeSchedule}
                        fetchStepData={this.props.fetchPreviewData}
                        dispatch={this.props.dispatch}
                        type={this.props.currentlyEditedImporter?.definitionId}
                    />

                    {this.state.importerType === 'sampleDataGenerator' ? (
                        <p>
                            If you want to start exploring Exply without using your own data, we can generate generic
                            demo data for you.
                            <br />
                            <br />
                            You can add additional data sources anytime through the Importer settings.
                        </p>
                    ) : (
                        <p>
                            The importer is by default configured to run every night. Of course you can change this
                            later.
                        </p>
                    )}
                </div>
            )
        )
    }

    renderButtons() {
        const typeId = this.state.importerType
        const steps = typeId && getImporterConfigurationSteps(typeId)
        const { currentStepIndex } = this.state
        const hasPreviousStep = steps && currentStepIndex > 0
        const hasNextStep = steps && currentStepIndex + 1 < steps.length
        return (
            <div className="setup-wizard__next-button">
                {hasPreviousStep && <Button onClick={this.handleJumpStep(-1)}>Back</Button>}
                {hasNextStep && (
                    <Button type="primary" onClick={this.handleJumpStep(1)}>
                        Next Step
                    </Button>
                )}
                {!hasNextStep && (
                    <ButtonWithTooltip
                        isDisabled={!typeId}
                        header="Please select Importer Type"
                        onClick={this.handleSaveImporter}
                        type="primary"
                    >
                        Save & Next
                    </ButtonWithTooltip>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetImportersStep)
