import React, { MouseEvent } from 'react'
import classnames from 'classnames'
import Icon, { IconColor, IconNames } from '../Icon/Icon'

type ButtonSize = 'big' | 'small'

type ButtonType = 'positive'

const FloatingActionButton: React.FunctionComponent<{
    onClick: (event: MouseEvent) => void
    iconName: IconNames
    iconColor?: IconColor
    className?: string
    type?: ButtonType
    size?: ButtonSize
    title?: string
    disabled?: boolean
}> = (props) => {
    const className = classnames('floating-action-button', props.className, {
        ['floating-action-button--' + props.type]: props.type,
        ['floating-action-button--' + props.size]: props.size,
        'floating-action-button--disabled': props.disabled,
    })

    return (
        <button className={className} onClick={props.onClick} title={props.title} disabled={props.disabled}>
            <Icon
                name={props.iconName}
                size={props.size === 'small' ? 'xs' : undefined}
                color={props.iconColor || (props.type ? 'white' : undefined)}
            />
        </button>
    )
}

export default FloatingActionButton
