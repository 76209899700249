import * as React from 'react'
import { useContext } from 'react'
import classnames from 'classnames'
import produce from 'immer'
import { AnalysisToken } from '../../Organisms/Widgets/WidgetModel'
import Icon from '../../Atoms/Icon/Icon'
import Select, { CreatableSelect } from '../../Atoms/Select/Select'
import { FieldsConfigurationContext } from '../../Atoms/FieldsConfiguration/FieldsConfigurationContext'
import { SelectOption } from '../../Atoms/Select/Select.types'
import NumberPicker from '../../Atoms/NumberPicker/NumberPicker'
import { FieldsConfiguration } from '../../Atoms/FieldsConfiguration/FieldsConfigurationTypes'

// eslint-disable-next-line
export enum SYMBOL_TYPE {
    VALUE_FIELD = 'ValueField',
    OPERATOR = 'Operator',
    CONSTANT = 'Constant',
    MODE = 'Mode',
    OPEN_PARENTHESES = 'OpenParentheses',
    CLOSE_PARENTHESES = 'CloseParentheses',
}

export type SymbolType = 'ValueField' | 'Operator' | 'Constant' | 'Mode' | 'OpenParentheses' | 'CloseParentheses'

export type SingleTokenEditorSymbolType = 'ValueField' | 'Operator' | 'Constant' | 'ValueFieldOrConstant'

type CalculatedFieldSymbolEditorProps = {
    onChange: (value: AnalysisToken) => void
    value?: AnalysisToken
    type: SingleTokenEditorSymbolType
    isLastToken?: boolean
    onRemoveToken: () => void
}

type SingleTokenEditorProps = {
    value?: AnalysisToken
    onChange: (value: AnalysisToken) => void
}

function valueFieldsAsOptions(fieldsConfiguration: FieldsConfiguration) {
    return Object.values(fieldsConfiguration)
        .filter((element: any) => element.tag === 'NUMBER')
        .reduce((accumulator: Array<SelectOption>, element: any) => {
            accumulator.push({
                label: element.label,
                value: element.name,
            })
            return accumulator
        }, [])
}

const ValueFieldEditor = React.memo((props: SingleTokenEditorProps) => {
    const fieldsConfiguration = useContext(FieldsConfigurationContext)
    return (
        <Select
            key={'calculated-field-token-editor__value-field'}
            onChange={(value: string) => {
                if (props.value) {
                    props.onChange(
                        produce(props.value, (draft) => {
                            draft.value = value
                        })
                    )
                }
            }}
            value={props.value ? props.value.value : undefined}
            placeholder="..."
            options={valueFieldsAsOptions(fieldsConfiguration)}
            isSearchable={true}
            smallChevron
        />
    )
})

const OperatorEditor = React.memo((props: SingleTokenEditorProps) => (
    <Select
        key={'calculated-field-token-editor__operator'}
        onChange={(value: string) =>
            props.onChange({
                type: SYMBOL_TYPE.OPERATOR,
                value,
            })
        }
        value={props.value ? props.value.value : undefined}
        placeholder="..."
        options={[
            {
                value: '+',
                label: '+',
            },
            {
                value: '-',
                label: '-',
            },
            {
                value: '*',
                label: '*',
            },
            {
                value: '/',
                label: '/',
            },
        ]}
        isSearchable={false}
        smallChevron
    />
))

const ConstantEditor = React.memo((props: SingleTokenEditorProps) => (
    <NumberPicker
        key={'calculated-field-token-editor__constant'}
        onChange={(value: number | null) => {
            if (props.value) {
                props.onChange(
                    produce(props.value, (draft) => {
                        draft.value = value === null ? '' : value.toString()
                    })
                )
            }
        }}
        value={props.value ? props.value.value : undefined}
        placeholder={1}
        autoFocus
    />
))

const ValueFieldOrConstantEditor = React.memo((props: SingleTokenEditorProps) => {
    const fieldsConfiguration = useContext(FieldsConfigurationContext)
    return (
        <CreatableSelect
            key={'calculated-field-token-editor__value-field-or-constant'}
            onChange={(value: string) =>
                props.onChange({
                    type:
                        value === SYMBOL_TYPE.CONSTANT || !Number.isNaN(Number(value))
                            ? SYMBOL_TYPE.CONSTANT
                            : SYMBOL_TYPE.VALUE_FIELD,
                    value: value === SYMBOL_TYPE.CONSTANT ? '1' : value,
                })
            }
            placeholder="..."
            isGrouped
            value={props.value ? props.value.value : undefined}
            smallChevron
            isValidNewOption={(inputValue: string) => !Number.isNaN(Number(inputValue))}
            options={[
                {
                    label: 'Number',
                    options: [
                        {
                            label: 'Enter a constant...',
                            value: SYMBOL_TYPE.CONSTANT,
                        },
                    ],
                },
                {
                    label: 'Valuefield',
                    options: valueFieldsAsOptions(fieldsConfiguration),
                },
            ]}
        />
    )
})

function getSingleTokenEditor(type: SingleTokenEditorSymbolType): React.FunctionComponent<SingleTokenEditorProps> {
    switch (type) {
        case 'ValueField':
            return ValueFieldEditor
        case 'Operator':
            return OperatorEditor
        case 'Constant':
            return ConstantEditor
        case 'ValueFieldOrConstant':
            return ValueFieldOrConstantEditor
    }
}

const CalculatedFieldTokenEditor = (props: CalculatedFieldSymbolEditorProps) => {
    const className = classnames('calculated-field--token-editor', {
        'calculated-field--token-editor__last-token': props.isLastToken,
        'calculated-field--token-editor__operator': props.type === 'Operator',
        'calculated-field-token-editor__value-field': props.type === 'ValueField',
    })
    const Editor = getSingleTokenEditor(props.type)
    return (
        <div className={className}>
            <Editor {...props} />
            {props.isLastToken && (
                <Icon
                    className="calculated-field--token-editor__last-token--icon"
                    name="TimesIcon"
                    size="xs"
                    color="error"
                    onClick={props.onRemoveToken}
                />
            )}
        </div>
    )
}

export default React.memo(CalculatedFieldTokenEditor)
