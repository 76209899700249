import React, { useContext } from 'react'
import Select from '../Select/Select'
import { FieldConfiguration, TagsType } from '../FieldsConfiguration/FieldsConfigurationTypes'
import { FieldsConfigurationContext } from '../FieldsConfiguration/FieldsConfigurationContext'
import { IconNames } from '../Icon/Icon'

export type Option = {
    value: string | number
    label: string
}

type DocumentFieldSelectProps = {
    value?: string | null
    values?: Array<DocumentFieldSelectValue>
    onChange: (value: any) => void
    tags?: TagsType
    optionGenerator?: (element: FieldConfiguration) => Array<Option> // if given, needs to generate the "option" values from the "field"
    additionalOptions?: Array<Option>
    placeholder?: string
    className?: string
    isValid?: boolean
    clearable?: boolean
    disabled?: boolean
    displayIconAsValue?: IconNames
    valueWithIcon?: IconNames
    smallChevron?: boolean
    searchable?: boolean
}

type DocumentFieldSelectValue = {
    field: string
}

const defaultOptionGenerator = (element: FieldConfiguration) => [
    {
        label: element.label,
        value: element.name,
    },
]

const DocumentFieldSelect: React.FunctionComponent<DocumentFieldSelectProps> = (props) => {
    const fieldsConfiguration = useContext(FieldsConfigurationContext)

    const optionGenerator = props.optionGenerator || defaultOptionGenerator

    const selectedValues = props.values || []
    const additionalOptions = (props.additionalOptions || []).filter(
        (element) =>
            // each element should only be selectable once, so we remove the already selected ones
            !(selectedValues.some((value) => value.field === element.value) && element.value !== props.value)
    )

    // same applies docCount, so we only want to add it as option if it's not already selected
    if (
        props.tags &&
        props.tags.includes('NUMBER') &&
        (!selectedValues.some((v) => v.field === 'docCount') || props.value === 'docCount')
    ) {
        additionalOptions.push({ label: 'Number of Entries', value: 'docCount' })
    }

    const options = Object.values(fieldsConfiguration)
        // only use fields of correct type
        .filter((element: FieldConfiguration) => !props.tags || props.tags.indexOf(element.tag) !== -1)
        // each element should only be selectable one, so we remove the already selected ones
        .filter(
            (element: FieldConfiguration) =>
                !(selectedValues.some((v) => v.field === element.name) && element.name !== props.value)
        )
        .reduce((accumulator: Array<Option>, element: FieldConfiguration) => {
            const options = optionGenerator(element)
            accumulator.push(...options)
            return accumulator
        }, additionalOptions)

    // if the current value is no longer available we show it as a placeholder
    const selectionMissing = props.value && !options.some((option: Option) => option.value === props.value)
    return (
        <Select
            className={props.className}
            options={options}
            value={props.value}
            onChange={props.onChange}
            placeholder={selectionMissing ? props.value : props.placeholder || 'Please select a field'}
            isValid={props.isValid}
            isDisabled={props.disabled}
            isClearable={props.clearable}
            sortOptionsAlphabetically
            displayIconAsValue={props.displayIconAsValue}
            valueWithIcon={props.valueWithIcon}
            smallChevron={props.smallChevron}
            isSearchable={props.searchable}
        />
    )
}

DocumentFieldSelect.defaultProps = {
    clearable: true,
}

export default DocumentFieldSelect
