import React from 'react'
import activeCollabIcon from '../Images/importerLogos/activeCollab.svg'
import quoJobIcon from '../Images/importerLogos/QuoJob.svg'
import jiraIcon from '../Images/importerLogos/jira.svg'
import csvIcon from '../Images/importerLogos/csv.svg'
import ImporterSourceSelector from '../Containers/SourceSelectorContainer'
import mysqlIcon from '../Images/importerLogos/mysql.svg'
import mariadbIcon from '../Images/importerLogos/maria_db.svg'
import postgresIcon from '../Images/importerLogos/postgresql.svg'
import msSqlIcon from '../Images/importerLogos/ms_sql.svg'
import oracleIcon from '../Images/importerLogos/oracle.svg'
import braintreeIcon from '../Images/importerLogos/braintreeLogo.png'
import bigQueryIcon from '../Images/importerLogos/google-bigquery.svg'
import xmlIcon from '../Images/importerLogos/xml.svg'
import sampleDataIcon from '../Images/exply_logo.svg'
import actiTimeIcon from '../Images/importerLogos/actiTIME.svg'
import oekokisteIcon from '../Images/importerLogos/logo_oekokiste_coloured.svg'
import {
    csvParsingStep,
    csvSourceFileStep,
    databaseDataSourceConfiguration,
    databaseQueryStep,
    defaultRequiredPropertyValidator,
    editorWithLabel,
    fieldMappingConfigurationStep,
    flag,
    genericSqlDataSourceType,
    numberPicker,
    OnNextStepHandler,
    passwordField,
    textField,
} from './manifest.importerTypes.buildingBlocks'
import { CsvParserSettings } from './manifest.importerExtensionTypes'
import TempoCloudConfigurationDescription from '../Containers/Importers/TempoCloud/TempoCloudConfigurationDescription'
import ProjectSelectContainer from '../Containers/Importers/JiraCloudConnectAppImporter/ProjectSelectContainer'
import { ImporterConfiguration, TypeSpecificConfiguration } from '../Redux/Data/types'
import TempoCloudResetOAuthButton from '../Containers/Importers/TempoCloud/TempoCloudResetOAuthButton'

const importerTypes: ImporterTypes = {
    activeCollab: {
        label: 'ActiveCollab',
        icon: activeCollabIcon,
        editors: databaseDataSourceConfiguration,
    },
    quoJob: {
        label: 'QuoJob',
        icon: quoJobIcon,
        editors: databaseDataSourceConfiguration,
    },
    jiraCloud: {
        label: 'JIRA Cloud',
        isHidden: true,
        icon: jiraIcon,
        configurationSteps: [
            {
                title: 'Import Options',
                hasPreview: false,
                editors: [editorWithLabel('projects', 'Projects', ProjectSelectContainer)],
            },
            // NOTE: because of the onSave callback this has to be the last step in order to work
            {
                title: 'Tempo Integration (Optional)',
                description: TempoCloudConfigurationDescription,
                hasPreview: false,
                editors: [
                    textField(
                        'tempo.clientId',
                        'Client-ID',
                        undefined,
                        undefined,
                        undefined,
                        ({ configuration }: { configuration: ImporterConfiguration['configuration'] }) => ({
                            disabled: Boolean(configuration?.tempo?.oAuthTokens),
                        })
                    ),
                    passwordField(
                        'tempo.clientSecret',
                        'Client Secret',
                        undefined,
                        undefined,
                        undefined,
                        ({ configuration }: { configuration: ImporterConfiguration['configuration'] }) => ({
                            disabled: Boolean(configuration?.tempo?.oAuthTokens),
                        })
                    ),
                    numberPicker(
                        'tempo.plannerTimeRange',
                        'Time Range for Tempo Planner Data into the past and future',
                        {
                            placeholder: 365,
                            min: 0,
                            max: 9999,
                            step: 1,
                        }
                    ),
                    {
                        importerConfigurationProperty: '',
                        component: TempoCloudResetOAuthButton,
                        editorContextBuilder: undefined,
                        configuration: {},
                    },
                ],
                onSaveCallback:
                    (config?: ImporterConfiguration, typeSpecificConfig?: TypeSpecificConfiguration) => () => {
                        if (config?.configuration?.tempo?.oAuthTokens) {
                            return
                        }

                        if (config && typeSpecificConfig?.tempoCloud.tempoOAuthConfiguration) {
                            const tempoOAuthConfiguration = typeSpecificConfig?.tempoCloud.tempoOAuthConfiguration

                            const atlassianHostUrl = tempoOAuthConfiguration.atlassianHostUrl
                            const redirectionUri = tempoOAuthConfiguration.redirectionUri
                            const clientId = config.configuration.tempo.clientId
                            if (window.top) {
                                window.top.location.href = `${atlassianHostUrl}/plugins/servlet/ac/io.tempo.jira/oauth-authorize/?client_id=${clientId}&redirect_uri=${redirectionUri}&access_type=tenant_user`
                            }
                        }
                    },
                customSaveButtonLabel: {
                    conditionValidator: (config?: ImporterConfiguration) => {
                        // no oAuthToken has been set yet && but user has entered some auth data
                        return (
                            !config?.configuration?.tempo?.oAuthTokens &&
                            (config?.configuration?.tempo?.clientId || config?.configuration?.tempo?.clientSecret)
                        )
                    },
                    label: 'Grant Access',
                },
                runImporterAfterSave: false,
                continueEditingImporterAfterSave: true,
            },
        ],
    },
    csvFileDataSource: {
        label: 'CSV File',
        icon: csvIcon,
        configurationInitializer: () => ({
            parserSettings: {
                separator: ';',
            },
        }),
        configurationSteps: [csvSourceFileStep, csvParsingStep(), fieldMappingConfigurationStep(false)],
    },
    genericMysqlDataSource: genericSqlDataSourceType('MySQL', mysqlIcon, () => ({
        jdbcConnection: 'jdbc:mysql://my-server:3306/my-database',
    })),
    genericMariadbDataSource: genericSqlDataSourceType('MariaDB', mariadbIcon, () => ({
        jdbcConnection: 'jdbc:mariadb://my-server:3306/my-database',
    })),
    genericPostgresDataSource: genericSqlDataSourceType('PostgreSQL', postgresIcon, () => ({
        jdbcConnection: 'jdbc:postgresql://my-server:5432/my-database',
    })),
    genericSqlServerDataSource: genericSqlDataSourceType('Microsoft SQL', msSqlIcon, () => ({
        jdbcConnection: 'jdbc:sqlserver://my-server:1433;databaseName=my-database',
    })),
    genericOracleDataSource: genericSqlDataSourceType('Oracle', oracleIcon, () => ({
        jdbcConnection: 'jdbc:oracle:thin:@my-server:1521:my-database',
    })),
    genericGoogleBigQueryDataSource: {
        label: 'Generic Google BigQuery',
        icon: bigQueryIcon,
        configurationSteps: [
            {
                title: 'Connection',
                hasPreview: false,
                editors: [
                    textField('projectId', 'Project ID'),
                    textField('serviceAccountEmail', 'Service Account Email'),
                    editorWithLabel('keyFile', 'JSON key file', ImporterSourceSelector, {
                        fileType: 'json',
                        allowedOptions: ['upload', 'path'],
                    }),
                ],
            },
            databaseQueryStep,
            fieldMappingConfigurationStep(false),
        ],
    },
    braintree: {
        label: 'Braintree',
        icon: braintreeIcon,
        configurationSteps: [
            {
                title: 'Braintree Settings',
                hasPreview: false,
                editors: [
                    textField('merchant_id', 'Merchant ID'),
                    textField('public_key', 'Public Key'),
                    passwordField('private_key', 'Private Key'),
                    passwordField('start_date', 'Start Date'),
                    passwordField('environment', 'Environment'),
                ],
            },
        ],
    },
    xmlFileDataSource: {
        label: 'XML File',
        icon: xmlIcon,
        configurationSteps: [
            {
                title: 'Source File',
                hasPreview: false,
                requiredProperties: [
                    {
                        propertyPath: 'source.name',
                        propertyValidator: defaultRequiredPropertyValidator,
                    },
                ],
                requiredPropertiesMessage: 'Source File required',
                editors: [editorWithLabel('source', 'Source', ImporterSourceSelector, { fileType: 'xml' })],
            },
            {
                title: 'Parsing',
                hasPreview: true,
                requiredProperties: [
                    {
                        propertyPath: 'readerSettings.xPath',
                        propertyValidator: defaultRequiredPropertyValidator,
                    },
                ],
                requiredPropertiesMessage: 'xPath required',
                editors: [textField('readerSettings.xPath', 'xPath Selector', '/bookstore/books', 'importerXPath')],
            },
            fieldMappingConfigurationStep(false),
        ],
    },
    sandstormManyTimeRecordsDataSource: {
        // hidden importers need to be white-listed in the feature-flags.json in order to be used
        isHidden: true,
        label: '(Sandstorm) Dummy Time Records',
        editors: [textField('limit', 'Limit')],
        icon: sampleDataIcon,
    },
    sampleDataGenerator: {
        label: 'Sample Data',
        icon: sampleDataIcon,
        editors: [],
    },
    actiTime: {
        label: 'actiTime',
        icon: actiTimeIcon,
        editors: databaseDataSourceConfiguration,
    },
    oekokiste: {
        // hidden importers need to be white-listed in the feature-flags.json in order to be used
        isHidden: true,
        label: 'Ökokiste',
        icon: oekokisteIcon,
        editors: databaseDataSourceConfiguration.concat([
            flag('isInconsistencyAllowed', 'Inkonsistenz erlauben'),
            numberPicker('timeRangeInDays', 'Tage für die Daten aktualisiert werden sollen', {
                placeholder: 'Alle',
                min: 0,
            }),
        ]),
    },
}

export type ImporterTypes = Record<ImporterType, ImporterTypeConfiguration>

export type ImporterType =
    | 'activeCollab'
    | 'quoJob'
    | 'jiraCloud'
    | 'csvFileDataSource'
    | 'genericMysqlDataSource'
    | 'genericMariadbDataSource'
    | 'genericPostgresDataSource'
    | 'genericSqlServerDataSource'
    | 'genericOracleDataSource'
    | 'genericGoogleBigQueryDataSource'
    | 'braintree'
    | 'xmlFileDataSource'
    | 'sandstormManyTimeRecordsDataSource'
    | 'sampleDataGenerator'
    | 'actiTime'
    | 'oekokiste'

export type ImporterTypeConfiguration = {
    label: string
    icon: string
    editors?: Array<ImporterEditor>
    configurationInitializer?: () => { parserSettings?: CsvParserSettings; jdbcConnection?: string }
    configurationSteps?: Array<ImporterConfigurationStep>
    isHidden?: boolean
}

export type ImporterConfigurationStep = {
    title?: string
    description?: React.FC
    hasPreview?: boolean
    hasConfigSuggestion?: boolean
    isMappingStep?: boolean
    requiredProperties?: Array<{
        propertyPath: string
        propertyValidator: (requiredValue: any) => boolean
    }>
    requiredPropertiesMessage?: string
    editors: Array<ImporterEditor>
    advancedEditors?: Array<ImporterEditor>
    onNextStep?: OnNextStepHandler
    onSaveCallback?: (config?: ImporterConfiguration, typeSpecificConfig?: TypeSpecificConfiguration) => () => void
    customSaveButtonLabel?: {
        conditionValidator: (config?: ImporterConfiguration) => boolean
        label: string
    }
    runImporterAfterSave?: boolean
    continueEditingImporterAfterSave?: boolean
}

type ImporterEditorComponentProps = {
    configuration: any
    value: any
    onChange: (newValue: any) => void
    // the editor context gets spreaded as props, which means that actually, we have the three props above plus all entries
    // of the object returned by the editorContextBuilder, which would be hard to type
    // see EditDataSourceEditors.tsx
    [key: string]: any
}

export type ImporterEditor = {
    importerConfigurationProperty: string
    component: React.ComponentType<ImporterEditorComponentProps>
    configuration: { [key: string]: any }
    editorContextBuilder?: ((config: EditorContextBuilderConfig) => { [key: string]: any }) | null
}

export type EditorContextBuilderConfig = {
    uuid?: string
    definitionId?: string
    configuration: any
    dispatch: () => void
    configurationSuggestions: {
        [key: string]: any
    }
}

export default importerTypes
