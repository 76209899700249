import React, { lazy, Suspense } from 'react'
import { TimeSeriesChartProps } from './TimeSeriesChart.types'

const LazyWidget = lazy(() => import(/* webpackChunkName: "TimeSeries" */ './TimeSeriesChart'))

const LazyWidgetWrapper: React.FC<TimeSeriesChartProps> = (props) => (
    <Suspense fallback={''}>
        <LazyWidget {...props} />
    </Suspense>
)

export default LazyWidgetWrapper
