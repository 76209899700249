import { tz } from 'moment-timezone'
import { TimeSelectorWidgetConfiguration } from './TimeSelectorWidget'
import dateRanges from '../../../../StaticManifests/manifest.dateRanges'

export default function (config: TimeSelectorWidgetConfiguration) {
    const timespan = config.defaultTimespan
    const availablePredefinedTimespans = config.availablePredefinedTimespans
    const selectedPredefinedTimespan = timespan.selectedPredefinedTimespan

    let startDate
    let endDate
    if (selectedPredefinedTimespan) {
        const dateRange = dateRanges[selectedPredefinedTimespan].getDateRange({
            workingDays: config.workingDays,
            showLastXDays: config.showLastXDays,
        })

        startDate = dateRange.startDate
        endDate = dateRange.endDate
    } else {
        startDate = timespan.startDate
        endDate = timespan.endDate
    }

    // As initially selected timezone we use the following priority:
    //  (1. Widget configuration - which currently initializes with null and cannot be set)
    //  2. the browsers default timezone
    // There is also a time zone setting in Exply, but it is global and meant for the server. The client may be in a very
    // different time zone than the server and other users of this Exply instance.
    const selectedTimeZone = config.defaultSelectedTimeZone || tz.guess()

    // start and end date can be null if there is no limit (e.g. all time option)
    return {
        selectedPredefinedTimespan,
        availablePredefinedTimespans,
        startMilliseconds: startDate ? startDate.valueOf() : null,
        endMilliseconds: startDate ? endDate?.valueOf() : null,
        selectedTimeZone,
        // overrides start and end if set
        forecastPeriod: null,
    }
}
