import { put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions } from '../../Redux'
import axios from '../Utility/axios'
import { UserOrGroupId } from '../../Redux/Data/UserManagement'

export const removeUserOrGroup = createAction('Data/UserManagement/RemoveUserOrGroup', (id: UserOrGroupId) => ({
    payload: { id },
}))

export default function* RemoveUserSaga() {
    yield takeLatest(removeUserOrGroup, function* (action) {
        try {
            const { id } = action.payload
            const response: AxiosResponse = yield axios({
                method: 'DELETE',
                url: 'admin/userManagement/remove',
                data: { user: id },
            })
            if (response.status < 400) {
                yield put(actions.Data.UserManagement.removeUserFinished(id))
                yield put(push('/user-management/'))
                yield put(actions.Data.UserManagement.clearEditing())
            } else {
                yield put(actions.UI.Notification.show({ id: 'userOrGroupRemoveFailed' }))
            }
        } catch (e) {
            console.error(e)
        }
    })
}
