import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import uuid from 'uuid'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { AccessMode, DashboardId } from '../../../Redux/Data/types'
import EmbeddedDashboard from './EmbeddedDashboard'
import ExplyDashboard from './ExplyDashboard'
import { actions, selectors } from '../../../Redux'
import Button from '../../../Components/Atoms/Button/Button'

type DashboardContainerOwnProps = {
    match: {
        params: {
            dashboardId: DashboardId | 'new'
        }
    }
    isEmbedded?: boolean
}

const mapStateToProps = (state: RootState, ownProps: DashboardContainerOwnProps) => ({
    dashboardExists: selectors.Data.Dashboards.ViewMode.dashboardExists(state, ownProps.match.params.dashboardId),
    inEditMode: selectors.Data.Dashboards.DashboardEditMode.isDashboardEditModeActive(state),
    hasDashboardBeenModified: selectors.Data.Dashboards.DashboardEditMode.hasDashboardBeenModified(state),
    reloadConfirmation: selectors.Data.FeatureFlags.reloadConfirmationFlag(state),
    applicationMode: selectors.Data.System.applicationMode(state),
    accessMode: selectors.Data.System.accessMode(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createNewDashboard: (dashboardId: DashboardId) => {
        dispatch(actions.Data.Dashboards.DashboardEditMode.addDashboard({ dashboardId }))
        dispatch(push(`/dashboard/${dashboardId}`))
    },
    disableEditMode: () => dispatch(actions.Data.Dashboards.DashboardEditMode.disableEditMode()),
})

type DashboardContainerProps = DashboardContainerOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    RouteComponentProps

class DashboardContainer extends PureComponent<DashboardContainerProps> {
    render() {
        if (!this.props.dashboardExists) {
            return (
                <div className="dashboard no-dashboard-message">
                    <p>This dashboard seems to have moved.</p>
                    <Button onClick={() => this.props.history.push('/')} type={'primary'}>
                        Go to dashboard overview
                    </Button>
                </div>
            )
        }

        const dashboardId = this.props.match.params.dashboardId
        const isEmbedded = this.props.accessMode === AccessMode.SHARED_DASHBOARD || this.props.isEmbedded
        const DashboardComponent = isEmbedded ? EmbeddedDashboard : ExplyDashboard
        return <DashboardComponent dashboardId={dashboardId} inEditMode={this.props.inEditMode} />
    }

    componentDidMount() {
        // called via "index.html#/dashboard/new" to create a new dashboard ?
        const directLinkToDashboardCreation = this.props.match.params.dashboardId === 'new'
        if (directLinkToDashboardCreation) {
            this.props.createNewDashboard(uuid())
        }

        window.addEventListener('beforeunload', this.onClose)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onClose)
        if (this.props.inEditMode) {
            this.props.disableEditMode()
        }
    }

    onClose = (event: any) => {
        if (this.props.inEditMode && this.props.hasDashboardBeenModified && this.props.reloadConfirmation) {
            const message = 'You are currently editing a dashboard. Are You sure You want to leave?'
            event.preventDefault()
            // for Chrome, Firefox
            event.returnValue = message
            // for Safari
            return message
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardContainer))
