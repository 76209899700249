import BurnChartWidget from './LazyBurnChartWidget'
// @ts-ignore file extension
import BurnChartWidgetIcon from './BurnChartWidget.Icon.tsx'
import BurnChartWidgetStateInitializer from './BurnChartWidget.init'
import BurnChartWidgetConfigurationInitializer, {
    BurnChartConfigurationInitializerForWidgetExplorer,
} from './BurnChartWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import {
    trendLine,
    groupingFieldOptionGenerator,
    WidgetManifestConfiguration,
} from '../../../../widgetManifestBuildingBlocks'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import ValueFieldEditor from '../../../Molecules/ValueFieldEditor/ValueFieldEditor'
import ManualQuotaEditor from '../../../Molecules/QuotaEditor/ManualQuotaEditor/ManualQuotaEditor'
import JiraQuotaEditor from '../../../Molecules/QuotaEditor/JiraQuotaEditor/JiraQuotaEditor'

type BurnChartTypes = 'burnChart' | 'jiraBurnChart'

export default (type: BurnChartTypes): WidgetManifestConfiguration => ({
    name: type === 'burnChart' ? 'Burn Down Chart' : 'Sprint Burndown Chart',
    isJiraWidget: type !== 'burnChart',
    component: BurnChartWidget,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 1,
        },
        max: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 1,
        },
    },
    icon: BurnChartWidgetIcon,
    stateInitializationFunction: BurnChartWidgetStateInitializer,
    configurationInitializer: BurnChartWidgetConfigurationInitializer(type),
    configurationInitializerForWidgetExplorer: BurnChartConfigurationInitializerForWidgetExplorer(type),

    editors: {
        data: [
            {
                widgetConfigurationProperty: 'quota',
                component: FormFieldWithLabel,
                configuration: {
                    label: type === 'burnChart' ? 'Resources' : 'Sprint',
                    icon: 'SyncIcon',
                    editor: type === 'burnChart' ? ManualQuotaEditor : JiraQuotaEditor,
                },
            },
            {
                widgetConfigurationProperty: 'chartGroups.2.valueField',
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Value Field',
                    icon: 'AnalyticsIcon',
                    editor: ValueFieldEditor,
                    tags: ['NUMBER'],
                    optionGenerator: groupingFieldOptionGenerator,
                    configuration: {
                        isModeDisabled: true,
                        isGroupingFieldDisabled: true,
                        isIgnoreTimeSelectionDisabled: true,
                        areDateRangeFieldsAllowed: false,
                    },
                    clearable: false,
                    helpTooltip: 'burnChartValueField',
                    includePivotField: true,
                },
            },
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.granularity(),
                    buildingBlocks.flag('isSummingUpQuotas', 'Treat all resources as one', 'SumUpAllQuotasIcon'),
                    trendLine('chartGroups.2.trendLineEntry', 'TrendLineAreaIcon'),
                    buildingBlocks.colorPicker('chartGroups.1.customColor', 'Color of Remaining Value'),
                    buildingBlocks.numberOfDecimals(),
                ],
            },
        ],
    },
})
