import { put } from 'redux-saga/effects'
import { actions } from '../../Redux'

/**
 * @type {string} name of the header to compare client- and server-side fields configuration
 * see UiFieldConfigurationPreProcessor.groovy
 */
export const FieldHashHeader = 'X-Exply-Field-Config'

/**
 * distributes the piggy back meta data
 *
 * // TODO file doesnt exist anymore, not sure what this Response#Meta is nowadays
 * @param meta see UiResponse.groovy#meta
 */
export function* distributePiggybackData(meta: any) {
    for (const item of meta) {
        switch (item.type) {
            case 'NewImporterStatuses':
                yield put(actions.Data.Importer.mergeStatusByUuid(item.statusByUuid))
                break
            case 'NewImporterLogTails':
                yield put(actions.Data.Importer.mergeLogsByUuid(item.logsByUuid))
                break
            case 'UiFieldConfigurationMetaResponse':
                yield put(actions.Data.FieldsConfiguration.setFieldsConfiguration(item.fields))
                break
            default:
                console.error('unable to process unknown piggy back data', item)
        }
    }
}
