import React, { MouseEvent } from 'react'
import classnames from 'classnames'
import HoverTooltip from '../../Molecules/HoverTooltip/HoverTooltip'
import Icon from '../Icon/Icon'

const Switch: React.FunctionComponent<{
    value: boolean
    disabled?: boolean
    onChange: (value: boolean) => void
    label?: string
    labelPositionRight?: boolean
    className?: string
    isSmall?: boolean
    helpTooltip?: string
}> = (props) => {
    const handleCheckedChanged = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        if (!props.disabled) {
            props.onChange(!props.value)
        }
    }

    const className = classnames('switch', props.className, {
        'switch--on': props.value,
        'switch--disabled': props.disabled,
        'switch--small': props.isSmall,
    })

    const sliderClassNames = classnames('switch__slider', {
        'switch__slider--left': props.labelPositionRight,
        'switch__slider--right': !props.labelPositionRight,
    })

    return (
        <div className={className} onClick={handleCheckedChanged} role="button">
            {props.label !== undefined && !props.labelPositionRight ? (
                <label className="switch__label">{props.label}</label>
            ) : null}
            <input className="switch__input" type="checkbox" defaultChecked={props.value} />
            <div className={sliderClassNames} />
            <div>
                {props.label !== undefined && props.labelPositionRight ? (
                    <label className="switch__label">{props.label}</label>
                ) : null}
                {props.helpTooltip ? (
                    <HoverTooltip isVisible={true} header={props.helpTooltip}>
                        <Icon name={'QuestionIcon'} />
                    </HoverTooltip>
                ) : null}
            </div>
        </div>
    )
}

Switch.defaultProps = {
    labelPositionRight: true,
}

export default Switch
