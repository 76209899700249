import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import { SizeMe } from 'react-sizeme'
import Icon from '../../../../Components/Atoms/Icon/Icon'
import { availableWidgets } from '../../../../Utility/WidgetUtility'
import { WidgetErrorBoundary } from '../../Widget/WidgetErrorBoundary'
import { RootState } from '../../../../Redux/types'
import { selectors } from '../../../../Redux'
import WidgetExplorerWidgetPreviewDashboardWidget from './WidgetExplorerSuggestedWidget'

type WidgetExplorerSuggestionsOwnProps = {
    showAdvancedScreen: () => void
}

const mapStateToProps = (state: RootState) => ({
    widgetIds: selectors.Data.Dashboards.WidgetExplorer.widgetIds(state),
    widgetFeatureFlags: selectors.Data.FeatureFlags.widgetFeatureFlags(state),
    loadingState: selectors.Data.Dashboards.ViewMode.dashboardLoadingState(state),
})

type WidgetExplorerSuggestionsProps = WidgetExplorerSuggestionsOwnProps & ReturnType<typeof mapStateToProps>

class WidgetExplorerSuggestions extends PureComponent<WidgetExplorerSuggestionsProps> {
    render() {
        return (
            <LoadingOverlay
                text={this.renderOverlayText()}
                active={this.props.widgetIds.length === 0}
                classNamePrefix="widget-selector-with-preview__"
            >
                <div className="widget-selector-with-preview">
                    {this.props.widgetIds.length > 0
                        ? this.props.widgetIds.map((widgetId) => (
                              <WidgetExplorerWidgetPreviewDashboardWidget
                                  key={`preview-widget-${widgetId}`}
                                  widgetId={widgetId}
                                  showAdvancedScreen={this.props.showAdvancedScreen}
                              />
                          )) || 'No Widgets available for this configuration'
                        : this.renderWidgetIcons()}
                </div>
            </LoadingOverlay>
        )
    }

    renderOverlayText = () => (
        <div className="widget-selector-with-preview__overlay-text-wrapper">
            <Icon name="ArrowIcon" size="lg" position="left" color="white" rotation={180} />
            <div>
                <h2>Select one or more items from the list that interest you</h2>
                <p>or select Advanced at the top to enter Advanced Mode</p>
            </div>
        </div>
    )

    renderWidgetIcons = () =>
        availableWidgets(this.props.widgetFeatureFlags).map((widgetType) => {
            const WidgetIcon = widgetType.icon
            return (
                <SizeMe monitorHeight key={widgetType.typeName + 'icon-size'}>
                    {({ size }) => (
                        <WidgetErrorBoundary size={size} key={widgetType.typeName + 'icon-error-boundary'}>
                            <WidgetIcon key={`widget-selector-with-preview-icon-${widgetType.typeName}`} />
                        </WidgetErrorBoundary>
                    )}
                </SizeMe>
            )
        })
}

export default connect(mapStateToProps)(WidgetExplorerSuggestions)
