import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState } from '../types'
import { actionTypes as system } from '../../System'
import { RootState } from '../../types'

const importerExecutionSlice = createSlice({
    name: 'Data/Importers/Execution',
    initialState: {} as DataState,
    reducers: {
        mergeLogsByUuid: {
            prepare: (logsByUuid) => ({ payload: { logsByUuid } }),
            reducer: (state, action: PayloadAction<{ logsByUuid: any }>) => {
                state.importers.execution.logsByUuid = {
                    ...state.importers.execution.logsByUuid,
                    ...action.payload.logsByUuid,
                }
            },
        },
        mergeStatusByUuid: {
            prepare: (statusByUuid) => ({ payload: { statusByUuid } }),
            reducer: (state, action: PayloadAction<{ statusByUuid: any }>) => {
                state.importers.execution.statusByUuid = {
                    ...state.importers.execution.statusByUuid,
                    ...action.payload.statusByUuid,
                }
            },
        },
    },
    extraReducers: {
        [system.INIT]: (state, action) => {
            state.importers.execution = {
                statusByUuid: action.payload.initialState.data.importers.statusByUuid,
                logsByUuid: {},
            }
        },
    },
})

export const actions = importerExecutionSlice.actions

export const reducer = importerExecutionSlice.reducer

const importerStatusByUuid = (state: RootState) => state.Data.importers.execution.statusByUuid

export const selectors = {
    importerStatusByUuid,
    logTails: (state: RootState) => state.Data.importers.execution.logsByUuid,

    runningImportersUuidArray: createSelector(importerStatusByUuid, (importerStatus) => {
        const runningImportersArray: Array<string> = []

        Object.entries(importerStatus).forEach(([uuid, status]) => {
            if (status.state === 'Running') {
                runningImportersArray.push(uuid)
            }
        })

        return runningImportersArray
    }),
}
