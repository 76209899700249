import { getCtrlKeyPressed } from '../../../../Utility/KeyPress'
import { PivotTableSelection } from './PivotTableWidget.types'

export const updatePivotTableSelection = (selections: PivotTableSelection, newSelection: Array<string>) => {
    const controlWasPressed = getCtrlKeyPressed()
    const newSelectionAsString = newSelection.join('-')

    if (entryAlreadyInArray(newSelectionAsString, selections)) {
        // item is already in selection, got deselected, so we remove it from the selections array
        return selections.filter((entry) => entry.join('-') !== newSelectionAsString)
    }

    // is not in selection - ctrl pressed ? add to selection : new selection
    return controlWasPressed ? [...selections, newSelection] : [newSelection]
}

const entryAlreadyInArray = (newSelectionAsString: string, selections: PivotTableSelection) =>
    selections.some((entry) => entry.join('-') === newSelectionAsString)
