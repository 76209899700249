const tooltips = {
    importerFieldMappingTarget: {
        header: 'Field Name',
        content:
            'How do you want to name it in Exply? Choose an existing field or create a new one.' +
            ' The time–selector uses the field created_on.' +
            ' The fields appear in the UI if you reload after the first successful data–import.',
        position: 'right',

        distance: 6,
    },
    importerFieldMappingType: {
        header: 'Field Type',
        content: 'Rename your field or map new data to existing fields by selecting them.',
        position: 'right',

        distance: 6,
    },
    importerFieldMappingFormat: {
        header: 'Field Format',
        content:
            'Dates and Numbers come in various forms. ' +
            'We cannot detect all of them. ' +
            "In case we don't: which one is it?",
        position: 'left',
    },
    importerFieldMappingDefaultValue: {
        header: 'Default Value',
        content: 'Set fallback data if an imported field is empty.',
        position: 'left',
    },
    csvImporterSettingsSeparator: {
        header: 'Delimiter',
        content:
            'Enter the delimiter separating the fields of each row in your CSV file, e.g. a comma (,), semicolon (;) or tab (\\t).',
        position: 'left',
    },
    csvImporterSettingsSourceLanguage: {
        header: 'Source Language',
        content: `Use <span className="importer-field-mapping-form__hint__language">English</span> if the
                    numbers look like
                    <span className="importer-field-mapping-form__hint__sample">1,234.56</span> and the
                    dates like <span className="importer-field-mapping-form__hint__sample">12/31/2019</span>
                    and use <span className="importer-field-mapping-form__hint__language">German</span> for
                    <span className="importer-field-mapping-form__hint__sample">1.234,56</span> and
                    <span className="importer-field-mapping-form__hint__sample">31.12.2019</span>.`,
        position: 'left',
    },
    csvImporterSettingsQuoteCharacter: {
        header: 'Quote Character',
        content:
            'Character used to quote values containing the delimiter, e.g. quote ("). Usually you shouldn\'t have to change it.',
        position: 'left',
    },
    csvImporterSettingsEscapeChar: {
        header: 'Escape Character',
        content:
            "Character to escape the following character, e.g. back-slash (\\). This is advanced configuration and usually you shouldn't have to change it.",
        position: 'left',
    },
    widgetEditorDateField: {
        header: 'Primary Date Field',
        content: 'Select the date field by which your time selector filters the data for this value.',
        position: 'right',
    },
    widgetEditorPivotField: {
        header: 'Pivot Field',
        content: 'Select whether the displayed values should be pivoted by another category.',
        position: 'right',
    },
    widgetEditorProjectBoard: {
        header: 'Jira Project Board',
        content: 'Select the board from which the sprints should be displayed.',
        position: 'right',
    },
    widgetEditorNumberOfSprints: {
        header: 'Number of Sprints',
        content: 'Select the number of sprints to be displayed from the current to the past.',
        position: 'left',
    },
    widgetEditorShowLegendByDefault: {
        header: 'Show Legend by Default',
        content: 'The legend is not displayed by default if the width of the widget is too small.',
        position: 'right',
    },
    importerXPath: {
        header: 'xPath Selector',
        content:
            'For XML xPath is what SQL is for a database. Please add a xPath, e.g. /bookstore/books.' +
            ' Also see ' +
            '<a href="https://www.w3schools.com/xml/xpath_examples.asp" target="_blank" rel="noopener noreferrer">w3schools.com</a> and ' +
            '<a href="https://devhints.io/xpath" target="_blank" rel="noopener noreferrer">devhints.io</a>.',
        position: 'right',
    },
    jiraConfigTempoError: {
        content:
            'We could not find the Tempo Timesheets table AO_013613_WORK_ATTRIBUTE and assume that the Tempo Timesheets add-on is not installed. Therefore we now continue with the import of the Jira data. If your Tempo Timesheets version is lower than 8 please consider an update.',
        position: 'bottom',
    },
    jiraConfigJiraSuccess: {
        content: 'We have detected your configuration automatically.',
        position: 'bottom',
    },
    importerScheduling: {
        header: 'Cron Scheduling',
        content:
            'The syntax of the scheduling follows the Cron syntax: "min hour day month weekday".' +
            ' Note that sunday is weekday zero.' +
            ' You can use the predefined schedules as examples.' +
            ' More details can be found at <a href="https://crontab.guru/" target="_blank" rel="noopener noreferrer">crontab.guru</a>.',
        position: 'right',
    },
    widgetProblemNoDataJira: {
        header: 'Where is my Data Checklist',
        content: `
            1. Is the Jira data import done?<br/> 
            2. Is the data import showing any errors?<br/> 
            3. Is the selected period covered by the imported period?<br/> 
            4. Is there data for this Widget configuration in the source system?<br/> 
            <br/> 
            Still don't see data? We're here to help: <a href="mailto:support@exply.io">support@exply.io</a>`,
        position: 'right',
    },
    widgetProblemNoDataOnPremise: {
        header: 'Where is my Data Checklist',
        content: `
            1. Is the data import done? <br />
            2. Is the data import showing any errors? <br/>
            3. Is there data for this Widget configuration in the source system? <br/>
            <br/>
            Still don't see data? We're here to help: <a href="mailto:support@exply.io">support@exply.io</a>`,
        position: 'right',
    },
    timeSelectorAvailablePredefinedTimespans: {
        header: 'Available predefined timespans',
        content: 'If you only want to see a selection of predefined time spans, just select your favourite ones here.',
        position: 'left',
    },
    workingDaySelector: {
        header: 'Working Days',
        content:
            'Here you can set which days are working days. However, this selection only affects the predefined time span "Last Working Day."',
        position: 'right',
    },
    showLastXDays: {
        header: 'Display the last x days',
        content: 'This applies for the Time Selector option "Last X Days"',
        position: 'left',
    },
    pivotFieldMapWidget: {
        header: 'Category Field',
        content:
            'Select the field which represents your geo shape as category, e.g: If your geo shapes represent ZIP Code Areas, select the field containing the ZIP Codes itself as Category.',
        position: 'left',
    },
    burnChartValueField: {
        header: 'Value Field',
        content:
            'Choose which values you want to see in the Burndown Chart, e.g. Story Points or , logged hours of closed issues.',
        position: 'right',
    },
}

export type TooltipId = keyof typeof tooltips

export default tooltips
