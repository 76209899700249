import { put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { actions } from '../../Redux'
import axios from '../Utility/axios'

export default function* SaveUserPasswordSaga() {
    yield takeLatest(actions.Data.UserManagement.saveUserPassword, function* (action) {
        try {
            const { userId, password } = action.payload

            const response: AxiosResponse = yield axios({
                method: 'POST',
                url: 'admin/userManagement/setPassword',
                data: {
                    newPassword: password,
                    user: userId,
                },
            })

            if (response.status < 400) {
                yield put(actions.Data.UserManagement.savePasswordSuccess())
            } else {
                yield put(actions.UI.Notification.show({ id: 'userOrGroupPasswordSaveFailed' }))
                yield put(actions.Data.UserManagement.savePasswordFailed())
            }
        } catch (e) {
            console.error(e)
        }
    })
}
