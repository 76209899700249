import axios from '../../Utility/axios'

export default function* (importerUuids: Array<string>) {
    yield axios({
        method: 'POST',
        url: 'admin/importer/log/tails',
        data: {
            uuids: importerUuids,
        },
    })
    // logs are send via piggy back data
}
