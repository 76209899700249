import { connect } from 'react-redux'
import { actions, selectors } from '../../Redux'
import FieldsCustomizationEntry, {
    FieldsCustomizationEntryOwnProps,
} from '../../Components/Molecules/FieldsCustomizationEntry/FieldsCustomizationEntry'
import { RootState } from '../../Redux/types'
import { AppDispatch } from '../../store'

const mapStateToProps = (state: RootState, props: FieldsCustomizationEntryOwnProps) => {
    return {
        userConfiguration: selectors.Data.FieldsCustomization.makeConfigurationForField(state, props.fieldIdentifier),
        defaultValues: selectors.Data.FieldsCustomization.makeDefaultValuesForField(state, props.fieldIdentifier),
        usageDocCount: selectors.Data.FieldsCustomization.makeUsageDocCountForField(state, props.fieldIdentifier),
        type: selectors.Data.FieldsCustomization.makeTypeForField(state, props.fieldIdentifier),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: FieldsCustomizationEntryOwnProps) => ({
    handleChangeLabel: (label: string) =>
        dispatch(actions.Data.FieldsCustomization.changeLabel(ownProps.fieldIdentifier, label)),
    handleChangeIsVisibleInUi: (isVisibleInUi?: boolean) =>
        dispatch(
            actions.Data.FieldsCustomization.changeIsVisibleInUi(ownProps.fieldIdentifier, Boolean(isVisibleInUi))
        ),
    handleResetFieldsCustomization: () =>
        dispatch(actions.Data.FieldsCustomization.resetSingle(ownProps.fieldIdentifier)),
})

export type FieldsCustomizationEntryReduxProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(FieldsCustomizationEntry)
