import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { actionTypes as system } from '../System'
import { UiState } from './types'
import { InitActionPayload, RootState } from '../types'

const setupWizardSlice = createSlice({
    name: 'UI/SetupWizard',
    initialState: {} as UiState,
    extraReducers: {
        [system.INIT]: (state, action: PayloadAction<InitActionPayload>) => {
            const { initialState } = action.payload

            const initialConfiguration = getInitialConfiguration(
                initialState.system.setupProcess,
                initialState.data.featureFlags.isDemo
            )

            state.setupWizard = {
                currentStep: initialConfiguration.firstStep,
                isActive: initialConfiguration.isActive,
            }
        },
    },
    reducers: {
        nextStep: (state) => {
            state.setupWizard.currentStep++
        },
    },
})

export const actions = setupWizardSlice.actions
export const reducer = setupWizardSlice.reducer

const getInitialConfiguration = (
    status: { defaultPasswordWasChanged: boolean; numberOfImporters: number },
    isDemo: boolean
) => {
    let configuration: {
        firstStep: number
        isActive: boolean
    }

    if (isDemo) {
        configuration = {
            firstStep: 5,
            isActive: false,
        }
    } else if (!status.defaultPasswordWasChanged) {
        configuration = {
            firstStep: 1,
            isActive: true,
        }
    } else if (status.numberOfImporters === 0) {
        configuration = {
            firstStep: 3,
            isActive: true,
        }
    } else {
        configuration = {
            firstStep: 5,
            isActive: false,
        }
    }

    return configuration
}

export const selectors = {
    wizardIsActive: (state: RootState) => state.UI.setupWizard.isActive,
    currentStep: (state: RootState) => state.UI.setupWizard.currentStep,
}
