import { FieldType } from '../Components/Atoms/FieldFilterInput/FieldFilterManifest'
import { UserOrGroupConfig } from '../Redux/Data/UserManagement'

export function getValidationErrorMessage(
    value: UserOrGroupConfig | string | Array<FieldType>,
    validators: Array<Validator>
) {
    for (const validator of validators) {
        if (!validate(value, validator)) {
            return validator.message
        }
    }

    return undefined
}

export function containsErrorMessages(errorMessagesByKey: { [key: string]: string | undefined }) {
    for (const key of Object.keys(errorMessagesByKey)) {
        if (errorMessagesByKey[key]) {
            return true
        }
    }

    return false
}

type Validator = {
    type: string | boolean
    length?: number
    regex?: RegExp
    message?: string
}

export function validate(value: Array<FieldType> | UserOrGroupConfig | string, validator: Validator) {
    switch (validator.type) {
        case 'required':
            return Boolean(value)
        case 'regex':
            return Boolean(validator.regex?.test(value as string))
        case 'minLength':
            return String(value).length >= validator.length!
        case 'operatorNotNull': {
            let isValid = true
            if (!value) {
                return isValid
            }

            ;(value as Array<FieldType>).forEach((filterField) => {
                if (filterField.operator === null) {
                    isValid = false
                }
            })
            return isValid
        }

        case 'valuesNotNull': {
            let isValid = true
            if (!value) {
                return isValid
            }

            ;(value as Array<FieldType>).forEach((filterField) => {
                if (filterField.operator !== 'matches current user name' && filterField.values.length === 0) {
                    isValid = false
                }
            })
            return isValid
        }

        case 'fieldNotNull': {
            let isValid = true
            if (!value) {
                return isValid
            }

            ;(value as Array<FieldType>).forEach((filterField) => {
                if (!filterField.field) {
                    isValid = false
                }
            })
            return isValid
        }

        case 'dataPermissionNotNullIfDashboardPermission': {
            const canSeeDashboards =
                (value as UserOrGroupConfig).settings.canSeeAnyDashboard ||
                (value as UserOrGroupConfig).settings.visibleDashboards.length > 0
            // TODO does being in a subgroup mean the user has data permissions?
            const hasDataPermissions =
                (value as UserOrGroupConfig).subGroups.length > 0 ||
                (value as UserOrGroupConfig).documentPermissions.ignoreFieldPermissions ||
                (value as UserOrGroupConfig).documentPermissions.fieldPermissions.length > 0
            return !canSeeDashboards || hasDataPermissions
        }
        case true:
            return true
        case false:
        default:
            // fail on unknown validators
            return false
    }
}
