import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import enhanceWithClickOutside from 'react-click-outside'
import { Link, RouteComponentProps } from 'react-router-dom'
import classnames from 'classnames'
import { withRouter } from 'react-router'
import { actions, selectors } from '../../../Redux'

import RowMenu, { RowMenuItem } from '../../../Components/Molecules/RowMenu/RowMenu'
import Hamburger from '../../../Components/Atoms/Hamburger/Hamburger'
import sandstormLogo from '../../../Images/sandstorm_logo_white.svg'
import Icon, { IconNames } from '../../../Components/Atoms/Icon/Icon'
import containsGivenObject from '../../../Utility/HandleClickOutside'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { hasAccessToRoute, RouteKeys, Routes } from '../../../routes'

const mapStateToProps = (state: RootState) => ({
    isMainMenuOpen: selectors.UI.Menu.isMainMenuOpen(state),
    featureFlags: selectors.Data.FeatureFlags.allFeatureFlags(state),
    userSettings: selectors.UI.CurrentUser.settings(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onToggleMenuClick: () => dispatch(actions.UI.Menu.toggleMenuVisibility()),
})

type MainMenuProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    Partial<RouteComponentProps>

class MainMenu extends PureComponent<MainMenuProps> {
    handleClickOutside = (event: React.MouseEvent) => {
        if (this.props.isMainMenuOpen) {
            if (containsGivenObject(event, ['main-menu-toggle main-menu--visible'])) {
                // only stop propagation if main-menu-toggle was clicked
                // the toggle-action is triggered by MainMenuToggle.onClick and handleClickOutside (bubbled)
                // propagation is needed for instant actions while menu is opened,
                // otherwise buttons clicked outside of the menu will only close the menu and cause no action
                event.stopPropagation()
            }

            this.props.onToggleMenuClick()
        }
    }

    render() {
        const className = classnames('main-menu', {
            'main-menu--visible': this.props.isMainMenuOpen,
        })

        const { userManagement, importerSettings, configurationSharing, typesUiConfiguration } = this.props.featureFlags
        const hasAccessTo = (route: RouteKeys) => hasAccessToRoute(route, this.props.userSettings)

        return (
            <div className={className}>
                <RowMenu inverted={true}>
                    {this.renderMenuEntry('/', 'Dashboards', 'MainMenuDashboardsIcon', false)}
                    {importerSettings && hasAccessTo('importer')
                        ? this.renderMenuEntry(Routes.importer.path, 'Data Sources', 'MainMenuDataSourcesIcon')
                        : ''}
                    {userManagement && hasAccessTo('userManagement')
                        ? this.renderMenuEntry(
                              Routes.userManagement.path,
                              'User Management',
                              'MainMenuUserManagementIcon'
                          )
                        : ''}
                    {configurationSharing && hasAccessTo('configurationSharing')
                        ? this.renderMenuEntry(
                              Routes.configurationSharing.path,
                              'Configuration Sharing',
                              'MainMenuConfigurationSharingIcon'
                          )
                        : ''}
                    {typesUiConfiguration && hasAccessTo('typesUiConfiguration')
                        ? this.renderMenuEntry(
                              Routes.typesUiConfiguration.path,
                              'Fields Customization',
                              'MainMenuFieldsCustomizationIcon'
                          )
                        : ''}
                    {hasAccessTo('colorConfiguration') &&
                        this.renderMenuEntry(Routes.colorConfiguration.path, 'Color Customization', 'ColorPaletteIcon')}
                    {hasAccessTo('sharedDashboardsOverview') &&
                        this.renderMenuEntry(Routes.sharedDashboardsOverview.path, 'Shared Dashboards', 'ShareIcon')}
                    {hasAccessTo('systemInformation')
                        ? this.renderMenuEntry(
                              Routes.systemInformation.path,
                              'System Information',
                              'MainMenuSystemInformationIcon'
                          )
                        : ''}
                </RowMenu>

                <RowMenu inverted={true}>
                    <RowMenuItem isHalfSized={true}>
                        <a
                            href="https://exply.io/en/support/setup-first-steps.html#How-to-use-exply"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Help <Icon name="ExternalLinkIcon" color="white" />
                        </a>
                    </RowMenuItem>

                    <RowMenuItem isHalfSized={true}>
                        <a href="https://exply.io/en/support/changelog.html" target="_blank" rel="noopener noreferrer">
                            Changelog <Icon name="ExternalLinkIcon" color="white" />
                        </a>
                    </RowMenuItem>

                    {this.renderMenuEntry('/imprint', 'Imprint', undefined, true)}
                    {this.renderMenuEntry('/privacy', 'Privacy Statement', undefined, true)}

                    <RowMenuItem>
                        <a
                            href="https://sandstorm.de"
                            className="sandstorm-logo"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={sandstormLogo} className="sandstorm-logo__image" alt="sandstorm logo" />
                        </a>
                    </RowMenuItem>
                </RowMenu>
            </div>
        )
    }

    renderMenuEntry = (path: string, name: string, iconName?: IconNames, isHalfSized?: boolean) => {
        const handleOnClick = this.props.onToggleMenuClick
        const isActive = this.props.location?.pathname === path
        return (
            <RowMenuItem onClick={handleOnClick} isHalfSized={isHalfSized} isActive={isActive}>
                <Link to={path}>
                    {iconName && <Icon name={iconName} color="white" />}
                    {name}
                </Link>
            </RowMenuItem>
        )
    }

    renderBackToJiraButton = () => {
        return (
            <RowMenuItem>
                <a href="../../.." style={{ color: 'white' }}>
                    Back to Jira <Icon name="SignOutIcon" color="white" title="logout" />
                </a>
            </RowMenuItem>
        )
    }
}

const MainMenuToggle = (props: MainMenuToggleProps) => {
    const className = classnames('main-menu-toggle', props.className, {
        'main-menu--visible': props.isMainMenuOpen,
    })

    return (
        <div
            className={className}
            onClick={props.onToggleMenuClick}
            title={props.isMainMenuOpen ? 'close main menu' : 'open main menu'}
            role="button"
        >
            <Hamburger active={props.isMainMenuOpen} />
        </div>
    )
}

type MainMenuToggleProps = {
    isMainMenuOpen: boolean
    onToggleMenuClick: () => void
    className?: string
}

const ConnectedMainMenu = connect(mapStateToProps, mapDispatchToProps)(enhanceWithClickOutside(MainMenu))
const ConnectedMainMenuToggle = connect(mapStateToProps, mapDispatchToProps)(MainMenuToggle)

export default withRouter(ConnectedMainMenu)
export { ConnectedMainMenuToggle as MainMenuToggle }
