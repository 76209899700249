import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import produce from 'immer'
import { createAction } from '@reduxjs/toolkit'
import { actions, selectors } from '../../Redux'
import {
    addCurrentlyEditedDashboardToRequest,
    addCurrentlyEditedWidgetToRequest,
    applyFakeTimeSelector,
    executeDashboardDataRequest,
    initializeWidgetState,
} from './Utility'
import { buildDefaultWidgetConfiguration, mergeWidgetConfigurationWithOldOne } from '../../Utility/DashboardEditor'
import widgetTypes from '../../StaticManifests/manifest.widgetTypes'
import { WidgetConfiguration } from '../../Redux/Data/types'

export const startChangingWidgetTypeAction = createAction(
    'Data/Dashboards/WidgetEditMode/startChangingWidgetType',
    (newType: string) => ({ payload: { newType } })
)

/**
 * Here, we update the widget configuration
 */
export function* UpdateWidgetConfigurationWhenTypeChanges() {
    yield takeEvery(startChangingWidgetTypeAction, function* (action) {
        const { newType } = action.payload
        const widgetBeforeChange: WidgetConfiguration | undefined =
            selectors.Data.Dashboards.currentlyEditedWidgetConfig(yield select())
        if (widgetBeforeChange === undefined) {
            throw Error('Widget to change type of must be set!')
        }

        const oldCoordinatesAndSize = widgetBeforeChange.coordinatesAndSize
        let newCoordinatesAndSize = oldCoordinatesAndSize

        const previousWidgetConfiguration = selectors.Data.Dashboards.widgetById(yield select(), widgetBeforeChange.id)

        const isNewWidget = previousWidgetConfiguration === undefined
        if (isNewWidget) {
            const widgetTypeDef = widgetTypes[newType]
            newCoordinatesAndSize = {
                x: oldCoordinatesAndSize.x,
                y: oldCoordinatesAndSize.y,
                // !!! Math.max(undefined, 6) => NaN !!!
                width: Math.max(widgetTypeDef.minWidth || 0, 6),
                height: Math.max(widgetTypeDef.minHeight || 0, 6),
            }
        }

        const priorConfig: Record<string, any> = yield select(selectors.UI.Widget.latestWidgetConfiguration)
        const oldType = widgetBeforeChange.type
        let newConfiguration = buildDefaultWidgetConfiguration(newType, priorConfig)
        // this is a HACK: the old config merge strategy does not work here, but refactoring has yet to be done, see AliasChart.configurationInitializer.ts
        if (newConfiguration.__alreadyMerged) {
            delete newConfiguration.__alreadyMerged
        } else {
            newConfiguration = mergeWidgetConfigurationWithOldOne(newConfiguration, priorConfig, oldType, newType)
        }

        const widgetHasCustomTitle = widgetBeforeChange.configuration.title !== widgetTypes[oldType].name
        if (widgetHasCustomTitle) {
            newConfiguration = produce(newConfiguration, (draft) => {
                draft.title = widgetBeforeChange.configuration.title
            })
        }

        let widgetWithNewConfiguration = produce(widgetBeforeChange, (draft) => {
            draft.configuration = newConfiguration
        })
        widgetWithNewConfiguration = produce(widgetWithNewConfiguration, (draft) => {
            draft.type = newType
        })
        const newInitialState = initializeWidgetState(widgetWithNewConfiguration)

        yield put(
            actions.Data.Dashboards.WidgetEditMode.changeWidgetType({
                newType,
                newConfiguration,
                newInitialState,
                newCoordinatesAndSize,
            })
        )
        yield put(actions.UI.Widget.initLatestWidgetConfiguration(newConfiguration))
    })
}

export function* UpdateLatestWidgetConfiguration() {
    yield takeEvery([actions.Data.Dashboards.WidgetEditMode.startEditingWidget.type], function* () {
        const widget = selectors.Data.Dashboards.currentlyEditedWidgetConfig(yield select())
        yield put(actions.UI.Widget.initLatestWidgetConfiguration(widget?.configuration))
    })
}

export function* ResetLatestWidgetConfiguration() {
    yield takeLatest([actions.Data.Dashboards.WidgetEditMode.finishEditingWidget.type], function* () {
        yield put(actions.UI.Widget.resetLatestConfiguration())
    })
}

export const startChangingWidgetConfiguration = createAction(
    'SAGAS/Dashboards/startChangingWidgetConfiguration',
    (propertyName: string, value: any) => ({ payload: { propertyName, value } })
)

/**
 * Here, we test whether the widget state needs to be reset based on a configuration change; by running the widget's stateInitializationFunction
 * "before" and "after" and seeing whether the result is the same.
 */
export function* UpdateStateOnIntendedWidgetConfigurationChange() {
    yield takeEvery(startChangingWidgetConfiguration, function* (action) {
        const { propertyName, value } = action.payload

        yield put(actions.UI.Widget.updateLatestWidgetConfiguration(propertyName, value))
        const widgetBeforePropertyChange = selectors.Data.Dashboards.currentlyEditedWidgetConfig(yield select())

        if (widgetBeforePropertyChange === undefined) {
            throw Error('EditedWidget must be set to change any properties!')
        }

        const widgetAfterPropertyChange = produce(widgetBeforePropertyChange, (draft) => {
            draft.configuration[propertyName] = value
        })

        const initialStateBeforePropertyChange = initializeWidgetState(widgetBeforePropertyChange)
        const initialStateAfterPropertyChange = initializeWidgetState(widgetAfterPropertyChange)

        // Deep comparison of all values. If state changed, we need to recompute!
        const needToReinitializeState =
            JSON.stringify(initialStateBeforePropertyChange) !== JSON.stringify(initialStateAfterPropertyChange)

        yield put(
            actions.Data.Dashboards.WidgetEditMode.changeWidgetConfiguration({
                propertyName,
                value,
                widgetRequestPart: needToReinitializeState
                    ? {
                          dependencyHash: null,
                          widgetState: initialStateAfterPropertyChange,
                      }
                    : undefined,
            })
        )
    })
}

// Load data on widget edit mode change
export function* UpdateResponseOnWidgetConfigurationChange() {
    yield takeLatest(
        [
            actions.Data.Dashboards.WidgetEditMode.addWidgetWithInitialStateForEditing.type,
            actions.Data.Dashboards.WidgetEditMode.changeWidgetType.type,
            actions.Data.Dashboards.WidgetEditMode.changeWidgetConfiguration.type,
            actions.Data.Dashboards.WidgetExplorer.setTime.type,
        ],
        function* () {
            let widgetDataRequest = selectors.Data.Dashboards.ViewMode.dashboardRequest(yield select())
            widgetDataRequest = yield addCurrentlyEditedDashboardToRequest(widgetDataRequest!)
            widgetDataRequest = yield addCurrentlyEditedWidgetToRequest(widgetDataRequest!)
            widgetDataRequest = yield applyFakeTimeSelector(widgetDataRequest!)

            yield executeDashboardDataRequest(widgetDataRequest!, [
                actions.Data.Dashboards.ViewMode.loadWidgetDataSuccess,
            ])
        }
    )
}
