import React, { useContext, useEffect, useRef } from 'react'
import produce from 'immer'
import FormFieldWithLabel from '../FormFieldWithLabel/FormFieldWithLabel'
import TextInput from '../../Atoms/TextInput/TextInput'
import HelpTooltipIcon from '../HelpTooltip/HelpTooltipIcon'
import { FieldMappingProps } from './ImporterFieldMappingFormControls'
import { Mapping } from './ImporterFieldMappingEditor'
import { DataSourcePreviewRefContext } from '../../Organisms/DataSources/EditDataSourceEditorsAndPreview/EditDataSourceEditorsAndPreview'
import SingleMapping from './SingleImporterFieldMapping'

const ImporterFieldMappingForm: React.FC<FieldMappingProps> = (props) => {
    const DataSourcePreviewRef = useContext(DataSourcePreviewRefContext)
    const importerFieldMappingRef = useRef<HTMLDivElement | null>(null)

    let timeout: NodeJS.Timeout | null = null
    /**
     * Function to sync scrolling between the upper and lower mapping divs - the resetting of the callback is required
     * so the event doesn't bounce (User scrolls in A, callback scrolls B, triggering the callback in B, scrolling A, etc.)
     */
    const scrollCallback = (source: HTMLDivElement, target: HTMLDivElement) => () => {
        if (timeout) {
            clearTimeout(timeout)
        }
        target.onscroll = () => {}
        target.scrollLeft = source.scrollLeft
        timeout = setTimeout(() => {
            target.onscroll = scrollCallback(target, source)
        }, 100)
    }

    useEffect(() => {
        if (DataSourcePreviewRef?.current && importerFieldMappingRef.current) {
            DataSourcePreviewRef.current.onscroll = scrollCallback(
                DataSourcePreviewRef.current,
                importerFieldMappingRef.current
            )
            importerFieldMappingRef.current.onscroll = scrollCallback(
                importerFieldMappingRef.current,
                DataSourcePreviewRef.current
            )
        }
    })

    const documentTypeChanged = (value: string) =>
        props.onChange(
            produce(props.value, (draft) => {
                if (draft) {
                    draft.documentType = value
                }
            })
        )

    const fieldMapping = props.value

    const mappingsChanged =
        (index: number) =>
        <K extends keyof Mapping>(propertyName: K, resets?: Partial<Mapping>) =>
        (value: Mapping[K]) => {
            let nextConfig = produce(props.value, (draft) => {
                if (draft) {
                    draft.mappings[index][propertyName] = value
                }
            })
            if (resets) {
                for (const property of Object.keys(resets)) {
                    nextConfig = produce(nextConfig, (draft) => {
                        // @ts-ignore
                        draft.mappings[index][property] = resets[property]
                    })
                }
            }

            props.onChange(nextConfig)
        }

    if (fieldMapping) {
        return (
            <>
                <FormFieldWithLabel
                    value={fieldMapping.documentType}
                    configuration={{
                        label: 'Name of Document Type',
                        editor: TextInput,
                    }}
                    onChange={documentTypeChanged}
                    className="importer-field-mapping--documentType"
                />
                <div className="importer-field-mapping">
                    <div className="importer-field-mapping--labels">
                        <div className="importer-field-mapping__single--wrapper">
                            <div className="importer-field-mapping__single">
                                <div className="importer-field-mapping__single__controls--and--source">
                                    <div className="importer-field-mapping__single__source">Source</div>
                                    <div className="importer-field-mapping__single__controls"></div>
                                </div>
                                <div className="importer-field-mapping__single__target importer-field-mapping__single__select">
                                    Field Name <HelpTooltipIcon id="importerFieldMappingTarget" />
                                </div>
                                <div className="importer-field-mapping__single__type importer-field-mapping__single__select">
                                    Field Type <HelpTooltipIcon id="importerFieldMappingType" />
                                </div>
                                <div className="importer-field-mapping__single__format importer-field-mapping__single__select">
                                    Field Format <HelpTooltipIcon id="importerFieldMappingFormat" />
                                </div>
                                <div className="importer-field-mapping__single__default-value importer-field-mapping__single__select">
                                    Default Value <HelpTooltipIcon id="importerFieldMappingDefaultValue" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="importer-field-mapping--mappings" ref={importerFieldMappingRef}>
                        {fieldMapping.mappings.map((mapping: Mapping, i: number) => (
                            <React.Fragment key={'mapping-for-source-' + mapping.sourceName}>
                                <SingleMapping mapping={mapping} onChange={mappingsChanged(i)} />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                {/*
                // removed for now. might come back later - but with different positioning / styling
                // it's supposed to give users the option to manually add fields, for example when updating the source
                // file with an additional column.
                <ImporterFieldMappingFormControls value={props.value} onChange={props.onChange} />
                */}
            </>
        )
    }

    return <>No mappings found.</>
}

ImporterFieldMappingForm.displayName = 'ImporterFieldMappingForm'

export default ImporterFieldMappingForm
