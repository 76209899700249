import React, { ChangeEvent } from 'react'
import classnames from 'classnames'
import AutosizeInput from 'react-input-autosize'

const ShyTextInput: React.FC<{
    className?: string
    placeholder?: string
    value?: string | number
    onChange: (value: string) => void
    disabled?: boolean
    autoFocus?: boolean
    onEnterPress?: () => void
}> = (props) => {
    const { value, disabled, placeholder, autoFocus } = props
    const className = classnames('shy-text-input', props.className, {
        'shy-text-input--disabled': disabled,
    })

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 && props.onEnterPress) {
            props.onEnterPress()
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.value)

    return (
        <AutosizeInput
            autoFocus={autoFocus}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={handleChange}
            className={className}
            onKeyDown={handleKeyDown}
        />
    )
}

export default ShyTextInput
