import { createSlice } from '@reduxjs/toolkit'
import { actionTypes as system } from '../System'
import { DataState } from './types'
import { RootState } from '../types'

const fieldConfigurationSlice = createSlice({
    name: 'Data/FieldConfiguration',
    initialState: {} as DataState,
    extraReducers: {
        [system.INIT]: (state, action) => {
            const { initialState } = action.payload
            state.fieldsConfiguration = initialState.data.fieldsConfiguration
        },
    },
    reducers: {
        setFieldsConfiguration: {
            prepare: (fieldsConfiguration: any) => ({ payload: { fieldsConfiguration } }), // TODO: correct typing
            reducer: (state, action) => {
                const { fieldsConfiguration } = action.payload
                state.fieldsConfiguration = fieldsConfiguration
            },
        },
    },
})

export const actions = fieldConfigurationSlice.actions

export const reducer = fieldConfigurationSlice.reducer

export const selectors = {
    hash: (state: RootState) => state.Data.fieldsConfiguration.hash,
    fields: (state: RootState) => state.Data.fieldsConfiguration.fields,
}
