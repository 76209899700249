import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ModalActions, ModalContent } from '../../../../Components/Molecules/Modal/Modal'
import FileUpload, {
    File,
    HandleFileUploadDone,
    HandleFileUploadProgress,
} from '../../../../Components/Molecules/FileUpload/FileUpload'
import Button from '../../../../Components/Atoms/Button/Button'
import { DashboardId } from '../../../../Redux/Data/types'
import { actions } from '../../../../Redux'
import { AppDispatch } from '../../../../store'
import { ModalProps } from '../../ModalContainer'
import ButtonWithTooltip from '../../../../Components/Molecules/HoverTooltip/ButtonWithTooltip'
import { startWidgetAssetUploadAction } from '../../../../Sagas/FileUploadSaga'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onStartUpload: (file: File, handleProgress: HandleFileUploadProgress, handleDone: HandleFileUploadDone) =>
        dispatch(startWidgetAssetUploadAction(file, handleProgress, handleDone)),
    onSetImageToDashboard: (dashboardId: DashboardId, filePath: string | null) =>
        dispatch(actions.Data.Dashboards.DashboardEditMode.setIcon({ dashboardId, filePath })),
})

const DashboardImageModal: React.FunctionComponent<
    {
        parameters: {
            dashboardId: DashboardId
        }
    } & ReturnType<typeof mapDispatchToProps> &
        ModalProps
> = (props) => {
    const [filePath, setFilePath] = useState<string | null>(null)

    const handleChange = (image: File) => {
        setFilePath(image.filePath === undefined ? '' : image.filePath.replace('/dashboard/user-files/', ''))
    }
    const handleSaveImage = () => {
        props.onConfirmModal()
        props.onSetImageToDashboard(props.parameters.dashboardId, filePath)
    }
    const handleResetImage = () => {
        props.onConfirmModal()
        props.onSetImageToDashboard(props.parameters.dashboardId, null)
    }

    return (
        <ModalContent className="dashboard-image-modal">
            <FileUpload fileType="image" onStartUpload={props.onStartUpload} onChange={handleChange} />

            <ModalActions>
                <Button onClick={handleResetImage} type="warning">
                    Remove Image
                </Button>
                <Button onClick={props.onCancelModal}>Cancel</Button>
                <ButtonWithTooltip
                    isDisabled={filePath === null}
                    header="No image selected"
                    onClick={handleSaveImage}
                    type="primary"
                >
                    Set Image
                </ButtonWithTooltip>
            </ModalActions>
        </ModalContent>
    )
}

export default connect(null, mapDispatchToProps)(DashboardImageModal)
