import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { DataState } from '../types'
import { actionTypes as system } from '../../System'
import { RootState } from '../../types'

const importerManagementSlice = createSlice({
    name: 'Data/Importers/ImporterManagement',
    initialState: {} as DataState,
    extraReducers: {
        [system.INIT]: (state, action) => {
            const { initialState } = action.payload
            state.importers.management = {
                definitionsById: initialState.data.importers.definitionsById,
                byUuid: initialState.data.importers.byUuid,
            }
        },
    },
    reducers: {
        toggleImporterFinished: (state, action: PayloadAction<string>) => {
            const importerUuid = action.payload
            state.importers.management.byUuid[importerUuid].enabled =
                !state.importers.management.byUuid[importerUuid].enabled
        },
    },
})

export const reducer = importerManagementSlice.reducer
export const actions = importerManagementSlice.actions

const importersByUuid = (state: RootState) => state.Data.importers.management.byUuid

export const selectors = {
    importersByUuid,
    importerUuidArray: createSelector(importersByUuid, (importers) => {
        return Object.keys(importers)
    }),
    importerDefinitionsById: (state: RootState) => state.Data.importers.management.definitionsById,
}
