/*
 * see _Functions.scss for an explanation how js and scss interact to create the color system for exply
 *
 * mostly these colors were calculated using the scss function below
 * @debug change-color(map-get($brand-colors, primary), $lightness: 100% - lightness(map-get($brand-colors, primary)));
 * in some cases color codes were hand picked to improve legibility/design/…
 *
 * a special case are the colors white and shine:
 * while originally white is brighter than shine and thus it should be darker when inverted for the darkmode,
 * I picked a white, which is *brighter* than shine. This is because shine is used as the dashboard background
 * and white is the widget background. The result is slightly lighter widgets on a dark backgorund.
 */
const darkMap = {
    primary: '#f69206',
    white: '#444', // caclulated: '#000',
    'white-two': '#1e1e1e',
    'white-three': '#0f0f0f',
    'dark-mint-green': '#40e08f',
    'orange-yellow': '#f69206',
    black: '#efefef',
    text: '#ffffff', // calculated: '#b1b1b1'
    grey: '#838383',
    'darker-grey': '#cdcdcd',
    'light-grey': '#535353',
    'lighter-grey': '#131313',
    shine: '#222', // caclulated: '#080808',
    'medium-grey': '#646464',
    error: '#fd3744', // same as light theme, calculated: '#c8020f'
    'tooltip-beacon': '#087cb0',

    'orange-yellow-l35': '#482b02',
    'primary-l35': '#482b02',
    'primary-l25': '#7a4803',
    'primary-l10': '#c47405',
    'lighter-grey-l5': '#060606',
    'darker-grey-l5': 'silver',
    'error-l5': '#af020d',
    'error-l35': '#170002',
    'toolip-beacon-l25': '#022636',
    'white-d10': '#1a1a1a',
    'grey-d10': '#9d9d9d',
    'dark-mint-green-d10': '#9d9d9d',
    'primary-d10': '#faa835',
    'primary-d5': '#f99d1c',
    'error-d10': '#fa0313',
    'tooltip-beacon-d50': '#bbe8fc',
    'black-t75': 'rgba(255, 255, 255, 0.75)',
    'white-t90': 'rgba(0, 0, 0, 0.9)',
    'white-t75': 'rgba(0, 0, 0, 0.75)',
}

export type THEME = 'LIGHT' | 'DARK' | 'SYSTEM'

const applyMode = (userPreferredTheme: THEME, mediaQueryPrefersDark: boolean) => {
    let shouldBeDark: boolean
    switch (userPreferredTheme) {
        case 'LIGHT':
            shouldBeDark = false
            break
        case 'DARK':
            shouldBeDark = true
            break
        default:
        case 'SYSTEM':
            shouldBeDark = mediaQueryPrefersDark
            break
    }

    const root = document.querySelector(':root')
    if (root) {
        Object.keys(darkMap).forEach((key) => {
            // @ts-ignore style on root
            root.style.setProperty(`--color-${key}`, shouldBeDark ? darkMap[key] : null)
        })
    }
}

const toggleLightMode = (userPreferredTheme: THEME | undefined = 'SYSTEM') => {
    const eventListener = (event: MediaQueryList) => {
        applyMode(userPreferredTheme, event.matches)
    }

    // @ts-ignore I could not figure out, which type is appropriate for a matchMedia ChangeEvent
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', eventListener)

    const mediaQueryPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    applyMode(userPreferredTheme, mediaQueryPrefersDark)
}

export default toggleLightMode
