import { select, takeLatest } from 'redux-saga/effects'
import axios from '../Utility/axios'
import { actions, selectors } from '../../Redux'
import { DashboardId } from '../../Redux/Data/types'

function* sendFavouriteRequest(addOrRemove: 'add' | 'remove', dashboardId: DashboardId) {
    try {
        yield axios({
            method: 'post',
            url: 'my-profile/' + (addOrRemove === 'add' ? 'like-dashboard' : 'dislike-dashboard') + `/${dashboardId}`,
        })
    } catch (e) {
        console.warn('failed to add/remove dashboard as favorite', e)
    }
}

export function* AddDashboardToFavouritesSaga() {
    yield takeLatest(actions.UI.FavouriteDashboards.add, function* (action) {
        yield sendFavouriteRequest('add', action.payload)
    })
}

export function* RemoveDashboardFromFavouritesSaga() {
    yield takeLatest(actions.UI.FavouriteDashboards.remove, function* (action) {
        yield sendFavouriteRequest('remove', action.payload)
    })
}

export function* ToggleFavouriteDashboardSaga() {
    yield takeLatest(actions.UI.FavouriteDashboards.toggle, function* (action) {
        const isFavourite = selectors.UI.FavouriteDashboards.isFavouriteDashboardById(yield select(), action.payload)
        yield sendFavouriteRequest(isFavourite ? 'add' : 'remove', action.payload)
    })
}
