import React, { useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import classnames from 'classnames'
import { ValueInput } from './FieldFilterManifest'

export const displayFormat = 'DD/MM/YYYY'

/**
 * id generation for this component must be (somewhat) deterministic or story-shot-test fail
 */
let dateInputId = 0

export const DateInput: ValueInput = (props) => {
    const [id] = useState(dateInputId++)
    const [focused, setFocused] = useState(false)
    const stringValue = props.field.values[0]
    const hasValue = Boolean(stringValue)
    const numberValue = hasValue ? Number(stringValue) : Number.NaN
    const isValid = !hasValue || !Number.isNaN(numberValue)
    const dateValue = hasValue && isValid ? moment(numberValue) : null
    // We have to set the ariaLabel or story-shots will depend on the time-zone (since the ariaLabel defaults to the time-stamp).
    const displayValue = dateValue ? dateValue.format(displayFormat) : stringValue
    const className = classnames('field-filter-value-date-input', props.className, {
        'field-filter-value-date-input--has-error': !isValid,
    })
    return (
        <div className={className}>
            <SingleDatePicker
                id={`field-filter-value-date-input-${id}`}
                date={dateValue}
                /*
                // @ts-ignore */
                ariaLabel={displayValue}
                placeholder={displayValue}
                displayFormat={displayFormat}
                focused={focused}
                firstDayOfWeek={1}
                isOutsideRange={() => false}
                hideKeyboardShortcutsPanel
                daySize={36}
                numberOfMonths={1}
                onFocusChange={({ focused }) => setFocused(Boolean(focused))}
                onDateChange={(value) =>
                    props.onValueChanged(value !== null ? [String(value.startOf('day').valueOf())] : [])
                }
            />
        </div>
    )
}
