import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { selectors } from '../../Redux'
import { RootState } from '../../Redux/types'
import { AppDispatch } from '../../store'
import modals, { ModalConfiguration, ModalId, ModalType, modalTypes } from '../../StaticManifests/manifest.modals'
import { handleModalResultAction } from '../../Sagas/ConfirmationModalSaga'
import { CurrentModal } from '../../Redux/UI/types'

const mapStateToProps = (state: RootState) => {
    return {
        currentModal: selectors.UI.Modal.currentModal(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        handleDenyModalFactory: (id: ModalId) => () => dispatch(handleModalResultAction({ state: 'deny', id })),
        handleConfirmModalFactory: (id: ModalId) => (payload?: any) =>
            dispatch(handleModalResultAction({ ...payload, state: 'confirm', id })),
        handleCancelModalFactory: (id: ModalId) => () => dispatch(handleModalResultAction({ state: 'cancel', id })),
    }
}

// those types are only used by the ModalContainer itself
type ModalContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

// the modal itself gets
export type ModalProps = {
    parameters?: any
    onDenyModal: VoidFunction
    onConfirmModal: (payload?: any) => void
    onCancelModal: VoidFunction
    currentModalConfiguration: ModalConfiguration
}

class ModalContainer extends PureComponent<ModalContainerProps, unknown> {
    render() {
        if (this.props.currentModal) {
            document.body.addEventListener('keyup', this.handleEscapePress)
        } else {
            document.body.removeEventListener('keyup', this.handleEscapePress)
        }

        return this.props.currentModal ? this.renderModal(this.props.currentModal) : null
    }

    renderModal(currentModal: CurrentModal) {
        const currentModalId = currentModal.modalId
        const currentModalConfiguration = modals[currentModalId]

        const CurrentModal = modalTypes[currentModalConfiguration.type as ModalType]
        return (
            <div className="modal">
                <div className="modal__scroll-container">
                    {CurrentModal ? (
                        <CurrentModal
                            parameters={currentModal.parameters}
                            onConfirmModal={this.props.handleConfirmModalFactory(currentModalId)}
                            onCancelModal={this.handleCancel(currentModalConfiguration.blocking)}
                            onDenyModal={this.props.handleDenyModalFactory(currentModalId)}
                            currentModalConfiguration={currentModalConfiguration}
                        />
                    ) : (
                        'The modal we tried to show does not exist. Please send us a note that something went wrong!'
                    )}
                </div>
                <div
                    className="modal__backdrop"
                    onClick={this.handleCancel(currentModalConfiguration?.blocking)}
                    role="button"
                />
            </div>
        )
    }

    handleCancel = (isBlocking: boolean | undefined) => {
        if (isBlocking || this.props.currentModal === null || this.props.currentModal === undefined) {
            return () => {}
        }

        return this.props.handleCancelModalFactory(this.props.currentModal.modalId)
    }

    handleEscapePress = (event: KeyboardEvent) => {
        if (event.keyCode === 27) {
            // escape
            event.stopPropagation()
            if (this.props.currentModal) {
                const currentModalId = this.props.currentModal.modalId
                const currentModalConfiguration = modals[currentModalId]
                this.handleCancel(currentModalConfiguration?.blocking)()
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
