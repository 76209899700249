import React from 'react'
import classnames from 'classnames'
import Select from '../../Atoms/Select/Select'
import TextInput from '../../Atoms/TextInput/TextInput'
import FormFieldWithLabel from '../FormFieldWithLabel/FormFieldWithLabel'
import OracleJdbcDriverDownload from './OracleJdbcDriverDownload'

type Schema = {
    pattern: RegExp
    factory: (driver: string, host: string, port: string, database: string) => string
}

const defaultSchema = {
    pattern: /jdbc:(mysql|mariadb|postgresql):\/\/([^:]*):([^/]*)\/(.*)/,
    factory: (driver: string, host: string, port: string, database: string) =>
        `jdbc:${driver}://${host}:${port}/${database}`,
}
const msschema = {
    pattern: /jdbc:(sqlserver):\/\/([^:]*):([^;]*);databaseName=(.*)/,
    factory: (driver: string, host: string, port: string, database: string) =>
        `jdbc:${driver}://${host}:${port};databaseName=${database}`,
}
const oracleSchema = {
    pattern: /jdbc:(oracle:thin):@([^:]*):([^:]*):(.*)/,
    factory: (driver: string, host: string, port: string, database: string) =>
        `jdbc:${driver}:@${host}:${port}:${database}`,
}
const connectionStringSchemas = [defaultSchema, msschema, oracleSchema]

const defaultLabels = { host: 'Host', port: 'Port', database: 'Database' }

const defaultDriverOptions = [
    {
        enabled: true,
        value: 'mysql',
        label: 'MySQL',
        schema: defaultSchema,
        labels: defaultLabels,
    },
    {
        enabled: true,
        value: 'mariadb',
        label: 'MariaDB',
        schema: defaultSchema,
        labels: defaultLabels,
    },
    {
        enabled: true,
        value: 'postgresql',
        label: 'PostgreSQL',
        schema: defaultSchema,
        labels: defaultLabels,
    },
    {
        enabled: true,
        value: 'sqlserver',
        label: 'Microsoft SQL Server',
        schema: msschema,
        labels: defaultLabels,
    },
    {
        enabled: true,
        value: 'oracle:thin',
        label: 'Oracle',
        schema: oracleSchema,
        labels: { host: 'Host', port: 'Port', database: 'Database SID (system identifier)' },
        hint: () => <OracleJdbcDriverDownload className="jdbc-connection-input__jdbc-warning" />,
    },
]

function parseJdbcConnection(connectionString: string) {
    if (connectionString) {
        const match = connectionStringSchemas.map((s) => connectionString.match(s.pattern)).find((m) => m)
        if (match) {
            return {
                driver: match[1],
                host: match[2],
                port: match[3],
                database: match[4],
            }
        }

        return {}
    }

    return {}
}

function asConnection(schema: Schema, driver: string, host?: string, port?: string, database?: string) {
    return schema.factory(driver, host || '', port || '', database || '')
}

const JdbcConnectionInput: React.FunctionComponent<Props> = (props) => {
    const className = classnames('jdbc-connection-input', props.className)
    const { driver, host, port, database } = parseJdbcConnection(props.value)
    const driverOptions = props.driverOptionUpdates
        ? defaultDriverOptions
              .map((option) => ({
                  ...option,
                  ...(props.driverOptionUpdates.find((override) => override.value === option.value) || {}),
              }))
              .filter((option) => option.enabled)
        : defaultDriverOptions
    const option = driverOptions.find((o) => o.value === driver) || driverOptions[0]
    const schema = option.schema
    return (
        <div className={className}>
            {props.showDriverSelect && (
                <FormFieldWithLabel
                    value={option.value}
                    options={driverOptions}
                    clearable={false}
                    multi={false}
                    onChange={(newDriver: string) => {
                        const option = driverOptions.find((option) => option.value === newDriver)
                        // @ts-ignore
                        props.onChange(asConnection(option.schema, newDriver, host, port, database))
                    }}
                    configuration={{ label: 'SQL Driver', editor: Select }}
                />
            )}
            {option.hint && option.hint()}
            <FormFieldWithLabel
                value={host}
                onChange={(newHost: string) =>
                    props.onChange(asConnection(schema, option.value, newHost, port, database))
                }
                configuration={{ label: option.labels.host, editor: TextInput }}
            />
            <FormFieldWithLabel
                value={port}
                onChange={(newPort: string) =>
                    props.onChange(asConnection(schema, option.value, host, newPort, database))
                }
                configuration={{ label: option.labels.port, editor: TextInput }}
            />
            <FormFieldWithLabel
                value={database}
                onChange={(newDatabase: string) =>
                    props.onChange(asConnection(schema, option.value, host, port, newDatabase))
                }
                configuration={{ label: option.labels.database, editor: TextInput }}
            />
            <div className="jdbc-connection-input__jdbc-preview">JDBC Connection String: {props.value}</div>
        </div>
    )
}

type Props = {
    className?: string
    showDriverSelect?: boolean
    // replaces single options
    driverOptionUpdates: Array<{
        enabled: boolean
        value: string
        label: string
        hint: () => React.ReactChild
    }>
    value: string // "jdbc:diver:…"
    onChange: (value: string) => void // fun("jdbc:driver:…")
}

export default JdbcConnectionInput
