import TreeMapWidget from './LazyTreeMapWidget'
// @ts-ignore
import TreeMapWidgetIcon from './TreeMapWidget.Icon.tsx'
import TreeMapWidgetStateInitializer from './TreeMapWidget.init'
import TreeMapWidgetConfigurationInitializer, {
    TreeMapConfigurationInitializerForWidgetExplorer,
} from './TreeMapWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import { TreeMapWidgetConfig, TreeMapWidgetData } from './TreeMapWidget.types'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'

const config: WidgetManifestConfiguration = {
    name: 'Tree Map',
    component: TreeMapWidget,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 1,
        },
        max: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 2,
        },
    },
    configurationValidations: (widgetConfig: TreeMapWidgetConfig) => {
        // @ts-ignore
        if (widgetConfig.valueField === undefined || widgetConfig.valueField === '') {
            return widgetProblems.noValueField
        }
    },
    renderRequirements: (widgetData?: TreeMapWidgetData) => {
        if (widgetData?.negativeValues) {
            return widgetProblems.negativeValues
        }

        if (widgetData?.root.children.length === 0) {
            return widgetProblems.noData
        }
    },
    icon: TreeMapWidgetIcon,
    stateInitializationFunction: TreeMapWidgetStateInitializer,
    configurationInitializer: TreeMapWidgetConfigurationInitializer,
    configurationInitializerForWidgetExplorer: TreeMapConfigurationInitializerForWidgetExplorer,

    editors: {
        data: [
            buildingBlocks.valueField({
                isModeDisabled: true,
                isGroupingFieldDisabled: true,
                areDateRangeFieldsAllowed: true,
            }),
            buildingBlocks.pivotFields(2),
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.numberOfDecimals(),
                    buildingBlocks.flag(
                        'showLegend',
                        'Legend by Default',
                        'LegendIcon',
                        'The legend is not displayed by default if the width of the widget is too small.'
                    ),
                    buildingBlocks.flag('showCellLabels', 'Show Labels', 'ShortLabelsIcon'),
                ],
            },
        ],
    },
}

export default config
