import { takeLatest, call } from 'redux-saga/effects'
import axios from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { actions } from '../Redux'

function* uploadExplyConfig(action: PayloadAction<any>) {
    try {
        const file = action.payload

        const reader = new FileReader()
        // @ts-ignore not sure what sum is supposed to be
        reader.onload = (e) => uploadConfig(e.target?.sum)
        const readAsText = reader.readAsText.bind(reader)
        yield call(readAsText, file)
    } catch (e) {
        console.error('failed to read config file', e)
    }
}

function uploadConfig(config: any) {
    // @ts-ignore
    const responsePromise: any = axios({
        method: 'POST',
        url: '/admin/configuration/import',
        data: config,
        withCredentials: false,
        responseType: 'json',
        crossDomain: true,
    })
    responsePromise.then((response: any) => {
        if (response.status > 200) {
            console.warn('failed to store configuration', response)
        }
    })
}

export default function* UploadExplyConfig() {
    yield takeLatest(actions.Data.ConfigurationSharing.uploadConfigurationStart.type, uploadExplyConfig)
}
