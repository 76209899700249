import { createAction } from '@reduxjs/toolkit'

export const actions = {
    fetch: createAction(
        'Data/Autocompletion/Fetch',
        (type: string, value: string, exclude: Array<string>, callback: () => void) => ({
            payload: {
                type,
                value,
                exclude,
                callback,
            },
        })
    ),
}
