import React, { useState } from 'react'
import { LicenseInformation } from '../LicenseInformation/LicenseInformation'
import Button from '../../../Atoms/Button/Button'
import TimeZoneSelect from '../../../Shame/TimeZoneSelector/TimeZoneSelect'
import { SystemInformationProps } from '../../../../Containers/System/SystemInformation'
import ApplicationMode from '../../../../ApplicationMode'
import WorkingDaySelector from '../../../Molecules/WorkingDaySelector/WorkingDaySelector'
import DateFormatSelector from '../../../Molecules/DateFormatSelector/DateFormatSelector'
import NumberPicker from '../../../Atoms/NumberPicker/NumberPicker'

const SystemInformation: React.FC<SystemInformationProps> = (props) => {
    const [elasticTokenLength, setElasticTokenLength] = useState<number | null>(props.elasticTokenLength)
    return (
        <div className="system-information">
            {props.licenses ? (
                <div className="system-information__licenses">
                    <h1>License</h1>
                    {props.licenses.map((l, i) => (
                        <LicenseInformation key={i} content={l.content} status={l.status} />
                    ))}
                    {props.applicationMode === ApplicationMode.OnPremise && (
                        <Button type="primary" onClick={props.onUpdateLicense}>
                            Upload License
                        </Button>
                    )}
                </div>
            ) : (
                ''
            )}

            <h1>Time Settings</h1>

            <div className="system-information__time-zone">
                <h3>Time Zone</h3>
                <div>
                    <TimeZoneSelect value={props.timeZone} onChange={props.onChangeTimeZone} />
                </div>
            </div>

            <div>
                <h3>Date Format</h3>
                <div>
                    <DateFormatSelector value={props.dateFormat} onChange={props.onChangeDateFormat} />
                </div>
            </div>

            <div>
                <h3>Working Days</h3>
                <WorkingDaySelector onChange={props.onChangeWorkingDays} value={props.workingDays} />
            </div>

            <h1>ElasticSearch Settings</h1>
            <div className="system-information__elastic">
                <NumberPicker
                    onChange={(value) => setElasticTokenLength(value)}
                    label={'Tokenization Length'}
                    value={elasticTokenLength}
                />
                <p>
                    Attention! This setting might seriously impact your performance. Read more at the official{' '}
                    <a href="https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-edgengram-tokenizer.html#max-gram-limits">
                        ElasticSearch Docs
                    </a>
                    . You will need to rerun your importers for this change to take effect.
                </p>
                <Button
                    onClick={() =>
                        elasticTokenLength !== null ? props.onChangeElasticTokenLength(elasticTokenLength) : null
                    }
                >
                    Save ElasticSearch Settings
                </Button>
            </div>

            <div className="system-information__installation">
                <h1>Installation</h1>
                <div>Version {props.version}</div>
                <div>Released on {new Date(props.releaseDateMillis).toDateString()}</div>
            </div>
        </div>
    )
}

export default SystemInformation
