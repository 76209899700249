import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UiState } from './types'
import { RootState } from '../types'
import { actionTypes as system } from '../System'

const widgetSlice = createSlice({
    name: 'UI/Widget',
    initialState: {
        latestWidgetConfigurations: {},
    } as UiState,
    extraReducers: {
        [system.INIT]: (state) => {
            state.latestWidgetConfigurations = {}
        },
    },
    reducers: {
        initLatestWidgetConfiguration: {
            prepare: (configuration) => ({ payload: { configuration } }),
            reducer: (state, action: PayloadAction<{ configuration: Map<string, any> }>) => {
                state.latestWidgetConfigurations = action.payload.configuration
            },
        },
        updateLatestWidgetConfiguration: {
            prepare: (propertyName: string, value: any) => ({ payload: { propertyName, value } }),
            reducer: (state, action: PayloadAction<{ propertyName: string; value: any }>) => {
                const { propertyName, value } = action.payload
                state.latestWidgetConfigurations[propertyName] = value
            },
        },
        resetLatestConfiguration: (state) => {
            state.latestWidgetConfigurations = {}
        },
    },
})

export const actions = widgetSlice.actions
export const reducer = widgetSlice.reducer

export const selectors = {
    latestWidgetConfiguration: (state: RootState) => state.UI.latestWidgetConfigurations,
}
