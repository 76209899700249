import React, { lazy, Suspense } from 'react'
import { RichTextEditorProps } from './RichTextEditor'

const RichTextEditor = lazy(() => import(/* webpackChunkName: "RichTextEditor" */ './RichTextEditor'))

const LazyRichTextEditor: React.FC<RichTextEditorProps> = (props) => (
    <Suspense fallback="">
        <RichTextEditor {...props} />
    </Suspense>
)

export default LazyRichTextEditor
