import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { actions, selectors } from '../../../Redux'
import DateSelector from '../../../Components/Organisms/DateSelector/DateSelector'
import Icon from '../../../Components/Atoms/Icon/Icon'
import { DashboardId } from '../../../Redux/Data/types'
import { DateObj, DateRangeObject } from '../../../Components/Organisms/DateSelector/DateSelector.types'
import { AppDispatch } from '../../../store'
import { RootState } from '../../../Redux/types'
import WidgetExplorer from './WidgetExplorer'
import AdvancedWidgetEditor from './AdvancedWidgetEditor'

const mapStateToProps = (state: RootState, ownProps: WidgetEditorOwnProps) => {
    return {
        widgetId: selectors.Data.Dashboards.WidgetEditMode.currentlyEditedWidgetId(state),
        currentExplorerDataSelectionEntries: selectors.Data.Dashboards.WidgetExplorer.currentFieldSelections(state),
        isEditingExistingWidget: selectors.Data.Dashboards.WidgetEditMode.isEditingExistingWidget(state),
        groupIdsOfDashboard: selectors.UI.DashbardGroups.groupIdsOfDashboard(state, ownProps.dashboardId),
        isNewDashboard: selectors.Data.Dashboards.DashboardEditMode.isNewDashboard(state, ownProps.dashboardId),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onChangeTime: (startDate: DateObj, endDate: DateObj) =>
            dispatch(actions.Data.Dashboards.WidgetExplorer.setTime({ startDate, endDate })),
        handleCloseWidgetExplorer: () => dispatch(actions.Data.Dashboards.WidgetExplorer.closeWidgetExplorer()),
        restoreEditedWidget: () => dispatch(actions.Data.Dashboards.WidgetEditMode.restoreWidgetFromBackup()),
    }
}

type WidgetEditorReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type WidgetEditorOwnProps = {
    dashboardId: DashboardId
}

type WidgetEditorProps = WidgetEditorOwnProps & WidgetEditorReduxProps

type WidgetEditorState = {
    tabIndex: number
    previewDashboard: boolean
}

class WidgetEditor extends PureComponent<WidgetEditorProps, WidgetEditorState> {
    static displayName = 'WidgetEditor'

    constructor(props: WidgetEditorProps) {
        super(props)
        this.state = {
            tabIndex: props.isEditingExistingWidget ? 1 : 0,
            previewDashboard: false,
        }
    }

    componentDidUpdate(prevProps: WidgetEditorProps) {
        const shouldSwitchWidgetEditorMode = prevProps.isEditingExistingWidget !== this.props.isEditingExistingWidget
        if (shouldSwitchWidgetEditorMode) {
            this.handleTabChange(this.props.isEditingExistingWidget ? 1 : 0)
        }
    }

    handleDateChange = ({ startDate, endDate }: DateRangeObject) => {
        this.props.onChangeTime(startDate, endDate)
    }

    handleTabChange = (tabIndex: number) => {
        this.setState({ tabIndex })
    }

    handleCloseAdvancedEditor = () => {
        if (this.props.isEditingExistingWidget) {
            this.props.restoreEditedWidget()
            this.props.handleCloseWidgetExplorer()
        }

        this.handleTabChange(0)
    }

    handleShowAdvancedEditor = () => this.handleTabChange(1)

    render() {
        const widgetId = this.props.widgetId
        const { startDate, endDate } = this.props.currentExplorerDataSelectionEntries.time

        if (widgetId === undefined) {
            return null
        }

        return (
            <div className="widget-editor">
                <Tabs selectedIndex={this.state.tabIndex} onSelect={this.handleTabChange}>
                    <TabList>
                        {this.renderTimeSelector({ startDate, endDate })}
                        <Tab>
                            <Icon name="CompassIcon" label="Widget Explorer" />
                        </Tab>
                        <Tab>
                            <Icon name="CogIcon" label="Advanced Configuration" />
                        </Tab>
                    </TabList>

                    <TabPanel>
                        <WidgetExplorer
                            onShowAdvancedEditor={this.handleShowAdvancedEditor}
                            isNewDashboard={this.props.isNewDashboard}
                            groupIdsOfDashboard={this.props.groupIdsOfDashboard}
                            dashboardId={this.props.dashboardId}
                        />
                    </TabPanel>
                    <TabPanel>
                        <AdvancedWidgetEditor onCloseAdvancedEditor={this.handleCloseAdvancedEditor} />
                    </TabPanel>
                </Tabs>
            </div>
        )
    }

    renderTimeSelector = ({
        startDate,
        endDate,
    }: {
        startDate: moment.Moment | null
        endDate: moment.Moment | null
    }) => (
        <div className="widget-editor__time-selector">
            <DateSelector
                // !!! We must not use the original startDate and endDate !!!
                // Otherwise we observe side-effects in the Redux State, since momentJS-Objects are mutable.
                // See #1005 for details.
                startDate={startDate === null ? null : moment(startDate)}
                endDate={endDate === null ? null : moment(endDate)}
                showPredefinedTimeSpan={false}
                onDateChange={this.handleDateChange}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetEditor)
