import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import produce from 'immer'
import {
    AnalysisTerm,
    AnalysisToken,
    getModeOfAnalysisTerm,
    getSecondValueFieldOfTerm,
    Mode,
} from '../../Organisms/Widgets/WidgetModel'
import CalculatedFieldTokenEditor, { SYMBOL_TYPE } from './CalculatedFieldTokenEditor'
import InputValidationWrapper from '../../Atoms/InputValidationWrapper/InputValidationWrapper'
import ModeEditor from '../ModeEditor/ModeEditor'
import DocumentFieldSelect, { Option } from '../../Atoms/DocumentFieldSelect/DocumentFieldSelect'
import { DATE, STRING_SUITABLE_FOR_GROUPING } from '../../Atoms/FieldsConfiguration/FieldsConfigurationTypes'
import TextInput from '../../Atoms/TextInput/TextInput'
import Button from '../../Atoms/Button/Button'
import Icon from '../../Atoms/Icon/Icon'
import { FieldsConfigurationContext } from '../../Atoms/FieldsConfiguration/FieldsConfigurationContext'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

type Props = {
    onChange: (value: AnalysisTerm) => void
    isModeDisabled: boolean
    groupingField?: string
    isGroupingFieldDisabled?: boolean
    value: AnalysisTerm
    label?: string
    onChangeLabel: (label?: string) => void
    optionGenerator: (element: any) => Array<Option>
    onChangeGroupingField: (groupingField: string) => void
}

export const CalculatedFieldEditor = (props: Props) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [secondTermIsField, setSecondTermIsField] = useState(false)
    const [isEditingTerm, setIsEditingTerm] = useState(props.value.length < 5)
    const mode = getModeOfAnalysisTerm(props.value)
    const modeIsAvg = mode === 'avg'
    const fieldsConfiguration = useContext(FieldsConfigurationContext)

    const updateOrInsertValue = (newSymbol: AnalysisToken, index: number) => {
        const newTerm = produce(props.value, (draft) => {
            // sum(vF) + ? -> ? === vF -> sum(vF) + sum(vF)
            if (index === 5 && newSymbol.type === SYMBOL_TYPE.VALUE_FIELD) {
                draft = [
                    ...draft.slice(0, 5),
                    {
                        type: SYMBOL_TYPE.MODE,
                        value: 'sum',
                    },
                    {
                        type: SYMBOL_TYPE.OPEN_PARENTHESES,
                        value: '(',
                    },
                    newSymbol,
                    {
                        type: SYMBOL_TYPE.CLOSE_PARENTHESES,
                        value: ')',
                    },
                ]
                draft[0].value = 'sum'
                return draft
            }
            draft[index] = newSymbol
        })

        props.onChange(newTerm)
    }

    const handleModeChange = (mode: Mode) => {
        // Calculation with second value field
        if (
            props.value.length === 6 &&
            mode !== 'sum' &&
            props.value.filter((token) => token.type === SYMBOL_TYPE.VALUE_FIELD).length > 1
        ) {
            setErrorMessage("The calculation with two fields is currently only allowed with the mode 'sum'")
        }

        props.onChange(
            produce(props.value, (draft) => {
                draft[0] = { type: SYMBOL_TYPE.MODE, value: mode }
            })
        )
    }

    const handleAddSymbolToTerm = (index: number) => (newSymbol: AnalysisToken) => updateOrInsertValue(newSymbol, index)

    const handleRemoveToken = (index: number) => () =>
        props.onChange(
            produce(props.value, (draft) => {
                draft.splice(index, 1)
            })
        )

    useEffect(() => {
        if (getSecondValueFieldOfTerm(props.value)) {
            setSecondTermIsField(true)
        } else {
            setSecondTermIsField(false)
        }
    }, [props.value])

    const renderAddButton = (callBack: () => void, index: number) => (
        <HoverTooltip isVisible={true} header={renderAddButtonTooltip(index)}>
            <Button key={'cF-add-button' + index} onClick={callBack} isSquare label="..." />
        </HoverTooltip>
    )

    const renderAddButtonTooltip = (index: number) => {
        switch (index) {
            case 0:
                return 'Operation per Value Entry'
            case 1:
                return 'Operation on total Value'
        }
    }

    const resetToInitialTerm = () => {
        const initialTerm = props.value.slice(0, 3)
        initialTerm.push({
            type: SYMBOL_TYPE.CLOSE_PARENTHESES,
            value: ')',
        })
        props.onChange(initialTerm)
    }

    const renderRemoveSubtermButton = () => (
        <Button onClick={resetToInitialTerm} isSquare type="warning">
            <Icon
                className="calculated-field--token-editor__last-token--icon"
                name="TimesIcon"
                size="xs"
                color="error"
                onClick={resetToInitialTerm}
            />
        </Button>
    )

    // TODO recognize constant
    const renderTermEditor = () => {
        const isInInitialState = props.value.length === 4
        const editors = isInInitialState
            ? [
                  ...props.value.slice(0, 3).map(getEditor),
                  renderAddButton(addTokensInMode, 0),
                  getEditor(props.value[3], 3),
                  renderAddButton(addTokensAfterMode, 1),
              ]
            : [...props.value.map(getEditor)]

        if (!isInInitialState) {
            if (
                props.value.filter((t) => t.type === SYMBOL_TYPE.MODE).length === 1 &&
                props.value[props.value.length - 1].type === SYMBOL_TYPE.CLOSE_PARENTHESES
            ) {
                editors.splice(editors.length - 1, 0, renderRemoveSubtermButton())
            } else {
                editors.push(renderRemoveSubtermButton())
            }
        }

        if (!props.isGroupingFieldDisabled && modeIsAvg) {
            editors.push(renderAverageOptions())
        }

        return (
            <div className="calculated-field">
                <div className="calculated-field--editor">{editors}</div>
                <Button
                    className="calculated-field--editor-mode-button"
                    onClick={() => setIsEditingTerm(false)}
                    type="primary"
                    title="Save term"
                >
                    <Icon name="CheckmarkIcon" color="white" size="small" />
                </Button>
            </div>
        )
    }

    const renderAverageOptions = () => (
        <>
            <p className="calculated-field-string">per</p>
            <DocumentFieldSelect
                className="mode-field-select"
                key="field-select"
                onChange={props.onChangeGroupingField}
                clearable={true}
                value={props.groupingField}
                tags={[STRING_SUITABLE_FOR_GROUPING, DATE]}
                optionGenerator={props.optionGenerator}
            />
        </>
    )

    const addTokensInMode = () =>
        props.onChange(
            produce(props.value, (draft) => {
                draft.splice(3, 0, {
                    type: SYMBOL_TYPE.OPERATOR,
                    value: '+',
                })
                draft.splice(4, 0, {
                    type: SYMBOL_TYPE.VALUE_FIELD,
                    value: '',
                })
            })
        )

    const addTokensAfterMode = () =>
        props.onChange(
            produce(props.value, (draft) => {
                return [
                    ...draft,
                    {
                        type: SYMBOL_TYPE.OPERATOR,
                        value: '+',
                    },
                    {
                        type: SYMBOL_TYPE.VALUE_FIELD,
                        value: '',
                    },
                ]
            })
        )

    const getEditor = (token: AnalysisToken, index: number) => {
        switch (token.type) {
            case SYMBOL_TYPE.MODE:
                return (
                    <ModeEditor
                        value={mode}
                        key="calculated-field--mode-editor"
                        onChange={handleModeChange}
                        onlySum={secondTermIsField || props.isModeDisabled}
                    />
                )
            case SYMBOL_TYPE.OPEN_PARENTHESES:
                return null
            case SYMBOL_TYPE.CLOSE_PARENTHESES:
                return (
                    <div
                        className="calculated-field__closing-parentheses"
                        key={'calculated-field__closing-parentheses' + index}
                    >
                        <div className="calculated-field__closing-parentheses--concave" />
                    </div>
                )
            case SYMBOL_TYPE.VALUE_FIELD:
            case SYMBOL_TYPE.CONSTANT:
            case SYMBOL_TYPE.OPERATOR:
                // TODO replace ValueFieldOrConst with just valueField
                if ((index === 4 || index === 5) && token.type === SYMBOL_TYPE.VALUE_FIELD) {
                    return (
                        <CalculatedFieldTokenEditor
                            onChange={handleAddSymbolToTerm(index)}
                            type="ValueFieldOrConstant"
                            key={'cFEditor' + index}
                            value={token}
                            onRemoveToken={handleRemoveToken(index)}
                        />
                    )
                }

                return (
                    <CalculatedFieldTokenEditor
                        onChange={handleAddSymbolToTerm(index)}
                        type={token.type}
                        key={'cFEditor' + index}
                        value={token}
                        onRemoveToken={handleRemoveToken(index)}
                    />
                )
        }
    }

    const renderTermAsString = () => {
        let termString = ''
        props.value.forEach((token) => {
            const tokenValue = token.value
            const field = fieldsConfiguration[tokenValue]
            if (token.type === SYMBOL_TYPE.VALUE_FIELD && field && field.label) {
                termString += field.label + ' '
            } else {
                termString += tokenValue + ' '
            }
        })

        if (props.groupingField) {
            termString += props.groupingField
        }

        return (
            <div className="calculated-field">
                <TextInput
                    className="calculated-term-string"
                    hideBorder
                    disabled
                    value={termString}
                    onChange={() => {}}
                />
                <Button
                    type="orange"
                    className="calculated-field--editor-mode-button"
                    onClick={() => setIsEditingTerm(true)}
                >
                    <Icon name="PenIcon" color="white" size="small" />
                </Button>
            </div>
        )
    }

    const renderContent = () => {
        return isEditingTerm ? renderTermEditor() : renderTermAsString()
    }

    return errorMessage ? (
        <InputValidationWrapper message={errorMessage} messageType="hint">
            {renderContent()}
        </InputValidationWrapper>
    ) : (
        renderContent()
    )
}
