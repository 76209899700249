import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { actionTypes as system } from '../System'
import { UiState } from './types'
import { RootState } from '../types'

const demoModeNotificationSlice = createSlice({
    name: 'UI/DemoModeNotification',
    initialState: {} as UiState,
    extraReducers: {
        [system.INIT]: (state) => {
            state.demoModeNotification = {
                isHidden: isHidden(),
            }
        },
    },
    reducers: {
        setIsHidden: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                setHiddenOn()
            } else {
                removeHiddenOn()
            }

            state.demoModeNotification.isHidden = action.payload
        },
    },
})

export const actions = demoModeNotificationSlice.actions
export const reducer = demoModeNotificationSlice.reducer

/**
 * selection
 */

export const selectors = {
    isHidden: (state: RootState) => state.UI.demoModeNotification.isHidden,
}

/**
 * storage
 */

const LOCAL_STORAGE_HIDDEN_ON_ITEM_NAME = 'demo-mode-notification-modal__hidden-on'

function today() {
    return new Date().toDateString()
}

function getHiddenOn() {
    return window.localStorage.getItem(LOCAL_STORAGE_HIDDEN_ON_ITEM_NAME)
}

function setHiddenOn() {
    window.localStorage.setItem(LOCAL_STORAGE_HIDDEN_ON_ITEM_NAME, today())
}

function removeHiddenOn() {
    window.localStorage.removeItem(LOCAL_STORAGE_HIDDEN_ON_ITEM_NAME)
}

function isHidden() {
    return today() === getHiddenOn()
}
