import React from 'react'

// this could be any string, but to provide some kind of autocomplete I created these types,
// since these strings are often reused
type ExpectedClassNames = Array<
    | 'row-menu__item'
    | 'application-header__logo'
    | 'main-menu-toggle main-menu--visible'
    | 'dashboard-selector-handleOnClick'
>

const containsGivenObject = (event: React.MouseEvent | Event, expectedClassNames: ExpectedClassNames) => {
    // @ts-ignore .path is non standard (only chrome)
    if (event.path) {
        // @ts-ignore .path is non standard (only chrome)
        return event.path.some(checkSingleElementInPathFactory(expectedClassNames))
    } // Firefox, Safari

    let parent = event.target as Element | null
    if (Array.isArray(expectedClassNames)) {
        while (parent && parent.nodeName !== 'body') {
            if (containsOneOf(parent.className, expectedClassNames)) {
                return true
            }

            parent = parent.parentElement
        }
    } else {
        while (parent && parent.nodeName !== 'body') {
            if (parent.className.includes(expectedClassNames)) {
                return true
            }

            parent = parent.parentElement
        }
    }

    return false
}

function checkSingleElementInPathFactory(expectedClassNames: ExpectedClassNames) {
    if (Array.isArray(expectedClassNames)) {
        return (element: Element) => {
            if (typeof element.className === 'string') {
                return containsOneOf(element.className, expectedClassNames)
            }

            return false
        }
    }

    return (element: Element) => {
        if (typeof element.className === 'string') {
            return element.className.includes(expectedClassNames)
        }

        return false
    }
}

function containsOneOf(searchSpace: string, needles: ExpectedClassNames) {
    return needles.some((n) => (searchSpace.includes ? searchSpace.includes(n) : false))
}

export default containsGivenObject
