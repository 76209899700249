import React from 'react'
import { colorScales } from '../../Organisms/Widgets/Constants'
import Select from '../Select/Select'
import { SelectValue } from '../Select/Select.types'

const ColorScaleSelectOption = (props: {
    value: string
    onChange: (scale: SelectValue) => void
    setValue: (value: any) => void
    data: any
}) => {
    const colorBlocks = []
    for (let i = 0; i <= 1; i += 0.1) {
        colorBlocks.push(
            <div
                style={{
                    width: '10%',
                    height: '100%',
                    // @ts-ignore
                    backgroundColor: colorScales.get(props.data.value)(i),
                }}
                key={'color-scale-option' + i}
            />
        )
    }

    return (
        <div
            onClick={() => props.setValue(props.data)}
            role="button"
            style={{
                display: 'flex',
                height: '30px',
                width: '99%',
                padding: '5px',
            }}
        >
            {colorBlocks}
        </div>
    )
}

const ColorScaleSelect: React.FC<{
    value: string
    onChange: (scale: SelectValue) => void
}> = (props) => {
    const handleChange = (newScale: SelectValue) => {
        if (newScale !== props.value) {
            props.onChange(newScale)
        }
    }

    return (
        <Select
            value={props.value}
            components={{
                // @ts-ignore
                Option: ColorScaleSelectOption,
                // @ts-ignore
                SingleValue: ColorScaleSelectOption,
            }}
            options={Array.from(colorScales).map(([key]) => ({
                value: key,
                label: key,
            }))}
            isSearchable={false}
            onChange={handleChange}
        />
    )
}

export default ColorScaleSelect
