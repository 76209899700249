import React from 'react'

const jdbcUri =
    'https://blogs.oracle.com/dev2dev/get-oracle-jdbc-drivers-and-ucp-from-oracle-maven-repository-without-ides'

const OracleJdbcDriverDownload: React.FunctionComponent<{
    className?: string
}> = (props) => {
    return (
        <div className={props.className}>
            You need to install the{' '}
            <a href={jdbcUri} target="_blank" rel="noopener noreferrer">
                JDBC Oracle Driver
            </a>{' '}
            on the server manually.
            <ol>
                <li>Stop Exply</li>
                <li>
                    Place the <i>.jar</i> file in the <i>/var/exply/app/lib/</i> directory.
                </li>
                <li>Start Exply</li>
            </ol>
            Please <a href="mailto:support@exply.io">contact us</a>, if you have any questions.
        </div>
    )
}

export default OracleJdbcDriverDownload
