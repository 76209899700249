import { Reducer } from 'redux'
import * as Data from './Data'
import * as UI from './UI/index'
import * as System from './System'

export const actionTypes = {
    System: System.actionTypes,
}

export const actions = {
    Data: Data.actions,
    UI: UI.actions,
    System: System.actions,
}

export const createReducerFromArray = (handlers: Array<Reducer>) => (state: any, action: any) => {
    return handlers.reduce((state, handler) => {
        if (!handler) {
            return state
        }
        return handler(state, action)
    }, state)
}

export const reducerArray = {
    // IMPORTANT: DataReducer must be first in Array
    Data: createReducerFromArray(Data.reducerArray),
    UI: createReducerFromArray(UI.reducerArray),
}

export const selectors = {
    Data: Data.selectors,
    UI: UI.selectors,
}
