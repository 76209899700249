import React, { PureComponent } from 'react'
import classnames from 'classnames'
import TetherComponent from 'react-tether'
import enhanceWithClickOutside from 'react-click-outside'
import Icon from '../Icon/Icon'

import { inTestingMode } from '../../../Utility/Testing'

type MenuProps = {
    menuNamePrefix?: string
    children?: React.ReactNode
    size?: 'small'
    toggleElement?: React.ReactElement
}

type MenuState = {
    isOpen: boolean
}

const ContextMenuContext = React.createContext({
    isOpen: false,
    closeContextMenu: () => {},
})

/*
 * We use the React.Context API (aka provider/consumer) here to be able to close the wrapping ContextMenu
 * from inside ContextMenuItem. Usually you could pass the state and handler (in this case closeContextMenu)
 * directly as prop to the child (ContextMenuItem), but since the ContextMenuItems are passed in as props.children,
 * we have to wire the connection with the React.Context
 */

export default class ContextMenu extends PureComponent<MenuProps, MenuState> {
    constructor(props: MenuProps) {
        super(props)

        this.state = {
            isOpen: false,
        }
    }

    render() {
        const { isOpen } = this.state
        const sizeClassName = this.props.size ? 'context-menu__button--' + this.props.size : ''
        const buttonName = classnames('context-menu__button', sizeClassName, {
            'context-menu__button--active': isOpen,
        })

        if (inTestingMode) {
            return <div>Context Menu Fake</div>
        }

        return (
            <TetherComponent
                className={this.props.menuNamePrefix + 'context-menu'}
                attachment="top right"
                targetAttachment="bottom right"
                constraints={[
                    {
                        to: 'scrollParent',
                    },
                ]}
                // This is what the item will be tethered to
                renderTarget={this.renderTarget(buttonName, isOpen)}
                // If present, this item will be tethered to the the component
                renderElement={this.renderElement(isOpen)}
            />
        )
    }

    // reference is needed to work!
    renderTarget = (buttonName: string, isOpen: boolean) => (ref: any) => {
        if (this.props.toggleElement) {
            return (
                <span onClick={this.handleMenuClick} role="button" ref={ref}>
                    {this.props.toggleElement}
                </span>
            )
        }

        return (
            <span ref={ref}>
                <Icon
                    className={buttonName}
                    name={isOpen ? 'TimesIcon' : 'ContextIcon'}
                    color={isOpen ? 'white' : undefined}
                    title={isOpen ? 'close context menu' : 'open context menu'}
                    onClick={this.handleMenuClick}
                />
            </span>
        )
    }

    // reference is needed to work!
    renderElement = (isOpen: boolean) => (ref: any) => {
        if (!isOpen) {
            return null
        }

        const closeContextMenu = () => {
            this.setState({
                isOpen: false,
            })
        }

        return (
            <ContextMenuItems onClickOutside={this.handleClickOutside} ref={ref}>
                <ContextMenuContext.Provider value={{ ...this.state, closeContextMenu }}>
                    {this.props.children}
                </ContextMenuContext.Provider>
            </ContextMenuItems>
        )
    }

    handleMenuClick = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        this.setState({ isOpen: !this.state.isOpen })
    }

    handleClickOutside = () => {
        this.setState({ isOpen: false })
    }
}

class ContextMenuItemsInner extends PureComponent<{
    onClickOutside: () => void
    children: any
}> {
    render() {
        return <div className="context-menu__items">{this.props.children}</div>
    }

    handleClickOutside() {
        // the "handle click outside" callback needs to happen AFTER the event was worked on; otherwise toggling the menu button won't work
        // because the "handleClickOutside" callback will first close the menu; and then the "button click" is processed; which re-toggles
        // the menu back to "on".
        // That's why we delay it for the next rendering loop here :-)
        window.setTimeout(this.props.onClickOutside, 1)
    }
}

const ContextMenuItems = enhanceWithClickOutside(ContextMenuItemsInner)

export class ContextMenuItem extends PureComponent<{
    onClick: () => void
    children?: React.ReactNode
    className?: string
    disabled?: boolean
    hideOnBreakpoint?: 'small'
}> {
    render() {
        const { children } = this.props
        const classname = classnames('context-menu__item', this.props.className, {
            ['context-menu__item--hide-on-' + this.props.hideOnBreakpoint]: this.props.hideOnBreakpoint,
            'context-menu__item--disabled': this.props.disabled,
        })

        return (
            <ContextMenuContext.Consumer>
                {({ closeContextMenu }) => (
                    <div onClick={this.handleClick(closeContextMenu)} className={classname} role="button">
                        {children}
                    </div>
                )}
            </ContextMenuContext.Consumer>
        )
    }

    handleClick = (closeContextMenuCallback: () => void) => (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (!this.props.disabled) {
            this.props.onClick()
            closeContextMenuCallback()
        }
    }
}
