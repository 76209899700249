import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import WidgetHeader, { WidgetHeaderFunctions } from '../../../Molecules/WidgetHeader/WidgetHeader'
import { RootState } from '../../../../Redux/types'
import { selectors } from '../../../../Redux'

type ImageWidgetProps = {
    widgetConfiguration: {
        title?: string
        image?: string
    }
} & WidgetHeaderFunctions

const mapStateToProps = (state: RootState) => ({
    jiraCloudJwt: selectors.Data.System.jiraCloudJwt(state),
})

const ImageWidget: React.FC<ImageWidgetProps & ReturnType<typeof mapStateToProps>> = (props) => {
    const title = props.widgetConfiguration.title
    const className = classnames('widget', {
        'widget--has-header': title || props.onChangeTitle,
    })
    const imageUrl = props.widgetConfiguration.image

    const [image, setImage] = useState('')

    const getImage = () => {
        if (!imageUrl) return
        fetch(imageUrl, {
            headers: new Headers({
                Authorization: 'JWT ' + props.jiraCloudJwt,
            }),
        }).then((res) => {
            res.blob().then((blob) => {
                const img = URL.createObjectURL(blob)
                setImage(img)
            })
        })
    }

    useEffect(() => {
        if (imageUrl?.startsWith('data')) {
            setImage(imageUrl)
        } else {
            getImage()
        }
    }, [])

    return (
        <div className={className}>
            <WidgetHeader title={title} onChangeTitle={props.onChangeTitle} />
            <div className="image-widget">{image && <img src={image} alt={title} />}</div>
        </div>
    )
}

ImageWidget.displayName = 'ImageWidget'

export default connect(mapStateToProps)(ImageWidget)
