import React, { useState } from 'react'
import { AsyncCreatableSelect } from '../Select/Select'
import { ValueInput } from './FieldFilterManifest'

const buildValuesFromNameStrings = (values: Array<string>) => {
    if (values) {
        return values.map((v: string) => ({
            value: v,
            label: v,
        }))
    }

    return []
}

export const KeywordSelector: ValueInput = (props) => {
    const values = props.field.values

    const operandValues = buildValuesFromNameStrings(values)

    const [options, setOptions] = useState<Array<any>>([])
    const handleValuesChanged = (newValue: any) => {
        props.onValueChanged(newValue || [])
    }

    const handleFocusedInput = () => {
        const fieldName = props.field.field
        if (!fieldName) {
            return
        }

        props.onTriggerAutoCompletion(fieldName, '', values, (suggestions: Array<string>) => {
            setOptions(buildValuesFromNameStrings(suggestions))
        })
    }

    const handleLoadOptions = (input: string, callback: (options: { value: string; label: string }[]) => void) => {
        const field = props.field.field
        if (!field) {
            callback([])
        }

        props.onTriggerAutoCompletion(field, input, values, (suggestions: Array<string>) => {
            callback(buildValuesFromNameStrings(suggestions))
        })
    }

    return (
        <AsyncCreatableSelect
            defaultOptions={options}
            className={props.className}
            isMulti={true}
            value={operandValues}
            isValid={Boolean(operandValues.length)}
            options={options}
            onChange={handleValuesChanged}
            onFocus={handleFocusedInput}
            loadOptions={handleLoadOptions}
            placeholder={props.placeholder || 'Select an operand'}
        />
    )
}
