export type FieldTypesByTag = {
    [key: string]: string
}

/*
 * see {@link FieldType.groovy}
 */
type FieldType = {
    value: string
    label: string
    tag: string
    formats: null | Array<{
        value: string
        label: string
    }>
}

type FieldTypes = Array<{
    id: string
    definition: FieldType
}>

export const fieldTypes: FieldTypes = [
    {
        id: 'STRING',
        definition: {
            value: 'STRING',
            label: 'Category',
            tag: 'STRING_SUITABLE_FOR_GROUPING',
            formats: null,
        },
    },
    {
        id: 'NUMBER',
        definition: {
            value: 'NUMBER',
            label: 'Value',
            tag: 'NUMBER',
            formats: [
                /**
                 * Those formats are used on the server-side in java,
                 * so please adjust the test-cases accordingly in the NumberTypeStrategyTest.
                 */
                {
                    value: '#.##0,0',
                    label: '1.234,0',
                },
                {
                    value: "#.##0,0 '€'",
                    label: '1.234,0 €',
                },
                {
                    value: '#,##0.0',
                    label: '1,234.0',
                },
                {
                    value: "#,##0.0 '€'",
                    label: '1,234.0 €',
                },
                {
                    value: "'€' #,##0.00",
                    label: '€ 1,234.0',
                },
            ],
        },
    },
    {
        id: 'DATE',
        definition: {
            value: 'DATE',
            label: 'Date',
            tag: 'DATE',
            formats: [
                /**
                 * Those formats are used on the server-side in java,
                 * so please adjust the test-cases accordingly in the DateTypeStrategyTest.
                 */
                {
                    value: 'dd.MM.yyyy',
                    label: '13.08.2019',
                },
                {
                    value: 'dd.MM.yy',
                    label: '13.08.19',
                },
                {
                    value: "dd.MM.''yy",
                    label: "13.08.'19",
                },
                {
                    value: 'yyyy-MM-dd',
                    label: '2019-08-13',
                },
                {
                    value: 'd. MMMMM yyyy',
                    label: '13. August 2019',
                },
                {
                    value: 'MM.dd.yy',
                    label: '08.13.19',
                },
                {
                    value: "MM.dd.''yy",
                    label: "08.13.'19",
                },
                {
                    value: 'MM.dd.yyyy',
                    label: '08.13.2019',
                },
                {
                    value: 'dd-MMM-yy',
                    label: '13-Aug-19',
                },
                {
                    value: 'dd-MMM-yyyy',
                    label: '13-Aug-2019',
                },
            ],
        },
    },
    {
        id: 'DATE_RANGE',
        definition: {
            value: 'DATE_RANGE',
            label: 'Date Range',
            tag: 'DATE_RANGE',
            formats: [
                /**
                 * Those formats are used on the server-side in java,
                 * so please adjust the test-cases accordingly in the DateTypeStrategyTest.
                 */
                {
                    value: 'dd.MM.yyyy - dd.MM.yyyy',
                    label: '13.08.2019 - 13.08.2019',
                },
                {
                    value: 'dd.MM.yy - dd.MM.yy',
                    label: '13.08.19 - 13.08.19',
                },
                {
                    value: "dd.MM.''yy - dd.MM.''yy",
                    label: "13.08.'19 - 13.08.'19",
                },
                {
                    value: 'yyyy-MM-dd - yyyy-MM-dd',
                    label: '2019-08-13 - 2019-08-13',
                },
                {
                    value: 'MM.dd.yy - MM.dd.yy',
                    label: '08.13.19 - 08.13.19',
                },
                {
                    value: "MM.dd.''yy - MM.dd.''yy",
                    label: "08.13.'19 - 08.13.'19",
                },
                {
                    value: 'MM.dd.yyyy - MM.dd.yyyy',
                    label: '08.13.2019 - 08.13.2019',
                },
                {
                    value: 'dd-MMM-yy - dd-MMM-yy',
                    label: '13-Aug-19 - 13-Aug-19',
                },
                {
                    value: 'dd-MMM-yyyy - dd-MMM-yyyy',
                    label: '13-Aug-2019 - 13-Aug-2019',
                },
            ],
        },
    },
    {
        id: 'GEO_POINT',
        definition: {
            value: 'GEO_POINT',
            label: 'Geo Point',
            tag: 'GEO_POINT',
            formats: [
                /**
                 * Those formats are used on the server-side in java,
                 * so please adjust the test-cases accordingly in the GeoPointTypeStrategyTest.
                 */
                {
                    value: 'WKT',
                    label: 'WKT [POINT (xxx)]',
                },
                {
                    value: 'lat,lon',
                    label: 'lat,lon [z.B. 41.24,-71.43]',
                },
            ],
        },
    },
]
