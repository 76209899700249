import * as Editing from './Editing'
import * as Execution from './ImporterExecution'
import * as Management from './ImporterManagement'

export const actions = {
    ...Editing.actions,
    ...Execution.actions,
    ...Management.actions,
}

export const reducerArray = [...Editing.reducerArray, Execution.reducer, Management.reducer]

export const selectors = {
    ...Editing.selectors,
    ...Execution.selectors,
    ...Management.selectors,
}
