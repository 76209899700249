import React from 'react'
import classnames from 'classnames'

// TODO: currently this library is not typed, pls check from time to time if there are types available
// @ts-ignore
import { StickyTable, Row, Cell } from 'react-sticky-table'

import Icon from '../Icon/Icon'
import { formatNumberToString } from '../../../Utility/NumberFormator'

type StyleShape = {
    numberOfDecimals: number
    unit: string
}

type OrderTypes = 'asc' | 'desc' | undefined | null

export type TableColumn = {
    field: string
    label: string
    direction?: OrderTypes
    isDisabled?: boolean
} & TableColumnFormatAndStyle

export type TableColumnFormatAndStyle = {
    style?: 'numeric' | null
    format?: StyleShape
}

export type RowShape = Record<string, any>

const Table = React.forwardRef(
    (
        props: {
            className?: string
            allowOverflow?: boolean
            columns: Array<TableColumn>
            rows: Array<RowShape>
            onTableHeaderClicked: (column: TableColumn) => void
            hasStickyHeader?: boolean
        },
        ref
    ) => {
        const rowIndexes: Array<number> = Array.from({ length: props.rows.length }, (value, key) => key)
        const className = classnames('table', props.className, {
            'table--allow-overflow': props.allowOverflow,
        })

        const renderHeader = () => {
            return (
                <Row>
                    {props.columns.map((column: TableColumn, columnIndex: number) => {
                        const cellClassname = classnames('table-cell sticky-table-cell table-header', {
                            'table-header--isDisabled': column.isDisabled,
                        })

                        return (
                            <Cell
                                key={`col-${columnIndex}`}
                                className={cellClassname}
                                onClick={onTableHeaderClickedHandler(column)}
                                role="button"
                            >
                                {column.label} {renderAngle(column.direction)}
                            </Cell>
                        )
                    })}
                </Row>
            )
        }

        const onTableHeaderClickedHandler = (column: TableColumn) => () => {
            props.onTableHeaderClicked(column)
        }

        const renderAngle = (ordering: OrderTypes) => {
            if (ordering) {
                return (
                    <Icon
                        name="AngleIcon"
                        rotation={ordering === 'asc' ? -90 : 90}
                        size="small"
                        title={ordering === 'asc' ? 'ascending' : 'descending'}
                    />
                )
            }

            return ''
        }

        const renderRow = (index: number, row: RowShape) => {
            return (
                <Row key={`row-${index}`}>
                    {props.columns.map((column: TableColumn, columnIndex: number) => {
                        const isNumeric = column.style === 'numeric'
                        const format = isNumeric && column.format
                        const value = row[column.field]
                        const cellClassName = classnames('table-cell', 'sticky-table-cell', {
                            'table__row__cell--style-is-numeric': isNumeric,
                            'table-cell--isDisabled': column.isDisabled,
                        })

                        return (
                            <Cell className={cellClassName} key={`col-${columnIndex}`}>
                                {format ? formatNumberToString(value, format.numberOfDecimals, format.unit) : value}
                            </Cell>
                        )
                    })}
                </Row>
            )
        }

        return (
            <StickyTable
                stickyHeaderCount={props.hasStickyHeader ? 1 : 0}
                stickyColumnCount={0}
                className={className}
                wrapperRef={ref}
            >
                {renderHeader()}
                {rowIndexes.map((i: number) => renderRow(i, props.rows[i]))}
            </StickyTable>
        )
    }
)

export default Table
