import React, { memo } from 'react'
import { connect } from 'react-redux'
import { Draggable, DraggableStateSnapshot, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import TileWall, { Tile } from '../../Molecules/TileWall/TileWall'
import { DashboardId } from '../../../Redux/Data/types'
import DashboardTileViewItem from './DashboardTileViewItem'
import Icon from '../../Atoms/Icon/Icon'
import { RootState } from '../../../Redux/types'
import { selectors } from '../../../Redux'
import { dispatchAddNewDashboardAction } from './CreateNewDashboardButton'
import { AppDispatch } from '../../../store'
import { DashboardGroupId } from '../../../Redux/UI/types'

type OwnProps = {
    dashboardIds: Array<DashboardId>
    dashboardGroupId?: DashboardGroupId
    isFavouriteList?: boolean
    isDragDisabled?: boolean
    dragNDropPlaceholder?: any
}

const mapStateToProps = (state: RootState) => ({
    canManageDashboards: selectors.UI.CurrentUser.settings(state)?.canManageDashboards ?? false,
})

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
    onAddNewDashboard: () =>
        dispatchAddNewDashboardAction(dispatch, ownProps.isFavouriteList, ownProps.dashboardGroupId),
})

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const DashboardTileView = (props: Props) => {
    // disable tiles moving around in group when dragging
    function getStyle(style: DraggingStyle | NotDraggingStyle | undefined, snapshot: DraggableStateSnapshot) {
        if (!snapshot.isDragging) return {}
        if (!snapshot.isDropAnimating) {
            return style
        }

        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`,
        }
    }

    return (
        <TileWall>
            {props.dashboardIds.map((dashboardId, index) => (
                <Draggable
                    // prefix with group to differ between same dashboard in different groups
                    draggableId={props.dashboardGroupId + '#' + dashboardId}
                    index={index}
                    key={'draggable-' + dashboardId}
                    isDragDisabled={props.isDragDisabled}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getStyle(provided.draggableProps.style, snapshot)}
                        >
                            <DashboardTileViewItem
                                key={dashboardId}
                                dashboardId={dashboardId}
                                dashboardGroupId={props.dashboardGroupId}
                            />
                        </div>
                    )}
                </Draggable>
            ))}

            {props.canManageDashboards && (
                <Tile onClick={props.onAddNewDashboard} className="dashboard-tile dashboard-tile--create">
                    <Icon name="PlusIcon" color="green" size="lg" />
                    New Dashboard
                </Tile>
            )}
            {props.dragNDropPlaceholder}
        </TileWall>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DashboardTileView))
