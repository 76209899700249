import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { connect } from 'react-redux'
import uuid from 'uuid'
import { ModalActions, ModalContent } from '../../../../Components/Molecules/Modal/Modal'
import TextInput from '../../../../Components/Atoms/TextInput/TextInput'
import Icon from '../../../../Components/Atoms/Icon/Icon'
import Button from '../../../../Components/Atoms/Button/Button'
import { RootState } from '../../../../Redux/types'
import { actions, selectors } from '../../../../Redux'
import { AppDispatch } from '../../../../store'
import { DashboardId, SharedDashboardId } from '../../../../Redux/Data/types'
import { UserOrGroupId } from '../../../../Redux/Data/UserManagement'
import InputValidationWrapper from '../../../../Components/Atoms/InputValidationWrapper/InputValidationWrapper'
import { getValidationErrorMessage } from '../../../../Utility/Validation'
import Switch from '../../../../Components/Atoms/Switch/Switch'
import { startSharingAction, stopSharingAction } from '../../../../Sagas/DashboardSagas/ShareDashboardSaga'

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
    sharedDashboard: selectors.Data.Dashboards.ShareDashboard.getSharedDashboardByDashboardId(
        state,
        ownProps.parameters.dashboardId
    ),
    internalLink: selectors.Data.Dashboards.ShareDashboard.getInternalSharingLink(
        state,
        ownProps.parameters.dashboardId
    ),
    externalLink: (sharedDashboardId: SharedDashboardId) =>
        selectors.Data.Dashboards.ShareDashboard.getExternalSharingLink(state, sharedDashboardId),
})

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
    showCopyNotification: () => dispatch(actions.UI.Notification.show({ id: 'copiedToClipboard' })),
    hideCopyNotification: () => dispatch(actions.UI.Notification.dismiss()),
    startSharing: (id: string, password: string, allowDownload: boolean) =>
        dispatch(
            startSharingAction({
                id,
                dashboardId: ownProps.parameters.dashboardId,
                password,
                allowDownload,
            })
        ),
    stopSharing: (id: SharedDashboardId) => dispatch(stopSharingAction(id)),
})

type OwnProps = {
    onCancelModal: () => void
    onConfirmModal: (args?: any) => void
    parameters: {
        dashboardId: DashboardId
        currentUserId: UserOrGroupId
    }
}

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const ShareDashboardModal: React.FC<Props> = (props) => {
    const [isPublic, setIsPublic] = useState(Boolean(props.sharedDashboard))
    const [isPasswordProtected, setIsPasswordProtected] = useState(props.sharedDashboard?.isPasswordProtected || false)
    const [password, setPassword] = useState('')
    const [allowDownload, setAllowDownload] = useState(props.sharedDashboard?.isDownloadAllowed || false)
    const [id] = useState(props.sharedDashboard?.id || uuid())

    const handleCopiedLink = () => {
        props.showCopyNotification()
        setTimeout(props.hideCopyNotification, 3000)
    }

    const handleSave = () => {
        if (isPublic) {
            props.startSharing(id, password, allowDownload)
        } else if (props.sharedDashboard) {
            props.stopSharing(id)
        }

        props.onConfirmModal()
    }

    const passwordValidation = {
        isValid: password.length === 0 || password.length > 7,
        errorMessage: getValidationErrorMessage(password, [
            {
                type: 'minLength',
                length: 8,
                message: 'Password needs to have a least 8 characters',
            },
        ]),
    }

    return (
        <ModalContent className="share-dashboard-modal">
            <h2>Share Dashboard</h2>

            <div className="share-dashboard--link">
                <Icon name={'LinkIcon'} label={'Internal Link'} />
                <div className="share-dashboard--link-text">
                    <TextInput value={props.internalLink} onChange={() => {}} />
                    <CopyToClipboard text={props.internalLink} onCopy={handleCopiedLink}>
                        <Icon name="ClipboardIcon" title="copy to clipboard" />
                    </CopyToClipboard>
                </div>
                <p className="hint">Only Exply users with access to this dashboard can use this link.</p>
            </div>

            <hr />
            <div className="public-settings">
                <div>
                    <div className="icon--and--switch">
                        <Icon name={'ExternalLinkIcon'} />
                        <Switch
                            value={isPublic}
                            onChange={setIsPublic}
                            label={'Public Link'}
                            labelPositionRight={false}
                        />
                    </div>
                    <div className="share-dashboard--link-text">
                        <TextInput value={props.externalLink(id)} onChange={() => {}} />
                        <CopyToClipboard text={props.externalLink(id)} onCopy={handleCopiedLink}>
                            <Icon name="ClipboardIcon" title="copy to clipboard" />
                        </CopyToClipboard>
                    </div>

                    <p className="hint">Anyone with this link can view the dashboard.</p>
                    <p className="hint">Dashboard is {!isPasswordProtected && 'not'} password protected.</p>
                </div>

                {
                    // can't change the password of an existing shared dashboard
                    !props.sharedDashboard && (
                        <>
                            <div className="icon--and--switch">
                                <Icon name={isPasswordProtected ? 'LockedIcon' : 'UnlockedIcon'} />
                                <Switch
                                    onChange={setIsPasswordProtected}
                                    value={isPasswordProtected}
                                    label={'Password'}
                                    labelPositionRight={false}
                                />
                            </div>
                            <InputValidationWrapper
                                messageType="hint"
                                message={isPasswordProtected ? passwordValidation.errorMessage : undefined}
                            >
                                <TextInput
                                    value={password}
                                    placeholder="Password"
                                    onChange={setPassword}
                                    type={'password'}
                                    disabled={!isPasswordProtected}
                                />
                            </InputValidationWrapper>
                        </>
                    )
                }
                <Switch
                    onChange={setAllowDownload}
                    value={allowDownload}
                    label={'Allow Download of Dashboard Configuration'}
                />
            </div>

            <ModalActions>
                <Button onClick={props.onCancelModal} type={'warning'}>
                    Cancel
                </Button>
                <Button
                    isDisabled={!props.sharedDashboard && !passwordValidation.isValid}
                    onClick={handleSave}
                    type={'primary'}
                >
                    Save
                </Button>
            </ModalActions>
        </ModalContent>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareDashboardModal)
