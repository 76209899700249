import React from 'react'
import { connect } from 'react-redux'

import produce from 'immer'

import { actions, selectors } from '../../../Redux'
import UserEditing from '../../UserManagement/UserEditing/UserEditing'
import Button from '../../../Components/Atoms/Button/Button'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { UserOrGroupConfig } from '../../../Redux/Data/UserManagement'
import ButtonWithTooltip from '../../../Components/Molecules/HoverTooltip/ButtonWithTooltip'

const mapStateToProps = (state: RootState) => {
    return {
        newUser: selectors.Data.UserManagement.currentlyEditedUserOrGroup(state),
        userOrGroupValidation: selectors.Data.UserManagement.currentlyEditedUserOrGroupValidation(state),
        hasPassword: selectors.Data.UserManagement.currentlyEditedUserOrGroupHasPassword(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        updateProperty: (key: keyof UserOrGroupConfig, value: any) =>
            dispatch(actions.Data.UserManagement.updateProperty({ key, value })),
        handleSaveNewUser: (user: UserOrGroupConfig) => {
            dispatch(actions.Data.UserManagement.createOrUpdate({ userOrGroup: user }))
            dispatch(actions.UI.SetupWizard.nextStep())
        },
        skipStep: () => {
            dispatch(actions.UI.SetupWizard.nextStep())
        },
    }
}

type AddUserStepProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class AddUserStep extends React.PureComponent<AddUserStepProps> {
    handleSaveNewUser = () => {
        if (this.props.newUser) {
            this.props.handleSaveNewUser(this.props.newUser)
        }
    }

    renderHoverTooltipContent = () => {
        if (this.props.hasPassword) {
            return ['To save you need to set a password for your new user']
        }
    }

    render() {
        return (
            <div className="setup-wizard__step">
                <UserEditing showFooter={false} showPasswordWarning={false} />

                <div className="setup-wizard__warning">
                    {this.props.hasPassword ? '' : 'To save you need to set a password for your new user'}
                </div>

                <div className="setup-wizard__next-button">
                    <Button onClick={this.props.skipStep}>Skip</Button>
                    <ButtonWithTooltip
                        isDisabled={!this.props.userOrGroupValidation?.isValid || !this.props.hasPassword}
                        header="Required data is missing"
                        content={this.renderHoverTooltipContent()}
                        onClick={this.handleSaveNewUser}
                        type="primary"
                    >
                        Save & Next
                    </ButtonWithTooltip>
                </div>
            </div>
        )
    }

    componentDidUpdate(prevProps: AddUserStepProps) {
        if (prevProps.newUser === null && this.props.newUser !== null) {
            this.props.updateProperty('description', 'My First Exply User')
            this.props.updateProperty(
                'settings',
                produce(this.props.newUser.settings, (draft) => {
                    draft.isSystemManager = true
                    draft.canSeeAnyDashboard = true
                })
            )

            this.props.updateProperty(
                'documentPermissions',
                produce(this.props.newUser.documentPermissions, (draft) => {
                    draft.ignoreDocumentTypes = true
                    draft.ignoreFieldPermissions = true
                })
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserStep)
