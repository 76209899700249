import { put, select, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { actions, selectors } from '../Redux'
import ApplicationMode from '../ApplicationMode'
import axios from './Utility/axios'
import { isNewerVersionThan } from './Utility/compareVersions'

export default function* VersionsSaga() {
    yield takeLatest(actions.System.sagasInit, function* () {
        const appMode = selectors.Data.System.applicationMode(yield select())

        if (appMode === ApplicationMode.OnPremise) {
            try {
                const response: AxiosResponse = yield axios({
                    method: 'GET',
                    url: 'api/latestVersion',
                })

                if (response.status === 200) {
                    const localVersion = selectors.Data.System.version(yield select())
                    const localVersionWithoutSnaps = localVersion.split('+')[0].trim()
                    const latestVersion = response.data.version

                    if (isNewerVersionThan(latestVersion, localVersionWithoutSnaps)) {
                        // eslint-disable-next-line no-console
                        console.log(
                            '%cNew Exply version available!',
                            'color:yellow',
                            localVersionWithoutSnaps,
                            latestVersion
                        )
                        yield put(
                            actions.UI.Notification.show({
                                id: 'updateAvailable',
                                details:
                                    'You are currently running Exply Version ' +
                                    localVersionWithoutSnaps +
                                    '. Exply Version ' +
                                    latestVersion +
                                    ' is available! Get it now from <a href="https://exply.io/en/download/choose-exply-version.html">exply.io</a>.',
                            })
                        )
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('%cExply is up to date.', 'color:green')
                    }
                } else {
                    // eslint-disable-next-line no-console
                    console.log('failed to fetch latest version number of exply')
                }
            } catch (e) {
                console.error(e)
            }
        }
    })
}
