import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../types'
import { DataState } from './types'
import { actionTypes as system } from '../System'
import { ColorCustomizationType } from '../../Components/Organisms/ColorCustomization/ColorCustomization'

const colorsCustomizationSlice = createSlice({
    name: 'Data/ColorsCustomization',
    initialState: {} as DataState,
    extraReducers: {
        [system.INIT]: (state, action) => {
            const { initialState } = action.payload
            state.colorCustomization = {
                fieldBasedColors: initialState.data.customColors.fieldBasedColors,
                transientFieldBasedColors: initialState.data.customColors.fieldBasedColors,
            }
        },
    },
    reducers: {
        changeColorConfig: {
            prepare: (value: ColorCustomizationType) => ({ payload: { value } }),
            reducer: (state, action) => {
                state.colorCustomization.transientFieldBasedColors = action.payload.value
            },
        },
        reset: (state) => {
            state.colorCustomization.transientFieldBasedColors = {}
        },
        resetTransient: (state) => {
            state.colorCustomization.transientFieldBasedColors = state.colorCustomization.fieldBasedColors
        },
    },
})

export const actions = colorsCustomizationSlice.actions
export const reducer = colorsCustomizationSlice.reducer

const getCustomColors = (state: RootState) => state.Data.colorCustomization.fieldBasedColors
const getTransientCustomColors = (state: RootState) => state.Data.colorCustomization.transientFieldBasedColors
const hasColorConfigurationChanged = (state: RootState) =>
    !Object.is(getCustomColors(state), getTransientCustomColors(state))

export const selectors = {
    getCustomColors,
    getTransientCustomColors,
    hasColorConfigurationChanged,
}
