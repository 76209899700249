import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uuid from 'uuid'
import {
    DashboardConfiguration,
    DashboardId,
    DataState,
    NormalizedDashboardConfig,
    WidgetConfiguration,
    WidgetGroupConfiguration,
    WidgetGroupId,
    WidgetId,
} from '../types'

const duplicateDashboardSlice = createSlice({
    name: 'Data/Dashboards/DuplicateDashboard',
    initialState: {} as DataState,
    reducers: {
        storeDuplicatedDashboard: (state, action: PayloadAction<NormalizedDashboardConfig>) => {
            const { dashboards, widgetGroups, widgets } = action.payload
            const dashboardId = dashboards.allIds[0]
            state.dashboards.normalizedDashboardConfig.dashboards.allIds.push(dashboardId)
            state.dashboards.normalizedDashboardConfig.dashboards.byId[dashboardId] = dashboards.byId[dashboardId]

            state.dashboards.normalizedDashboardConfig.widgetGroups.allIds.push(...widgetGroups.allIds)
            widgetGroups.allIds.forEach((groupId) => {
                state.dashboards.normalizedDashboardConfig.widgetGroups.byId[groupId] = widgetGroups.byId[groupId]
            })

            state.dashboards.normalizedDashboardConfig.widgets.allIds.push(...widgets.allIds)
            widgets.allIds.forEach((widgetId) => {
                state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId] = widgets.byId[widgetId]
            })
        },
    },
})

export const actions = duplicateDashboardSlice.actions
export const reducer = duplicateDashboardSlice.reducer

export default function duplicateDashboard(
    normalizedDashboardConfig: NormalizedDashboardConfig,
    dashboardToDuplicateId: DashboardId,
    newDashboardId: DashboardId,
    newTitle: string
): NormalizedDashboardConfig {
    const dashboardConfigurationToDuplicate: DashboardConfiguration =
        normalizedDashboardConfig.dashboards.byId[dashboardToDuplicateId]

    const oldWidgetGroupIdToNewWidgetGroupId: Record<WidgetGroupId, WidgetGroupId> =
        dashboardConfigurationToDuplicate.widgetGroups.reduce(
            (idMap, widgetGroupToDuplicateId) => ({
                ...idMap,
                [widgetGroupToDuplicateId]: uuid(),
            }),
            {} as Record<WidgetGroupId, WidgetGroupId>
        )

    const oldWidgetIdToNewWidgetId: Record<WidgetId, WidgetId> = dashboardConfigurationToDuplicate.widgetGroups.reduce(
        (idMap, widgetGroupId) => {
            const idMapForThisGroup = normalizedDashboardConfig.widgetGroups.byId[widgetGroupId].widgets.reduce(
                (acc, oldId) => ({
                    ...acc,
                    [oldId]: uuid(),
                }),
                {} as Record<WidgetId, WidgetId>
            )

            return {
                ...idMap,
                ...idMapForThisGroup,
            }
        },
        {} as Record<WidgetId, WidgetId>
    )

    const duplicatedWidgetGroupConfigurations = Object.keys(oldWidgetGroupIdToNewWidgetGroupId).reduce(
        (acc, widgetGroupToDuplicateId) => {
            const newWidgetGroupId = oldWidgetGroupIdToNewWidgetGroupId[widgetGroupToDuplicateId]
            const newWidgetIds = normalizedDashboardConfig.widgetGroups.byId[widgetGroupToDuplicateId].widgets.map(
                (oldId) => oldWidgetIdToNewWidgetId[oldId]
            )

            return {
                ...acc,
                [newWidgetGroupId]: {
                    ...normalizedDashboardConfig.widgetGroups.byId[widgetGroupToDuplicateId],
                    id: newWidgetGroupId,
                    widgets: newWidgetIds,
                    dashboardId: newDashboardId,
                },
            }
        },
        {} as Record<WidgetGroupId, WidgetGroupConfiguration>
    )

    const duplicatedWidgetConfigurations = Object.keys(oldWidgetIdToNewWidgetId).reduce((acc, widgetToDuplicateId) => {
        const newWidgetId = oldWidgetIdToNewWidgetId[widgetToDuplicateId]
        const widgetConfig = normalizedDashboardConfig.widgets.byId[widgetToDuplicateId]

        return {
            ...acc,
            [newWidgetId]: {
                ...widgetConfig,
                id: newWidgetId,
                widgetGroup: oldWidgetGroupIdToNewWidgetGroupId[widgetConfig.widgetGroup],
            },
        }
    }, {} as Record<WidgetId, WidgetConfiguration>)

    const newGlobalWidgetGroup = dashboardConfigurationToDuplicate.globalWidgetGroup
        ? oldWidgetGroupIdToNewWidgetGroupId[dashboardConfigurationToDuplicate.globalWidgetGroup]
        : null

    // create duplicate DashboardConfiguration
    const duplicateDashboardConfiguration: DashboardConfiguration = {
        id: newDashboardId,
        title: newTitle,
        widgetGroups: Object.values(oldWidgetGroupIdToNewWidgetGroupId),
        globalWidgetGroup: newGlobalWidgetGroup,
        icon: dashboardConfigurationToDuplicate.icon,
    }

    return {
        dashboards: {
            allIds: [newDashboardId],
            byId: {
                [newDashboardId]: duplicateDashboardConfiguration,
            },
        },
        widgetGroups: {
            allIds: Object.values(oldWidgetGroupIdToNewWidgetGroupId),
            byId: duplicatedWidgetGroupConfigurations,
        },
        widgets: {
            allIds: Object.values(oldWidgetIdToNewWidgetId),
            byId: duplicatedWidgetConfigurations,
        },
        sharedDashboards: {
            allIds: [],
            byId: {},
        },
    }
}
