import React from 'react'
import { ModalActions, ModalContent } from '../../../Molecules/Modal/Modal'
import Button from '../../../Atoms/Button/Button'
// eslint-disable-next-line import/extensions
import { ModalConfiguration } from '../../../../StaticManifests/manifest.modals'

const HintModal: React.FC<{
    currentModalConfiguration: ModalConfiguration
    onCancelModal: () => void
}> = (props) => {
    return (
        <ModalContent>
            <div>{props.currentModalConfiguration.message}</div>

            <ModalActions>
                <Button onClick={props.onCancelModal}>Ok</Button>
            </ModalActions>
        </ModalContent>
    )
}

export default HintModal
