import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { createHashHistory } from 'history'
import { reducerArray } from './Redux'

/**
 * 1) Build up store.
 */
export const sagaMiddleware = createSagaMiddleware()

/**
 * 2) Router/History setup
 *
 * getUserConfirmation: this callback handles a blocked user navigation - since we don't want a dialog popup
 * when the user navigates back in the DataSourceEditor, we overrride it. See EditDataSourceStepManager.tsx and the
 * ReactRouter Docs for details.
 */
export const history = createHashHistory({
    getUserConfirmation: () => {}, // disable user confirmation on back navigation, see EditDataSourceStepManager
})

const makeStore = () => {
    return configureStore({
        reducer: combineReducers({
            router: connectRouter(history),
            ...reducerArray,
        }),
        middleware: [routerMiddleware(history), sagaMiddleware],
    })
}

const store = makeStore()

export default store
export type AppDispatch = typeof store.dispatch
