import React, { PureComponent } from 'react'
import Headline from '../../../Atoms/Headline/Headline'
import Button from '../../../Atoms/Button/Button'
import { ModalActions, ModalContent } from '../../../Molecules/Modal/Modal'
import TextInput from '../../../Atoms/TextInput/TextInput'
import Switch from '../../../Atoms/Switch/Switch'
import ButtonWithTooltip from '../../../Molecules/HoverTooltip/ButtonWithTooltip'

type DuplicateDashboardModalProps = {
    onConfirmModal: (arg0: any) => void
    onCancelModal: () => void
    parameters: {
        showNavigate: boolean
    }
}

type DuplicateDashboardModalState = {
    name: string
    navigate: boolean
}

export default class DuplicateDashboardModal extends PureComponent<
    DuplicateDashboardModalProps,
    DuplicateDashboardModalState
> {
    constructor(props: DuplicateDashboardModalProps) {
        super(props)

        this.state = {
            name: '',
            navigate: false,
        }
    }

    render() {
        const showNavigationSwitch = this.props.parameters.showNavigate

        return (
            <ModalContent className="duplicate-dashboard-modal">
                <Headline configuration={{ title: 'Duplicate Dashboard' }} />

                <p>Please provide a name for the new dashboard!</p>
                <br />

                <TextInput
                    value={this.state.name}
                    placeholder="Dashboard Name"
                    onChange={(v) => this.setState({ name: v })}
                    autoFocus={true}
                />

                {showNavigationSwitch && (
                    <Switch
                        onChange={() => this.setState({ navigate: !this.state.navigate })}
                        value={this.state.navigate}
                        label="Switch to new dashboard"
                    />
                )}

                <ModalActions>
                    <Button onClick={this.props.onCancelModal}>Cancel</Button>
                    <ButtonWithTooltip
                        isDisabled={this.state.name === ''}
                        header="Missing name"
                        onClick={this.handleDuplicate}
                        type="primary"
                    >
                        Duplicate
                    </ButtonWithTooltip>
                </ModalActions>
            </ModalContent>
        )
    }

    handleDuplicate = () => {
        if (this.state.name !== '') {
            this.props.onConfirmModal({
                dashboardName: this.state.name,
                navigate: this.state.navigate,
            })
        }
    }
}
