import { connect } from 'react-redux'
import FileUpload, {
    File,
    HandleFileUploadDone,
    HandleFileUploadProgress,
} from '../Components/Molecules/FileUpload/FileUpload'
import endpoint from '../Utility/endpoint'
import { AppDispatch } from '../store'
import { startWidgetAssetUploadAction } from '../Sagas/FileUploadSaga'

const mapDispatchToProps = (dispatch: AppDispatch, props: any) => ({
    onStartUpload: (file: File, handleProgress: HandleFileUploadProgress, handleDone: HandleFileUploadDone) =>
        dispatch(startWidgetAssetUploadAction(file, handleProgress, handleDone)),
    onChange: (response: any) => props.onChange(endpoint + response.filePath),
})

const ImageUploader = connect(null, mapDispatchToProps)(FileUpload)
export default ImageUploader
