import React from 'react'

export const CustomTickForHorizontal: React.FC<CustomTickProps> = React.memo((props) => {
    const height = (props.height! + 110) / props.visibleTicksCount!
    const width = props.width! + 10
    const fontSize = Math.max(8, Math.min(13, height * 0.3))
    // calculation to determine whether the row label is going to need 2 lines or more
    // found out by trial and error with multiple variables
    // (width / (fontSize * labelLength)) < 0.4 -> string too long for 1 line, could also work for different widths
    // true = needs two lines, false = needs only one line
    const isTwoLines = width / (fontSize * props.payload!.value.length) <= 0.4

    return (
        <foreignObject
            width={width}
            height={Math.max(20, height)}
            x={25}
            y={props.payload!.coordinate - height / 3}
            fontSize={fontSize}
        >
            <div
                title={props.payload!.value}
                style={{
                    display: 'flex',
                    alignItems: isTwoLines ? 'start' : 'center',
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                    height: 2 * fontSize + 1,
                    lineHeight: 1,
                    overflow: 'hidden',
                }}
            >
                {props.payload!.value}
            </div>
        </foreignObject>
    )
})

export const CustomTickForVertical: React.FC<CustomTickProps> = React.memo((props) => {
    const { x, y, height, index, payload } = props
    const width = Math.max(10, (props.width! - 100) / (props.visibleTicksCount! / 2))
    return (
        <React.Fragment>
            <foreignObject
                x={x! - width / 2}
                y={y! + (index! % 2 ? 0 : 18)}
                width={width}
                height={height}
                fontSize={12}
            >
                <div className="axis-label--vertical" title={payload!.value}>
                    {payload!.value}
                </div>
            </foreignObject>

            {index! % 2 ? null : (
                <line x1={x} y1={y! - 5} x2={x} y2={y! + 15} style={{ stroke: '#666', strokeWidth: 1 }} />
            )}
        </React.Fragment>
    )
})

type CustomTickProps = Partial<{
    x: number
    y: number
    height: number
    index: number
    width: number
    visibleTicksCount: number
    payload: {
        value: any
        coordinate: number
    }
}>
