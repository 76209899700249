import React, { PureComponent } from 'react'
import produce from 'immer'
import enhanceWithClickOutside from 'react-click-outside'
import TextInput from '../../Atoms/TextInput/TextInput'
import FormFieldWithLabel from '../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import DocumentFieldSelect from '../../Atoms/DocumentFieldSelect/DocumentFieldSelect'
import Button from '../../Atoms/Button/Button'
import Icon from '../../Atoms/Icon/Icon'
import { FieldsConfigurationContext } from '../../Atoms/FieldsConfiguration/FieldsConfigurationContext'
import containsGivenObject from '../../../Utility/HandleClickOutside'
import { FieldsConfiguration } from '../../Atoms/FieldsConfiguration/FieldsConfigurationTypes'

export type ColorCustomizationType = {
    [key: string]: {
        fieldColor: string
    }
}

type Props = {
    colorCustomization: ColorCustomizationType
    onChange: (newValue: ColorCustomizationType) => void
    onUnsavedLeave: (event: Event) => void
    hasColorConfigurationChanged: boolean
}

class ColorCustomization extends PureComponent<
    Props,
    {
        unusedFields: Array<string>
    }
> {
    static contextType = FieldsConfigurationContext

    constructor(props: Props, context: FieldsConfiguration) {
        super(props)

        const fieldsConfiguration = context
        this.state = {
            unusedFields: Object.entries(fieldsConfiguration)
                .filter((entry) => entry[1].tag === 'NUMBER')
                .map((entry) => entry[0]),
        }
    }

    handleClickOutside = (event: Event) => {
        if (
            containsGivenObject(event, ['row-menu__item', 'application-header__logo']) &&
            this.props.hasColorConfigurationChanged
        ) {
            event.stopPropagation()
            event.preventDefault()
            this.props.onUnsavedLeave(event)
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.colorCustomization !== this.props.colorCustomization) {
            const usedFields = this.props.colorCustomization ? Object.keys(this.props.colorCustomization) : []
            this.setState({
                unusedFields: this.state.unusedFields.filter((f) => !usedFields.includes(f)),
            })
        }
    }

    render() {
        const renderColorEditor = (field: string, config: { fieldColor: string }) => {
            return (
                <div className="custom-color--editor" key={'color-customization-' + field}>
                    <FormFieldWithLabel
                        configuration={{
                            editor: DocumentFieldSelect,
                            onChange: handleChangeField(field, config),
                            tags: ['NUMBER'],
                            value: field,
                            clearable: false,
                        }}
                        className="custom-color--field"
                    />
                    <FormFieldWithLabel
                        configuration={{
                            label: 'Color',
                            editor: TextInput,
                            type: 'color',
                            onChange: handleChangeColor(field),
                            className: 'custom-color--color-picker',
                            value: config.fieldColor,
                        }}
                    />
                    <Icon name={'TrashIcon'} onClick={handleRemoveCustomColor(field)} />
                </div>
            )
        }

        const handleAddCustomColor = () => {
            this.props.onChange(
                produce(this.props.colorCustomization, (draft) => {
                    if (!draft) {
                        draft = {}
                    }
                    draft[this.state.unusedFields[0]] = {
                        fieldColor: '#4fc3f7',
                    }
                    return draft
                })
            )
        }

        const handleChangeColor = (field: string) => (value: string) =>
            this.props.onChange(
                produce(this.props.colorCustomization, (draft) => {
                    draft[field].fieldColor = value
                })
            )

        const handleChangeField = (field: string, config: { fieldColor: string }) => (value: string) =>
            this.props.onChange(
                produce(this.props.colorCustomization, (draft) => {
                    delete draft[field]
                    draft[value] = config
                })
            )

        const handleRemoveCustomColor = (field: string) => () =>
            this.props.onChange(
                produce(this.props.colorCustomization, (draft) => {
                    delete draft[field]
                })
            )

        return (
            <>
                {this.props.colorCustomization &&
                    Object.entries(this.props.colorCustomization).map(([field, config]) =>
                        renderColorEditor(field, config)
                    )}
                <Button onClick={handleAddCustomColor}>Add Custom Color</Button>
            </>
        )
    }
}

export default enhanceWithClickOutside(ColorCustomization)
