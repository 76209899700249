import { put, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions } from '../../../Redux'
import axios from '../../Utility/axios'

export const startEditingImporterConfiguration = createAction(
    'Data/Importer/startEditingImporterConfiguration',
    (importerUuid: string) => ({ payload: { importerUuid } })
)

export const startEditingExtension = createAction(
    'Data/Importer/startEditingExtension',
    (importerUuid: string, extensionIndex: number) => ({ payload: { importerUuid, extensionIndex } })
)

export default function* LoadConfigurationSaga() {
    yield takeLatest([startEditingImporterConfiguration, startEditingExtension], function* (action) {
        try {
            const importerUuid = action.payload.importerUuid
            const response: AxiosResponse = yield axios({
                method: 'GET',
                url: 'admin/importer/' + importerUuid,
            })

            yield put(actions.Data.Importer.loadedConfiguration(response.data))

            // @ts-ignore
            if (action.payload.extensionIndex !== undefined) {
                if (response.data.postProcessors.length === 0) {
                    // @ts-ignore
                    yield put(actions.Data.Importer.addExtension(importerUuid, action.payload.extensionIndex))
                }
            }
        } catch (e) {
            console.error(e)
        }
    })
}
