import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { actions, selectors } from '../../../Redux/index'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import Select from '../../../Components/Atoms/Select/Select'

const mapStateToProps = (state: RootState) => {
    return {
        availableProjects: selectors.Data.JiraConfiguration.availableProjects(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    loadAvailableProjects: () => dispatch(actions.Data.JiraConfiguration.loadAvailableProjects()),
})
type ProjectSelectReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type ProjectSelectProps = ProjectSelectReduxProps & {
    value?: string[]
    onChange: (result: string[] | undefined) => void
}

class ProjectSelect extends PureComponent<ProjectSelectProps> {
    constructor(props: ProjectSelectProps) {
        super(props)
        this.props.loadAvailableProjects()
    }

    render() {
        return (
            <Select
                className="jira-configuration-projects-select"
                isMulti={true}
                value={this.props.value}
                options={
                    this.props.availableProjects?.map((project) => {
                        return { label: project.name, value: project.key || project.name }
                    }) || []
                }
                onChange={this.props.onChange}
                placeholder={'All projects'}
                closeMenuOnSelect={false}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelect)
