import QuotaTileWidget from './QuotaTileWidget'
// @ts-ignore
import QuotaTileWidgetIcon from './QuotaTileWidget.Icon.tsx'
import QuotaTileWidgetStateInitializer from './QuotaTileWidget.init'
import QuotaTileWidgetConfigurationInitializer, {
    QuotaTileConfigurationInitializerForWidgetExplorer,
} from './QuotaTileWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import JiraQuotaEditor from '../../../Molecules/QuotaEditor/JiraQuotaEditor/JiraQuotaEditor'
import ManualQuotaEditor from '../../../Molecules/QuotaEditor/ManualQuotaEditor/ManualQuotaEditor'
import ColorScaleEditor from '../../../Molecules/ColorScaleEditor/ColorScaleEditor'

export type QuotaTileType = 'quotaTile' | 'jiraQuotaTile'

export default (type: QuotaTileType) => ({
    name: type === 'quotaTile' ? 'Quota Tile' : 'Sprint Quota Tile',
    isJiraWidget: type !== 'quotaTile',
    component: QuotaTileWidget,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 0,
        },
        max: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 0,
        },
    },
    icon: QuotaTileWidgetIcon,
    stateInitializationFunction: QuotaTileWidgetStateInitializer,
    configurationInitializer: QuotaTileWidgetConfigurationInitializer(type),
    configurationInitializerForWidgetExplorer: QuotaTileConfigurationInitializerForWidgetExplorer(type),

    editors: {
        data: [
            {
                widgetConfigurationProperty: 'quota',
                component: FormFieldWithLabel,
                configuration: {
                    label: type === 'quotaTile' ? 'Resources' : 'Sprint',
                    icon: 'SyncIcon',
                    editor: type === 'quotaTile' ? ManualQuotaEditor : JiraQuotaEditor,
                },
            },
            buildingBlocks.valueField({
                isModeDisabled: true,
                isGroupingFieldDisabled: true,
                areDateRangeFieldsAllowed: true,
            }),
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [buildingBlocks.colorPicker(), buildingBlocks.numberOfDecimals()],
            },
            {
                name: 'Color Scale',
                editors: [
                    {
                        widgetConfigurationProperty: 'colorScale',
                        component: ColorScaleEditor,
                    },
                ],
            },
        ],
    },
    minWidth: 3,
    minHeight: 2,
})
