import { FieldType } from '../../Atoms/FieldFilterInput/FieldFilterManifest'
import { SYMBOL_TYPE } from '../../Molecules/CalculatedFieldEditor/CalculatedFieldTokenEditor'

export type AnalysisTerm = Array<AnalysisToken>

export type AnalysisToken = {
    type: SYMBOL_TYPE
    value: string
}

export type Filters = Array<FieldType>

export type Mode = 'sum' | 'avg' | 'min' | 'max'

// see ValueField.groovy
export type ValueField = {
    analysisTerm: AnalysisTerm
    customFieldLabel?: string
    filter: Filters
    dateField?: string
    ignoreTimeSelection?: boolean
    groupingField?: string
    unit?: string
}

export const noDataWidgetTypes = ['timeSelector', 'searchBar', 'image', 'quickFilter', 'spacer']

/*
 * We should use this helper method whenever we want to get the mode from an analysis term. So if you
 * see places in code where this isn't the case, please change.
 */
const getModeOfAnalysisTerm = (analysisTerm: AnalysisTerm) => {
    const modeToken = analysisTerm.find((token) => token.type === SYMBOL_TYPE.MODE)
    return modeToken ? (modeToken.value as Mode) : undefined
}

const getSecondValueFieldOfTerm = (analysisTerm: AnalysisTerm) => {
    const valueFieldTokens = analysisTerm.filter((token) => token.type === SYMBOL_TYPE.VALUE_FIELD)
    return valueFieldTokens.length > 1 ? valueFieldTokens[1] : undefined
}

export { getModeOfAnalysisTerm, getSecondValueFieldOfTerm }
