import React from 'react'
import ApplicationHeader from './ApplicationHeader/ApplicationHeader'
import { Page, PageSection } from '../Components/Organisms/Page/Page'

const UserManagement: React.FC = (props) => (
    <Page header={<ApplicationHeader pageTitle="User Management" />}>
        <PageSection>{props.children}</PageSection>
    </Page>
)

export default UserManagement
