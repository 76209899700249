import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import WidgetHeader from '../../../Molecules/WidgetHeader/WidgetHeader'
import Table, { TableColumn } from '../../../Atoms/Table/Table'
import Paginator from '../../../Molecules/Paginator/Paginator'
import { getCtrlKeyPressed } from '../../../../Utility/KeyPress'
import BlockingButton from '../../../Atoms/BlockingButton'
import { ResultTableWidgetProps } from './ResultTableWidget.types'
import { FieldsConfigurationContext } from '../../../Atoms/FieldsConfiguration/FieldsConfigurationContext'
import { FieldConfiguration, NUMBER } from '../../../Atoms/FieldsConfiguration/FieldsConfigurationTypes'
import { exportDashboard } from '../../../../Sagas/DashboardSagas/ExportSagas'

const mapDispatchToProps = (dispatch: any) => ({
    onExportWidget: (dashboardUuid: string, callback: () => void) =>
        dispatch(exportDashboard(undefined, dashboardUuid, true, callback)),
})

export class ResultTableWidget extends PureComponent<ResultTableWidgetProps> {
    context!: React.ContextType<typeof FieldsConfigurationContext>

    static contextType = FieldsConfigurationContext

    static displayName = 'ResultTable'

    render = () => {
        const title = this.props.widgetConfiguration.title
        const className = classnames('widget', {
            'widget--has-header': title,
        })

        return (
            <div className={className}>
                <WidgetHeader title={title} onChangeTitle={this.props.onChangeTitle} />
                {this.renderWidget()}
            </div>
        )
    }

    renderWidget = () => {
        const widgetData = this.props.widgetData
        const columns = widgetData.columns
        if (columns.length === 0) {
            return (
                <div className="result-table-widget">
                    This table has no columns. Please edit the widget to add some.
                </div>
            )
        }

        return (
            <div className="result-table-widget">
                {this.renderResult()}
                <Paginator
                    className="result-table-widget__paginator"
                    currentOffset={this.props.widgetState.rowOffset}
                    itemsPerPage={this.props.widgetConfiguration.rowLimit}
                    itemsTotal={widgetData.totalNumberOfRows}
                    onOffsetChanged={this.handleOffsetChanged}
                />
            </div>
        )
    }

    renderResult() {
        const widgetData = this.props.widgetData
        const columns = widgetData.columns
        const rows = widgetData.rows
        if (widgetData.isOutOfBound) {
            return (
                <div className="result-table-widget__result result-table-widget__result--with-hints">
                    <div className="result-table-widget__result__hint">
                        This table is too big to perform adequately. This page is out of bound, sorry.
                    </div>
                    <div className="result-table-widget__result__hint">
                        Sorting the table by other columns might help you.
                    </div>
                    <div className="result-table-widget__result__hint">
                        Alternatively you can export the data and download it. <br />
                        <BlockingButton onClick={this.handleTableExport} label="EXPORT Dashboard" />
                    </div>
                </div>
            )
        }

        return (
            <div className="result-table-widget__result">
                <Table
                    columns={columns.map((column: TableColumn) => ({
                        ...column,
                        style: this.getColumnStyle(column.field),
                        direction: this.getDirectionByColumnKey(column.field),
                    }))}
                    rows={rows}
                    onTableHeaderClicked={this.handleOnTableHeaderClicked}
                    hasStickyHeader={true}
                />
            </div>
        )
    }

    getColumnStyle = (fieldId: string) => {
        const field: FieldConfiguration = this.context[fieldId]
        return field && field.tag === NUMBER ? 'numeric' : null
    }

    handleOffsetChanged = (rowOffset: number) => {
        this.props.setWidgetState({ rowOffset })
    }

    handleOnTableHeaderClicked = (column: TableColumn) => {
        const key = column.field
        const ordering = column.direction
        const ctrlKeyClicked = getCtrlKeyPressed()
        if (ctrlKeyClicked) {
            const otherOrders = this.props.widgetState.orderBy.filter((item) => item.field !== key)
            this.props.setWidgetState({
                rowOffset: 0,
                orderBy: otherOrders.concat({
                    field: key,
                    direction: ordering === 'desc' ? 'asc' : 'desc',
                }),
            })
        } else {
            this.props.setWidgetState({
                rowOffset: 0,
                orderBy: [
                    {
                        field: key,
                        direction: ordering === 'desc' ? 'asc' : 'desc',
                    },
                ],
            })
        }
    }

    handleTableExport = (callback: () => void) => this.props.onExportWidget(this.props.currentDashboardUuid, callback)

    getDirectionByColumnKey(key: string) {
        const columnOrder = this.props.widgetState.orderBy.find((item) => item.field === key)
        return columnOrder ? columnOrder.direction : null
    }
}

export default connect(null, mapDispatchToProps)(ResultTableWidget)
