import React, { FC, MouseEvent } from 'react'
import classnames from 'classnames'
import produce from 'immer'
import TextInput from '../../Atoms/TextInput/TextInput'
import Icon, { IconNames } from '../../Atoms/Icon/Icon'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

export type TrendLineEntry = {
    showTrendLine: boolean
    trendLineColor: string
}

type Props = {
    value?: TrendLineEntry
    disabled?: boolean
    onChange: (value: TrendLineEntry) => void
    className?: string
    label?: string
    isLeftToRight?: boolean
    configuration?: {
        icon?: IconNames
    }
}

const TrendLineEditor: FC<Props> = (props) => {
    const handleCheckedChange = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        if (!props.disabled) {
            props.onChange(
                produce(props.value, (draft) => {
                    if (draft) {
                        draft.showTrendLine = !draft.showTrendLine
                    } else {
                        draft = {
                            showTrendLine: true,
                            trendLineColor: '#ff0000',
                        }
                    }
                    return draft
                })
            )
        }
    }

    const switchClasses = classnames('switch', {
        'switch--on': props.value?.showTrendLine,
        'switch--disabled': props.disabled,
        'switch--small': !props.isLeftToRight,
    })

    const containerClasses = classnames(props.className, 'trend-line-editor', {
        'trend-line-editor--left-to-right': props.isLeftToRight,
        'trend-line-editor--right-to-left': !props.isLeftToRight,
    })

    const icon = props.configuration ? props.configuration.icon : null

    return (
        <>
            {icon ? (
                <Icon name={icon} size="small" useOwnColors={true} useGrayscale={!props.value?.showTrendLine} />
            ) : null}
            <HoverTooltip
                isVisible={true}
                header={'Calculated using a rolling average. Where no data is present, 0 is assumed as a value.'}
                className={containerClasses}
            >
                <div className={switchClasses} onClick={handleCheckedChange} role="button">
                    <input className="switch__input" type="checkbox" defaultChecked={props.value?.showTrendLine} />
                    <div className={classnames('switch__slider')} />
                </div>
                <label className="trend-line-editor__label">{props.label || 'Trend Line'}</label>
                <TextInput
                    value={
                        props.disabled || !props.value?.showTrendLine
                            ? '#acacac'
                            : props.value?.trendLineColor || '#ff0000'
                    }
                    type="color"
                    onChange={(color: string) => {
                        if (!props.disabled) {
                            props.onChange({ trendLineColor: color, showTrendLine: true })
                        }
                    }}
                    disabled={props.disabled}
                    className="trend-line-editor__input--color"
                />
            </HoverTooltip>
        </>
    )
}

export default TrendLineEditor
