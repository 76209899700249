import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'

const LogMessageContainer: React.FunctionComponent<{
    logs: string
    title?: string
    className?: string
}> = (props) => {
    const ref = useRef<HTMLPreElement>(null)
    // We want to scroll to the end of the logs only once when the site is rendered. An empty array as dependency
    // didn't scroll down the log container at all and since the title is rarely
    // changing, it suits as dependency here quite well.
    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollTop = 100000000
        }
    }, [props.title])
    const classNames = classnames('log-message-container', props.className)

    return (
        <div className={classNames}>
            {props.title ? <header className="log-message-container__title">{props.title}</header> : null}
            <pre className="log-message-container__logs" ref={ref}>
                {props.logs}
            </pre>
        </div>
    )
}

export default React.memo(LogMessageContainer)
