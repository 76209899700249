import { connect } from 'react-redux'
import { actions, selectors } from '../../../Redux'

import DemoModeNotificationModal from '../../../Components/Organisms/Modal/DemoModeNotificationModal/DemoModeNotificationModal'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'

const mapStateToProps = (state: RootState) => ({
    isHidden: selectors.UI.DemoModeNotification.isHidden(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onIsHiddenChanged: (isHidden: boolean) => dispatch(actions.UI.DemoModeNotification.setIsHidden(isHidden)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DemoModeNotificationModal)
