import { call, put, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions } from '../../../Redux'
import axios from '../../Utility/axios'

export const detectFieldMapping = createAction(
    'Data/Importer/ConfigPreview/DetectFieldMapping',
    (
        uuid: string | undefined,
        type: string | undefined,
        configuration: any,
        sourceLanguage: string,
        callback: (value: any) => void,
        isExtension: boolean
    ) => ({
        payload: { uuid, type, configuration, sourceLanguage, callback, isExtension },
    })
)

export default function* DetectFieldMappingSaga() {
    yield takeLatest(detectFieldMapping, function* (action) {
        const { uuid, type, configuration, sourceLanguage, callback, isExtension } = action.payload
        try {
            // result of type MappingSuggestion.groovy
            const result: AxiosResponse = yield axios({
                method: 'post',
                url: isExtension ? '/admin/importer/extension/detect-mapping' : '/admin/importer/detect-mapping',
                data: {
                    uuid,
                    type,
                    configuration,
                    sourceLanguage,
                },
            })

            if (result.status < 400) {
                yield call(callback, result.data.mapping)
            } else {
                yield put(actions.UI.Notification.show({ id: 'importerDetectMappingFailed' }))
                yield call(callback, { mappings: [] })
            }
        } catch (e) {
            console.error(e)
            yield put(actions.UI.Notification.show({ id: 'importerDetectMappingFailed' }))
            yield call(callback, { mappings: [] })
        }
    })
}
