import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { actions } from '../Redux/index'

import endpoint from '../Utility/endpoint'
import ApplicationHeader from './ApplicationHeader/ApplicationHeader'
import { Page, PageSection } from '../Components/Organisms/Page/Page'
import Headline from '../Components/Atoms/Headline/Headline'
import Switch from '../Components/Atoms/Switch/Switch'
import Button from '../Components/Atoms/Button/Button'
import FileUpload from '../Components/Molecules/FileUpload/FileUpload'
import { AppDispatch } from '../store'
import ButtonWithTooltip from '../Components/Molecules/HoverTooltip/ButtonWithTooltip'

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onUploadConfiguration: (file: any) => {
            // @ts-ignore amount of args
            dispatch(actions.Data.ConfigurationSharing.uploadConfigurationStart(file))
        },
    }
}

type Props = ReturnType<typeof mapDispatchToProps>

type ExportValues = {
    schema: boolean
    dashboards: boolean
    importers: boolean
    usersAndGroups: boolean
    features: boolean
}

type ConfigurationSharingScreenState = {
    export: ExportValues
    import: null | any
}

class ConfigurationSharingScreen extends PureComponent<Props, ConfigurationSharingScreenState> {
    state = {
        export: {
            schema: true,
            dashboards: true,
            importers: true,
            usersAndGroups: true,
            features: true,
        },
        import: null,
    }

    renderSwitchList = () => {
        return Object.entries(this.state.export).map(([key, value]) => {
            return (
                <div key={key}>
                    <Switch onChange={this.handleValueToggle(key)} value={value} />
                </div>
            )
        })
    }

    handleValueToggle = (propertyName: string) => {
        return () => {
            // @ts-ignore
            this.setState({ export: { [propertyName]: !this.state.export[propertyName] } })
        }
    }

    handleExportClick = () => {
        window.location.href = endpoint + '/admin/configuration/export'
    }

    handleImportClick = () => {
        this.props.onUploadConfiguration(this.state.import)
    }

    // this function just saves the data from the file upload
    // the actual upload will be triggered by the import button
    handleStartUpload = (file: any) => this.setState({ import: file })

    render() {
        const header = <ApplicationHeader pageTitle="Configuration Sharing" />

        return (
            <Page header={header}>
                <PageSection>
                    <Headline configuration={{ title: 'Export' }} />
                    Select which parts should be exported
                    {/* {this.renderSwitchList()} */}
                    <Button onClick={this.handleExportClick} type="primary">
                        Export
                    </Button>
                </PageSection>

                <PageSection>
                    <Headline configuration={{ title: 'Import' }} />

                    <FileUpload onChange={() => {}} onStartUpload={this.handleStartUpload} />

                    <ButtonWithTooltip
                        isDisabled={this.state.import === null}
                        header="Nothing to import"
                        onClick={this.handleImportClick}
                        type="primary"
                    >
                        Import
                    </ButtonWithTooltip>
                </PageSection>
            </Page>
        )
    }
}

export default connect(null, mapDispatchToProps)(ConfigurationSharingScreen)
