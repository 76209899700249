import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uuid from 'uuid'
import {
    CoordinatesAndSize,
    DataState,
    WidgetGroupConfiguration,
    WidgetGroupFilter,
    WidgetGroupId,
    WidgetId,
} from '../types'
import { widgetGroupIdsSortedByPosition } from './ViewMode'
import { currentlyEditedDashboardId, widgetGroupById, widgetsForWidgetGroup } from './BaseSelectors'

export const defaultWidgetGroupConfiguration: WidgetGroupConfiguration = {
    id: 'widgetGroupId',
    position: 10,
    name: null,
    dashboardId: '',
    filter: {
        fields: [],
        dateFields: [],
        documentType: null,
    },
    widgets: [],
}

const widgetGroupEditModeSlice = createSlice({
    name: 'Data/Dashboards/WidgetGroupEditMode',
    initialState: {} as DataState,
    reducers: {
        moveWidgetGroup: (state, action: PayloadAction<{ widgetGroupId: WidgetGroupId; direction: 'up' | 'down' }>) => {
            const { widgetGroupId, direction } = action.payload
            const dashboardId = currentlyEditedDashboardId({ Data: state as DataState })
            if (dashboardId === undefined) {
                throw new Error('tried to move widget groups while not editing dashboard')
            }

            const widgetGroupIds = widgetGroupIdsSortedByPosition({ Data: state as DataState }, dashboardId)
            const indexOfCurrentWidgetGroup = widgetGroupIds.indexOf(widgetGroupId)

            if (
                (indexOfCurrentWidgetGroup === 0 && direction === 'up') ||
                (indexOfCurrentWidgetGroup === widgetGroupId.length - 1 && direction === 'down')
            ) {
                // if the group is already the first OR the last AND we want to move up/down respectively
                // we do not have to do anything
                return
            }

            const indexOfGroupToSwitchPositionWith =
                direction === 'up' ? indexOfCurrentWidgetGroup - 1 : indexOfCurrentWidgetGroup + 1
            const idOfGroupToSwitchPositionWith = widgetGroupIds[indexOfGroupToSwitchPositionWith]

            const newPosition = widgetGroupById({ Data: state as DataState }, idOfGroupToSwitchPositionWith)!.position
            const oldPosition = widgetGroupById({ Data: state as DataState }, widgetGroupId)!.position

            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId].position = newPosition
            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[idOfGroupToSwitchPositionWith].position =
                oldPosition
        },

        toggleAsGlobalWidgetGroup: (state, action: PayloadAction<WidgetGroupId | null>) => {
            const dashboardId = currentlyEditedDashboardId({ Data: state as DataState })
            if (dashboardId === undefined) {
                throw Error('Dashboard must be edited to set the global widget group!')
            }

            const widgetGroupId = action.payload
            state.dashboards.normalizedDashboardConfig.dashboards.byId[dashboardId].globalWidgetGroup = widgetGroupId

            if (state.dashboards.request) {
                const widgetGroupsInDashboard =
                    state.dashboards.normalizedDashboardConfig.dashboards.byId[state.dashboards.request.dashboard]
                        .widgetGroups

                if (widgetGroupId) {
                    const prevPosition =
                        state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId].position
                    // move widget group to the top
                    state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId].position = 0
                    // move all widgetGroups below the original position 1 up to close the gap
                    widgetGroupsInDashboard.forEach((id) => {
                        if (state.dashboards.normalizedDashboardConfig.widgetGroups.byId[id].position > prevPosition) {
                            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[id].position -= 10
                        }
                    })
                } else {
                    // move all widget groups down to free "position 0"
                    widgetGroupsInDashboard.forEach((id) => {
                        state.dashboards.normalizedDashboardConfig.widgetGroups.byId[id].position += 10
                    })
                }
            }
        },
        changeName: (state, action: PayloadAction<{ widgetGroupId: WidgetGroupId; newName: string }>) => {
            const { widgetGroupId, newName } = action.payload
            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId].name = newName
        },

        // usually we change the position and size of multiple widgets at once
        changeCoordinatesAndSizeOfWidgets: (state, action: PayloadAction<Record<WidgetId, CoordinatesAndSize>>) => {
            Object.keys(action.payload).forEach((widgetId) => {
                state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId].coordinatesAndSize =
                    action.payload[widgetId]
            })
        },

        updateFilters: (state, action: PayloadAction<{ widgetGroupId: WidgetGroupId; filter: WidgetGroupFilter }>) => {
            const { widgetGroupId, filter } = action.payload

            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId] = {
                ...state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId],
                filter,
            }
        },

        duplicateWidgetGroup: (state, action: PayloadAction<WidgetGroupId>) => {
            const baseWidgetGroupId = action.payload
            const baseWidgetGroupConfiguration = widgetGroupById({ Data: state as DataState }, baseWidgetGroupId)
            const baseWidgetConfigurations = widgetsForWidgetGroup({ Data: state as DataState }, baseWidgetGroupId)

            if (baseWidgetGroupConfiguration === undefined) {
                throw Error('Can not duplicate not existing widget group!')
            }

            const newWidgetGroupId = uuid()

            // clone widgets
            const newWidgetIds = baseWidgetConfigurations.map((widgetConfig) => {
                const newWidgetId = uuid()
                state.dashboards.normalizedDashboardConfig.widgets.allIds.push(newWidgetId)
                state.dashboards.normalizedDashboardConfig.widgets.byId[newWidgetId] = {
                    ...widgetConfig,
                    id: newWidgetId,
                    widgetGroup: newWidgetGroupId,
                }

                // clone widget request & response
                if (state.dashboards.request) {
                    state.dashboards.request.widgetRequests[newWidgetId] =
                        state.dashboards.request.widgetRequests[widgetConfig.id]
                }

                if (state.dashboards.response) {
                    state.dashboards.response[newWidgetId] = state.dashboards.response[widgetConfig.id]
                }

                return newWidgetId
            })

            // increase the position of all widgetGroups below the original
            const otherWidgetGroupsIds =
                state.dashboards.normalizedDashboardConfig.dashboards.byId[baseWidgetGroupConfiguration.dashboardId]
                    .widgetGroups
            const widgetGroupConfigsBelow = otherWidgetGroupsIds
                .map((groupId) => state.dashboards.normalizedDashboardConfig.widgetGroups.byId[groupId])
                .filter((widgetGroupConfig) => widgetGroupConfig.position > baseWidgetGroupConfiguration.position)

            widgetGroupConfigsBelow.forEach((widgetGroup) => {
                state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroup.id].position =
                    widgetGroup.position + 10
            })

            // add cloned widget group to the widget groups
            state.dashboards.normalizedDashboardConfig.widgetGroups.allIds.push(newWidgetGroupId)
            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[newWidgetGroupId] = {
                ...baseWidgetGroupConfiguration,
                id: newWidgetGroupId,
                name: baseWidgetGroupConfiguration?.name ? baseWidgetGroupConfiguration?.name + ' (copy)' : '',
                widgets: newWidgetIds,
                position: baseWidgetGroupConfiguration.position + 10,
            }

            // add widgetGroupId to dashboard
            state.dashboards.normalizedDashboardConfig.dashboards.byId[
                baseWidgetGroupConfiguration.dashboardId
            ].widgetGroups.push(newWidgetGroupId)
        },

        addWidgetGroup(state, action: PayloadAction<{ idOfNewGroup?: WidgetGroupId }>) {
            const dashboardId = state.dashboards.editedDashboard.dashboardId
            const groupId = action.payload.idOfNewGroup ?? uuid()
            if (dashboardId === undefined) {
                return
            }

            const sortedGroupIds = widgetGroupIdsSortedByPosition({ Data: state as DataState }, dashboardId)
            const currentLastGroupId = sortedGroupIds[sortedGroupIds.length - 1]
            const currentLastGroup = widgetGroupById({ Data: state as DataState }, currentLastGroupId)
            const nextPosition = currentLastGroup ? currentLastGroup.position + 10 : 10

            state.dashboards.normalizedDashboardConfig.widgetGroups.allIds.push(groupId)
            state.dashboards.normalizedDashboardConfig.widgetGroups.byId[groupId] = {
                id: groupId,
                dashboardId,
                name: '',
                widgets: [],
                position: nextPosition,
                filter: {
                    fields: [],
                    dateFields: [],
                },
            }

            state.dashboards.normalizedDashboardConfig.dashboards.byId[dashboardId].widgetGroups.push(groupId)
        },

        removeWidgetGroup(state, action: PayloadAction<WidgetGroupId>) {
            const groupId = action.payload
            const groupConfig = widgetGroupById({ Data: state as DataState }, groupId)

            if (groupConfig === undefined) {
                throw Error(`tried to remove a non-existing widget group with id "${groupId}"`)
            }

            // remove all widgets belonging to that group
            groupConfig.widgets.forEach((widgetId) => {
                state.dashboards.normalizedDashboardConfig.widgets.allIds =
                    state.dashboards.normalizedDashboardConfig.widgets.allIds.filter((id) => id !== widgetId)
                delete state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId]
            })

            // remove widget group from the dashboard it belonged to
            state.dashboards.normalizedDashboardConfig.dashboards.byId[groupConfig.dashboardId].widgetGroups =
                state.dashboards.normalizedDashboardConfig.dashboards.byId[groupConfig.dashboardId].widgetGroups.filter(
                    (id) => id !== groupId
                )

            // unset as global WidgetGroup
            if (
                state.dashboards.normalizedDashboardConfig.dashboards.byId[groupConfig.dashboardId]
                    .globalWidgetGroup === groupConfig.id
            ) {
                state.dashboards.normalizedDashboardConfig.dashboards.byId[groupConfig.dashboardId].globalWidgetGroup =
                    null
            }

            // remove widget group from list of widget groups
            state.dashboards.normalizedDashboardConfig.widgetGroups.allIds =
                state.dashboards.normalizedDashboardConfig.widgetGroups.allIds.filter((id) => id !== groupId)
            delete state.dashboards.normalizedDashboardConfig.widgetGroups.byId[groupId]
        },
    },
})

export const actions = widgetGroupEditModeSlice.actions
export const reducer = widgetGroupEditModeSlice.reducer
