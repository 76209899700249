import ResultTableWidget from './ResultTableWidget'
import ResultTableWidgetStateInitializer from './ResultTableWidget.init'
import ResultTableWidgetConfigurationInitializer, {
    ResultTableConfigurationInitializerForWidgetExplorer,
} from './ResultTableWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import FieldListEditor from '../../../Molecules/FieldListEditor/FieldListEditor'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import OrderBySelector from '../../../Molecules/OrderBySelector/OrderBySelector'
// @ts-ignore file ext
import ResultTableWidgetIcon from './ResultTableWidget.Icon.tsx'
import FilterEditor from '../../../Molecules/FilterEditor/FilterEditor'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'
import { ResultTableWidgetData } from './ResultTableWidget.types'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'

const config: WidgetManifestConfiguration = {
    name: 'Raw Data Table',
    component: ResultTableWidget,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 0,
        },
        max: {
            dateFields: 1,
            valueFields: 100,
            categoryFields: 100,
        },
    },
    renderRequirements: (widgetData?: ResultTableWidgetData) => {
        if (widgetData?.totalNumberOfRows === 0) {
            return widgetProblems.noData
        }
    },
    icon: ResultTableWidgetIcon,
    stateInitializationFunction: ResultTableWidgetStateInitializer,
    configurationInitializer: ResultTableWidgetConfigurationInitializer,
    configurationInitializerForWidgetExplorer: ResultTableConfigurationInitializerForWidgetExplorer,

    editors: {
        data: [
            {
                widgetConfigurationProperty: 'filter',
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Filter',
                    editor: FilterEditor,
                },
            },
            buildingBlocks.primaryDateField(),
            buildingBlocks.headline('Columns'),
            {
                widgetConfigurationProperty: 'columns',
                component: FieldListEditor,
                configuration: {
                    labelFn: ({ ordinal }: { ordinal: string }) => `${ordinal} column`,
                },
            },
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.headline('Sorting'),
                    {
                        widgetConfigurationProperty: 'orderBy',
                        editorContextBuilder: (configuration) => ({
                            columns: configuration.columns,
                            orderBy: configuration.orderBy,
                        }),
                        component: FormFieldWithLabel,
                        configuration: {
                            label: 'Columns to sort by',
                            editor: OrderBySelector,
                            editorIcon: 'SortingIcon',
                        },
                    },
                    buildingBlocks.headline('Rows'),
                    buildingBlocks.number('rowLimit', 'Maximum number of rows per page', '20', 1, 500, 1),
                ],
            },
        ],
    },
}

export default config
