import * as BaseSelectors from './BaseSelectors'
import * as Dashboards from './Dashboards'
import * as DashboardEditMode from './DashboardEditMode'
import * as DuplicateDashboard from './DuplicateDashboard'
import * as ResetDashboardState from './ResetDashboardState'
import * as ShareDashboard from './ShareDashboard'
import * as ViewMode from './ViewMode'
import * as WidgetEditMode from './WidgetEditMode'
import * as WidgetExplorer from './WidgetExplorer'
import * as WidgetGroupEditMode from './WidgetGroupEditMode'

export const actions = {
    Dashboards: Dashboards.actions,
    DashboardEditMode: DashboardEditMode.actions,
    DuplicateDashboard: DuplicateDashboard.actions,
    ResetDashboardState: ResetDashboardState.actions,
    ShareDashboard: ShareDashboard.actions,
    ViewMode: ViewMode.actions,
    WidgetEditMode: WidgetEditMode.actions,
    WidgetExplorer: WidgetExplorer.actions,
    WidgetGroupEditMode: WidgetGroupEditMode.actions,
}

export const reducerArray = [
    Dashboards.reducer,
    DashboardEditMode.reducer,
    DuplicateDashboard.reducer,
    ShareDashboard.reducer,
    ViewMode.reducer,
    WidgetEditMode.reducer,
    WidgetExplorer.reducer,
    WidgetGroupEditMode.reducer,
]

export const selectors = {
    ...BaseSelectors,
    DashboardEditMode: DashboardEditMode.selectors,
    ShareDashboard: ShareDashboard.selectors,
    ViewMode: ViewMode.viewMode,
    WidgetEditMode: WidgetEditMode.selectors,
    WidgetExplorer: WidgetExplorer.selectors,
}
