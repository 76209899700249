import React from 'react'
import classnames from 'classnames'
import logoDefault from '../../../Images/exply_logo.svg'
import logoLight from '../../../Images/exply_logo_light.svg'

const Logo: React.FunctionComponent<{
    variant?: 'light'
    className?: string
}> = (props) => {
    let logo = logoDefault
    if (props.variant === 'light') {
        logo = logoLight
    }

    const className = classnames('logo', props.className)

    return <img className={className} src={logo} alt="exply logo" />
}

export default Logo
