import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest } from 'redux-saga/effects'
import { actions } from '../Redux'
import axios from './Utility/axios'

function* fetchCompletion(
    action: PayloadAction<{ type: string; value: string; exclude: Array<string>; callback: any }>
) {
    try {
        const { type, value, exclude, callback } = action.payload
        // @ts-ignore yield returns any
        const response = yield axios({
            method: 'GET',
            url: 'autocompletion/' + type,
            params: buildParams(value, exclude),
        })
        // TODO: check status code and notify user
        // call handler with array of strings
        callback(response.data)
    } catch (e) {
        console.error(e)
    }
}

function buildParams(queryString: string, exclude: Array<string>) {
    if (exclude) {
        return exclude.reduce(
            (acc, s, i) => {
                // since each item becomes an own query parameter, we have no trouble finding a delimiter character
                acc[`exclude${i}`] = s
                return acc
            },
            { query: queryString } as Record<string, string>
        )
    }

    return { query: queryString }
}

export default function* FetchCompletionSaga() {
    yield takeLatest(actions.UI.Autocompletion.fetch.type, fetchCompletion)
}
