import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { Draft } from 'immer'
import { EXPLORER_DEFAULT_STATE } from './WidgetExplorer'

import { DashboardConfiguration, DashboardId, DashboardsState, DataState, WidgetId } from '../types'
import { validate } from '../../../Utility/Validation'
import { currentlyEditedDashboardConfig, currentlyEditedWidgetConfig, widgetGroupsById } from './BaseSelectors'

export const defaultCurrentlyEditedDashboard: DashboardsState['editedDashboard'] = {
    dashboardId: undefined,
    backup: undefined,
    explorer: EXPLORER_DEFAULT_STATE,
}

export const defaultDashboardConfiguration: DashboardConfiguration = {
    id: 'dashboardId',
    title: 'Dashboard Title',
    icon: null,
    globalWidgetGroup: null,
    widgetGroups: [],
}

const dashboardEditModeSlice = createSlice({
    name: 'Data/Dashboards/DashboardEditMode',
    initialState: {} as DataState,
    reducers: {
        addDashboard: (state, action: PayloadAction<{ dashboardId: DashboardId }>) => {
            const { dashboardId } = action.payload

            state.dashboards.normalizedDashboardConfig.dashboards.allIds.push(dashboardId)
            state.dashboards.normalizedDashboardConfig.dashboards.byId[dashboardId] = {
                id: dashboardId,
                title: '',
                globalWidgetGroup: null,
                widgetGroups: [],
                icon: null,
            }

            state.dashboards.request = {
                dashboard: dashboardId,
                widgetRequests: {},
            }
        },

        deleteDashboardSuccess: (state, action: PayloadAction<DashboardId>) => {
            const dashboardIdToDelete = action.payload
            const widgetGroupsToDelete =
                state.dashboards.normalizedDashboardConfig.dashboards.byId[dashboardIdToDelete].widgetGroups
            const widgetIdsToDelete = widgetGroupsToDelete.reduce(
                (acc, widgetGroupId) => [
                    ...acc,
                    ...state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId].widgets,
                ],
                [] as Array<WidgetId>
            )
            // delete widgets
            state.dashboards.normalizedDashboardConfig.widgets.allIds =
                state.dashboards.normalizedDashboardConfig.widgets.allIds.filter(
                    (id) => !widgetIdsToDelete.includes(id)
                )
            widgetIdsToDelete.forEach((widgetId) => {
                delete state.dashboards.normalizedDashboardConfig.widgets.byId[widgetId]
            })

            // delete widgetGroups
            state.dashboards.normalizedDashboardConfig.widgetGroups.allIds =
                state.dashboards.normalizedDashboardConfig.widgetGroups.allIds.filter(
                    (id) => !widgetGroupsToDelete.includes(id)
                )
            widgetGroupsToDelete.forEach((widgetGroupId) => {
                delete state.dashboards.normalizedDashboardConfig.widgetGroups.byId[widgetGroupId]
            })

            // delete dashboard
            state.dashboards.normalizedDashboardConfig.dashboards.allIds =
                state.dashboards.normalizedDashboardConfig.dashboards.allIds.filter((id) => id !== dashboardIdToDelete)
            delete state.dashboards.normalizedDashboardConfig.dashboards.byId[dashboardIdToDelete]
        },
        enableEditMode: (state, action: PayloadAction<DashboardId>) => {
            if (state.dashboards.editedDashboard.dashboardId !== undefined) {
                // abort, we do not want to override any existing backup!
                return
            }

            const dashboardId = action.payload
            state.dashboards.editedDashboard = {
                dashboardId,
                explorer: EXPLORER_DEFAULT_STATE,
                backup: {
                    normalizedDashboardConfig: JSON.parse(JSON.stringify(state.dashboards.normalizedDashboardConfig)),
                    request: JSON.parse(JSON.stringify(state.dashboards.request)),
                    response: JSON.parse(JSON.stringify(state.dashboards.response)),
                },
            }
        },

        discardEditModeChanges: (state) => {
            state.dashboards.editedDashboard.dashboardId = undefined

            if (state.dashboards.editedDashboard.backup === undefined) {
                throw Error('Backup is missing, unable to restore state!')
            }
            state.dashboards.normalizedDashboardConfig =
                state.dashboards.editedDashboard.backup.normalizedDashboardConfig

            state.dashboards.request = state.dashboards.editedDashboard.backup.request
            state.dashboards.response = state.dashboards.editedDashboard.backup.response

            // if dashboard is new, delete draft
            if (
                state.dashboards.editedDashboard.dashboardId &&
                !state.dashboards.editedDashboard.backup?.normalizedDashboardConfig.dashboards.allIds.includes(
                    state.dashboards.editedDashboard.dashboardId
                )
            ) {
                const newDashboardId = state.dashboards.editedDashboard.dashboardId
                state.dashboards.normalizedDashboardConfig.dashboards.allIds =
                    state.dashboards.normalizedDashboardConfig.dashboards.allIds.filter((id) => id !== newDashboardId)
                delete state.dashboards.normalizedDashboardConfig.dashboards.byId[newDashboardId]
            }
            resetCurrentlyEditedDashboard(state)
        },
        disableEditMode: (state) => resetCurrentlyEditedDashboard(state),

        changeDashboardTitle: (state, action: PayloadAction<{ newTitle: string }>) => {
            const dashboardId = state.dashboards.editedDashboard.dashboardId
            if (dashboardId === undefined) {
                throw Error('can not edit dashboard title while not in edit mode')
            }
            state.dashboards.normalizedDashboardConfig.dashboards.byId[dashboardId].title = action.payload.newTitle
        },
        setIcon: (state, action: PayloadAction<{ dashboardId: DashboardId; filePath: string | null }>) => {
            const { dashboardId, filePath } = action.payload
            state.dashboards.normalizedDashboardConfig.dashboards.byId[dashboardId].icon = filePath
        },
    },
})

export const actions = dashboardEditModeSlice.actions
export const reducer = dashboardEditModeSlice.reducer

const resetCurrentlyEditedDashboard = (state: Draft<DataState>) => {
    state.dashboards.editedDashboard = defaultCurrentlyEditedDashboard
    state.dashboards.editedWidget = {}
}

const isDashboardEditModeActive = (state: { Data: DataState }) =>
    Boolean(state.Data.dashboards.editedDashboard.dashboardId)

const isNewDashboard = (state: { Data: DataState }, dashboardId: DashboardId) => {
    // Why?
    if (state.Data.dashboards.editedDashboard.backup === undefined) {
        return false
    }

    // Why?
    return !state.Data.dashboards.editedDashboard.backup?.normalizedDashboardConfig.dashboards.allIds?.includes(
        dashboardId
    )
}

const isValidDashboard = createSelector(
    [currentlyEditedDashboardConfig, widgetGroupsById],
    (transientDashboard, allWidgetGroups) => {
        let isValid = true
        const messages: Array<string> = []

        if (!transientDashboard) {
            return { isValid, messages }
        }

        transientDashboard.widgetGroups.forEach((widgetGroupId) => {
            const widgetGroup = allWidgetGroups[widgetGroupId]
            const validationResult = validate(widgetGroup.filter.fields, { type: 'operatorNotNull' })
            if (!validationResult) {
                isValid = validationResult
                messages.push('Operand for widget group filter is missing.')
            }
        })

        const dashboardTitle = transientDashboard.title
        if (dashboardTitle.length === 0) {
            isValid = false
            messages.push('No Dashboard title entered.')
        }

        return { isValid, messages }
    }
)

const hasDashboardBeenModified = (state: { Data: DataState }) => {
    const dashboardId = state.Data.dashboards.editedDashboard.dashboardId

    if (!dashboardId) {
        return false
    }

    const dashboardConfig = state.Data.dashboards.normalizedDashboardConfig
    const dashboardBackup = state.Data.dashboards.editedDashboard.backup?.normalizedDashboardConfig

    if (!dashboardBackup) {
        return false
    }

    // check if Dashboard Config has been modified
    const unmodifiedDashboard = dashboardBackup.dashboards.byId[dashboardId]
    const modifiedDashboard = dashboardConfig.dashboards.byId[dashboardId]

    if (JSON.stringify(unmodifiedDashboard) !== JSON.stringify(modifiedDashboard)) {
        return true
    }

    // check if Widget Group configs have been modified
    // use for-loops here to save iteration time
    const widgetGroupIds = dashboardConfig.dashboards.byId[dashboardId].widgetGroups
    const modifiedWidgetGroups = []
    const unmodifiedWidgetGroups = []
    for (let i = 0; i < widgetGroupIds.length; i++) {
        const id = widgetGroupIds[i]
        modifiedWidgetGroups.push(dashboardConfig.widgetGroups.byId[id])
        unmodifiedWidgetGroups.push(dashboardBackup.widgetGroups.byId[id])
    }

    if (JSON.stringify(unmodifiedWidgetGroups) !== JSON.stringify(modifiedWidgetGroups)) {
        return true
    }

    // check if Widget Configs have been modified
    // use for-loops here to save iteration time
    const widgetIds = modifiedWidgetGroups.map((widgetGroup) => widgetGroup.widgets).flat()
    const modifiedWidgets = []
    const unmodifiedWidgets = []
    widgetIds.map((id) => dashboardConfig.widgets.byId[id])
    for (let i = 0; i < widgetIds.length; i++) {
        const id = widgetIds[i]
        modifiedWidgets.push(dashboardConfig.widgets.byId[id])
        unmodifiedWidgets.push(dashboardBackup.widgets.byId[id])
    }

    if (JSON.stringify(unmodifiedWidgets) !== JSON.stringify(modifiedWidgets)) {
        return true
    }

    return false
}

const currentlyEditedWidgetGroups = createSelector(
    [currentlyEditedWidgetConfig],
    (currentlyEditedWidgetConfig) => currentlyEditedWidgetConfig?.widgetGroup ?? []
)

export const selectors = {
    isDashboardEditModeActive,
    hasDashboardBeenModified,
    currentlyEditedWidgetGroups,
    isNewDashboard,
    isValidDashboard,
}
