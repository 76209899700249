import { defaultAnalysisTerm, getDefaultAnalysisTermWithField } from '../DefaultConfigurations'
import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

const createDefaultConfiguration = () => ({
    xAxisPivotField: 'projectName',
    yAxisPivotField: 'status' as string | null,
    valueField: { analysisTerm: defaultAnalysisTerm, filter: [] as Array<FieldType>, dateField: 'primaryDate' },
    yLimit: 5,
    xLimit: 10,
    numberOfDecimals: 2,
    color: '#4fc3f7',
    showSumRow: false,
    showSumColumn: false,
    title: 'Matrix Chart',
})

export default createDefaultConfiguration

export const MatrixChartConfigurationInitializerForWidgetExplorer = ({
    valueFields,
    categoryFields,
    dateFields,
    filters,
}: {
    valueFields: Array<string>
    categoryFields: Array<string>
    dateFields: Array<string>
    filters: Array<FieldType>
}) => {
    const defaultConfiguration = createDefaultConfiguration()

    defaultConfiguration.xAxisPivotField = categoryFields[0]

    defaultConfiguration.yAxisPivotField = categoryFields.length === 2 ? categoryFields[1] : null

    defaultConfiguration.valueField = {
        analysisTerm: getDefaultAnalysisTermWithField(valueFields[0]),
        filter: filters,
        dateField: dateFields[0],
    }

    return defaultConfiguration
}
