import { createAction } from '@reduxjs/toolkit'
import { put, select, takeLatest } from 'redux-saga/effects'
import { WidgetId } from '../../Redux/Data/types'
import { actions, selectors } from '../../Redux'

export const startDuplicateWidget = createAction<{ widgetId: WidgetId }>(
    'Data/Dashboards/WidgetEditMode/StartDuplicateWidget'
)

export default function* DuplicateWidgetSaga() {
    yield takeLatest(startDuplicateWidget, function* (action) {
        const widgetId = action.payload.widgetId
        const widgetConfig = selectors.Data.Dashboards.widgetById(yield select(), widgetId)
        const widgetGroupConfig = selectors.Data.Dashboards.widgetGroupById(yield select(), widgetConfig.widgetGroup)
        const dashboardConfig = selectors.Data.Dashboards.dashboardById(yield select(), widgetGroupConfig.dashboardId)

        // when the dashboard has only one widget group, the widget will be placed in that group
        if (dashboardConfig.widgetGroups.length === 1) {
            yield put(
                actions.Data.Dashboards.WidgetEditMode.duplicateWidgetById({
                    widgetId,
                    widgetGroupId: widgetConfig.widgetGroup,
                })
            )
            return
        }

        yield put(
            actions.UI.Modal.startConfirmation({
                modalId: 'duplicateWidget',
                parameters: {
                    widgetId,
                    dashboardId: dashboardConfig.id,
                },
            })
        )
    })
}
