import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import enhanceWithClickOutside from 'react-click-outside'
import { actions, selectors } from '../../../Redux'

import SecondaryHeader from '../../../Components/Molecules/SecondaryHeader/SecondaryHeader'
import SecondaryFooter from '../../../Components/Molecules/SecondaryFooter/SecondaryFooter'
import containsGivenObject from '../../../Utility/HandleClickOutside'
import WidgetGroupArea from './WidgetGroupsArea'
import DashboardTitle from './DashboardTitle'
import EditDashboardButtons from './EditDashboardButtons'
import DashboardControls from './DashboardControls'
import { DashboardId } from '../../../Redux/Data/types'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { saveCurrentlyEditedDashboardAndExitEditMode } from '../../../Sagas/DashboardSagas/SaveDashboardToServerSaga'

type ExplyDashboardOwnProps = {
    dashboardId: DashboardId
    inEditMode: boolean
}

const mapStateToProps = (state: RootState, ownProps: ExplyDashboardOwnProps) => ({
    isNewDashboard: selectors.Data.Dashboards.DashboardEditMode.isNewDashboard(state, ownProps.dashboardId),
    hasDashboardBeenModified: selectors.Data.Dashboards.DashboardEditMode.hasDashboardBeenModified(state),
    canManageDashboards: selectors.UI.CurrentUser.settings(state)?.canManageDashboards ?? false,
})

type HandleFactoryWithDispatch = (dispatch: AppDispatch) => () => void

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onLeaveWhileInEditMode: (handleFactory: HandleFactoryWithDispatch) =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'leaveUnsaved',
                confirmationCallback: () => {
                    dispatch(saveCurrentlyEditedDashboardAndExitEditMode())
                    handleFactory(dispatch)()
                },
                denyCallback: () => {
                    dispatch(actions.Data.Dashboards.DashboardEditMode.disableEditMode())
                    handleFactory(dispatch)()
                },
            })
        ),
    saveDashboardAndExitEditMode: () => dispatch(saveCurrentlyEditedDashboardAndExitEditMode()),
    disableEditMode: () => dispatch(actions.Data.Dashboards.DashboardEditMode.disableEditMode()),
    dispatchActionAfterUnmodifiedClickOutside: (clickedItem: string, clickTarget: HTMLElement) => {
        if (clickedItem === 'mainMenu' || clickedItem === 'explyLogo') {
            clickTarget.click()
        }
    },
})

type ExplyDashboardProps = ExplyDashboardOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

/**
 * native, non-embedded dashboard
 */
class ExplyDashboard extends PureComponent<ExplyDashboardProps> {
    // used by react-click-outside
    // noinspection JSUnusedGlobalSymbols
    handleClickOutside = (event: MouseEvent) => {
        // catch untrusted events because they are sent by this function
        if (this.props.inEditMode && event.isTrusted) {
            let clickedItem = ''

            if (containsGivenObject(event, ['row-menu__item'])) {
                clickedItem = 'mainMenu'
            }

            if (containsGivenObject(event, ['application-header__logo'])) {
                clickedItem = 'explyLogo'
            }

            const handleFactory = (dispatch: AppDispatch) => {
                return () => {
                    const target = event.target as HTMLElement
                    if (clickedItem === 'mainMenu') {
                        target.click()
                        dispatch(actions.UI.Menu.toggleMenuVisibility())
                    }

                    if (clickedItem === 'explyLogo') {
                        target.click()
                    }
                }
            }

            if (
                containsGivenObject(event, [
                    'dashboard-selector-handleOnClick',
                    'row-menu__item',
                    'application-header__logo',
                ])
            ) {
                event.preventDefault()
                event.stopPropagation()
                if (this.props.hasDashboardBeenModified || this.props.isNewDashboard) {
                    this.props.onLeaveWhileInEditMode(handleFactory)
                } else {
                    const target = event.target as HTMLElement
                    this.props.dispatchActionAfterUnmodifiedClickOutside(clickedItem, target)
                    this.props.disableEditMode()
                }
            }
        }
    }

    render() {
        const { dashboardId, inEditMode } = this.props

        return (
            <div>
                <SecondaryHeader
                    className="dashboard__header"
                    left={<DashboardTitle dashboardId={this.props.dashboardId} />}
                    right={<DashboardControls dashboardId={dashboardId} isEmbedded={false} />}
                />
                <WidgetGroupArea dashboardId={dashboardId} />
                {inEditMode ? <SecondaryFooter right={<EditDashboardButtons dashboardId={dashboardId} />} /> : null}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(enhanceWithClickOutside(ExplyDashboard))
