import React from 'react'
import { connect } from 'react-redux'

import { FieldsConfigurationContext } from '../../Components/Atoms/FieldsConfiguration/FieldsConfigurationContext'
import { FieldsConfiguration } from '../../Components/Atoms/FieldsConfiguration/FieldsConfigurationTypes'
import { selectors } from '../../Redux'
import { RootState } from '../../Redux/types'

const mapStateToProps = (state: RootState) => ({
    fieldsConfiguration: selectors.Data.FieldsConfiguration.fields(state),
})

const FieldsConfigurationProvider: React.FunctionComponent<{
    fieldsConfiguration: FieldsConfiguration
}> = (props) => (
    <FieldsConfigurationContext.Provider value={props.fieldsConfiguration}>
        {props.children}
    </FieldsConfigurationContext.Provider>
)

/**
 * This container make the fields configuration in the Redux store available globally
 * by placing it inside a context.
 */
export default connect(mapStateToProps)(FieldsConfigurationProvider)
