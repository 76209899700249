import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import enhanceWithClickOutside from 'react-click-outside'
import { actions, selectors } from '../../../Redux'

import UserEditor from '../../../Components/Organisms/UserManagement/UserEditor'
import Button from '../../../Components/Atoms/Button/Button'
import SecondaryFooter from '../../../Components/Molecules/SecondaryFooter/SecondaryFooter'
import containsGivenObject from '../../../Utility/HandleClickOutside'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { UserOrGroupConfig } from '../../../Redux/Data/UserManagement'
import { switchToUser } from '../../../Sagas/UserManagementSagas/SwitchToUserSaga'
import { getPasswordToken } from '../../../Sagas/UserManagementSagas/GetPasswordTokenSaga'
import { removeUserOrGroup } from '../../../Sagas/UserManagementSagas/RemoveSaga'
import ButtonWithTooltip from '../../../Components/Molecules/HoverTooltip/ButtonWithTooltip'

const mapStateToProps = (state: RootState) => {
    return {
        isCreation: selectors.Data.UserManagement.isCreation(state),
        userOrGroup: selectors.Data.UserManagement.currentlyEditedUserOrGroup(state),
        hasPassword: selectors.Data.UserManagement.currentlyEditedUserOrGroupHasPassword(state),
        userOrGroupValidation: selectors.Data.UserManagement.currentlyEditedUserOrGroupValidation(state),
        groups: selectors.Data.UserManagement.groups(state),
        dashboards: selectors.Data.Dashboards.allDashboards(state),

        changedConfiguration: selectors.Data.UserManagement.changedConfiguration(state),
        reloadConfirmation: selectors.Data.FeatureFlags.reloadConfirmationFlag(state),
        loggedInUser: selectors.UI.CurrentUser.loggedInUser(state),
        applicationMode: selectors.Data.System.applicationMode(state),
        passwordsAreManagedLocally: selectors.Data.FeatureFlags.passwordsAreManagedLocally(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        startEditingUser: (id: string) => dispatch(actions.Data.UserManagement.startEditing(id)),
        startCreatingUser: () => dispatch(actions.Data.UserManagement.startCreatingUser()),
        startCreatingGroup: () => dispatch(actions.Data.UserManagement.startCreatingGroup()),

        updateProperty: (key: keyof UserOrGroupConfig, value: any) => {
            dispatch(actions.Data.UserManagement.updateProperty({ key, value }))
        },
        cancel: () => {
            dispatch(push('/user-management'))
            dispatch(actions.Data.UserManagement.clearEditing())
        },
        save: (userOrGroup: UserOrGroupConfig) => {
            dispatch(actions.Data.UserManagement.createOrUpdateAndGoToOverview(userOrGroup))
            dispatch(actions.Data.UserManagement.clearEditing())
        },
        openPasswordModal: () => dispatch(actions.UI.Modal.show('userManagementSetPassword')),
        openPasswordLinkModal: (userId: string) => dispatch(getPasswordToken(userId)),
        warnAboutSelfDeactivation: (confirmationCallback: any) =>
            dispatch(
                actions.UI.Modal.startConfirmation({
                    modalId: 'userManagementRemoveOrDisableSelf',
                    confirmationCallback,
                })
            ),
        openRemoveUserOrGroupModal: (isGroup: boolean, confirmationCallback: any) =>
            dispatch(
                actions.UI.Modal.startConfirmation({
                    modalId: isGroup ? 'userManagementRemoveGroup' : 'userManagementRemoveUser',
                    confirmationCallback,
                })
            ),
        removeUserOrGroup: (id: string) => dispatch(removeUserOrGroup(id)),
        triggerAutoCompletion: (type: any, value: any, exclude: any, callback: any) =>
            dispatch(actions.UI.Autocompletion.fetch(type, value, exclude, callback)),
        onUnsavedLeave: (userOrGroup: UserOrGroupConfig, event: any) =>
            dispatch(
                actions.UI.Modal.startConfirmation({
                    modalId: 'leaveUnsaved',
                    confirmationCallback: () => {
                        dispatch(actions.Data.UserManagement.createOrUpdate({ userOrGroup }))
                        event.target.click()
                        dispatch(actions.UI.Menu.toggleMenuVisibility())
                    },
                    denyCallback: () => {
                        event.target.click()
                        dispatch(actions.UI.Menu.toggleMenuVisibility())
                    },
                })
            ),
        switchUser: (userId: string) => {
            dispatch(switchToUser(userId))
        },
    }
}

type UserEditingProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & UserEditingOwnProps

type UserEditingOwnProps = {
    showFooter: boolean
    showPasswordWarning: boolean
    // react router
    match?: any
}

class UserEditing extends PureComponent<UserEditingProps, unknown> {
    static defaultProps = {
        showFooter: true,
        showPasswordWarning: true,
    }

    handleCancel = () => this.props.cancel()

    handleSave = () => {
        if (this.props.userOrGroup) {
            this.props.save(this.props.userOrGroup)
        }
    }

    handleSetPassword = () => this.props.openPasswordModal()

    handleGeneratePasswordToken = () => {
        if (this.props.userOrGroup && this.props.userOrGroup.id) {
            this.props.openPasswordLinkModal(this.props.userOrGroup.id)
        }
    }

    handleClickOutside = (event: React.MouseEvent) => {
        // catch untrusted events because they are sent by this function
        if (
            containsGivenObject(event, ['row-menu__item']) &&
            this.props.changedConfiguration &&
            event.isTrusted &&
            this.props.userOrGroup
        ) {
            event.stopPropagation()
            event.preventDefault()
            this.props.onUnsavedLeave(this.props.userOrGroup, event)
        }
    }

    handleToggleUserActivation = () =>
        this.props.updateProperty('isActive', this.props.userOrGroup && !this.props.userOrGroup.isActive)

    handleSwitchUser = () => {
        if (this.props.userOrGroup) {
            this.props.switchUser(this.props.userOrGroup.id)
        }
    }

    onClose = (event: any) => {
        if (this.props.changedConfiguration && this.props.reloadConfirmation) {
            event.preventDefault()
            event.returnValue = 'This user-configuration has got unsaved changes. Are You sure You want to leave?'
        }

        return event
    }

    handleIdChanged = (value: string) => this.props.updateProperty('id', value.toLowerCase())

    handleUpdate = (key: keyof UserOrGroupConfig, value: any) => this.props.updateProperty(key, value)

    handleRemove = () => {
        const userOrGroup = this.props.userOrGroup
        if (userOrGroup) {
            const id = userOrGroup.id
            this.props.openRemoveUserOrGroupModal(userOrGroup.isGroup, () => {
                const isCurrentUser = this.props.loggedInUser && this.props.loggedInUser.id === id
                if (isCurrentUser) {
                    this.props.warnAboutSelfDeactivation(() => this.props.removeUserOrGroup(id))
                } else {
                    this.props.removeUserOrGroup(id)
                }
            })
        }
    }

    render() {
        if (!this.props.userOrGroup) {
            return null
        }

        return (
            <div>
                <UserEditor
                    isCreation={this.props.isCreation}
                    userOrGroup={this.props.userOrGroup}
                    hasValidId={!this.props.userOrGroupValidation?.errorMessages.id}
                    hasPassword={this.props.hasPassword}
                    passwordsAreManagedLocally={this.props.passwordsAreManagedLocally}
                    showPasswordWarning={this.props.showPasswordWarning}
                    groups={this.props.groups}
                    dashboards={this.props.dashboards}
                    onIdChange={this.handleIdChanged}
                    onUpdate={this.handleUpdate}
                    onSetPassword={this.handleSetPassword}
                    onGeneratePasswordToken={this.handleGeneratePasswordToken}
                    triggerAutoCompletion={this.props.triggerAutoCompletion}
                    onSwitchUser={this.handleSwitchUser}
                    onToggleUserActivation={this.handleToggleUserActivation}
                    userOrGroupValidation={this.props.userOrGroupValidation}
                    applicationMode={this.props.applicationMode}
                    loggedInUser={this.props.loggedInUser}
                />
                {this.props.showFooter ? (
                    <SecondaryFooter
                        left={
                            this.props.isCreation ? (
                                ''
                            ) : (
                                <Button type="warning" onClick={this.handleRemove}>
                                    Remove
                                </Button>
                            )
                        }
                        right={
                            <div>
                                <Button onClick={this.handleCancel}>Cancel</Button>
                                <ButtonWithTooltip
                                    isDisabled={!this.props.userOrGroupValidation?.isValid}
                                    header="Fill in all required values to save"
                                    type="primary"
                                    onClick={this.handleSave}
                                >
                                    Save
                                </ButtonWithTooltip>
                            </div>
                        }
                    />
                ) : (
                    ''
                )}
            </div>
        )
    }

    componentDidMount() {
        if (!this.props.match) {
            this.props.startCreatingUser()
            return
        }

        const userId = this.props.match.params.id

        if (userId) {
            this.props.startEditingUser(userId)
        } else if (this.props.match.path === '/user-management/new-user') {
            this.props.startCreatingUser()
        } else {
            this.props.startCreatingGroup()
        }

        window.addEventListener('beforeunload', this.onClose)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onClose)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(enhanceWithClickOutside(UserEditing))
