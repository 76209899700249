import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { storeExecuteDashboardResponseReducer } from './Utility'
import { DashboardId, DashboardLoadingStates, DashboardRequest, DataState, WidgetId, WidgetState } from '../types'
import { RootState } from '../../types'
import dateRanges, { DateRangeId } from '../../../StaticManifests/manifest.dateRanges'
import { dashboardById, dashboardIds, dashboardsById, widgetGroupsById, widgetsById } from './BaseSelectors'

const viewModeSlice = createSlice({
    name: 'Data/Dashboard/ViewMode',
    initialState: {} as DataState,
    reducers: {
        setInitialWidgetDataRequest: (state, action: PayloadAction<DashboardRequest>) => {
            state.dashboards.request = action.payload
        },

        loadWidgetDataStart: (state) => {
            state.dashboards.loadingState = DashboardLoadingStates.LOADING
        },
        loadWidgetDataError: (state) => {
            state.dashboards.loadingState = DashboardLoadingStates.ERROR
        },
        loadWidgetDataSuccess: storeExecuteDashboardResponseReducer,

        updateWidgetState: (
            state,
            action: PayloadAction<{ widgetId: WidgetId; widgetStateChanges: Record<string, any> }>
        ) => {
            const { widgetId, widgetStateChanges } = action.payload
            if (state.dashboards.editedDashboard.dashboardId !== undefined) {
                throw new Error(
                    'INVARIANT ERROR: UPDATE_WIDGET_STATE is not allowed to be called when we are currently editing a dashboard.'
                )
            }

            const keys = Object.keys(widgetStateChanges) as Array<keyof WidgetState>
            keys.forEach((key) => {
                if (state.dashboards.request && state.dashboards.request.widgetRequests) {
                    state.dashboards.request.widgetRequests[widgetId].widgetState[key] = widgetStateChanges[key]
                }
            })
        },
    },
})

export const actions = viewModeSlice.actions
export const reducer = viewModeSlice.reducer

const dashboardExists = (state: { Data: DataState }, dashboardId: DashboardId) => {
    const dashboardsConfig = dashboardById(state, dashboardId)
    return dashboardsConfig !== undefined
}

// createSelector([dashboardById], (dashboardConfiguration) => dashboardConfiguration !== undefined)

export const widgetGroupIdsForDashboard = (state: { Data: DataState }, dashboardId: DashboardId) =>
    dashboardById(state, dashboardId)?.widgetGroups

// PROPS: state, dashboardId
export const widgetGroupIdsSortedByPosition = (state: { Data: DataState }, dashboardId: DashboardId) => {
    const groupIds = widgetGroupIdsForDashboard(state, dashboardId)
    const allWidgetGroupsConfigs = widgetGroupsById(state)

    return (
        groupIds
            ?.map((id) => allWidgetGroupsConfigs[id])
            .sort((a, b) => a.position - b.position)
            .map((w) => w.id) ?? []
    )
}

// This selector must not be used for rendering dashboards as it does change too often
// It was written for the DuplicateWidgetModal and the de-normalization as we need all this information there
export const widgetGroupConfigurationsForDashboard = createSelector(
    [widgetGroupIdsForDashboard, widgetGroupsById],
    (widgetGroupIdsForDashboard, allWidgetGroups) => {
        return widgetGroupIdsForDashboard?.map((id) => allWidgetGroups[id]).sort((a, b) => a.position - b.position)
    }
)

export const widgetIdsForDashboard = createSelector(
    [widgetGroupIdsForDashboard, widgetGroupsById],
    (widgetGroupIds, byId) => {
        if (widgetGroupIds === undefined || widgetGroupIds.length === 0) {
            return []
        }

        return widgetGroupIds
            .map((groupId) => byId[groupId])
            .reduce((acc, group) => {
                return [...acc, ...group.widgets]
            }, [] as Array<WidgetId>)
    }
)

// PROPS: dashboardId
const dashboardHasWidgets = createSelector(
    [widgetIdsForDashboard],
    (widgetIds: Array<WidgetId>) => Object.keys(widgetIds).length > 0
)

const sortedDashboardConfigurationIds = createSelector([dashboardIds, dashboardsById], (allIds, byId) =>
    allIds
        .map((dashboardId) => byId[dashboardId])
        .sort((a, b) => {
            const aTitle = a.title.toLowerCase()
            const bTitle = b.title.toLowerCase()
            if (aTitle < bTitle) {
                return -1
            }

            if (aTitle > bTitle) {
                return 1
            }

            return 0
        })
        .map((dashboard) => dashboard.id)
)

const dashboardLoadingState = (state: RootState) => state.Data.dashboards.loadingState

const dashboardTitle = createSelector([dashboardById], (dashboardConfiguration) => {
    return dashboardConfiguration?.title ?? ''
})

const dashboardRequest = (state: RootState) => state.Data.dashboards.request
const dashboardResponse = (state: RootState) => state.Data.dashboards.response

// PARAM 1: state
// PARAM 2: widgetId
const widgetStateForWidget = (widgetId: WidgetId) =>
    createSelector([dashboardRequest], (dashboardRequest) => dashboardRequest?.widgetRequests[widgetId]?.widgetState)

// PARAM 1: state
// PARAM 2: widgetId
const widgetDataForWidget = (widgetId: WidgetId) =>
    createSelector([dashboardResponse], (dashboardResponse) => dashboardResponse?.[widgetId])

// Param 1: state
// Param 2: dashboardUUID
const dashboardHasSelection = createSelector(
    dashboardRequest,
    dashboardById,
    widgetGroupsById,
    widgetsById,
    (viewModeWidgetRequest, dashboardConfiguration, allWidgetGroupsById, widgetsById) => {
        if (!viewModeWidgetRequest || !dashboardConfiguration) {
            return false
        }

        const widgetUuidsOfDashboard = dashboardConfiguration.widgetGroups
            .map((id) => allWidgetGroupsById[id])
            .reduce((acc, widgetGroupConfiguration) => {
                return [...acc, ...widgetGroupConfiguration.widgets]
            }, [] as Array<WidgetId>)

        let hasSelection = false

        Object.keys(viewModeWidgetRequest.widgetRequests).forEach((widgetUuid) => {
            if (widgetUuidsOfDashboard.includes(widgetUuid)) {
                const widgetState = viewModeWidgetRequest.widgetRequests[widgetUuid].widgetState
                if (widgetState.selection) {
                    hasSelection = hasSelection || widgetState.selection.length > 0
                }

                if (widgetState.activeFilters) {
                    hasSelection = hasSelection || Object.keys(widgetState.activeFilters).length > 0
                }
            }
        })

        widgetUuidsOfDashboard
            .map((id) => widgetsById[id])
            .forEach((widget) => {
                if (widget.type === 'timeSelector') {
                    const widgetState = viewModeWidgetRequest.widgetRequests[widget.id]?.widgetState
                    if (widgetState) {
                        const defaultTimespan = widget.configuration.defaultTimespan
                        let defaultStart = defaultTimespan.startDate
                        let defaultEnd = defaultTimespan.endDate

                        // some timeSelector widgets don't have the defaultTimeSpanMillis in their config,
                        // so we have to fetch them from the manifest
                        if (!defaultStart && !defaultEnd && defaultTimespan.selectedPredefinedTimespan !== 'allTime') {
                            const dateRange = dateRanges[
                                defaultTimespan.selectedPredefinedTimespan as DateRangeId
                            ].getDateRange({
                                workingDays: widget.configuration.workingDays,
                                showLastXDays: widget.configuration.showLastXDays,
                            })
                            defaultStart = dateRange.startDate?.valueOf()
                            defaultEnd = dateRange.endDate?.valueOf()
                        }

                        if (
                            defaultStart !== widgetState.startMilliseconds ||
                            defaultEnd !== widgetState.endMilliseconds
                        ) {
                            hasSelection = true
                        }
                    }
                }
            })

        return hasSelection
    }
)

export const viewMode = {
    dashboardById,
    dashboardTitle,
    dashboardHasWidgets,
    widgetGroupIdsForDashboard,
    sortedDashboardConfigurationIds,
    widgetGroupIdsSortedByPosition,
    widgetStateForWidget,
    widgetDataForWidget,
    dashboardRequest,
    dashboardLoadingState,
    dashboardHasSelection,
    dashboardExists,
    widgetGroupConfigurationsForDashboard,
    widgetIdsForDashboard,
}
