import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { actionTypes as system } from '../System'
import { containsErrorMessages, getValidationErrorMessage } from '../../Utility/Validation'
import { DashboardId, DataState } from './types'
import { RootState } from '../types'
import { FieldType } from '../../Components/Atoms/FieldFilterInput/FieldFilterManifest'

export type UserOrGroupId = string

export type DocumentPermissions = {
    ignoreDocumentTypes: boolean
    documentTypes: Array<string>
    ignoreFieldPermissions: boolean
    fieldPermissions: Array<FieldType>
}

// see UserPolicySettings.groovy
export type UserSettings = {
    isAdmin: boolean
    isSystemManager: boolean
    isUserManager: boolean
    isDashboardManager: boolean
    isImporterManager: boolean
    canSeeAnyDashboard: boolean
    // these props are calculated by the server on the fly, so we must not send them with requests when updating
    // so they are optional for the creation of a new user
    canManageDashboards?: boolean
    canManageImporters?: boolean
    canManageUsers?: boolean
    canExportRawData?: boolean
    canManageCustomColors: boolean
    visibleDashboards: Array<DashboardId>
}

export type UserOrGroupConfig = {
    id: string
    label: string
    isActive: boolean
    isGroup: boolean
    subGroups: Array<string>
    documentPermissions: DocumentPermissions
    settings: UserSettings
    apiKey?: string | null
    createdOnMillis?: number
    email?: string
    description?: string | null
}

export type UserOrGroupKey = keyof UserOrGroupConfig

export type UserOrGroupDocumentPermissionsPath = {
    property: 'documentPermissions'
    fieldName: keyof DocumentPermissions
}

export type UserOrGroupSettingsPath = {
    property: 'settings'
    fieldName: keyof UserSettings
}

const userManagementSlice = createSlice({
    name: 'Data/UserManagement',
    initialState: {} as DataState,
    extraReducers: {
        [system.INIT]: (state, action) => {
            const { initialState } = action.payload
            state.userManagement = {
                usersAndGroupsById: initialState.data.users,
                hasPasswordByUserId: initialState.data.hasPasswordByUserId,
                isCreation: false,
                currentlyEditedUserOrGroup: null,
                passwordToken: null,
                changedConfiguration: false,
            }
        },
    },
    reducers: {
        startEditing: (state, action: PayloadAction<string>) => {
            const userOrGroupId = action.payload
            state.userManagement.isCreation = false
            state.userManagement.currentlyEditedUserOrGroup = state.userManagement.usersAndGroupsById[userOrGroupId]
        },
        startCreatingUser: (state) => {
            state.userManagement.isCreation = true
            state.userManagement.currentlyEditedUserOrGroup = createNewUserOrGroup(false)
        },
        startCreatingGroup: (state) => {
            state.userManagement.isCreation = true
            state.userManagement.currentlyEditedUserOrGroup = createNewUserOrGroup(true)
        },
        saveUserPassword: {
            prepare: (userId: string, password: string) => ({ payload: { userId, password } }),
            reducer: (state, action: PayloadAction<{ userId: string; password: string }>) => {
                const { userId, password } = action.payload
                state.userManagement.hasPasswordByUserId[userId] = Boolean(password)
            },
        },
        setPasswordToken: (state, action: PayloadAction<string>) => {
            state.userManagement.passwordToken = action.payload
        },
        removePasswordToken: (state) => {
            state.userManagement.passwordToken = null
        },
        updateProperty: (
            state,
            action: PayloadAction<{
                key: keyof UserOrGroupConfig
                value: any
            }>
        ) => {
            const { key, value } = action.payload
            if (state.userManagement.currentlyEditedUserOrGroup) {
                state.userManagement.currentlyEditedUserOrGroup = {
                    ...state.userManagement.currentlyEditedUserOrGroup,
                    [key]: value,
                }
                state.userManagement.changedConfiguration = true
            }
        },
        clearEditing: (state) => {
            state.userManagement.changedConfiguration = false
        },
        createOrUpdate: (state, action: PayloadAction<{ userOrGroup: UserOrGroupConfig }>) => {
            const userOrGroup = action.payload.userOrGroup
            state.userManagement.usersAndGroupsById[userOrGroup.id] = userOrGroup
        },
        removeUserFinished: (state, action: PayloadAction<UserOrGroupId>) => {
            delete state.userManagement.usersAndGroupsById[action.payload]
        },
        setUsersAndGroups: (state, action: PayloadAction<Record<UserOrGroupId, UserOrGroupConfig>>) => {
            state.userManagement.usersAndGroupsById = action.payload
        },
        createOrUpdateAndGoToOverview: {
            prepare: (userOrGroup: UserOrGroupConfig) => ({ payload: { userOrGroup } }),
            reducer: () => {},
        },
        savePasswordSuccess: () => {},
        savePasswordFailed: () => {},
    },
})

export const actions = userManagementSlice.actions
export const reducer = userManagementSlice.reducer

const createNewUserOrGroup = (isGroup: boolean) => ({
    id: '',
    label: '',
    // description
    // email
    isActive: true,
    isGroup,
    subGroups: [],
    documentPermissions: {
        ignoreDocumentTypes: false,
        documentTypes: [],
        ignoreFieldPermissions: false,
        fieldPermissions: [],
    },
    settings: {
        isAdmin: false,
        isSystemManager: false,
        isUserManager: false,
        isDashboardManager: false,
        isImporterManager: false,
        canSeeAnyDashboard: false,
        canManageCustomColors: false,
        visibleDashboards: [],
    },
})

/*
 * selectors
 */

function sortByLabel(arrayWithLabelledItems: Array<{ id: string; label: string }>) {
    return arrayWithLabelledItems.sort((a, b) => {
        const aLabel = a.label ? a.label.toLowerCase() : a.id
        const bLabel = b.label ? b.label.toLowerCase() : b.id
        if (aLabel < bLabel) {
            return -1
        }

        if (aLabel > bLabel) {
            return 1
        }

        return 0
    })
}

const idAlreadyExists = createSelector(
    (state: RootState) =>
        state.Data.userManagement.currentlyEditedUserOrGroup && state.Data.userManagement.currentlyEditedUserOrGroup.id,
    (state: RootState) => state.Data.userManagement.usersAndGroupsById,
    (currentId, existing) => Boolean(currentId && Object.keys(existing).includes(currentId))
)

const users = createSelector(
    (state: RootState) => state.Data.userManagement.usersAndGroupsById,
    (usersAndGroupsById) => sortByLabel(Object.values(usersAndGroupsById).filter((u) => !u.isGroup))
)

const groups = createSelector(
    (state: RootState) => state.Data.userManagement.usersAndGroupsById,
    (usersAndGroupsById) => sortByLabel(Object.values(usersAndGroupsById).filter((u) => u.isGroup))
)

const currentlyEditedUserOrGroupHasPassword = createSelector(
    (state: RootState) => state.Data.userManagement.currentlyEditedUserOrGroup,
    (state: RootState) => state.Data.userManagement.hasPasswordByUserId,
    (current, hasPasswordsById) => {
        if (current) {
            // groups do not need passwords, so we just return true for them
            return current.isGroup || hasPasswordsById[current.id]
        }

        return true
    }
)

const currentlyEditedUserOrGroupValidation = createSelector(
    (state: RootState) => state.Data.userManagement.currentlyEditedUserOrGroup,
    idAlreadyExists,
    (state: RootState) => state.Data.userManagement.isCreation,
    (currentlyEditedUserOrGroup, idAlreadyExists, isCreation) => {
        if (currentlyEditedUserOrGroup) {
            const idErrorMessages = []
            const filterErrorMessages = []
            const permissionErrorMessages = []

            if (isCreation) {
                // we need to check this if we create a new user but not when editing an existing one
                idErrorMessages.push({
                    type: !idAlreadyExists,
                    message: 'Username is already taken!',
                })
            }

            idErrorMessages.push(
                {
                    type: 'required',
                    message: 'Required value!',
                },
                {
                    type: 'regex',
                    regex: /^([a-zäöü0-9_@. -])+$/,
                    // we enforce lower-casing in the input itself
                    message: 'Please use letters, digits, dash, underscore, @, dot or space.',
                },
                {
                    type: 'regex',
                    regex: /^\S.*$/,
                    message: 'Please do not start with a space.',
                },
                {
                    type: 'regex',
                    regex: /^.*\S$/,
                    message: 'Please do not end with a space.',
                }
            )

            filterErrorMessages.push(
                {
                    type: 'operatorNotNull',
                    message: 'A Filter operator must be selected.',
                },
                {
                    type: 'valuesNotNull',
                    message: 'Please specify values in your filter.',
                },
                {
                    type: 'fieldNotNull',
                    message: 'Please select a field.',
                }
            )

            permissionErrorMessages.push({
                type: 'dataPermissionNotNullIfDashboardPermission',
                message: 'Please specify the kind of data that should be seen.',
            })

            const errorMessages = {
                id: getValidationErrorMessage(currentlyEditedUserOrGroup.id, idErrorMessages),
                filter: getValidationErrorMessage(
                    currentlyEditedUserOrGroup.documentPermissions.fieldPermissions,
                    filterErrorMessages
                ),
                permission: getValidationErrorMessage(currentlyEditedUserOrGroup, permissionErrorMessages),
            }

            return {
                isValid: !containsErrorMessages(errorMessages),
                errorMessages,
            }
        }

        return null
    }
)

export const selectors = {
    usersAndGroupsById: (state: RootState) => state.Data.userManagement.usersAndGroupsById,
    currentlyEditedUserOrGroup: (state: RootState) => state.Data.userManagement.currentlyEditedUserOrGroup,
    isCreation: (state: RootState) => state.Data.userManagement.isCreation,
    currentPasswordToken: (state: RootState) => state.Data.userManagement.passwordToken,
    changedConfiguration: (state: RootState) => state.Data.userManagement.changedConfiguration,
    users,
    groups,
    currentlyEditedUserOrGroupHasPassword,
    currentlyEditedUserOrGroupValidation,
}
