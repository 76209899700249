import TimeSelectorWidget from './TimeSelectorWidget'
// @ts-ignore extension
import TimeSelectorWidgetIcon from './TimeSelectorWidget.Icon.tsx'
import TimeSelectorWidgetStateInitializer from './TimeSelectorWidget.init'
import TimeSelectorWidgetConfigurationInitializer, {
    TimeSelectorConfigurationInitializerForWidgetExplorer,
} from './TimeSelectorWidget.configurationInitializer'
import DefaultDateRangePicker from '../../DateSelectorEditor/DateSelectorEditor'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import { DateRangeSelectMulti } from '../../../Atoms/DateRangeSelect/DateRangeSelect'
import WorkingDaySelector from '../../../Molecules/WorkingDaySelector/WorkingDaySelector'
import NumberPicker from '../../../Atoms/NumberPicker/NumberPicker'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'

const config: WidgetManifestConfiguration = {
    name: 'Time Selector Widget',
    component: TimeSelectorWidget,
    icon: TimeSelectorWidgetIcon,

    stateInitializationFunction: TimeSelectorWidgetStateInitializer,
    configurationInitializer: TimeSelectorWidgetConfigurationInitializer,
    configurationInitializerForWidgetExplorer: TimeSelectorConfigurationInitializerForWidgetExplorer,

    editors: {
        data: [
            {
                widgetConfigurationProperty: 'defaultTimespan',
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Default Date Range',
                    icon: 'CalendarIcon',
                    editor: DefaultDateRangePicker,
                },
            },
            {
                widgetConfigurationProperty: 'availablePredefinedTimespans',
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Available predefined time ranges',
                    editor: DateRangeSelectMulti,
                    helpTooltip: 'timeSelectorAvailablePredefinedTimespans',
                },
            },
            {
                widgetConfigurationProperty: 'workingDays',
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Working Days',
                    editor: WorkingDaySelector,
                    helpTooltip: 'workingDaySelector',
                },
            },
            {
                widgetConfigurationProperty: 'showLastXDays',
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Amount of last days to show',
                    editor: NumberPicker,
                    placeholder: 30,
                    helpTooltip: 'showLastXDays',
                },
            } /* ,
            {
                widgetConfigurationProperty: 'defaultSelectedTimeZone',
                component: FormFieldWithLabel,
                configuration: {
                    label: 'Time Zone to determine start and end of days',
                    editor: TimeZoneSelect
                }
            } */,
        ],
        bottomTabs: [],
    },
    minWidth: 4,
    minHeight: 2,
}

export default config
