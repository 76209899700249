import { takeLatest } from 'redux-saga/effects'
import { actions } from '../../Redux'
import { addCurrentlyEditedDashboardToRequest, executeDashboardDataRequest, getDashboardDataRequest } from './Utility'
import { DashboardRequest } from '../../Redux/Data/types'

export function* LoadDataFromServerWhenEditModeFilterChanges() {
    yield takeLatest(actions.Data.Dashboards.WidgetGroupEditMode.updateFilters, function* () {
        yield executeDashboard()
    })
}

export function* LoadDataFromServerWhenWidgetHasBeenAdded() {
    yield takeLatest(
        [
            // widget has been inserted into dashboard without opening widget editor
            actions.Data.Dashboards.WidgetEditMode.addWidgetWithInitialState.type,
        ],
        executeDashboard
    )
}

export function* executeDashboard() {
    let dashboardRequest: DashboardRequest = yield getDashboardDataRequest()
    dashboardRequest = yield addCurrentlyEditedDashboardToRequest(dashboardRequest)

    yield executeDashboardDataRequest(dashboardRequest, [actions.Data.Dashboards.ViewMode.loadWidgetDataSuccess])
}
