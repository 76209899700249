import React from 'react'
import Select from '../Atoms/Select/Select'

type ValueType = {
    [value: string]: {
        key: string
        asc: boolean
    }
}

const valueMapping: ValueType = {
    keyAsc: {
        key: 'key',
        asc: true,
    },
    keyDesc: {
        key: 'key',
        asc: false,
    },
    valueSortKeyAsc: {
        key: 'valueSortKey',
        asc: true,
    },
    valueSortKeyDesc: {
        key: 'valueSortKey',
        asc: false,
    },
}

/**
 * Widget Configuration Editor for the 'sortKey' and 'sortAsc' widget configuration properties.
 * NOTE: unlike the other editors and building blocks, this component is hardcoded on specific configuration properties
 * which only appear in the Row Chart (/Bar Chart).
 *
 * The sorting is currently saved as two config properties, but should be selectable by the user with only one dropdown.
 * Therefore we do some mapping and conversion here.
 * TODO since the backend does NOTHING with these two properties, we could just migrate the config to save it in a format
 * that suits the frontend better
 *
 * @param props
 * @constructor
 */
const WidgetSortingPropField: React.FunctionComponent<{
    widgetConfiguration: {
        [property: string]: any
    }
    onChangeMultiple: (values: Array<any>) => void
}> = (props) => {
    const handleSelection = (value: string) => {
        const { key, asc } = valueMapping[value]
        props.onChangeMultiple([
            {
                propertyName: 'sortKey',
                value: key,
            },
            {
                propertyName: 'sortAsc',
                value: asc,
            },
        ])
    }

    const key = props.widgetConfiguration.sortKey
    const asc = props.widgetConfiguration.sortAsc

    const value = key ? key + (asc ? 'Asc' : 'Desc') : null

    return (
        <Select
            value={value}
            onChange={handleSelection}
            isClearable={false}
            placeholder="Select sorting..."
            options={[
                {
                    value: 'keyAsc',
                    label: 'Name asc',
                },
                {
                    value: 'keyDesc',
                    label: 'Name desc',
                },
                {
                    value: 'valueSortKeyAsc',
                    label: 'Value asc',
                },
                {
                    value: 'valueSortKeyDesc',
                    label: 'Value desc',
                },
            ]}
        />
    )
}

export default WidgetSortingPropField
