import React, { useState } from 'react'
import Button from './Button/Button'
import { ButtonType } from './Button/Button.types'

const BlockingButton: React.FunctionComponent<{
    className?: string
    label: string
    type?: ButtonType
    isDisabled?: boolean
    onClick: (callback: () => void) => void
}> = (props) => {
    const [isPending, setIsPending] = useState(false)

    const handleClick = () => {
        if (!(props.isDisabled || isPending)) {
            setIsPending(true)
            props.onClick(() => setIsPending(false))
        }
    }

    return (
        <Button
            className={props.className}
            onClick={handleClick}
            isDisabled={props.isDisabled || isPending}
            type={props.type}
        >
            {props.label}
        </Button>
    )
}

export default BlockingButton
