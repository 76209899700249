import React from 'react'
import produce from 'immer'
import NumberPicker from '../Atoms/NumberPicker/NumberPicker'
import Switch from '../Atoms/Switch/Switch'

type Props = {
    onChange: (value: any) => void
    value?: {
        allowCollapsing?: boolean
        indexOfGroupingField?: number
    }
    min: number
    max: number
}

const PivotTableCollapseEditor: React.FC<Props> = (props) => {
    const handleNumberChange = (value: number | null) => {
        props.onChange(
            props.value
                ? produce(props.value, (draft) => {
                      draft.indexOfGroupingField = value || undefined
                  })
                : {
                      indexOfGroupingField: value,
                  }
        )
    }
    const handleToggle = (value?: boolean) => {
        props.onChange(
            props.value
                ? produce(props.value, (draft) => {
                      draft.allowCollapsing = value
                  })
                : {
                      allowCollapsing: true,
                  }
        )
    }

    return (
        <>
            <NumberPicker
                onChange={handleNumberChange}
                value={props.value?.indexOfGroupingField}
                disabled={!props.value?.allowCollapsing}
                label="Select Group Number to collapse by default"
                placeholder="1"
                min={props.min}
                max={props.max}
            />
            <Switch onChange={handleToggle} value={Boolean(props.value?.allowCollapsing)} />
        </>
    )
}

export default PivotTableCollapseEditor
