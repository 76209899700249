import {
    interpolateRdPu,
    interpolateRdYlGn,
    interpolateYlGn,
    interpolateYlGnBu,
    interpolateYlOrRd,
} from 'd3-scale-chromatic'

export const FURTHER_VALUES = '(Further Values)'
export const SUM = 'Sum'
export const EMPTY_VALUE = '(empty value)'

export const COLORS = [
    '#4fc3f7',
    '#b2dfdb',
    '#aed581',
    '#fcf192',
    '#ffb74d',
    // '#c9bfbb', // too close to #ccc for unselected values
    '#f06292',
    '#d1c4e9',
    '#63afed',
    '#b2ebf2',
    '#81c784',
    '#d2d69d',
    '#ffd54f',
    '#ffccbc',
    '#e57373',
    '#e1bee7',
    '#7986cb',
    '#b3e5fc',
    '#4db6ac',
    '#dcedc8',
    '#fff176',
    '#ffe0b2',
    '#a1887f',
    '#f8bbd0',
    '#9575cd',
    '#bbdefb',
    '#4dd0e1',
    '#c8e6c9',
    '#dce775',
    '#ffecb3',
    '#ff8a65',
    '#ffcdd2',
    '#ba68c8',
    '#c5cae9',
]

export const yellowGreenBlue = interpolateYlGnBu

export const colorScales = new Map([
    ['yellowGreenBlue', yellowGreenBlue],
    ['redPurple', interpolateRdPu],
    ['orangeRed', interpolateYlOrRd],
    ['yellowGreen', interpolateYlGn],
    ['redYellowGreen', interpolateRdYlGn],
])
