import React, { memo } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import uuid from 'uuid'
import Icon from '../../Atoms/Icon/Icon'
import Button from '../../Atoms/Button/Button'
import { AppDispatch } from '../../../store'
import { actions } from '../../../Redux'
import { createAndAddNewWidgetAction } from '../../../Sagas/DashboardSagas/CreateAndAddNewWidgetSaga'
import { DashboardGroupId } from '../../../Redux/UI/types'
import { ALL_DASHBOARDS, FAVOURITE_DASHOARDS } from '../../../Redux/UI/DashboardGroups'

export const dispatchAddNewDashboardAction = (
    dispatch: AppDispatch,
    isFavourite?: boolean,
    dashboardGroup?: DashboardGroupId
) => {
    const newDashboardId = uuid()
    const widgetGroupId = uuid()
    dispatch(actions.Data.Dashboards.DashboardEditMode.enableEditMode(newDashboardId))
    dispatch(actions.Data.Dashboards.DashboardEditMode.addDashboard({ dashboardId: newDashboardId }))
    dispatch(actions.Data.Dashboards.WidgetGroupEditMode.addWidgetGroup({ idOfNewGroup: widgetGroupId }))
    dispatch(actions.Data.Dashboards.WidgetEditMode.addInitialTimeSelectorWidget(widgetGroupId))
    dispatch(createAndAddNewWidgetAction({}))
    dispatch(push(`/dashboard/${newDashboardId}`))
    if (isFavourite) {
        dispatch(actions.UI.FavouriteDashboards.add(newDashboardId))
    }
    if (dashboardGroup && dashboardGroup !== ALL_DASHBOARDS && dashboardGroup !== FAVOURITE_DASHOARDS) {
        dispatch(
            actions.UI.DashboardGroups.moveDashboardFromGroupToGroup({
                sourceGroup: ALL_DASHBOARDS,
                destinationGroup: dashboardGroup,
                dashboardId: newDashboardId,
            })
        )
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onAddNewDashboard: () => dispatchAddNewDashboardAction(dispatch),
})

type Props = ReturnType<typeof mapDispatchToProps>

const CreateNewDashboardButton = (props: Props) => (
    <Button onClick={props.onAddNewDashboard} type="primary">
        <Icon name="PlusIcon" color="white" label="Create new Dashboard" />
    </Button>
)

export default connect(undefined, mapDispatchToProps)(memo(CreateNewDashboardButton))
