import MatrixChartWidget from './MatrixChartWidget'
import MatrixChartWidgetStateInitializer from './MatrixChartWidget.init'
import MatrixChartWidgetConfigurationInitializer, {
    MatrixChartConfigurationInitializerForWidgetExplorer,
} from './MatrixChartWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import NumberPicker from '../../../Atoms/NumberPicker/NumberPicker'
// @ts-ignore file ext
import MatrixChartWidgetIcon from './MatrixChartWidget.Icon.tsx'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'
import { MatrixChartWidgetConfig } from './MatrixChartWidget.types'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'

const sortOptions = [
    {
        value: 'asc',
        label: 'ascending',
    },
    {
        value: 'desc',
        label: 'descending',
    },
]

const config: WidgetManifestConfiguration = {
    name: 'Matrix Chart',
    component: MatrixChartWidget,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 1,
        },
        max: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 2,
        },
    },
    configurationValidations: (widgetConfig: MatrixChartWidgetConfig) => {
        if (widgetConfig.valueField === undefined || widgetConfig.valueField === '') {
            return widgetProblems.noValueField
        }
    },
    icon: MatrixChartWidgetIcon,
    stateInitializationFunction: MatrixChartWidgetStateInitializer,
    configurationInitializer: MatrixChartWidgetConfigurationInitializer,
    configurationInitializerForWidgetExplorer: MatrixChartConfigurationInitializerForWidgetExplorer,
    editors: {
        data: [
            buildingBlocks.valueField({ areDateRangeFieldsAllowed: true }),
            buildingBlocks.pivotField('X-Axis Pivot Field', 'xAxisPivotField'),
            buildingBlocks.pivotField('Y-Axis Pivot Field', 'yAxisPivotField'),
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.flag('showSumRow', 'Show Row Sum', 'ShowColSumIcon'),
                    buildingBlocks.flag('showSumColumn', 'Show Column Sum', 'ShowRowSumIcon'),
                    {
                        widgetConfigurationProperty: 'xLimit',
                        component: NumberPicker,
                        editorContextBuilder: () => ({
                            label: 'Maximum Number of Columns',
                            placeholder: '10',
                            min: 1,
                            max: 20,
                            step: 1,
                        }),
                        configuration: {
                            editorIcon: 'MaxColsIcon',
                            isIconAlwaysActive: true,
                        },
                    },
                    {
                        widgetConfigurationProperty: 'yLimit',
                        component: NumberPicker,
                        editorContextBuilder: () => ({
                            label: 'Maximum Number of Rows',
                            placeholder: '10',
                            min: 1,
                            max: 500,
                            step: 1,
                        }),
                        configuration: {
                            editorIcon: 'MaxRowsIcon',
                            isIconAlwaysActive: true,
                        },
                    },
                    buildingBlocks.numberOfDecimals(),
                ],
            },
            {
                name: 'Label Options',
                editors: [
                    buildingBlocks.text('xAxisLabel', 'X-Axis Label'),
                    buildingBlocks.text('yAxisLabel', 'Y-Axis Label'),
                ],
            },
            {
                name: 'Sorting Options',
                editors: [
                    buildingBlocks.selectOneOf('orderXAxisBy', 'Sort X-Axis', sortOptions, false, 'AxisTopIcon', true),
                    buildingBlocks.selectOneOf('orderYAxisBy', 'Sort Y-Axis', sortOptions, false, 'AxisLeftIcon', true),
                ],
            },
        ],
    },
}

export default config
