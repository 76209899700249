import { put, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions } from '../../Redux'
import axios from '../Utility/axios'
import { UserOrGroupId } from '../../Redux/Data/UserManagement'

export const switchToUser = createAction('Data/UserManagement/SwitchToUser', (userId: UserOrGroupId) => ({
    payload: { userId },
}))

export default function* SwitchToUserSaga() {
    yield takeLatest(switchToUser, function* (action) {
        try {
            const userId = action.payload.userId
            const response: AxiosResponse = yield axios({
                method: 'GET',
                url: 'admin/userManagement/switch-to-user/' + userId,
            })

            if (response.status < 400) {
                window.location.href =
                    window.location.protocol + '//' + window.location.host + window.location.pathname + '#'
                window.location.reload()
            } else {
                yield put(actions.UI.Notification.show({ id: 'userOrGroupSwitchToUserFailed' }))
            }
        } catch (e) {
            console.error(e)
        }
    })
}
