import React from 'react'
import classnames from 'classnames'

const TextArea: React.FunctionComponent<{
    className?: string
    value?: string
    size?: 'small' | 'large'
    disabled?: boolean
    placeholder?: string
    onChange: (value: string) => void
}> = (props) => {
    const className = classnames('textarea', props.className, {
        ['textarea--' + props.size]: props.size,
        'textarea--disabled': props.disabled,
    })

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.onChange(event.target.value)
    }

    return (
        <textarea className={className} value={props.value} placeholder={props.placeholder} onChange={handleChange} />
    )
}

export default TextArea
