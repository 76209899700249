import { takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { createAction } from '@reduxjs/toolkit'
import axios from '../../../Utility/axios'

type TempoOAuthCredentials = {
    clientId: string
    clientSecret: string
}

export const storeTempoOAuthCredentals = createAction(
    'SAGAS/StoreTempoOAuthCredentials',
    (tempoOAuthCredentials: TempoOAuthCredentials) => ({ payload: tempoOAuthCredentials })
)

export default function* StoreTempoOAuthCredentialsSaga() {
    yield takeLatest(storeTempoOAuthCredentals, function* (action) {
        try {
            const response: AxiosResponse = yield axios({
                method: 'POST',
                url: 'admin/tempo-configuration/tempo-oauth-credentials',
                data: action.payload,
            })
            if (response.status < 400) {
                const { redirectionTarget } = response.data
                // TODO: redirection within i-frame looks weird, use AP JS lib ?!
                window.location.href = redirectionTarget
            } else {
                console.error('failed to store Tempo credentials', response.status, response)
            }
        } catch (e) {
            console.error(e)
        }
    })
}
