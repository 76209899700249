import { put, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions } from '../../../Redux'
import axios from '../../Utility/axios'

export const clearImporterData = createAction('Data/Importer/Data/ClearData', (uuid: string) => ({ payload: { uuid } }))

export function* ClearSingleImporterDataSaga() {
    yield takeLatest(clearImporterData, function* (action) {
        try {
            const importerUuid = action.payload.uuid

            const response: AxiosResponse = yield axios({
                method: 'POST',
                url: 'admin/importer/clear/' + importerUuid,
            })

            if (response.status >= 400) {
                yield put(actions.UI.Notification.show({ id: 'importerClearSingleFailed' }))
            }
        } catch (e) {
            console.error(e)
        }
    })
}

export const clearAllImporterData = createAction('Data/Importer/Data/ClearAllData')

export function* ClearAllDataSaga() {
    yield takeLatest(clearAllImporterData, function* () {
        try {
            const response: AxiosResponse = yield axios({
                method: 'POST',
                url: 'admin/importer/clear-all',
            })

            if (response.status >= 400) {
                yield put(actions.UI.Notification.show({ id: 'importerClearAllFailed' }))
            }
        } catch (e) {
            console.error(e)
        }
    })
}
