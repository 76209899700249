import { all, takeLatest } from 'redux-saga/effects'
import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import axios from './Utility/axios'
import { HandleFileUploadDone, HandleFileUploadProgress, File } from '../Components/Molecules/FileUpload/FileUpload'

export const startImporterSourceFileUploadAction = createAction(
    'Sagas/startImporterSourceFileUpload',
    (file: File, handleProgress: HandleFileUploadProgress, handleDone: HandleFileUploadDone) => ({
        payload: { file, handleProgress, handleDone, url: 'admin/importer/upload-file' },
    })
)
export const startWidgetAssetUploadAction = createAction(
    'Sagas/startWidgetAssetUpload',
    (file: File, handleProgress: HandleFileUploadProgress, handleDone: HandleFileUploadDone) => ({
        payload: { file, handleProgress, handleDone, url: 'dashboard/upload-file' },
    })
)
export const startDashboardConfigurationUploadAction = createAction(
    'Sagas/startDashboardConfigurationUpload',
    (file: File, handleProgress: HandleFileUploadProgress, handleDone: HandleFileUploadDone) => ({
        payload: { file, handleProgress, handleDone, url: 'dashboard/configuration' },
    })
)

function* uploadFile(
    action: PayloadAction<{
        file: File
        handleProgress: HandleFileUploadProgress
        handleDone: HandleFileUploadDone
        url: string
    }>
) {
    const { file, url, handleProgress, handleDone } = action.payload
    try {
        const formData = new FormData()
        // @ts-ignore this works fine, but according to typescript wrong
        formData.append('file', file)

        const response: AxiosResponse = yield axios({
            method: 'POST',
            url,
            params: {
                'file-name': file.name,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            onUploadProgress: ({ loaded, total }) => handleProgress(loaded, total),
        })

        if (response.status < 300) {
            handleDone(response.data)
        } else {
            console.error(JSON.stringify(response))
            handleDone(null)
        }
    } catch (e) {
        console.error(e)
        handleDone(null)
    }
}

export default function* FileUploadSaga() {
    yield all([
        takeLatest(startImporterSourceFileUploadAction, uploadFile),
        takeLatest(startWidgetAssetUploadAction, uploadFile),
        takeLatest(startDashboardConfigurationUploadAction, uploadFile),
    ])
}
