import React from 'react'
import ColorUtility from '../../../Utility/ColorUtility'

// half the size of the marker
const HALF_ARROW_SIZE = 10

const CustomizedReferenceLineLabel: React.FC<
    Partial<{
        viewBox: {
            x: number
            y: number
            width: number
            height: number
        }
        offset: number
        label: string
        color: string
        isVerticalChart: boolean
    }>
> = (props) => {
    const x = props.viewBox ? props.viewBox.x : 0
    const y = props.viewBox ? props.viewBox.y : 0
    const width = props.viewBox ? props.viewBox.width : 0
    const height = props.viewBox ? props.viewBox.height : 0

    const backgroundColor = props.color || '#ff0000'
    const fontColor = ColorUtility.isLight(backgroundColor) ? '#000000' : '#ffffff'
    const labelLength = 6 * (props.label ? props.label.length : 0)

    if (props.isVerticalChart) {
        return (
            <g>
                <path
                    d={`M${
                        x - HALF_ARROW_SIZE
                    } ${y} v${labelLength} l${HALF_ARROW_SIZE} ${HALF_ARROW_SIZE} l${HALF_ARROW_SIZE} -${HALF_ARROW_SIZE} v-${labelLength} Z`}
                    fill={backgroundColor}
                />
                <text
                    x={x + 2}
                    y={y + HALF_ARROW_SIZE * 0.25}
                    width={width}
                    height={height}
                    fontSize={10}
                    fontFamily="Monospace"
                    fill={fontColor}
                    transform={`rotate(90 ${x} ${y})`}
                >
                    {props.label && props.label.toUpperCase()}
                </text>
            </g>
        )
    }

    return (
        <g>
            <path
                d={`M${x} ${
                    y - HALF_ARROW_SIZE
                } h${labelLength} l${HALF_ARROW_SIZE} ${HALF_ARROW_SIZE} l-${HALF_ARROW_SIZE} ${HALF_ARROW_SIZE} h-${labelLength} Z`}
                fill={backgroundColor}
            />
            <text
                x={x + 2}
                y={y + HALF_ARROW_SIZE * 0.25}
                width={width}
                height={height}
                fontSize={10}
                fontFamily="Monospace"
                fill={fontColor}
            >
                {props.label && props.label.toUpperCase()}
            </text>
        </g>
    )
}

export default React.memo(CustomizedReferenceLineLabel)
