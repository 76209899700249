import SunburstWidget from './LazySunburstWidget'
import SunburstWidgetStateInitializer from './SunburstWidget.init'
import SunburstWidgetConfigurationInitializer, {
    SunBurstConfigurationInitializerForWidgetExplorer,
} from './SunburstWidget.configurationInitializer'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
// @ts-ignore
import SunburstWidgetIcon from './SunBurstWidget.Icon.tsx'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'
import { SunburstWidgetConfig, SunburstWidgetData } from './SunburstWidget.types'
import { widgetProblems } from '../../../Atoms/WidgetProblemState'

const config: WidgetManifestConfiguration = {
    name: 'Sun Burst Chart',
    component: SunburstWidget,
    capabilities: {
        min: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 1,
        },
        max: {
            dateFields: 1,
            valueFields: 1,
            categoryFields: 4,
        },
    },
    configurationValidations: (widgetConfig: SunburstWidgetConfig) => {
        // @ts-ignore check for old versions will always fail
        if (widgetConfig.valueField === undefined || widgetConfig.valueField === '') {
            return widgetProblems.noValueField
        }
    },
    renderRequirements: (widgetData?: SunburstWidgetData) => {
        if (widgetData?.negativeValues) {
            return widgetProblems.negativeValues
        }

        if (!widgetData) {
            return widgetProblems.noData
        }
    },
    icon: SunburstWidgetIcon,
    stateInitializationFunction: SunburstWidgetStateInitializer,
    configurationInitializer: SunburstWidgetConfigurationInitializer,
    configurationInitializerForWidgetExplorer: SunBurstConfigurationInitializerForWidgetExplorer,

    editors: {
        data: [
            buildingBlocks.valueField({
                isModeDisabled: true,
                isGroupingFieldDisabled: true,
                areDateRangeFieldsAllowed: true,
            }),
            buildingBlocks.pivotFields(4),
        ],
        bottomTabs: [
            {
                name: 'Display Options',
                editors: [
                    buildingBlocks.numberOfDecimals(),
                    buildingBlocks.flag(
                        'showLegend',
                        'Legend by Default',
                        'LegendIcon',
                        'The legend is not displayed by default if the width of the widget is too small.'
                    ),
                ],
            },
        ],
    },
}

export default config
