import React, { PureComponent } from 'react'
import { EditDataSourcePreviewManagerProps, EditDataSourcePreviewManagerState } from '../DataSource.types'
import EditDataSourceEditorsAndPreview from '../EditDataSourceEditorsAndPreview/EditDataSourceEditorsAndPreview'

const initialState = {
    preview: null,
    configurationSuggestions: undefined,
    previewIsLoading: false,
    previewIsOutdated: false,
}

/**
 * This component handles updating the preview of a Data Source configuration
 * More Details in {@link EditImporterOrExtensionManager}
 */
export default class EditDataSourcePreviewManager extends PureComponent<
    EditDataSourcePreviewManagerProps,
    EditDataSourcePreviewManagerState
> {
    constructor(props: EditDataSourcePreviewManagerProps) {
        super(props)
        this.state = initialState
    }

    componentDidMount() {
        this.fetchStepData()
    }

    componentDidUpdate(prevProps: EditDataSourcePreviewManagerProps) {
        if (this.props !== prevProps) {
            // if this is another importer than before, we must reset the UI
            const importerHasChanged =
                this.props.currentlyEditedConfiguration?.uuid !== prevProps.currentlyEditedConfiguration?.uuid
            if (importerHasChanged) {
                this.setState(initialState)
                this.fetchStepData()
            } else {
                const stepChanged = this.props.currentStepIndex !== prevProps.currentStepIndex
                const typeChanged = this.props.type !== prevProps.type
                const autoUpdateTypes = ['xmlFileDataSource', 'csvFileDataSource', 'joinExtraCsvData']
                const allowAutoUpdate = this.props.type && autoUpdateTypes.includes(this.props.type)
                const configChanged =
                    this.props.currentlyEditedConfiguration?.configuration !==
                    prevProps.currentlyEditedConfiguration?.configuration
                // if the type of the importer has changed or the user clicked "next" we reset the preview
                if (stepChanged || typeChanged || allowAutoUpdate) {
                    this.fetchStepData()
                } else if (configChanged) {
                    // … otherwise we allow the user to refresh the preview manually
                    this.setState({ previewIsOutdated: true })
                }
            }
        }
    }

    render() {
        return (
            <EditDataSourceEditorsAndPreview
                currentlyEditedConfiguration={this.props.currentlyEditedConfiguration}
                currentStepIndex={this.props.currentStepIndex}
                previewIsOutdated={this.state.previewIsOutdated}
                preview={this.state.preview}
                configurationSuggestions={this.state.configurationSuggestions || {}}
                onReloadPreview={this.fetchStepData}
                onChangeConfigurationProperty={this.props.onChangeProperty}
                onChangeSchedule={this.props.onChangeSchedule}
                dispatch={this.props.dispatch}
                steps={this.props.steps}
                showNoSchedule={this.props.showNoSchedule}
                type={this.props.type}
            />
        )
    }

    fetchStepData = () => {
        const { currentStepIndex, currentlyEditedConfiguration, type } = this.props
        const uuid = this.props.currentlyEditedImporter?.uuid

        if (currentlyEditedConfiguration && type && uuid) {
            const currentStep = this.props.steps[currentStepIndex]
            const hasStepData = currentStep.hasPreview || currentStep.hasConfigSuggestion

            if (hasStepData) {
                this.setState((prevState) => {
                    // We don't want to throw configuration suggestions away after we have loaded them once
                    return {
                        configurationSuggestions: prevState.configurationSuggestions,
                        previewIsOutdated: false,
                        previewIsLoading: true,
                    }
                })
                this.props.fetchStepData(
                    uuid,
                    type,
                    currentStepIndex,
                    this.props.currentlyEditedConfiguration?.configuration,
                    (previewAndConfigSuggestion) =>
                        this.setState({
                            preview: previewAndConfigSuggestion.preview,
                            configurationSuggestions: previewAndConfigSuggestion.configurationSuggestions,
                            previewIsLoading: false,
                        })
                )
            } else {
                this.setState({
                    preview: undefined,
                    configurationSuggestions: undefined,
                })
            }
        }
    }
}
