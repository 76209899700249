import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../Redux/types'
import { selectors } from '../../../Redux'
import { AppDispatch } from '../../../store'
import Loader from '../../../Components/Atoms/Loader/Loader'
import { loadTempoOAuthConfigurationAction } from '../../../Sagas/ImporterSagas/Editing/TempoCloud/GetTempoOAuthConfigSaga'

type ReduxTempoCloudConfigurationProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: RootState) => ({
    tempoOAuthConfiguration: selectors.Data.Importer.tempoOAuthConfiguration(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    loadTempoOAuthConfiguration: () => dispatch(loadTempoOAuthConfigurationAction()),
})

const TempoCloudConfigurationDescription: React.FC<ReduxTempoCloudConfigurationProps> = (props) => {
    useEffect(() => {
        props.loadTempoOAuthConfiguration()
    }, [])

    return !props.tempoOAuthConfiguration ? (
        <Loader />
    ) : (
        <div className="tempo-cloud-configuration-description">
            <h3>To create a Tempo importer we need to setup a connection to Tempo via OAuth 2.0.</h3>
            <ol>
                <li>
                    Go to your&nbsp;
                    <a
                        href={`${props.tempoOAuthConfiguration.atlassianHostUrl}/plugins/servlet/ac/io.tempo.jira/tempo-app#!/configuration/identity-service`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="primary"
                    >
                        Tempo Configuration of OAuth 2.0 Applications
                    </a>
                    .
                </li>
                <li>
                    Add Exply as an App.
                    <ul>
                        <li>Name = Exply</li>
                        <li>Redirect URIs = {props.tempoOAuthConfiguration.redirectionUri}</li>
                        <li>Client type = Public</li>
                        <li>Authorization grant type = Authorization code</li>
                    </ul>
                </li>
                <li>Copy-paste the Client ID and the Client Secret in the formula below.</li>
                <li>Press the Grant-Access-Button and follow the instruction on the screen.</li>
            </ol>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TempoCloudConfigurationDescription)
