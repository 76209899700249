import React from 'react'
import moment from 'moment-timezone'
import Select from '../../Atoms/Select/Select'

const TimeZoneSelect: React.FC<{
    timeZones?: Array<string> // defaults to moment.tz.names()
    placeholder?: string // defaults to moment.tz.guess()
    value: string
    onChange: (value: string) => void
    className?: string
}> = (props) => {
    const options = (props.timeZones || moment.tz.names()).map((timezoneName: string) => {
        const label = timezoneName
        const value = timezoneName
        return { label, value }
    })

    return (
        <Select
            className={props.className}
            placeholder={props.placeholder || moment.tz.guess()}
            options={options}
            onChange={props.onChange}
            value={props.value}
        />
    )
}

export default TimeZoneSelect
