import RowChartManifest from '../Components/Organisms/Widgets/RowChartWidget/RowChartWidget.manifest'
import TreeMapManifest from '../Components/Organisms/Widgets/TreeMapWidget/TreeMapWidget.manifest'
import SunburstManifest from '../Components/Organisms/Widgets/SunburstWidget/SunburstWidget.manifest'
import ScatterPlotManifest from '../Components/Organisms/Widgets/ScatterPlotWidget/ScatterPlotWidget.manifest'
import MatrixChartManifest from '../Components/Organisms/Widgets/MatrixChartWidget/MatrixChartWidget.manifest'
import BurnChartManifest from '../Components/Organisms/Widgets/BurnChartWidget/BurnChartWidget.manifest'
import TimeSeriesManifest, {
    TimeAreaChartManifest,
    TimeBarChartManifest,
    TimeLineChartManifest,
} from '../Components/Organisms/Widgets/TimeSeriesChart/TimeSeriesChart.manifest'
import GanttChartManifest from '../Components/Organisms/Widgets/GanttChart/GanttChartWidget.manifest'
import PivotTableManifest from '../Components/Organisms/Widgets/PivotTableWidget/PivotTableWidget.manifest'
import ResultTableManifest from '../Components/Organisms/Widgets/ResultTableWidget/ResultTableWidget.manifest'
import QuotaTileManifest from '../Components/Organisms/Widgets/QuotaTileWidget/QuotaTileWidget.manifest'
import MetricsTileManifest from '../Components/Organisms/Widgets/MetricsTileWidget/MetricsTileWidget.manifest'
import MapWidgetManifest from '../Components/Organisms/Widgets/MapWidget/MapWidget.manifest'
import QuickFilterManifest from '../Components/Organisms/Widgets/QuickFilterWidget/QuickFilterWidget.manifest'
import SearchBarManifest from '../Components/Organisms/Widgets/SearchBarWidget/SearchBarWidget.manifest'
import { ImporterStatusWidgetManifest } from '../Components/Organisms/Widgets/ImporterStatusWidget/ImporterStatusWidget.manifest'
import TimeSelectorManifest from '../Components/Organisms/Widgets/TimeSelectorWidget/TimeSelectorWidget.manifest'
import ImageManifest from '../Components/Organisms/Widgets/ImageWidget/ImageWidget.manifest'
import SpacerWidgetManifest from '../Components/Organisms/Widgets/SpacerWidget/SpacerWidget.manifest'
import { WidgetManifestConfiguration } from '../widgetManifestBuildingBlocks'
import SqlTableWidgetManifest from '../Components/Organisms/Widgets/SqlTableWidget/SqlTableWidget.manifest'
import LineChartManifest from '../Components/Organisms/Widgets/LineChartWidget/LineChartWidget.manifest'

export const DEFAULT_WIDGET_TYPE = 'rowChart'
export const TIME_SELECTOR = 'timeSelector'

const widgetTypes: { [key: string]: WidgetManifestConfiguration } = {
    rowChart: RowChartManifest('rowChart'),
    barChart: RowChartManifest('barChart'),
    lineChart: LineChartManifest,
    treeMap: TreeMapManifest,
    sunburst: SunburstManifest,
    scatterPlot: ScatterPlotManifest,
    matrixChart: MatrixChartManifest,
    burnChart: BurnChartManifest('burnChart'),
    jiraBurnChart: BurnChartManifest('jiraBurnChart'),
    timeSeriesChart: TimeSeriesManifest,
    timeAreaChart: TimeAreaChartManifest,
    timeBarChart: TimeBarChartManifest,
    timeLineChart: TimeLineChartManifest,
    ganttChart: GanttChartManifest,
    pivotTable: PivotTableManifest,
    resultTable: ResultTableManifest,
    sqlTable: SqlTableWidgetManifest,
    quotaTile: QuotaTileManifest('quotaTile'),
    jiraQuotaTile: QuotaTileManifest('jiraQuotaTile'),
    metricTile: MetricsTileManifest,
    mapWidget: MapWidgetManifest,
    quickFilter: QuickFilterManifest,
    searchBar: SearchBarManifest,
    importerStatus: ImporterStatusWidgetManifest,
    timeSelector: TimeSelectorManifest,
    image: ImageManifest,
    spacer: SpacerWidgetManifest,
}

export default widgetTypes
