import createAndAddNewWidgetSaga from './CreateAndAddNewWidgetSaga'
import DeleteDashboardSaga from './DeleteDashboardSaga'
import DownloadDashboardConfigurationSaga from './DownloadDashboardConfigurationSaga'
import DuplicateDashboardSaga from './DuplicateDashboardSaga'
import {
    LoadDataFromServerWhenEditModeFilterChanges,
    LoadDataFromServerWhenWidgetHasBeenAdded,
} from './LoadDataFromServerWhenDashboardChanged'
import { SaveDashboardToServerSaga } from './SaveDashboardToServerSaga'
import {
    ResetLatestWidgetConfiguration,
    UpdateLatestWidgetConfiguration,
    UpdateResponseOnWidgetConfigurationChange,
    UpdateStateOnIntendedWidgetConfigurationChange,
    UpdateWidgetConfigurationWhenTypeChanges,
} from './WidgetEditModeSagas'
import { ExportDashboardSaga, GetExportDashboardSettingsThenExportSaga } from './ExportSagas'
import {
    CreateWidgetPreviewsForExplorer,
    UpdateDashboardAfterAddingWidget,
    DeleteNewDashboardOnBrowserBack,
} from './WidgetExplorerSagas'
import {
    AddDashboardToFavouritesSaga,
    RemoveDashboardFromFavouritesSaga,
    ToggleFavouriteDashboardSaga,
} from './FavouriteDashboardsSaga'
import SetDashboardImage from './SetImageSaga'
import DuplicateWidgetSaga from './DuplicateWidgetSaga'
import DuplicateWidgetGroupSaga from './DuplicateWidgetGroupSaga'
import DashboardGroupsSaga from './DashboardGroupsSaga'
import ShareDashboardSaga from './ShareDashboardSaga'

export default [
    createAndAddNewWidgetSaga,
    DeleteDashboardSaga,
    DownloadDashboardConfigurationSaga,
    DuplicateDashboardSaga,
    LoadDataFromServerWhenEditModeFilterChanges,
    LoadDataFromServerWhenWidgetHasBeenAdded,
    SaveDashboardToServerSaga,
    UpdateWidgetConfigurationWhenTypeChanges,
    UpdateLatestWidgetConfiguration,
    ResetLatestWidgetConfiguration,
    UpdateStateOnIntendedWidgetConfigurationChange,
    UpdateResponseOnWidgetConfigurationChange,
    GetExportDashboardSettingsThenExportSaga,
    ExportDashboardSaga,
    CreateWidgetPreviewsForExplorer,
    UpdateDashboardAfterAddingWidget,
    AddDashboardToFavouritesSaga,
    RemoveDashboardFromFavouritesSaga,
    SetDashboardImage,
    DuplicateWidgetSaga,
    DuplicateWidgetGroupSaga,
    ToggleFavouriteDashboardSaga,
    DashboardGroupsSaga,
    ShareDashboardSaga,
    DeleteNewDashboardOnBrowserBack,
]
