import ImporterStatusWidget from './ImporterStatusWidget'
// @ts-ignore file extension
import ImporterStatusWidgetIcon from './ImporterStatusWidget.Icon.tsx'
import ImporterStatusWidgetStateInitializer from './ImporterStatusWidget.init'
import ImporterStatusWidgetConfigurationInitializer from './ImporterStatusWidget.configurationInitializer'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import ImporterSelector from '../../../../Containers/Importers/ImporterSelector/ImporterSelector'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'

export const ImporterStatusWidgetManifest: WidgetManifestConfiguration = {
    name: 'Importer Status Widget',
    component: ImporterStatusWidget,
    icon: ImporterStatusWidgetIcon,
    stateInitializationFunction: ImporterStatusWidgetStateInitializer,
    configurationInitializer: ImporterStatusWidgetConfigurationInitializer,
    editors: {
        data: [
            {
                widgetConfigurationProperty: 'importerUuids',
                component: FormFieldWithLabel,
                configuration: { title: 'Importers to show', editor: ImporterSelector },
            },
        ],
        bottomTabs: [],
    },
}
