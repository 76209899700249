import React, { memo } from 'react'
import Button from '../../Atoms/Button/Button'

type Props = {
    handleCancelRemoval: () => void
    handleRemoveButtonClick: () => void
}

const DashboardRemovalConfirmation = (props: Props) => {
    return (
        <React.Fragment>
            <Button onClick={props.handleCancelRemoval}>Cancel</Button>
            <Button onClick={props.handleRemoveButtonClick} type="warning">
                Remove
            </Button>
        </React.Fragment>
    )
}

export default memo(DashboardRemovalConfirmation)
