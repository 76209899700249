import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { actionTypes as system } from '../System'
import { DataState, DateFormats, License, WorkingDays } from './types'
import { RootState } from '../types'

const systemSlice = createSlice({
    name: 'Data/System',
    initialState: {} as DataState,
    extraReducers: {
        [system.INIT]: (state, action) => {
            const { initialState } = action.payload
            state.system = {
                version: initialState.system.version,
                releaseDateMillis: initialState.system.releaseDateMillis,
                licenses: initialState.system.licenses,
                applicationMode: initialState.system.applicationMode,
                timeZone: initialState.system.timeZone,
                workingDays: initialState.system.workingDays,
                dateFormat: initialState.system.dateFormat,
                openDashboardOnStart: initialState.system.openDashboardOnStart,
                internalDashboardSharingLink: window.location.protocol + '//' + window.location.host + '#/dashboard/',
                accessMode: initialState.system.accessMode,
                elasticTokenLength: initialState.system.elasticTokenLength,
                jiraCloudJwt: initialState.system.jiraCloudJwt,
            }
        },
    },
    reducers: {
        setLicenses: (state, action: PayloadAction<{ licenses: [License] }>) => {
            state.system.licenses = action.payload.licenses
        },
        uploadLicense: {
            prepare: (license: License) => ({ payload: { license } }),
            reducer: () => {},
        },
        setSecureCookie: {
            prepare: (secureCookie: boolean) => ({ payload: { secureCookie } }),
            reducer: () => {},
        },
        setTimeZone: {
            prepare: (timeZone: string) => ({ payload: { timeZone } }),
            reducer: (state, action) => {
                state.system.timeZone = action.payload.timeZone
            },
        },
        setWorkingDays: {
            prepare: (workingDays: WorkingDays) => ({ payload: { workingDays } }),
            reducer: (state, action) => {
                state.system.workingDays = action.payload.workingDays
            },
        },
        setDateFormat: {
            prepare: (dateFormat: DateFormats) => ({ payload: { dateFormat } }),
            reducer: (state, action) => {
                state.system.dateFormat = action.payload.dateFormat
            },
        },
        setInternalDashboardSharingLink: (state, action: PayloadAction<string>) => {
            state.system.internalDashboardSharingLink = action.payload
        },
        setElasticTokenLength: {
            prepare: (elasticTokenLength: number) => ({ payload: elasticTokenLength }),
            reducer: (state, action: PayloadAction<number>) => {
                state.system.elasticTokenLength = action.payload
            },
        },
    },
})

export const actions = systemSlice.actions
export const reducer = systemSlice.reducer

const applicationMode = (state: RootState) => state.Data.system.applicationMode
export const selectors = {
    version: (state: RootState) => state.Data.system.version,
    releaseDateMillis: (state: RootState) => state.Data.system.releaseDateMillis,
    licenses: (state: RootState) => state.Data.system.licenses,
    applicationMode,
    timeZone: (state: RootState) => state.Data.system.timeZone,
    workingDays: (state: RootState) => state.Data.system.workingDays,
    dateFormat: (state: RootState) => state.Data.system.dateFormat,
    openDashboardOnStart: (state: RootState) => state.Data.system.openDashboardOnStart,
    accessMode: (state: RootState) => state.Data.system.accessMode,
    elasticTokenLength: (state: RootState) => state.Data.system.elasticTokenLength,
    jiraCloudJwt: (state: RootState) => state.Data.system.jiraCloudJwt,
    userLimit: createSelector(
        (state: RootState) => state.Data.system.licenses,
        (licenses: Array<License>) => {
            const limit = licenses[0].content.userLimit
            if (limit < 2147483647 /* int.MAX_VALUE */) {
                return limit
            }

            return false
        }
    ),
}
