import React, { useState } from 'react'
import { Legend, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts'
import ResponsiveTestingAwareChartContainer from '../../../Atoms/ResponsiveTestingAwareChartContainer'
import { RowChartWidgetProps } from '../RowChartWidget/RowChartWidget.types'
import CustomTooltip from '../ToolTip/CustomTooltip'
import { COLORS } from '../Constants'
import { CustomTickForVertical } from '../RowChartWidget/CustomTicks'
import RowChartWidgetHeader, { sortValues } from '../RowChartWidget/RowChartWidgetHeader'
import { handleUpdateSelection, stackLabelFnBanner } from '../RowChartWidget/RowChartWidgetChart'
import CustomizedTick from '../CustomizedTick'
import { ReferenceLineEntry } from '../../../Molecules/ReferenceLineEditor/ReferenceLineEditor'
import CustomizedReferenceLineLabel from '../CustomizedReferenceLineLabel'
import { formatNumberToString } from '../../../../Utility/NumberFormator'

type LineChartWidgetProps = RowChartWidgetProps

/**
 * Note: The LineChart uses the backend of the RowChart, since it's just a different way of drawing
 * the same data. This is why we reuse most of the RowChart Types here as well as some functions
 *
 */
const LineChartWidget = (props: LineChartWidgetProps) => {
    const [sortKey, setSortKey] = useState('')
    const [sortAsc, setSortAsc] = useState(false)
    const decimals = props.widgetConfiguration.numberOfDecimals
    const stacks = props.widgetData.stacks

    const handleChangeSort = (key: string, asc: boolean) => {
        setSortAsc(asc)
        setSortKey(key)
    }

    const widgetData = sortValues(sortKey, sortAsc, props.widgetData)
    const referenceLines = props.widgetConfiguration.referenceLines

    const groupLabelFn = (dataKey: string) => {
        const stack = stacks.filter((s) => dataKey.includes(s.stackId))
        return stack.length > 0 ? stack[0].label : dataKey.toString()
    }

    const formatLegendEntries = (value: string) => {
        const label = groupLabelFn(value)

        return <span style={{ fontSize: 12 }}>{label}</span>
    }

    return (
        <div className={'widget widget--has-header'}>
            <RowChartWidgetHeader {...props} onChangeSort={handleChangeSort} sortAsc={sortAsc} sortKey={sortKey} />
            <div className={'line-chart-widget'} style={{ width: '100%', height: '100%' }}>
                <ResponsiveTestingAwareChartContainer debounce={200}>
                    <LineChart
                        data={widgetData.chartData}
                        margin={{ bottom: 30, right: 20, top: 10 }}
                        onClick={handleUpdateSelection(props.widgetState, props.setWidgetState)}
                    >
                        <XAxis type="category" dataKey="key" interval={0} tick={<CustomTickForVertical />} />
                        <YAxis type={'number'} tick={<CustomizedTick dy={4} fontSize={12} textAnchor="end" />} />
                        <Tooltip
                            content={
                                <CustomTooltip
                                    stackLabelFn={stackLabelFnBanner(stacks, Boolean(props.widgetData.legend))}
                                    decimals={decimals}
                                />
                            }
                            cursor={{ fill: props.usesDarkMode ? '#222' : '#F6F6F6' }}
                        />
                        {widgetData.stacks.map((stack, stackIndex) => {
                            const keys = stack.dataKeys.map((e) => e.key)
                            return keys.map((dataKey) => {
                                const customColorEntry = widgetData.chartData.find(
                                    (entry) => entry.valueFieldResults[dataKey]?.color
                                )
                                const customColor =
                                    customColorEntry &&
                                    customColorEntry.valueFieldResults[dataKey] &&
                                    customColorEntry.valueFieldResults[dataKey].color

                                const color =
                                    props.widgetState.selection.length > 0
                                        ? '#ccc'
                                        : customColor || COLORS[stackIndex % COLORS.length]

                                return (
                                    <Line
                                        key={dataKey}
                                        dataKey={dataKey}
                                        strokeWidth={3}
                                        fill={color}
                                        stroke={color}
                                        activeDot={{ fill: COLORS[stackIndex % COLORS.length] }}
                                        dot={
                                            <CustomDot
                                                selection={props.widgetState.selection}
                                                activeColor={customColor || COLORS[stackIndex % COLORS.length]}
                                            />
                                        }
                                        label={
                                            <CustomLabel
                                                decimals={props.widgetConfiguration.numberOfDecimals}
                                                chartData={widgetData.chartData}
                                                stackId={stack.stackId}
                                                showPercentage={props.widgetConfiguration.showPercentages}
                                            />
                                        }
                                        isAnimationActive={false}
                                        onClick={handleUpdateSelection(props.widgetState, props.setWidgetState)}
                                    />
                                )
                            })
                        })}

                        {referenceLines &&
                            referenceLines.map((entry: ReferenceLineEntry, index) => {
                                const value = entry.value || undefined
                                const color = entry.color
                                return (
                                    <ReferenceLine
                                        key={`reference-line-${index}`}
                                        x={undefined}
                                        y={value}
                                        label={<CustomizedReferenceLineLabel color={color} label={entry.label} />}
                                        stroke={color}
                                        strokeWidth={2}
                                    />
                                )
                            })}
                        {props.widgetConfiguration.showMultiValueLegend && (
                            <Legend
                                verticalAlign={props.widgetConfiguration.multiValueLegendPosition || 'top'}
                                formatter={formatLegendEntries}
                                wrapperStyle={
                                    props.widgetConfiguration.multiValueLegendPosition === 'bottom'
                                        ? { bottom: 10 }
                                        : { top: 10 }
                                }
                            />
                        )}
                    </LineChart>
                </ResponsiveTestingAwareChartContainer>
            </div>
        </div>
    )
}
LineChartWidget.displayName = 'LineChartWidget'

// custom Dot to control the color: we want the selected dots to be colorful, otherwise gray
const CustomDot = (props: any) => {
    const { cx, cy, stroke, payload } = props
    const hasSelection = props.selection.length > 0
    const isInSelection = hasSelection && Boolean(props.selection.find((k: string) => k === payload.key))
    const color = isInSelection ? props.activeColor : stroke

    return <circle cx={cx} cy={cy} r={props.r} stroke={color} fill={color}></circle>
}

const CustomLabel = (props: any) => {
    const { x, y } = props
    const value = formatNumberToString(props.value, props.decimals === undefined ? 0 : props.decimals)
    const valueFieldResult = props.stackId ? props.chartData[props.index!].valueFieldResults[props.stackId] : null
    const percentage = valueFieldResult?.percentage
    const formattedPercentage = percentage ? formatNumberToString(percentage, props.decimals || 0) : null

    return (
        <text x={x} y={y} dy={-6} fill={'white'} fontSize={10} textAnchor="middle">
            {value} {props.showPercentage && percentage ? '| ' + formattedPercentage + '%' : ''}
        </text>
    )
}

export default LineChartWidget
