import produce from 'immer'
import { put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { AxiosResponse } from 'axios'
import { actions } from '../../../Redux'
import axios from '../../Utility/axios'

export function* RemoveImporterSaga() {
    yield takeLatest(actions.Data.Importer.remove, function* (action) {
        try {
            const importerUuid = action.payload.uuid
            const response: AxiosResponse = yield axios({
                method: 'DELETE',
                url: 'admin/importer/' + importerUuid,
            })

            if (response.status < 400) {
                yield put(push('/importer'))
            } else {
                yield put(actions.UI.Notification.show({ id: 'importerDeleteFailed' }))
            }
        } catch (e) {
            console.error(e)
        }
    })
}

export function* RemoveExtensionSaga() {
    yield takeLatest(actions.Data.Importer.removeExtension, function* (action) {
        try {
            const importer = action.payload.importer
            const extensionIndex = action.payload.index
            yield axios({
                method: 'POST',
                url: 'admin/importer/' + importer.uuid,
                data: produce(importer, (draft) => {
                    draft.postProcessors.splice(extensionIndex, 1)
                }),
            })
        } catch (e) {
            console.error(e)
        }
    })
}
