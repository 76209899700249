import React, { PureComponent } from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import HelpTooltip from './HelpTooltip'
import Icon, { IconNames } from '../../Atoms/Icon/Icon'
import { TooltipId } from '../../../StaticManifests/manifest.tooltips'

type Props = {
    id: TooltipId
    isVisible?: boolean
    customIcon?: {
        name: IconNames
        useOwnColors?: boolean
    }
    openOnHover?: boolean
}

class HelpTooltipIcon extends PureComponent<
    Props,
    {
        isVisible: boolean
    }
> {
    constructor(props: Props) {
        super(props)
        this.state = { isVisible: props.isVisible || false }
    }

    render() {
        return (
            <span className="help-tooltip">
                {this.renderIcon()}
                {this.renderTooltip()}
            </span>
        )
    }

    renderIcon() {
        return (
            <Icon
                name={this.props.customIcon ? this.props.customIcon.name : 'QuestionIcon'}
                size={this.props.customIcon ? undefined : 'small'}
                useOwnColors={this.props.customIcon && this.props.customIcon.useOwnColors}
                onClick={this.handleIsVisibleChanged(!this.state.isVisible)}
                onMouseEnter={this.props.openOnHover ? this.handleIsVisibleChanged(true) : undefined}
                onMouseLeave={this.props.openOnHover ? this.handleIsVisibleChanged(false) : undefined}
            />
        )
    }

    renderTooltip() {
        return (
            <HelpTooltip
                id={this.props.id}
                isVisible={this.state.isVisible}
                isActive={this.state.isVisible}
                onCloseTooltip={this.handleIsVisibleChanged(false)}
            />
        )
    }

    handleIsVisibleChanged = (isVisible: boolean) => () => this.setState({ isVisible })

    handleClickOutside = this.handleIsVisibleChanged(false)
}

export default enhanceWithClickOutside(HelpTooltipIcon)
