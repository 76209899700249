import React, { memo } from 'react'
import { connect } from 'react-redux'
import Icon from '../../../Components/Atoms/Icon/Icon'
import DashboardContextMenu from './DashboardContextMenu'
import { DashboardActions } from './DashboardContextMenu.types'
import Button from '../../../Components/Atoms/Button/Button'
import { AccessMode, DashboardId } from '../../../Redux/Data/types'
import { AppDispatch } from '../../../store'
import { RootState } from '../../../Redux/types'
import { actions, selectors } from '../../../Redux'
import { duplicateDashboard } from '../../../Sagas/DashboardSagas/DuplicateDashboardSaga'
import { getExportDashboardSettingsThenExport } from '../../../Sagas/DashboardSagas/ExportSagas'
import { deleteDashboard } from '../../../Sagas/DashboardSagas/DeleteDashboardSaga'
import { downloadDashboardConfigurationAction } from '../../../Sagas/DashboardSagas/DownloadDashboardConfigurationSaga'

type DashboardControlsOwnProps = {
    dashboardId: DashboardId
    isEmbedded: boolean
}

const mapStateToProps = (state: RootState, ownProps: DashboardControlsOwnProps) => ({
    hasSelection: selectors.Data.Dashboards.ViewMode.dashboardHasSelection(state, ownProps.dashboardId),
    inEditMode: selectors.Data.Dashboards.DashboardEditMode.isDashboardEditModeActive(state),
    applicationMode: selectors.Data.System.applicationMode(state),
    canManageDashboards: Boolean(selectors.UI.CurrentUser.settings(state)?.canManageDashboards),
    canDownloadDashboard:
        selectors.Data.System.accessMode(state) !== AccessMode.SHARED_DASHBOARD ||
        Boolean(
            selectors.Data.Dashboards.ShareDashboard.getSharedDashboardByDashboardId(state, ownProps.dashboardId)
                ?.isDownloadAllowed
        ),
})

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: DashboardControlsOwnProps) => ({
    onResetDashboardStateClick: () =>
        dispatch(actions.Data.Dashboards.ResetDashboardState.resetDashboardState(ownProps.dashboardId)),
    enableEditMode: () => dispatch(actions.Data.Dashboards.DashboardEditMode.enableEditMode(ownProps.dashboardId)),
    onDuplicateDashboard: () =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'dashboardDuplicate',
                parameters: {
                    showNavigate: true,
                },
                confirmationCallback: ({ dashboardName, navigate }: { dashboardName: string; navigate: boolean }) => {
                    dispatch(duplicateDashboard(ownProps.dashboardId, dashboardName, navigate))
                },
            })
        ),
    onExportDashboard: (format: string, callback: any) =>
        dispatch(getExportDashboardSettingsThenExport(format, ownProps.dashboardId, callback)),
    onDeleteDashboard: () =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'dashboardDelete',
                confirmationCallback: () => dispatch(deleteDashboard(ownProps.dashboardId)),
            })
        ),
    onDownloadDashboardConfiguration: () => dispatch(downloadDashboardConfigurationAction(ownProps.dashboardId)),
    onShareDashboard: () =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'dashboardShare',
                parameters: {
                    dashboardId: ownProps.dashboardId,
                },
            })
        ),
})

type DashboardControlsProps = DashboardControlsOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const DashboardControls: React.FC<DashboardControlsProps> = (props) => {
    const hasAction = (action: DashboardActions) => {
        switch (action) {
            case DashboardActions.RESET:
            case DashboardActions.EXPORT:
                return !props.inEditMode
            case DashboardActions.DELETE:
                return !props.isEmbedded && props.canManageDashboards
            case DashboardActions.EDIT:
            case DashboardActions.DUPLICATE:
            case DashboardActions.SHARE:
                return !props.inEditMode && props.canManageDashboards
            case DashboardActions.DOWNLOAD_CONFIGURATION:
                return !props.inEditMode && props.canDownloadDashboard
            default:
                return false
        }
    }

    return (
        <div className="dashboard__header-right-area secondary-header__options">
            <div className="dashboard__header--quick-controls">
                {hasAction(DashboardActions.RESET) && (
                    <Icon
                        label="Reset selection"
                        name="UndoIcon"
                        title="Reset selection"
                        onClick={props.onResetDashboardStateClick}
                        isDisabled={!props.hasSelection}
                    />
                )}
                {hasAction(DashboardActions.EDIT) && (
                    <Button onClick={props.enableEditMode} type="orange" className="dashboard__header--edit-dashboard">
                        <Icon label="Edit" color="white" name="PenIcon" title="Edit Dashboard" />
                    </Button>
                )}
            </div>
            <DashboardContextMenu
                hasAction={hasAction}
                onDashboardDuplicate={props.onDuplicateDashboard}
                onDashboardDeletion={props.onDeleteDashboard}
                onDashboardExport={props.onExportDashboard}
                onDownloadDashboardConfiguration={props.onDownloadDashboardConfiguration}
                onShareDashboard={props.onShareDashboard}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DashboardControls))
