import React from 'react'

import ImporterFieldMappingForm from './ImporterFieldMappingForm'

const ImporterFieldMappingEditor: React.FC<Props> = (props) => (
    <div className="importer-field-mapping-editor">
        <ImporterFieldMappingForm value={props.value} onChange={props.onChange} />
    </div>
)

export type Mapping = {
    defaultValue?: string | number | null
    sourceFormat?: string | null
    sourceName: string
    targetLabel: string | null
    targetName: string
    targetType: string
    isActive: boolean
    isConfirmed: boolean
}

export type FieldMappings = {
    documentType: string
    mappings: Array<Mapping>
}

type Props = {
    value: FieldMappings
    onChange: (newValue: any) => void
    generateSuggestion: (language: any, callback: (suggestion: any) => void) => void
}

export default ImporterFieldMappingEditor
