import { UserSettings } from './Redux/Data/UserManagement'

export const DashboardsRoute = '/dashboard'
export const EmbeddedDashboardRoute = '/embedded-dashboard'
export const SharedDashboardRoute = '/shared-dashboard'

export const Routes: RoutesType = {
    jiraSettings: {
        path: '/jira-settings',
        rights: [],
    },
    importer: {
        path: '/importer',
        rights: ['isImporterManager', 'isSystemManager', 'canManageImporters'],
    },
    userManagement: {
        path: '/user-management',
        rights: ['isUserManager', 'isSystemManager'],
    },
    typesUiConfiguration: {
        path: '/types-ui-configuration',
        rights: [],
    },
    configurationSharing: {
        path: '/configuration-sharing',
        rights: [],
    },
    systemInformation: {
        path: '/system-information',
        rights: [],
    },
    colorConfiguration: {
        path: '/color-configuration',
        rights: ['canManageCustomColors'],
    },
    sharedDashboardsOverview: {
        path: '/shared-dashboards-overview',
        rights: ['isSystemManager', 'canManageDashboards'],
    },
}

export const hasAccessToRoute = (route: RouteKeys, userSettings: UserSettings | undefined) => {
    return (
        userSettings?.isAdmin || Routes[route].rights.some((requirement) => userSettings && userSettings[requirement])
    )
}

type RoutesType = {
    [route in RouteKeys]: {
        path: string
        rights: Array<keyof UserSettings>
    }
}

export type RouteKeys =
    | 'jiraSettings'
    | 'importer'
    | 'userManagement'
    | 'typesUiConfiguration'
    | 'configurationSharing'
    | 'systemInformation'
    | 'colorConfiguration'
    | 'sharedDashboardsOverview'
