import React, { useContext } from 'react'
import classNames from 'classnames'
import { Mapping } from './ImporterFieldMappingEditor'
import { fieldTypes, FieldTypesByTag } from './ImporterFieldMapping.types'
import NumberPicker from '../../Atoms/NumberPicker/NumberPicker'
import TextInput from '../../Atoms/TextInput/TextInput'
import Switch from '../../Atoms/Switch/Switch'
import Select, { CreatableSelect } from '../../Atoms/Select/Select'
import Button from '../../Atoms/Button/Button'
import { FieldConfiguration } from '../../Atoms/FieldsConfiguration/FieldsConfigurationTypes'
import { FieldsConfigurationContext } from '../../Atoms/FieldsConfiguration/FieldsConfigurationContext'

const targetTypeByFieldTag: FieldTypesByTag = fieldTypes
    .map((t) => ({ id: t.id, tag: t.definition.tag }))
    .reduce((acc, curr) => ({ ...acc, [curr.tag]: curr.id }), {})

function asString(value?: string | number | null): string {
    if (value !== null && value !== undefined) {
        return `${value}`
    }

    return ''
}

/**
 * This component represents a single column in the Mapping Step of a Data Source. It renders the editors to adjust the
 * mapping of field (is it a value, category, date...)
 */
const SingleMapping: React.FC<{
    mapping: Mapping
    onChange: (
        propertyName: keyof Mapping,
        resets?: Partial<Mapping>
    ) => (value: string | number | boolean | null) => void
}> = (props) => {
    const fieldsConfiguration = useContext(FieldsConfigurationContext)
    const sourceName = props.mapping.sourceName
    const targetName = props.mapping.targetName
    const isActive = props.mapping.isActive
    const isConfirmed = props.mapping.isConfirmed

    const targetNameOptions: Array<{
        value: string
        label: string
        type: string
    }> = Object.values(fieldsConfiguration).map((t: FieldConfiguration) => ({
        value: t.name,
        label: t.label,
        type: targetTypeByFieldTag[t.tag],
    }))
    // Predefined means that this Field has already been defined by another importer
    const predefinedTargetField = Boolean(targetName) && targetNameOptions.find((t) => t.value === targetName)

    const handleToggle = () => {
        if (!isConfirmed) {
            props.onChange('isConfirmed')(true)
        } else if (isActive) {
            props.onChange('isActive')(false)
        } else {
            props.onChange('isActive')(true)
        }
    }

    const confirmOnChange = () => {
        if (!isConfirmed) {
            props.onChange('isConfirmed')(true)
        }
    }

    const handleTargetFieldChanged = (labelOrId: string) => {
        const existing = targetNameOptions.find((t) => t.value === labelOrId)
        if (existing) {
            // existing field
            props.onChange('targetLabel', { targetName: existing.value, targetType: existing.type })(existing.label)
        } else {
            // new custom field
            props.onChange('targetLabel', { targetName: labelOrId })(labelOrId)
        }
    }

    const handleConfirm = () => {
        props.onChange('isConfirmed')(true)
    }

    const targetType =
        (predefinedTargetField && fieldTypes.find((t) => t.id === predefinedTargetField.type)) ||
        fieldTypes.find((t) => t.id === props.mapping.targetType)

    const renderDefaultValue = (isActive: boolean) => {
        if (targetType && targetType.id === 'NUMBER') {
            return (
                <NumberPicker
                    value={!isActive ? undefined : asString(props.mapping.defaultValue)}
                    placeholder="(empty value)"
                    disabled={!isActive}
                    onChange={props.onChange('defaultValue')}
                    onFocus={confirmOnChange}
                    useSelectHeight
                />
            )
        }

        const allowDefaultValue = targetType && targetType.id === 'STRING'

        return (
            <TextInput
                value={!isActive ? undefined : asString(props.mapping.defaultValue)}
                placeholder="(empty value)"
                disabled={!isActive || !allowDefaultValue}
                onChange={props.onChange('defaultValue')}
                onFocus={confirmOnChange}
                useSelectHeight
            />
        )
    }

    const className = classNames('importer-field-mapping__single ', {
        'importer-field-mapping__single--disabled': !isActive,
        'importer-field-mapping__single--inactive': !isConfirmed,
    })

    return (
        <div className="importer-field-mapping__single--wrapper">
            <div className={className}>
                <div className="importer-field-mapping__single__controls--and--source">
                    <div className="importer-field-mapping__single__source">{sourceName}</div>
                    <div className="importer-field-mapping__single__controls">
                        <Switch value={isActive && isConfirmed} onChange={handleToggle} />
                    </div>
                </div>
                <div className="importer-field-mapping__single__target importer-field-mapping__single__select">
                    <CreatableSelect
                        value={props.mapping.targetLabel || props.mapping.targetName}
                        options={targetNameOptions}
                        placeholder="skipped (select a target to change that)"
                        isClearable
                        isDisabled={!isActive}
                        onChange={handleTargetFieldChanged}
                        onMenuOpen={confirmOnChange}
                        menuPortalTarget={document.getElementById('root')}
                        sortOptionsAlphabetically
                    />
                </div>
                <div className="importer-field-mapping__single__type importer-field-mapping__single__select">
                    <Select
                        isDisabled={!isActive || Boolean(predefinedTargetField)}
                        value={isActive && targetType && targetType.definition.value}
                        placeholder="none"
                        options={fieldTypes.map((t) => ({ value: t.id, label: t.definition.label }))}
                        onMenuOpen={confirmOnChange}
                        menuPortalTarget={document.getElementById('root')}
                        onChange={props.onChange('targetType', { sourceFormat: null })}
                    />
                </div>
                <div className="importer-field-mapping__single__format importer-field-mapping__single__select">
                    <CreatableSelect
                        value={isActive && props.mapping.sourceFormat}
                        options={targetType && targetType.definition.formats ? targetType.definition.formats : []}
                        placeholder="auto"
                        isClearable
                        isDisabled={!isActive || Boolean(!(targetType && targetType.definition.formats))}
                        onMenuOpen={confirmOnChange}
                        closeMenuOnSelect={false}
                        menuPortalTarget={document.getElementById('root')}
                        onChange={props.onChange('sourceFormat')}
                    />
                </div>
                <div className="importer-field-mapping__single__default-value importer-field-mapping__single__select">
                    {renderDefaultValue(isActive)}
                </div>
            </div>
            {!isConfirmed && (
                <Button onClick={handleConfirm} type="primary">
                    Confirm
                </Button>
            )}
        </div>
    )
}

export default SingleMapping
