import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import SettingsView from '../../../Components/Organisms/SettingsView/SettingsView'
import WidgetTypeSelector from '../../../Components/Molecules/WidgetTypeSelector/WidgetTypeSelector'
import Button from '../../../Components/Atoms/Button/Button'
import SecondaryFooter from '../../../Components/Molecules/SecondaryFooter/SecondaryFooter'
import WidgetGroupArea from '../Dashboard/WidgetGroupsArea'
import WidgetPreview from './WidgetPreview/WidgetPreview'
import WidgetEditorForm from '../../../Components/Organisms/WidgetEditorForm/WidgetEditorForm'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { actions, selectors } from '../../../Redux'
import {
    startChangingWidgetConfiguration,
    startChangingWidgetTypeAction,
} from '../../../Sagas/DashboardSagas/WidgetEditModeSagas'

const mapStateToProps = (state: RootState) => ({
    widgetConfiguration: selectors.Data.Dashboards.currentlyEditedWidgetConfig(state),
    dashboardId: selectors.Data.Dashboards.currentlyEditedDashboardId(state),
    widgetFeatureFlags: selectors.Data.FeatureFlags.widgetFeatureFlags(state),
    hasJiraConfiguration: selectors.Data.FeatureFlags.jiraConfigurationFlag(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onChangeWidgetType: (newType: string) => dispatch(startChangingWidgetTypeAction(newType)),
    onSaveWidgetAndCloseEditor: () => {
        dispatch(actions.Data.Dashboards.WidgetEditMode.finishEditingWidget())
    },
    onTriggerAutoCompletion: (type: any, value: any, exclude: any, callback: any) =>
        dispatch(actions.UI.Autocompletion.fetch(type, value, exclude, callback)),
    onChangedWidgetConfiguration: (propertyName: string, value: any) =>
        dispatch(startChangingWidgetConfiguration(propertyName, value)),
})

type AdvancedWidgetEditorProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        onCloseAdvancedEditor: () => void
    }

type AdvancedWidgetEditorState = {
    previewDashboard: boolean
}

class AdvancedWidgetEditor extends PureComponent<AdvancedWidgetEditorProps, AdvancedWidgetEditorState> {
    constructor(props: AdvancedWidgetEditorProps) {
        super(props)

        this.state = {
            previewDashboard: false,
        }
    }

    handleToggleShowPreviewDashboard = () => {
        this.setState({
            previewDashboard: !this.state.previewDashboard,
        })
    }

    render() {
        if (this.props.widgetConfiguration === undefined) {
            return null
        }

        return (
            <>
                <SettingsView
                    className="widget-advanced-config__settings-view"
                    main={
                        <>
                            <WidgetTypeSelector
                                widgetFeatureFlags={this.props.widgetFeatureFlags}
                                selectedType={this.props.widgetConfiguration.type}
                                onChange={this.props.onChangeWidgetType}
                            />
                            {this.renderWidgetEditors('data', false)}
                        </>
                    }
                    aside={this.renderPreview()}
                />
                {this.renderFooter()}
            </>
        )
    }

    renderWidgetEditors = (configurationPath: 'data' | 'bottomTabs', isTabView: boolean) => (
        <WidgetEditorForm
            editorPath={configurationPath}
            isTabView={isTabView}
            widget={this.props.widgetConfiguration!}
            onChange={this.props.onChangedWidgetConfiguration}
            onTriggerAutoCompletion={this.props.onTriggerAutoCompletion}
            hasJiraConfiguration={this.props.hasJiraConfiguration}
        />
    )

    renderPreview = () => (
        <SettingsView
            verticalOrientation={true}
            className="widget-advanced-config-preview__settings-view"
            main={
                <div className="widget-advanced-config-preview">
                    <Button onClick={this.handleToggleShowPreviewDashboard} className="switch-preview-button">
                        {this.state.previewDashboard ? 'Show Widget Preview' : 'Show Dashboard Preview'}
                    </Button>
                    {this.state.previewDashboard ? (
                        <WidgetGroupArea dashboardId={this.props.dashboardId!} isPreview />
                    ) : (
                        <WidgetPreview />
                    )}
                </div>
            }
            aside={this.renderWidgetEditors('bottomTabs', true)}
        />
    )

    renderFooter = () => (
        <SecondaryFooter
            right={
                <div>
                    <Button onClick={this.props.onCloseAdvancedEditor}>Cancel</Button>
                    <Button onClick={this.props.onSaveWidgetAndCloseEditor} type="primary">
                        Save
                    </Button>
                </div>
            }
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedWidgetEditor)
