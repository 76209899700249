import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Collapse } from 'react-collapse'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import classnames from 'classnames'
import Draggable from 'react-draggable'
import Icon from '../../../Components/Atoms/Icon/Icon'
import { actions, selectors } from '../../../Redux'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import { Notification } from '../../../Redux/UI/Notification'
import notifications, { NotificationConfiguration } from '../../../StaticManifests/manifest.notifications'
import { switchUserBack } from '../../../Sagas/UserManagementSagas/SwitchUserBackSaga'

type NotificationContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const NotificationContainer: React.FC<NotificationContainerProps> = (props) => {
    const [isDetailsOpen, setIsDetailsOpen] = useState(props.currentNotification?.isDetailsInitiallyOpen || false)

    useEffect(() => {
        if (props.currentNotification?.isDetailsInitiallyOpen) {
            setIsDetailsOpen(true)
        }
    }, [props.currentNotification?.isDetailsInitiallyOpen])

    const renderNotification = (currentNotification: Notification) => {
        if (!currentNotification) {
            return null
        }

        const currentNotificationId = currentNotification.id
        const currentNotificationDetails = currentNotification.details || ''
        const currentNotificationConfiguration: NotificationConfiguration = notifications[currentNotification.id]

        const className = classnames('notification', 'notification--' + currentNotificationConfiguration.type, {
            'notification--big': isDetailsOpen,
        })

        const timeout = currentNotification.timeout

        if (timeout) {
            setTimeout(props.onDismissClick, timeout)
        }

        return (
            <CSSTransition classNames="notification-" timeout={{ exit: 150, enter: 350 }}>
                <Draggable handle=".notification__header">
                    <div key={currentNotificationId} className={className}>
                        <header className="notification__header">
                            {currentNotificationConfiguration ? currentNotificationConfiguration.title : ''}
                            {props.isSwitchUserModeEnabled ? null : (
                                <Icon
                                    onClick={props.onDismissClick}
                                    className="notification__dismiss"
                                    name="TimesIcon"
                                    title="dismiss notification"
                                />
                            )}
                        </header>
                        <div className="notification__message">
                            {currentNotificationConfiguration && currentNotificationConfiguration.message
                                ? currentNotificationConfiguration.message
                                : ''}
                            {currentNotificationDetails ? (
                                <button
                                    className="notification__details-toggle"
                                    onClick={() => setIsDetailsOpen(!isDetailsOpen)}
                                >
                                    Details
                                </button>
                            ) : null}
                        </div>
                        {currentNotificationDetails ? (
                            <Collapse isOpened={isDetailsOpen}>
                                <div className="notification__details">
                                    <div
                                        className="notification__details-inner"
                                        // use dangerously... to allow markup in the notification details (like links)
                                        dangerouslySetInnerHTML={{ __html: currentNotificationDetails }}
                                    />
                                </div>
                            </Collapse>
                        ) : null}
                        {props.isSwitchUserModeEnabled && (
                            <Icon name="UndoIcon" label="Switch back" onClick={props.onSwitchBackClick} />
                        )}
                    </div>
                </Draggable>
            </CSSTransition>
        )
    }

    if (props.currentNotification) {
        return <TransitionGroup>{renderNotification(props.currentNotification)}</TransitionGroup>
    }

    if (props.isSwitchUserModeEnabled) {
        return (
            <TransitionGroup>
                {renderNotification({
                    id: 'userOrGroupSwitchBack',
                    isDetailsInitiallyOpen: true,
                })}
            </TransitionGroup>
        )
    }

    return null
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onDismissClick: () => dispatch(actions.UI.Notification.dismiss()),
    onSwitchBackClick: () => dispatch(switchUserBack()),
})

const mapStateToProps = (state: RootState) => {
    return {
        currentNotification: selectors.UI.Notification.currentNotification(state),
        isSwitchUserModeEnabled: selectors.UI.Notification.isSwitchUserModeEnabled(state),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NotificationContainer))
