import { connect } from 'react-redux'
import React, { memo, useMemo, useState } from 'react'
import { DragDropContext, DragStart, Droppable, DropResult } from 'react-beautiful-dnd'
import { RootState } from '../../../Redux/types'
import { actions, selectors } from '../../../Redux'
import DashboardOverviewHeader from './DashboardOverviewHeader'
import DashboardGroup from '../DashboardGroup/DashboardGroup'
import { AppDispatch } from '../../../store'
import { DashboardGroupId } from '../../../Redux/UI/types'
import { DashboardId } from '../../../Redux/Data/types'
import { ALL_DASHBOARDS, FAVOURITE_DASHOARDS } from '../../../Redux/UI/DashboardGroups'
import FixedDashboardGroup from '../DashboardGroup/FixedDasbhoardGroup'

const mapStateToProps = (state: RootState) => ({
    allDashboards: selectors.Data.Dashboards.allDashboards(state),
    favouriteDashboardIds: selectors.UI.FavouriteDashboards.list(state),
    allDashboardGroupIds: selectors.UI.DashbardGroups.allDashboardGroupIds(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    moveDashboardFromGroupToGroup: (
        sourceGroup: DashboardGroupId,
        destinationGroup: DashboardGroupId,
        dashboardId: DashboardId
    ) =>
        dispatch(
            actions.UI.DashboardGroups.moveDashboardFromGroupToGroup({ sourceGroup, destinationGroup, dashboardId })
        ),
    moveDashboardGroup: (sourceIndex: number, destinationIndex: number) =>
        dispatch(actions.UI.DashboardGroups.moveDashboardGroup({ sourceIndex, destinationIndex })),
})

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const DashboardOverview = (props: Props) => {
    const [titleFilter, setTitleFilter] = useState('')
    const handleClearFilter = () => {
        setTitleFilter('')
    }
    const [draggedItem, setDraggedItem] = useState<DragStart | null>(null)

    const onDragStart = (item: DragStart) => setDraggedItem(item)

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result
        if (!destination) {
            return
        }

        if (type === 'dashboard-group') {
            props.moveDashboardGroup(source.index, destination.index)
        } else if (type === 'dashboard') {
            // draggableId is groupId#dashboardId to differ between same dashboard in multiple groups
            const dashboardId = draggableId.split('#')[1]
            props.moveDashboardFromGroupToGroup(source.droppableId, destination.droppableId, dashboardId)
        }

        setDraggedItem(null)
    }

    const favouriteDashboards = useMemo(
        () => props.allDashboards.filter((dashboard) => props.favouriteDashboardIds.includes(dashboard.id)),
        [props.allDashboards, props.favouriteDashboardIds]
    )

    return (
        <div className="dashboard-overview" tabIndex={0}>
            <DashboardOverviewHeader
                handleClearFilter={handleClearFilter}
                titleFilter={titleFilter}
                setTitleFilter={setTitleFilter}
            />
            <div className="dashboard-overview__content">
                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                    {favouriteDashboards.length > 0 && (
                        <FixedDashboardGroup
                            dashboards={favouriteDashboards}
                            groupTitle={'Favourite Dashboards'}
                            iconName={'HeartFilledIcon'}
                            isDragDisabled
                            titleFilter={titleFilter}
                            id={FAVOURITE_DASHOARDS}
                        />
                    )}
                    <Droppable droppableId="dashboard-groups--drop-zone" type="dashboard-group">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {/*
                                 * reverse the list to render newest-to-oldest from top-to-bottom
                                 */}
                                {[...props.allDashboardGroupIds].reverse().map((id, index) => (
                                    <DashboardGroup
                                        titleFilter={titleFilter}
                                        id={id}
                                        key={'dashboard-group' + id}
                                        index={index}
                                        draggedItem={draggedItem}
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <FixedDashboardGroup
                        dashboards={props.allDashboards}
                        groupTitle={'All Dashboards'}
                        iconName={'DashboardIcon'}
                        titleFilter={titleFilter}
                        id={ALL_DASHBOARDS}
                    />
                </DragDropContext>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DashboardOverview))
