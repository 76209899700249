import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, JiraProject } from './types'
import { actionTypes as system } from '../System'
import { RootState } from '../types'

const jiraConfigurationSlice = createSlice({
    name: 'Data/JiraConfiguration',
    initialState: {} as DataState,
    extraReducers: {
        [system.INIT]: (state) => {
            state.jira = {}
        },
    },

    reducers: {
        // todo move into SAGA since this doesnt do anything here
        loadAvailableProjects: () => {},
        loadedAvailableProjects: {
            prepare: (projects: string[] | JiraProject[]) => ({ payload: { projects } }),
            reducer: (state, action: PayloadAction<{ projects: string[] | JiraProject[] }>) => {
                state.jira.availableProjects = action.payload.projects.map((project) =>
                    typeof project === 'string' ? { name: project } : project
                )
            },
        },
    },
})

export const reducer = jiraConfigurationSlice.reducer

export const actions = {
    ...jiraConfigurationSlice.actions,
}

export const selectors = {
    availableProjects: (state: RootState) => state.Data.jira.availableProjects,
}
