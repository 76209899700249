import React from 'react'
import ApplicationHeader from '../../../Containers/ApplicationHeader/ApplicationHeader'
import { Page, PageSection } from '../Page/Page'

const PrivacyStatement: React.FC<unknown> = () => {
    const header = <ApplicationHeader pageTitle="Privacy Statement" />

    return (
        <Page header={header}>
            <PageSection>
                <p>
                    Sandstorm Media GmbH takes the protection of your personal data very seriously. We treat your
                    personal data confidentially and in accordance with the legal data privacy laws as well as this
                    privacy policy. Personal data are information like name, address, phone number or email address
                    which can be matched to you.
                </p>
                <p>Your data will not be shared with third parties without your explicit consent.</p>
                <p>
                    We advise you, that data transmission via the internet (e.g. in communication via email) can contain
                    security vulnerabilities. A complete protection of your data from access through third parties is
                    not possible.
                </p>

                <h4>Inventory Data</h4>

                <p>
                    Your personal data, insofar necessary for the substantiation of, for the definition of the content
                    of or to change the contractual relationship, will only be used to fulfil the contract. To deliver
                    the good(s) to you, we have to use e.g. your name and email address.
                </p>
                <p>
                    Without your explicit consent or without a lawful basis we will not share your data with third
                    parties that are not involved in the execution of the contract. After complete execution of the
                    contract your data will be blocked from further use. At the end of the period required by tax and
                    business law your data will be deleted unless you have given your consent for further use of your
                    data.
                </p>

                <h4>Disclosure, Deletion, Blocking</h4>

                <p>
                    You can get information about the extent and purpose of our data processing at any time. You can
                    request this information from{' '}
                    <a href="mailto:datenschutz@sandstorm-media.de">datenschutz@sandstorm-media.de</a>. If you have
                    explicitly agreed to our use or sharing of your data you can revoke this at any time at{' '}
                    <a href="mailto:datenschutz@sandstorm-media.de">datenschutz@sandstorm-media.de</a>. We will
                    furthermore correct, block or delete data as requested by you unless they are necessary for us to
                    fulfil our contractual obligations or required by law. Just contact us at{' '}
                    <a
                        href="
                    mailto:datenschutz@sandstorm-media.de"
                    >
                        datenschutz@sandstorm-media.de
                    </a>
                    .
                </p>

                <h4>Security for online purchases - SSL encryption</h4>

                <p>To protect you - our customer - we only use certified encryption technologies.</p>
                <p>
                    Your personal data are transferred encrypted (= not readable by third parties) via SSL (Secure
                    Socket Layer) technology.
                </p>
                <p>
                    This way you can be sure, that your personal data like name or address are transferred to{' '}
                    <b>our server</b> only.
                </p>

                <h4>What does SSL encryption mean?</h4>

                <p>
                    As soon as you open our SSL encrypted website your browser verifies our SSL certificate. With this
                    information your browser can check if it is connected to the server you entered. Is this check
                    successful, your data are transmitted securely through the internet.
                </p>

                <h4>How do I recognize SSL encryption?</h4>
                <p>
                    We use SSL encryption for all situations where sensitive data is entered, e.g. the order process.
                    While you are connected to our website a lock symbol is shown in the address bar of your browser.
                </p>
                <p>
                    Furthermore the URL changes from "http" to "https". These signs show that your personal data cannot
                    be accessed by third parties.
                </p>

                <h4>Secure Payment</h4>

                <p>
                    You do not take any risk when paying for your ordered items. Sandstorm Media GmbH transfers your
                    data, your contact information as well as the ordered items to our partners{' '}
                    <a href="https://www.braintreepayments.com/">Braintree</a> and{' '}
                    <a
                        href="
                    https://www.taxamo.com/"
                    >
                        Taxamo
                    </a>{' '}
                    in separate, especially encrypted forms. You are automatically redirected to the security-servers of
                    our partners where you enter the required payment details.
                </p>

                <h4>Server Log-Files</h4>
                <p>
                    We automatically collect and save information in so-called server log-files which are automatically
                    transmitted to us from your browser. This information is:
                </p>
                <ul>
                    <li>browser type and version</li>
                    <li>operating system</li>
                    <li>referrer URL</li>
                    <li>hostname of the accessing computer</li>
                    <li>date and time of the request</li>
                </ul>
                <p>
                    This data cannot be matched to individuals. This data is not brought together with data from other
                    sources. We reserve the right to inspect this data when we have concrete indications for unlawful
                    use.
                </p>

                <h4>Cookies</h4>

                <p>
                    Our website uses so-called cookies. Cookies do not harm your computer and do not contain viruses.
                    Cookies are used to make our services easier to use, more effective and more secure. Cookies are
                    small text files which are saved on your computer by your browser.
                </p>
                <p>
                    Most of the cookies we use are so-called "session cookies". They are automatically deleted after you
                    leave our website. Other cookies will remain on your computer until you delete them. These cookies
                    allow us to recognize your browser when you visit our website again.
                </p>
                <p>
                    You can configure your browser in a way that you are informed when a cookie is set and only allow
                    cookies on an individual basis or you accept cookies only in particular cases or disallow them
                    generally or configure your browser to automatically delete cookies when it is closed. When you
                    deactivate cookies the functionality of this website may be reduced.
                </p>

                <h4>Piwik</h4>

                <p>
                    This service uses Piwik, an open source software to statistically analyse user accesses. Piwik uses
                    so-called "cookies", text files which are saved to your computer which allow the analysis of the
                    user's website use. The information about the use of our services which are created by this cookie
                    are saved on our server in Germany. The IP-address is anonymized immediately after processing and
                    before it is saved. You can block the setting of the cookie by configuring your browser to block it.
                    Sandstorm Media GmbH advises you that not all functionality of this website may be available in this
                    case.
                </p>
                <p>
                    By clicking this{' '}
                    <a href="https://piwik.sandstorm.de/index.php?module=CoreAdminHome&action=optOut&language=en">
                        link
                    </a>
                    , you can set an opt-out cookie whereby your visit will no longer be tracked.
                </p>

                <h4>Objection to Promotional Emails</h4>

                <p>
                    We object to the use of the published information in our legally required imprint for the sending of
                    not explicitly requested promotions and information material. The operator of this website
                    explicitly reserves the right to use legal measures in case of being sent unsolicited promotional
                    material such as spam emails.
                </p>

                <h4>Contact</h4>

                <p>
                    For any questions relating to data privacy, requests for disclosure or changes please contact us at{' '}
                    <a
                        href="
                    mailto:datenschutz@sandstorm-media.de"
                    >
                        datenschutz@sandstorm-media.de
                    </a>
                    .
                </p>
            </PageSection>
        </Page>
    )
}

export default PrivacyStatement
