import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import enhanceWithClickOutside from 'react-click-outside'
import { goBack } from 'connected-react-router'
import { actions, selectors } from '../../Redux'

import Loader from '../../Components/Atoms/Loader/Loader'
import FieldsCustomizationEntryContainer from './FieldsCustomizationEntryContainer'
import SecondaryFooter from '../../Components/Molecules/SecondaryFooter/SecondaryFooter'
import containsGivenObject from '../../Utility/HandleClickOutside'
import { AppDispatch } from '../../store'
import { RootState } from '../../Redux/types'
import ButtonWithTooltip from '../../Components/Molecules/HoverTooltip/ButtonWithTooltip'

const mapStateToProps = (state: RootState) => {
    return {
        fieldIdentifiers: selectors.Data.FieldsCustomization.makeAllIdentifiers(state),
        isLoaded: selectors.Data.FieldsCustomization.isLoaded(state),
        isSaving: selectors.Data.FieldsCustomization.isSaving(state),
        hasTypeConfigurationChanged: selectors.Data.FieldsCustomization.hasTypeConfigurationChanged(state),
        isConfigurationEqualToDefaultConfig:
            selectors.Data.FieldsCustomization.isConfigurationEqualToDefaultConfig(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    loadInitialData: () => dispatch(actions.Data.FieldsCustomization.loadInitialData()),
    onUnsavedLeave: (event: React.MouseEvent) =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'leaveUnsaved',
                confirmationCallback: () => {
                    dispatch(actions.Data.FieldsCustomization.saveConfiguration())
                    // @ts-ignore click does not exist?
                    event.target.click()
                    dispatch(actions.UI.Menu.toggleMenuVisibility())
                },
                denyCallback: () => {
                    // @ts-ignore click does not exist?
                    event.target.click()
                    dispatch(actions.UI.Menu.toggleMenuVisibility())
                },
            })
        ),
    resetConfiguration: () => dispatch(actions.Data.FieldsCustomization.resetConfiguration()),
    saveConfiguration: () => dispatch(actions.Data.FieldsCustomization.saveConfiguration()),
    goBack: () => dispatch(goBack()),
})

type FieldsCustomizationContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class FieldsCustomizationContainer extends PureComponent<FieldsCustomizationContainerProps> {
    constructor(props: FieldsCustomizationContainerProps) {
        super(props)
        props.loadInitialData()
    }

    render() {
        if (this.props.isLoaded) {
            return this.renderComponent()
        }

        return <Loader />
    }

    handleClickOutside = (event: React.MouseEvent) => {
        // catch untrusted events because they are sent by this function
        if (
            containsGivenObject(event, ['row-menu__item', 'application-header__logo']) &&
            this.props.hasTypeConfigurationChanged &&
            event.isTrusted
        ) {
            event.stopPropagation()
            event.preventDefault()

            this.props.onUnsavedLeave(event)
        }
    }

    renderComponent = () => {
        return (
            <div className="type-ui-configuration-container">
                <div className="fields--headline">
                    <h2>Fields</h2>
                    <p className="fields--headline__id">id</p>
                    <p className="fields--headline__label">label</p>
                    <p className="fields--headline__type">type</p>
                    <p className="fields--headline__count">count</p>
                </div>

                <div className="type-ui-configuration-container--typeList type-ui-configuration-container--fields">
                    {this.renderFields()}
                </div>
                <SecondaryFooter left={this.renderResetButton()} right={this.renderCancelSaveButton()} />
            </div>
        )
    }

    handleReset = () => this.props.resetConfiguration()

    handleSave = () => this.props.saveConfiguration()

    handleCancel = () => this.props.goBack()

    renderFields = () => {
        return this.props.fieldIdentifiers.map((fieldIdentifier) => (
            <FieldsCustomizationEntryContainer key={fieldIdentifier} fieldIdentifier={fieldIdentifier} />
        ))
    }

    renderResetButton = () => {
        return (
            <ButtonWithTooltip
                isDisabled={this.props.isSaving || this.props.isConfigurationEqualToDefaultConfig || false}
                header="No changes to reset"
                type="primary-warning"
                onClick={this.handleReset}
            >
                Reset
            </ButtonWithTooltip>
        )
    }

    renderCancelSaveButton = () => {
        return (
            <React.Fragment>
                <ButtonWithTooltip
                    isDisabled={this.props.isSaving || false}
                    header="Is saving"
                    onClick={this.handleCancel}
                >
                    Cancel
                </ButtonWithTooltip>
                <ButtonWithTooltip
                    isDisabled={this.props.isSaving || !this.props.hasTypeConfigurationChanged}
                    header="No changes to save"
                    type="primary"
                    onClick={this.handleSave}
                >
                    Save
                </ButtonWithTooltip>
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.props.loadInitialData()
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(enhanceWithClickOutside(FieldsCustomizationContainer))
