import './Fonts/Lato/latofonts.css'
import './Fonts/SourceCodePro/stylesheet.css'

import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import { actions } from './Redux'

import Application from './Containers/Application'
import sagas from './Sagas'

import './Sass/main.scss'
import ScrollToTop from './Containers/ScrollToTop'
import FieldsConfigurationProvider from './Containers/FieldsConfigurationProvider/FieldsConfigurationProvider'
import 'react-hot-loader/patch'

import store, { history, sagaMiddleware } from './store'

declare global {
    interface Window {
        initialState: any
    }
}

document.addEventListener('DOMContentLoaded', () => {
    if (!window.initialState) {
        // Error message for Exply in Jira Cloud when using Safari, where Exply doesn't work because of the strict cookie policy
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            // eslint-disable-next-line no-alert
            alert(
                "We're currently unable to run Exply in Safari - as a workaround you can disable Safaris' 'Prevent Cross Site Tracking' Setting or open Exply in another browser. Our Team is working on a fix in the meantime."
            )
        }

        const errorContainer = document.createElement('p')
        errorContainer.innerHTML =
            'Something went wrong when loading Exply. Please try to reload the page. If the problem persists, please contact our support team at <a href="mailto:support@exply.io">support@exply.io</a>.'
        document.body.appendChild(errorContainer)

        throw Error('Frontend not connected to backend! Make sure you are logged in!')
    }

    const initialState = window.initialState
    delete window.initialState

    // eslint-disable-next-line no-console
    console.info('exply-core version', initialState.system.version)

    // MOVED 1-3 to store.ts to be able to export Dispatch types

    /**
     * 4) Start Sagas; exposing both the store and the global registry to the sagas.
     */
    // @ts-ignore
    sagas.forEach((saga) => sagaMiddleware.run(saga, { store }))

    /**
     * 5) Dispatch Init signal
     */
    store.dispatch(actions.System.init(initialState))

    /**
     * 6) start the sagas after store was initially set up
     */
    store.dispatch(actions.System.sagasInit())

    /**
     * 6) licensing setup
     */
    const licenseWarning = initialState.system.licenseWarning
    if (licenseWarning.message) {
        store.dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: licenseWarning.disablesExply ? 'licenseBlock' : 'licenseWarning',
                parameters: {
                    message: licenseWarning.message,
                    preventHideModal: licenseWarning.disablesExply,
                },
                confirmationCallback: () => window.open(licenseWarning.shopURL),
            })
        )
    }

    /**
     * 7) Render Application
     */
    const appContainer = document.querySelector('#root')

    const render = (Component: any) => {
        ReactDOM.render(
            <Provider store={store}>
                <FieldsConfigurationProvider>
                    <ConnectedRouter history={history}>
                        <ScrollToTop />
                        <Component />
                    </ConnectedRouter>
                </FieldsConfigurationProvider>
            </Provider>,
            appContainer
        )
    }

    render(Application)
})
