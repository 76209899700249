import { put, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions } from '../../Redux'
import axios from '../Utility/axios'
import { UserOrGroupId } from '../../Redux/Data/UserManagement'

export const getPasswordToken = createAction('Data/UserManagement/GetPasswordToken', (userId: UserOrGroupId) => ({
    payload: { userId },
}))

export default function* GetPasswordSaga() {
    yield takeLatest(getPasswordToken, function* (action) {
        try {
            const userId = action.payload.userId
            const response: AxiosResponse = yield axios({
                method: 'GET',
                url: 'admin/userManagement/reset-password-token/' + userId,
            })

            if (response.status < 400) {
                yield put(actions.Data.UserManagement.setPasswordToken(response.data))
                yield put(actions.UI.Modal.show('userManagementResetPasswordLink'))
            } else {
                yield put(actions.UI.Notification.show({ id: 'userOrGroupGetPassWordTokenFailed' }))
            }
        } catch (e) {
            console.error(e)
        }
    })
}
