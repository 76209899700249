import type { VirtualElement as IVirtualElement } from '@popperjs/core'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { useEvent } from 'react-use'

export interface PopperPortalProps {
    active?: boolean
    children: ReactNode
}

/**
 * Taken from https://github.com/recharts/recharts/issues/2458#issuecomment-1063463873 to solve the problem that recharts
 * automatic tooltip placement covers most of the chart content (now it follows the mouse) and some z-Index issues
 * (tooltip is below dashboard header, widget group header etc.). This component uses Popper.js which portals the tooltip
 * out of the dom and positions it next to mouse. Popper.js is also smart enough to not move it out of the viewport.
 */

export default function PopperPortal({ active = true, children }: PopperPortalProps) {
    const [portalElement, setPortalElement] = useState<HTMLDivElement>()
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>()
    const virtualElementRef = useRef(new VirtualElement())

    const { styles, attributes, update } = usePopper(virtualElementRef.current, popperElement, POPPER_OPTIONS)

    useEffect(() => {
        const el = document.createElement('div')
        document.body.appendChild(el)
        setPortalElement(el)
        return () => el.remove()
    }, [])

    useEvent('mousemove', ({ clientX: x, clientY: y }) => {
        virtualElementRef.current?.update(x, y)
        if (!active) return
        update?.()
    })

    useEffect(() => {
        if (!active) return
        update?.()
    }, [active, update])

    if (!portalElement) return null

    return createPortal(
        <div
            ref={setPopperElement}
            {...attributes.popper}
            style={{
                ...styles.popper,
                // this needs to be higher than the highest z-index from our sass layers...
                zIndex: 10000,
                display: active ? 'block' : 'none',
            }}
        >
            {children}
        </div>,
        portalElement
    )
}

class VirtualElement implements IVirtualElement {
    private rect = {
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        x: 0,
        y: 0,
        toJSON() {
            return this
        },
    }

    update(x: number, y: number) {
        this.rect.y = y
        this.rect.top = y
        this.rect.bottom = y

        this.rect.x = x
        this.rect.left = x
        this.rect.right = x
    }

    getBoundingClientRect(): DOMRect {
        return this.rect
    }
}

const POPPER_OPTIONS: Parameters<typeof usePopper>[2] = {
    placement: 'top-start',
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [5, 20],
            },
        },
    ],
}
