import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { actions as action, actions, selectors } from '../../../Redux/index'
import ImporterListItem from '../../../Components/Molecules/ImporterListItem/ImporterListItem'
import SecondaryHeader from '../../../Components/Molecules/SecondaryHeader/SecondaryHeader'
import List from '../../../Components/Organisms/List/List'
import Icon from '../../../Components/Atoms/Icon/Icon'
import { RootState } from '../../../Redux/types'
import { AppDispatch } from '../../../store'
import Button from '../../../Components/Atoms/Button/Button'
import { runImport } from '../../../Sagas/ImporterSagas/Execution/RunStartSaga'
import { clearAllImporterData, clearImporterData } from '../../../Sagas/ImporterSagas/Data/ClearImporterDataSaga'
import { exportLogs } from '../../../Sagas/ImporterSagas/Execution/ExportImporterLogsSaga'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'
import { pollLogTailsAction } from '../../../Sagas/ImporterSagas/Execution/PollImporterLogTails'
import { ImporterId } from '../../../Redux/Data/types'
import { killImportAction } from '../../../Sagas/ImporterSagas/Execution/RunKillSaga'
import { toggleImporterAction } from '../../../Sagas/ImporterSagas/Management/ToggleImporter'

const mapStateToProps = (state: RootState) => {
    return {
        importersByUuid: selectors.Data.Importer.importersByUuid(state),
        importerStatusByUuid: selectors.Data.Importer.importerStatusByUuid(state),
        importerDefinitionsById: selectors.Data.Importer.importerDefinitionsById(state),
        logTails: selectors.Data.Importer.logTails(state),
        lastEditedImporter: selectors.Data.Importer.lastEditedImporter(state),
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    loadImporterLogTails: () => dispatch(pollLogTailsAction()),

    onCreateNewImporter: () => dispatch(push('/importer/new')),
    onClearAllData: () =>
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'importerClearAll',
                confirmationCallback: () => dispatch(clearAllImporterData()),
            })
        ),

    onRunImport: (uuid: string) => dispatch(runImport(uuid)),
    onKillImport: (uuid: ImporterId) => dispatch(killImportAction(uuid)),
    onEditImporter: (uuid: string) => dispatch(push(`/importer/edit/${uuid}`)),
    onAddExtension: (uuid: string) => dispatch(push(`/importer/${uuid}/extension`)),
    onPollLogTails: (uuids: Array<ImporterId>) => dispatch(pollLogTailsAction(uuids)),
    onClearData: (uuid: string) =>
        dispatch(
            action.UI.Modal.startConfirmation({
                modalId: 'importerClearSingleImporterData',
                confirmationCallback: () => dispatch(clearImporterData(uuid)),
            })
        ),
    onRemoveImporter: (importerUuid: string) => {
        dispatch(
            actions.UI.Modal.startConfirmation({
                modalId: 'importerDelete',
                confirmationCallback: () => {
                    dispatch(actions.Data.Importer.remove(importerUuid))
                },
            })
        )
    },
    onToggleImporterEnabled: (uuid: string) => dispatch(toggleImporterAction(uuid)),
    onExportImporterLogs: (uuid: string) => dispatch(exportLogs(uuid)),
    resetLastEditedImporter: () => dispatch(actions.Data.Importer.resetLastEditedImporter()),
})

type ImporterOverviewReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
type State = { importerRefsByUuid: { [key: string]: React.RefObject<any> } }

class ImporterList extends PureComponent<ImporterOverviewReduxProps, State> {
    constructor(props: ImporterOverviewReduxProps) {
        super(props)

        this.state = {
            importerRefsByUuid: Object.fromEntries(
                Object.keys(props.importersByUuid).map((uuid) => [uuid, React.createRef()])
            ),
        }
    }

    render() {
        const listItemCount = Object.keys(this.props.importersByUuid).length
        return (
            <div className="importer-overview">
                <SecondaryHeader
                    className="importer-list__header"
                    left={<div />}
                    right={
                        <div className="secondary-header__options">
                            <Button onClick={this.props.onCreateNewImporter} type="orange">
                                <Icon
                                    label="Add New Data Source"
                                    name="PlusIcon"
                                    title="Add New Data Source"
                                    color="white"
                                />
                            </Button>
                            <Icon
                                label="Clear All Imported Data"
                                name="TrashIcon"
                                title="Clear All Imported Data"
                                onClick={this.props.onClearAllData}
                            />
                        </div>
                    }
                />
                <List>
                    {Object.values(this.props.importersByUuid)
                        .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
                        .map((i) => i.uuid)
                        .map((uuid: string) => {
                            // If the importer has just been created the UI might not know its status yet.
                            const status = this.props.importerStatusByUuid[uuid] || {}
                            const importer = this.props.importersByUuid[uuid]
                            const importerDefinition = this.props.importerDefinitionsById[importer.definitionId]

                            return (
                                <ErrorBoundary componentWrapper key={'error-boundary' + uuid}>
                                    <ImporterListItem
                                        ref={this.state.importerRefsByUuid[uuid]}
                                        key={uuid}
                                        uuid={uuid}
                                        importer={importer}
                                        importerDefinition={importerDefinition}
                                        status={status}
                                        isOpen={listItemCount < 4 || uuid === this.props.lastEditedImporter}
                                        logTails={this.getLogTailsForImporter(uuid)}
                                        onRunImport={this.props.onRunImport}
                                        onKillImport={this.props.onKillImport}
                                        onEditImporter={this.props.onEditImporter}
                                        onAddExtension={this.props.onAddExtension}
                                        onPollLogTails={this.props.onPollLogTails}
                                        onClearData={this.props.onClearData}
                                        onRemoveImporter={this.props.onRemoveImporter}
                                        onToggleImporterEnabled={this.props.onToggleImporterEnabled}
                                        onExportLogs={this.props.onExportImporterLogs}
                                    />
                                </ErrorBoundary>
                            )
                        })}
                </List>
            </div>
        )
    }

    getLogTailsForImporter = (importerUuid: string) => this.props.logTails[importerUuid]

    componentDidMount() {
        this.props.loadImporterLogTails()
        if (this.props.lastEditedImporter) {
            this.state.importerRefsByUuid[this.props.lastEditedImporter]?.current?.scrollIntoView()
            this.props.resetLastEditedImporter()
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImporterList)
