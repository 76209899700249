import { delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { push, LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router'
import { actions, selectors } from '../../Redux'
import {
    addCurrentlyEditedDashboardToRequest,
    buildInitialDashboardDataRequest,
    executeDashboardDataRequest,
    executeWidgetSideDataRequest,
    getDashboardDataRequest,
} from './Utility'
import { DashboardsRoute, EmbeddedDashboardRoute, SharedDashboardRoute } from '../../routes'
import { DashboardRequest, DashboardId, WidgetId } from '../../Redux/Data/types'

export function* DashboardInitializationOnDashboardLoadSaga() {
    yield takeLatest([LOCATION_CHANGE], function* (action: PayloadAction<LocationChangePayload>) {
        const { location } = action.payload
        const matchResult = location.pathname.match(
            new RegExp(
                `^(${DashboardsRoute}|${EmbeddedDashboardRoute}|${SharedDashboardRoute})/([0-9a-f-]{36})(/edit)?$`
            )
        )

        if (!matchResult || matchResult.length !== 4) {
            // url did not match a dashboard route, do not fetch data
            return
        }

        const dashboardId = matchResult[2]
        const openInEditMode = Boolean(matchResult[3])
        yield loadInitialDashboardData(
            dashboardId,
            openInEditMode ? [() => actions.Data.Dashboards.DashboardEditMode.enableEditMode(dashboardId)] : undefined
        )
    })
}

export function* DashboardInitializationOnReset() {
    yield takeLatest(
        [actions.Data.Dashboards.ResetDashboardState.resetDashboardState.type],
        function* (action: PayloadAction<{ currentlySelectedDashboardUuid: string }>) {
            yield loadInitialDashboardData(action.payload.currentlySelectedDashboardUuid)
        }
    )
}

function* loadInitialDashboardData(dashboardId: DashboardId, successActions?: Array<() => void>) {
    const dashboard = selectors.Data.Dashboards.dashboardById(yield select(), dashboardId)

    if (dashboard === undefined) {
        yield put(push('/'))
        return
    }

    const isNewDashboard = selectors.Data.Dashboards.DashboardEditMode.isNewDashboard(yield select(), dashboardId)
    if (isNewDashboard) {
        // we did not save our dashboard yet; so let's abort!
        return
    }

    const dashboardRequest: DashboardRequest = yield buildInitialDashboardDataRequest(dashboardId)

    yield put(actions.Data.Dashboards.ViewMode.setInitialWidgetDataRequest(dashboardRequest))
    yield executeDashboardDataRequest(dashboardRequest, [
        actions.Data.Dashboards.ViewMode.loadWidgetDataSuccess,
        ...(successActions ?? []),
    ])
}

export function* UpdateOnWidgetStateChangeSaga() {
    yield takeLatest(actions.Data.Dashboards.ViewMode.updateWidgetState, function* () {
        yield delay(50) // workaround to ensure the request is already applied. TODO: DIRTY WORKAROUND!

        const widgetDataRequest: DashboardRequest = yield getDashboardDataRequest()
        yield executeDashboardDataRequest(widgetDataRequest, [actions.Data.Dashboards.ViewMode.loadWidgetDataSuccess])
    })
}

export type RequestSideData = (endpoint: string, parameters: any, callback: (arg: any) => void) => void

export const requestSideDataAction = createAction(
    'Data/Dashboard/ViewMode/RequestSideData',
    (widgetUuid: WidgetId, endpoint: string, parameters: any, callback: () => void) => ({
        payload: { widgetUuid, endpoint, parameters, callback },
    })
)

export function* ExecuteWidgetSideDataRequestSaga() {
    yield takeEvery(requestSideDataAction, function* (action) {
        let dashboardRequest: DashboardRequest = yield getDashboardDataRequest()
        dashboardRequest = yield addCurrentlyEditedDashboardToRequest(dashboardRequest)

        const { widgetUuid, endpoint, parameters, callback } = action.payload
        yield executeWidgetSideDataRequest(widgetUuid, endpoint, parameters, dashboardRequest, callback)
    })
}
