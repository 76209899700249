import React from 'react'
import { withTooltip } from 'react-tippy'
import Headline from './Components/Atoms/Headline/Headline'
import FormFieldWithLabel from './Components/Molecules/FormFieldWithLabel/FormFieldWithLabel'
import DocumentFieldSelect from './Components/Atoms/DocumentFieldSelect/DocumentFieldSelect'
import FieldListEditor from './Components/Molecules/FieldListEditor/FieldListEditor'
import NumberPicker from './Components/Atoms/NumberPicker/NumberPicker'

import TextInput from './Components/Atoms/TextInput/TextInput'
import Select from './Components/Atoms/Select/Select'
import FormFieldWithModes from './Components/Molecules/FormFieldWithModes/FormFieldWithModes'
import ValueFieldEditor from './Components/Molecules/ValueFieldEditor/ValueFieldEditor'
import ReferenceLineEditor from './Components/Molecules/ReferenceLineEditor/ReferenceLineEditor'
import ColorScaleSelect from './Components/Atoms/ColorScaleSelect/ColorScaleSelect'
import {
    DATE,
    FieldConfiguration,
    STRING_SUITABLE_FOR_GROUPING,
    TagsType,
} from './Components/Atoms/FieldsConfiguration/FieldsConfigurationTypes'
import TrendLineEditor from './Components/Molecules/TrendLineEditor/TrendLineEditor'
import { SelectOption } from './Components/Atoms/Select/Select.types'
import { IconNames } from './Components/Atoms/Icon/Icon'
import { configMapping } from './Components/Organisms/Widgets/TimeSeriesChart/TimeSeriesChart.manifest'
import Switch from './Components/Atoms/Switch/Switch'
import { UserSettings } from './Redux/Data/UserManagement'

// this is not complete and should over time be added to all widget.manifest files to facilitate editing
export type WidgetManifestConfiguration = {
    name: string
    component: any
    icon: React.ComponentType
    stateInitializationFunction: (configuration: any) => any
    configurationInitializer: (priorConfiguration?: Record<string, any>) => InitialWidgetConfiguration
    configurationInitializerForWidgetExplorer?: (arg?: any) => any
    editors: {
        data: Array<WidgetConfigurationEditor>
        bottomTabs: Array<BottomTab>
    }
    minHeight?: number
    maxHeight?: number
    minWidth?: number
    maxWidth?: number
    capabilities?: {
        min?: {
            dateFields: number
            valueFields: number
            categoryFields: number
        }
        max?: {
            dateFields: number
            valueFields: number
            categoryFields: number
        }
    }
    configurationValidations?: (widgetConfig: any) => string | void
    renderRequirements?: (widgetData?: any) => string | void
    configMapping?: typeof configMapping
    isJiraWidget?: boolean
    isOnPremiseOnlyWidget?: boolean
    isHidden?: boolean
    requiredPermissions?: Array<keyof UserSettings>
}

export type InitialWidgetConfiguration = {
    title: string
    [key: string]: any
}

export type WidgetConfigurationEditor = {
    component: any
    widgetConfigurationProperty?: string
    configuration?: Record<string, any>
    editorContextBuilder?: (args?: any) => any
    isDisplayed?: (arg?: any) => boolean
}

export type BottomTab = {
    name: string
    editors: Array<WidgetConfigurationEditor>
}

export const headline = (headline: string) => ({
    component: Headline,
    configuration: {
        title: headline,
    },
})

export const text = (widgetConfigurationProperty: string, label: string) => ({
    widgetConfigurationProperty,
    component: FormFieldWithLabel,
    configuration: {
        label,
        editor: TextInput,
    },
})

export const flag = (
    widgetConfigurationProperty: string,
    label: string,
    editorIcon?: IconNames,
    helpTooltip?: string
) => ({
    widgetConfigurationProperty,
    component: Switch,
    editorContextBuilder: () => ({
        label,
        isSmall: true,
        helpTooltip,
    }),
    isFlag: true,
    configuration: {
        editorIcon,
    },
})

export const selectOneOf = (
    widgetConfigurationProperty: string,
    label: string,
    options: Array<SelectOption>,
    isDisabled?: boolean,
    editorIcon?: IconNames,
    clearable?: boolean,
    tooltip?: string
) => ({
    widgetConfigurationProperty,
    component: FormFieldWithLabel,
    configuration: {
        label,
        editor: tooltip ? withTooltip(Select, { title: tooltip }) : Select,
        isClearable: clearable || false,
        disabled: isDisabled,
        options,
        editorIcon,
        isIconAlwaysActive: true,
    },
})

export const valueField = (overrides?: any) => {
    const {
        label,
        disableCalculatedField,
        isModeDisabled,
        isGroupingFieldDisabled,
        isIgnoreTimeSelectionDisabled,
        includePivotField,
        areDateRangeFieldsAllowed,
    } = overrides || {}
    return {
        widgetConfigurationProperty: 'valueField',
        component: FormFieldWithLabel,
        configuration: {
            label: label || 'Value Field',
            icon: 'AnalyticsIcon' as IconNames,
            editor: ValueFieldEditor,
            tags: ['NUMBER'],
            optionGenerator: groupingFieldOptionGenerator,
            configuration: {
                allowFilters: true,
                disableCalculatedField,
                isModeDisabled,
                isGroupingFieldDisabled,
                isIgnoreTimeSelectionDisabled,
                areDateRangeFieldsAllowed,
            },
            clearable: false,
            includePivotField,
        },
    }
}

export const colorPicker = (widgetConfigurationProperty?: string, label?: string) => ({
    widgetConfigurationProperty: widgetConfigurationProperty || 'color',
    component: FormFieldWithLabel,
    configuration: {
        label: label || 'Color (hex value)',
        editor: FormFieldWithModes,
        modeByValue: (v: any) => (v ? 'custom' : 'auto'),
        initialValueByMode: (m: string) => (m === 'auto' ? '' : '#4FC3F7'),
        modes: [
            {
                label: 'auto',
                editor: TextInput,
                configuration: {
                    disabled: true,
                    value: '',
                },
            },
            {
                label: 'custom',
                editor: TextInput,
                configuration: {
                    type: 'color',
                },
            },
        ],
    },
})

export const stackingField = () => ({
    widgetConfigurationProperty: 'stackingField',
    component: FormFieldWithLabel,
    configuration: {
        label: 'Stacking Field',
        icon: 'LayerPlusIcon' as IconNames,
        editor: DocumentFieldSelect,
        optionGenerator: groupingFieldOptionGenerator(),
        tags: [STRING_SUITABLE_FOR_GROUPING, DATE],
        placeholder: 'Please select a stacking field',
    },
})

export const pivotField = (
    overwriteLabel?: string,
    overwriteConfigurationProperty?: string,
    tags?: TagsType,
    icon?: IconNames,
    tooltipId?: string
) => ({
    widgetConfigurationProperty: overwriteConfigurationProperty || 'pivotField',
    component: FormFieldWithLabel,
    configuration: {
        label: overwriteLabel || 'Pivot Field',
        icon: icon || 'CabinetIcon',
        editor: DocumentFieldSelect,
        clearable: false,
        optionGenerator: groupingFieldOptionGenerator(),
        tags: tags || ['STRING_SUITABLE_FOR_GROUPING', 'DATE'],
        placeholder: 'Please select a pivot field',
        helpTooltip: tooltipId || 'widgetEditorPivotField',
    },
})

export const pivotFields = (limit: number) => ({
    widgetConfigurationProperty: 'pivotFields',
    component: FieldListEditor,
    configuration: {
        label: 'Pivot Field',
        icon: 'CabinetIcon' as IconNames,
        tags: ['STRING_SUITABLE_FOR_GROUPING', DATE],
        optionGenerator: groupingFieldOptionGenerator(),
        placeholder: 'Please select a pivot field',
        clearable: true,
        limit,
        labelFn: ({ ordinal }: { ordinal: string }) => `${ordinal} Pivot Field`,
    },
})

export const numberOfDecimals = () => ({
    widgetConfigurationProperty: 'numberOfDecimals',
    component: NumberPicker,
    editorContextBuilder: () => ({
        label: 'Number of Decimals',
        placeholder: 0,
        min: 0,
        max: 5,
        step: 1,
    }),
    configuration: {
        editorIcon: 'NumDecimalsIcon' as IconNames,
        isIconAlwaysActive: true,
    },
})

export const maximumNumberOfStacks = () => ({
    widgetConfigurationProperty: 'maximumNumberOfStacks',
    component: NumberPicker,
    editorContextBuilder: () => ({
        label: 'Max Number of Stacks',
        placeholder: '20',
        min: 2,
        max: 500,
        step: 1,
    }),
    configuration: {
        editorIcon: 'NumStacksIcon' as IconNames,
        isIconAlwaysActive: true,
    },
})

export const number = (
    widgetConfigurationProperty: string,
    label: string,
    placeholder?: string,
    min?: number,
    max?: number,
    step?: number,
    precision?: number,
    editorIcon?: string
) => ({
    widgetConfigurationProperty,
    component: NumberPicker,
    editorContextBuilder: () => ({
        label,
        placeholder: placeholder || '20',
        min: min || 2,
        max: max || 500,
        step: step || 1,
        precision,
    }),
    configuration: {
        editorIcon,
        isIconAlwaysActive: true,
    },
})

export const granularity = () => ({
    widgetConfigurationProperty: 'granularity',
    component: FormFieldWithLabel,
    configuration: {
        label: 'Granularity',
        editor: Select,
        placeholder: 'automatic',
        clearable: true,
        options: [
            /*
             {
                 value: 'minute',
                 label: 'Minute'
             },
             {
                 value: 'hour',
                 label: 'Hour'
             },
             */
            {
                value: 'day',
                label: 'day',
            },
            {
                value: 'week',
                label: 'week',
            },
            {
                value: 'month',
                label: 'month',
            },
            {
                value: 'quarter',
                label: 'quarter',
            },
            {
                value: 'year',
                label: 'year',
            },
        ],
    },
})

export const primaryDateField = (overrides?: any) => {
    const { label } = overrides || {}
    return {
        widgetConfigurationProperty: 'primaryDateField',
        component: FormFieldWithLabel,
        configuration: {
            tags: ['DATE', 'DATE_RANGE'],
            clearable: false,
            editor: DocumentFieldSelect,
            label: label || 'Date Field',
            icon: 'CalendarIcon' as IconNames,
            helpTooltip: 'widgetEditorDateField',
            placeholder: 'Please select a date field',
        },
    }
}

export const colorScale = () => {
    return {
        widgetConfigurationProperty: 'colorScale',
        component: FormFieldWithLabel,
        configuration: {
            label: 'Color Scale',
            editor: ColorScaleSelect,
        },
    }
}

export const unit = () => ({
    widgetConfigurationProperty: 'unit',
    component: FormFieldWithLabel,
    configuration: {
        label: 'Unit (label)',
        editor: TextInput,
    },
})

export const referenceLine = () => ({
    widgetConfigurationProperty: 'referenceLines',
    component: ReferenceLineEditor,
})

export const trendLine = (widgetConfigurationProperty: string, iconName: IconNames) => ({
    widgetConfigurationProperty,
    component: TrendLineEditor,
    configuration: {
        icon: iconName,
    },
})

export const topOrFlop = () => ({
    widgetConfigurationProperty: 'topOrFlop',
    component: FormFieldWithLabel,
    configuration: {
        label: 'Get Top or Flop items',
        icon: 'TopFlopIcon' as IconNames,
        editor: Select,
        clearable: false,
        options: [
            {
                value: 'desc',
                label: 'Top',
            },
            {
                value: 'asc',
                label: 'Flop',
            },
        ],
    },
})

// A grouping field can be a category field or a date with this by year or by month grouping. They are used
// for the average feature and for the pivot table.
export const groupingFieldOptionGenerator = (currentDateField?: string) => (fieldConfiguration: FieldConfiguration) => {
    const fieldName = fieldConfiguration.name
    if (fieldConfiguration.tag === DATE) {
        if (!currentDateField) {
            // For the possible grouping values, see DatePivotFieldModifier class in DatePivotField.groovy
            return [
                {
                    label: fieldConfiguration.label + ' by year',
                    value: fieldName + '::YEAR',
                },
                {
                    label: fieldConfiguration.label + ' by quarter',
                    value: fieldName + '::QUARTER',
                },
                {
                    label: fieldConfiguration.label + ' by month',
                    value: fieldName + '::MONTH',
                },
                {
                    label: fieldConfiguration.label + ' by week',
                    value: fieldName + '::WEEK',
                },
                {
                    label: fieldConfiguration.label + ' by day',
                    value: fieldName + '::DAY',
                },
            ]
        }

        if (fieldName === currentDateField) {
            return [
                {
                    label: 'year',
                    value: currentDateField + '::YEAR',
                },
                {
                    label: 'quarter',
                    value: currentDateField + '::QUARTER',
                },
                {
                    label: 'month',
                    value: currentDateField + '::MONTH',
                },
                {
                    label: 'week',
                    value: currentDateField + '::WEEK',
                },
                {
                    label: 'day',
                    value: currentDateField + '::DAY',
                },
            ]
        }

        return []
    }

    return [
        {
            label: fieldConfiguration.label,
            value: fieldConfiguration.name,
        },
    ]
}
