import { connect } from 'react-redux'
import SourceSelector from '../Components/Molecules/SourceSelector/SourceSelector'
import { File, HandleFileUploadDone, HandleFileUploadProgress } from '../Components/Molecules/FileUpload/FileUpload'
import { AppDispatch } from '../store'
import { startImporterSourceFileUploadAction } from '../Sagas/FileUploadSaga'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onStartUpload: (file: File, handleProgress: HandleFileUploadProgress, handleDone: HandleFileUploadDone) =>
        dispatch(startImporterSourceFileUploadAction(file, handleProgress, handleDone)),
})

const ImporterSourceSelector = connect(null, mapDispatchToProps)(SourceSelector)

export default ImporterSourceSelector
