import React, { PureComponent } from 'react'
import produce from 'immer'
import FormFieldWithLabel from '../../Molecules/FormFieldWithLabel/FormFieldWithLabel'
import TextInput from '../../Atoms/TextInput/TextInput'
import Switch from '../../Atoms/Switch/Switch'
import FieldFilterEditor from '../../Molecules/FieldFilterEditor/FieldFilterEditor'
import List, { ListItem } from '../List/List'
import InputValidationWrapper from '../../Atoms/InputValidationWrapper/InputValidationWrapper'
import { UserEditorProps } from './UserEditor.types'
import { FieldType } from '../../Atoms/FieldFilterInput/FieldFilterManifest'
import { UserOrGroupConfig, UserOrGroupKey } from '../../../Redux/Data/UserManagement'
import ButtonWithTooltip from '../../Molecules/HoverTooltip/ButtonWithTooltip'
import { DashboardConfiguration } from '../../../Redux/Data/types'

type UserEditorState = {
    dashboardFilter: string
    filteredDashboards: DashboardConfiguration[]
}

export default class UserEditor extends PureComponent<UserEditorProps, UserEditorState> {
    constructor(props: UserEditorProps) {
        super(props)

        this.state = {
            dashboardFilter: '',
            filteredDashboards: this.props.dashboards,
        }
    }

    handleUpdate =
        <K extends keyof UserOrGroupConfig>(oldValue: any, property: K, fieldName?: keyof UserOrGroupConfig[K]) =>
        (newValue: any) => {
            if (oldValue && fieldName) {
                const updatedValue = {
                    ...oldValue,
                    [fieldName]: newValue,
                }
                this.props.onUpdate(property, updatedValue)
            } else {
                this.props.onUpdate(property, newValue)
            }
        }

    handleSetPassword = () => this.props.onSetPassword()

    handleGetPasswordResetToken = () => this.props.onGeneratePasswordToken()

    handleFieldPermissionAdded = (newFilter: FieldType) => {
        const filters = this.props.userOrGroup.documentPermissions.fieldPermissions
        this.handleUpdate(
            this.props.userOrGroup.documentPermissions,
            'documentPermissions',
            'fieldPermissions'
        )([...filters, newFilter])
    }

    handleFieldPermissionChanged = (index: number, field: string, newValue: string) => {
        const newFieldPermissions = produce(this.props.userOrGroup.documentPermissions.fieldPermissions, (draft) => {
            // @ts-ignore do not worry about the index for now (might want to refactor this in the future)
            draft[index][field] = newValue
        })
        this.handleUpdate(
            this.props.userOrGroup.documentPermissions,
            'documentPermissions',
            'fieldPermissions'
        )(newFieldPermissions)
    }

    handleFieldPermissionRemoved = (index: number) => {
        const filters = this.props.userOrGroup.documentPermissions.fieldPermissions
        this.handleUpdate(
            this.props.userOrGroup.documentPermissions,
            'documentPermissions',
            'fieldPermissions'
        )(
            produce(filters, (draft) => {
                draft.splice(index, 1)
            })
        )
    }

    render() {
        const userOrGroup = this.props.userOrGroup
        const isActive = userOrGroup.isActive
        const isAdmin = userOrGroup.settings.isAdmin
        const hasDataPermissions = !this.props.userOrGroupValidation?.errorMessages.permission
        const toggleIsAdmin = () => this.handleUpdate(userOrGroup.settings, 'settings', 'isAdmin')(!isAdmin)

        return (
            <div className="user-editor">
                <div className="user-editor__header">
                    <h3 key="headline">Personal Information</h3>
                    <div className="user-editor__header__controls">
                        <Switch
                            label={isActive ? 'active' : 'inactive'}
                            value={isActive}
                            onChange={this.props.onToggleUserActivation}
                            labelPositionRight={false}
                        />
                    </div>
                </div>

                {this.renderPersonalInformation()}

                <div className="api__header">
                    <h3 key="headline">Groups & Access Rights</h3>
                </div>

                <div className="user-editor__administrator">
                    <Switch
                        key="switch"
                        value={isAdmin}
                        onChange={toggleIsAdmin}
                        label="is Administrator"
                        labelPositionRight={true}
                    />
                </div>

                {this.renderUnless(
                    isAdmin,
                    <List>
                        {this.renderCollapsibleAllowAll(
                            'Groups',
                            <List>
                                {this.props.groups.map((g) => {
                                    if (g.id !== userOrGroup.id) {
                                        return this.renderCheckListContains(
                                            g.label + ' (' + g.id + ')',
                                            g.id,
                                            userOrGroup,
                                            'subGroups'
                                        )
                                    }

                                    return undefined
                                })}
                            </List>
                        )}
                        {this.renderCollapsibleAllowAll(
                            'General Permissions',
                            <div className="user-permissions-list-item__body">
                                {this.renderCheckFlag(
                                    'change access rights (including own)',
                                    userOrGroup.settings,
                                    'settings',
                                    'isUserManager'
                                )}
                                {this.renderCheckFlag(
                                    'create or edit dashboards',
                                    userOrGroup.settings,
                                    'settings',
                                    'isDashboardManager'
                                )}
                                {this.renderCheckFlag(
                                    'manage importers',
                                    userOrGroup.settings,
                                    'settings',
                                    'isImporterManager'
                                )}
                                {this.renderCheckFlag(
                                    'manage custom colors',
                                    userOrGroup.settings,
                                    'settings',
                                    'canManageCustomColors'
                                )}
                            </div>,
                            userOrGroup.settings,
                            'settings',
                            'isSystemManager'
                        )}
                        {this.renderCollapsibleAllowAllWithSearch(
                            'Dashboard Permissions',
                            <div className="user-permissions-list-item__body">
                                {this.state.filteredDashboards.map((dashboard) =>
                                    this.renderCheckListContains(
                                        dashboard.title,
                                        dashboard.id,
                                        userOrGroup.settings,
                                        'settings',
                                        'visibleDashboards'
                                    )
                                )}
                            </div>,
                            userOrGroup.settings,
                            'settings',
                            'canSeeAnyDashboard'
                        )}
                        {hasDataPermissions ? null : (
                            <small className="user-permissions-hint">
                                {this.props.userOrGroupValidation?.errorMessages.permission}
                            </small>
                        )}
                        {this.renderCollapsibleAllowAll(
                            'Data Field Permissions',
                            <FieldFilterEditor
                                className="user-permissions__filter"
                                fields={userOrGroup.documentPermissions.fieldPermissions}
                                hasPermissionFilters={true}
                                onFilterAdded={this.handleFieldPermissionAdded}
                                onFilterChanged={this.handleFieldPermissionChanged}
                                onFilterRemoved={this.handleFieldPermissionRemoved}
                                onTriggerAutoCompletion={this.props.triggerAutoCompletion}
                            />,
                            userOrGroup.documentPermissions,
                            'documentPermissions',
                            'ignoreFieldPermissions'
                        )}
                    </List>
                )}
            </div>
        )
    }

    renderPersonalInformation() {
        const userOrGroup = this.props.userOrGroup
        const isGroup = userOrGroup.isGroup

        return (
            <div className="user-editor__personal-information__wrapper">
                <div className="user-editor__personal-information">
                    <InputValidationWrapper message={this.props.userOrGroupValidation?.errorMessages.id}>
                        <FormFieldWithLabel
                            configuration={{
                                label: isGroup ? 'Group Name' : 'Username',
                                value: userOrGroup.id,
                                editor: TextInput,
                                disabled: !this.props.isCreation,
                                onChange: this.props.onIdChange,
                            }}
                        />
                    </InputValidationWrapper>

                    {this.renderTextInput('label', 'Name', 'Alice Wonderland')}
                    {this.renderUnless(
                        this.props.userOrGroup.isGroup,
                        this.renderTextInput('email', 'Email', 'alice@example.net')
                    )}
                    {this.renderTextInput('description', 'Description', 'short description for you and other admins')}
                    {this.props.passwordsAreManagedLocally && this.renderSetPasswordButtonsForUser()}
                </div>
                {this.renderSwitchUser()}
            </div>
        )
    }

    getJiraNameHint = (isGroup: boolean) => {
        return isGroup
            ? 'Please make sure that the Group name is identical to the JIRA Group name.'
            : 'Please make sure that the User name is identical to the JIRA Username.'
    }

    renderTextInput = (property: UserOrGroupKey, label: string, placeholder: string) => {
        return (
            <FormFieldWithLabel
                configuration={{ label, placeholder, editor: TextInput }}
                value={this.props.userOrGroup[property] || ''}
                onChange={this.handleUpdate(null, property)}
            />
        )
    }

    renderSetPasswordButtonsForUser = () => {
        const label = this.props.userOrGroup.label || 'the user'
        const isDisabled = !this.props.hasValidId
        return this.renderUnless(
            this.props.userOrGroup.isGroup,
            <div>
                <div className="user-editor__password-editing-buttons">
                    <ButtonWithTooltip
                        header="Please enter a username"
                        isDisabled={isDisabled}
                        onClick={this.handleSetPassword}
                    >
                        {this.props.hasPassword ? 'Change Password' : 'Set Password'}
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        isDisabled={this.props.isCreation || isDisabled}
                        header="User must be created to generate password link."
                        onClick={this.handleGetPasswordResetToken}
                    >
                        Generate Password Link
                    </ButtonWithTooltip>
                </div>
                <div className="user-editing__info-text">
                    {this.renderUnless(
                        isDisabled || this.props.hasPassword || !this.props.showPasswordWarning,
                        label + ' cannot login since no password is set. '
                    )}
                    {this.renderIf(
                        this.props.isCreation && !isDisabled && Boolean(this.props.showPasswordWarning),
                        'After finishing creation you can create a password link on this page to send to ' + label
                    )}
                </div>
            </div>
        )
    }

    renderSwitchUser = () => {
        return this.renderIf(
            !this.props.userOrGroup.isGroup,
            <ButtonWithTooltip
                isDisabled={
                    !this.props.hasValidId ||
                    this.props.isCreation ||
                    this.props.loggedInUser.id === this.props.userOrGroup.id
                }
                header="User invalid"
                onClick={this.props.onSwitchUser}
            >
                Switch to User
            </ButtonWithTooltip>
        )
    }

    renderUnless = (condition: boolean, node: any) => {
        return this.renderIf(!condition, node)
    }

    renderIf = (condition: boolean, node: any) => {
        if (condition) {
            return node
        }

        return ''
    }

    renderCollapsibleAllowAll = <K extends 'settings' | 'documentPermissions'>(
        headline: string,
        inner: any,
        oldValue?: any,
        path?: K,
        fieldName?: keyof UserOrGroupConfig[K]
    ) => {
        let right = null
        let isOpen = true
        if (path && fieldName) {
            const allowAll = Boolean(this.props.userOrGroup[path][fieldName])
            const toggleAllowAll = () => this.handleUpdate(oldValue, path, fieldName)(!allowAll)
            right = (
                <Switch
                    value={allowAll}
                    onChange={toggleAllowAll}
                    labelPositionRight={false}
                    label="allow all"
                    key={'allow-all-switch-for-' + headline}
                />
            )
            isOpen = !allowAll
        }

        return (
            <ListItem
                isOpen={isOpen}
                left={[<div key="headline">{headline}</div>]}
                right={[right]}
                collapsible={inner}
            />
        )
    }

    renderCollapsibleAllowAllWithSearch = <K extends 'settings' | 'documentPermissions'>(
        headline: string,
        inner: any,
        oldValue?: any,
        path?: K,
        fieldName?: keyof UserOrGroupConfig[K]
    ) => {
        let right = null
        let isOpen = true
        if (path && fieldName) {
            const allowAll = Boolean(this.props.userOrGroup[path][fieldName])
            const toggleAllowAll = () => this.handleUpdate(oldValue, path, fieldName)(!allowAll)
            right = (
                <div className="search-and-switch" key={path + headline}>
                    <TextInput
                        value={this.state.dashboardFilter}
                        onChange={this.handleFilterDashboards}
                        onClear={this.handleClearDashboardsFilter}
                        clearable
                        placeholder="Search Dashboards"
                    />
                    <Switch
                        value={allowAll}
                        onChange={toggleAllowAll}
                        labelPositionRight={false}
                        label="allow all"
                        key={'allow-all-switch-for-' + headline}
                    />
                </div>
            )
            isOpen = !allowAll
        }

        return (
            <ListItem
                isOpen={isOpen}
                left={[<div key="headline">{headline}</div>]}
                right={[right]}
                collapsible={inner}
            />
        )
    }

    handleFilterDashboards = (filter: string) =>
        this.setState({ dashboardFilter: filter, filteredDashboards: this.filterDashboards(filter) })

    handleClearDashboardsFilter = () =>
        this.setState({ dashboardFilter: '', filteredDashboards: this.filterDashboards('') })

    filterDashboards = (filter: string) =>
        this.props.dashboards.filter((dashboard) => dashboard.title.toLowerCase().match(filter.toLowerCase()))

    renderCheckFlag = <K extends 'settings' | 'documentPermissions'>(
        label: string,
        oldValue: any,
        property: K,
        fieldName: keyof UserOrGroupConfig[K]
    ) => {
        const checked = Boolean(this.props.userOrGroup[property][fieldName])
        const handleChange = (newValue?: boolean) => this.handleUpdate(oldValue, property, fieldName)(newValue)

        return this.renderCheckbox(label, fieldName as string, checked, handleChange)
    }

    renderCheckListContains = <K extends 'settings' | 'documentPermissions' | 'subGroups'>(
        label: string,
        id: string,
        oldValue: any,
        property: K,
        fieldName?: keyof UserOrGroupConfig[K]
    ) => {
        // @ts-ignore
        const members: Array<string> = fieldName
            ? this.props.userOrGroup[property][fieldName]
            : this.props.userOrGroup[property]
        const checked = Array.isArray(members) && members.includes(id)
        const handleChange = (newChecked?: boolean) => {
            if (checked !== newChecked) {
                this.handleUpdate(
                    oldValue,
                    property,
                    fieldName
                )(newChecked ? members.concat(id) : members.filter((it) => it !== id))
            }
        }

        return this.renderCheckbox(label, id, checked, handleChange)
    }

    // using <label/> instead of <span/> results in double clicking the switch
    renderCheckbox = (label: string, key: string, checked: boolean, handleChange: (value?: boolean) => void) => {
        return (
            <div key={'checkbox-' + key} className="user-permissions-list-item__single-permission">
                <span>
                    <Switch onChange={handleChange} value={checked} label={label} />
                </span>
            </div>
        )
    }
}
