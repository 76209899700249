import produce from 'immer'
import { FURTHER_VALUES } from './Constants'
import { getCtrlKeyPressed } from '../../../Utility/KeyPress'
import { MatrixSelections } from './MatrixChartWidget/MatrixChartWidget.types'

export function findSelectionIndex({ x, y }: { x?: string; y?: string }, selections: MatrixSelections): number {
    for (let i = 0; i < selections.length; i++) {
        const selection = selections[i]
        if (selection.x === x && selection.y === y) {
            return i
        }
    }

    return -1
}

/**
 * @param x x of click target
 * @param y y of click target
 * @param currentSelection current selection
 * @returns array new selection according to click
 */
export function updateSelectionAfterItemClick(
    { x, y }: { x?: string; y?: string },
    currentSelection: MatrixSelections
) {
    if (x === FURTHER_VALUES || y === FURTHER_VALUES) {
        return currentSelection
    }

    const multiSelection = getCtrlKeyPressed()
    const selectionIndex = findSelectionIndex({ x, y }, currentSelection)
    const isAlreadySelected = selectionIndex >= 0

    if (isAlreadySelected) {
        // case: deselection
        if (multiSelection) {
            return produce(currentSelection, (draft) => {
                draft.splice(selectionIndex, 1)
            })
        }

        return currentSelection.length > 1 ? /* deselection of others */ [{ x, y }] : /* deselection of this */ []
    }

    // case: selection
    const newSelection = { x, y }
    if (multiSelection) {
        return [...currentSelection, newSelection]
    }

    return [newSelection]
}
