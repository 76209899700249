import React from 'react'

import Icon, { IconComponents, IconNames } from '../Icon/Icon'

import ActiveCollabLogo from '../../../Images/importerLogos/activeCollab_small.svg'
import JiraLogo from '../../../Images/importerLogos/jira_small.svg'
import TempoLogo from '../../../Images/importerLogos/tempo_small.svg'
import ActiTimeLogo from '../../../Images/importerLogos/actiTIME_small.svg'

type CustomIcons = 'activeCollab' | 'actiTime' | 'jiraProjects' | 'jira' | 'tempo'

const CustomIcon: React.FunctionComponent<{
    // TODO implement importerIDs as type
    type: IconNames | CustomIcons | string
}> = (props) => {
    let src
    switch (props.type) {
        case 'activeCollab':
            src = ActiveCollabLogo
            break
        case 'actiTime':
            src = ActiTimeLogo
            break
        case 'jiraProjects':
        case 'jira':
            src = JiraLogo
            break
        case 'tempo':
            src = TempoLogo
            break
        default:
            break
    }

    return (
        <div className="custom-icon-wrapper">
            {src ? (
                <img src={src} alt={props.type + ' icon'} />
            ) : (
                <Icon
                    name={Object.keys(IconComponents).includes(props.type) ? (props.type as IconNames) : 'CodeIcon'}
                />
            )}
        </div>
    )
}

export default CustomIcon
