import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { actionTypes as system } from '../System'
import { DataState } from './types'
import { RootState } from '../types'

const featureFlagsSlice = createSlice({
    name: 'Data/FeatureFlags',
    initialState: {} as DataState,
    extraReducers: {
        [system.INIT]: (state, action) => {
            const { initialState } = action.payload
            state.featureFlags = initialState.data.featureFlags
            state.authenticationMechanism = initialState.data.authenticationMechanism
        },
    },
    reducers: {},
})

export const reducer = featureFlagsSlice.reducer

export const selectors = {
    allFeatureFlags: (state: RootState) => state.Data.featureFlags,
    reloadConfirmationFlag: (state: RootState) => state.Data.featureFlags.reloadConfirmation,
    analyticsFlag: (state: RootState) => state.Data.featureFlags.analytics,
    userManagementFlag: (state: RootState) => state.Data.featureFlags.userManagement,
    importerSettingsFlag: (state: RootState) => state.Data.featureFlags.importerSettings,
    jiraConfigurationFlag: (state: RootState) => state.Data.featureFlags.jiraConfiguration,
    configurationSharingFlag: (state: RootState) => state.Data.featureFlags.configurationSharing,
    passwordsAreManagedLocally: (state: RootState) => state.Data.authenticationMechanism.passwordsAreManagedLocally,
    userCanLogout: (state: RootState) => state.Data.authenticationMechanism.userCanLogout,
    typesUiConfigurationFlag: (state: RootState) => state.Data.featureFlags.typesUiConfiguration,
    isDemo: (state: RootState) => state.Data.featureFlags.isDemo,
    // white-list of other-wise hidden importer types
    additionalImporters: (state: RootState) => state.Data.featureFlags.additionalImporters,

    // see frontend-app-react/src/Utility/WidgetUtility.tsx#WidgetFeatureFlags
    widgetFeatureFlags: createSelector(
        [
            (state: RootState) => state.Data.featureFlags.jiraConfiguration,
            (state) => state.Data.featureFlags.additionalWidgets,
        ],
        (hasJiraConfiguration, additionalWidgets) => ({
            hasJiraConfiguration,
            additionalWidgets,
        })
    ),
}
