import { put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { actions } from '../../Redux'

export default function* CreateOrUpdateAndCloseSagas() {
    yield takeLatest(actions.Data.UserManagement.createOrUpdateAndGoToOverview, function* (action) {
        yield put(push('/user-management'))
        yield put(actions.Data.UserManagement.createOrUpdate({ userOrGroup: action.payload.userOrGroup }))
    })
}
