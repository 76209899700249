import { getDefaultAnalysisTermWithField } from '../DefaultConfigurations'

const createDefaultConfiguration = () => {
    return {
        valueField: { analysisTerm: getDefaultAnalysisTermWithField('docCount'), filter: [], dateField: 'primaryDate' },
        title: 'Map Widget',
        colorScale: 'yellowGreenBlue',
        maxNumberOfResults: 100,
        mode: 'geo_point',
        defaultViewport: {
            zoom: 2,
            center: {
                lat: 43.315088190459576,
                lng: -14.160438084395622,
            },
            topLeft: {
                lat: 73.12494524712693,
                lng: 154.6875,
            },
            bottomRight: {
                lat: -12.897489183755892,
                lng: 176.8359375,
            },
        },
    }
}

export default createDefaultConfiguration

export const MapWidgetConfigurationInitializerForWidgetExplorer = createDefaultConfiguration
