import { FieldType } from '../../../Atoms/FieldFilterInput/FieldFilterManifest'

const createDefaultConfiguration = () => ({
    primaryDateField: 'primaryDate',
    columns: [{ field: 'primaryDate' }, { field: 'projectName' }, { field: 'issueName' }],
    orderBy: [
        {
            field: 'primaryDate',
            direction: 'desc',
        },
    ],
    rowLimit: 200,
    filter: [
        {
            field: 'kind',
            operator: 'equals',
            values: ['task'],
        },
    ],
    title: 'Raw Data Table',
})

export default createDefaultConfiguration

export const ResultTableConfigurationInitializerForWidgetExplorer = ({
    valueFields,
    dateFields,
    categoryFields,
}: {
    valueFields: Array<string>
    categoryFields: Array<string>
    dateFields: Array<string>
    filters: Array<FieldType>
}) => {
    const defaultConfiguration = createDefaultConfiguration()

    defaultConfiguration.primaryDateField = dateFields[0]

    const valueFieldArray = valueFields.filter((e) => e !== 'docCount').map((e) => ({ field: e }))
    const categoryFieldArray = categoryFields.map((e) => ({ field: e }))

    defaultConfiguration.columns = [...valueFieldArray, ...categoryFieldArray]

    return defaultConfiguration
}
