import React, { useCallback } from 'react'
import { SizeMe } from 'react-sizeme'
import { connect } from 'react-redux'
import WidgetProblemState from '../../../../Components/Atoms/WidgetProblemState'
import { WidgetId } from '../../../../Redux/Data/types'
import Icon from '../../../../Components/Atoms/Icon/Icon'
import { RootState } from '../../../../Redux/types'
import { actions, selectors } from '../../../../Redux'
import widgetTypes from '../../../../StaticManifests/manifest.widgetTypes'
import { noDataWidgetTypes } from '../../../../Components/Organisms/Widgets/WidgetModel'
import { WidgetErrorBoundary } from '../../Widget/WidgetErrorBoundary'
import ButtonWithTooltip from '../../../../Components/Molecules/HoverTooltip/ButtonWithTooltip'
import Button from '../../../../Components/Atoms/Button/Button'
import { AppDispatch } from '../../../../store'
import { addWidgetFromExplorerAction } from '../../../../Sagas/DashboardSagas/WidgetExplorerSagas'

const noOp = () => {}

type WidgetExplorerSuggestedWidgetOwnProps = {
    widgetId: WidgetId
    showAdvancedScreen: () => void
}

const mapStateToProps = (state: RootState, ownProps: WidgetExplorerSuggestedWidgetOwnProps) => ({
    configuration: selectors.Data.Dashboards.WidgetExplorer.widgetById(state, ownProps.widgetId),
    request: selectors.Data.Dashboards.WidgetExplorer.widgetRequest(state, ownProps.widgetId),
    response: selectors.Data.Dashboards.WidgetExplorer.widgetResponse(state, ownProps.widgetId),

    widgetWasAlreadyAdded: selectors.Data.Dashboards.WidgetExplorer.widgetWasAlreadyAdded(state, ownProps.widgetId),

    hasJiraConfiguration: selectors.Data.FeatureFlags.jiraConfigurationFlag(state),
    userSettings: selectors.UI.CurrentUser.settings(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onConfigureWidget: (widgetId: WidgetId) =>
        dispatch(actions.Data.Dashboards.WidgetExplorer.configureWidget(widgetId)),
    onAddWidget: (widgetId: WidgetId) => dispatch(addWidgetFromExplorerAction(widgetId)),
})

type WidgetExplorerSuggestedWidgetProps = WidgetExplorerSuggestedWidgetOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const WidgetExplorerSuggestedWidget = (props: WidgetExplorerSuggestedWidgetProps) => {
    const widgetType = props.configuration.type
    const widgetManifest = widgetTypes[widgetType]

    // unknown widgetType
    if (!widgetManifest) {
        return (
            <WidgetProblemState
                problem="noSuchWidget"
                key={props.widgetId}
                title={props.configuration.configuration.title}
                isJira={props.hasJiraConfiguration}
            />
        )
    }

    const widgetData = props.response?.data
    // no widget Data
    if (!noDataWidgetTypes.includes(widgetType) && !widgetData) {
        return null
    }

    if (props.response.hasFailed) {
        return null
    }

    const configurationError = widgetManifest.configurationValidations
        ? widgetManifest.configurationValidations(props.configuration.configuration)
        : null
    if (configurationError) {
        return null
    }

    const problem = widgetManifest.renderRequirements ? widgetManifest.renderRequirements(widgetData) : false

    if (problem) {
        return null
    }

    const handleConfigureWidget = useCallback(() => {
        props.onConfigureWidget(props.widgetId)
        props.showAdvancedScreen()
    }, [props.widgetId])

    const handleAddWidget = useCallback(() => props.onAddWidget(props.widgetId), [props.widgetId])

    const WidgetComponent = widgetManifest.component
    return (
        <div className="widget-with-controls" key={props.widgetId + '-with-controls'}>
            <div className="widget-container" key={props.widgetId}>
                <SizeMe monitorHeight>
                    {({ size }) => (
                        <WidgetErrorBoundary size={size} key={props.widgetId + 'error-boundary'}>
                            <WidgetComponent
                                widgetType={widgetType}
                                widgetConfiguration={props.configuration.configuration}
                                widgetState={props.request.widgetState}
                                widgetData={widgetData}
                                userSettings={props.userSettings}
                                setWidgetState={noOp}
                                requestSideData={noOp}
                                openModal={noOp}
                                onChangeWidgetTitle={noOp}
                            />
                        </WidgetErrorBoundary>
                    )}
                </SizeMe>
            </div>
            <div className="widget-selector-with-preview__controls">
                <ButtonWithTooltip
                    isDisabled={props.widgetWasAlreadyAdded}
                    header="Widget already added"
                    type="primary"
                    onClick={handleAddWidget}
                    title="Add Widget to dashboard"
                >
                    <Icon
                        name="PlusIcon"
                        color="white"
                        label={props.widgetWasAlreadyAdded ? 'Added Widget' : 'Add Widget'}
                    />
                </ButtonWithTooltip>
                <Button onClick={handleConfigureWidget} title="Configure this widget in advanced mode">
                    <Icon name="CogIcon" label="Configure" color="green" />
                </Button>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetExplorerSuggestedWidget)
