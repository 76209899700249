import React, { PureComponent } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { Link } from 'react-router-dom'
import WidgetHeader, { WidgetHeaderFunctions } from '../../../Molecules/WidgetHeader/WidgetHeader'
import { ImporterState } from '../../../../Redux/Data/types'

type ImporterStatusWidgetProps = {
    widgetData: Record<
        string,
        {
            uuid: string
            lastCommit: string
            title: string
            status: ImporterState
        }
    >
    setWidgetState: (...args: any[]) => any
    widgetConfiguration: {
        title?: string
    }
    userSettings?: {
        isImporterManager: boolean
    }
    hasJiraConfiguration?: boolean
} & WidgetHeaderFunctions

export default class ImporterStatusWidget extends PureComponent<ImporterStatusWidgetProps> {
    static displayName = 'ImporterStatus'

    render() {
        const title = this.props.widgetConfiguration.title
        const className = classnames('widget', {
            'widget--has-header': title || this.props.onChangeTitle,
        })
        const isImporterManager = this.props.userSettings && this.props.userSettings.isImporterManager
        const isJira = this.props.hasJiraConfiguration
        const wrapperClassname = classnames('importer-status-widget__item__wrapper', {
            'importer-status-widget__item': !isImporterManager,
        })
        return (
            <div className={className}>
                <WidgetHeader title={title} onChangeTitle={this.props.onChangeTitle} />
                <div className="importer-status-widget">
                    {Object.values(this.props.widgetData).map((importer) => {
                        const lastCommit = importer.lastCommit
                        const importerStatus = (
                            <div key={isImporterManager ? undefined : importer.uuid} className={wrapperClassname}>
                                <div className="importer-status-widget__item__header">
                                    {this.renderStatusBubble(importer.status)}
                                    {importer.title}
                                </div>

                                <div className="importer-status-widget__item__body">
                                    <div>{importer.status}</div>
                                    <div className="importer-status-widget__item__body--sub">
                                        Last Import:{' '}
                                        {lastCommit ? moment(lastCommit).format('YYYY-MM-DD HH:mm') : 'never'}
                                    </div>
                                </div>
                            </div>
                        )
                        return isImporterManager ? (
                            <Link key={importer.uuid} to={isJira ? '/jira-settings' : '/importer'}>
                                {importerStatus}
                            </Link>
                        ) : (
                            importerStatus
                        )
                    })}
                </div>
            </div>
        )
    }

    renderStatusBubble = (status: string) => {
        const className = classnames('importer-status-widget__status-bubble', 'importer-status-widget--' + status)
        return <div className={className} />
    }
}
