import React, { PureComponent } from 'react'
import { getIn } from 'immutable'
import List, { ListItem } from '../List/List'
import Headline from '../../Atoms/Headline/Headline'
import Icon from '../../Atoms/Icon/Icon'
import { EditDataSourceEditorsProps } from './DataSource.types'
import { ImporterEditor } from '../../../StaticManifests/manifest.importerTypes'

type EditDataSourceEditorsState = {
    isAdvancedEditorsOpen: boolean
}

/**
 * Data Sources have type-specific configuration, which we define in ImporterDefinitions / ImporterExtensionDefinitions.
 * These configurations can be done in several steps. {@link manifest.importerTypes.ts}
 * This Component is responsible of rendering the form for the configuration for one of these steps.
 * The form is composed of one or more editors (textfield, select, etc.), which are defined in the manifest
 */
export default class EditDataSourceEditors extends PureComponent<
    EditDataSourceEditorsProps,
    EditDataSourceEditorsState
> {
    constructor(props: EditDataSourceEditorsProps) {
        super(props)
        this.state = {
            isAdvancedEditorsOpen: false,
        }
    }

    render() {
        const { editors, advancedEditors } = this.props
        return (
            <div className="data-source--editors">
                {this.renderEditors(editors)}
                {advancedEditors && advancedEditors.length > 0 && this.renderAdvancedEditors(advancedEditors)}
            </div>
        )
    }

    renderEditors = (editors: Array<ImporterEditor>) => {
        return editors.map((editor, i) => {
            const EditorComponent = editor.component
            const property = editor.importerConfigurationProperty
            const value = property && getIn(this.props.configuration, [...property.split('.')])
            const editorContextBuilder = editor.editorContextBuilder
            const editorContext = editorContextBuilder
                ? editorContextBuilder({
                      uuid: this.props.uuid,
                      definitionId: this.props.definitionId,
                      configuration: this.props.configuration,
                      dispatch: this.props.dispatch,
                      configurationSuggestions: this.props.configurationSuggestions,
                  })
                : {}

            return (
                <EditorComponent
                    key={`${property}-${i}`}
                    configuration={editor.configuration}
                    value={value}
                    onChange={this.handleConfigurationPropertyChange(property)}
                    {...editorContext}
                />
            )
        })
    }

    renderAdvancedEditors = (editors: Array<ImporterEditor>) => {
        const headline = [
            <Icon
                key="advanced-editors-icon"
                name="ChevronIcon"
                rotation={this.state.isAdvancedEditorsOpen ? 90 : 0}
            />,
            <Headline key="advanced-options-headline" configuration={{ title: 'Advanced Options', level: 3 }} />,
        ]

        return (
            <List
                children={[
                    <ListItem
                        key="advanced-editors"
                        left={headline}
                        collapsible={this.renderEditors(editors)}
                        isOpen={this.state.isAdvancedEditorsOpen}
                        onClick={this.handleToggleAdvancedEditors}
                    />,
                ]}
            />
        )
    }

    handleToggleAdvancedEditors = () =>
        this.setState((state) => ({ isAdvancedEditorsOpen: !state.isAdvancedEditorsOpen }))

    handleConfigurationPropertyChange = (propertyName: string) => (newValue: any) => {
        this.props.onChange(propertyName, newValue)
    }
}
