import React, { memo } from 'react'
import { connect } from 'react-redux'
import { selectors } from '../../Redux'
import { RootState } from '../../Redux/types'
import DashboardOverview from '../../Components/Organisms/DashboardOverview/DashboardOverview'
import WelcomeScreenHeader from '../../Components/Organisms/WelcomeScreenHeader/WelcomeScreenHeader'
import AccessDeniedScreen from '../AccessDeniedScreen'

const mapStateToProps = (state: RootState) => ({
    hasPermissions: selectors.UI.CurrentUser.hasPermissions(state),
})

type Props = ReturnType<typeof mapStateToProps>

const WelcomeScreen = (props: Props) => (
    <div className="welcome-screen">
        <WelcomeScreenHeader />

        {props.hasPermissions ? (
            <DashboardOverview />
        ) : (
            // rendered if the user has no permissions to see dashboards at all, created for JIRA context
            <AccessDeniedScreen />
        )}
    </div>
)

export default connect(mapStateToProps)(memo(WelcomeScreen))
