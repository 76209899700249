import React, { memo } from 'react'
import Loader from '../../Atoms/Loader/Loader'
import Icon, { IconColor } from '../../Atoms/Icon/Icon'

type Props = {
    iconColor: IconColor
    heading: string
    subtitle: string
    isUploading: boolean
}

const DragNDropContent = (props: Props) => {
    return (
        <React.Fragment>
            {props.isUploading ? <Loader /> : <Icon name="UploadIcon" color={props.iconColor} />}
            <div className="welcome-screen__header__option__text">
                <h3>{props.heading}</h3>
                <p>{props.subtitle}</p>
            </div>
        </React.Fragment>
    )
}

export default memo(DragNDropContent)
