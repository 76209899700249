import dashboardSagas from './DashboardSagas'
import importerSagas from './ImporterSagas'
import jiraConfigurationSagas from './JiraConfiguration'
import userManagementSagas from './UserManagementSagas'
import typeUiConfigurationSagas from './FieldsCustomization/index'
import {
    DashboardInitializationOnDashboardLoadSaga,
    DashboardInitializationOnReset,
    ExecuteWidgetSideDataRequestSaga,
    UpdateOnWidgetStateChangeSaga,
} from './DashboardSagas/WidgetsDataSaga'
import ConfirmationModalSaga from './ConfirmationModalSaga'
import FileUploadSaga from './FileUploadSaga'
import UploadExplyConfig from './UploadExplyConfig'
import MyProfileSaga from './MyProfile'
import SystemSaga from './SystemSaga'
import AutoCompletion from './AutoCompletion'
import SetupWizardSagas from './SetupWizardSagas'
import SaveColorCustomizationSaga from './SaveColorCustomizationSaga'
import VersionsSaga from './VersionSaga'

export default [
    ...dashboardSagas,
    ...importerSagas,
    ...jiraConfigurationSagas,
    ...userManagementSagas,
    ...typeUiConfigurationSagas,
    DashboardInitializationOnDashboardLoadSaga,
    UpdateOnWidgetStateChangeSaga,
    ExecuteWidgetSideDataRequestSaga,
    DashboardInitializationOnReset,
    ConfirmationModalSaga,
    FileUploadSaga,
    UploadExplyConfig,
    MyProfileSaga,
    ...SystemSaga,
    AutoCompletion,
    SetupWizardSagas,
    SaveColorCustomizationSaga,
    VersionsSaga,
]
