import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import ShyTextInput from '../../../Components/Atoms/ShyTextInput/ShyTextInput'
import { DashboardId } from '../../../Redux/Data/types'
import { RootState } from '../../../Redux/types'
import { actions, selectors } from '../../../Redux'
import { AppDispatch } from '../../../store'

type DashboardTitleOwnProps = {
    dashboardId: DashboardId
}

const mapStateToProps = (state: RootState, ownProps: DashboardTitleOwnProps) => ({
    title: selectors.Data.Dashboards.ViewMode.dashboardTitle(state, ownProps.dashboardId),
    inEditMode: selectors.Data.Dashboards.DashboardEditMode.isDashboardEditModeActive(state),
    loadingState: selectors.Data.Dashboards.ViewMode.dashboardLoadingState(state),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeDashboardTitle: (newTitle: string) => {
        dispatch(actions.Data.Dashboards.DashboardEditMode.changeDashboardTitle({ newTitle }))
    },
})

type DashboardTitleProps = DashboardTitleOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const DashboardTitle: React.FC<DashboardTitleProps> = (props) => {
    useEffect(() => {
        document.title = 'Exply | ' + props.title

        return () => {
            document.title = 'Exply'
        }
    }, [props.title, props.inEditMode])

    const className = classnames('dashboard__title', {
        'dashboard__title--error': props.title.length === 0,
    })

    return (
        <div className={className}>
            <h1>
                <ShyTextInput
                    placeholder={'Your Dashboard Title'}
                    disabled={!props.inEditMode}
                    value={props.title}
                    autoFocus
                    onChange={props.changeDashboardTitle}
                />
            </h1>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DashboardTitle))
