import { call, put, select } from 'redux-saga/effects'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import endpoint from '../../Utility/endpoint'
import { actions, selectors } from '../../Redux'
import { distributePiggybackData, FieldHashHeader } from './distributePiggybackData'

/**
 * default endpoint for axios
 */
axios.defaults.baseURL = endpoint.endsWith('/') ? endpoint : endpoint + '/'

export default function* (request: AxiosRequestConfig) {
    const isRemote = request.url?.startsWith('http://') || request.url?.startsWith('https://')
    const headers = request.headers || {}

    const token = selectors.Data.System.jiraCloudJwt(yield select())

    headers.common = {
        Authorization: 'JWT ' + token,
    }
    if (!isRemote) {
        // we need to split this up to provide type information
        headers[FieldHashHeader] = selectors.Data.FieldsConfiguration.hash(yield select())
    }

    // @ts-ignore smth about the axios props
    const response: AxiosResponse = yield call(axios, {
        withCredentials: !isRemote,
        crossDomain: isRemote,
        responseType: 'json',
        maxRedirects: 0,
        validateStatus: null, // status codes >= 300 do not throw
        ...request,
        headers,
    })
    // for some reason even with max redirects set to 0 the status code is never 302 but always 200
    // so we have a work-around here: 'login.html' for Exply on Premise
    if (!isRemote && response.data === null && response.request.responseURL.endsWith('login.html')) {
        yield put(
            actions.UI.Modal.startConfirmation({
                modalId: 'sessionExpired',
                confirmationCallback: () => window.location.reload(),
            })
        )
        return { status: 401 }
    }

    /* I' a teapot - used to block destructive operations which would break the entire system, such as removing the last existing admin */
    if (response.status === 418) {
        yield put(
            actions.UI.Modal.startConfirmation({
                modalId: 'blockDestructiveOperation',
                parameters: {
                    message: response.data?.message,
                },
                confirmationCallback: () => window.location.reload(),
            })
        )
        const processedData: AxiosResponse = yield processMetadata(response)
        return processedData
    }

    /* Precondition Failed - used for Demo mode */
    if (response.status === 412) {
        const hideDemoModeNotification: boolean = yield select(selectors.UI.DemoModeNotification.isHidden)
        if (!hideDemoModeNotification) {
            yield put(
                actions.UI.Modal.startConfirmation({
                    modalId: 'demoOperationDisabled',
                    cancelCallback: () => window.location.reload(),
                })
            )
        }

        return { status: 204 }
    }

    const processedData: AxiosResponse = yield processMetadata(response)
    return processedData
}

function* processMetadata(response: AxiosResponse) {
    if (response && response.data && response.data.meta) {
        const { meta, payload } = response.data
        response.data = payload
        yield distributePiggybackData(meta)
        return response
    }

    // ignore non-piggy-back responses (i.e. on 204 responses)
    return response
}
