import React, { useEffect, useState } from 'react'
import Table, { RowShape } from '../../../../Atoms/Table/Table'

const TablePreview = React.forwardRef(
    (
        props: {
            content: TablePreviewContent
            height?: number
        },
        ref
    ) => {
        // preview should always have half the screen height as height (respecting the "preview" header and padding)
        const [height, setHeight] = useState(0)
        useEffect(() => {
            if (props.height) {
                setHeight(props.height - 40)
            }
        }, [])

        return (
            <div className="preview-scroll-container" style={{ height, overflow: 'scroll' }}>
                <Table
                    allowOverflow={true}
                    columns={props.content.columns}
                    rows={props.content.rows}
                    onTableHeaderClicked={() => {}}
                    hasStickyHeader
                    ref={ref}
                />
            </div>
        )
    }
)

TablePreview.displayName = 'TablePreview'

export type TablePreviewContent = {
    columns: Array<{
        field: string
        label: string
    }>
    rows: Array<RowShape>
}

export default React.memo(TablePreview)
