import { put, select, takeLatest } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { actions, selectors } from '../../../Redux'
import axios from '../../Utility/axios'

export const exportLogs = createAction('Data/Importers/Execution/ExportLogs', (uuid) => ({ payload: { uuid } }))

export default function* ExportImporterLogsSaga() {
    yield takeLatest(exportLogs, function* (action) {
        yield put(actions.UI.Notification.show({ id: 'importerExportLogsRunning' }))
        const { uuid } = action.payload

        try {
            yield executeExport(action.payload)
            yield put(actions.UI.Notification.dismiss())
            window.location.href =
                `admin/importer/log/export/download/${uuid}?jwt=` + selectors.Data.System.jiraCloudJwt(yield select())
        } catch (e) {
            console.error('failed to export importer logs', e)
            yield put(actions.UI.Notification.show({ id: 'importerExportLogsFailed' }))
        }
    })
}

function* executeExport({ uuid }: { uuid: string }) {
    const response: AxiosResponse = yield axios({
        method: 'post',
        url: 'admin/importer/log/export/prepare/' + uuid,
    })
    if (response.status < 400) {
        return response.data.payload
    }

    throw new Error(response.data || 'unknown error')
}
