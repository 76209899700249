import moment from 'moment'
import { UserOrGroupConfig, UserOrGroupId } from './UserManagement'
import { FieldType } from '../../Components/Atoms/FieldFilterInput/FieldFilterManifest'
import { ImportIncident, LogTails } from '../../Components/Molecules/ImporterListItem/ImporterListItem.types'
import {
    ImporterExtensionType,
    JoinExtraCsvDataConfiguration,
} from '../../StaticManifests/manifest.importerExtensionTypes'
import { ImporterType } from '../../StaticManifests/manifest.importerTypes'
import { ColorCustomizationType } from '../../Components/Organisms/ColorCustomization/ColorCustomization'
import { ApplicationModeType } from '../../ApplicationMode'

export enum DashboardLoadingStates {
    SUCCESS = 'SUCCESS',
    LOADING = 'LOADING',
    ERROR = 'ERROR',
    INITIAL = 'INITIAL',
}

export enum ImporterState {
    RUNNING = 'Running',
    ERRONEOUS = 'Erroneous',
    INCONSISTENT = 'Inconsistent',
    OUTDATED = 'Outdated',
    REMOVED = 'Removed',
    HEALTHY = 'Healthy',
}

// see AccessMode.groovy
export enum AccessMode {
    DEFAULT = 'Default',
    SHARED_DASHBOARD = 'SharedDashboard',
}

export type DashboardId = string
export type WidgetGroupId = string
export type WidgetId = string
export type ImporterId = string
export type SharedDashboardId = string

export type ApiDashboardConfiguration = {
    uuid: DashboardId
    title: string
    icon?: string | null
    globalWidgetGroup: WidgetGroupId | null
    widgets: Record<string, ApiWidgetConfiguration>
    widgetGroups: Record<WidgetGroupId, ApiWidgetGroupConfiguration>
}

export type ApiWidgetGroupConfiguration = {
    uuid: WidgetGroupId
    position: number
    name?: string | null
    filter: {
        fields: Array<FieldType>
        dateFields: Array<string>
        documentType?: string | null
    }
}

export type ApiWidgetConfiguration = {
    uuid: WidgetId
    widgetGroup: WidgetGroupId
    type: string
    configuration: {
        title?: string
        [key: string]: any
    }
    coordinatesAndSize: CoordinatesAndSize
}

export type NormalizedDashboardConfig = {
    dashboards: {
        allIds: Array<DashboardId>
        byId: Record<DashboardId, DashboardConfiguration>
    }
    widgetGroups: {
        allIds: Array<WidgetGroupId>
        byId: Record<WidgetGroupId, WidgetGroupConfiguration>
    }
    widgets: {
        allIds: Array<WidgetId>
        byId: Record<WidgetId, WidgetConfiguration>
    }
    sharedDashboards: {
        allIds: Array<SharedDashboardId>
        byId: Record<SharedDashboardId, SharedDashboard>
    }
}

type DashboardBaseState = {
    normalizedDashboardConfig: NormalizedDashboardConfig
    request: DashboardRequest | null
    response: WidgetResponses | null
}

export type DashboardsState = DashboardBaseState & {
    editedDashboard: {
        dashboardId?: DashboardId
        explorer: WidgetExplorer
        backup?: DashboardBaseState
    }

    editedWidget: {
        widgetId?: WidgetId
        backup?: DashboardBaseState
    }

    loadingState: DashboardLoadingStates
}

export type DataState = {
    readonly dashboards: DashboardsState

    readonly userManagement: {
        usersAndGroupsById: Record<UserOrGroupId, UserOrGroupConfig>
        hasPasswordByUserId: Record<UserOrGroupId, boolean>
        isCreation: boolean
        currentlyEditedUserOrGroup: UserOrGroupConfig | null
        passwordToken: string | null
        changedConfiguration: boolean
    }
    readonly system: {
        version: string
        releaseDateMillis: number
        licenses: Array<License>
        applicationMode: ApplicationModeType
        timeZone: string
        workingDays: WorkingDays
        dateFormat: DateFormats
        openDashboardOnStart: DashboardId | null
        internalDashboardSharingLink: string
        accessMode: AccessMode
        elasticTokenLength: number
        jiraCloudJwt: string | null
    }
    readonly jira: {
        availableProjects?: JiraProject[]
    }
    readonly typeUiConfiguration?: {
        userConfiguration: FieldsUIConfiguration
        typesUiDefaults: FieldsUIConfiguration
        fieldsCountByIdentifier: {
            [type: string]: number
        }
        typesByIdentifiers: {
            [type: string]: string
        }
        transientUserConfiguration: FieldsUIConfiguration
        isSaving: boolean
    }
    readonly fieldsConfiguration: {
        hash: string
        fields: {
            [identifier: string]: {
                name: string
                label: string
                tag: 'UNKNOWN' | 'DATE' | 'NUMBER' | 'STRING_SUITABLE_FOR_GROUPING' | 'GEO_POINT' | 'GEO_SHAPE' // see FieldTag.groovy
                unit: string
            }
        }
    }
    readonly featureFlags: {
        feedback: boolean
        configurationSharing: boolean
        analytics: boolean
        additionalWidgets: [any] // TODO
        jiraConfiguration: boolean
        typesUiConfiguration: boolean
        isDemo: boolean
        reloadConfirmation: boolean
        userManagement: boolean
        beta: boolean
        importerSettings: boolean
        additionalImporters: [any] // TODO,
    }
    readonly importers: {
        editing: {
            currentlyEditedImporter: null | ImporterConfiguration
            changedConfiguration: boolean
            initialStepIndex: number | null
            cancelLocation: string | null
            lastEditedImporter?: string | null
            typeSpecific: TypeSpecificConfiguration
        }
        execution: {
            statusByUuid: {
                [uuid: string]: ImporterStatus
            }
            logsByUuid: {
                [uuid: string]: LogTails
            }
        }
        management: {
            definitionsById: {
                [id: string]: ImporterDefinition
            }
            byUuid: {
                [uuid: string]: ImporterConfiguration
            }
        }
    }
    readonly authenticationMechanism: {
        passwordsAreManagedLocally: boolean
        userCanLogout: boolean
    }
    readonly colorCustomization: {
        fieldBasedColors: ColorCustomizationType
        transientFieldBasedColors: ColorCustomizationType
    }
}

export type DashboardConfiguration = {
    id: DashboardId
    title: string
    icon?: string | null
    globalWidgetGroup: WidgetGroupId | null
    widgetGroups: Array<WidgetGroupId>
}

export type SharedDashboard = {
    id: SharedDashboardId
    dashboardId: DashboardId
    isPasswordProtected: boolean
    isDownloadAllowed: boolean
}

export type WidgetGroupConfiguration = {
    id: WidgetGroupId
    dashboardId: DashboardId
    position: number
    name?: string | null
    filter: WidgetGroupFilter
    widgets: Array<WidgetId>
}

export type WidgetGroupFilter = {
    fields: Array<FieldType>
    dateFields: Array<string>
    documentType?: string | null
}

export type WidgetConfiguration = {
    id: WidgetId
    widgetGroup: WidgetGroupId
    type: string
    configuration: {
        title: string
        [key: string]: any
    }
    coordinatesAndSize: CoordinatesAndSize
}

export type CoordinatesAndSize = {
    x: number
    y: number
    height: number
    width: number
}

export type WidgetExplorer = {
    selections: WidgetExplorerSelections
    // this stores a single dashboard to fake the explorer widget preview
    dashboardConfigurations: NormalizedDashboardConfig

    request: Record<WidgetId, WidgetRequest>
    response: WidgetResponses
    addedWidgets: Array<WidgetId>
    isActive: boolean
}

export type WidgetExplorerSelections = {
    dateFields: Array<string>
    valueFields: Array<string>
    categoryFields: Array<string>
    time: {
        startDate: moment.Moment | null
        endDate: moment.Moment | null
    }
    filters: Array<Filter>
}

export type DashboardRequest = {
    dashboard: DashboardId
    widgetRequests: Record<WidgetId, WidgetRequest>

    // transient dashboard is stored in the state when in demo mode
    transientDashboard?: ApiDashboardConfiguration
}

export type WidgetRequest = {
    dependencyHash: string | null
    widgetState: WidgetState
}

export type WidgetResponses = Record<WidgetId, WidgetResponse>

// this is what the server returns when calling the /execute api
export type ExecuteDashboardServerResponse = {
    widgetResponses: WidgetResponses
}

export type WidgetResponseData = {
    [property: string]: any
}

export type WidgetResponse = {
    data: WidgetResponseData | null
    dependencyHash: string | null
    hasChanged: boolean
    hasFailed: boolean
    displayErrorMessage: string | null
}

export type License = {
    content: {
        id: string
        owner: string
        expirationDateMillis: number
        userLimit: number
    }
    status: {
        isValid: boolean
        isReadable: boolean
        raw: string
    }
}

export type FieldConfiguration = {
    label: string | null
    isVisibleInUi: boolean | null
}

export type FieldsUIConfiguration = {
    fieldsUiConfiguration: {
        [typeIdentifier: string]: FieldConfiguration
    }
}

export type ImporterStatus = {
    latestCreatedOn: null
    exptectedNumberOfDocuments: number | null
    lastImportStart: number
    lastSchedule: number
    lastCommit: number
    state: ImporterState
    isRunning: boolean
    isRemoved: boolean
    numberOfDocuments: number | null
    uuid: string
    isInconsistencyAllowed: boolean
    incidents: ImportIncident[]
}

// see ImporterConfigurationWithoutConfiguration.groovy and ImporterConfiguration.groovy
export type ImporterConfiguration = {
    version?: number
    uuid: string
    title: string
    enabled: boolean
    parent?: null
    definitionId: ImporterType
    schedule: string
    postProcessors: PostProcessorConfiguration[]
    configuration: {
        [property: string]: any
    }
}

export type TypeSpecificConfiguration = {
    tempoCloud: {
        tempoOAuthConfiguration?: TempoOAuthConfiguration
    }
}

export type TempoOAuthConfiguration = {
    redirectionUri: string
    atlassianHostUrl: string
}

// see PostProcessorConfiguration.groovy
export type PostProcessorConfiguration = {
    uuid: string
    title: string
    type: ImporterExtensionType
    configuration: JoinExtraCsvDataConfiguration
}

export type ImporterDefinition = {
    label: string
    importerName: string
    managedByExply: boolean
    aliases: Array<string>
    processType: string
    definitionType: string
    targetSystem: string
}

export type WidgetState = {
    startMilliseconds?: number
    endMilliseconds?: number
    selection?: Array<string>
    activeFilters?: Record<string, Array<string>>
}

export type Filter = {
    field: string | null
    operator: string
    values: Array<string>
}

export type JiraProject = {
    name: string
    key?: string
}

export type WorkingDays = Array<number>

export enum DateFormats {
    MMDDYY = 'MM/DD/YY',
    DDMMYY = 'DD/MM/YY',
    YYMMDD = 'YY/MM/DD',
}
