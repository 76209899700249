import React, { lazy, Suspense } from 'react'
import { SpacerWidgetProps } from './SpacerWidget'

const SpacerWidget = lazy(() => import(/* webpackChunkName: "SpacerWidget" */ './SpacerWidget'))

const LazySpacerWidget: React.FC<SpacerWidgetProps> = (props) => (
    <Suspense fallback="">
        <SpacerWidget {...props} />
    </Suspense>
)

export default LazySpacerWidget
