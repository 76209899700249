import React from 'react'
import StatusIndicator from '../../../Atoms/StatusIndicator/StatusIndicator'
import { License } from '../../../../Redux/Data/types'

export const LicenseInformation: React.FC<License> = (props) => {
    const { content, status } = props
    const isReadable = status.isReadable
    const userLimit = content.userLimit === 2147483647 ? false : content.userLimit
    const raw = status.raw

    const renderExpiration = () => {
        const { content, status } = props
        const expirationDateMillis = content.expirationDateMillis
        const validUntil =
            expirationDateMillis === 9223372036854775807 ? false : new Date(expirationDateMillis).toDateString()
        if (validUntil) {
            return <div>{status.isValid ? `valid until ${validUntil}` : `expired at ${validUntil}`}</div>
        }

        return ''
    }

    return (
        <div className="license-information">
            <div className="license-information__header">
                <StatusIndicator status={status.isValid} />
                <div>{isReadable ? `License of ${content.owner} (${content.id})` : 'invalid content'}</div>
            </div>
            {isReadable ? (
                <div className="license-information__details">
                    {renderExpiration()}
                    {userLimit === false ? '' : <div>user limit is {userLimit}</div>}
                </div>
            ) : (
                <div className="license-information__details">invalid format</div>
            )}
            {raw ? <div className="license-information__details">{raw}</div> : ''}
        </div>
    )
}
