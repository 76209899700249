import { TooltipId } from './manifest.tooltips'
import TextInput from '../Components/Atoms/TextInput/TextInput'
import Switch from '../Components/Atoms/Switch/Switch'
import NumberPicker from '../Components/Atoms/NumberPicker/NumberPicker'
import { EditorContextBuilderConfig, ImporterConfigurationStep, ImporterEditor } from './manifest.importerTypes'
import FormFieldWithLabel from '../Components/Molecules/FormFieldWithLabel/FormFieldWithLabel'
import ImporterFieldMappingEditor, {
    FieldMappings,
    Mapping,
} from '../Components/Molecules/ImporterFieldMapping/ImporterFieldMappingEditor'
import { detectFieldMapping } from '../Sagas/ImporterSagas/ConfigPreview/DetectFieldMappingSaga'
import JdbcConnectionInput from '../Components/Molecules/JdbcConnectionInput/JdbcConnectionInput'
import CodeAceArea from '../Components/Atoms/CodeAceArea/LazyCodeAceArea'
import { CsvParserSettings } from './manifest.importerExtensionTypes'
import Select from '../Components/Atoms/Select/Select'
import ImporterSourceSelector from '../Containers/SourceSelectorContainer'
import { actions } from '../Redux'
import { ImporterConfiguration, PostProcessorConfiguration } from '../Redux/Data/types'

export const CHARSET_OPTIONS = [
    {
        value: 'US-ASCII',
        label: 'US-ASCII',
    },
    {
        value: 'ISO-8859-1',
        label: 'ISO-LATIN-1',
    },
    {
        value: 'UTF-8',
        label: 'UTF-8',
    },
    {
        value: 'UTF-16BE',
        label: 'UTF-16BE',
    },
    {
        value: 'UTF-16LE',
        label: 'UTF-16LE',
    },
    {
        value: 'UTF-16',
        label: 'UTF-16',
    },
]

export function textField(
    property: string,
    label: string,
    placeholder?: string,
    helpTooltip?: TooltipId,
    configuration?: any,
    editorContextBuilder?: (config: EditorContextBuilderConfig) => any
): ImporterEditor {
    return textOrPasswordField('text', property, label, placeholder, helpTooltip, configuration, editorContextBuilder)
}

export function passwordField(
    property: string,
    label: string,
    placeholder?: string,
    helpTooltip?: string,
    configuration?: any,
    editorContextBuilder?: (config: EditorContextBuilderConfig) => any
): ImporterEditor {
    return textOrPasswordField(
        'password',
        property,
        label,
        placeholder,
        helpTooltip,
        configuration,
        editorContextBuilder
    )
}

export function textOrPasswordField(
    type: 'text' | 'password',
    property: string,
    label: string,
    placeholder?: string,
    helpTooltip?: string,
    configuration?: any,
    editorContextBuilder?: (config: EditorContextBuilderConfig) => any
): ImporterEditor {
    return editorWithLabel(
        property,
        label,
        TextInput,
        { type, placeholder, helpTooltip, ...(configuration || {}) },
        editorContextBuilder
    )
}

export const flag = (importerConfigurationProperty: string, label: string) => ({
    importerConfigurationProperty,
    component: Switch,
    editorContextBuilder: () => ({
        label,
    }),
    isFlag: true,
    configuration: {},
})

export function numberPicker(property: string, label: string, configuration = {}): ImporterEditor {
    return {
        importerConfigurationProperty: property,
        component: NumberPicker,
        editorContextBuilder: () => ({
            label,
            ...configuration,
        }),
        configuration: {
            ...configuration,
        },
    }
}

export const defaultRequiredPropertyValidator = (propertyValue: any) => propertyValue && propertyValue.length > 0

export function editorWithLabel(
    property: string,
    label: string,
    editor: any,
    configuration = {},
    editorContextBuilder: null | ((config: EditorContextBuilderConfig) => any) = null
): ImporterEditor {
    return {
        importerConfigurationProperty: property,
        component: FormFieldWithLabel,
        editorContextBuilder,
        configuration: {
            label,
            editor,
            ...configuration,
        },
    }
}

export const databaseDataSourceConfiguration = [
    textField('jdbcConnection', 'JDBC Connection', 'jdbc:mysql://database-server.org:3306/my-database'),
    textField('jdbcUser', 'JDBC User', 'database-user'),
    passwordField('jdbcPassword', 'JDBC Password', 'database password'),
    textField('jdbcDriver', 'JDBC Driver', 'com.mysql.jdbc.Driver'),
    textField('tunnelCommand', 'Tunnel Command (optional)', 'ssh -t -p [your port] user@myserver.com'),
]

export type OnNextStepHandler = (
    configuration: ImporterConfiguration | PostProcessorConfiguration,
    dispatch: any,
    defaultNextAction: () => void
) => void

export const fieldMappingConfigurationStep = (isExtension: boolean): ImporterConfigurationStep => {
    return {
        title: 'Data Types',
        hasPreview: true,
        hasConfigSuggestion: true,
        isMappingStep: true,
        onNextStep: mappingOnNextStepHandler,
        requiredProperties: [
            {
                propertyPath: 'mapping.documentType',
                propertyValidator: defaultRequiredPropertyValidator,
            },
            {
                propertyPath: 'mapping.mappings',
                propertyValidator: defaultRequiredPropertyValidator,
            },
            {
                propertyPath: 'mapping.mappings',
                propertyValidator: (value: Array<Mapping>) =>
                    value.some((mapping) => mapping.isConfirmed && mapping.isActive),
            },
        ],
        requiredPropertiesMessage: 'At least one confirmed and active mapping required',
        editors: [
            {
                importerConfigurationProperty: 'mapping',
                // @ts-ignore: ImporterFielMappingEditor has the generateSuggestion Function required, which is actually
                // present because the editorContext (resulting object from the editorContextBuilder) gets expanded into
                // the component
                component: ImporterFieldMappingEditor,
                editorContextBuilder: ({
                    uuid,
                    definitionId,
                    configuration,
                    dispatch,
                }: {
                    uuid?: string
                    definitionId?: string
                    configuration: any
                    dispatch: any
                }) => ({
                    generateSuggestion: (language: string, callback: () => void) =>
                        dispatch(
                            detectFieldMapping(uuid, definitionId, configuration, language, callback, isExtension)
                        ),
                }),
                configuration: {},
            },
        ],
    }
}

const mappingOnNextStepHandler: OnNextStepHandler = (configuration, dispatch, defaultNextAction) => {
    dispatch(
        actions.UI.Modal.startConfirmation({
            modalId: 'saveCSVImporter',
            parameters: {
                configuration,
            },
            confirmationCallback: defaultNextAction,
        })
    )
}

export const csvSourceFileStep = {
    title: 'Source File',
    hasPreview: false,
    requiredProperties: [
        {
            propertyPath: 'source.name',
            propertyValidator: defaultRequiredPropertyValidator,
        },
    ],
    requiredPropertiesMessage: 'Source File required',
    editors: [editorWithLabel('source', '', ImporterSourceSelector, { fileType: 'csv' })],
}

export const csvParsingStep = (isExtension?: boolean) => ({
    title: 'Parsing',
    hasPreview: true,
    requiredProperties: [
        {
            propertyPath: 'parserSettings.separator',
            propertyValidator: defaultRequiredPropertyValidator,
        },
        {
            propertyPath: 'parserSettings.sourceLanguage',
            propertyValidator: defaultRequiredPropertyValidator,
        },
    ],
    requiredPropertiesMessage: 'Delimiter and Source Language required',
    onNextStep: isExtension ? getExtensionCsvParsingStepOnNextStepHandler : getImporterCsvParsingStepOnNextStepHandler,
    editors: [
        textField('parserSettings.separator', 'Delimiter', ';', 'csvImporterSettingsSeparator', {
            autoFocus: true,
        }),
        editorWithLabel('parserSettings.sourceLanguage', 'Source Language', Select, {
            clearable: false,
            options: [
                {
                    label: 'English',
                    value: 'ENGLISH',
                },
                {
                    label: 'German',
                    value: 'GERMAN',
                },
            ],
            placeholder: 'Select Source Language...',
            helpTooltip: 'csvImporterSettingsSourceLanguage',
        }),
        editorWithLabel('parserSettings.charset', 'Charset', Select, {
            clearable: false,
            options: CHARSET_OPTIONS,
            placeholder: 'UTF-8',
        }),
    ],
    advancedEditors: [
        textField('parserSettings.quoteChar', 'Quote Character', '"', 'csvImporterSettingsQuoteCharacter'),
        textField('parserSettings.escapeChar', 'Escape Character', '\\', 'csvImporterSettingsEscapeChar'),
        flag('parserSettings.ignoreLeadingWhiteSpace', 'Ignore Leading White Space'),
        flag('parserSettings.ignoreQuotation', 'Ignore Quotation'),
        flag('parserSettings.strictQuotes', 'Strict Quotes'),
        numberPicker('parserSettings.linesToSkip', 'Lines To Skip', {
            min: 0,
            placeholder: 0,
            max: 9999,
            step: 1,
        }),
        numberPicker('parserSettings.multiLineLimit', 'Limit for Multi Line Values', {
            min: 0,
            placeholder: 0,
            max: 9999,
            step: 1,
        }),
    ],
})

const getImporterCsvParsingStepOnNextStepHandler: OnNextStepHandler = (configuration, dispatch, defaultNextAction) => {
    dispatch(
        detectFieldMapping(
            configuration.uuid,
            (configuration as ImporterConfiguration).definitionId,
            configuration.configuration,
            configuration.configuration.parserSettings.sourceLanguage,
            (value: FieldMappings) => dispatch(actions.Data.Importer.editConfiguration('mapping', value)),
            false
        )
    )
    defaultNextAction()
}

const getExtensionCsvParsingStepOnNextStepHandler: OnNextStepHandler = (configuration, dispatch, defaultNextAction) => {
    dispatch(
        detectFieldMapping(
            configuration.uuid,
            (configuration as PostProcessorConfiguration).type,
            configuration.configuration,
            configuration.configuration.parserSettings.sourceLanguage,
            (value: FieldMappings) =>
                dispatch(actions.Data.Importer.editExtensionConfigurationProperty('mapping', value)),
            true
        )
    )
    defaultNextAction()
}

const getSqlOnNextStepHandler: OnNextStepHandler = (configuration, dispatch, defaultNextAction) => {
    dispatch(
        detectFieldMapping(
            configuration.uuid,
            (configuration as ImporterConfiguration).definitionId,
            configuration.configuration,
            'ENGLISH',
            (value: FieldMappings) => dispatch(actions.Data.Importer.editConfiguration('mapping', value)),
            false
        )
    )
    defaultNextAction()
}

export const databaseQueryStep = {
    title: 'Database Query',
    hasPreview: true,
    requiredProperties: [
        {
            propertyPath: 'querySettings.query',
            propertyValidator: defaultRequiredPropertyValidator,
        },
    ],
    requiredPropertiesMessage: 'Query required',
    editors: [
        editorWithLabel('querySettings.query', 'Query', CodeAceArea, {
            focus: true,
            height: '350px',
            language: 'mysql',
            placeholder: 'select * from my_data_table',
        }),
    ],
    onNextStep: getSqlOnNextStepHandler,
}

export const genericSqlDataSourceType = (
    label: string,
    icon: string,
    configurationInitializer: () => { parserSettings?: CsvParserSettings; jdbcConnection: string }
) => ({
    label,
    icon,
    configurationInitializer,
    configurationSteps: [
        {
            title: 'Database Connection',
            hasPreview: false,
            requiredProperties: [
                {
                    propertyPath: 'jdbcConnection',
                    propertyValidator: defaultRequiredPropertyValidator,
                },
                {
                    propertyPath: 'jdbcUsername',
                    propertyValidator: defaultRequiredPropertyValidator,
                },
            ],
            requiredPropertiesMessage: 'Connection and Username required',
            editors: [
                editorWithLabel('jdbcConnection', 'Database Connection', JdbcConnectionInput),
                textField('jdbcUsername', 'Database User'),
                passwordField('jdbcPassword', 'Database Password'),
            ],
        },
        databaseQueryStep,
        fieldMappingConfigurationStep(false),
    ],
})
