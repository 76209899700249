import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uuid from 'uuid'
import { DashboardGroup, DashboardGroupId, UiState } from './types'
import { RootState } from '../types'
import { actionTypes as system } from '../System'
import { DashboardId } from '../Data/types'

export const ALL_DASHBOARDS = 'allDashboards'
export const FAVOURITE_DASHOARDS = 'favourites'

const dashboardGroupsSlice = createSlice({
    name: 'UI/DashboardGroups',
    initialState: {} as UiState,
    extraReducers: {
        [system.INIT](state, action) {
            const { initialState } = action.payload

            const byId: { [key: string]: DashboardGroup } = {}
            const allIds: Array<DashboardGroupId> = []
            const dashboardGroups: Array<DashboardGroup> = initialState.userSettings.dashboardGroups

            dashboardGroups.forEach((group) => {
                allIds.push(group.id)
                byId[group.id] = group
            })

            state.currentUser.uiSettings.dashboardGroups = {
                allIds,
                byId,
            }
        },
    },
    reducers: {
        createDashboardGroup: (state) => {
            const id = uuid()
            const newGroup = {
                id,
                title: '',
                dashboards: [],
                isCollapsed: false,
            }

            state.currentUser.uiSettings.dashboardGroups.allIds.push(id)
            state.currentUser.uiSettings.dashboardGroups.byId[id] = newGroup
            state.lastAddedDashboardGroup = id
        },
        removeDashboardGroup: (state, action: PayloadAction<DashboardGroupId>) => {
            state.currentUser.uiSettings.dashboardGroups.allIds =
                state.currentUser.uiSettings.dashboardGroups.allIds.filter((id) => id !== action.payload)
            state.lastAddedDashboardGroup = null
            delete state.currentUser.uiSettings.dashboardGroups.byId[action.payload]
        },
        removeDashboardFromGroup: (
            state,
            action: PayloadAction<{ groupId: DashboardGroupId; dashboardId: DashboardId }>
        ) => {
            state.currentUser.uiSettings.dashboardGroups.byId[action.payload.groupId].dashboards =
                state.currentUser.uiSettings.dashboardGroups.byId[action.payload.groupId].dashboards.filter(
                    (id) => id !== action.payload.dashboardId
                )
        },
        changeGroupTitle: (state: UiState, action: PayloadAction<{ groupId: DashboardGroupId; title: string }>) => {
            state.currentUser.uiSettings.dashboardGroups.byId[action.payload.groupId].title = action.payload.title
            state.lastAddedDashboardGroup = null
        },
        moveDashboardGroup: (state, action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>) => {
            const groupToMove = state.currentUser.uiSettings.dashboardGroups.allIds.splice(
                action.payload.sourceIndex,
                1
            )
            state.currentUser.uiSettings.dashboardGroups.allIds.splice(
                action.payload.destinationIndex,
                0,
                ...groupToMove
            )
        },
        moveDashboardFromGroupToGroup: (
            state: UiState,
            action: PayloadAction<{
                sourceGroup: DashboardGroupId
                destinationGroup: DashboardGroupId
                dashboardId: DashboardId
            }>
        ) => {
            const { sourceGroup, destinationGroup, dashboardId } = action.payload
            // remove from old group
            if (sourceGroup !== ALL_DASHBOARDS) {
                state.currentUser.uiSettings.dashboardGroups.byId[sourceGroup].dashboards =
                    state.currentUser.uiSettings.dashboardGroups.byId[sourceGroup].dashboards.filter(
                        (id) => id !== dashboardId
                    )
            }

            // add to new group
            if (destinationGroup !== ALL_DASHBOARDS && destinationGroup !== FAVOURITE_DASHOARDS) {
                state.currentUser.uiSettings.dashboardGroups.byId[destinationGroup].dashboards.push(dashboardId)
            }
        },
        toggleIsGroupCollapsed: (state, action: PayloadAction<DashboardGroupId>) => {
            state.currentUser.uiSettings.dashboardGroups.byId[action.payload].isCollapsed =
                !state.currentUser.uiSettings.dashboardGroups.byId[action.payload].isCollapsed
        },
    },
})

export const actions = dashboardGroupsSlice.actions
export const reducers = dashboardGroupsSlice.reducer

const groupIdsOfDashboard = (state: RootState, dashboard: DashboardId) => {
    const groupIds: Array<DashboardGroupId> = []
    Object.values(state.UI.currentUser.uiSettings.dashboardGroups.byId).forEach((group) => {
        if (group.dashboards.includes(dashboard)) {
            groupIds.push(group.id)
        }
    })

    return groupIds
}

const allDashboardGroups = (state: RootState) => {
    const allIds = state.UI.currentUser.uiSettings.dashboardGroups.allIds
    return allIds.map((id) => state.UI.currentUser.uiSettings.dashboardGroups.byId[id])
}

export const selectors = {
    allDashboardGroupIds: (state: RootState) => state.UI.currentUser.uiSettings.dashboardGroups.allIds,
    dashboardGroupById: (state: RootState, id: DashboardGroupId) =>
        state.UI.currentUser.uiSettings.dashboardGroups.byId[id],
    lastAddedDashboardGroup: (state: RootState) => state.UI.lastAddedDashboardGroup,
    allDashboardGroups,
    groupIdsOfDashboard,
}
