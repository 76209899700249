import React from 'react'

const CustomizedLabel: React.FC<CustomizedLabelProps> = (props) => {
    const { x, y, width, height } = props.viewBox!
    return (
        <g className="customized-label">
            <g className="customized-label--click-area" transform={`translate(${x},${y})`}>
                <rect
                    width={width}
                    height={height}
                    style={{
                        fill: 'rgb(0,0,0,0)',
                        stroke: 'rgb(0,0,0,0)',
                        cursor: props.onClick ? 'pointer' : undefined,
                    }}
                    fillOpacity={0 /* fill-opacity is needed for Safari */}
                    onClick={props.onClick}
                />
            </g>
            <g className="customized-label--text" transform={`translate(${x + width / 2},${y + height / 2})`}>
                <text
                    x={props.x}
                    y={props.y}
                    textAnchor={props.textAnchor}
                    fontSize={props.fontSize}
                    transform={`rotate(${props.angle === undefined ? '0' : props.angle})`}
                >
                    {props.value}
                </text>
            </g>
        </g>
    )
}

type CustomizedLabelProps = Partial<{
    value: string
    textAnchor: string
    angle: number
    fontSize: number
    x: number
    y: number
    // provided by recharts
    viewBox: {
        x: number
        y: number
        width: number
        height: number
    }
    // the clickable area covers almost the entire axis including the white-space
    // the click handler of recharts only allows to click the axis ticks
    onClick: () => void
}>

CustomizedLabel.defaultProps = {
    x: 0,
    y: 0,
    textAnchor: 'middle',
    fontSize: 16, // see base-font-size in _Variables.scss
}

export default React.memo(CustomizedLabel)
