import React, { PureComponent } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { getIn } from 'immutable'
import { OnChangeMultiple, WidgetEditorFormProps } from './WidgetEditorForm.types'
import Icon from '../../Atoms/Icon/Icon'
import { BottomTab, WidgetConfigurationEditor } from '../../../widgetManifestBuildingBlocks'
import widgetTypes from '../../../StaticManifests/manifest.widgetTypes'

export default class WidgetEditorForm extends PureComponent<WidgetEditorFormProps> {
    render() {
        const widgetType = this.getWidgetType()
        if (widgetType) {
            const widgetConfiguration = this.getConfiguration()
            const groups = widgetType.editors[this.props.editorPath]

            if (this.props.isTabView && groups.length > 0) {
                return (
                    <Tabs defaultIndex={0}>
                        <TabList>
                            {(groups as Array<BottomTab>).map((e: any, i: number) => (
                                <Tab key={`form-tab-name-${i}`}>{e.name}</Tab>
                            ))}
                        </TabList>

                        {(groups as Array<BottomTab>).map((e: any, i: number) => (
                            <TabPanel key={`form-tab-panel-${i}`}>
                                <div className="widget-editor-form__panel">
                                    {this.renderEditors(e.editors, widgetConfiguration)}
                                </div>
                            </TabPanel>
                        ))}
                    </Tabs>
                )
            }

            const editors = widgetType.editors[this.props.editorPath as 'data']
            return this.renderEditors(editors, widgetConfiguration)
        }

        return <div>The current widget type is unknown.</div>
    }

    renderEditors = (editors: Array<WidgetConfigurationEditor>, widgetConfiguration: any) =>
        editors.map((editor: any, i: number) => {
            const isDisplayed = editor.isDisplayed ? editor.isDisplayed(widgetConfiguration) : true
            const EditorComponent = editor.component
            const editorContextBuilder = editor.editorContextBuilder
            const property = editor.widgetConfigurationProperty
            const value = property ? getIn(widgetConfiguration, [...property.split('.')]) : null
            const iconName = editor.configuration?.editorIcon
            const iconIsActive = editor.configuration?.isIconAlwaysActive || Boolean(value)

            const editorContext = editorContextBuilder ? editorContextBuilder(widgetConfiguration) : []

            const handleClickSwitchIcon = () => this.handleConfigurationPropertyChange(property)(!value)

            const renderEditorComponentWithProps = () =>
                isDisplayed ? (
                    <EditorComponent
                        key={this.props.widget?.type + (property || i)}
                        configuration={editor.configuration}
                        onChange={this.handleConfigurationPropertyChange(property)}
                        onChangeMultiple={this.handleManyConfigurationPropertyChange}
                        widgetConfiguration={widgetConfiguration}
                        widgetType={this.props.widget?.type}
                        value={value}
                        onTriggerAutoCompletion={this.props.onTriggerAutoCompletion}
                        hasJiraConfiguration={this.props.hasJiraConfiguration}
                        {...editorContext}
                    />
                ) : null

            if (this.props.editorPath === 'bottomTabs') {
                return (
                    <div className="bottomTab-editor" key={'bottom-tab-editor-' + i}>
                        {iconName ? (
                            <Icon
                                name={iconName}
                                useOwnColors
                                useGrayscale={!iconIsActive}
                                onClick={editor.isFlag ? handleClickSwitchIcon : undefined}
                            />
                        ) : null}
                        {renderEditorComponentWithProps()}
                    </div>
                )
            }

            return renderEditorComponentWithProps()
        })

    getWidgetType = () => this.props.widget && widgetTypes[this.props.widget.type]

    getConfiguration = () => {
        const config = this.props.widget?.configuration
        const configMapping = this.getWidgetType()?.configMapping
        if (configMapping && config) {
            return (Object.keys(configMapping) as Array<keyof typeof configMapping>).reduce((result, targetName) => {
                result[targetName] = getIn(config, [...configMapping[targetName].split('.')])
                return result
            }, {} as { [key: string]: any })
        }

        return config
    }

    handleConfigurationPropertyChange = (propertyName: string) => (newValue: any) =>
        this.handleOnChange(propertyName, newValue)

    handleManyConfigurationPropertyChange: OnChangeMultiple = (changeArray) => {
        changeArray.forEach((change) => {
            const { propertyName, value } = change
            this.handleOnChange(propertyName, value)
        })
    }

    handleOnChange = (property: string, value: any) => {
        const configMapping = this.getWidgetType()?.configMapping
        if (configMapping) {
            this.props.onChange(configMapping[property as keyof typeof configMapping], value)
        } else {
            this.props.onChange(property, value)
        }
    }
}
