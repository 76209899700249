import QuickFilterWidget from './QuickFilterWidget'
// @ts-ignore file ext
import QuickFilterWidgetIcon from './QuickFilterWidget.Icon.tsx'
import QuickFilterWidgetStateInitializer from './QuickFilterWidget.init'
import QuickFilterWidgetConfigurationInitializer from './QuickFilterWidget.configurationInitializer'
import QuickFilterEditor from '../../../Molecules/QuickFilterEditor/QuickFilterEditor'
import { WidgetManifestConfiguration } from '../../../../widgetManifestBuildingBlocks'
import * as buildingBlocks from '../../../../widgetManifestBuildingBlocks'
import DateFieldEditor from '../../../Molecules/DateFieldEditor/DateFieldEditor'
import FormFieldWithLabel from '../../../Molecules/FormFieldWithLabel/FormFieldWithLabel'

const config: WidgetManifestConfiguration = {
    name: 'Quick Filter Widget',
    component: QuickFilterWidget,
    icon: QuickFilterWidgetIcon,
    stateInitializationFunction: QuickFilterWidgetStateInitializer,
    configurationInitializer: QuickFilterWidgetConfigurationInitializer,
    editors: {
        data: [
            {
                widgetConfigurationProperty: 'filters',
                component: QuickFilterEditor,
                configuration: {},
            },
        ],
        bottomTabs: [
            {
                name: 'Filter Options',
                editors: [
                    buildingBlocks.flag('useTimeSelectorFilter', 'Include current Time Selection for Filter Options'),
                    {
                        widgetConfigurationProperty: 'dateField',
                        component: FormFieldWithLabel,
                        configuration: {
                            label: 'Datefield',
                            editor: DateFieldEditor,
                        },
                    },
                ],
            },
        ],
    },
    minHeight: 1,
    minWidth: 4,
}

export default config
