import importerTypes, { ImporterConfigurationStep, ImporterType } from '../../../StaticManifests/manifest.importerTypes'
import importerExtensionTypes, { ImporterExtensionType } from '../../../StaticManifests/manifest.importerExtensionTypes'

export function getImporterConfigurationSteps(definitionId?: ImporterType): ImporterConfigurationStep[] {
    if (definitionId) {
        const type = importerTypes[definitionId]

        if (type) {
            return type.configurationSteps || [{ editors: type.editors || [] }]
        }
    }

    return []
}

export const getExtensionConfigurationSteps = (extensionId?: ImporterExtensionType) => {
    if (extensionId) {
        return importerExtensionTypes[extensionId].configurationSteps
    }

    return []
}
