import React from 'react'
import classnames from 'classnames'
import Icon from '../Icon/Icon'

const TextInput = React.forwardRef<
    HTMLInputElement,
    {
        className?: string
        value?: string
        disabled?: boolean
        hasError?: boolean
        hideBorder?: boolean
        placeholder?: string
        clearable?: boolean
        onChange: (value: string) => void
        onEnterPress?: () => void
        onClear?: () => void
        onBlur?: () => void
        onFocus?: () => void
        onClick?: () => void
        autoFocus?: boolean
        type?: 'password' | 'text' | 'color'
        useSelectHeight?: boolean
    }
>((props, ref) => {
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 && props.onEnterPress) {
            props.onEnterPress()
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event.target.value)

    const className = classnames('input', props.className, {
        'input--disabled': props.disabled,
        'input--hide-border': props.hideBorder,
        'input--has-error': props.hasError,
        'input--select-height': props.useSelectHeight,
        'input--clearable': props.clearable,
    })

    const hasValue = () => {
        if (props.value === undefined || props.value === null) {
            return false
        }

        return props.value.length > 0
    }

    const iconClassName = classnames({
        'clear-icon--hidden': !hasValue(),
    })

    return (
        <>
            <input
                className={className}
                type={props.type || 'text'}
                value={props.value || ''}
                placeholder={props.placeholder}
                onClick={props.onClick}
                onFocus={props.onFocus}
                onChange={handleChange}
                onBlur={props.onBlur}
                onKeyDown={handleKeyDown}
                ref={ref}
                autoFocus={props.autoFocus}
            />
            {props.clearable && (
                <button className="input__clear-button" onClick={props.onClear}>
                    <Icon name="TimesIcon" title="clear input" color="light" className={iconClassName} />
                </button>
            )}
        </>
    )
})

export default React.memo(TextInput)
